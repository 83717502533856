import React from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';

import defaultStyles from './DefaultLayout.module.css';
import { selectParticipant } from 'participant-search/participant.slice';
import ParticipantSubHeader from '../shared/ParticipantSubHeader';
import AppToolbar from './Header/AppToolbar';
import { selectPageTitle } from 'components/app-settings.slice';
import { WizardStep } from 'components/shared/WizardStepper';

interface WizardLayoutProps {
  showBackButton?: boolean;
  steps?: Map<string, WizardStep>;
}

function WizardLayout({ children, steps, showBackButton = true }: React.PropsWithChildren<WizardLayoutProps>) {
  const { stepSlug } = useParams<{stepSlug?: string;}>();
  const history = useHistory();
  const participant = useSelector(selectParticipant);
  const pageTitle = useSelector(selectPageTitle);

  function getCurrentStep() {
    // this probably happens for pages that use wizard layout without steps, like Add Participant
    if (!steps || !stepSlug) {
      return null;
    }

    return steps.get(stepSlug);
  }

  function shouldShowBack() {
    if (!showBackButton || !steps || !stepSlug) {
      return false;
    }

    const currentStep = getCurrentStep();

    if (!currentStep || !currentStep.prev) {
      return false;
    }

    return true;
  }

  function handleBack() {
    const currentStep = getCurrentStep();

    // We should only get to this function if there are steps and if there is a .prev, but TypeScript can't see that
    if (currentStep?.prev) {
      const newPath = history.location.pathname.replace(stepSlug!, currentStep.prev);
      history.push(newPath);
    }
  }

  return (
    <div className="min-h-screen flex">
      <div className="flex-grow">
        <div className="flex flex-col">
          <AppBar position="fixed" className="left-0 w-auto shadow-none wizard-layout" color="inherit">
            <AppToolbar showSearch={false} />
            <div className="flex justify-center w-full">
              <div className="w-full md:w-2/3 py-3 pl-4">
                { shouldShowBack()
                  ? (
                    <Button onClick={handleBack} data-testid="wizardBack">
                      <ArrowBackIcon fontSize="large" />
                      <h5 className="normal-case wizard-title">{pageTitle}</h5>
                    </Button>
                  )
                  : <h5 className="normal-case wizard-title">{pageTitle}</h5>
                }
              </div>
            </div>
          </AppBar>
          <div className={`${defaultStyles.contentContainer} w-full min-h-screen flex justify-center bg-surface-dark`}>
            <div className="w-full md:w-2/3 pt-32">
              <div className="flex flex-col pt-6">
                {
                  participant
                    ? <ParticipantSubHeader participant={participant} />
                    : <></>
                }
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WizardLayout;
