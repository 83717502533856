import React, { useEffect, useState, ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { compose, nthArg } from 'ramda';

import AdministrativeBillingRow from './AdministrativeBillingRow';
import { AdminInvoice } from './administrative-billing.types';

interface AdministrativeBillingProps {
  adminInvoices: AdminInvoice[];
}

function stableSort(array: any[], comparator: any) {
  const stabilizedThis = array.map((el, index) => [ el, index ]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order: any, orderBy: any) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a: any, b: any, orderBy: any) {
  if (orderBy === 'invoiceDate' || orderBy === 'dueDate') {
    return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function AdministrativeBillingTable({ adminInvoices }: AdministrativeBillingProps): ReactElement {
  const [ page, setPage ] = useState(0);
  const [ selectedOrder, setSelectedOrder ] = useState<'desc' | 'asc' | undefined>('desc');
  const [ selectedOrderBy, setSelectedOrderBy ] = useState('invoiceDate');
  const [ sortedAdminInvoices, setSortedAdminInvoices ] = useState(adminInvoices);

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = selectedOrderBy === property && selectedOrder === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setSelectedOrder(newOrder);
    setSelectedOrderBy(property);
    setSortedAdminInvoices(stableSort(adminInvoices, getComparator(newOrder, property)));
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };

  const headCells = [
    { id: 'invoiceNumber', numeric: false, label: 'Invoice #', sortable: false },
    { id: 'invoiceDate', numeric: false, label: 'Invoice Date', sortable: true },
    { id: 'dueDate', numeric: false, label: 'Due Date', sortable: true },
    { id: 'amount', numeric: true, label: 'Amount', sortable: true },
    { id: 'paid', numeric: true, label: 'Paid', sortable: true },
    { id: 'open', numeric: true, label: 'Open', sortable: true },
    { id: 'action', numeric: false, label: 'Action', sortable: false },
  ];

  useEffect(() => {
    setSelectedOrder('desc');
    setSortedAdminInvoices(stableSort(adminInvoices, getComparator('desc', 'invoiceDate')));
  }, [ adminInvoices ]);

  return (
    <div className="w-full">
      <Table className="w-full">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                className="text-sm"
                key={headCell.id}
                align={'left'}
                sortDirection={selectedOrderBy === headCell.id ? selectedOrder : false}
              >
                <TableSortLabel
                  active={selectedOrderBy === headCell.id}
                  direction={selectedOrderBy === headCell.id ? selectedOrder : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  disabled={!headCell.sortable}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            adminInvoices.length > 0
              ? sortedAdminInvoices
                .slice(page * 10, page * 10 + 10)
                .map((invoice) => <AdministrativeBillingRow adminInvoice={invoice} key={invoice.invoiceNumber} />)
              : <TableRow><TableCell colSpan={6}><p>No administrative invoices to display.</p></TableCell></TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={10}
              rowsPerPageOptions={[ 10 ]}
              colSpan={7}
              count={adminInvoices.length}
              page={page}
              onPageChange={compose(setPage, nthArg(1))}
              SelectProps={ { native: true } }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

export default AdministrativeBillingTable;
