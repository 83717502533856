import { Dependent } from 'shared/types/non-plan-year.types';

// kind of a combination of bits of PlanYear and PlanService
export interface OpenPlan {
  unqid?: number;
  pyrid: number;
  yearbeg: string;
  yearend: string;
  medmax: number;
  depmax: number;
  plcid: number;
  plcdesc: string;
  plccode: string;
  subPlanId?: number;
  subName?: string;
  subPlanName: string;
  isBalance: boolean;
  planYearName: string;
  hasFloresHSA: boolean;
  hasOtherHSA: boolean;
}

export type PlanLevel = 10 | 11;

export const planLevelMap: Map<PlanLevel, string> = new Map([
  [ 10, 'Employee Only' ],
  [ 11, 'Family' ],
]);

export interface MRABase {
  type: 'MRA';
  partStart: string; // begin date
  partEnd: string; // end date
  partAnn: number; // annual allocation
  firstPayDateOfChange: string;
  payPeriodDeduction: number;
  totalContributions?: number;
}

export interface MRADetails extends MRABase {
  lfsa: false;
}

export interface MRADetailsWithLFSA extends MRABase {
  lfsa: true;
  planLevel?: PlanLevel;
}

export interface HSADetails {
  type: 'HSA';
  partStart: string; // begin date
  partEnd?: string; // end date
}

export interface CustomDetails {
  type: 'CUSTOM';
  partStart: string; // begin date
  partEnd: string; // end date
  partAnn?: number; // annual allocation
  currentContributions?: number;
  additionalContributions?: number;
  coveredDependents: Dependent[];
}

export type PlanDetails = MRADetails | MRADetailsWithLFSA | HSADetails | CustomDetails;

export interface PlanEnrollmentPayload {
  medplancd: string; // from companiesSlice.selectedCompany
  empno: string; // from participantSlice.selected

  // from thisSlice.plan
  pyrid: number;
  plcid: number;
  plccode: string;

  // from thisSlice.planDetails
  partStart: string;
  partEnd?: string;
  partAnn?: number;
  firstPayDateOfChange?: string;
  payPeriodDeduction?: number;
  planLevel?: PlanLevel;

  //Custom
  currentContributions?: number;
  additionalContributions?: number;
  coveredDependents?: Dependent[];
}

export interface PlanEnrollmentState {
  plan?: OpenPlan;
  planDetails?: PlanDetails;
}

export interface PlanEnrollmentFormProps {
  isEdit?: boolean;
  onNextStep: (data: OpenPlan | PlanDetails) => void;
  onCancel: () => void;
}
