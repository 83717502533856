import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { F, T, always, compose, cond, equals } from 'ramda';
import Button from '@mui/material/Button';

import PageContentPaper from 'components/layout/PageContentPaper';
import WizardStepper, { WizardStep } from 'components/shared/WizardStepper';
import CancelModal from 'components/shared/CancelModal';
import { SubmissionError } from 'components/shared/form/SubmissionError';
import { submitLeaveRequestForm } from 'shared/api/fmlaFormsApi';
import wizardSteps, { LeaveRequestStepSlug } from './enter-leave-request-steps';
import { ShowModal } from 'shared/types/modal.types';
import { LeaveRequestFormFields } from './enter-leave-request.types';
import { defaultLeaveRequestValues } from './enter-leave-request-fields';
import { validation } from './enter-leave-request.validators';
import { selectSelectedCompanyId } from 'companies/companies.slice';
import { EnterLeaveRequestPersonalDetails } from './personal-details/EnterLeaveRequestPersonalDetails';
import { EnterLeaveRequestEmploymentDetails } from './employment-details/EnterLeaveRequestEmploymentDetails';
import { EnterLeaveRequestEventInfo } from './event-info/EnterLeaveRequestEventInfo';
import { EnterLeaveRequestSummary } from './summary/EnterLeaveRequestSummary';
import EnterLeaveRequestConfirmation from './EnterLeaveRequestConfirmation';

export function EnterLeaveRequestPage(): React.ReactElement {
  const history = useHistory();
  const compid = useSelector(selectSelectedCompanyId);
  const { stepSlug: activeStepSlug = 'personal-details' } = useParams<{ stepSlug: LeaveRequestStepSlug; }>();
  const [ showModal, setShowModal ] = useState<ShowModal>('none');
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ hasSubmissionError, setHasSubmissionError ] = useState(false);

  const form = useFormik<LeaveRequestFormFields>({
    enableReinitialize: true,
    initialValues: defaultLeaveRequestValues,
    validateOnMount: true,
    validationSchema: validation.get(activeStepSlug),
    onSubmit: (data) => {
      setHasSubmissionError(false);
      setIsSubmitting(true);
      submitLeaveRequestForm(compid, data)
        .then(setModalState('success'))
        .catch(handleError)
        .finally(compose(setIsSubmitting, F));
    },
  });

  const headerText = activeStepSlug === 'summary'
    ? 'Are you sure you want to submit this leave of absence?'
    : 'Please use the form below to enter a new leave of absence or accommodation request.';

  const submitText = activeStepSlug === 'summary'
    ? 'Submit Leave Request'
    : 'Next Step';

  const handleError = compose(setHasSubmissionError, T);

  function onNext() {
    const step = wizardSteps.get(activeStepSlug);

    if (step?.slug === 'summary') {
      form.submitForm();
    } else {
      history.push(`/enter-leave-request/${step?.next}`);
    }
  }

  function handleStepClick(step: WizardStep) {
    history.push(`/enter-leave-request/${step.slug}`);
  }

  function goToDashboard() {
    setShowModal('none');
    history.push('/dashboard');
  }

  function enterNewLeaveRequest() {
    setShowModal('none');
    form.resetForm();
    history.push(`/report-an-absence/personal-details/?eeId=${form.values.employee.eeId}`);
  }

  const setModalState = (modal: ShowModal) => () => {
    setShowModal(modal);
  };

  return (
    <>
      <PageContentPaper className="mt-4 mb-8 px-8" data-testid="enter-leave-request-page">
        <div className="mb-8">
          <WizardStepper
            steps={wizardSteps}
            allowClick
            onChange={handleStepClick}
          />
        </div>
        <p className="mb-12" data-testid="header-text">{headerText}</p>
        <FormikProvider value={form}>
          {
            cond<LeaveRequestStepSlug, JSX.Element>([
              [
                equals<LeaveRequestStepSlug>('personal-details'),
                always(
                  <EnterLeaveRequestPersonalDetails />,
                ),
              ],
              [
                equals<LeaveRequestStepSlug>('employment-details'),
                always(
                  <EnterLeaveRequestEmploymentDetails />,
                ),
              ],
              [
                equals<LeaveRequestStepSlug>('event-info'),
                always(
                  <EnterLeaveRequestEventInfo />,
                ),
              ],
              [
                equals<LeaveRequestStepSlug>('summary'),
                always(<EnterLeaveRequestSummary />),
              ],
            ])(activeStepSlug)
          }
          {
            hasSubmissionError && <SubmissionError />
          }
          <div className="flex justify-end my-4">
            <Button
              color="primary"
              type="button"
              data-testid="btn-cancel"
              onClick={setModalState('cancel')}
            >
                Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              data-testid="btn-next-step"
              className="ml-4"
              type="button"
              disabled={!form.isValid || isSubmitting}
              onClick={onNext}
            >
              {submitText}
            </Button>
          </div>
        </FormikProvider>
      </PageContentPaper>
      <CancelModal
        isOpen={showModal === 'cancel'}
        onCancel={goToDashboard}
        onClose={setModalState('none')}
      />
      <EnterLeaveRequestConfirmation
        isOpen={showModal === 'success'}
        onClose={goToDashboard}
        onGoToDash={goToDashboard}
        onNewLeaveRequest={enterNewLeaveRequest}
      />
    </>
  );
}
