import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { compose } from 'redux';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { isNil } from 'ramda';

import { Debit } from './direct-debit.types';
import withModal from 'components/shared/HOC/WithModal';
import { validationSchema } from './perform-direct-debit.validators';
import { performDirectDebit } from 'shared/api/directDebitApi';
import { selectSelectedCompany } from 'companies/companies.slice';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { currencyFormatter } from 'shared/utils';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import DirectDebitAccountSelect from 'components/shared/DirectDebitAccountSelect';
import store from 'store';
import {
  selectDirectDebitAccounts,
  selectFetchDirectDebitAccountStatus,
} from 'store/slices/direct-debit/direct-debit.slice';
import { fetchDirectDebitAccount } from 'store/slices/direct-debit/direct-debit.thunk';

function formatDate(date?: string): string {
  return date ? dayjs(date).format('MM/DD/YYYY') : '';
}

interface DirectDebitConfirmationProps {
  debit?: Debit;
  onCancel: () => void;
}

function DirectDebitConfirmation({
  onCancel,
  debit,
}: DirectDebitConfirmationProps): ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [ paying, setPaying ] = useState(false);
  const { compid: companyId } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const handleError = compose(
    onCancel,
    dispatch,
    addHttpErrorNotification,
  );
  const directDebitAccounts = useSelector(selectDirectDebitAccounts);
  const directDebitFetchStatus = useSelector(selectFetchDirectDebitAccountStatus);
  useEffect(() => {
    if (directDebitFetchStatus === 'uninitialized') {
      dispatch(fetchDirectDebitAccount());
    }
  }, [ dispatch, directDebitFetchStatus ]);
  const form = useFormik({
    initialValues: {
      cddid: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (debit) {
        setPaying(true);
        performDirectDebit(companyId, { ...debit, cddid: parseInt(values.cddid) })
          .catch(handleError);
        debit.status = 'In Progress';
        setPaying(false);
        onCancel();
      }
    },
  });

  function getCddComment(id: string): string {
    if (directDebitAccounts !== null && directDebitAccounts.length > 0) {
      return directDebitAccounts.find(i => i.cddid === parseInt(id))?.cddcomment ?? '';
    }

    return '';
  }

  const prompt = `Confirm in order to perform a direct debit from ${getCddComment(form.values.cddid)} for
     the ${formatDate(debit?.paydate)} pay date for a total of ${currencyFormatter.format(debit?.amount ?? 0)}.`;

  const DirectDebitAccountSelectWithLoading = withLoopIcon(
    DirectDebitAccountSelect,
    'Loading accounts...',
    directDebitAccounts === null,
  );

  function handleChange(val: string) {
    form.setFieldValue('cddid', val);
  }

  return (
    <div>
      <p className="py-5" data-testid="direct-debit-confirmation-modal-prompt">
        {prompt}
      </p>
      <form onSubmit={form.handleSubmit} className="mt-4">
        <DirectDebitAccountSelectWithLoading
          isRequired
          label="Account to Perform Debit"
          accounts={directDebitAccounts}
          name="cddid"
          value={form.values.cddid.toString()}
          error={form.touched.cddid && !!form.errors.cddid}
          onAccountChanged={handleChange}
        />
        <div className="flex justify-end mt-4">
          <Button
            data-testid="direct-debit-confirmation-modal-cancel"
            onClick={onCancel}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            data-testid="direct-debit-confirmation-modal-confirm"
            variant="contained"
            color="primary"
            className={`ml-2 ${paying && 'animate-ping'}`}
            type="submit"
            disabled={isNil(debit) || !form.values.cddid || paying}
          >
            Perform Direct Debit
          </Button>
        </div>
      </form>
    </div>
  );
}

export const DirectDebitConfirmationModal = withModal(DirectDebitConfirmation);

export default DirectDebitConfirmation;
