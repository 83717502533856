import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { LeaveRecordCaseSummaryAndName } from 'leave-records/leaveRecord.types';
import { valueOrPlaceholder, dateOrPlaceholder } from 'shared/utils';
import { avatarColor, getInitials } from 'components/shared/avatar-color-palette';
import { DateFormat_M_D_YYYY } from 'shared/consts/date-time.consts';

interface Props {
  caseSummary: LeaveRecordCaseSummaryAndName;
  onViewEdit: () => void;
  showFullName?: boolean;
}

export function LeaveRecordsTableRow({ caseSummary, onViewEdit, showFullName = false }: Readonly<Props>) {
  const placeholder = '-';

  return (
    <TableRow
      className="group hover:bg-zinc-100 hover:cursor-pointer"
      key={`case-${caseSummary.caseListId}`}
      data-testid={`case-${caseSummary.caseListId}`}
      onClick={onViewEdit}
    >
      {showFullName && (
        <TableCell className="text-sm">
          <div className="flex flex-row">
            <Avatar
              className={`p-2 w-10 text-sm ${avatarColor(caseSummary.firstName ?? '', caseSummary.lastName ?? '')}`}
            >
              {getInitials(caseSummary.firstName ?? '', caseSummary.lastName ?? '')}
            </Avatar>
            <p className="whitespace-nowrap ml-5 self-center">
              {caseSummary.firstName ?? ''} {caseSummary.lastName ?? ''}
            </p>
          </div>
        </TableCell>
      )}
      <TableCell className="text-sm">{valueOrPlaceholder(caseSummary.title, placeholder)}</TableCell>
      <TableCell className="text-sm">
        {dateOrPlaceholder(caseSummary.startDate, DateFormat_M_D_YYYY, placeholder)}
      </TableCell>
      <TableCell className="text-sm">
        {dateOrPlaceholder(caseSummary.endDate, DateFormat_M_D_YYYY, placeholder)}
      </TableCell>
      <TableCell className="text-sm">{valueOrPlaceholder(caseSummary.reasonForLeaveNrR, placeholder)}</TableCell>
      <TableCell className="text-sm">
        <Button
          variant="text"
          className="text-sm text-black invisible group-hover:visible"
          data-testid={`case-${caseSummary.caseListId}-view-button`}
          startIcon={<EditIcon />}
        >
          View/Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}
