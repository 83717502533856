import React from 'react';

import { Company } from './company.types';
import CompanyTile from './CompanyTile';

interface CompanyListProps {
  companies: Company[];
  onSelected: (c: Company) => void;
  className?: string;
}

function CompanyList({ companies, onSelected, className = '' }: CompanyListProps): React.ReactElement {
  function handleSelect(company: Company) {
    onSelected(company);
  }

  return (
    <div className={`-m-4 flex flex-wrap ${className}`}>
      {companies.map((c) => <CompanyTile company={c} onSelected={handleSelect} key={c.compid} />)}
    </div>
  );
}

export default CompanyList;
