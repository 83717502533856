import React from 'react';
import { useSelector } from 'react-redux';

import { selectSelectedCompany } from 'companies/companies.slice';
import AccountManagerTile from 'account-manager-tile/AccountManagerTile';
import ReportsTile from 'reports-tile/ReportsTile';
import ActionItems from 'action-items/ActionItems';
import EmblaCarousel from 'marketing-info-tile/EmblaCarousel';

function Dashboard(): React.ReactElement {
  const company = useSelector(selectSelectedCompany);

  return (
    <div data-testid="dashboard" className="p-4 flex flex-wrap basis-full">
      <div className="xl:w-2/3 sm:w-full ">
        <div className="">
          <ReportsTile />
        </div>
        <div className="mt-2">
          <ActionItems />
        </div>
      </div>

      <div className="flex xl:w-1/3 xl:pl-4 xl:mt-0 sm:w-full sm:mt-4 space-y-5 flex-col ">
        <div className="xl:w-full sm:w-1/2 ">
          <EmblaCarousel />
        </div>
        {
          !company?.isFmlaOnly &&
            <div className="xl:w-full sm:w-1/2 " data-testid="am-card">
              <AccountManagerTile
                agentName={company?.fullname}
                email={company?.emailaddress}
                title="If you are in need of further assistance, please contact"
                phone="800.532.3327"
              />
            </div>
        }
        {
          company?.hasFmla &&
          <div className="xl:w-full sm:w-1/2 " data-testid="ls-card">
            <AccountManagerTile
              agentName={company.fmlaConsultant?.name}
              email={company.fmlaConsultant?.email}
              title="Your Dedicated Leave Solutions Consultant"
              phone={company.fmlaConsultant?.phone}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default Dashboard;
