import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeCobraPlanOnDeckPlanLevel,
  setCobraPlanOnDeckPlanRate,
  selectIsOnDeckPlanNew,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import TextField from '@mui/material/TextField';
import React from 'react';
import store from 'store';
import { always, assoc, compose, pathOr } from 'ramda';
import Button from '@mui/material/Button';
import { PlanLevel } from 're-enrollment-checklist/re-enrollment-checklist.types';

interface Props {
    plans: PlanLevel[];
    disabled?: boolean;
}

function RatesTable({ plans, disabled = false }: Props) {
  const isNewPlan = useSelector(selectIsOnDeckPlanNew);
  const dispatch = useDispatch<typeof store.dispatch>();
  const handleOnChange = (plan: PlanLevel) => compose(
    dispatch,
    setCobraPlanOnDeckPlanRate,
    (e) => assoc('levelCost', e, plan),
    pathOr(0, [ 'target', 'value' ]),
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Coverage</TableCell>
              <TableCell>Monthly Rate (without 2%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((plan) => (
              <TableRow
                key={plan.levelCoverageTier}
              >
                <TableCell component="th" scope="row">
                  {plan.levelCoverageTier}
                </TableCell>
                <TableCell align="right">
                  <div className="flex">
                    <span style={ { alignSelf: 'center' } }>$</span>
                    <TextField
                      name={`${plan.levelCoverageTier}-input`}
                      className="ml-1"
                      variant="standard"
                      type="number"
                      error={plan.levelCost < 0}
                      helperText={plan.levelCost < 0 && 'Cost must be a positive number'}
                      data-testid={`${plan.levelCoverageTier}-input`}
                      value={plan.levelCost || ''}
                      onChange={handleOnChange(plan)}
                      disabled={disabled}
                    />
                    {
                      (isNewPlan && !disabled) && (
                        <Button
                          variant="text"
                          onClick={compose(
                            dispatch,
                            removeCobraPlanOnDeckPlanLevel,
                            always(plan.levelCoverageTier),
                          )}
                        > X
                        </Button>
                      )
                    }
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default RatesTable;
