import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { always, anyPass } from 'ramda';
import { RootState } from 'store/rootReducer';
import { fetchDirectBillRateHistory, fetchRateHistory } from 'rates-history/rates-history.thunk';
import { HistoricalPlanWithRates, PlanYear } from 'rates-history/rates-history.types';
import { ApiStatus } from 'shared/types/api-status.types';

export interface RatesHistorySlice {
    fetchCobraRatesHistoryStatus: ApiStatus;
    cobraPlans: HistoricalPlanWithRates[];
    cobraPlanYears: PlanYear[];
    fetchDirectBillRatesHistoryStatus: ApiStatus;
    directBillPlans: HistoricalPlanWithRates[];
    directBillPlanYears: PlanYear[];
    filteredCobraPlanYearId: number | null;
    filteredDirectBillPlanYearId: number | null;
}

export const initialState: RatesHistorySlice = {
  fetchCobraRatesHistoryStatus: 'uninitialized',
  cobraPlans: [],
  cobraPlanYears: [],
  filteredCobraPlanYearId: null,
  fetchDirectBillRatesHistoryStatus: 'uninitialized',
  directBillPlans: [],
  directBillPlanYears: [],
  filteredDirectBillPlanYearId: null,
};

const ratesHistorySlice = createSlice({
  name: 'ratesHistory',
  initialState,
  reducers: {
    cobraPlanYearFilterChanged(state, { payload }: PayloadAction<number>) {
      state.filteredCobraPlanYearId = payload;
    },
    directBillPlanYearFilterChanged(state, { payload }: PayloadAction<number>) {
      state.filteredDirectBillPlanYearId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRateHistory.pending, (state) => {
        state.fetchCobraRatesHistoryStatus = 'pending';
      })
      .addCase(fetchRateHistory.rejected, (state) => {
        state.fetchCobraRatesHistoryStatus = 'rejected';
      })
      .addCase(fetchRateHistory.fulfilled, (state, action) => {
        state.fetchCobraRatesHistoryStatus = 'fulfilled';
        state.cobraPlans = action.payload.plans.map(p => ({ ...p, planType: 'cobra' }));
        state.cobraPlanYears = action.payload.planYears;
      })
      .addCase(fetchDirectBillRateHistory.pending, (state) => {
        state.fetchDirectBillRatesHistoryStatus = 'pending';
      })
      .addCase(fetchDirectBillRateHistory.rejected, (state) => {
        state.fetchDirectBillRatesHistoryStatus = 'rejected';
      })
      .addCase(fetchDirectBillRateHistory.fulfilled, (state, action) => {
        state.fetchDirectBillRatesHistoryStatus = 'fulfilled';
        state.directBillPlans = action.payload.plans.map(p => ({ ...p, planType: 'directBill' }));
        state.directBillPlanYears = action.payload.planYears;
      });
  },
});

export const {
  cobraPlanYearFilterChanged,
  directBillPlanYearFilterChanged,
} = ratesHistorySlice.actions;

export default ratesHistorySlice.reducer;

export const selectFetchCobraRatesHistoryStatus = (state: RootState) => {
  return state.historicalRates.fetchCobraRatesHistoryStatus;
};

export const selectFetchDirectBillRatesHistoryStatus = (state: RootState) => {
  return state.historicalRates.fetchDirectBillRatesHistoryStatus;
};

export const selectCobraHistoricalPlanYears = (state: RootState) => {
  return state.historicalRates.cobraPlanYears;
};

export const selectDirectBillHistoricalPlanYears = (state: RootState) => {
  return state.historicalRates.directBillPlanYears;
};

export const selectFilteredCobraPlanYear = (state: RootState) => {
  return state
    .historicalRates
    .cobraPlanYears
    .find(planYear => planYear.planYearId === state.historicalRates.filteredCobraPlanYearId) ?? null;
};

export const selectFilteredDirectBillPlanYear = (state: RootState) => {
  return state
    .historicalRates
    .directBillPlanYears
    .find(planYear => planYear.planYearId === state.historicalRates.filteredDirectBillPlanYearId) ?? null;
};

const removeNonMatchingPlanYears = (filteredPlanYear: PlanYear | null) => (
  acc: HistoricalPlanWithRates[],
  cur: HistoricalPlanWithRates,
) => {
  const coverageTiers = cur.coverageTiers.map(t => ({
    ...t,
    tierRates: t.tierRates.filter(r => anyPass([
      always(filteredPlanYear === null),
      always(
        dayjs(r.rateBeginDate).isBefore(dayjs(filteredPlanYear?.planYearEnd))
          && dayjs(r.rateEndDate).isAfter(dayjs(filteredPlanYear?.planYearStart))),
    ])()),
  }));

  return [ ...acc, { ...cur, coverageTiers }];
};

export const selectHistoricalCobraPlans = (state: RootState) => {
  const filteredPlanYear = selectFilteredCobraPlanYear(state);

  return state
    .historicalRates
    .cobraPlans
    .reduce(removeNonMatchingPlanYears(filteredPlanYear), []);
};

export const selectHistoricalDirectBillPlans = (state: RootState) => {
  const filteredPlanYear = selectFilteredDirectBillPlanYear(state);

  return state
    .historicalRates
    .directBillPlans
    .reduce(removeNonMatchingPlanYears(filteredPlanYear), []);
};
