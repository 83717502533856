import {okResponse} from "./utils";

function routing(server) {
    server.get('/responsibilities', (schema) => {
        const { models } = schema.responsibilities.all();
        const data = models.map((m) => m.attrs);
        return okResponse(data)();
    });
}

export default routing;
