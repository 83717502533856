import React from 'react';
import PageContentPaper from 'components/layout/PageContentPaper';

import AdministrativeBillingTable from './AdministrativeBillingTable';
import { useAdministrativeBillingInvoices } from './hooks/useAdministrativeBillingInvoices';
import Loading from 'components/shared/Loading';

function AdministrativeBilling(): React.ReactElement {
  const adminInvoices = useAdministrativeBillingInvoices();

  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">Administrative Billing</h2>
      <PageContentPaper className="flex flex-col">
        <div className="w-full flex">
          {
            adminInvoices !== null
              ? <AdministrativeBillingTable adminInvoices={adminInvoices} />
              : <Loading text="Loading administrative invoices..." />
          }
        </div>
      </PageContentPaper>
    </div>
  );
}

export default AdministrativeBilling;
