import request from '../api-request';
import { PlanEnrollmentPayload } from 'plan-enrollment/plan-enrollment.types';
import { CobraEnrollment, Enrollment } from 'participant-overview/enrollment/enrollment.types';
import { EnrollmentType } from 'participant-overview/participant-overview.types';

export function getEnrollments(companyId: number, employeeNumber: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load enrollments for invalid company'));
  }

  if (employeeNumber.trim().length === 0) {
    return Promise.reject(new Error('Cannot load enrollments for invalid employee'));
  }

  const uri = `/api/companies/${companyId}/plan-enrollment/enrollments/${employeeNumber.trim()}`;

  return request<Enrollment[]>(uri);
}

export function saveEnrollment(companyId: number, savePayload: Partial<PlanEnrollmentPayload>) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot save enrollment for invalid company'));
  }

  const uri = `/api/companies/${companyId}/plan-enrollment`;

  return request(uri, { method: 'POST', body: savePayload });
}

export function updateEnrollment(companyId: number, savePayload: Partial<PlanEnrollmentPayload>) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot update enrollment for invalid company'));
  }

  const uri = `/api/companies/${companyId}/plan-enrollment`;

  return request(uri, { method: 'PUT', body: savePayload });
}

export function deleteEnrollment(
  companyId: number,
  planYearId: number,
  planCode: string,
  employeeNumber: string,
  customPlanId?: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot delete enrollment for invalid company'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot delete enrollment for invalid plan year'));
  }

  if (planCode.trim().length === 0) {
    return Promise.reject(new Error('Cannot delete enrollment for invalid plan code'));
  }

  if (employeeNumber.trim().length === 0) {
    return Promise.reject(new Error('Cannot delete enrollment for invalid employee'));
  }

  const uri = `/api/companies/${companyId}/plan-enrollment/${planYearId}/`
    + `${planCode.trim()}/participant/${employeeNumber.trim()}`
    + (customPlanId ? `/custom/${customPlanId}` : '');

  return request(uri, { method: 'DELETE' });
}

function uriMap(strings: TemplateStringsArray, compid: number, type: EnrollmentType, empno: string) {
  let subpath = '';

  switch (type) {
  case EnrollmentType.COBRA:
    subpath = 'get-cobra-enrollments';
    break;
  case EnrollmentType.Retiree:
    subpath = 'get-retiree-enrollments';
    break;
  case EnrollmentType.LOA:
    subpath = 'get-loa-enrollments';
    break;
  case EnrollmentType.Regular:
    subpath = 'enrollments';
    break;
  }

  return `${strings[0]}${compid}${strings[1]}${subpath}${strings[2]}${empno}`;
}

export function getEnrollmentByType(companyId: number, employeeNumber: string, type: EnrollmentType) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load enrollments for invalid company'));
  }

  if (employeeNumber.trim().length === 0) {
    return Promise.reject(new Error('Cannot load enrollments for invalid employee'));
  }

  const uri = uriMap`/api/companies/${companyId}/plan-enrollment/${type}/${employeeNumber}`;

  return request<CobraEnrollment[]>(uri);
}
