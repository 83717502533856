import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Slide } from 'marketing-info-tile/marketing-info-tile.types';
import { RootState } from 'store/rootReducer';
import { fetchSlides } from 'marketing-info-tile/slideshow.thunks';
import { ApiStatus } from 'shared/types/api-status.types';

export interface SlideShowState {
  slides: Slide[];
  fetchSlidesStatus: ApiStatus;
}

export const initialState: SlideShowState = {
  slides: [],
  fetchSlidesStatus: 'uninitialized',
};

const slideshowSlice = createSlice({
  name: 'slideshow',
  initialState,
  reducers: {
    setSlides(state, { payload }: PayloadAction<Slide[]>) {
      state.slides = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlides.pending, (state) => {
        state.fetchSlidesStatus = 'pending';
      })
      .addCase(fetchSlides.rejected, (state) => {
        state.fetchSlidesStatus = 'rejected';
      })
      .addCase(fetchSlides.fulfilled, (state, action) => {
        state.slides = action.payload ?? [];
        state.fetchSlidesStatus = 'fulfilled';
      });
  },
});

export const {
  setSlides,
} = slideshowSlice.actions;

export default slideshowSlice.reducer;

// selectors
export const selectSlides = (state: RootState) => state.slideshow.slides;

export const selectFetchSlidesStatus = (state: RootState) => state.slideshow.fetchSlidesStatus;
