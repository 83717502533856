import request, { ContentType } from 'shared/api-request';
import { validateDate } from './validateDate';
import queryStringTag from './reportQueryStringTag';
import dayjs from 'dayjs';

export function downloadActiveWithAddressReport(
  companyId: number,
  serviceType: string,
  startDate?: string,
  endDate?: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download active with address report for invalid company.'));
  }

  try {
    const start = validateDate(
      'Cannot download active with address report for invalid start date.',
      startDate ?? dayjs().format(),
    );
    const end = validateDate(
      'Cannot download active with address report for invalid end date.',
      endDate ?? dayjs().format(),
    );

    // eslint-disable-next-line max-len
    const uri = queryStringTag`/api/companies/${companyId}/reports/active-participants/${serviceType}/with-address/export${start}${end}`;

    return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (err) {
    return Promise.reject(err);
  }
}
