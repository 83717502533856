import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies', (schema) => {
    const { models } = schema.companies.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:id', (schema, request) => {
    const { attrs: data } = schema.companies.findBy({ compid: parseInt(request.params.id) });
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:id/non-plan-year-services', (schema, request) => {
    const { attrs: data } = schema.nonPlanYearServices.findBy({ compid: parseInt(request.params.id) });
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:id/locations', (schema, request) => {
    const { models: data } = schema.locations.where({ compid: parseInt(request.params.id) });
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:id/payfreqs', (schema) => {
    const { models: data } = schema.payfreqs.all();
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/deduction-dates', (schema) => {
    const { models: data } = schema.deductionDates.all();
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.post('/companies/:companyId/client-log', (schema) => {
    return new Response(204, { 'content-length': 0 });
  });
}

export default routing;
