import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';

import ReportsTile from '../../shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useSelector } from 'react-redux';
import {
  downloadNoResponseWithAddressReport,
} from 'shared/api/noResponseWithAddressApi';
import { useNoResponseWithAddressCount } from '../hooks/useNoResponseWithAddressCount';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

function NoResponseWithAddress() {
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const count = useNoResponseWithAddressCount();

  function handleExport() {
    startDownloading();
    downloadNoResponseWithAddressReport(compid)
      .then(doneDownloading)
      .catch(handleError);
  }

  return (
    <ReportsTile
      count={count}
      title="No Response With Address"
      description="List of participants with an open election
and their address used to mail the QE. Includes response deadline."
      variant="primary-main"
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default NoResponseWithAddress;
