import dayjs from 'dayjs';
import { FormikHelpers, FormikValues, getIn } from 'formik';

/**
 * Takes the typical format of our datepicker output and converts it to our DB format
 */
export function handleDateChange<T>(field: string, form: FormikHelpers<T>) {
  return (event: any) => {
    form.setFieldValue(field, event ? dayjs(event, 'MM/DD/YYYY').format('YYYY-MM-DD') : null);
  };
}

/**
 * Plucks the value off of a Material Select menu event
 * @param field
 * @param form
 * @returns (string) => void
 */
export function handleSelectChange<T>(field: string, form: FormikHelpers<T>) {
  return (event: any) => {
    form.setFieldValue(field, event.target.value);
  };
}

/**
 * Useful for custom controls like PayFreqSelect or PayrollIdParticipantLookupInput
 * @param field
 * @param form
 * @returns (string) => void
 */
export function handleCustomFieldChange<T>(field: string, form: FormikHelpers<T>) {
  return (value: string) => form.setFieldValue(field, value);
}

/**
 *
 * @param data the data you want to map to options
 * @param labelProp the property on the data that has the label string
 * @param valueProp the property on the data that has the value
 */
export function mapToSelectOption<T>(data: T[], labelProp: keyof T, valueProp: keyof T) {
  return data.map((d) => ({
    label: d[labelProp] + '',
    value: d[valueProp] + '',
  }));
}

/**
 * @param path path to the Formik key to validate
 * @param form object returned from useFormik and useFormikContext hooks
 * @returns boolean
 */
export function formikFieldHasError(path: string, form: FormikValues) {
  return Boolean(formikFieldErrorText(path, form));
}

/**
 * @param path path to the Formik key to validate
 * @param form object returned from useFormik and useFormikContext hooks
 * @returns string
 */
export function formikFieldErrorText(path: string, form: FormikValues) {
  return getIn(form.touched, path) && getIn(form.errors, path);
}
