import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import healthCareFSAGuide from 'images/health-care-fsa-guide.svg';
import hraGuide from 'images/hra-guide.svg';
import floresBenefitsMastercard from 'images/flores-benefits-mastercard.svg';
import floresMobile from 'images/flores-mobile.svg';
import dependentCareFSAGuide from 'images/dependent-care-fsa-guide.svg';
import noWaitDependentCareFSA from 'images/no-wait-dependent-care-fsa.svg';
import commuterBenefitAccountGuide from 'images/commuter-benefit-account-guide.svg';
import limitedPurposeFSAGuide from 'images/limited-purpose-fsa-guide.svg';
import fsaChecklist from 'images/reenroll-fsa-checklist.svg';
import commuterChecklist from 'images/reenroll-commuter-checklist.svg';
import hraChecklist from 'images/reenroll-hra-checklist.svg';
import cobraChecklist from 'images/reenroll-cobra-checklist.svg';
import hsaChecklist from 'images/reenroll-hsa-checklist.svg';
import directBillChecklist from 'images/reenroll-direct-bill-checklist.svg';
import hsaGuide from 'images/hsa-guide.svg';
import hsaFAQ from 'images/hsa-faq.svg';

import GridCard from 'components/shared/GridCard';
import FsaEnrollmentTemplateCard from './FsaEnrollmentTemplateCard';
import CustomEnrollmentCard from './CustomEnrollmentCard';
import { selectEnrollmentResourcePlans } from 'open-enrollment-resources/open-enrollment-resources.slice';

function EnrollmentSection({ plan }: { plan: string; }) {
  const plans = useSelector(selectEnrollmentResourcePlans);

  switch (plan) {
  case 'Health Care FSA':
    return (
      <Grid container spacing={3}>
        <GridCard
          src={fsaChecklist}
          name="FSA Re-Enrollment Checklist"
          href="https://www.flores-associates.com/fsaforms/reenroll-FSA-checklist.pdf"
        />
        <FsaEnrollmentTemplateCard />
        <GridCard
          src={healthCareFSAGuide}
          name="Health Care FSA Guide"
          href="https://www.flores-associates.com/fsaforms/MRAGuide.pdf"
        />
        <GridCard
          src={floresBenefitsMastercard}
          name="Flores Benefits Mastercard"
          href="https://www.flores-associates.com/fsaforms/DebitCard.pdf"
        />
        <GridCard
          src={floresMobile}
          name="Flores Mobile"
          href="https://www.flores-associates.com/fsaforms/floresmobileflyer.pdf"
        />
      </Grid>
    );
  case 'Dependent Care FSA':
    return (
      <Grid container spacing={3}>
        <GridCard
          src={noWaitDependentCareFSA}
          name="FSA Re-Enrollment Checklist"
          href="https://www.flores-associates.com/fsaforms/reenroll-FSA-checklist.pdf"
        />
        <GridCard
          src={dependentCareFSAGuide}
          name="Dependent Care FSA Guide"
          href="https://www.flores-associates.com/fsaforms/DCRAGuide.pdf"
        />
        <GridCard
          src={noWaitDependentCareFSA}
          name="No-Wait Dependent Care FSA"
          href="https://www.flores-associates.com/fsaforms/NoWaitGuide.pdf"
        />
      </Grid>
    );
  case 'Commuter Benefit Account':
    return (
      <Grid container spacing={3}>
        <GridCard
          src={commuterChecklist}
          name="Commuter Re-Enrollment Checklist"
          href="https://www.flores-associates.com/fsaforms/reenroll-Commuter-checklist.pdf"
        />
        <GridCard
          src={commuterBenefitAccountGuide}
          name="Commuter Benefit Account Guide"
          href="https://www.flores-associates.com/fsaforms/qte.pdf"
        />
      </Grid>
    );
  case 'Limited-Purpose FSA':
    return (
      <Grid container spacing={3}>
        <GridCard
          src={limitedPurposeFSAGuide}
          name="Limited Purpose FSA Guide"
          href="https://www.flores-associates.com/fsaforms/LimitedFSA.pdf"
        />
      </Grid>
    );
  case 'Health Reimbursement Account':
    return (
      <Grid container spacing={3}>
        <GridCard
          src={hraChecklist}
          name="HRA Re-Enrollment Checklist"
          href="https://www.flores-associates.com/fsaforms/reenroll-HRA-checklist.pdf"
        />
        <GridCard
          src={hraGuide}
          name="HRA Guide"
          href="https://www.flores-associates.com/fsaforms/HRA_Guide.pdf"
        />
      </Grid>
    );
  case 'Custom Reimbursement Account':
    return (
      <Grid container spacing={3}>
        {
          plans
            ?.filter((customPlan) => !!customPlan.subPlans?.length)
            .map((customPlan) => {
              return customPlan.subPlans!.map((subPlan, idx) => (
                <CustomEnrollmentCard
                  key={`${customPlan.planYearId}-${customPlan.planId}-${subPlan.pyrPlansId}`}
                  subPlanIndex={idx}
                  plan={customPlan}
                />
              ));
            })
        }
      </Grid>
    );
  case 'COBRA':
    return (
      <Grid container spacing={3}>
        <GridCard
          src={cobraChecklist}
          name="COBRA Re-Enrollment Checklist"
          href="https://www.flores-associates.com/fsaforms/reenroll-COBRA-checklist.pdf"
        />
        <GridCard
          src={cobraChecklist}
          name="COBRA OE Mailing Checklist (optional)"
          href="https://www.flores-associates.com/fsaforms/oe-COBRA-mailing.pdf"
        />
        <GridCard
          src={cobraChecklist}
          name="COBRA Rate and Carrier Sheet"
          href="https://www.flores-associates.com/fsaforms/COBRARates.pdf"
        />
      </Grid>
    );
  case 'Direct Bill':
    return (
      <Grid container spacing={3}>
        <GridCard
          src={directBillChecklist}
          name="Direct Bill Re-Enrollment Checklist"
          href="https://www.flores-associates.com/fsaforms/reenroll-Direct-Bill-checklist.pdf"
        />
      </Grid>
    );
  default:
    return (
      <Grid container spacing={3}>
        <GridCard
          src={hsaChecklist}
          name="HSA Re-Enrollment Checklist"
          href="https://www.flores-associates.com/fsaforms/reenroll-HSA-checklist.pdf"
        />
        <GridCard
          src={hsaGuide}
          name="HSA Guide"
          href="https://www.flores-associates.com/fsaforms/HSA_Guide.pdf"
        />
        <GridCard
          src={hsaFAQ}
          name="HSA FAQ"
          href="https://www.flores-associates.com/fsaforms/HSA_FAQ.pdf?pdf=HSA-FAQ"
        />
        <GridCard
          src={floresBenefitsMastercard}
          name="Flores Benefits Mastercard"
          href="https://www.flores-associates.com/fsaforms/DebitCard.pdf"
        />
      </Grid>
    );
  }
}

export default EnrollmentSection;
