import { useState, useRef, useEffect } from 'react';
import { compose, tap, always } from 'ramda';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { QeBenefits } from '../cobra-qe.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { getCobraAvailablePlans } from 'shared/api/cobraQeApi';

export function useCobraPlans(effectiveDate?: string | dayjs.Dayjs) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ plans, setPlans ] = useState<QeBenefits[] | null>(null);

  const { current: abortCtrl } = useRef(new AbortController());
  const { current: setDefault } = useRef(compose(setPlans, always([])));
  const { current: handleError } = useRef(compose(
    tap(setDefault),
    useErrorNotifier(),
  ));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    getCobraAvailablePlans(compid, effectiveDate ? effectiveDate : '')
      .then((result) => {
        if (mountedRef.current) {
          setPlans(result);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
      abortCtrl.abort();
    };
  }, [ compid, handleError, abortCtrl, effectiveDate ]);

  return plans;
}
