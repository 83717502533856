import { useState } from 'react';
import dayjs from 'dayjs';

import PageContentPaper from 'components/layout/PageContentPaper';

import ReportDateRangeFilter from 'reports/shared/ReportDateRangeFilter';
import { useCobraReportCounts } from './hooks/useCobraReportCounts';

import InitialNoticeSent from './tiles/InitialNoticeSent';
import NoResponseWithAddress from './tiles/NoResponseWithAddress';

import PendingResponses from '../nonPlanYearServices/tiles/PendingResponses';
import ActiveParticipants from '../nonPlanYearServices/tiles/ActiveParticipants';
import PendingNotices from '../nonPlanYearServices/tiles/PendingNotices';
import CurrentActive from '../nonPlanYearServices/tiles/CurrentActive';
import NoticesMailed from '../nonPlanYearServices/tiles/NoticesMailed';
import PaymentsReceived from '../nonPlanYearServices/tiles/PaymentsReceived';
import MonthlyRecap from '../nonPlanYearServices/tiles/MonthlyRecap';
import AcceptanceReceived from '../nonPlanYearServices/tiles/AcceptanceReceived';
import InvoiceStatus from '../nonPlanYearServices/tiles/InvoiceStatus';
import ActiveWithAddress from '../nonPlanYearServices/tiles/ActiveWithAddress';
import TerminatedParticipants from '../nonPlanYearServices/tiles/TerminatedParticipants';
import Ten95CData from '../nonPlanYearServices/tiles/Ten95CData';

function COBRAReports() {
  const [ startDate, setStartDate ] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [ endDate, setEndDate ] = useState<string>(dayjs().format('YYYY-MM-DD'));

  const counts = useCobraReportCounts(startDate, endDate);

  function handleApply(start: string, end: string) {
    setStartDate(start);
    setEndDate(end);
  }

  return (
    <div className="w-full flex space-x-8">
      <PageContentPaper className="w-3/4">
        <div className="space-y-4">
          <div className="w-full">
            <ReportDateRangeFilter onFilterChange={handleApply} />
          </div>
          <div className="grid grid-flow-row grid-cols-3 grid-rows-4 gap-8">
            <NoticesMailed
              serviceType="cobra"
              count={counts?.noticesMailed ?? null}
              startDate={startDate}
              endDate={endDate}
            />
            <PaymentsReceived
              startDate={startDate}
              endDate={endDate}
              count={counts?.paymentsReceived ?? null}
              reportType="cobra"
            />
            <CurrentActive
              serviceType="cobra"
              description="This report looks for participants in the system during the date range
entered and will provide you with detail regarding those participants who elected coverage within the date range."
              count={counts?.currentActive ?? null}
              startDate={startDate}
              endDate={endDate}
            />
            <MonthlyRecap
              count={counts?.monthlyRecap ?? null}
              serviceType="cobra"
              startDate={startDate}
              endDate={endDate}
            />
            <AcceptanceReceived
              serviceType="cobra"
              description="List of participants that have accepted COBRA coverage
and what plans they elected.Please note this does not necessarily mean that the participant has paid,
but they have elected COBRA coverage."
              count={counts?.acceptancesReceived ?? null}
              startDate={startDate}
              endDate={endDate}
            />
            <InvoiceStatus
              count={counts?.invoiceStatus ?? null}
              serviceType="cobra"
              startDate={startDate}
              endDate={endDate}
            />
            <InitialNoticeSent count={counts?.initialNoticesSent ?? null} startDate={startDate} endDate={endDate} />
            <ActiveWithAddress
              count={counts?.activeWithAddress ?? null}
              serviceType="cobra"
              description="List of active COBRA participants with their addresses."
              startDate={startDate}
              endDate={endDate}
            />
            <TerminatedParticipants
              count={counts?.termsFromCobra ?? null}
              serviceType="cobra"
              description="List of participants that have terminated off of COBRA and their reason for termination
within the date range selected."
              startDate={startDate}
              endDate={endDate}
            />
            <Ten95CData count={counts?.ten95cData ?? null} startDate={startDate} endDate={endDate} reportType="cobra" />
          </div>
        </div>
      </PageContentPaper>
      <div className="w-1/4 flex flex-col space-y-8">
        <PendingResponses serviceType="cobra" />
        <PendingNotices type="qe" serviceType="cobra" />
        <ActiveParticipants serviceType="cobra" />
        <NoResponseWithAddress />
      </div>
    </div>
  );
}

export default COBRAReports;
