import { useState, useRef, useEffect } from 'react';
import { compose, tap, always } from 'ramda';

import { useSelector } from 'react-redux';

import { selectSelectedCompany } from 'companies/companies.slice';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { getCustomEnrollmentWithDepsCount } from 'shared/api/customEnrollmentWithDepsApi';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';

interface UseCustomEnrollmentWithDepsProps {
  selectedPlan?: OpenPlan;
}

export function useCustomEnrollmentWithDepsCount({ selectedPlan }: UseCustomEnrollmentWithDepsProps) {
  const errorNotifier = useErrorNotifier();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ count, setCount ] = useState<number | null>(null);
  const [ setDefault ] = useState(() => compose(setCount, always(0)));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (selectedPlan) {
      setCount(null);
      getCustomEnrollmentWithDepsCount(compid, selectedPlan)
        .then((result) => {
          if (mountedRef.current) {
            setCount(result);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            setCount(0);
            handleError(err);
          }
        });

      return () => {
        mountedRef.current = false;
      };
    }
    setCount(0);
  }, [ handleError, compid, selectedPlan ]);

  return count;
}
