import * as yup from 'yup';
import dayjs from 'dayjs';

export default yup.object({
  type: yup.string(),
  planYearStart: yup.string(),
  cobraOeSendPackets: yup
    .date()
    .typeError('cobraOeSendPackets Please enter a valid date')
    .required()
    .when('planYearStart', (planYearStart: string | null, sch: any) => {
      return sch.test({
        test: (cobraOeSendPackets: Date | null) => {
          return !!cobraOeSendPackets
            && dayjs(cobraOeSendPackets).diff(dayjs().format('MM/DD/YYYY'), 'days') >= 14;
        },
        message: 'cobraOeSendPackets Packet send date must be two weeks or more from the current date',
      });
    }),
  cobraOeResponseDeadline: yup
    .date()
    .typeError('cobraOeResponseDeadline Please enter a valid date')
    .required()
    .when('cobraOeSendPackets', (cobraOeSendPackets: Date | null, sch: any) => {
      return sch.test({
        test: (cobraOeResponseDeadline: Date | null) => !!cobraOeSendPackets && !!cobraOeResponseDeadline && (
          dayjs(cobraOeResponseDeadline).diff(dayjs(cobraOeSendPackets), 'days') >= 14
          && dayjs(cobraOeResponseDeadline).isAfter(cobraOeSendPackets)
        ),
        message: 'cobraOeResponseDeadline Deadline must be more than 14 days after send by date',
      });
    }),
});
