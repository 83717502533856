import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import ViewIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { WebFile } from './file-history.types';
import { downloadFileHistoryFile } from 'shared/api/fileHistoryApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

interface FileHistoryTableRowProp {
  webfile: WebFile;
}

function FileHistoryTableRow({ webfile }: FileHistoryTableRowProp) {
  const errorNotifier = useErrorNotifier();

  function handleOnClick() {
    downloadFileHistoryFile(webfile.compid, webfile.fileid, webfile.filename)
      .catch(errorNotifier);
  }

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body1" className="text-black text-sm">
          {webfile.description}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" className="text-black text-sm">
          {dayjs(webfile.dateadded).format('MM/DD/YYYY')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" className="text-black text-sm">
          <Button onClick={handleOnClick} className="p-0">
            <p className="flex flex-row justify-center text-high-em text-sm">
              <ViewIcon className="mr-1" />View
            </p>
          </Button>
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default FileHistoryTableRow;
