import { useEffect } from 'react';

import { setPageTitle } from 'components/app-settings.slice';
import { useDispatch } from 'react-redux';
import store from 'store';

export function usePageTitle(title?: string) {
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    dispatch(setPageTitle(title ?? ''));
  }, [ title, dispatch ]);
}
