import { map, or, is } from 'ramda';

/**
 * Deeply maps values in an object
 */
export function deepMap<T extends {}> (fn: Function, obj: T): T {
  return map(
    (val) => {
      return or(is(Array, val), is(Object, val))
        ? deepMap(fn, val)
        : fn(val);
    }, obj);
}
