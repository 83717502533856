import React, { ChangeEvent, useRef } from 'react';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import states from 'components/shared/states.json';

interface StateSelectProps {
  onStateChanged: (state: string) => void;
  onStateBlur?: (e: React.FocusEvent<any, Element>) => void;
  value?: string;
  error?: boolean;
  className?: string;
  label?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  [otherProp: string]: any;
}

function displayErrorText(y: boolean) {
  if (y) {
    return (
      <FormHelperText
        disabled
        error
        data-testid="stateSelectHelper"
      >This field is required
      </FormHelperText>
    );
  } else {
    return (<></>);
  }
}

function StateSelect({
  onStateChanged,
  onStateBlur,
  value = '',
  error = false,
  className = '',
  label = 'State',
  variant = 'outlined',
  ...rest
}: StateSelectProps): React.ReactElement {
  const labelRef = useRef<HTMLLabelElement>(null);

  function handleChange(event: ChangeEvent<{ name?: string; value: unknown; }>) {
    onStateChanged(event.target.value as string);
  }

  return (
    <FormControl variant={variant} className={`${className} w-full`}>
      <InputLabel ref={labelRef} id="state-select-label"><span className="bg-white px-2">{label}</span></InputLabel>
      <Select
        onChange={handleChange as any}
        onBlur={onStateBlur}
        value={value}
        native
        error={error}
        data-testid="stateSelect"
        {...rest}
      >
        <option />
        {states.map(item => (
          <option key={item.abbreviation} value={item.abbreviation}>
            {item.abbreviation}
          </option>
        ))}
      </Select>
      {displayErrorText(error)}
    </FormControl>
  );
}

export default StateSelect;
