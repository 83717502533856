import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { selectSelectedCompany } from 'companies/companies.slice';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { getBalanceReportPlans } from 'shared/api/balanceReportsByDateRangeApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

export function useBalanceReportPlans() {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ plans, setPlans ] = useState<OpenPlan[] | null>(null);
  const [ setDefault ] = useState(() => compose(setPlans, always([])));
  const handleError = compose(
    tap(setDefault),
    useErrorNotifier(),
  );
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    getBalanceReportPlans(compid)
      .then((result) => {
        if (mountedRef.current) {
          setPlans(result?.map((n, i) => ({ ...n, unqid: i })) ?? []);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ compid, setPlans ]);

  return plans;
}
