import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { Check } from './view-checks.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { selectParticipant } from 'participant-search/participant.slice';
import request, { APIOptions } from 'shared/api-request';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import store from 'store';

export function useChecks(isCobra: boolean) {
  const dispatch = useDispatch<typeof store.dispatch>();

  const [ checks, setChecks ] = useState<Check[] | null>(null);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const { empno } = useSelector(selectParticipant) ?? { empno: undefined };

  const { current: abortCtrl } = useRef(new AbortController());
  const { current: setDefault } = useRef(compose(setChecks, always([])));
  const { current: handleError } = useRef(compose(
    tap(setDefault),
    dispatch,
    addHttpErrorNotification,
  ));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    const uri = `/api/companies/${compid}/qualifying-events/${isCobra ? 'c' : 'r'}/checks/${empno}`;
    const apiOpts: APIOptions = { method: 'GET', signal: abortCtrl.signal };

    request<Check[]>(uri, apiOpts)
      .then((result) => {
        if (mountedRef.current) {
          setChecks(result ?? []);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
      abortCtrl.abort();
    };
  }, [ dispatch, compid, empno, setDefault, handleError, abortCtrl, isCobra ]);

  return checks;
}
