import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from '@mui/icons-material/Event';

import { WizardStep } from 'components/shared/WizardStepper';

export type UPEStepSlug = 'plan-details' | 'summary';
const wizardSteps: Map<UPEStepSlug, WizardStep> = new Map([
  [
    'plan-details',
    {
      icon: EventIcon,
      label: 'Plan Details',
      slug: 'plan-details',
      next: 'summary',
    },
  ],
  [
    'summary',
    {
      icon: DescriptionIcon,
      label: 'Summary',
      slug: 'summary',
      prev: 'plan-details',
    },
  ],
]);

export default wizardSteps;
