import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import { compose, nthArg } from 'ramda';

import { NonPlanYearInvoiceDetail } from './view-invoices.types';
import InvoiceDetailTableRow from './InvoiceDetailTableRow';

export interface InvoiceTableProps {
  invoiceDetails: NonPlanYearInvoiceDetail[] | null;
  isExpanded: boolean;
}

function InvoicesTable({ invoiceDetails, isExpanded = false }: InvoiceTableProps): React.ReactElement {
  const [ page, setPage ] = useState(0);

  return (
    <>
      <TableRow>
        <TableCell colSpan={5} className={isExpanded ? '' : 'p-0'}>
          <Collapse in={isExpanded} collapsedSize={0}>
            <Table className="border">
              <TableHead>
                <TableRow>
                  <TableCell>Plan</TableCell>
                  <TableCell>Level</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  invoiceDetails && invoiceDetails.length > 0
                    ? invoiceDetails
                      .slice(page * 5, page * 5 + 5)
                      .map(
                        (invoiceDetail) => (
                          <InvoiceDetailTableRow
                            invoiceDetail={invoiceDetail}
                            key={invoiceDetail.detailNumber}
                          />
                        ),
                      )
                    : <TableRow><TableCell colSpan={3}><p>No details to display.</p></TableCell></TableRow>
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPage={5}
                    rowsPerPageOptions={[ 5 ]}
                    colSpan={6}
                    count={invoiceDetails?.length ?? 0}
                    page={page}
                    onPageChange={compose(setPage, nthArg(1))}
                    SelectProps={ { native: true } }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default InvoicesTable;
