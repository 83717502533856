import { Participant } from 'participant-search/participant-list.types';
import request from 'shared/api-request';

export async function getParticipant(companyId: number, payrollId: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load participant for invalid company.'));
  }

  if (!payrollId) {
    return Promise.reject(new Error('Cannot load participant with invalid id.'));
  }

  let participant = await request<Participant>(`/api/companies/${companyId}/participant/${payrollId}`);

  if (!participant) {
    return Promise.reject(new Error(`Could not load participant with ID# ${ payrollId }`));
  }

  return participant;
}

export function updateParticipant(companyId: number, participant: Participant) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot update participant for invalid company.'));
  }

  if (!participant.empno) {
    return Promise.reject(new Error('Cannot update participant with invalid id.'));
  }

  return request(`/api/companies/${companyId}/participant/${participant.empno}`, { method: 'PUT', body: participant });
}

export function searchForParticipant(companyId: number, searchText: string, abortController?: AbortController) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot search for a participant for invalid company.'));
  }

  const search = searchText.trim();

  if (search === '') {
    return Promise.reject(new Error('Cannot search for a participant without a search string'));
  }

  const uri = `/api/companies/${companyId}/participants?searchText=${search}`;

  return request<Participant[]>(uri, { method: 'GET', signal: abortController?.signal });
}
