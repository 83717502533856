import * as yup from 'yup';

/**
 * @file Contains common validators for yup schemas
 *
 * @description These can be added to schema validation via object composition (example below)
 *
 * @example
 * ```
 * const validationSchema = yup.object({
 *   ...fname,
 *   ...lname,
 *   someNonCommonField: yup.require(),
 * })
 */

export const requiredText = 'This field is required';

export const errorMessages = {
  nameFirst: {
    max: 'First name must be 15 characters or less',
  },
  nameLast: {
    max: 'Last name must be 20 characters or less',
  },
  ssn: {
    matches: 'SSN must only include numbers',
    length: 'SSN must be 9 digits',
  },
  addr1: {
    max: 'Address line 1 must be 30 characters or less',
  },
  addr2: {
    max: 'Address line 2 must be 30 characters or less',
  },
  email: {
    matches: 'Email must be a valid email address',
    max: 'Email must be 50 characters or less',
  },
  phone: {
    matches: 'Phone is invalid',
    max: 'Phone must be 14 characters or less',
    min: 'Phone must be a minimum of 10 characters',
  },
  fax: {
    matches: 'Fax number is invalid',
    max: 'Fax number must be 14 characters or less',
    min: 'Fax number must be a minimum of 10 characters',
  },
  city: {
    matches: 'City must only include letters and spaces',
    max: 'City must be 20 characters or less',
  },
  postalCd: {
    matches: 'Zip must only include numbers and hyphens',
    max: 'Zip must be 10 characters or less',
    min: 'Zip must be a minimum of 5 characters',
  },
};

export const regexPatterns = {
  ssn: /^\d+$/,
  postalCd: /^[\d-]+$/,
  phone: /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/,
};

export const fname = {
  fname: yup
    .string()
    .trim()
    .max(15, errorMessages.nameFirst.max)
    .required(requiredText),
};

export const lname = {
  lname: yup
    .string()
    .trim()
    .max(20, errorMessages.nameLast.max)
    .required(requiredText),
};

export const name = {
  ...fname,
  ...lname,
};

export const payrollid = {
  payrollid: yup
    .string()
    .trim()
    .matches(/^[\d-]+$/, 'Must only include numbers')
    .transform(function (value, originalvalue) {
      return originalvalue.replace(/[^\d]+/g, '');
    })
    .max(9, 'Must be 9 characters or less')
    .required(requiredText),
};

export const ssn = {
  ssn: yup
    .string()
    .matches(regexPatterns.ssn, errorMessages.ssn.matches)
    .length(9, errorMessages.ssn.length),
};

export const hstreet1 = {
  hstreet1: yup
    .string()
    .trim()
    .max(30, errorMessages.addr1.max)
    .nullable()
    .required(requiredText),
};

export const hstreet2 = {
  hstreet2: yup
    .string()
    .max(30, errorMessages.addr2.max)
    .nullable(),
};

export const hcity = {
  hcity: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z\s]+$/, errorMessages.city.matches)
    .max(20, errorMessages.city.max)
    .nullable()
    .required(requiredText),
};

export const hstate = {
  hstate: yup
    .string()
    .trim()
    .nullable()
    .required(requiredText),
};

export const hzip = {
  hzip: yup
    .string()
    .trim()
    .matches(/^[\d-]+$/, errorMessages.postalCd.matches)
    .max(10, errorMessages.postalCd.max)
    .nullable()
    .required(requiredText),

};

export const hphone = {
  hphone: yup
    .string()
    .max(13, 'Phone must be 13 characters or less')
    .test(
      'phone-empty-or-10-chars-check',
      'Phone must be at least 10 characters',
      (phone) => !phone || phone.length >= 10,
    )
    .nullable(),
};

export const email = {
  email: yup
    .string()
    .email(errorMessages.email.matches)
    .max(50, errorMessages.email.max)
    .nullable(),
};
