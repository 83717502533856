import React from 'react';

import DocumentsTable from './DocumentsTable';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { useDocuments } from './useDocuments';
import withModal from 'components/shared/HOC/WithModal';

interface ViewDocumentsProps {
  isCobra: boolean;
}

function ViewDocuments({ isCobra }: ViewDocumentsProps): React.ReactElement {
  const documents = useDocuments(isCobra);

  const Documents = withLoopIcon(
    DocumentsTable,
    'Loading Documents...',
    documents === null,
  );

  return <Documents documents={documents} />;
}

export const DocumentsModal = withModal<ViewDocumentsProps>(ViewDocuments);

export default ViewDocuments;
