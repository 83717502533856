import { DateFormat_M_D_YYYY } from 'shared/consts/date-time.consts';
import { LeaveRecordCaseInfo } from '../leaveRecord.types';
import { dateOrPlaceholder, valueOrPlaceholder } from 'shared/utils';

interface Props {
  data?: LeaveRecordCaseInfo | null;
}

export function LeaveRecordCaseInfoTab({ data }: Readonly<Props>) {
  const placeholder = '-';

  return (
    <div className="m-4" data-testid="case-info-tab-panel">
      <h6 className="mb-4">Case Information</h6>
      <dl>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Case ID</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.title, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Case Status</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.caseStatus, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Approval Status</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.approvalStatus, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Work State</dt>
            <dd className="font-medium"> { valueOrPlaceholder(data?.workState, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Duration of Leave</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.durationOfLeave, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Regulation/Policy Type</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.regulationPolicyType, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Start Date</dt>
            <dd className="font-medium">{ dateOrPlaceholder(data?.startDate, DateFormat_M_D_YYYY, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">End Date</dt>
            <dd className="font-medium">{ dateOrPlaceholder(data?.endDate, DateFormat_M_D_YYYY, placeholder) }</dd>
          </div>
        </div>
        <dt className="text-sm text-neutral-500">Estimated Return to Work Date</dt>
        <dd className="mb-8 font-medium">
          { dateOrPlaceholder(data?.returnToWorkDate, DateFormat_M_D_YYYY, placeholder) }
        </dd>
        <dt className="text-sm text-neutral-500">Relationship</dt>
        <dd className="mb-8 font-medium">{ valueOrPlaceholder(data?.relationship, placeholder) }</dd>
        <dt className="text-sm text-neutral-500">Relationship Details</dt>
        <dd className="font-medium">{ valueOrPlaceholder(data?.relationshipDetails, placeholder) }</dd>
      </dl>
    </div>
  );
}
