import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InvoiceDetails from './ViewInvoiceDetails';

import dayjs from 'dayjs';

import { NonPlanYearInvoice } from './view-invoices.types';
import { currencyFormatter } from 'shared/utils';

interface InvoiceTableRowProps {
  invoice: NonPlanYearInvoice;
  isCobra: boolean;
}

function InvoiceTableRow({ invoice, isCobra }: InvoiceTableRowProps): React.ReactElement {
  const [ invoiceDetailsOpen, setInvoiceDetailsOpen ] = useState(false);

  function handleOnClick() {
    setInvoiceDetailsOpen(!invoiceDetailsOpen);
  }

  return (
    <>
      <TableRow onClick={handleOnClick} className="invoice-row">
        <TableCell>{invoice.invoiceNumber}</TableCell>
        <TableCell>{dayjs(invoice.generated).format('M/D/YYYY')}</TableCell>
        <TableCell>{dayjs(invoice.periodStart).format('M/D/YYYY')}</TableCell>
        <TableCell>{dayjs(invoice.periodEnd).format('M/D/YYYY')}</TableCell>
        <TableCell>{currencyFormatter.format(invoice.amount)}</TableCell>
        <TableCell>{currencyFormatter.format(invoice.paid)}</TableCell>
        <TableCell>{currencyFormatter.format(invoice.open)}</TableCell>
      </TableRow>
      <InvoiceDetails
        isCobra={isCobra}
        invoiceNumber={invoice.invoiceNumber}
        isExpanded={invoiceDetailsOpen}
      />
    </>
  );
}

export default InvoiceTableRow;
