import Button from '@mui/material/Button';
import withModal from 'components/shared/HOC/WithModal';
import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined';

interface EnterLeaveRequestConfirmationProps {
  onNewLeaveRequest: () => void;
  onGoToDash: () => void;
}

export function EnterLeaveRequestConfirmation({
  onNewLeaveRequest,
  onGoToDash,
}: Readonly<EnterLeaveRequestConfirmationProps>): React.ReactElement {
  return (
    <div className="p-4 text-center bg-surface" data-testid="leave-request-confirmation-modal">
      <div className="text-green-600">
        <CheckCircleOutlineOutlined color="inherit" fontSize="large" />
      </div>
      <h5 className="mb-8">Leave Request has been submitted</h5>
      <Button
        variant="contained"
        className="mr-4"
        onClick={onNewLeaveRequest}
        data-testid="btn-leave-request-add-new"
      >
        Report an Absence
      </Button>
      <Button
        variant="contained"
        onClick={onGoToDash}
        data-testid="btn-leave-request-dashboard"
      >
        Go to Dashboard
      </Button>
    </div>
  );
}

export default withModal(EnterLeaveRequestConfirmation);
