import { isNil } from 'ramda';
import * as yup from 'yup';

export const validationSchema = yup
  .object({
    benefits: yup
      .array()
      .of(
        yup
          .object()
          .nullable()
          .shape({
            selectedPlan: yup
              .object({
                planLevelId: yup
                  .string()
                  .nullable(),
              })
              .nullable(),

            cost: yup
              .number()
              .nullable()
              .typeError('Must be a number')
              .min(0, 'Must be a positive number'),

            subsidy: yup
              .number()
              .nullable()
              .typeError('Subsidy must be a number')
              .min(0, 'Subsidy must be a positive number.')
              .when('subsidyEnd', {
                is: (subsidyEnd: Date | undefined) => !!subsidyEnd,
                then: yup
                  .number()
                  .typeError('Subsidy must be a number.')
                  .min(0, 'Subsidy must be a positive number.')
                  .required('Subsidy is required.'),
              }),

            subsidyEnd: yup
              .date()
              .nullable()
              .when('subsidy', {
                is: (subsidy: number | undefined) => subsidy && (subsidy > 0),
                then: yup
                  .date()
                  .typeError('Subsidy End must be a valid date.')
                  .required('Subsidy End is required.'),
              }),
          }, [ 'subsidy', 'subsidyEnd' ] as any),
      ),
  })
  .test(
    'at-least-one-benefit-election',
    'Please select at least one benefit election.',
    ({ benefits }) => !!benefits?.map((b) => b?.selectedPlan).some((p) => !isNil(p?.planLevelId)),
  )
;
