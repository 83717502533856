import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import dayjs, { Dayjs } from 'dayjs';

import { Dependent } from 'shared/types/non-plan-year.types';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { colorFromLetters } from 'components/shared/avatar-color-palette';
import CancelModal from 'components/shared/CancelModal';

interface DependentsTableRowProps {
  dependent: Dependent;
  dateFormat?: string;
  onDependentDeleted?: (d: Dependent) => void;
}

function DependentsTableRow({
  dependent,
  dateFormat = 'MM/DD/YYYY',
  onDependentDeleted,
}: DependentsTableRowProps): React.ReactElement {
  const [ showModal, setShowModal ] = useState(false);

  function dependentInitials(): string {
    return dependent.name ? dependent.name.split(' ').map(n => n.charAt(0).toUpperCase()).join('') : '';
  }

  function formatDate(date?: string| Dayjs): string {
    return date ? dayjs(date).format(dateFormat) : '';
  }

  function formatGender(gender?: string): string {
    switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    default:
      return 'Unknown';
    }
  }

  function formatRelation(relation?: string): string {
    switch (relation) {
    case 'S':
      return 'Spouse';
    case 'C':
      return 'Child';
    case 'O':
      return 'Other';
    default:
      return 'Unknown';
    }
  }

  function handleDelete() {
    if (onDependentDeleted) {
      onDependentDeleted(dependent);
    }
  }

  function handleModalClose() {
    setShowModal(false);
  }

  function deleteTooltip() {
    return onDependentDeleted ? 'Delete' : 'Existing dependents cannot be deleted';
  }

  function avatarColor() {
    return colorFromLetters(dependentInitials());
  }

  function onDeleteButtonClicked() {
    setShowModal(true);
  }

  return (
    <>
      <TableRow className="transition-opacity duration-100 ease-linear">
        <TableCell>
          <Avatar className={`p-2 text-sm ${avatarColor()}`}>{dependentInitials()}</Avatar>
        </TableCell>
        <TableCell className="text-sm">{dependent.name}</TableCell>
        <TableCell className="text-sm">{dependent.ssn}</TableCell>
        <TableCell className="text-sm">{formatDate(dependent.dateOfBirth)}</TableCell>
        <TableCell className="text-sm">{formatRelation(dependent.relation)}</TableCell>
        <TableCell className="text-sm">{formatGender(dependent.sex)}</TableCell>
        <TableCell>
          <Tooltip title={deleteTooltip()}>
            <span>
              <Button
                onClick={onDeleteButtonClicked}
                data-testid={`${dependent.name}-delete`}
                className="text-md"
                disabled={!onDependentDeleted}
              >
                <DeleteIcon className="text-md" />
              </Button>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
      <CancelModal
        isOpen={showModal}
        onCancel={handleDelete}
        onClose={handleModalClose}
        title="Are you sure you want to delete the dependent?"
        prompt=""
        cancelText="Delete"
      />
    </>
  );
}

export default DependentsTableRow;
