import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DescriptionIcon from '@mui/icons-material/Description';

import { WizardStep } from 'components/shared/WizardStepper';

export type AddUserStepSlug = 'step-1' | 'step-2';

const wizardSteps: Map<AddUserStepSlug, WizardStep> = new Map([
  [
    'step-1',
    {
      label: 'Step 1',
      icon: PersonOutlineIcon,
      slug: 'step-1',
      next: 'step-2',
    },
  ],
  [
    'step-2',
    {
      label: 'Step 2',
      icon: DescriptionIcon,
      slug: 'step-2',
      prev: 'step-1',
    },
  ],
]);

export default wizardSteps;
