import React, { useRef, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { Benefit, Dependent } from 'shared/types/non-plan-year.types';
import { QeBenefits } from 'enter-cobra-qe/cobra-qe.types';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from 'companies/companies.slice';
import styles from 'components/shared/qe/Benefits.module.css';
import InputLabel from '@mui/material/InputLabel';
import FormErrorText from 'components/shared/FormErrorText';
import Select from '@mui/material/Select';
import { benefitErrorMessage } from './benefit-state-helpers';
import BenefitSubsidyFields from './BenefitSubsidyFields';
import BenefitDependents from './BenefitDependents';
import { selectQeEventInfo } from 'enter-cobra-qe/cobra-qe.slice';
import { getNoBenefitsMessage } from 'shared/api/cobraQeApi';
import dayjs from 'dayjs';
import CancelModal from 'components/shared/CancelModal';
import { always, compose, equals, F, T, when } from 'ramda';

interface BenefitsFormProps {
  dependents: Dependent[];
  availablePlans: QeBenefits[];
  letterSent?: boolean;
  respPostmark?: boolean;
}

function BenefitsForm({ dependents, availablePlans, letterSent = false, respPostmark = false }: BenefitsFormProps) {
  const { values, handleChange, errors } = useFormikContext<{ benefits: Benefit[]; }>();
  const planSelectLabelRef = useRef<HTMLLabelElement>(null);
  const { hasCobraErsubsidy } = useSelector(selectSelectedCompany) ?? { hasCobraErsubsidy: false };
  const eventInfo = useSelector(selectQeEventInfo);
  const [ costConfirmationModal, setConfirmationModal ] = useState(false);
  const [ hasTriggeredModal, setHasTriggeredModal ] = useState(false);

  function displayErSubsidy(idx: number, benefit: Benefit) {
    return hasCobraErsubsidy
      ? <BenefitSubsidyFields idx={idx} benefit={benefit} disabled={respPostmark} />
      : <></>;
  }

  function showDependents(idx: number, benefit: Benefit) {
    return dependents.length > 0
      ? (
        <BenefitDependents
          dependents={dependents}
          idx={idx}
          benefit={benefit}
          disabled={letterSent || respPostmark}
        />
      )
      : <></>;
  }

  function renderFieldArray() {
    return () => (
      <div>
        {
          values.benefits.length > 0
            ? (
              values.benefits.map((b: Benefit, idx: number) => (
                <div
                  key={idx}
                  className={`${styles.summaryContainer} flex flex-col mb-8 p-4`}
                  data-testid={`benefitSelector-${b.planTypeName}`}
                >
                  <h6 className={`${styles.color} mb-4`}>{b.planTypeName}</h6>
                  <div className="flex flex-row">
                    <div className="flex flex-grow flex-col">
                      <FormControl variant="outlined">
                        <InputLabel
                          ref={planSelectLabelRef}
                        >
                          Plan
                        </InputLabel>

                        <Select
                          name={`benefits.${idx}.plvlid`}
                          value={b.plvlid}
                          native
                          fullWidth
                          data-testid={`benefits.${idx}.planSelect`}
                          label="Plan"
                          variant="outlined"
                          className="mb-4"
                          onChange={handleChange}
                          error={!!benefitErrorMessage(errors, idx, 'plvlid')}
                          inputProps={ { disabled: letterSent || respPostmark } }
                          disabled={letterSent || respPostmark}
                        >
                          <option value="-1">None Selected</option>
                          {availablePlans.find(({ benefits }) => benefits
                            .some(benefit => benefit.planTypeName === b.planTypeName))?.benefits
                            .map(item => (
                              <option key={item.plvlid} value={item.plvlid}>
                                {item.displayName}
                              </option>
                            ))}
                        </Select>
                        <FormErrorText
                          show={!!benefitErrorMessage(errors, idx, 'plvlid')}
                          message={benefitErrorMessage(errors, idx, 'plvlid')}
                          className="planSelectHelper"
                        />
                      </FormControl>
                      <div className="flex flex-row">
                        <TextField
                          variant="outlined"
                          label="Cost"
                          name={`benefits.${idx}.cost`}
                          data-testid={`benefits.${idx}.cost`}
                          placeholder="Default"
                          value={b.cost === 0 ? '' : b.cost}
                          InputProps={ {
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            disabled: respPostmark,
                          } }
                          className="mr-4"
                          onChange={handleChange}
                          onFocus={
                            when(
                              compose(equals(false), always(hasTriggeredModal)),
                              compose(
                                compose(setHasTriggeredModal, T),
                                compose(setConfirmationModal, T),
                              ),
                            )
                          }

                          error={benefitErrorMessage(errors, idx, 'cost').length > 0}
                          helperText={benefitErrorMessage(errors, idx, 'cost')}
                        />
                        {displayErSubsidy(idx, b)}
                      </div>
                    </div>
                    <div className={`flex flex-col${dependents.length > 0 ? ' ml-4' : ''}`}>
                      {showDependents(idx, b)}
                    </div>
                  </div>
                </div >
              ))
            )
            : <p>{getNoBenefitsMessage(dayjs(eventInfo.coverageBegins).format('MM/DD/YYYY'))}</p>
        }
        <CancelModal
          isOpen={costConfirmationModal}
          onCancel={compose(setConfirmationModal, F)}
          onClose={compose(setConfirmationModal, F)}
          title={'Are you sure you wish to edit the rate?'}
          prompt="Flores keeps default rates on file for all benefits except Age-Banded plans.
          If your plan is not an Age-Banded plan, we suggest using the default rates.
          If your plan is not an Age-Banded plan and you still believe the rates should be edited,
          please confirm with your account manager."
          closeText="Yes"
          cancelText="No"
        />
      </div>
    );
  }

  return (
    <FieldArray
      name="benefits"
      render={renderFieldArray()}
    />
  );
}

export default BenefitsForm;
