import React from 'react';
import { Document as _Document, Page as _Page, Text as _Text, StyleSheet as _StyleSheet } from '@react-pdf/renderer';
import {
  PdfTable as _PdfTable,
  PdfTableHeader as _PdfTableHeader,
  PdfTableCell as _PdfTableCell,
  PdfTableBody as _PdfTableBody,
  PdfDataTableCell as _PdfDataTableCell,
} from 'shared/pdf-data-table';
import { identity } from 'ramda';

interface Props<T> {
  title: string;
  headers: string[];
  cells: T[];
}

const Document = _Document as any;
const Page = _Page as any;
const Text = _Text as any;
const StyleSheet = _StyleSheet as any;
const PdfTable = _PdfTable as any;
const PdfTableHeader = _PdfTableHeader as any;
const PdfTableCell = _PdfTableCell as any;
const PdfTableBody = _PdfTableBody as any;
const PdfDataTableCell = _PdfDataTableCell as any;

const styles = StyleSheet.create({
  title: { margin: '5% 5% 0' },
});

function PdfDataTableGenerator<T>({ title, headers, cells }: Props<T>) {
  return (
    <Document>
      <Page>
        <Text style={styles.title}>{ title }</Text>
        <PdfTable data={cells}>
          <PdfTableHeader>
            { headers.map((h, i) => <PdfTableCell key={`pdfHeader${i}`}>{h}</PdfTableCell>) }
          </PdfTableHeader>
          <PdfTableBody>
            {
              cells.length > 0
                ? (Object.keys(cells[0]) as any).map((c: keyof T, i: number) => {
                  return <PdfDataTableCell key={`pdfCell${i}`} getContent={identity((r: T) => r[c])} />;
                })
                : <PdfDataTableCell getContent={identity(() => 'No transactions to display.')} />
            }
          </PdfTableBody>
        </PdfTable>
      </Page>
    </Document>
  );
}

export default PdfDataTableGenerator;
