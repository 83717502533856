import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import ReportHistoryTableRow from './ReportHistoryTableRow';
import { ReportHistory } from './report-history.types';

interface FileHistoryTableProp {
  reportHistory: ReportHistory[];
  reportType: string | null;
}

function FileHistoryTable({ reportHistory, reportType = '' }: FileHistoryTableProp) : React.ReactElement {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          reportHistory
            .filter(rh => rh.description.includes(reportType ?? ''))
            .map((rh) => <ReportHistoryTableRow rptHistory={rh} key={rh.unqid} />)
        }
      </TableBody>
    </Table>
  );
}

export default FileHistoryTable;
