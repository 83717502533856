import dayjs from 'dayjs';
import * as yup from 'yup';

import {
  payrollid,
  fname,
  lname,
  hphone,
  hstreet1,
  hstreet2,
  hcity,
  hstate,
  hzip,
  email,
} from 'shared/common-validators';

export const validationSchema = yup.object({
  ...payrollid,
  ...fname,
  ...lname,
  ...hphone,
  ...email,
  ...hstreet1,
  ...hstreet2,
  ...hcity,
  ...hstate,
  ...hzip,
  birthdate: yup
    .date()
    .nullable()
    .max(dayjs(), 'Birthdate must be before today')
    .typeError('Invalid Date Format'),
});

