import React, { ReactElement } from 'react';
import PageContentPaper from 'components/layout/PageContentPaper';

export function FMLAMarketingTabPanel(): ReactElement {
  return (
    <div className="w-full flex space-x-8">
      <PageContentPaper className="w-full">
        <p>
          These reports are available to clients with our Leave Solutions
          service. Are you in need of a leave administration provider? Flores
          Leave Solutions can help! <br />Contact your Flores Account Manager to learn
          more.
        </p>
      </PageContentPaper>
    </div>
  );
}
