import { useState, useRef, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import LoopIcon from '@mui/icons-material/Loop';

import request, { ContentType } from 'shared/api-request';
import { NotificationType } from 'notifications/notification.types';
import { addNotification } from 'notifications/notifications.slice';
import { selectSelectedCompany } from 'companies/companies.slice';
import PageContentPaper from 'components/layout/PageContentPaper';
import { useFileTypes } from './useFileTypes';
import FileTypeSelect from './FileTypeSelect';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import safeFilename from 'shared/utils/safe-filename';
import store from 'store';

const maxAllowedFileSize = 52428800; // max allowed 50 MB

function FileUpload() {
  const [ uploading, setUploading ] = useState(false);
  const [ selectedFileType, setSelectedFileType ] = useState<string>('');
  const [ formData, setFormData ] = useState<FormData | null>(null);
  const [ fileInputKey, setFileInputKey ] = useState<number>(-1);
  const availableFileTypes = useFileTypes();

  const { medplancd } = useSelector(selectSelectedCompany) ?? { medplancd: '0' };
  const dispatch = useDispatch<typeof store.dispatch>();

  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (availableFileTypes?.length) {
      setSelectedFileType(availableFileTypes[0].description);
    } else {
      setSelectedFileType('');
    }
  }, [ availableFileTypes ]);

  function handleFileSelectionChange(fileType?: string) {
    setSelectedFileType(fileType ?? '');
  }

  function isValidInputs() {
    return (formData !== null && selectedFileType !== '');
  }

  async function submitFileUploadData(event: any) {
    event.preventDefault();

    if (isValidInputs()) {
      try {
        setUploading(true);
        const uri = `/api/file-upload/upload-files/${encodeURIComponent(selectedFileType)}/${medplancd}`;
        const status = await request(uri, {
          headers: { 'content-type': ContentType.FORM_DATA },
          method: 'POST',
          body: formData!,
        });

        if (mountedRef.current) {
          setUploading(false);

          if (status) {
            dispatchMessage('File uploaded successfully.', NotificationType.SUCCESS);
            setFormData(null);
            setFileInputKey(Math.random());
          } else {
            dispatchMessage('An error occured please try again.', NotificationType.ERROR);
          }
        }
      } catch (ex) {
        if (mountedRef.current) {
          setUploading(false);
          dispatchMessage(ex.message, NotificationType.ERROR);
        }
      }
    } else if (selectedFileType !== '') {
      dispatchMessage('Please select a valid file type.', NotificationType.WARNING);
    } else {
      dispatchMessage('Please select a file to upload.', NotificationType.WARNING);
    }
  }

  function dispatchMessage(text: string, notificationType: any) {
    dispatch(addNotification({
      message: text,
      type: notificationType,
      area: 'snackbar',
      key: performance.now().toString(),
    }));
  }

  function handleFileSelect(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    const { files } = event.target;
    const data = new FormData();

    if (files !== null) {
      Array.from(files).forEach((file) => {
        if (getFileExtension(file.name) !== 'exe') {
          if (file.size <= maxAllowedFileSize) {
            data.append('file', file, safeFilename(file.name));
            setFormData(data);
          } else {
            dispatchMessage('File is too large to upload. Max size is 50MB.', NotificationType.WARNING);
            setFormData(null);
          }
        } else {
          dispatchMessage('Please select a valid file for upload.', NotificationType.WARNING);
          setFormData(null);
        }
      });
    }
  }

  function getFileExtension(filename: string) {
    return filename.split('.').pop();
  }

  const FileSelect = withLoopIcon(
    FileTypeSelect,
    'Loading file types...',
    availableFileTypes === null,
    'mr-4 file-upload-loading-file-types',
  );

  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">File Upload</h2>
      <div className="w-full md:w-2/3">
        <PageContentPaper className="flex flex-col">
          <div className="flex flex-col ml-3 mt-5 space-y-4 w-1/3 file-upload">
            <FileSelect
              value={selectedFileType}
              onSelectChange={handleFileSelectionChange}
              fileTypes={availableFileTypes ?? []}
              disabled={uploading}
            />

            <input
              accept="*"
              id="file-input"
              type="file"
              onChange={handleFileSelect}
              disabled={uploading}
              key={fileInputKey}
            />

            <Button
              onClick={submitFileUploadData}
              disabled={!isValidInputs() || uploading}
              variant="contained"
              color="primary"
              className="upload-button"
            >
              Upload
              {uploading && <LoopIcon className="animate-spin ml-2 file-upload-uploading" />}
            </Button>
          </div>
        </PageContentPaper>
      </div>
    </div>
  );
}

export default FileUpload;
