import { Response } from 'miragejs';

function routing(server) {
  server.post('/two-factor-authentication', () => {
    return new Response(200, { 'content-length': 12 }, 'token_string');
  });

  server.get('/users/multi-factor-setting', () => {
    return new Response(200, { 'content-length': 15 }, { requiremfa: 'Y' });
  })

  server.post('/two-factor-authentication/resend-passcode', () => {
    return new Response(200, { 'content-length': 0 }, null);
  });
}


export default routing;
