import TabPanel from 'components/shared/TabPanel';
import {
  LeaveRecordCaseInfo,
  LeaveRecordsEmployeeInfo,
  LeaveRecordTab,
} from '../leaveRecord.types';
import { LeaveRecordCaseInfoTab } from './LeaveRecordCaseInfoTab';
import { LeaveRecordPaperworkDatesTab } from './LeaveRecordPaperworkDatesTab';
import { LeaveRecordEmployeeInfoTab } from './LeaveRecordEmployeeInfoTab';
import { LeaveRecordCurrentApprovalsTab } from './LeaveRecordCurrentApprovalsTab';

interface Props {
  activeTab: LeaveRecordTab;
  caseDetails: LeaveRecordCaseInfo | null;
  employeeDetails: LeaveRecordsEmployeeInfo | null;
}

export function LeaveRecordTabPanels({
  activeTab,
  caseDetails,
  employeeDetails,
}: Readonly<Props>) {
  return (
    <div data-testid="leave-record-tab-panels">
      <TabPanel value={activeTab} index="case-info">
        <LeaveRecordCaseInfoTab data={caseDetails} />
      </TabPanel>
      <TabPanel value={activeTab} index="employee-info">
        <LeaveRecordEmployeeInfoTab data={employeeDetails} />
      </TabPanel>
      <TabPanel value={activeTab} index="paperwork-dates">
        <LeaveRecordPaperworkDatesTab data={caseDetails} />
      </TabPanel>
      <TabPanel value={activeTab} index="current-approvals">
        <LeaveRecordCurrentApprovalsTab data={caseDetails} />
      </TabPanel>
    </div>
  );
}
