import { combineReducers } from 'redux';

// import slices here
import companyReducer, { initialState as companyState }
  from 'companies/companies.slice';
import notificationReducer, { initialState as notificationState }
  from 'notifications/notifications.slice';
import cobraQeReducer, { initialState as cobraQeState }
  from 'enter-cobra-qe/cobra-qe.slice';
import participantReducer, { initialState as participantState } from 'participant-search/participant.slice';
import planEnrollmentReducer, { initialState as planEnrollmentState } from 'plan-enrollment/plan-enrollment.slice';
import appSettingsReducer, { initialState as appSettingsState } from 'components/app-settings.slice';
import enrollmentsReducer, {
  initialState as enrollmentsState,
}
  from 'participant-overview/enrollment/enrollments.slice';
import participantOverviewReducer, {
  initialState as participantOverviewInitialState,
} from 'participant-overview/participant-overview.slice';
import directBillingQeReducer, { initialState as directBillingQeState } from 'direct-billing/direct-billing.slice';
import manageUsersReducer, { initialState as manageUsersState } from 'manage-settings/manage-users/manage-users.slice';
import enrollmentResourcesReducer, {
  initialState as enrollmentResourcesState,
} from 'open-enrollment-resources/open-enrollment-resources.slice';
import fileHistoryReducer, { initialState as fileHistoryState } from 'file-history/file-history.slice';
import slideshowReducer, {
  initialState as slideshowState,
} from 'marketing-info-tile/slideshow.slice';
import reportHistoryReducer, { initialState as reportHistoryState } from 'report-history/report-history.slice';
import cobraPendingInitialNoticesReducer, {
  initialState as cobraPendingInitialNoticesState,
} from 'cobra-pending-initial/cobra-pending-initial-notices.slice';
import contactInfoReducer, {
  initialState as contactInfoState,
} from 'manage-settings/contact-info/contact-info.slice';
import cobraPendingQeNoticesReducer, {
  initialState as cobraPendingQeNoticesState,
} from 'cobra-pending-qe/cobra-pending-qe-notices.slice';
import reportsReducer, {
  initialState as reportsInitialState,
} from 'reports/reports.slice';
import customReportsReducer, {
  initialState as customReportsInitialState,
} from 'reports/custom/custom-reports.slice';
import reEnrollmentReducer, {
  initialState as reEnrollmentInitialState,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import ratesHistoryReducer, {
  initialState as ratesHistoryInitialState,
} from 'rates-history/rates-history.slice';
import actionItemsReducer, {
  initialState as actionItemsInitialState,
} from 'action-items/action-items.slice';

import dependentsReducer, {
  initialState as dependentsInitialState,
} from 'store/slices/dependents/dependents.slice';
import directDebitReducer, {
  initialState as directDebitInitialState,
} from 'store/slices/direct-debit/direct-debit.slice';
import fmlaReportsReducer, {
  initialState as fmlaInitialState,
} from 'reports/fmla/fmla-reports-slice';
import leaveRecordReducer, {
  initialState as leaveRecordInitialState,
} from 'leave-records/leave-records.slice';

const rootReducer = combineReducers({
  // and add slices here
  companies: companyReducer,
  notifications: notificationReducer,
  cobraQe: cobraQeReducer,
  participant: participantReducer,
  planEnrollment: planEnrollmentReducer,
  appSettings: appSettingsReducer,
  enrollments: enrollmentsReducer,
  directBillingQe: directBillingQeReducer,
  manageUsers: manageUsersReducer,
  enrollmentResources: enrollmentResourcesReducer,
  participantOverview: participantOverviewReducer,
  fileHistory: fileHistoryReducer,
  slideshow: slideshowReducer,
  reportHistory: reportHistoryReducer,
  cobraPendingInitialNotices: cobraPendingInitialNoticesReducer,
  contactInfo: contactInfoReducer,
  cobraPendingQeNotices: cobraPendingQeNoticesReducer,
  reports: reportsReducer,
  customReports: customReportsReducer,
  reEnrollmentChecklist: reEnrollmentReducer,
  historicalRates: ratesHistoryReducer,
  actionItems: actionItemsReducer,
  dependents: dependentsReducer,
  directDebit: directDebitReducer,
  fmlaReports: fmlaReportsReducer,
  leaveRecords: leaveRecordReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const rootInitialState: RootState = {
  // add slice initial states here
  companies: companyState,
  notifications: notificationState,
  cobraQe: cobraQeState,
  participant: participantState,
  planEnrollment: planEnrollmentState,
  appSettings: appSettingsState,
  enrollments: enrollmentsState,
  directBillingQe: directBillingQeState,
  manageUsers: manageUsersState,
  enrollmentResources: enrollmentResourcesState,
  participantOverview: participantOverviewInitialState,
  fileHistory: fileHistoryState,
  slideshow: slideshowState,
  reportHistory: reportHistoryState,
  cobraPendingInitialNotices: cobraPendingInitialNoticesState,
  contactInfo: contactInfoState,
  cobraPendingQeNotices: cobraPendingQeNoticesState,
  reports: reportsInitialState,
  customReports: customReportsInitialState,
  reEnrollmentChecklist: reEnrollmentInitialState,
  historicalRates: ratesHistoryInitialState,
  actionItems: actionItemsInitialState,
  dependents: dependentsInitialState,
  directDebit: directDebitInitialState,
  fmlaReports: fmlaInitialState,
  leaveRecords: leaveRecordInitialState,
};

export default rootReducer;
