import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/participants', (schema, request) => {
    const search = request.queryParams.searchText.toLowerCase() ?? '';
    const { models } = schema.participants.where((p) => {
      return (p.lname.toLowerCase().includes(search) || p.fname.toLowerCase().includes(search) ||
          p.payrollid.includes(search) || p.empno.includes(search));
    });

    const data = models
      .map((m) => m.attrs)
      .slice(0, 10);
    data.sort((a, b) => {
      if (a.lname !== b.lname) {
        return a.lname.localeCompare(b.lname);
      }
      return  a.fname.localeCompare(b.fname);
    });

    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/participant/:payrollId', (schema, request) => {
    const result = schema.participants.findBy({ payrollid: request.params.payrollId });
    if (result) {
      const { attrs: spouse } = schema.dependents.findBy({relation: "S"}) ?? { attrs: undefined };
      const { attrs: data } = result;
      data.dependents = [spouse];
      const length = JSON.stringify(data).length;
      return new Response(200, { 'content-length': length }, { data });
    } else {
      return new Response(404, { 'content-length': 0 });
    }
  });

  server.get('/companies/:companyId/participant/:empno/dependents', (schema, request) => {
    const { models: data } = schema.dependents.where({ empno: request.params.empno }) ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.post('/companies/:companyId/participant/', (schema, request) => {
    if (!request.requestBody) {
      return new Response(400, {'content-length': 0 });
    }

    const attrs = JSON.parse(request.requestBody);
    const participant = schema.participants.new(attrs);
    participant.empno = participant.payrollid;
    participant.save();
    const data = participant.attrs;
    const length = JSON.stringify(data).length;

    return new Response(201, { 'content-length': length }, { data });
  });

  server.put('/companies/:companyId/participant/:empno', (schema, request) => {
    if (!request.requestBody) {
      return new Response(400, {'content-length': 0 });
    }

    const result = schema.participants.findBy({ empno: request.params.empno });
    if (result) {
      const attrs = JSON.parse(request.requestBody);
      result.update(attrs);
      return new Response(204, { 'content-length': 0 });
    } else {
      return new Response(404, { 'content-length': 0 });
    }
  });

  server.post('/companies/:companyId/participant/:empno/dependents', () => {
    return new Response(201, { 'content-length': 0 }, {});
  });

  server.delete('/companies/:companyId/participants', () => {
    return new Response(201, { 'content-length': 0 }, {});
  });
}

export default routing;
