import { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { compose, path, always, ifElse, equals, andThen, F, T, when, isNil } from 'ramda';

import {
  cancelModalOpened,
  selectCobraOeHandler,
  selectCobraOeTemplateCoverLetter,
  setCobraOeHandler,
  setCobraOeTemplateCoverLetter,
  selectCobraPlan,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { saveCobraOePacketInfo } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import { noop } from 'shared/utils';
import store from 'store';

function OePacketsOptions(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const cobraOeHandler = useSelector(selectCobraOeHandler);
  const cobraOeCoverLetter = useSelector(selectCobraOeTemplateCoverLetter);
  const { checklistComplete } = useSelector(selectCobraPlan);

  const changeRoute = compose(
    history.push,
    always(`/reenrollment-checklist/cobra-plan/${planYearId}/plan-rates`),
  );

  const handleOeHandlerChange = compose(
    dispatch,
    setCobraOeHandler,
    ifElse(
      equals('1'),
      always(1),
      always(2),
    ),
    path([ 'target', 'value' ]),
  );

  const handleOeTemplatesChange = compose(
    dispatch,
    setCobraOeTemplateCoverLetter,
    ifElse(
      equals('yes'),
      T,
      F,
    ),
    path([ 'target', 'value' ]),
  );

  const handleContinue = compose(
    ifElse(
      equals(false),
      compose(
        andThen(changeRoute),
        dispatch,
        compose(saveCobraOePacketInfo),
        when(
          compose(isNil, always(cobraOeCoverLetter)),
          compose(dispatch, setCobraOeTemplateCoverLetter , F),
        ),
      ),
      changeRoute,
    ),
    always(checklistComplete),
  );

  return (
    <>
      <FormControl component="fieldset" className="my-8">
        <p>Would you like Flores to mail your packets to participants directly?</p>
        <RadioGroup
          aria-label="mail"
          name="mail"
          value={(cobraOeHandler ?? 2).toString()}
          onChange={handleOeHandlerChange}
        >
          <FormControlLabel
            value="1"
            control={<Radio color="primary" data-testid="mail-packets-yes" />}
            label="Yes (results in a fee of $15 per packet plus printing and shipping, $75 minimum.)"
            disabled={checklistComplete}
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" data-testid="mail-packets-no" />}
            label="No (no fee)"
            disabled={checklistComplete}
          />
        </RadioGroup>
      </FormControl>

      {
        (2 === cobraOeHandler) && (
          <FormControl component="fieldset" className="mb-8" data-testid="question-2">
            <p>
              Would you like to use Flores' system to generate a template cover letter and
              election form that you can mail to your participants at no additional cost?
            </p>
            <RadioGroup
              aria-label="template"
              name="template"
              value={!!cobraOeCoverLetter ? 'yes' : 'no'}
              onChange={handleOeTemplatesChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" data-testid="generate-docs-yes" />}
                label="Yes"
                disabled={checklistComplete}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" data-testid="generate-docs-no" />}
                label="No"
                disabled={checklistComplete}
              />
            </RadioGroup>
          </FormControl>
        )
      }

      <div className="flex flex-row-reverse">
        <Button
          data-testid="oe-general-continue"
          variant="contained"
          color="primary"
          onClick={handleContinue}
        >Continue
        </Button>

        <Button
          className="mr-4"
          onClick={compose(dispatch, cancelModalOpened, noop)}
        >Cancel
        </Button>
      </div>
    </>
  );
}

export default OePacketsOptions;
