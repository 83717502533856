import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { LeaveRecordsTableRow } from './LeaveRecordsTableRow';
import {
  LeaveRecordCaseSummary,
} from 'leave-records/leaveRecord.types';
import store from 'store';
import { selectEmployeeDetails, updateRecentlyViewed } from 'leave-records/leave-records.slice';

interface Props {
  cases: LeaveRecordCaseSummary[];
}

export function LeaveRecordsTable({ cases }: Readonly<Props>) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();
  const { eeId } = useParams<{ eeId: string; }>();
  const employeeDetails = useSelector(selectEmployeeDetails);

  const handleViewEdit = (result: LeaveRecordCaseSummary) => () => {
    dispatch(updateRecentlyViewed({
      ...result,
      eeId: employeeDetails?.eeId,
      firstName: employeeDetails?.firstName,
      lastName: employeeDetails?.lastName,
    }));
    history.push(`/leave-records/${eeId}/case/${result.caseListId}/case-info`);
  };

  return (
    <TableContainer data-testid="leave-records-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="text-sm font-normal whitespace-nowrap">Case ID</TableCell>
            <TableCell className="text-sm font-normal whitespace-nowrap">Leave Start</TableCell>
            <TableCell className="text-sm font-normal whitespace-nowrap">Leave End</TableCell>
            <TableCell className="text-sm font-normal whitespace-nowrap">Reason for Leave</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            cases.length === 0 && (
              <TableRow data-testid="no-records-row">
                <TableCell colSpan={5} className="text-center">Employee has no leave case records.</TableCell>
              </TableRow>
            )
          }
          {
            cases.length > 0 &&
            cases.map((c) => (
              <LeaveRecordsTableRow
                caseSummary={c}
                onViewEdit={handleViewEdit(c)}
                key={`leave-case-row-${c.caseListId}}`}
              />
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
