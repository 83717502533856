import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { LoaDateRangeCounts } from '../loa-report-types';
import { getLoaCountsByDateRange } from 'shared/api/loaDateRangeReportsApi';
import { selectSelectedCompany } from 'companies/companies.slice';

export const defaultCounts: LoaDateRangeCounts = {
  noticesMailed: 0,
  pendingNotices: 0,
  paymentsReceived: 0,
  currentActive: 0,
  monthlyRecap: 0,
  acceptancesReceived: 0,
  invoiceStatus: 0,
  activeWithAddress: 0,
  termsFromLoa: 0,
  turningSixtyFive: 0,
  newEnrollments: 0,
  overDueInvoices: 0,
};

export function useLoaReportCounts(startDate: string, endDate: string) {
  const errorNotifier = useErrorNotifier();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ reportCounts, setReportCounts ] = useState<LoaDateRangeCounts | null>(null);

  const [ setDefault ] = useState(() => compose(setReportCounts, always(defaultCounts)));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (compid > 0 && !!startDate && !!endDate) {
      setReportCounts(null);
      getLoaCountsByDateRange(compid, startDate, endDate)
        .then((result) => {
          if (mountedRef.current) {
            setReportCounts(result ?? defaultCounts);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ handleError, compid, startDate, endDate ]);

  return reportCounts;
}
