import React from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';

import { Participant } from './participant-list.types';
import { avatarColor, getInitials } from 'components/shared/avatar-color-palette';
import { nameCase } from 'shared/utils';
import { selectSelectedCompany } from 'companies/companies.slice';
import { selectSearchText } from './participant.slice';

interface ParticipantResultsRowProps {
  participant: Participant;
  onParticipantSelected: (participant: Participant) => void;
}

function ParticipantResultsRow({
  participant,
  onParticipantSelected,
}: ParticipantResultsRowProps): React.ReactElement {
  const { iddesc = 'SSN' } = useSelector(selectSelectedCompany) ?? { iddesc: undefined };
  const searchText = useSelector(selectSearchText);

  function handleSelected() {
    onParticipantSelected(participant);
  }

  function highlightMatch(str?: string): React.ReactElement {
    const field = str ?? '';
    const lowerStr = field.toLocaleLowerCase();
    const lowerSearch = searchText.toLocaleLowerCase();

    if (searchText.length > 0 && lowerStr.includes(lowerSearch)) {
      const index = lowerStr.indexOf(lowerSearch.toLocaleLowerCase());

      return (
        <span>
          {field.slice(0, index)}
          <b>{field.slice(index, index + searchText.length)}</b>
          {field.slice(index + searchText.length)}
        </span>
      );
    }

    return <span>{str}</span>;
  }

  return (
    <MenuItem className="flex justify-between w-full flex-nowrap space-x-3" onClick={handleSelected}>
      <Avatar className={`p-2 w-10 text-sm ${avatarColor(participant.fname, participant.lname)}`}>
        {getInitials(participant.fname, participant.lname)}
      </Avatar>
      <p className="whitespace-nowrap flex-grow">
        {highlightMatch(nameCase(`${participant.fname} ${participant.lname}`))}
      </p>
      <p className="whitespace-nowrap w-32">PID# {highlightMatch(participant.empno)}</p>
      <p className="whitespace-nowrap">{iddesc}# XXX-XX-{highlightMatch(participant.payrollid ?? '')}</p>
    </MenuItem>
  );
}

export default ParticipantResultsRow;
