import { LoginInfo, User } from './user.types';
import { assertIsDefined } from 'shared/asserts';
import { parseQueryString } from 'shared/utils';

/**
 * Handle the initial landing on the app with potentially a token in the url.
 *
 * @throws if any required environment variables is undefined.
 * @throws if JSON.parse throws
 */
export function loginInit() {
  // if we hit this route with a t=<string> in the query string, we are logging in.
  const queryMap = parseQueryString(window.location.search);

  if (queryMap.has('t')) {
    // login
    const token = queryMap.get('t');
    assertIsDefined(token);
    const info = parseLoginToken(token);
    setLogin(info);

    if (info.user.requiremfa === 'Y') {
      window.localStorage.setItem('isPartialLogin', 'Y');
    }
  } else if (!isLoggedIn()) {
    // not logged in, redirect to login
    window.location.replace(process.env.REACT_APP_LOGIN_URL ?? 'https://flores247.com/');
  }
}

/**
 * Determine if there is a login in the localstorage. This merely checks for user and jwt, and does not validate either.
 *
 * @returns true if there is a login, false if not.
 * @throws if environment variables are not defined
 */
export function isLoggedIn(): boolean {
  const user = getUser();
  const jwt = getJWT();

  return user !== null && jwt.length > 0;
}

/**
 * Parse a login token we get from the API.
 *
 * @param token a base64url encoded string of a user and jwt response
 * @returns an object containing a user object and a jwt token
 * @throws if JSON.parse() throws
 */
export function parseLoginToken(token: string): LoginInfo {
  const unescaped = (token + '==='.slice((token.length + 3) % 4))
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const decoded = Buffer.from(unescaped, 'base64').toString('utf8');

  return JSON.parse(decoded) as LoginInfo;
}

/**
 * Store login information for a user. Does not actually authenticate or validate the info.
 *
 * @param info the user and jwt data to set
 * @throws if environment variables are not defined
 * @throws if JSON.stringify() throws
 */
export function setLogin(info: LoginInfo) {
  const { user, token } = info;
  setUser(user);
  setJWT(token);
}

/**
 * Store JWT information for a user. Does not actually authenticate or validate the info.
 *
 * @param token the jwt data to set
 * @throws if environment variables are not defined
 */
export function setJWT(token: string) {
  assertIsDefined(process.env.REACT_APP_JWT_STORAGE_KEY);
  window.localStorage.setItem(process.env.REACT_APP_JWT_STORAGE_KEY, token);
}

/**
 * Clears the login info from localstorage, effectively logging out the user since any subsequent API calls will fail.
 *
 * @throws if environment variables are not defined
 */
export function logout() {
  assertIsDefined(process.env.REACT_APP_USER_STORAGE_KEY);
  assertIsDefined(process.env.REACT_APP_JWT_STORAGE_KEY);

  window.localStorage.removeItem(process.env.REACT_APP_USER_STORAGE_KEY);
  window.localStorage.removeItem(process.env.REACT_APP_JWT_STORAGE_KEY);
}

/**
 * Get the JWT from localstorage
 * @returns the jwt or empty string if it is not set
 * @throws if the REACT_APP_JWT_STORAGE_KEY environment variable is null or undefined
 */
export function getJWT(): string {
  assertIsDefined(process.env.REACT_APP_JWT_STORAGE_KEY);
  const jwt = window.localStorage.getItem(process.env.REACT_APP_JWT_STORAGE_KEY);

  return jwt ?? '';
}

/**
 * Get the logged in user from localstorage
 * @returns the logged in user or null if it's not in storage
 * @throws if the REACT_APP_USER_STORAGE_KEY environment variable is null or undefined
 * @throws if JSON.parse throws
 */
export function getUser(): User | null {
  assertIsDefined(process.env.REACT_APP_USER_STORAGE_KEY);
  const user = window.localStorage.getItem(process.env.REACT_APP_USER_STORAGE_KEY);

  return user ? JSON.parse(user) : null;
}

/**
 * Set the user in localstorage
 * @param {User} user details to set
 * @throws if the REACT_APP_USER_STORAGE_KEY environment variable is null or undefined
 */
export function setUser(user: User) {
  assertIsDefined(process.env.REACT_APP_USER_STORAGE_KEY);

  window.localStorage.setItem(process.env.REACT_APP_USER_STORAGE_KEY, JSON.stringify(user));
}
