import dayjs from 'dayjs';

import { CobraQe, CobraQePlusPlans, QeBenefits } from 'enter-cobra-qe/cobra-qe.types';
import request from '../api-request';
import { CobraQENotice } from 'components/shared/notice-list/notice-list.types';

function planMapper(benefits: QeBenefits) {
  return { benefits };
}

export function getCobraQe(companyId: number, employeeNumber: string, cQualId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get COBRA QE for invalid company.'));
  }

  if (employeeNumber.length !== 9) {
    return Promise.reject(new Error('Cannot get COBRA QE for invalid employee number.'));
  }

  if (cQualId <= 0) {
    return Promise.reject(new Error('Cannot get COBRA QE for invalid ID.'));
  }

  const uri = `/api/companies/${companyId}/cobra-qe/${employeeNumber}/${cQualId}`;

  return request<CobraQePlusPlans>(uri)
    .then((data) => {
      if (data) {
        return {
          ...data,
          availablePlans: data.availablePlans.map(planMapper) as unknown as QeBenefits[],
        };
      }

      return data;
    });
}

export function updateCobraQe(companyId: number, body: CobraQe) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot update COBRA QEs for invalid company.'));
  }

  return request<CobraQe>(`/api/companies/${companyId}/cobra-qe`, { method: 'PUT', body });
}

export function getCobraAvailablePlans(companyId: number, effectiveDate: string | dayjs.Dayjs) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get available COBRA plans for invalid company.'));
  }

  const date = dayjs(effectiveDate);

  if (!date.isValid()) {
    return Promise.reject(new Error('Cannot get available COBRA plans for invalid effective date.'));
  }

  const uri = `/api/companies/${companyId}/cobra-qe/cobra-plans?effectiveDate=${date.format('YYYY-MM-DD')}`;

  return request<QeBenefits[]>(uri)
    .then((data) => data ? data.map(planMapper) as unknown as QeBenefits[] : []);
}

export function getCobraPendingQeList(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get COBRA pending QEs for invalid company.'));
  }

  return request<CobraQENotice[]>(`/api/companies/${companyId}/reports/pending-notices/qe`);
}

export function getNoBenefitsMessage(coverageStart: string | dayjs.Dayjs) {
  return `No benefits are available as the Coverage Begins date of ` +
  `${dayjs(coverageStart).format('MM/DD/YYYY')}. ` +
  `If that is the wrong date, return to the Personal and Event Information tab and correct the date entry. ` +
  `If that is the correct date, please contact your Flores account manager ` +
  `with the plans that should be available for the specified period.`;
}
