import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { Dependent } from 'shared/types/non-plan-year.types';
import { validationSchema } from './add-new-dependent.validators';
import { handleDateChange, handleSelectChange } from 'shared/form-helpers';
import withModal from 'components/shared/HOC/WithModal';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDayJS from '@mui/lab/AdapterDayjs';

export interface AddNewDependentFormProps {
  onSaveDependent: (data: Dependent) => void;
  onCancel: () => void;
}

export function AddNewDependent({ onSaveDependent, onCancel }: AddNewDependentFormProps): React.ReactElement {
  const form = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      ssn: '',
      dateOfBirth: '',
      relation: '',
      sex: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSaveDependent({ ...values, name: `${values.fname} ${values.lname}`, ssn: values.ssn.toString() } as Dependent);
    },
  });

  const genderLabelRef = useRef<HTMLLabelElement>(null);
  const relationLabelRef = useRef<HTMLLabelElement>(null);

  function handleCancel() {
    onCancel();
  }

  function displayErrorText(y: boolean) {
    if (y) {
      return (
        <FormHelperText
          disabled
          error
          data-testid="selectError"
        >This field is required
        </FormHelperText>
      );
    } else {
      return (<></>);
    }
  }

  return (
    <form data-testid="add-new-dependent-form">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row">
          <TextField
            variant="outlined"
            label="First name"
            name="fname"
            value={form.values.fname}
            error={form.touched.fname && Boolean(form.errors.fname)}
            helperText={form.touched.fname && form.errors.fname}
            onChange={form.handleChange}
            InputProps={
              {
                className: !form.values.fname ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
              }
            }
            fullWidth
            data-testid="add-new-dependent-first-name"
          />
        </div>
        <div className="flex flex-row">
          <TextField
            variant="outlined"
            label="Last name"
            name="lname"
            value={form.values.lname}
            error={form.touched.lname && Boolean(form.errors.lname)}
            helperText={form.touched.lname && form.errors.lname}
            onChange={form.handleChange}
            fullWidth
            InputProps={
              {
                className: !form.values.lname ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
              }
            }
            data-testid="add-new-dependent-last-name"
          />
        </div>
        <div className="flex flex-row">
          <TextField
            variant="outlined"
            name="ssn"
            label="SSN (Optional)"
            value={form.values.ssn}
            onChange={form.handleChange}
            error={form.touched.ssn && Boolean(form.errors.ssn)}
            helperText={form.touched.ssn && form.errors.ssn}
            InputProps={
              {
                className: !form.values.ssn ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
              }
            }
            fullWidth
            data-testid="add-new-dependent-ssn"
          />
        </div>
        <div className="flex flex-row">
          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <DatePicker
              label="Date of Birth (Optional)"
              value={form.values.dateOfBirth ? dayjs(form.values.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
              onChange={handleDateChange('dateOfBirth', form)}
              inputFormat="MM/DD/YYYY"
              InputProps={
                {
                  className: !form.values.dateOfBirth ? 'MuiFormLabel-root' : '',
                  tabIndex: -1,
                }
              }
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  data-testid="add-new-dependent-dob"
                  variant="outlined"
                  className="w-full"
                  {...params}
                  error={form.touched.dateOfBirth && Boolean(form.errors.dateOfBirth)}
                  helperText={form.touched.dateOfBirth && form.errors.dateOfBirth}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="flex flex-row">
          <FormControl variant="outlined" className="w-full">
            <InputLabel ref={relationLabelRef} id="relation-select-label">Relation (Optional)</InputLabel>
            <Select
              onChange={handleSelectChange('relation', form)}
              value={form.values.relation}
              native
              fullWidth
              data-testid="add-new-dependent-relation"
              className={!form.values.relation ? 'MuiFormLabel-root' : ''}
              error={form.touched.relation && Boolean(form.errors.relation)}
              tabIndex={-1}
            >
              <option />
              <option value="S">Spouse</option>
              <option value="C">Child</option>
              <option value="O">Other</option>
            </Select>
            {displayErrorText(!!form.errors.relation)}
          </FormControl>
        </div>
        <div className="flex flex-row">
          <FormControl variant="outlined" className="w-full">
            <InputLabel ref={genderLabelRef} id="gender-select-label">Gender (Optional)</InputLabel>
            <Select
              onChange={handleSelectChange('sex', form)}
              value={form.values.sex}
              native
              fullWidth
              data-testid="add-new-dependent-gender"
              className={!form.values.sex ? 'MuiFormLabel-root' : ''}
              error={form.touched.sex && Boolean(form.errors.sex)}
              tabIndex={-1}
            >
              <option />
              <option value="F">Female</option>
              <option value="M">Male</option>
            </Select>
            {displayErrorText(!!form.errors.sex)}
          </FormControl>
        </div>
      </div>
      <div className="flex justify-end mt-8">
        <Button
          color="primary"
          onClick={handleCancel}
          data-testid="add-new-dependent-cancel"
        >
            Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={form.submitForm}
          data-testid="add-new-dependent-save"
          className="ml-4"
        >
              Save Dependent
        </Button>
      </div>
    </form>
  );
}

export default withModal(AddNewDependent);
