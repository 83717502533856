import request, { APIOptions } from 'shared/api-request';
import { User } from 'authentication/user.types';
import { compose, equals, ifElse, pipe, tap, identity } from 'ramda';
import { parseLoginToken, setLogin } from 'authentication/authentication';
import {
  MultiFactorAuthRequest,
  MultiFactorSetting,
} from 'multi-factor-authentication/multi-factor-auth-request.types';
import { noop } from 'shared/utils';

export function updateMultiFactorSetting(userId: number, enableTwoFactor: boolean) {
  if (userId <= 0) {
    return Promise.reject(new Error('Cannot update 2-step verification setting for invalid user.'));
  }

  const options: APIOptions = { method: 'PUT', body: { enableTwoFactor } };

  return request<User>(`/api/users/${userId}/multi-factor-setting`, options);
}

export function verifyToken(multiFactorRequestBody: MultiFactorAuthRequest) {
  if (!multiFactorRequestBody.userName) {
    return Promise.reject(new Error('Username was not provided.'));
  }

  if (!multiFactorRequestBody.passCode) {
    return Promise.reject(new Error('Passcode was not provided.'));
  }

  return request<string>(
    `/api/two-factor-authentication`,
    { method: 'POST', body: multiFactorRequestBody },
  )
    .then(
      pipe(
        tap(
          ifElse(
            equals(null),
            noop,
            compose(setLogin, parseLoginToken),
          ),
        ),
        identity,
      ),
    );
}

export function resendCode(multiFactorRequestBody: MultiFactorAuthRequest) {
  if (!multiFactorRequestBody.userName) {
    return Promise.reject(new Error('Username was not provided.'));
  }

  return request<string>(
    `/api/two-factor-authentication/resend-passcode`,
    { method: 'POST', body: multiFactorRequestBody },
  );
}

export function getMultiFactorSetting() {
  return request<MultiFactorSetting>(
    `/api/users/multi-factor-setting`,
    { method: 'GET' },
  );
}
