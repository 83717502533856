import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/reports/pending-notices/initial', (schema) => {
    const { models } = schema.initNotices.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.delete('/companies/:companyId/initial-notices/:employeeNumber/:cInitId', (schema, request) => {
    const { attrs: data } = schema.initNotices.findBy({ c_initid: request.params.cInitId });
    if (data) {
      return new Response(204, { 'content-length': 0 });
    }

    return new Response(404, { 'content-length': 0 });
  });

  server.post('/companies/:companyId/initial-notices', () => {
    return new Response(204, { 'content-length': 0 });
  });
}

export default routing;
