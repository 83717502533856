import React from 'react';

import ContributionsNotForwardedTable
  from 'reports/shared/ContributionsNotForwardedTable/ContributionsNotForwardedTable';
import contributions from 'server/fixtures/contributions-not-forwarded.json';

/*
NOTE: This is a placeholder component used for development. When you're making
a component that doesn't have a place elsewhere in the app, you can drop it in
here, yarn start, and navigate to localhost:3000/scratchpad to see how it's
looking and working.
*/
function Scratchpad(): React.ReactElement {
  return (
    <ContributionsNotForwardedTable rows={contributions} />
  );
}

export default Scratchpad;
