import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { useHistory } from 'react-router-dom';
import { compose, always } from 'ramda';

import PageContentPaper from 'components/layout/PageContentPaper';
import { getUser } from 'authentication/authentication';
import { getMultiFactorSetting, updateMultiFactorSetting } from 'shared/api/multiFactorApi';
import { useErrorNotifier, useSuccessNotifier } from 'shared/custom-hooks/useNotifiers';

export function MultiFactorSettings() {
  const handleError = useErrorNotifier();
  const handleSuccess = useSuccessNotifier('Settings saved');
  const history = useHistory();
  const [ enableTfa, setEnableTfa ] = useState<boolean>(false);

  useEffect(() => {
    getMultiFactorSetting()
      .then((data) => {
        if (data) {
          const { requireMfa } = data;
          setEnableTfa(requireMfa === 'Y');
        }
      });
  }, []);

  function handleSave() {
    const user = getUser();

    if (user) {
      updateMultiFactorSetting(user.conuserid, enableTfa)
        .then(handleSuccess)
        .catch(handleError);
    }
  }

  function toggleTfa() {
    setEnableTfa(!enableTfa);
  }

  return (
    <div className="w-1/2">
      <PageContentPaper>
        <h4>2-step verification</h4>
        <div className="space-y-8">
          <p>Require a security code in addition to your password.</p>
          <div className="w-full">
            <TextField
              value={(getUser() ?? {}).username ?? ''}
              disabled
              className="w-full"
              variant="outlined"
              label="Email Address"
            />
          </div>
          <div>
            <Switch
              checked={enableTfa}
              color="primary"
              name="requiremfa"
              onChange={toggleTfa}
              data-testid
            /> 2-Step Verification
          </div>
        </div>
        <div className="mt-12 flex justify-end space-x-4">
          <Button
            variant="text"
            color="primary"
            onClick={compose(history.push, always('/manage-settings/contact-info'))}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </div>
      </PageContentPaper>
    </div>
  );
}

export default MultiFactorSettings;
