import React, { ReactElement } from 'react';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import { always, compose } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import withModal from 'components/shared/HOC/WithModal';
import { validationSchema } from './cancel-billing-event.validators';
import { selectSelectedCompany } from 'companies/companies.slice';
import { addHttpErrorNotification, addHttpSuccessNotification } from 'notifications/notifications.slice';
import { CobraEnrollment } from 'participant-overview/enrollment/enrollment.types';
import { handleDateChange } from 'shared/form-helpers';
import { cancelDirectBillingEvent } from 'shared/api/directBillingApi';
import { selectParticipant } from 'participant-search/participant.slice';
import { loadLoaEnrollments, loadRetireeEnrollments } from 'participant-overview/participant-overview.slice';

import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import store from 'store';

export interface InvoiceTableProps {
  enrollment: CobraEnrollment | null;
  onCancel: () => void;
}

function formatDateValue(val?: string) {
  return val ? dayjs(val, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;
}

function CancelDirectBillingEvent({ enrollment, onCancel }: InvoiceTableProps): ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const selectedParticipant = useSelector(selectParticipant);
  const handleError = compose(
    onCancel,
    dispatch,
    addHttpErrorNotification,
  );

  const handleSuccess = compose(
    compose(dispatch, always(loadLoaEnrollments(compid!, selectedParticipant!.empno))),
    compose(dispatch, always(loadRetireeEnrollments(compid!, selectedParticipant!.empno))),
    onCancel,
    dispatch,
    addHttpSuccessNotification,
    always('Successfully cancelled the event.'),
  );

  const form = useFormik({
    initialValues: {
      covend: '',
    },
    validationSchema,
    onSubmit: (values) => {
      cancelDirectBillingEvent(compid!, {
        participant: selectedParticipant,
        covend: values.covend,
        r_qualid: enrollment?.cQualId,
      }).then(handleSuccess).catch(handleError);
    },
  });

  return (
    <div>
      <p>Cancel billing event by specifying a new period end:</p>

      <form onSubmit={form.handleSubmit} className="mt-4">

        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="New Period End Date"
            value={formatDateValue(form.values.covend)}
            onChange={handleDateChange('covend', form)}
            InputProps={
              {
                className: !form.values.covend ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
              }
            }
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                data-testid="cancel-date"
                variant="outlined"
                className="w-full"
                {...params}
                error={form.touched.covend && Boolean(form.errors.covend)}
                helperText={form.touched.covend && form.errors.covend}
              />
            )}
          />
        </LocalizationProvider>

        <div className="flex flex-row-reverse mt-4">
          <Button
            variant="contained"
            className={'ml-2 text-sm bg-danger text-white'}
            type="submit"
          >Cancel Billing Event
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={onCancel}
          >Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export const CancelDirectBillingEventModal = withModal(CancelDirectBillingEvent);

export default CancelDirectBillingEvent;
