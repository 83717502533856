import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { always, compose, tap } from 'ramda';
import dayjs from 'dayjs';

import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import ReportsTile from 'reports/shared/ReportsTile';
import {
  getCustomBalanceReportCounts,
  downloadCustomBalanceReport,
  downloadCustomReimbursementRecapReport,
} from 'shared/api/customReportsByDateRangeApi';
import { BalanceAccountCount } from 'reports/balance/balance-account.types';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import ReportDateRangeFilter from 'reports/shared/ReportDateRangeFilter';

interface CustomReportsByDateRangeProps {
  selectedPlan?: OpenPlan;
}

function CustomReportsByDateRange({ selectedPlan }: CustomReportsByDateRangeProps) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ startDate, setStartDate ] = useState<string | null>(dayjs().format('MM/DD/YYYY'));
  const [ endDate, setEndDate ] = useState<string | null>(dayjs().format('MM/DD/YYYY'));
  const [ counts, setCounts ] = useState<BalanceAccountCount | null>(null);
  const [ downloadingReimbursement, setDownloadingReimbursement ] = useState(false);
  const [ downloadingBalance, setDownloadingBalance ] = useState(false);
  const errorNotifier = useErrorNotifier();
  const [ handleError ] = useState(() => compose(
    tap(compose(setCounts, always(null)),
      errorNotifier,
    )));

  const Counts = withLoopIcon(makeCountTiles, '', !!selectedPlan && !counts && !!startDate && !!endDate);

  useEffect(() => {
    if (startDate && endDate && selectedPlan) {
      setCounts(null);
      getCustomBalanceReportCounts(
        compid,
        selectedPlan.pyrid,
        selectedPlan.plcid,
        startDate,
        endDate,
      )
        .then(setCounts)
        .catch(handleError);
    }
  }, [ compid, endDate, startDate, selectedPlan, handleError ]);

  function handleApply(start: string, end: string) {
    setStartDate(start);
    setEndDate(end);
  }

  function handleBalanceExport() {
    if (selectedPlan) {
      setDownloadingBalance(true);
      downloadCustomBalanceReport(compid, selectedPlan.pyrid, selectedPlan.plcid, startDate || '', endDate || '')
        .catch(handleError)
        .finally(() => setDownloadingBalance(false));
    }
  }

  function handleReimbursementExport() {
    if (selectedPlan) {
      setDownloadingReimbursement(true);
      downloadCustomReimbursementRecapReport(compid, selectedPlan.pyrid, selectedPlan.plcid,
        startDate || '', endDate || '')
        .catch(errorNotifier)
        .finally(() => setDownloadingReimbursement(false));
    }
  }

  function makeCountTiles() {
    if (!counts) {
      return <></>;
    }

    return (
      <div className="flex justify-end space-x-4 mt-4" data-testid="custom-balance-reports-counts-loaded">
        <div className="w-1/2">
          <ReportsTile
            count={counts.reimbursement}
            title="Reimbursements By Participant"
            description="List of reimbursements issued per participant within the date range selected."
            exportDisabled={downloadingReimbursement}
            onExport={handleReimbursementExport}
            downloading={downloadingReimbursement}
          />
        </div>
        {
          selectedPlan?.isBalance
            ? (
              <div className="w-1/2">
                <ReportsTile
                  count={counts.balance}
                  title="Balance Report"
                  description="Live data of contributions and reimbursements within the date range selected."
                  exportDisabled={downloadingBalance}
                  onExport={handleBalanceExport}
                  downloading={downloadingBalance}
                />
              </div>
            )
            : <></>
        }
      </div>
    );
  }

  return (
    <div>
      <ReportDateRangeFilter onFilterChange={handleApply} />
      <div>
        <Counts />
      </div>
    </div>
  );
}

export default CustomReportsByDateRange;
