import { ApiStatus } from 'shared/types/api-status.types';
import {
  ContributionsNotForwarded,
} from 'reports/shared/ContributionsNotForwardedTable/contributions-not-forwarded.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchHsaContributionsNotForwarded,
} from 'action-items/action-items.thunks';
import { RootState } from 'store/rootReducer';

export interface ActionItemsSlice {
    fetchContributionsStatus: ApiStatus;
    hsaContributionsNotForwarded: ContributionsNotForwarded[];
}

export const initialState: ActionItemsSlice = {
  fetchContributionsStatus: 'uninitialized',
  hsaContributionsNotForwarded: [],
};

const actionItemsSlice = createSlice({
  name: 'actionItems',
  initialState,
  reducers: {
    setHsaContributionsNotForwarded(state, { payload }: PayloadAction<ContributionsNotForwarded[]>) {
      state.hsaContributionsNotForwarded = payload;
    },
    setContributionsStatus(state, { payload }: PayloadAction<ApiStatus>) {
      state.fetchContributionsStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHsaContributionsNotForwarded.pending, (state) => {
        state.fetchContributionsStatus = 'pending';
      })
      .addCase(fetchHsaContributionsNotForwarded.rejected, (state) => {
        state.fetchContributionsStatus = 'rejected';
      })
      .addCase(fetchHsaContributionsNotForwarded.fulfilled, (state, action) => {
        state.fetchContributionsStatus = 'fulfilled';
        state.hsaContributionsNotForwarded = action.payload;
      });
  },
});

export const {
  setHsaContributionsNotForwarded,
  setContributionsStatus,
} = actionItemsSlice.actions;

export default actionItemsSlice.reducer;

export const selectContributionsNotForwardedCount = (state: RootState) => {
  return state.actionItems.hsaContributionsNotForwarded
    .reduce((acc, curr) => acc + curr.contributions.length, 0);
};

export const selectContributionsNotForwarded = (state: RootState) => {
  return state.actionItems.hsaContributionsNotForwarded;
};

export const selectContributionsNotForwardedFetchStatus = (state: RootState) => {
  return state.actionItems.fetchContributionsStatus;
};

export const selectShowActionItemsSection = (state: RootState) => {
  return selectContributionsNotForwardedCount(state) > 0;
};
