import { useEffect } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import Divider from '@mui/material/Divider';

import DisplayCobRetLoaEnrollment, { DisplayCobRetLoaEnrollmentProps } from './enrollment/DisplayCobRetLoaEnrollment';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import ListEnrollments, { ListEnrollmentsProps } from './enrollment/ListEnrollments';
import { useDispatch, useSelector } from 'react-redux';
import { loadEnrollments, selectRegularEnrollments } from './enrollment/enrollments.slice';
import { selectSelectedCompany } from 'companies/companies.slice';
import {
  loadCobraEnrollments,
  loadLoaEnrollments,
  loadRetireeEnrollments,
  selectCobraEnrollments,
  selectLoaEnrollments,
  selectRetireeEnrollments,
} from 'participant-overview/participant-overview.slice';
import store from 'store';

function PlanDivider() {
  return <Divider className="my-10 text-neutral5 -mx-4" />;
}

interface PlanEnrollmentProps {
  employeeNumber: string;
}

function PlanEnrollmentTab({ employeeNumber }: PlanEnrollmentProps) {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const cobEnrollment = useSelector(selectCobraEnrollments);
  const retEnrollment = useSelector(selectRetireeEnrollments);
  const loaEnrollment = useSelector(selectLoaEnrollments);
  const planEnrollments = useSelector(selectRegularEnrollments);

  useEffect(() => {
    dispatch(loadEnrollments(compid, employeeNumber));
    dispatch(loadCobraEnrollments(compid, employeeNumber));
    dispatch(loadRetireeEnrollments(compid, employeeNumber));
    dispatch(loadLoaEnrollments(compid, employeeNumber));
  }, [ dispatch, compid, employeeNumber ]);

  function handleClick() {
    history.push(`/plan-enrollment/${employeeNumber}/plan-type`);
  }

  const CobraEnrollments = withLoopIcon<DisplayCobRetLoaEnrollmentProps>(
    DisplayCobRetLoaEnrollment,
    'Loading COBRA Enrollments...',
    cobEnrollment === null,
  );

  const RetireeEnrollments = withLoopIcon<DisplayCobRetLoaEnrollmentProps>(
    DisplayCobRetLoaEnrollment,
    'Loading Retiree Enrollments...',
    retEnrollment === null,
  );

  const LOAEnrollments = withLoopIcon<DisplayCobRetLoaEnrollmentProps>(
    DisplayCobRetLoaEnrollment,
    'Loading LOA Enrollments...',
    loaEnrollment === null,
  );

  const PlanEnrollments = withLoopIcon<ListEnrollmentsProps>(
    ListEnrollments,
    'Loading Plan Enrollments...',
    planEnrollments === null,
  );

  function planDivider() {
    return <PlanDivider />;
  }

  return (
    <>
      <h6 className="mb-4" data-testid="planEnrollment">Plan Enrollment</h6>
      <p>To enroll this employee in a benefit Flores administers click enroll participant.</p>
      <Button
        variant="contained"
        color="primary"
        className="uppercase mt-8 font-normal enroll-participant-btn"
        onClick={handleClick}
      >
        <PersonAddIcon className="mr-1" /> Enroll Participant
      </Button>
      {cobEnrollment?.length ? <PlanDivider /> : <></>}
      <CobraEnrollments enrollment={cobEnrollment ?? []} title="COBRA" type="c" />
      {retEnrollment?.length ? <PlanDivider /> : <></>}
      <RetireeEnrollments enrollment={retEnrollment ?? []} title="Retiree Billing" type="r" />
      {loaEnrollment?.length ? <PlanDivider /> : <></>}
      <LOAEnrollments enrollment={loaEnrollment ?? []} title="LOA Billing" type="l" />
      {planEnrollments?.length ? <PlanDivider /> : <></>}
      <PlanEnrollments enrollments={planEnrollments ?? []} divider={planDivider} />
    </>
  );
}

export default PlanEnrollmentTab;
