import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { always, compose, pipe } from 'ramda';

import { colorFromLetters } from 'components/shared/avatar-color-palette';
import { SummaryUser } from 'manage-settings/manage-users/manage-users.types';
import { Contact } from 'manage-settings/contact-info/contact.types';
import { useDispatch } from 'react-redux';
import { loadSelectedUser } from 'manage-settings/manage-users/manage-users.slice';
import store from 'store';

interface UserCardProps {
  user: SummaryUser;
}

function avatarColor(contact: Contact): string {
  return colorFromLetters(`${contact.confname} ${contact.conlname}`);
}

function initials(contact: Contact) {
  const firstName = contact.confname ?? '';
  const lastName = contact.conlname ?? '';

  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
}

export function UserCard({ user }: UserCardProps) {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { responsibilities, locations, payFrequencies } = user;

  function setUser() {
    dispatch(loadSelectedUser(user.conid!));
  }

  return (
    <Paper elevation={2} className="p-4 border-neutral5 bg-white flex-col">
      <div className="w-full pb-4 flex border-b border-neutral4 border-solid">
        <Avatar className={`p-2 mr-4 w-10 text-sm ${avatarColor(user)}`}>
          {initials(user)}
        </Avatar>
        <div className="flex-grow">
          <p className="font-bold text-outer-space">{user.confname} {user.conlname}</p>
          <p className="text-xs">{user.conemail}</p>
          <p className="text-xs">{user.conphone}</p>
        </div>
        <div>
          <Button
            className="min-w-0 min-h-0 text-stone"
            size="small"
            aria-label="Edit User"
            onClick={
              pipe(
                setUser,
                compose(history.push, always(`/edit-user/step-1`)),
              )
            }
          >
            <EditIcon fontSize="small" />
          </Button>
        </div>
      </div>

      <div className="w-full py-4 flex border-b border-neutral4 border-solid">
        <div className="w-1/2">
          <p className="font-bold text-sm text-outer-space">Locations</p>
          { (locations as string[]).map((loc) => <p className="text-xs" key={loc}>{loc}</p>) }
        </div>
        <div className="w-1/2">
          <p className="font-bold text-sm text-outer-space">Pay Frequencies</p>
          { (payFrequencies as string[]).map((pf) => <p className="text-xs" key={pf}>{pf}</p>) }
        </div>
      </div>

      <div className="w-full pt-4 flex flex-wrap">
        <p className="w-full font-bold text-sm text-outer-space">Responsibilities</p>
        {
          (responsibilities as string[])
            .slice(0,5)
            .map((resp) => <p className="w-1/2 text-xs" key={resp}>{resp}</p>)
        }
        {
          (responsibilities as string[]).length > 5
            ? <p className="w-1/2 text-xs">+{ responsibilities.length - 5 }</p>
            : <></>
        }
      </div>
    </Paper>
  );
}

export default UserCard;
