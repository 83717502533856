import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { CobraEnrollment } from './enrollment/enrollment.types';
import { getEnrollmentByType } from 'shared/api/enrollmentApi';
import { EnrollmentType } from 'participant-overview/participant-overview.types';

export interface ParticipantOverviewState {
  cobraEnrollments: CobraEnrollment[] | null;
  retireeEnrollments: CobraEnrollment[] | null;
  loaEnrollments: CobraEnrollment[] | null;
}

export const initialState: ParticipantOverviewState = {
  cobraEnrollments: [],
  retireeEnrollments: [],
  loaEnrollments: [],
};

const participantOverviewSlice = createSlice({
  name: 'participantOverview',
  initialState,
  reducers: {
    loadCobraEnrollmentsStart(state) {
      state.cobraEnrollments = null;
    },
    setCobraEnrollments(state, { payload }: PayloadAction<CobraEnrollment[] | null>) {
      state.cobraEnrollments = payload;
    },
    loadRetireeEnrollmentsStart(state) {
      state.retireeEnrollments = null;
    },
    setRetireeEnrollments(state, { payload }: PayloadAction<CobraEnrollment[] | null>) {
      state.retireeEnrollments = payload;
    },
    loadLoaEnrollmentsStart(state) {
      state.loaEnrollments = null;
    },
    setLoaEnrollments(state, { payload }: PayloadAction<CobraEnrollment[] | null>) {
      state.loaEnrollments = payload;
    },
  },
});

export const {
  loadCobraEnrollmentsStart,
  setCobraEnrollments,
  loadRetireeEnrollmentsStart,
  setRetireeEnrollments,
  loadLoaEnrollmentsStart,
  setLoaEnrollments,
} = participantOverviewSlice.actions;

export default participantOverviewSlice.reducer;

// thunks
export function loadCobraEnrollments(companyId: number, employeeNumber: string): AppThunk {
  return async (dispatch) => {
    dispatch(loadCobraEnrollmentsStart());

    try {
      const enrollments = await getEnrollmentByType(companyId, employeeNumber, EnrollmentType.COBRA);
      dispatch(setCobraEnrollments(enrollments));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
      dispatch(setCobraEnrollments([]));
    }
  };
}

export function loadRetireeEnrollments(companyId: number, employeeNumber: string): AppThunk {
  return async (dispatch) => {
    dispatch(loadRetireeEnrollmentsStart());

    try {
      const enrollments = await getEnrollmentByType(companyId, employeeNumber, EnrollmentType.Retiree);
      dispatch(setRetireeEnrollments(enrollments));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
      dispatch(setRetireeEnrollments([]));
    }
  };
}

export function loadLoaEnrollments(companyId: number, employeeNumber: string): AppThunk {
  return async (dispatch) => {
    dispatch(loadLoaEnrollmentsStart());

    try {
      const enrollments = await getEnrollmentByType(companyId, employeeNumber, EnrollmentType.LOA);
      dispatch(setLoaEnrollments(enrollments));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
      dispatch(setLoaEnrollments([]));
    }
  };
}

// selectors
export const selectCobraEnrollments = (state: RootState) => state.participantOverview.cobraEnrollments;

export const selectRetireeEnrollments = (state: RootState) => state.participantOverview.retireeEnrollments;

export const selectLoaEnrollments = (state: RootState) => state.participantOverview.loaEnrollments;
