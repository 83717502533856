import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { Enrollment } from './enrollment.types';
import { deleteEnrollment, getEnrollments } from 'shared/api/enrollmentApi';

export interface EnrollmentsState {
  regular: Enrollment[] | null;
  deleting: boolean;
}

export const initialState: EnrollmentsState = {
  regular: [],
  deleting: false,
};

const enrollmentsSlice = createSlice({
  name: 'enrollments',
  initialState,
  reducers: {
    loadRegularEnrollmentsStart(state) {
      state.regular = null;
    },
    setRegularEnrollments(state, { payload }: PayloadAction<Enrollment[] | null>) {
      state.regular = payload;
    },
    startDeleting(state) {
      state.deleting = true;
    },
    doneDeleting(state) {
      state.deleting = false;
    },
    removeEnrollment(state, { payload }: PayloadAction<Enrollment>) {
      if (state.regular) {
        state.regular = state.regular.filter((e) => e.pyrid !== payload.pyrid || e.planCode !== payload.planCode);
      }
    },
  },
});

export const {
  loadRegularEnrollmentsStart,
  setRegularEnrollments,
  startDeleting,
  doneDeleting,
  removeEnrollment,
} = enrollmentsSlice.actions;

export default enrollmentsSlice.reducer;

// thunks
export function loadEnrollments(companyId: number, employeeNumber: string): AppThunk {
  return async (dispatch) => {
    dispatch(loadRegularEnrollmentsStart());

    try {
      const enrollments = await getEnrollments(companyId, employeeNumber);
      dispatch(setRegularEnrollments(enrollments));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
      dispatch(setRegularEnrollments([]));
    }
  };
}

export function deleteRegularEnrollment(companyId: number, employeeNumber: string, enrollment: Enrollment): AppThunk {
  return async (dispatch) => {
    dispatch(startDeleting());

    const { pyrid, planCode } = enrollment;

    try {
      await deleteEnrollment(companyId, parseInt(pyrid), planCode, employeeNumber, enrollment.customPlanId);
      dispatch(removeEnrollment(enrollment));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
    } finally {
      dispatch(doneDeleting());
    }
  };
}

// selectors
export const selectRegularEnrollments = (state: RootState) => state.enrollments.regular;

export const selectDeletingEnrollment = (state: RootState) => state.enrollments.deleting;
