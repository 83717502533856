import React, { ReactElement, useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import { always, compose, isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import disablePopup from 'images/disable-popup.svg';
import { usePlanDisplayNames } from './usePlanDisplayNames';
import EnrollmentSection from './EnrollmentSection';
import { selectSelectedCompany } from 'companies/companies.slice';
import {
  loadEnrollmentResourcePlans,
  selectEnrollmentResourcePlans,
} from 'open-enrollment-resources/open-enrollment-resources.slice';
import store from 'store';

function OpenEnrollmentResources(): ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const plans = useSelector(selectEnrollmentResourcePlans);
  const { hasCobra, hasRetiree } = useSelector(selectSelectedCompany) ?? { hasCobra: undefined, hasRetiree: undefined };
  const [ abbreviations, displayNames ] = usePlanDisplayNames(plans, hasCobra, hasRetiree);
  const [ active, setActive ] = useState<number | null>(null);

  useEffect(() => {
    dispatch(loadEnrollmentResourcePlans());
  }, [ dispatch ]);

  return (
    <>
      <div className="w-full flex flex-col p-4">
        <h2 className="page-title">Open Enrollment Resources</h2>
        {
          plans === null
            ? <p className="animate-pulse text-2xl">Loading Open Enrollment Resources...</p>
            : (
              <>
                <div className="w-full flex pb-4">
                  <Chip
                    label="All"
                    variant={isNil(active) ? undefined : 'outlined'}
                    className="cursor-pointer"
                    onClick={compose(setActive, always(null))}
                  />
                  {
                    abbreviations.map((plan, i) => (
                      <Chip
                        label={plan}
                        variant={i === active ? undefined : 'outlined'}
                        key={plan}
                        className="ml-2 cursor-pointer"
                        onClick={compose(setActive, always(i))}
                      />
                    ))
                  }
                  <a
                    href="https://www.flores-associates.com/resource-open-enrollment.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Chip
                      label="Additional Resources"
                      variant="outlined"
                      className="ml-2 cursor-pointer"
                    />
                  </a>
                </div>
                <div className="w-full">
                  {
                    displayNames
                      .filter((plan, i) => isNil(active) || i === active)
                      .map(plan => (
                        <div key={plan} className="pb-4">
                          <h6 className="pb-2">{plan}</h6>
                          <EnrollmentSection plan={plan} />
                        </div>
                      ))
                  }
                </div>
              </>
            )
        }
        <div className="mt-4 w-1/2 m-auto">
          <p>If the online re-enrollment checklist has not opened in a new tab, you may have a pop-up blocker enabled
            that is preventing the checklist from opening. To disable, click on the icon in the highlighted box below
            and check “Allow Pop Ups for www.flores247.com only.” Click on the Open Enrollment tab again and the
            re-enrollment checklist should open. If you have any questions, or are unable to open the checklist, please
            contact your Flores Account Manager.
          </p>
          <CardMedia
            component="img"
            src={disablePopup}
            title="Disable Popup Help Text"
          />
        </div>
      </div>
    </>
  );
}

export default OpenEnrollmentResources;
