import React, { ComponentType, ReactElement, useEffect, useRef, useState } from 'react';
import { always, andThen, assocPath, compose, otherwise, pipe, tap } from 'ramda';
import { useDispatch } from 'react-redux';

import request from 'shared/api-request';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { EnrollmentTransaction } from '../enrollment.types';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import withModal from 'components/shared/HOC/WithModal';
import store from 'store';

interface Props {
  pyrid: string;
  empno: string;
  compid: number;
  plccode: string;
  isOpen: boolean;
  transactions: EnrollmentTransaction[];
  onClose: (args?: unknown) => void;
}

function makeUri({ compid, empno, pyrid, plccode }: Partial<Props>) {
  return `/api/companies/${compid}/plan-enrollment/${pyrid}/transactions/${empno}/plan/${plccode}`;
}

function withTransactionModal<P extends Props>(WrappedComponent: ComponentType<React.PropsWithChildren<P>>) {
  function WithTransactionModal (
    { compid, empno, pyrid, plccode, ...props }: P,
  ): ReactElement {
    const dispatch = useDispatch<typeof store.dispatch>();
    const [ transactions, setTransactions ] = useState<EnrollmentTransaction[] | null>(null);
    const { current: handleError } = useRef(
      compose(
        tap<unknown>(compose(setTransactions, always([]))),
        dispatch,
        addHttpErrorNotification,
      ),
    );
    const { current: setLoading } = useRef(compose(setTransactions, always(null)));

    useEffect(() => {
      pipe(
        tap(setLoading),
        makeUri,
        request,
        andThen(setTransactions),
        otherwise(handleError),
      )({ compid, empno, pyrid, plccode });
    }, [ setLoading, handleError, compid, empno, pyrid, plccode ]);

    const TransactionsList = withLoopIcon<P>(
      WrappedComponent,
      'Loading transactions...',
      !transactions,
    );

    return (
      <TransactionsList {...props as P} transactions={transactions} />
    );
  }

  const wrappedComponentName = WrappedComponent.displayName ?? WrappedComponent.name;
  WithTransactionModal.displayName = `withTransactionModal(${wrappedComponentName})`;

  // add extra withModal props w/o having to do it in jsx for everything that uses WithTransactionModal
  return compose(
    withModal(WithTransactionModal),
    assocPath([ 'title' ], 'Transactions'),
    assocPath([ 'classes', 'modalContainer' ], 'modal'),
  );
}

export default withTransactionModal;
