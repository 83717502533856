import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import { compose, F } from 'ramda';

import { FloresDocument } from './view-documents.types';

interface DocumentTableRowProps {
  document: FloresDocument;
  onDownloadDocument: (e: FloresDocument) => Promise<any>;
}

function InvoiceTableRow({ document, onDownloadDocument }: DocumentTableRowProps): React.ReactElement {
  const [ downloading, setDownloading ] = useState(false);

  function handleDownload() {
    setDownloading(true);
    onDownloadDocument(document).finally(compose(setDownloading, F));
  }

  return (
    <TableRow>
      <TableCell>{dayjs(document.receivedDate).format('M/D/YYYY')}</TableCell>
      <TableCell>{document.description}</TableCell>
      <TableCell>{document.documentNumber}</TableCell>
      <TableCell>
        <Button
          color="primary"
          type="button"
          data-testid="document-export"
          tabIndex={-1}
          disabled={downloading}
          onClick={handleDownload}
        >
          <GetAppIcon />Export
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default InvoiceTableRow;
