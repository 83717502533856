import { always, compose, tap, T, F } from 'ramda';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import wizardSteps, { DBEStepSlug } from './direct-billing-steps';
import WizardStepper, { WizardStep } from 'components/shared/WizardStepper';
import { useHistoryBlock } from 'shared/custom-hooks/useHistoryBlock';
import { resetEntry } from './direct-billing.slice';
import { noop } from 'shared/utils';
import PageContentPaper from 'components/layout/PageContentPaper';
import CancelModal from 'components/shared/CancelModal';
import DirectBillingPersonalDetails from './personal-details/DirectBillingPersonalDetails';
import DirectBillingEventInfo from './event-info/DirectBillingEventInfo';
import DirectBillingQeDependents from './dependents/DirectBillingQeDependents';
import DirectBillingBenefits from './benefits/DirectBillBenefits';
import DirectBillingSummary from './summary/DirectBillingSummary';
import store from 'store';

function DirectBillingWizard() {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const {
    stepSlug: activeStepSlug,
  } = useParams<{ stepSlug: DBEStepSlug; }>();

  const [ unmountCallback ] = useState(() => compose(dispatch, resetEntry));
  const [ blockRegex ] = useState(/^\/direct-bill-event/);
  const navUnblock = useHistoryBlock(blockRegex, unmountCallback);

  const [ modalOpen, setModalOpen ] = useState(false);
  const showModal = compose(setModalOpen, T);
  const hideModal = compose(setModalOpen, F);

  const goToDashboard = compose(
    tap(compose(history.push, always('/dashboard'))),
    navUnblock ?? noop,
    hideModal,
  );

  function handleStepClick(clickedStep: WizardStep) {
    return history.push(`/direct-bill-event/${clickedStep.slug}`);
  }

  function onWizardCancel() {
    if (activeStepSlug !== wizardSteps.keys().next().value) {
      showModal();
    } else {
      goToDashboard();
    }
  }

  function renderStep() {
    switch (activeStepSlug) {
    case 'personal-details':
      return <DirectBillingPersonalDetails onCancel={onWizardCancel} />;
    case 'event-info':
      return <DirectBillingEventInfo onCancel={onWizardCancel} />;
    case 'dependents':
      return <DirectBillingQeDependents onCancel={onWizardCancel} />;
    case 'benefits':
      return <DirectBillingBenefits onCancel={onWizardCancel} />;
    case 'summary':
      return <DirectBillingSummary onCancel={onWizardCancel} />;
    }
  }

  return (
    <>
      <PageContentPaper className="mt-4 mb-8">
        <div className="mb-8">
          <WizardStepper
            steps={wizardSteps}
            allowClick
            onChange={handleStepClick}
          />
        </div>
        <div className="px-8">
          {renderStep()}
        </div>
      </PageContentPaper>
      <CancelModal
        isOpen={modalOpen}
        onCancel={goToDashboard}
        onClose={hideModal}
      />
    </>
  );
}

export default DirectBillingWizard;
