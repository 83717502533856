import { ApiStatus } from 'shared/types/api-status.types';
import { createSlice } from '@reduxjs/toolkit';
import {
  __,
  compose,
  dropLast,
  gt,
  identity,
  ifElse,
  insert,
  length,
  without,
} from 'ramda';
import {
  EmployeeSearchResults,
  LeaveRecordCaseInfo,
  LeaveRecordCaseSummary,
  LeaveRecordCaseSummaryAndName,
  LeaveRecordsAbsenceSummary,
  LeaveRecordsEmployeeInfo,
} from 'leave-records/leaveRecord.types';
import {
  fetchCaseDetails,
  fetchCasesSummary,
  fetchEmployeeAbsences,
  fetchEmployeeDetails,
  fetchEmployeeSearchResultsByCriteria,
} from 'leave-records/leave-records.thunk';
import { RootState } from 'store/rootReducer';

export interface LeaveRecordsState {
  employeeDetailsStatus: ApiStatus;
  casesSummaryStatus: ApiStatus;
  caseDetailsStatus: ApiStatus;
  employeeAbsencesStatus: ApiStatus;
  employeeSearchStatus: ApiStatus;
  employeeDetails: LeaveRecordsEmployeeInfo | null;
  caseDetails: LeaveRecordCaseInfo | null;
  casesSummary: LeaveRecordCaseSummary[];
  employeeAbsences: LeaveRecordsAbsenceSummary[];
  employeeSearchResults: EmployeeSearchResults[];
  recentlyViewedEmployeeAbsences: LeaveRecordCaseSummaryAndName[];
}

export const initialState: LeaveRecordsState = {
  employeeDetailsStatus: 'uninitialized',
  casesSummaryStatus: 'uninitialized',
  caseDetailsStatus: 'uninitialized',
  employeeAbsencesStatus: 'uninitialized',
  employeeSearchStatus: 'uninitialized',
  employeeDetails: null,
  caseDetails: null,
  casesSummary: [],
  employeeAbsences: [],
  employeeSearchResults: [],
  recentlyViewedEmployeeAbsences: [],
};

const LeaveRequestsSlice = createSlice({
  name: 'leaveRecords',
  initialState,
  reducers: {
    resetLeaveRecordsApiStatus(state) {
      state.employeeDetailsStatus = initialState.employeeDetailsStatus;
      state.casesSummaryStatus = initialState.casesSummaryStatus;
      state.caseDetailsStatus = initialState.caseDetailsStatus;
      state.employeeAbsencesStatus = initialState.employeeAbsencesStatus;
      state.employeeSearchStatus = initialState.employeeSearchStatus;
      state.employeeSearchResults = [];
    },
    getRecentlyViewedFromLocalStorage(state) {
      state
        .recentlyViewedEmployeeAbsences = JSON
          .parse(window.localStorage.getItem('leaveSolutionsRecentlyViewed') ?? '[]');
    },
    updateRecentlyViewed(state, action) {
      const updated = compose(
        ifElse(
          compose(gt(__, 10), length),
          dropLast(1),
          identity,
        ),
        insert(0, action.payload),
        without([ action.payload ]),
      )(state.recentlyViewedEmployeeAbsences);

      state.recentlyViewedEmployeeAbsences = updated;
      localStorage.setItem('leaveSolutionsRecentlyViewed', JSON.stringify(updated));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployeeDetails.pending, (state) => {
      state.employeeDetailsStatus = 'pending';
    });
    builder.addCase(fetchEmployeeDetails.rejected, (state) => {
      state.employeeDetailsStatus = 'rejected';
    });
    builder.addCase(fetchEmployeeDetails.fulfilled, (state, action) => {
      state.employeeDetailsStatus = 'fulfilled';
      state.employeeDetails = action.payload ?? null;
    });

    builder.addCase(fetchCasesSummary.pending, (state) => {
      state.casesSummaryStatus = 'pending';
    });
    builder.addCase(fetchCasesSummary.rejected, (state) => {
      state.casesSummaryStatus = 'rejected';
    });
    builder.addCase(fetchCasesSummary.fulfilled, (state, action) => {
      state.casesSummaryStatus = 'fulfilled';
      state.casesSummary = action.payload ?? [];
    });
    builder.addCase(fetchCaseDetails.pending, (state) => {
      state.caseDetailsStatus = 'pending';
    });
    builder.addCase(fetchCaseDetails.rejected, (state) => {
      state.caseDetailsStatus = 'rejected';
    });
    builder.addCase(fetchCaseDetails.fulfilled, (state, action) => {
      state.caseDetailsStatus = 'fulfilled';
      state.caseDetails = action.payload ?? null;
    });

    builder.addCase(fetchEmployeeAbsences.pending, (state) => {
      state.employeeAbsencesStatus = 'pending';
    });
    builder.addCase(fetchEmployeeAbsences.rejected, (state) => {
      state.employeeAbsencesStatus = 'rejected';
    });
    builder.addCase(fetchEmployeeAbsences.fulfilled, (state, action) => {
      state.employeeAbsencesStatus = 'fulfilled';
      state.employeeAbsences = action.payload ?? [];
    });
    builder.addCase(fetchEmployeeSearchResultsByCriteria.pending, (state) => {
      state.employeeSearchStatus = 'pending';
      state.employeeSearchResults = [];
    });
    builder.addCase(fetchEmployeeSearchResultsByCriteria.rejected, (state, { meta }) => {
      // this happens when changing search while it's pending
      if (meta.aborted) {
        state.employeeSearchStatus = 'pending';
      } else {
        state.employeeSearchStatus = 'rejected';
        state.employeeSearchResults = [];
      }
    });
    builder.addCase(fetchEmployeeSearchResultsByCriteria.fulfilled, (state, action) => {
      state.employeeSearchStatus = 'fulfilled';
      state.employeeSearchResults = action.payload ?? [];
    });
  },
});

export const {
  resetLeaveRecordsApiStatus,
  getRecentlyViewedFromLocalStorage,
  updateRecentlyViewed,
} = LeaveRequestsSlice.actions;

export default LeaveRequestsSlice.reducer;

export const selectEmployeeDetailsStatus = (state: RootState) => {
  return state.leaveRecords.employeeDetailsStatus;
};

export const selectCasesSummaryStatus = (state: RootState) => {
  return state.leaveRecords.casesSummaryStatus;
};

export const selectCaseDetailsStatus = (state: RootState) => {
  return state.leaveRecords.caseDetailsStatus;
};

export const selectEmployeeAbsencesStatus = (state: RootState) => {
  return state.leaveRecords.employeeAbsencesStatus;
};

export const selectEmployeeDetails = (state: RootState) => {
  return state.leaveRecords.employeeDetails;
};

export const selectCasesSummary = (state: RootState) => {
  return state.leaveRecords.casesSummary;
};

export const selectCaseDetails = (state: RootState) => {
  return state.leaveRecords.caseDetails;
};

export const selectEmployeeAbsences = (state: RootState) => {
  return state.leaveRecords.employeeAbsences;
};

export const selectEmployeeSearchResultsStatus = (state: RootState) => {
  return state.leaveRecords.employeeSearchStatus;
};

export const selectEmployeeSearchResults = (state: RootState) => {
  return state.leaveRecords.employeeSearchResults;
};

export const selectRecentlyViewedEmployeeAbsences = (state: RootState) => {
  return state.leaveRecords.recentlyViewedEmployeeAbsences;
};
