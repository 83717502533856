import React from 'react';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

interface TransactionPaginatorProps {
  count: number;
  page: number; // 0-based index
  pageSize: number;
  colSpan?: number;
  pageSizeOptions?: number[];
  onChangePage: (newPage: number) => void;
  onChangePageSize: (newPageSize: number) => void;
}

function TransactionPaginator({
  count,
  page,
  pageSize,
  colSpan = 5,
  pageSizeOptions = [ 10 ],
  onChangePage,
  onChangePageSize,
}: TransactionPaginatorProps): React.ReactElement {
  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    onChangePage(newPage);
  }

  function handleChangePageSize(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    onChangePageSize(parseInt(event.target.value, 10));
    onChangePage(0);
  }

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPage={pageSize}
          rowsPerPageOptions={pageSizeOptions}
          colSpan={colSpan}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
          SelectProps={ { native: true } }
        />
      </TableRow>
    </TableFooter>
  );
}

export default TransactionPaginator;
