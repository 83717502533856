import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';

import ReportsTile from '../../shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useSelector } from 'react-redux';
import { download1095CReport } from 'shared/api/ten95CApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { CRLType } from 'shared/types/non-plan-year.types';

interface Ten95CDataProps {
  startDate: string | null;
  endDate: string | null;
  count: number | null;
  reportType: CRLType;
}

function Ten95CData({ startDate, endDate, count, reportType }: Ten95CDataProps) {
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  function handleExport() {
    startDownloading();
    download1095CReport(compid, reportType, startDate ?? undefined, endDate ?? undefined)
      .then(doneDownloading)
      .catch(handleError);
  }

  return (
    <ReportsTile
      count={count}
      title="1095-C Data"
      description={`List of participants for the tax year and their months active on ${reportType.toUpperCase()}.`}
      exportDisabled={count === null || !startDate || !endDate}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default Ten95CData;
