import React from 'react';
import { ModalContent } from './StateContinuationContent.model';
import withModal from 'components/shared/HOC/WithModal';

export interface StateContinuationProps {
    eventTypeState: string;
}

function StateContinuation({ eventTypeState }: StateContinuationProps): React.ReactElement {
  return (
    <div>
      <p className="my-4">
        {ModalContent.get(eventTypeState)}
      </p>
    </div>
  );
}

export const StateContinuationModal = withModal<StateContinuationProps>(StateContinuation);

export default StateContinuation;
