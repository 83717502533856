import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import { Company } from './company.types';
import styles from './CompanyTile.module.css';

interface CompanyTileProps {
  company: Company;
  onSelected: (c: Company) => void;
}

function CompanyTile({ company, onSelected }: CompanyTileProps): React.ReactElement {
  function handleClick() {
    onSelected(company);
  }

  function logo(): React.ReactElement {
    if (company.filepath) {
      return (
        <CardMedia
          className={styles.media}
          title={`${company.compname}`}
          image={`https://admin.flores247.com/fsaforms/logos/${company.filepath}/logo.jpg`}
        />
      );
    }

    return <CardMedia className={styles.media} />;
  }

  return (
    <div onClick={handleClick} role="button" data-testid={`companyTile${company.compid}`}>
      <Card className={`${styles.tile} m-4`} elevation={2} square>
        {logo()}
        <CardContent classes={ { root: styles.content } }>
          <p>{company.compname}</p>
        </CardContent>
      </Card>
    </div>
  );
}

export default CompanyTile;
