// https://dev.azure.com/flores247/Flores%20Agile/_wiki/wikis/Flores-Agile.wiki/359/Plan-code-to-description-mapping
export const PlanCodes = {
  BOOT: 'B',
  DCRA: 'D',
  RESPONSE: 'C',
  PREM: 'E',
  HRAF: 'H',
  TOOL: 'L',
  MRA: 'M',
  POP: 'P',
  HSA: 'S',
  QRE: 'T',
  AUTH_FORM: 'V',
  EXCEP: 'X',
  PVP: 'Y',
  CUST: 'O',
  DENT: '1',
  VIS: '2',
  HRA: '3',
  EDU: '4',
  COBRA: '5',
  RETIREE: '6',
  LOA: '7',
  LFSA: '-',
};
