import Typography from '@mui/material/Typography';
import ViewIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';

import { ReportHistory } from './report-history.types';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { downloadReportHistoryFile } from 'shared/api/reportHistoryApi';

interface ReportHistoryTableRowProp {
  rptHistory: ReportHistory;
}

function ReportHistoryTableRow({ rptHistory }: ReportHistoryTableRowProp) {
  const errorNotifier = useErrorNotifier();

  function handleViewClick() {
    downloadReportHistoryFile(rptHistory.compid, rptHistory.unqid, rptHistory.file_name)
      .catch(errorNotifier);
  }

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body1" className="text-black text-sm">
          {rptHistory.description}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" className="text-black text-sm">
          {dayjs(rptHistory.rundate).format('MM/DD/YYYY')}
        </Typography>
      </TableCell>
      <TableCell>
        <Button onClick={handleViewClick} className="text-sm">
          <ViewIcon className="mr-1" />View
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default ReportHistoryTableRow;
