import React from 'react';

interface PlanValueProps {
  label: string;
  value: string;
  labelClassName?: string;
  valueClassName?: string;
}

export const defaultLabelClass = 'text-lg text-high-em font-normal';

export const defaultValueClass = 'text-3xl text-high-em font-medium';

function PlanValue({
  label,
  value,
  labelClassName = defaultLabelClass,
  valueClassName = defaultValueClass,
}: PlanValueProps): React.ReactElement {
  return (
    <div className="w-full flex flex-col">
      <div className={labelClassName}>{label}</div>
      <div className={valueClassName}>{value}</div>
    </div>
  );
}

export default PlanValue;
