import { useState, useRef, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import Button from '@mui/material/Button';
import { compose, always, T, F, tap } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import hashCoveredDependents from 'direct-billing/benefits/hash-covered-dependents';
import unhashCoveredDependents from 'direct-billing/benefits/unhash-covered-dependents';
import { QeBenefits, QeEventInfo } from 'enter-cobra-qe/cobra-qe.types';
import DirectBillEventInfoForm from 'direct-billing/event-info/DirectBillingEventInfoForm';
import BenefitsForm from 'components/shared/qe/BenefitsForm';
import FormikErrorFocus from 'components/shared/FormikErrorFocus';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import {
  selectDirectBillingDependents,
  setBenefits,
  setEventInfo,
  selectAvailableBenefits,
} from 'direct-billing/direct-billing.slice';
import { DBBenefit, DirectBillingQe, DirectBillSavePayload } from 'direct-billing/direct-billing.types';
import DirectBillingQeDetails from 'update-direct-bill-event/DirectBillingQeDetails';
import { validationSchema } from 'update-direct-bill-event/update-direct-billing-qe.validators';
import dayjs from 'dayjs';
import FormErrorText from 'components/shared/FormErrorText';
import { updateDirectBillingEvent } from 'shared/api/directBillingApi';
import { selectSelectedCompany } from 'companies/companies.slice';
import Loading from 'components/shared/Loading';
import { useHistory } from 'react-router-dom';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { Dependent } from 'shared/types/non-plan-year.types';
import store from 'store';

interface UpdateDirectBillingFormProps {
  entry: DirectBillingQe;
  onCancel: () => void;
}

type UpdateQeValues = QeEventInfo & QeBenefits;

const errorMap = (msg: string, i:number) => <FormErrorText className="text-base pb-5" key={i} show message={msg} />;

function eventInfoFromValues(values: UpdateQeValues) {
  return {
    eventDate: values.eventDate ? dayjs(values.eventDate).format('YYYY-MM-DD') : '',
    coverageBegins: values.coverageBegins ? dayjs(values.coverageBegins).format('YYYY-MM-DD') : '',
    paidThrough: values.paidThrough ? dayjs(values.paidThrough).format('YYYY-MM-DD') : '',
    eventType: parseInt(values.eventType as any),
  };
}

function valuesFromBenefits(benefits: DBBenefit[], dependents: Dependent[]) {
  return benefits.map((benefit) => ({
    ...benefit,
    coveredDependents: hashCoveredDependents(benefit.coveredDependents ?? [], dependents),
  }));
}

function benefitsFromValues(values: UpdateQeValues, dependents: Dependent[]) {
  return values.benefits
    .filter((benefit: any) => benefit.selectedPlan)
    .map((benefit: any) => ({
      ...benefit,
      cost: parseFloat(benefit?.cost + '') || 0,
      subsidy: parseFloat(benefit?.subsidy + '') || 0,
      coveredDependents: unhashCoveredDependents(benefit?.coveredDependents ?? [], dependents),
    }));
}

function UpdateDirectBillingForm({
  entry,
  onCancel,
}: UpdateDirectBillingFormProps) {
  const history = useHistory();
  const allDependents = useSelector(selectDirectBillingDependents);
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const availableBenefits = useSelector(selectAvailableBenefits);
  const dispatch = useDispatch<typeof store.dispatch>();
  const [ wholeFormErrors, setWholeFormErrors ] = useState<string[]>([]);
  const formRef = useRef<HTMLDivElement>(null);
  const errorNotifier = useErrorNotifier();

  const [ saving, setSaving ] = useState(false);
  const startSaving = compose(setSaving, T);
  const doneSaving = compose(setSaving, F);
  const handleError = compose(tap(doneSaving), errorNotifier);

  const form = useFormik<UpdateQeValues>({
    initialValues: {
      eventDate: entry.eventInfo.eventDate,
      eventType: entry.eventInfo.eventType,
      coverageBegins: entry.eventInfo.coverageBegins,
      paidThrough: entry.eventInfo.paidThrough,
      benefits: valuesFromBenefits(availableBenefits ?? [], allDependents),
    } as any,
    validationSchema,
    onSubmit: (values) => {
      try {
        const benefits = benefitsFromValues(values, allDependents);

        validationSchema.validateSync(
          {
            benefits,
            eventDate: values.eventDate,
            eventType: values.eventType,
            coverageBegins: values.coverageBegins,
            paidThrough: values.paidThrough,
          },
          {
            abortEarly: false,
            stripUnknown: false,
          },
        );

        dispatch(setBenefits(benefits as DBBenefit[]));

        const eventInfo = eventInfoFromValues(values);
        dispatch(setEventInfo(eventInfo));

        const payload: DirectBillSavePayload = {
          r_qualid: entry.r_qualid,
          participant: entry.participant,
          eventInfo,
          addedDependents: [],
          benefits,
        };

        startSaving();
        updateDirectBillingEvent(compid, payload)
          .then(() => {
            doneSaving();
            history.push(`/participant-overview/${entry.participant.empno}/plan-enrollment`);
          })
          .catch(handleError);
      } catch (err) {
        setWholeFormErrors(err.errors);

        if (formRef.current?.scrollIntoView) {
          formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      }
    },
  });

  /* This block needs to be here because the initial benefits will be null, and formik doesn't update unless
  you explicitly set the value. I'm destructuring setFieldValue so the useEffect doesn't trigger when we get
  a new form object on render. */
  const { setFieldValue } = form;
  useEffect(() => {
    if (availableBenefits !== null) {
      setFieldValue('benefits', valuesFromBenefits(availableBenefits, allDependents));
    }
  }, [ availableBenefits, setFieldValue, allDependents ]);

  const BenefitsWithLoading = withLoopIcon(
    BenefitsForm,
    'Loading available benefits...',
    availableBenefits === null,
  );

  return (
    <form className="update-cobra-qe-form w-full">
      <div className="w-full flex flex-col space-y-4" ref={formRef}>
        {wholeFormErrors.length > 0 && wholeFormErrors.map(errorMap)}
      </div>
      <div className="flex flex-col">
        <FormikProvider value={form}>
          <FormikErrorFocus />
          <div className="flex mb-4 space-x-4">
            <div className="w-1/2">
              <DirectBillEventInfoForm
                updatingEvent
                letterSent={!!entry.details?.letterSent}
                respPostmark={!!entry.details?.postmark}
              />
            </div>
            <div className="w-1/2">
              <DirectBillingQeDetails />
            </div>
          </div>

          <div className="flex-grow overflow-auto">
            <BenefitsWithLoading benefits={availableBenefits!} />
          </div>
        </FormikProvider>
        <div className="flex justify-end my-4 h-8">
          <Button
            className="update-qe-form-cancel"
            color="primary"
            onClick={compose(onCancel, always(undefined))}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="update-qe-form-save"
            className="ml-8 update-qe-form-save"
            type="button"
            onClick={form.submitForm}
            disabled={!!entry.details?.postmark}
          >
            Save QE
          </Button>

          {saving && <Loading text="Submitting Billing Event Update" />}
        </div>
      </div>
    </form>
  );
}

export default UpdateDirectBillingForm;
