import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';

import ReportsTile from '../../shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useSelector } from 'react-redux';
import { downloadActiveWithAddressReport } from 'shared/api/activeWithAddressApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { CRLType } from 'shared/types/non-plan-year.types';

interface ActiveWithAddressProps {
  serviceType: CRLType;
  description: string;
  startDate: string | null;
  endDate: string | null;
  count: number | null;
}

function ActiveWithAddress({ serviceType, description, startDate, endDate, count }: ActiveWithAddressProps) {
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  function handleExport() {
    startDownloading();
    downloadActiveWithAddressReport(compid, serviceType, startDate ?? undefined, endDate ?? undefined)
      .then(doneDownloading)
      .catch(handleError);
  }

  return (
    <ReportsTile
      count={count}
      title="Active With Address"
      description={description}
      exportDisabled={count === null || (!startDate && !endDate)}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default ActiveWithAddress;
