import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';

interface ReportDateRangeFilterProps {
  onFilterChange: (start: string, end: string) => void;
  applyOnInit?: boolean;
}

function ReportDateRangeFilter({ onFilterChange, applyOnInit }: Readonly<ReportDateRangeFilterProps>) {
  const [ startDate, setStartDate ] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [ endDate, setEndDate ] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [ dateHasError, setDateHasError ] = useState(false);

  function handleStartDateChange(newDate: any) {
    setDateHasError(!newDate || !newDate.isValid());
    setStartDate(newDate?.format('YYYY-MM-DD') ?? '');
  }

  function handleEndDateChange(newDate: any) {
    setDateHasError(!newDate || !newDate.isValid());
    setEndDate(newDate?.format('YYYY-MM-DD') ?? '');
  }

  function handleApply() {
    if (!dateHasError) {
      onFilterChange(startDate, endDate);
    }
  }

  useEffect(() => {
    if (applyOnInit) {
      handleApply();
    }
    // want the use effect to only ever run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-row justify-between" data-testid="date-range-filter">
      <div className="pr-4">
        <p className="text-high-em">
            If a date range is not entered, the report will be generated with today's date as the default.
        </p>
      </div>
      <div>
        <div className="flex flex-row justify-between">
          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <DatePicker
              label="Report Start Date"
              inputFormat="MM/DD/YYYY"
              value={startDate ? dayjs(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
              onChange={handleStartDateChange}
              InputProps={
                {
                  className: `${!startDate ? 'MuiFormLabel-root' : ''} text-sm`,
                  classes: {
                    input: 'px-2 py-3',
                    adornedEnd: 'pr-1',
                  },
                }
              }
              InputAdornmentProps={ { classes: { positionEnd: 'ml-1' } } }
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  data-testid="monthly-report-date-range-filter-start"
                  variant="outlined"
                  className="w-full"
                  {...params}
                  error={dateHasError}
                  helperText={dateHasError && 'Please enter valid dates.'}
                />
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <DatePicker
              className="ml-4"
              label="Report End Date"
              inputFormat="MM/DD/YYYY"
              value={endDate ? dayjs(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
              onChange={handleEndDateChange}
              InputProps={
                {
                  className: `${!endDate ? 'MuiFormLabel-root' : ''} text-sm`,
                  classes: {
                    input: 'px-2 py-3',
                    adornedEnd: 'pr-1',
                  },
                }
              }
              InputAdornmentProps={ { classes: { positionEnd: 'ml-1' } } }
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  data-testid="monthly-report-date-range-filter-end"
                  variant="outlined"
                  className="w-full"
                  {...params}
                  error={dateHasError}
                  helperText={dateHasError && 'Please enter valid dates.'}
                />
              )}
            />
          </LocalizationProvider>

          <Button
            className="ml-4"
            variant="outlined"
            color="primary"
            onClick={handleApply}
            disabled={dateHasError}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReportDateRangeFilter;
