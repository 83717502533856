import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  updateChecklistComplete,
  getReEnrollmentChecklistPlans,
  updateCobraOePacketInfo,
  updateCobraPlan,
  getCobraChecklistPlans,
  getCustomPlans,
  finalizeCobraChecklistPlans,
  getCobraCoverageTypes,
  deleteInProgressPlan,
  updateDirectBillChecklist,
  getPlanLevelDescriptions,
} from 'shared/api/reEnrollmentChecklistApi';
import {
  selectCobraPlan,
  selectCobraPlanIds,
  selectCobraPlanOnDeck,
  selectDirectBillPlan,
  selectPlanByPlanCode,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { RootState } from 'store/rootReducer';
import {
  CobraChecklistPlan,
  ReEnrollmentChecklistPlan,
} from 're-enrollment-checklist/re-enrollment-checklist.types';
import { getCobraPlanTypes } from 'shared/api/cobraPlanTypesApi';
import { getCobraInsurers } from 'shared/api/cobraInsurersApi';
import { createOeDocuments, deleteDocument } from 'shared/api/cobraChecklistDocumentsApi';

export let fetchReEnrollmentChecklistPlan = createAsyncThunk(
  'reEnrollment/fetchReEnrollmentPlan',
  (companyId: number) => getReEnrollmentChecklistPlans(companyId),
);

export let saveCobraOePacketInfo = createAsyncThunk(
  'reEnrollment/saveCobraOePacketInfo',
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const cobraPlan = selectCobraPlan(state);

    return updateCobraOePacketInfo(cobraPlan);
  },
);

export let completeChecklist = createAsyncThunk(
  'reEnrollment/completeReEnrollmentPlan',
  (planCode: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const plan = selectPlanByPlanCode(planCode)(state);

    return updateChecklistComplete(plan);
  },
);

export let saveCobraPlan = createAsyncThunk(
  'reEnrollment/updateCobraPlan',
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const details = selectCobraPlan(state);
    const cobraPlan = selectCobraPlanOnDeck(state);

    return updateCobraPlan(cobraPlan as CobraChecklistPlan, details.companyId, details.planYearId);
  },
);

export let finalizeCobraPlans = createAsyncThunk(
  'reEnrollment/finalizeCobraPlans',
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const details = selectCobraPlan(state);
    const cobraPlanIds = selectCobraPlanIds(state);

    return finalizeCobraChecklistPlans({ companyId: details.companyId, planYearId: details.planYearId, cobraPlanIds });
  },
);

export let fetchCobraChecklistPlans = createAsyncThunk(
  'reEnrollment/fetchCobraChecklistPlans',
  ({ companyId, planYearId }: ReEnrollmentChecklistPlan) => {
    return getCobraChecklistPlans({ companyId, planYearId });
  },
);

export const fetchCobraPlanTypes = createAsyncThunk(
  'reEnrollment/fetchCobraPlanTypes',
  (companyId: number) => {
    return getCobraPlanTypes(companyId);
  },
);

export const fetchCobraInsurers = createAsyncThunk(
  'reEnrollment/fetchCobraInsurers',
  (companyId: number) => {
    return getCobraInsurers(companyId);
  },
);

export const fetchCustomPlans = createAsyncThunk(
  'reEnrollment/fetchCustomPlans',
  getCustomPlans,
);

export const deleteOeDocument = createAsyncThunk(
  'reEnrollment/deleteOeDocument',
  deleteDocument,
);

export const generateOeDocuments = createAsyncThunk(
  'reEnrollment/generateOeDocuments',
  createOeDocuments,
);

export const fetchCoverageTypes = createAsyncThunk(
  'reEnrollment/fetchCoverageTypes',
  getCobraCoverageTypes,
);

export const deleteCobraInProgressPlan = createAsyncThunk(
  'reEnrollment/deleteInProgressPlan',
  (planInProgressId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { companyId, planYearId } = selectCobraPlan(state);

    return deleteInProgressPlan(companyId, planYearId, planInProgressId);
  },
);

export const saveDirectBillChecklist = createAsyncThunk(
  'reEnrollment/updateDirectBillChecklist',
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const details = selectDirectBillPlan(state);

    return updateDirectBillChecklist(details);
  },
);

export const fetchPlanLevelDescriptions = createAsyncThunk(
  'reEnrollment/getPlanLevelDescriptions',
  getPlanLevelDescriptions,
);
