import { useState } from 'react';
import Button from '@mui/material/Button';
import { useFormik, FormikProps } from 'formik';
import { compose, T } from 'ramda';

import PayrollIdParticipantLookupInput, {
  setParticipantValues,
} from 'components/shared/PayrollIdParticipantLookupInput';
import { Participant } from 'participant-search/participant-list.types';
import { CobraQeFormProps } from '../cobra-qe.types';
import { validationSchema } from './cobra-qe-personal-details.validators';
import ParticipantDetails from 'components/shared/ParticipantDetails';
import { useDispatch } from 'react-redux';
import { resetEntry } from 'enter-cobra-qe/cobra-qe.slice';
import { setSelectedParticipant } from 'participant-search/participant.slice';
import { defaultParticipant } from 'shared/constants';
import { QeParticipant } from 'shared/types/non-plan-year.types';
import store from 'store';

interface CobraQePersonalDetailsProps extends CobraQeFormProps {
  details?: QeParticipant;
}

function CobraQePersonalDetails({ details, onNextStep, onCancel }: CobraQePersonalDetailsProps) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [ searching, setSearching ] = useState(false);

  const form = useFormik<Partial<QeParticipant>>({
    initialValues: {
      ...defaultParticipant,
      ...details,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setSelectedParticipant(values as Participant));
      onNextStep(values as any);
    },
  });
  const setParticipantDetails = setParticipantValues(form.setFieldValue);

  const handleSearchStarted = compose(setSearching, T);

  function handleParticipant(p: Participant) {
    dispatch(resetEntry());
    setParticipantDetails(p);
    setSearching(false);
  }

  function handleCustomFieldChange(field: string) {
    return (value: string) => form.setFieldValue(field, value);
  }

  return (
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    <form data-testid="cobraQePersonalDetails">
      <div className="flex flex-col space-y-4">
        <p>
            The demographic information entered below should be for the primary insured losing coverage due to the
            COBRA Qualifying Event. Please ask your account manager for clarification.
        </p>
        <div className="flex flex-row pr-4">
          <PayrollIdParticipantLookupInput
            className="w-1/2"
            data-testid="participantLookup"
            onSearchStarted={handleSearchStarted}
            onParticipantFound={handleParticipant}
            name="payrollid"
            value={form.values.payrollid ?? ''}
            error={form.touched.payrollid && !!form.errors.payrollid}
            helperText={form.touched.payrollid && form.errors.payrollid}
            onTextChanged={handleCustomFieldChange('payrollid')}
          />
        </div>
        <ParticipantDetails form={form as FormikProps<Partial<QeParticipant>>} />
      </div>
      <div className="flex justify-end my-4">
        <Button
          color="primary"
          data-testid="cancelCobraQe"
          onClick={onCancel}
        >
            Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={form.submitForm}
          data-testid="cobraQeNextStep"
          className="ml-4"
          disabled={searching || (!!Object.values(form.touched).length && !!Object.values(form.errors).length)}
        >
            Next Step
        </Button>
      </div>
    </form>
    /* eslint-enable @typescript-eslint/no-unnecessary-condition */
  );
}

export default CobraQePersonalDetails;
