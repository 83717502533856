import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';
import { selectSelectedCompany } from 'companies/companies.slice';
import { getParticipant } from 'shared/api/participantApi';

export let fetchParticipant = createAsyncThunk(
  'participant/fetchParticipant',
  (payrollId: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const company = selectSelectedCompany(state);

    return getParticipant(company?.compid ?? -1, payrollId);
  },
);
