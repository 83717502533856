import request, { ContentType } from '../api-request';

export function getContributionsNotForwardedCount(
  companyId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load contributions not forwarded total for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/hsa-contributions-not-forwarded`;

  return request<number>(uri);
}

export function downloadContributionsNotForwarded(
  companyId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download contributions not forwarded report for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/hsa-contributions-not-forwarded/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}
