import * as React from 'react';
import { PdfTableBorder, PdfTableCell } from './PdfTableCell';
import { PdfDataTableCell } from './PdfDataTableCell';
import { View } from '@react-pdf/renderer';
import { getDefaultBorderIncludes } from './utils';
import { PdfTableBodyProps } from './PdfTableBody';

export interface PdfTableRowProps extends PdfTableBorder {
    /**
     * The font size as a valid unit defined in react-pdf.
     */
    fontSize?: number | string;

    /**
     * Whether to align the text. Defaults to left.
     */
    textAlign?: 'left' | 'center' | 'right';

    /**
     * Any data associated, relevant if the parent is a {@see DataTableCell}.
     */
    data?: any;
}

/**
 * This component describes how to display a row.
 */
// eslint-disable-next-line react/prefer-stateless-function
export class PdfTableRow extends React.PureComponent<Partial<PdfTableBodyProps>> {
  render() {
    const rowCells: any[] = React.Children.toArray((this.props as any).children);
    const {
      includeLeftBorder,
      includeBottomBorder,
      includeRightBorder,
      includeTopBorder,
    } = getDefaultBorderIncludes(this.props);

    let remainingWeighting = 1;
    let numberOfWeightingsDefined = 0;
    rowCells.forEach((i: PdfTableCell | PdfDataTableCell) => {
      if (i.props.weighting !== undefined) {
        remainingWeighting -= i.props.weighting;
        numberOfWeightingsDefined++;
      }
    });

    const weightingsPerNotSpecified = Math.ceil(remainingWeighting / (rowCells.length - numberOfWeightingsDefined));

    return (
      <View
        // @ts-expect-error
        style={ {
          borderBottom: includeBottomBorder && '1pt solid #E5E5E5',
          borderRight: includeRightBorder && '1pt solid #E5E5E5',
          borderLeft: includeLeftBorder && '1pt solid #E5E5E5',
          borderTop: includeTopBorder && '1pt solid #E5E5E5',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
        } }
      >
        {
          rowCells.map((rc, columnIndex) => React.cloneElement(rc, {
            weighting: rc.props.weighting ?? weightingsPerNotSpecified,
            data: this.props.data,
            key: columnIndex,
            fontSize: this.props.fontSize,
            textAlign: this.props.textAlign,
            includeLeftBorder: columnIndex === 0,
            includeRightBorder: false,
          }))
        }
      </View>
    );
  }
}
