import IconButton from '@mui/material/IconButton';
import ExportIcon from '@mui/icons-material/SystemUpdateAlt';
import { always, cond, equals, T } from 'ramda';

interface ReportExportButtonProps {
  onExport: () => void;
  disabled?: boolean;
  downloading?: boolean;
  title: string;
  variant?: 'light' | 'dark';
}

function ReportExportButton({
  onExport,
  disabled = false,
  downloading = false,
  title,
  variant = 'light',
}: ReportExportButtonProps) {
  function handleClick() {
    if (!disabled) {
      onExport();
    }
  }

  // Compare against a tuple of [variant, disabled]
  const textClasses = cond([
    [ equals([ 'light', true ]), always('text-low-em') ],
    [ equals([ 'dark', true ]), always('text-white opacity-25') ],
    [ equals([ 'dark', false ]), always('text-white') ],
    [ T, always('text-high-em') ],
  ]) as any;

  return (
    <IconButton
      onClick={handleClick}
      disabled={disabled}
      data-testid="balance-reports-enrollment-export-btn"
      className={`${textClasses([ variant, disabled ])} ${downloading && 'animate-ping'}`}
      size="large"
    >
      <ExportIcon fontSize="small" titleAccess={title} />
    </IconButton>
  );
}

export default ReportExportButton;
