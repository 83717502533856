import DocumentLibraryTabs from './DocumentLibraryTabs';

function DocumentLibrary() {
  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">Document Library</h2>
      <div className="w-full flex">
        <DocumentLibraryTabs />
      </div>
    </div>
  );
}

export default DocumentLibrary;
