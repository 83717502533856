/* eslint-disable react/jsx-no-bind */
import { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  compose,
  path,
  always,
  tap,
  andThen,
  otherwise,
  pathOr,
  equals,
  ifElse,
} from 'ramda';
import {
  ApiStatus,
  selectCobraOeProgress,
  selectCompanyId,
  selectCobraOePrintOptionBlackAndWhite,
  selectCobraOePrintOptionTwoSided,
  setCobraOePacketFiles,
  setCobraOePrintOptionsBlackAndWhite,
  setCobraOePrintOptionsTwoSided,
  setCobraOeSubmitFileStatus,
  cancelModalOpened,
  selectCobraOeCoverLetterApproved,
  selectCobraOeElectionFormApproved,
  setCobraOeCoverLetterApproved,
  setCobraOeElectionFormApproved,
  selectCobraOeCoverLetter,
  selectCobraOeElectionForm,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import DragAndDropUpload from 'components/shared/DragAndDropUpload';
import {
  saveCobraOePacketInfo,
} from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import OeFiles from 're-enrollment-checklist/cobra/oe-packets/OeFiles';
import { uploadCobraOePacketDoc } from 'shared/api/reEnrollmentChecklistApi';
import { FileListItem } from 're-enrollment-checklist/re-enrollment-checklist.types';
import { noop } from 'shared/utils';
import store from 'store';
import handleDownloadDocument from 'shared/utils/download-document';
import OeGeneratedFile from './OeGeneratedFile';

function OePacketsTab(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const companyId = useSelector(selectCompanyId);
  const blackAndWhite = useSelector(selectCobraOePrintOptionBlackAndWhite);
  const twoSided = useSelector(selectCobraOePrintOptionTwoSided);
  const isCoverLetterApproved = useSelector(selectCobraOeCoverLetterApproved);
  const isElectionApproved = useSelector(selectCobraOeElectionFormApproved);
  const [ isCoverLetterReviewed, setIsCoverLetterReviewed ] = useState(false);
  const [ isElectionFormReviewed, setIsElectionFormReviewed ] = useState(false);
  const inputsDisabled = useSelector(selectCobraOeProgress) >= 100;
  const changeRoute = compose(history.push, always(`/reenrollment-checklist/cobra/${planYearId}/summary`));
  const submitFile = compose(
    otherwise(compose(dispatch, compose(setCobraOeSubmitFileStatus, always<ApiStatus>('rejected')))),
    andThen(compose(dispatch, compose(setCobraOeSubmitFileStatus, always<ApiStatus>('fulfilled')))),
    andThen(compose(
      compose(dispatch, setCobraOePacketFiles),
      pathOr<FileListItem[]>([],[ 'oePacketFiles' ]),
    )),
    compose(uploadCobraOePacketDoc(companyId, +planYearId)),
    tap(compose(dispatch, compose(setCobraOeSubmitFileStatus, always<ApiStatus>('pending')))),
  );

  const coverLetter = useSelector(selectCobraOeCoverLetter);
  const electionForm = useSelector(selectCobraOeElectionForm);

  function handleDownload (fileName: string) {
    try {
      return handleDownloadDocument(companyId, planYearId, fileName);
    } catch {}
  }

  const handleCoverLetterChangeReviewStatus = compose(
    compose(setIsCoverLetterReviewed, always(!isCoverLetterReviewed)),
    path([ 'target', 'id' ]),
  );

  const handleElectionFormChangeReviewStatus = compose(
    compose(setIsElectionFormReviewed, always(!isElectionFormReviewed)),
    path([ 'target', 'id' ]),
  );

  const handleCoverLetterChangeApprovalStatus = compose(
    compose(
      dispatch,
      saveCobraOePacketInfo,
      dispatch,
      setCobraOeCoverLetterApproved,
      always(!isCoverLetterApproved),
    ),
    path([ 'target', 'id' ]),
  );

  const handleElectionFormChangeApprovalStatus = compose(
    compose(
      dispatch,
      saveCobraOePacketInfo,
      dispatch,
      setCobraOeElectionFormApproved,
      always(!isElectionApproved),
    ),
    path([ 'target', 'id' ]),
  );

  return (
    <>
      <h5 className="py-6">Please review and confirm your cover letter and election form.</h5>
      <div className="flex pb-10">
        <OeGeneratedFile
          file={coverLetter}
          title="Cover Letter"
          fileType="cover-letter"
          inputsDisabled={inputsDisabled}
          approvalStatus={isCoverLetterApproved}
          reviewStatus={isCoverLetterReviewed}
          onChangeApprovalStatus={handleCoverLetterChangeApprovalStatus}
          onChangeReviewStatus={handleCoverLetterChangeReviewStatus}
          onDownload={handleDownload}
        />
        <OeGeneratedFile
          file={electionForm}
          title="Election Form"
          fileType="election-form"
          inputsDisabled={inputsDisabled}
          approvalStatus={isElectionApproved}
          reviewStatus={isElectionFormReviewed}
          onChangeApprovalStatus={handleElectionFormChangeApprovalStatus}
          onChangeReviewStatus={handleElectionFormChangeReviewStatus}
          onDownload={handleDownload}
        />
      </div>

      <hr />

      <h5 className="py-4">
        Please upload any additional documents that you would like to be included in the
         Open Enrollment Packets. This should include a SPD for all plans that your participants will
         have the option to elect.
      </h5>

      {!inputsDisabled && (
        <DragAndDropUpload
          onDrop={submitFile}
        />)}

      <OeFiles showDelete={!inputsDisabled} showDownload companyId={companyId} planYearId={planYearId} />

      <div className="pt-6">
        <p>If other documents should be included, please attach below</p>
        <div className="flex flex-column">
          <FormControl component="fieldset" className="mb-8">
            <h5>Page Count</h5>
            <RadioGroup
              aria-label="twoSided"
              name="twoSided"
              value={!!twoSided ? 'two' : 'single'}
              onChange={
                compose(
                  dispatch,
                  setCobraOePrintOptionsTwoSided,
                  equals('two'),
                  path([ 'target', 'value' ]) as any,
                )
              }
            >
              <FormControlLabel
                value="two"
                control={
                  <Radio
                    color="primary"
                    id="page-count-two-sided"
                    data-testid="page-count-two-sided"
                  />
                }
                label="Two Sided (Recommended)"
                disabled={inputsDisabled}

              />
              <FormControlLabel
                value="single"
                control={
                  <Radio
                    color="primary"
                    id="page-count-single-sided"
                    data-testid="page-count-single-sided"
                  />}
                label="Single Sided (Results in increased billing due to printing costs)"
                disabled={inputsDisabled}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="flex flex-column">
          <FormControl component="fieldset" className="mb-8">
            <h5>Color Options</h5>
            <RadioGroup
              aria-label="blackAndWhite"
              name="blackAndWhite"
              value={!!blackAndWhite ? 'blackAndWhite' : 'color'}
              onChange={
                compose(
                  dispatch,
                  setCobraOePrintOptionsBlackAndWhite,
                  equals('blackAndWhite'),
                  path([ 'target', 'value' ]) as any,
                )
              }
            >
              <FormControlLabel
                value="blackAndWhite"
                control={
                  <Radio
                    color="primary"
                    id="color-options-black-and-white"
                    data-testid="color-options-black-and-white"
                  />
                }
                label="Black and White"
                disabled={inputsDisabled}
              />
              <FormControlLabel
                value="color"
                control={
                  <Radio
                    color="primary"
                    id="color-options-color"
                    data-testid="color-options-color"
                  />
                }
                label="Color (Results in increased billing due to printing costs)"
                disabled={inputsDisabled}
              />
            </RadioGroup>
          </FormControl>
        </div>

      </div>

      <hr />

      <div className="flex flex-row-reverse pt-10">
        <Button
          data-testid="oe-general-continue"
          variant="contained"
          color="primary"
          onClick={
            compose(
              ifElse(
                equals(false),
                compose(andThen(changeRoute), dispatch, compose(saveCobraOePacketInfo)),
                changeRoute,
              ),
              always(inputsDisabled),
            )
          }
          disabled={!isCoverLetterApproved || !isElectionApproved}
        >Continue
        </Button>

        <Button
          className="mr-4"
          onClick={compose(dispatch, cancelModalOpened, noop)}
        >Cancel
        </Button>
      </div>
    </>
  );
}

export default OePacketsTab;
