import { Dependent } from 'shared/types/non-plan-year.types';
import { equals } from 'ramda';

function hashCoveredDependents(covered: Dependent[], all: Dependent[]) {
  return covered
    .map((coveredDep) => all.findIndex((dep) => equals(coveredDep, dep)).toString())
    .filter((idx:string) => idx !== '-1');
}

export default hashCoveredDependents;
