import { LeaveRecordCaseInfo } from '../leaveRecord.types';
import { valueOrPlaceholder } from 'shared/utils';

interface Props {
  data?: LeaveRecordCaseInfo | null;
}

export function LeaveRecordCurrentApprovalsTab({ data }: Readonly<Props>) {
  const placeholder = '-';

  return (
    <div className="m-4" data-testid="current-approvals-tab-panel">
      <h6 className="mb-4">Current Approvals</h6>
      <dl>
        <div className="flex mb-8">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">Federal FMLA Start/End Dates</dt>
            <dd className="font-medium">
              { valueOrPlaceholder(data?.federalStartEndDates, placeholder) }
            </dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">State Leave Regulation 1 Name</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.stateLeaveRegulation1Name, placeholder) }</dd>
          </div>
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">State Leave Regulation 1 Start/End Dates</dt>
            <dd className="font-medium">
              { valueOrPlaceholder(data?.stateLeaveRegulation1StartEndDates, placeholder) }
            </dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">State Leave Regulation 2 Name</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.stateLeaveRegulation2Name, placeholder) }</dd>
          </div>
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">State Leave Regulation 2 Start/End Dates</dt>
            <dd className="font-medium">
              { valueOrPlaceholder(data?.stateLeaveRegulation2StartEndDates, placeholder) }
            </dd>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">State Leave Regulation 3 Name</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.stateLeaveRegulation3Name, placeholder) }</dd>
          </div>
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">State Leave Regulation 3 Start/End Dates</dt>
            <dd className="font-medium">
              { valueOrPlaceholder(data?.stateLeaveRegulation3StartEndDates, placeholder) }
            </dd>
          </div>
        </div>
        <div className="flex mb-8 pt-8 border-t">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">Reduced Schedule Start/End Date</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.reducedScheduledStartEndDates, placeholder) }</dd>
          </div>
          <div>
            <dt className="text-sm text-neutral-500">Reduced Schedule Details</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.reducedScheduleDetails, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8 pt-8 border-t">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">Intermittent Treatment Start/End Dates</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.intTreatmentStartEndDates, placeholder) }</dd>
          </div>
          <div>
            <dt className="text-sm text-neutral-500">Intermittent Treatment Details</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.intTreatmentDetails, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">Intermittent Episode Start/End Dates</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.intEpisodeStartEndDates, placeholder) }</dd>
          </div>
          <div>
            <dt className="text-sm text-neutral-500">Intermittent Episode Details</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.intEpisodeDetails, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8 pt-8 border-t">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">Accommodation Start/End Dates</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.accommodationStartEndDates, placeholder) }</dd>
          </div>
          <div>
            <dt className="text-sm text-neutral-500">Accommodation Details</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.accommodationDetails, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8 pt-8 border-t">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">Denial Start/End Dates</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.denialStartEndDates, placeholder) }</dd>
          </div>
          <div>
            <dt className="text-sm text-neutral-500">Denial Reason</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.denialReason, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="basis-1/3">
            <dt className="text-sm text-neutral-500">Personal Leave Start/End Dates</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.personalLoAStartEndDates, placeholder) }</dd>
          </div>
          <div>
            <dt className="text-sm text-neutral-500">Other Medical Leave Start/End Dates</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.medicalLoAStartEndDates, placeholder) }</dd>
          </div>
        </div>
      </dl>
    </div>
  );
}
