import { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { F, T, always, compose, cond, equals } from 'ramda';
import { FormikProvider, useFormik } from 'formik';
import Button from '@mui/material/Button';

import PageContentPaper from 'components/layout/PageContentPaper';
import CancelModal from 'components/shared/CancelModal';
import Loading from 'components/shared/Loading';
import { ReportAbsenceFormFields } from './report-an-absence.types';
import {
  defaultEventInfoFields,
  defaultPersonalDetailsFields,
  headerTextEnum,
  submitTextEnum,
} from './report-an-absence-fields';
import wizardSteps, { ReportAbsenceStepSlug } from './report-an-absence-steps';
import WizardStepper, { WizardStep } from 'components/shared/WizardStepper';
import { ShowModal } from 'shared/types/modal.types';
import { SubmissionError } from 'components/shared/form/SubmissionError';
import { validation } from './report-an-absence.validation';
import store from 'store';
import { selectEmployeeDetails, selectEmployeeDetailsStatus } from 'leave-records/leave-records.slice';
import { selectSelectedCompanyId } from 'companies/companies.slice';
import { fetchEmployeeDetails } from 'leave-records/leave-records.thunk';
import { submitReportAnAbsenceForm } from 'shared/api/fmlaFormsApi';
import { ReportAnAbsencePersonalDetails } from './personal-details/ReportAnAbsencePersonalDetails';
import { ReportAnAbsenceEventInfo } from './event-info/ReportAnAbsenceEventInfo';
import { ReportAnAbsenceSummary } from './summary/ReportAnAbsenceSummary';
import ReportAnAbsenceConfirmation from './ReportAnAbsenceConfirmation';

export function ReportAnAbsence(): React.ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();
  const { stepSlug: activeStepSlug = 'personal-details' } = useParams<{ stepSlug: ReportAbsenceStepSlug; }>();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const eeId = params.get('eeId') ?? '';
  const caseId = params.get('caseId') ?? '';

  const companyId = useSelector(selectSelectedCompanyId);
  const isLoading = useSelector(selectEmployeeDetailsStatus) === 'pending';
  const employeeDetails = useSelector(selectEmployeeDetails);

  const [ showModal, setShowModal ] = useState<ShowModal>('none');
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ hasSubmissionError, setHasSubmissionError ] = useState(false);
  const form = useFormik<ReportAbsenceFormFields>({
    enableReinitialize: true,
    initialValues: {
      employee: {
        ...defaultPersonalDetailsFields,
        eeId: employeeDetails?.eeId ?? defaultPersonalDetailsFields.eeId,
        nameFirst: employeeDetails?.firstName ?? defaultPersonalDetailsFields.nameFirst,
        nameLast: employeeDetails?.lastName ?? defaultPersonalDetailsFields.nameLast,
        phone: employeeDetails?.phone1 ?? defaultPersonalDetailsFields.phone,
        addr1: employeeDetails?.address1 ?? defaultPersonalDetailsFields.addr1,
        dob: employeeDetails?.dob ?? defaultPersonalDetailsFields.dob,
      },
      event: {
        ...defaultEventInfoFields,
        title: caseId || defaultEventInfoFields.title,
      },
    },
    validateOnMount: true,
    validationSchema: validation.get(activeStepSlug),
    onSubmit: (data) => {
      setHasSubmissionError(false);
      setIsSubmitting(true);
      submitReportAnAbsenceForm(companyId, data)
        .then(setModalState('success'))
        .catch(compose(setHasSubmissionError, T))
        .finally(compose(setIsSubmitting, F));
    },
  });

  const headerText = cond<ReportAbsenceStepSlug, string>([
    [
      equals<ReportAbsenceStepSlug>('personal-details'),
      always(headerTextEnum.personalDetails),
    ],
    [
      equals<ReportAbsenceStepSlug>('event-info'),
      always(headerTextEnum.eventInfo),
    ],
    [
      equals<ReportAbsenceStepSlug>('summary'),
      always(headerTextEnum.summary),
    ],
  ])(activeStepSlug);

  const submitText = activeStepSlug === 'summary'
    ? submitTextEnum.submit
    : submitTextEnum.next;

  function onNext() {
    const step = wizardSteps.get(activeStepSlug);

    if (step?.slug === 'summary') {
      form.submitForm();
    } else {
      history.push(`/report-an-absence/${step?.next}${search}`);
    }
  }

  function handleStepClick(step: WizardStep) {
    history.push(`/report-an-absence/${step.slug}${search}`);
  }

  function goToDashboard() {
    setShowModal('none');
    history.push('/dashboard');
  }

  function reportAnotherAbsence() {
    setShowModal('none');
    form.resetForm();
    history.push('/report-an-absence/personal-details');
  }

  const setModalState = (modal: ShowModal) => () => {
    setShowModal(modal);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (eeId !== '') {
      dispatch(fetchEmployeeDetails({ companyId, eeId }));
    }
  }, []);

  return (
    <div data-testid="report-an-absence-page">
      <PageContentPaper className="mt-4 p-8">
        <div className="mb-8">
          <WizardStepper
            steps={wizardSteps}
            allowClick
            onChange={handleStepClick}
          />
        </div>
        <p className="mb-12" data-testid="header-text">{headerText}</p>
        <FormikProvider value={form}>
          {
            cond<ReportAbsenceStepSlug, JSX.Element>([
              [
                equals<ReportAbsenceStepSlug>('personal-details'),
                always(<ReportAnAbsencePersonalDetails />),
              ],
              [
                equals<ReportAbsenceStepSlug>('event-info'),
                always(<ReportAnAbsenceEventInfo />),
              ],
              [
                equals<ReportAbsenceStepSlug>('summary'),
                always(<ReportAnAbsenceSummary />),
              ],
            ])(activeStepSlug)
          }
          {
            hasSubmissionError && <SubmissionError />
          }
          <div className="flex justify-end">
            <Button
              data-testid="btn-cancel"
              type="button"
              variant="text"
              className="mr-4"
              onClick={setModalState('cancel')}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              data-testid="btn-next-step"
              type="submit"
              variant="contained"
              className="whitespace-nowrap"
              onClick={onNext}
              disabled={!form.isValid || isSubmitting}
            >
              {submitText}
            </Button>
          </div>
        </FormikProvider>
      </PageContentPaper>
      <CancelModal
        isOpen={showModal === 'cancel'}
        onCancel={goToDashboard}
        onClose={setModalState('none')}
      />
      <ReportAnAbsenceConfirmation
        isOpen={showModal === 'success'}
        onClose={goToDashboard}
        onGoToDash={goToDashboard}
        onNewAbsence={reportAnotherAbsence}
      />
      {
        isLoading && (
          <Loading />
        )
      }
    </div>
  );
}
