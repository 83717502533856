import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/SystemUpdateAlt';
import Skeleton from '@mui/material/Skeleton';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import MonthSelect from 'components/shared/MonthSelect';
import styles from './NonPlanYearServiceSummary.module.css';
import { getNonPlanYearServiceSummaryReport } from 'shared/api/nonPlanYearServicesApi';
import { noop } from 'shared/utils';
import { downloadNonPlanYearParticipantReport } from 'shared/api/reportsApi';
import { downloadActiveParticipantsReport } from 'shared/api/activeParticipantsApi';
import { NonPlanYearServiceSummaryReportResponse } from 'reports-tile/reports-tile.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

interface NonPlanYearServiceSummaryProps {
  startDate: string;
  endDate: string;
  title: string;
  exportType?: 'cobra' | 'retiree' | 'loa';
  apiUri: string;
  onLoaded: () => void;
  className?: string;
  data?: NonPlanYearServiceSummaryReportResponse | null;
}

const defaultSummaryReportResponse: NonPlanYearServiceSummaryReportResponse = {
  activeParticipants: 0,
  noticesMailed: 0,
  paymentsProcessed: 0,
};

function NonPlanYearServiceSummary({
  startDate,
  endDate,
  title,
  apiUri,
  exportType,
  onLoaded,
  className = '',
  data = defaultSummaryReportResponse,
}: NonPlanYearServiceSummaryProps): React.ReactElement {
  const [ month, setMonth ] = useState<Dayjs>();
  const [ loading, setLoading ] = useState(false);
  const [ summary, setSummary ] =
    useState<NonPlanYearServiceSummaryReportResponse | null>(defaultSummaryReportResponse);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const handleError = useErrorNotifier();

  async function getData(day: Dayjs) {
    const start = day.startOf('month');
    const end = day.endOf('month');

    setLoading(true);

    try {
      const result = (await getNonPlanYearServiceSummaryReport(apiUri, start, end)) || defaultSummaryReportResponse;

      setSummary(result);
    } catch (ex) {
      handleError(ex as Error);
    } finally {
      onLoaded();
      setLoading(false);
    }
  }

  function handleMonthChange(m: string) {
    const newMonth = dayjs(`${m}`);
    setMonth(newMonth);
    getData(newMonth);
  }

  useEffect(() => {
    setSummary(data);

    if (!!startDate) {
      setMonth(dayjs(startDate));
    }
  }, [ data, startDate ]);

  function handleExport() {
    if (compid && exportType) {
      let promise: Promise<unknown>;

      if (exportType === 'cobra') {
        promise = downloadActiveParticipantsReport(
          compid,
          exportType,
          month?.startOf('month').format('MM/DD/YYYY'),
          month?.endOf('month').format('MM/DD/YYYY'),
        );
      } else {
        promise = downloadNonPlanYearParticipantReport(
          compid,
          exportType,
          month?.startOf('month').format('MM/DD/YYYY') ?? '',
          month?.endOf('month').format('MM/DD/YYYY') ?? '',
        );
      }
      promise.then(noop)
        .catch(handleError);
    }
  }

  function exportLink() {
    if (compid && exportType && !loading) {
      return (
        <Button onClick={handleExport} className="p-0">
          <p className="flex flex-row justify-center text-high-em text-sm">
            <DownloadIcon className="mr-1" fontSize="small" /> Export
          </p>
        </Button>
      );
    }

    return <></>;
  }

  function content(): React.ReactElement {
    if (loading) {
      return (
        <>
          <Skeleton variant="rectangular" className="basis-full h-full" data-testid="reportLoadingSkeleton" />
        </>
      );
    }

    return (
      <>
        <div className={`${styles.dataSection} basis-full xl:basis-1/2 px-4 xl:border-r`}>
          {
            <MonthSelect
              startDate={dayjs(startDate)}
              endDate={dayjs(endDate)}
              value={month?.format('YYYY-MM-01')}
              onMonthChanged={handleMonthChange}
              className="text-sm text-high-em"
            />
          }
        </div>
        <div className={`${styles.dataSection} basis-full xl:basis-1/2 border-t xl:border-t-0`}>
          <p className="text-high-em">Active Participants</p>
          <h4 className="text-high-em">{summary?.activeParticipants ?? 0}</h4>
        </div>
        <div className={`${styles.dataSection} basis-full xl:basis-1/2 border-t xl:border-r`}>
          <p className="text-high-em">Notices Mailed</p>
          <h4 className="text-high-em">{summary?.noticesMailed ?? 0}</h4>
        </div>
        <div className={`${styles.dataSection} basis-full xl:basis-1/2 border-t`}>
          <p className="text-high-em">Payments Processed</p>
          <h4 className="text-high-em">{summary?.paymentsProcessed ?? 0}</h4>
        </div>
      </>
    );
  }

  return (
    <div className={`${className} ${styles.summaryContainer} divide-y`} data-testid={title}>
      <div className="flex flex-row justify-between align-middle py-2 px-4">
        <p className="text-high-em">{title}</p>
        {exportLink()}
      </div>
      <div className="flex flex-wrap flex-grow" >{content()}</div>
    </div>
  );
}

export default NonPlanYearServiceSummary;
