import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from 'store';
import {
  selectReportsCounts,
  selectStartAndEndDateRange,
  setFMLADateRange,
} from 'reports/fmla/fmla-reports-slice';
import PageContentPaper from 'components/layout/PageContentPaper';
import ReportDateRangeFilter from 'reports/shared/ReportDateRangeFilter';
import {
  fetchDateFilterableFmlaReports,
  fetchFMLAReportCountsForDashboard,
} from 'reports/fmla/fmla-reports.thunks';
import { selectSelectedCompany } from 'companies/companies.slice';
import { GenericFMLAReportTile } from 'reports/nonPlanYearServices/tiles/GenericFMLAReportTile';
import { FmlaCompanyIdDatesAndReportType } from 'reports/fmla/fmla-report-types';
import { Variant } from 'reports/shared/ReportsTile';

export interface FMLAGenericTileProps {
  count: number | null;
  reportData: FmlaCompanyIdDatesAndReportType;
  title: string;
  description: string;
  variant?: Variant;
}

export function FMLAReports (): ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const { startDate, endDate } = useSelector(selectStartAndEndDateRange);
  const {
    allOpenLeaveCaseListCount,
    allAbsencesListCount,
    usageTotalsCount,
    allLeaveCases,
    intermittentLeaveCaseListCount,
    pendingInfoCount,
    returnToWorkConfirmationCount,
  } = useSelector(selectReportsCounts);

  function handleDateRangeChange(start: string, end: string) {
    dispatch(setFMLADateRange({ start, end }));
    dispatch(fetchDateFilterableFmlaReports({
      compid,
      startDate: start,
      endDate: end,
    }));
  }

  useEffect(() => {
    dispatch(fetchFMLAReportCountsForDashboard({
      compid,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex space-x-8">
      <PageContentPaper className="w-3/4">
        <div className="space-y-4">
          <div className="w-full">
            <ReportDateRangeFilter onFilterChange={handleDateRangeChange} applyOnInit />
          </div>
          <div className="grid grid-flow-row grid-cols-3 grid-rows-4 gap-8" data-testId="open-case-list-card">
            <GenericFMLAReportTile
              title="All Absences"
              /* eslint-disable-next-line max-len */
              description="Absence list based on the date filter above"
              reportData={ {
                reportType: 'all-absences',
                compid,
                startDate,
                endDate,
              } }
              count={allAbsencesListCount}
            />
            <GenericFMLAReportTile
              title="Usage Totals"
              /* eslint-disable-next-line max-len */
              description="Usage totals by employee based on the date filter above"
              reportData={ {
                reportType: 'usage-totals',
                compid,
                startDate,
                endDate,
              } }
              count={usageTotalsCount}
            />
            <GenericFMLAReportTile
              title="All Leave Cases"
              description="Historic cases based on the date filter above"
              reportData={ {
                reportType: 'all-leave-cases',
                compid,
                startDate,
                endDate,
              } }
              count={allLeaveCases}
            />
          </div>
        </div>
      </PageContentPaper>
      <div className="w-1/4 flex flex-col space-y-8">
        <GenericFMLAReportTile
          title="Open Cases"
          description="Detailed list of all open cases"
          variant="primary-dark"
          reportData={ {
            reportType: 'open-cases',
            compid,
          } }
          count={allOpenLeaveCaseListCount}
        />
        <GenericFMLAReportTile
          title="Intermittent Absences in past 2 weeks"
          description="All intermittent absences reported to Flores Leave Solutions in the past two weeks"
          variant="primary-dark"
          reportData={ {
            reportType: 'intermittent-leave-cases',
            compid,
          } }
          count={intermittentLeaveCaseListCount}
        />
        <GenericFMLAReportTile
          title="Action Needed"
          description="Open cases that are pending a response from you"
          variant="primary-dark"
          reportData={ {
            reportType: 'action-needed',
            compid,
          } }
          count={pendingInfoCount}
        />
        <GenericFMLAReportTile
          title="Return to Work Confirmation Needed"
          description="Continuous LOA cases pending a return to work confirmation"
          variant="primary-dark"
          reportData={ {
            reportType: 'rtw-confirmation-needed',
            compid,
          } }
          count={returnToWorkConfirmationCount}
        />
      </div>
    </div>
  );
}
