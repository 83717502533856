import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { compose, always } from 'ramda';
import dayjs from 'dayjs';

import { TerminateDetails } from './terminate-participant.types';

interface TerminateParticipantRowProps {
  details: TerminateDetails;
  onRemove: (details: TerminateDetails) => void;
}

function TerminateParticipantRow({ details, onRemove }: TerminateParticipantRowProps) {
  function formatDate(date?: string) {
    if (!date || date.length === 0) {
      return '';
    }

    const d = dayjs(date, 'YYYY-MM-DD');

    return d.isValid() ? d.format('M/D/YYYY') : '';
  }

  return (
    <TableRow>
      <TableCell >{details.participant.fname} {details.participant.lname}</TableCell>
      <TableCell >{details.participant.payrollid}</TableCell>
      <TableCell >{formatDate(details.terminateBenefits)}</TableCell>
      <TableCell >{formatDate(details.lastDeduction)}</TableCell>
      <TableCell>
        <IconButton onClick={compose(onRemove, always(details))} color="default" size="large">
          <ClearIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default TerminateParticipantRow;
