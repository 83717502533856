import React, { ReactElement, useRef } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';

import LocationSelect from 'components/shared/LocationSelect';
import StateSelect from 'components/shared/StateSelect';
import { handleDateChange, handleSelectChange } from 'shared/form-helpers';
import { FormikProps } from 'formik';
import { Participant } from '../../participant-search/participant-list.types';
import { compose, pipe, tap, T } from 'ramda';
import { noop } from 'shared/utils';
import { ChangeNotifier } from 'shared/types/form.types';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import DatePicker from '@mui/lab/DatePicker';

interface ParticipantDetailsProps extends ChangeNotifier {
  form: FormikProps<Partial<Participant>>;
}

function ParticipantDetails({
  form,
  onHasChanged = noop }: ParticipantDetailsProps): ReactElement {
  const genderLabelRef = useRef<HTMLLabelElement>(null);

  function handleCustomFieldChange(field: string) {
    return (value: string) => form.setFieldValue(field, value);
  }

  function handleLocationChange(loccode: string) {
    form.setFieldValue('location', loccode ? parseInt(loccode) : null);
  }

  return (
    <>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          label="First name"
          name="fname"
          value={form.values.fname ?? ''}
          error={form.touched.fname && !!form.errors.fname}
          helperText={form.touched.fname && form.errors.fname}
          onChange={pipe(tap(compose(onHasChanged, T)), form.handleChange('fname'))}
          fullWidth
          data-testid="firstName"
        />
        <TextField
          variant="outlined"
          label="Last name"
          name="lname"
          value={form.values.lname ?? ''}
          error={form.touched.lname && !!form.errors.lname}
          helperText={form.touched.lname && form.errors.lname}
          onChange={pipe(tap(compose(onHasChanged, T)), form.handleChange('lname'))}
          fullWidth
          data-testid="lastName"
        />
      </div>
      <div className="flex flex-row space-x-4">
        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Date of Birth (Optional)"
            value={form.values.birthdate ? dayjs(form.values.birthdate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
            onChange={pipe(tap(compose(onHasChanged, T)), handleDateChange('birthdate', form))}
            InputProps={
              {
                className: !form.values.birthdate ? 'MuiFormLabel-root' : '',
              }
            }
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                variant="outlined"
                data-testid="dob"
                className="w-full"
                {...params}
                error={form.touched.birthdate && Boolean(form.errors.birthdate)}
                helperText={form.touched.birthdate && form.errors.birthdate}
              />
            )}
          />
        </LocalizationProvider>
        <FormControl variant="outlined" className="w-full">
          <InputLabel ref={genderLabelRef} id="gender-select-label">Gender (Optional)</InputLabel>
          <Select
            label="Gender (Optional)"
            value={form.values.sex ?? ''}
            onChange={pipe(tap(compose(onHasChanged, T)), handleSelectChange('sex', form))}
            native
            variant="outlined"
            fullWidth
            data-testid="gender"
          >
            <option />
            <option value="F">Female</option>
            <option value="M">Male</option>
          </Select>
        </FormControl>
      </div>
      <div className="flex flex-row space-x-4">
        <LocationSelect
          variant="outlined"
          className="w-full"
          value={form.values.location?.toString() ?? ''}
          label="Location (Optional)"
          onLocationChanged={pipe(tap(compose(onHasChanged, T)), handleLocationChange)}
        />
        <TextField
          variant="outlined"
          name="hphone"
          label="Phone (Optional)"
          value={form.values.hphone ?? ''}
          error={form.touched.hphone && !!form.errors.hphone}
          helperText={form.touched.hphone && form.errors.hphone}
          onChange={pipe(tap(compose(onHasChanged, T)), form.handleChange('hphone'))}
          fullWidth
          data-testid="phone"
        />
        <TextField
          variant="outlined"
          name="email"
          label="Email Address (Recommended)"
          value={form.values.email ?? ''}
          error={form.touched.email && !!form.errors.email}
          helperText={form.touched.email && form.errors.email}
          onChange={pipe(tap(compose(onHasChanged, T)), form.handleChange('email'))}
          fullWidth
          data-testid="email"
        />
      </div>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          label="Address Line 1"
          name="hstreet1"
          value={form.values.hstreet1 ?? ''}
          error={form.touched.hstreet1 && !!form.errors.hstreet1}
          helperText={form.touched.hstreet1 && form.errors.hstreet1}
          onChange={pipe(tap(compose(onHasChanged, T)), form.handleChange('hstreet1'))}
          fullWidth
          data-testid="addr1"
        />
      </div>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          label="Address Line 2 (Optional)"
          name="hstreet2"
          value={form.values.hstreet2 ?? ''}
          error={form.touched.hstreet2 && !!form.errors.hstreet2}
          helperText={form.touched.hstreet2 && form.errors.hstreet2}
          onChange={pipe(tap(compose(onHasChanged, T)), form.handleChange('hstreet2'))}
          fullWidth
          data-testid="addr2"
        />
      </div>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          name="hcity"
          label="City"
          value={form.values.hcity ?? ''}
          error={form.touched.hcity && !!form.errors.hcity}
          helperText={form.touched.hcity && form.errors.hcity}
          onChange={pipe(tap(compose(onHasChanged, T)), form.handleChange('hcity'))}
          className="w-1/2"
          data-testid="city"
        />

        <div className="w-1/4" data-testid="state">
          <StateSelect
            onStateChanged={pipe(tap(compose(onHasChanged, T)), handleCustomFieldChange('hstate'))}
            name="hstate"
            label="State"
            value={form.values.hstate ?? undefined}
            variant="outlined"
            error={form.touched.hstate && !!form.errors.hstate}
          />
        </div>

        <TextField
          variant="outlined"
          name="hzip"
          label="Zip"
          value={form.values.hzip ?? ''}
          error={form.touched.hzip && !!form.errors.hzip}
          helperText={form.touched.hzip && form.errors.hzip}
          onChange={pipe(tap(compose(onHasChanged, T)), form.handleChange('hzip'))}
          className="w-1/4"
          data-testid="zip"
        />
      </div>
    </>
  );
}

export default ParticipantDetails;
