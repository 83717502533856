import { FormikErrors } from 'formik';
import { clone, equals, flatten } from 'ramda';
import { Benefit, Dependent } from 'shared/types/non-plan-year.types';
import { QeBenefits } from '../cobra-qe.types';

/**
 * Convert the covered dependents to a string to compare and find by index in the full
 * dependents array
 */
export function hashCoveredDependents(benefit: Benefit, dependents: Dependent[]): Benefit {
  return Object.fromEntries(
    Object.entries(clone(benefit))
      .map(([ k, v ]) => {
        return k === 'coveredDependents'
          ? [ k, v
            .map((d: Dependent) => dependents.findIndex(a => equals(d, a)).toString())
            .filter((s:string) => s !== '-1') ]
          : [ k, v ];
      }),
  ) as Benefit;
}

/**
 * Conform the redux state for benefits to the format needed for the benefits form.
 *
 * If the benefit has been previously selected the selected benefit is filled in for
 * that benefit, otherwise populate the benefit based on available benefits
 */
export function conformToForm(state: Benefit[], plans: QeBenefits[]): Benefit[] {
  return plans.reduce((acc: Benefit[], current) => {
    const foundBenefit = state.find((b) => b.planType === current.benefits[0].planType);

    return [ ...acc, foundBenefit ?? { ...current.benefits[0], plvlid: -1, coveredDependents: [] } ];
  }, []);
}

/**
 * Conform the selected benefits for the redux slice. This includes filtering out any
 * benefits where a selection was not made, and converting the hashed dependents to full
 * dependent objects
 */
export function conformToSlice(dependents: Dependent[], availablePlans: QeBenefits[]): (s: QeBenefits) => QeBenefits {
  const allPlans = flatten(availablePlans.map((plans) => plans.benefits));

  return (state: QeBenefits) => ({
    benefits: state.benefits
      .filter((b) => b.plvlid.toString() !== '-1')
      .map((b) => {
        const plvlid = parseInt(b.plvlid.toString());
        // Note: It should never be the case that the plan isn't found. If somehow that does happen, defaulting back to
        // the one selected might result in a mismatched label on the summary step, but likely in good data in the DB
        // since what we really care about is plvlid, which is preserved.
        // plvlid may or may not be unique
        const found = allPlans.find((plan) => (plan.plvlid === plvlid && plan.planType === b.planType)) ?? b;

        return {
          ...found,
          coveredDependents: b.coveredDependents.map((cd) => dependents[cd as unknown as number]),
          plvlid,
          cost: b.cost ? parseFloat(b.cost.toString()) : 0.00,
          subsidy: b.subsidy ? parseFloat(b.subsidy.toString()) : undefined,
          subsidyEnd: b.subsidyEnd,
        };
      }),
  });
}

/* istanbul ignore next */ // This function exists solely to work around type errors
export function benefitErrorMessage(
  errors: FormikErrors<{benefits: Benefit[];}>,
  idx: number,
  fieldName: keyof Benefit): string {
  if (!errors.benefits) {
    return '';
  }

  if (idx >= errors.benefits.length) {
    return '';
  }

  const benErrors = (errors.benefits as FormikErrors<Benefit[]>)[idx] as FormikErrors<Benefit>;

  /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */ // benErrors can be undefined because errors is a sparse array
  return benErrors && !!benErrors[fieldName] ? (benErrors[fieldName] as string) : '';
}
/* istanbul ignore stop */
