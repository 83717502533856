import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import PageContentPaper from 'components/layout/PageContentPaper';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import { dateOrPlaceholder, valueOrPlaceholder } from 'shared/utils';
import { DateFormat_M_D_YYYY } from 'shared/consts/date-time.consts';
import store from 'store';
import { fetchEmployeeAbsences, fetchEmployeeDetails } from 'leave-records/leave-records.thunk';
import {
  selectEmployeeAbsences,
  selectEmployeeAbsencesStatus,
  selectEmployeeDetails,
  selectEmployeeDetailsStatus,
} from 'leave-records/leave-records.slice';
import { selectSelectedCompany } from 'companies/companies.slice';
import { EmployeeSearchResults } from 'leave-records/leaveRecord.types';
import { LeaveRecordsEmployeeHeader } from 'leave-records/components/LeaveRecordsEmployeeHeader';
import Loading from 'components/shared/Loading';
import { any, equals } from 'ramda';

export function AbsenceReport() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();
  const { eeId } = useParams<{ eeId: string; }>();
  const companyId = useSelector(selectSelectedCompany)?.compid ?? 0;
  const employeeDetailsStatus = useSelector(selectEmployeeDetailsStatus);
  const absencesStatus = useSelector(selectEmployeeAbsencesStatus);
  const isLoading = any(equals('pending'))([ employeeDetailsStatus, absencesStatus ]);
  const employeeDetails = useSelector(selectEmployeeDetails);
  const employeeSummary: EmployeeSearchResults = {
    firstName: employeeDetails?.firstName ?? '',
    lastName: employeeDetails?.lastName ?? '',
    eeId: employeeDetails?.eeId ?? '',
    taxIdLast4: employeeDetails?.taxIdLast4 ?? null,
  };
  const absences = useSelector(selectEmployeeAbsences);
  const placeholder = '-';

  function onReportAbsence() {
    history.push(`/report-an-absence/personal-details/?eeId=${eeId}`);
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (employeeDetailsStatus !== 'fulfilled') {
      dispatch(fetchEmployeeDetails({ companyId, eeId }));
    }

    dispatch(fetchEmployeeAbsences({ companyId, eeId }));
  }, []);

  return (
    <div className="w-full mx-8 my-4" data-testid="absence-report-page">
      <div className="flex items-center mb-4">
        <IconButton
          aria-label="Go Back"
          data-testid="go-back-button"
          onClick={history.goBack}
          size="large"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <h2 className="page-title my-0">Absence Report</h2>
      </div>
      <div className="flex items-center justify-between">
        <LeaveRecordsEmployeeHeader employeeSummary={employeeSummary} />
        <Button
          data-testid="report-absence-btn"
          className="whitespace-nowrap px-8 mb-4"
          variant="contained"
          size="small"
          startIcon={<EditIcon />}
          onClick={onReportAbsence}
        >
          Report Absence
        </Button>
      </div>
      <PageContentPaper>
        <TableContainer sx={ { maxHeight: 500, overflowY: 'scroll', msOverflowY: 'scroll' } }>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Date of Absence</TableCell>
                <TableCell>Hours</TableCell>
                <TableCell>Minutes</TableCell>
                <TableCell>Regulation Type</TableCell>
                <TableCell>Duration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                absences.length === 0 && (
                  <TableRow data-testid="no-absences-row">
                    <TableCell colSpan={5} className="text-center">Employee has no absences</TableCell>
                  </TableRow>
                )
              }
              {
                absences.length > 0 && absences.map((abs) => (
                  <TableRow key={`absence-${abs.absenceId}`} data-testid={`absence-row-${abs.absenceId}`}>
                    <TableCell>{dateOrPlaceholder(abs.absenceDate, DateFormat_M_D_YYYY, placeholder)}</TableCell>
                    <TableCell>{valueOrPlaceholder(abs.hours, placeholder)}</TableCell>
                    <TableCell>{valueOrPlaceholder(abs.minutes, placeholder)}</TableCell>
                    <TableCell>{valueOrPlaceholder(abs.absenceType, placeholder)}</TableCell>
                    <TableCell>{valueOrPlaceholder(abs.absenceDuration, placeholder)}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </PageContentPaper>
      {
        isLoading && (
          <Loading />
        )
      }
    </div>
  );
}
