import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { compose } from 'ramda';

import styles from './DirectBillingEventInfoForm.module.css';
import {
  loadAvailableBenefits,
  loadEventTypes,
  selectDirectBillingEventTypes,
} from 'direct-billing/direct-billing.slice';
import { handleDateChange, mapToSelectOption } from 'shared/form-helpers';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { selectSelectedCompany } from 'companies/companies.slice';
import CommonSelect from 'components/shared/form/CommonSelect';
import { DirectBillingEventType } from 'direct-billing/direct-billing.types';
import { noop } from 'shared/utils';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import store from 'store';
import Skeleton from '@mui/material/Skeleton';

interface EventInfoFormProps {
  letterSent?: boolean;
  respPostmark?: boolean;
  updatingEvent?: boolean;
}

function mapEventTypesToOptions(events: DirectBillingEventType[] | null) {
  return mapToSelectOption(events ?? [], 'desc', 'r_qualevid');
}

function DirectBillingEventInfoForm({
  updatingEvent = false,
  letterSent = false,
  respPostmark = false,
}: EventInfoFormProps) {
  const form = useFormikContext<any>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const dispatch = useDispatch<typeof store.dispatch>();

  // don't load benefits in the create event wizard since it's handled elsewhere
  const updateBenefits = updatingEvent
    ? compose(dispatch, loadAvailableBenefits.bind(null, compid))
    : (_: string) => noop;

  function handleCoverageChange(event: any) {
    const value = event ? dayjs(event, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
    form.setFieldValue('coverageBegins', value);

    if (value) {
      updateBenefits(value);
    }
  }

  useEffect(() => {
    dispatch(loadEventTypes(compid));
  }, [ dispatch, compid ]);
  const events = useSelector(selectDirectBillingEventTypes);

  const EventSelectWithLoading = withLoopIcon(
    CommonSelect,
    'Loading event types...',
    events === null,
    'event-type-loading',
  );
  const options = mapEventTypesToOptions(events);

  return (
    <>
      <div className="flex flex-row basis-full mb-4">
        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Event Date"
            value={form.values.eventDate ? dayjs(form.values.eventDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
            onChange={handleDateChange('eventDate', form)}
            inputFormat="MM/DD/YYYY"
            InputProps={
              {
                className: !form.values.eventDate ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
                disabled: respPostmark,
              }
            }
            disabled={respPostmark}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                data-testid="event-date"
                className="event-info-event-date basis-2/4 mr-4"
                variant="outlined"
                name="eventDate"
                {...params}
                error={form.touched.eventDate && Boolean(form.errors.eventDate)}
                helperText={form.touched.eventDate && form.errors.eventDate as any}
              />
            )}
          />
        </LocalizationProvider>
        {
          events !== null
            ?
            <EventSelectWithLoading
              onSelectChange={form.handleChange('eventType')}
              error={!!form.touched.eventType && !!form.errors.eventType}
              value={form.values.eventType}
              options={options}
              disabled={respPostmark}
              label="Retiree/LOA Events"
              name="retiree-loa-events"
              emptyOption={ {
                value: '-1',
              } }
              classes={ {
                control: `${styles.eventInfoFormField} basis-2/4 mr-4`,
                select: `event-info-type`,
              } }
            />
            :
            <div className="basis-2/4 mr-4" data-testid="event-type-skeleton">
              <Skeleton variant="rectangular" width="100%" height={60} />
            </div>
        }
      </div>
      <div className="flex flex-row basis-full">
        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Coverage Begins"
            value={
              form.values.coverageBegins ? dayjs(form.values.coverageBegins, 'YYYY-MM-DD').format('MM/DD/YYYY') : null
            }
            onChange={handleCoverageChange}
            inputFormat="MM/DD/YYYY"
            className={styles.eventInfoFormField}
            InputProps={
              {
                className: !form.values.coverageBegins ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
                disabled: respPostmark || letterSent,
              }
            }
            disabled={respPostmark || letterSent}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                data-testid="coverage-begins"
                variant="outlined"
                className="event-info-coverage-begins basis-2/4 mr-4"
                name="coverageBegins"
                {...params}
                error={form.touched.coverageBegins && Boolean(form.errors.coverageBegins)}
                helperText={form.touched.coverageBegins && form.errors.coverageBegins as any}
              />
            )}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Paid Through (Optional)"
            value={form.values.paidThrough ? dayjs(form.values.paidThrough, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
            onChange={handleDateChange('paidThrough', form)}
            className={`mb-4 ${styles.eventInfoFormField}`}
            inputFormat="MM/DD/YYYY"
            InputProps={
              {
                className: !form.values.paidThrough ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
                disabled: respPostmark,
              }
            }
            disabled={respPostmark}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                data-testid="paid-through"
                className="event-info-paid-thru basis-2/4 mr-4"
                variant="outlined"
                name="paidThrough"
                {...params}
                error={form.touched.paidThrough && Boolean(form.errors.paidThrough)}
                helperText={form.touched.paidThrough && form.errors.paidThrough as any}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </>
  );
}

export default DirectBillingEventInfoForm;
