import React from 'react';
import Backdrop from '@mui/material/Backdrop';

import styles from './Loading.module.css';

interface LoadingProps {
  text?: string;
}

function Loading({ text = 'Please wait…' }: LoadingProps): React.ReactElement {
  return (
    <Backdrop open className={styles.backdrop}>
      <div className="flex flex-col items-center">
        <div className={`${styles.imageContainer} rounded-full flex justify-center items-center bg-white
            overflow-visible relative`}
        >
          <div className={`${styles.logoImage} absolute rounded-full bg-no-repeat`} data-testid="loadingImage" />
        </div>
        <p className="text-2xl mt-4 text-white">{text}</p>
      </div>
    </Backdrop>
  );
}

export default Loading;
