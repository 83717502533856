import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {
  compose,
  isEmpty,
  pathOr,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import store from 'store';
import {
  selectLevelCoverageTiersOnDeck,
  setCobraPlanOnDeckNewPlanLevel,
  selectPlanLevelDescriptions,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { toNumber } from 'shared/utils';

function NewRateSelect() {
  const [ levelToAdd, setLevelToAdd ] = useState('');
  const addedLevels = useSelector(selectLevelCoverageTiersOnDeck).map(p => p.levelDescriptionId);
  const dispatch = useDispatch<typeof store.dispatch>();
  const planLevelOptions = useSelector(selectPlanLevelDescriptions);

  function handleAddLevelClick() {
    const level = planLevelOptions.find(i => i.levelId === toNumber(levelToAdd));
    dispatch(setCobraPlanOnDeckNewPlanLevel(level));
    setLevelToAdd('');
  }

  function isOptionDisabled(strIds: string[]) {
    return addedLevels.some(level => level ? strIds.includes(level.toString()) : false);
  }

  return (
    <div data-testid="new-rate-select" className="flex">
      <FormControl variant="outlined" className="w-full mt-4 mr-5 w-2/3">
        <InputLabel>Type of Coverage</InputLabel>
        <Select
          label="Type of Coverage"
          onChange={compose(
            setLevelToAdd,
            pathOr('', [ 'target', 'value' ]),
          )}
          value={levelToAdd}
          native
          fullWidth
          data-testid="cobra-plan-rates-type-of-coverage"
        >
          <option value="" data-testid="option-default" disabled />
          {planLevelOptions
            .filter(({ levelId }) => !addedLevels.includes(levelId))
            .map(({ levelName, levelId, conflictingUnqIds }) => (
              <option
                value={levelId}
                key={levelId}
                data-testid={`option-${levelId}`}
                disabled={isOptionDisabled(conflictingUnqIds.split(','))}
              >
                {levelName}
              </option>
            ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        color="primary"
        className="text-uppercase mt-5"
        data-testid="add-rate-button"
        onClick={handleAddLevelClick}
        disabled={isEmpty(levelToAdd)}
      >
        Add Rate
      </Button>
    </div>
  );
}

export default NewRateSelect;

