import * as React from 'react';
import { PdfTableHeader } from './PdfTableHeader';
import { PdfTableBody } from './PdfTableBody';
import { View } from '@react-pdf/renderer';

interface PdfTableProps {
    data?: any[];
}

// eslint-disable-next-line react/prefer-stateless-function
export class PdfTable extends React.PureComponent<PdfTableProps> {
  render() {
    let tableHeader = null;
    let tableBody = null;

    React.Children.forEach((this.props as any).children, (c: any) => {
      if (c.type === PdfTableHeader) {
        tableHeader = c;
      } else if (c.type === PdfTableBody) {
        tableBody = React.cloneElement(c, {
          data: c.props.data ?? this.props.data ?? [],
        });
      }
    });

    return (
      <View
        style={ {
          width: '90%',
          margin: '5%',
        } }
      >
        {tableHeader}
        {tableBody}
      </View>
    );
  }
}
