import React, { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { always, andThen, compose, equals, when, path, pipe } from 'ramda';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import store from 'store';
import { resendCode, verifyToken } from 'shared/api/multiFactorApi';
import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import { getUser } from 'authentication/authentication';
import { User } from 'authentication/user.types';
import { validationSchema } from './multi-factor-authentication.validators';
import { getCompaniesForUser } from 'companies/companies.slice';

function MultiFactorAuth(): ReactElement {
  usePageTitle('2-Step Verification');
  const history = useHistory();
  const [ userName, setUserName ] = useState<string>('');
  const dispatch = useDispatch<typeof store.dispatch>();
  const getCompanies = () => getCompaniesForUser(getUser() ?? {} as User);

  const form = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      verifyToken({
        userName,
        passCode: values.code,
      })
        .then(compose(history.push, always('/company-select')))
        .then(compose(dispatch, getCompanies))
        .catch(
          pipe(
            path<any>([ 'status' ]),
            when(
              equals(400),
              compose(
                setErrors,
                always({ code: 'The code that you entered did not match what was sent. Please try again.' }),
              ),
            ),
          ),
        );
    },
  });

  useEffect(() => {
    window.localStorage.removeItem('isPartialLogin');
    setUserName((getUser() ?? {} as User).username);
  }, []);

  return (
    <Paper className="w-1/2">
      <div className="flex flex-col p-4">
        <p className="font-bold pt-4">{ userName }</p>
        <p className="pt-4">Check your email</p>
        <p className="pt-4">Flores sent a six digit access code that will be valid for 5 minutes.</p>
        <form onSubmit={form.handleSubmit} className="pt-2">
          <TextField
            variant="outlined"
            label="Enter the code"
            name="code"
            value={form.values.code}
            error={form.touched.code && !!form.errors.code}
            helperText={form.touched.code && form.errors.code}
            onChange={form.handleChange}
          />
          <p className="pt-4">Didn't get it?
            <Button variant="text" color="primary" className="normal-case" onClick={
              pipe(
                always({ userName }),
                resendCode,
                andThen(compose(window.alert, always('Token was successfully created'))),
              )
            }
            > Resend
            </Button>
          </p>

          <div className="flex flex-row-reverse">
            <Button variant="contained" color="primary" type="submit">Next</Button>
          </div>
        </form>
      </div>
    </Paper>
  );
}

export default MultiFactorAuth;
