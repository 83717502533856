import React, { ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import { PlanYear } from './reports-tile.types';

interface PlanYearSelectProps {
  planYears: PlanYear[];
  value: number;
  onChange: (planYearId: number) => void;
}

function PlanYearSelect({ value, planYears, onChange }: PlanYearSelectProps): React.ReactElement {
  function handleChange(event: ChangeEvent<{ name?: string; value: unknown; }>) {
    const pyrid = parseInt('' + event.target.value);
    onChange(pyrid);
  }

  return (
    <FormControl variant="outlined" className="w-full">
      <InputLabel shrink id="plan-years-label">Plan Year</InputLabel>
      <Select
        label="Plan Year"
        labelId="plan-years-label"
        onChange={handleChange as any}
        value={value}
        native
      >
        {planYears.map((py) => <option value={py.pyrid} key={py.pyrid}>{py.planYearName}</option>)}
      </Select>
    </FormControl>
  );
}

export default PlanYearSelect;
