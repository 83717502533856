import React, { ReactElement, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import { compose } from 'ramda';

import { Check } from './view-checks.types';
import { currencyFormatter } from 'shared/utils';

interface CheckTableRowProps {
  check: Check;
}

function CheckTableRow({ check }: CheckTableRowProps): ReactElement {
  const [ isExpanded, setIsExpanded ] = useState(false);

  return (
    <>
      <TableRow className="check-row">
        <TableCell className="border-0">{check.checkNumber}</TableCell>
        <TableCell className="border-0">{currencyFormatter.format(check.checkAmount)}</TableCell>
        <TableCell className="border-0">{dayjs(check.postDate).format('M/D/YYYY')}</TableCell>
        <TableCell className="border-0">{currencyFormatter.format(check.appliedAmount)}</TableCell>
        <TableCell className="border-0">{currencyFormatter.format(check.creditRemaining)}</TableCell>
        <TableCell className="border-0">
          <Link
            className="cursor-pointer"
            onClick={compose(setIsExpanded, () => !isExpanded)}
            underline="hover"
          >View
          </Link>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} className={isExpanded ? '' : 'p-0'}>
          <Collapse in={isExpanded} collapsedSize={0}>
            <Table className="border">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Applied</TableCell>
                  <TableCell>Period Start</TableCell>
                  <TableCell>Period End</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  check.invoices.map(invoice => (
                    <TableRow className="invoice-row"
                      key={`${check.unqid}-${invoice.invoiceNumber}-${invoice.payAmount}`}
                    >
                      <TableCell>{invoice.invoiceNumber}</TableCell>
                      <TableCell>{currencyFormatter.format(invoice.invoiceAmount)}</TableCell>
                      <TableCell>{currencyFormatter.format(invoice.payAmount)}</TableCell>
                      <TableCell>{dayjs(invoice.periodStart).format('M/D/YYYY')}</TableCell>
                      <TableCell>{dayjs(invoice.periodEnd).format('M/D/YYYY')}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CheckTableRow;
