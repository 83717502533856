import { Switch as _Switch } from 'react-router-dom';

import AppRoute from 'routing/AppRoute';
import CompanySelect from './pages/CompanySelect';
import Dashboard from './pages/Dashboard';
import EnterCobraQePage from 'enter-cobra-qe/EnterCobraQePage';
import enterQeSteps from 'enter-cobra-qe/enter-cobra-qe-steps';
import Scratchpad from './pages/Scratchpad';
import FileUpload from 'fileupload/FileUpload';
import ReportHistory from 'report-history/ReportHistory';
import FileHistory from 'file-history/FileHistory';
import ParticipantOverview from 'participant-overview/ParticipantOverview';
import PlanEnrollmentWizard from 'plan-enrollment/PlanEnrollmentWizard';
import planEnrollmentSteps from 'plan-enrollment/plan-enrollment-steps';
import CobraPendingQENotices from 'cobra-pending-qe/CobraPendingQENotices';
import CobraPendingInitialNotices from 'cobra-pending-initial/CobraPendingInitialNotices';
import ManageSettings from 'manage-settings/ManageSettings';
import NotFoundPage from './pages/NotFound';
import UpdatePlanEnrollmentWizard from 'plan-enrollment-update/UpdatePlanEnrollmentWizard';
import updateEnrollmentSteps from 'plan-enrollment-update/update-plan-enrollment-steps';
import AddParticipant from 'add-participant/AddParticipant';
import EnterCobraInitialNotice from 'enter-cobra-initial-notice/EnterCobraInitialNotice';
import TerminateParticipant from 'terminate-participant/TerminateParticipant';
import UpdateCobraQe from 'update-cobra-qe/UpdateCobraQe';
import Reports from 'reports/Reports';
import OpenEnrollment from 'components/pages/OpenEnrollment';
import DirectBillingWizard from 'direct-billing/DirectBillingWizard';
import UpdateDirectBilling from 'update-direct-bill-event/UpdateDirectBilling';
import OpenEnrollmentResources from 'open-enrollment-resources/OpenEnrollmentResources';
import AdministrativeBilling from 'administrative-billing/AdministrativeBilling';
import MultiFactorAuth from 'multi-factor-authentication/MultiFactorAuth';
import DocumentLibrary from 'document-library/DocumentLibrary';
import DirectDebit from 'direct-debit/DirectDebit';
import AddUser from '../manage-settings/add-user/AddUser';
import ReEnrollmentChecklist from 're-enrollment-checklist/ReEnrollmentChecklist';
import CobraReEnrollmentChecklist from 're-enrollment-checklist/cobra/CobraReEnrollmentChecklist';
import CobraPlanReEnrollmentChecklist
  from 're-enrollment-checklist/cobra-reenrollment-checklist/CobraPlanReEnrollmentChecklist';
import LegacyChecklists from 're-enrollment-checklist/LegacyChecklists';
import RatesHistory from 'rates-history/RatesHistory';
import ContributionsNotForwarded from 'action-items/pages/contributions-not-forwarded/ContributionsNotForwarded';
import DirectBillReEnrollmentChecklist
  from 're-enrollment-checklist/direct-bill-reenrollment-checklist/DirectBillReEnrollmentChecklist';
import { useEffect } from 'react';
import { getUser } from 'authentication/authentication';
import { getCompaniesForUser, selectLoadingCompanies } from 'companies/companies.slice';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { EnterLeaveRequestPage } from 'enter-leave-request/EnterLeaveRequestPage';
import { LeaveRecordDetails } from 'leave-records/leave-record-details/LeaveRecordDetails';
import { ParticipantLeaveRecords } from 'leave-records/participant-leave-records/ParticipantLeaveRecords';
import { LeaveRecordsByEmployee } from 'leave-records/leave-records-by-employee/LeaveRecordsByEmployee';
import { ReportAnAbsence } from 'report-an-absence/ReportAnAbsence';
import { AbsenceReport } from 'absence-report/AbsenceReport';

const Switch = _Switch as any;

function App() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const companyLoadingStatus = useSelector(selectLoadingCompanies);

  useEffect(() => {
    const user = getUser();

    if (!!user && !window.localStorage.getItem('isPartialLogin') && companyLoadingStatus === 'uninitialized') {
      dispatch(getCompaniesForUser(user));
    }
  }, [ dispatch, companyLoadingStatus ]);

  return (
    <Switch>
      <AppRoute
        title="Home"
        path="/"
        exact
        layout="wizard"
      >
        <CompanySelect />
      </AppRoute>
      <AppRoute
        path="/verify-token"
        title="2-Step Verification"
        layout="wizard"
        authRequired={false}
      >
        <MultiFactorAuth />
      </AppRoute>
      <AppRoute
        title="Select a Company"
        path="/company-select"
        layout="wizard"
      >
        <CompanySelect />
      </AppRoute>
      <AppRoute
        title="Dashboard"
        path="/dashboard"
        layout="default"
      >
        <Dashboard />
      </AppRoute>
      <AppRoute
        title="Scratchpad"
        path="/scratchpad"
        layout="default"
      >
        <Scratchpad />
      </AppRoute>
      <AppRoute
        title="Enter COBRA QE"
        path="/enter-cobra-qe/:stepSlug"
        layout="wizard"
        steps={enterQeSteps}
      >
        <EnterCobraQePage />
      </AppRoute>
      <AppRoute
        title="Enter Leave Request"
        path="/enter-leave-request/:stepSlug"
        layout="wizard"
      >
        <EnterLeaveRequestPage />
      </AppRoute>
      <AppRoute
        title="Update COBRA QE"
        path="/update-cobra-qe/:cQualId"
        layout="wizard"
      >
        <UpdateCobraQe />
      </AppRoute>
      <AppRoute
        title="Update Retiree/LOA Billing"
        path="/update-direct-billing-event/:rQualId"
        layout="wizard"
      >
        <UpdateDirectBilling />
      </AppRoute>
      <AppRoute
        title="Enter COBRA Initial Notice"
        path="/enter-cobra-initial-notice"
        layout="default"
      >
        <EnterCobraInitialNotice />
      </AppRoute>
      <AppRoute
        title="File Upload"
        path="/file-upload"
        layout="default"
      >
        <FileUpload />
      </AppRoute>
      <AppRoute
        title="Document Library"
        path="/document-library/:tab?"
        layout="default"
      >
        <DocumentLibrary />
      </AppRoute>
      <AppRoute
        title="Administrative Billing"
        path="/administrative-billing"
        layout="default"
      >
        <AdministrativeBilling />
      </AppRoute>
      <AppRoute
        title="Report History"
        path="/report-history"
        layout="default"
      >
        <ReportHistory />
      </AppRoute>
      <AppRoute
        title="File History"
        path="/file-history"
        layout="default"
      >
        <FileHistory />
      </AppRoute>
      <AppRoute
        title="Perform Direct Debit"
        path="/perform-direct-debit"
        layout="default"
      >
        <DirectDebit />
      </AppRoute>
      <AppRoute
        title="Participant Overview"
        path="/participant-overview/:employeeNumber/:tab"
        layout="default"
      >
        <ParticipantOverview />
      </AppRoute>
      <AppRoute
        title="Plan Enrollment Wizard"
        path="/plan-enrollment/:employeeNumber/:stepSlug"
        layout="wizard"
        steps={planEnrollmentSteps}
      >
        <PlanEnrollmentWizard />
      </AppRoute>
      <AppRoute
        title="Update Plan Enrollment Wizard"
        path="/update-plan-enrollment/:employeeNumber/:planYearId/:planCode/:stepSlug"
        layout="wizard"
        exact
        steps={updateEnrollmentSteps}
      >
        <UpdatePlanEnrollmentWizard />
      </AppRoute>
      <AppRoute
        title="Update Plan Enrollment Wizard"
        path="/update-plan-enrollment/:employeeNumber/:planYearId/:planCode/:customPlanId/:stepSlug"
        layout="wizard"
        exact
        steps={updateEnrollmentSteps}
      >
        <UpdatePlanEnrollmentWizard />
      </AppRoute>
      <AppRoute
        title="Pending COBRA Qualifying Event Notices"
        path="/pending-qe-notices"
        layout="default"
      >
        <CobraPendingQENotices />
      </AppRoute>
      <AppRoute
        title="Pending COBRA Initial Notices"
        path="/pending-initial-notices"
        layout="default"
      >
        <CobraPendingInitialNotices />
      </AppRoute>
      <AppRoute
        title="ManageSettings"
        path="/manage-settings"
        layout="default"
      >
        <ManageSettings />
      </AppRoute>
      <AppRoute
        title="Add User"
        path="/add-user/:stepSlug"
        layout="wizard"
      >
        <AddUser />
      </AppRoute>
      <AppRoute
        title="Edit User"
        path="/edit-user/:stepSlug"
        layout="wizard"
      >
        <AddUser />
      </AppRoute>
      <AppRoute
        title="Add New Participant"
        path="/add-participant"
        layout="wizard"
      >
        <AddParticipant />
      </AppRoute>
      <AppRoute
        title="Terminate Participant"
        path="/terminate-participant"
        layout="default"
      >
        <TerminateParticipant />
      </AppRoute>
      <AppRoute
        title="Reports"
        path="/reports/:tab?"
        layout="default"
      >
        <Reports />
      </AppRoute>
      <AppRoute
        title="Open Enrollment"
        path="/open-enrollment"
        layout="default"
      >
        <OpenEnrollment />
      </AppRoute>
      <AppRoute
        title="Start Retiree/LOA Billing"
        path="/direct-bill-event/:stepSlug"
        layout="wizard"
      >
        <DirectBillingWizard />
      </AppRoute>
      <AppRoute
        title="Open Enrollment Resources"
        path="/open-enrollment-resources"
        layout="default"
      >
        <OpenEnrollmentResources />
      </AppRoute>
      <AppRoute
        layout="default"
        title="Re-Enrollment Checklist"
        path="/reenrollment-checklist"
        exact
      >
        <ReEnrollmentChecklist />
      </AppRoute>
      <AppRoute
        title="Cobra Open Enrollment Packets"
        path="/reenrollment-checklist/cobra/:planYearId/:tab"
        layout="wizard"
      >
        <CobraReEnrollmentChecklist />
      </AppRoute>
      <AppRoute
        title="Cobra Enrollment"
        path="/reenrollment-checklist/cobra-plan/:planYearId/:tab"
        layout="wizard"
      >
        <CobraPlanReEnrollmentChecklist />
      </AppRoute>
      <AppRoute
        title="Direct Billing Enrollment"
        path="/reenrollment-checklist/direct-bill/:planYearId/:tab"
        layout="wizard"
      >
        <DirectBillReEnrollmentChecklist />
      </AppRoute>
      <AppRoute
        title="HSA Checklist"
        path="/reenrollment-checklist/:planType/:planYearId"
        layout="wizard"
      >
        <LegacyChecklists />
      </AppRoute>
      <AppRoute
        title="Rates History"
        path="/rates-history"
        layout="default"
      >
        <RatesHistory />
      </AppRoute>
      <AppRoute
        title="HSA Contributions Not Forwarded"
        path="/action-items/hsa-not-forwarded"
        layout="default"
      >
        <ContributionsNotForwarded />
      </AppRoute>
      <AppRoute
        title="Leave Records"
        path="/leave-records"
        layout="default"
        exact
      >
        <ParticipantLeaveRecords />
      </AppRoute>
      <AppRoute
        title=""
        path="/leave-records/all-cases/:eeId"
        layout="default"
      >
        <LeaveRecordsByEmployee />
      </AppRoute>
      <AppRoute
        title=""
        path="/leave-records/:eeId/case/:caseId/:tab"
        layout="default"
      >
        <LeaveRecordDetails />
      </AppRoute>
      <AppRoute
        title="Report an Absence"
        path="/report-an-absence/:stepSlug"
        layout="wizard"
      >
        <ReportAnAbsence />
      </AppRoute>
      <AppRoute
        title="Absence Report"
        path="/leave-records/:eeId/absence-report"
        layout="default"
      >
        <AbsenceReport />
      </AppRoute>
      <AppRoute
        path="*"
        title="Not Found"
        layout="default"
      >
        <NotFoundPage />
      </AppRoute>
    </Switch>
  );
}

export default App;
