import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';

import { Participant } from 'participant-search/participant-list.types';
import { colorFromLetters } from 'components/shared/avatar-color-palette';
import { selectSelectedCompany } from 'companies/companies.slice';

interface ParticipantSubHeaderProps {
  participant: Participant;
}

function ParticipantSubHeader({ participant }: ParticipantSubHeaderProps): React.ReactElement {
  const { iddesc } = useSelector(selectSelectedCompany) ?? { iddesc: undefined };

  return (
    <div className="flex flex-nowrap w-full space-x-8">
      <Avatar className={
        `p-2 text-sm ${colorFromLetters(participant.fname + participant.lname)}`
      }
      >{participant.fname.charAt(0).toUpperCase()}{participant.lname.charAt(0).toUpperCase()}
      </Avatar>
      <p className="pt-2">
        {`${participant.fname} ${participant.lname}`}
      </p>
      <p className="pt-2">
        PID# {participant.empno}
      </p>
      <p className="pt-2">
        {iddesc ?? 'SSN'}# XXXXX{participant.payrollid ?? ''}
      </p>
    </div>
  );
}

export default ParticipantSubHeader;
