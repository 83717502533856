import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

export function useDownloading(downloadApi: Function, params: any) {
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  function handleExport() {
    startDownloading();
    downloadApi(params)
      .then(doneDownloading)
      .catch(handleError);
  }

  return { downloading, handleExport };
}

