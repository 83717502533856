import { useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import dayjs from 'dayjs';

import { Benefit } from 'shared/types/non-plan-year.types';
import { benefitErrorMessage } from './benefit-state-helpers';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface BenefitsSubsidyFieldsProps {
  idx: number;
  benefit: Benefit;
  disabled?: boolean;
}

function BenefitSubsidyFields({ idx, benefit, disabled = false }: BenefitsSubsidyFieldsProps) {
  const { setFieldValue, handleChange, errors } = useFormikContext<{benefits: Benefit[];}>();

  function handleDateChange() {
    return (value: any) => setFieldValue(
      `benefits.${idx}.subsidyEnd`, value ? dayjs(value, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
    );
  }

  return (
    <>
      <TextField
        variant="outlined"
        label="ER Subsidy"
        name={`benefits.${idx}.subsidy`}
        value={benefit.subsidy ?? ''}
        InputProps={ {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          disabled,
        } }
        className="mr-4"
        data-testid={`benefits.${idx}.subsidy`}
        onChange={handleChange}
        error={benefitErrorMessage(errors, idx, 'subsidy').length > 0}
        helperText={benefitErrorMessage(errors, idx, 'subsidy')}
      />
      <LocalizationProvider dateAdapter={AdapterDayJS}>
        <DatePicker
          label="Subsidy Ends"
          value={benefit.subsidyEnd ? dayjs(benefit.subsidyEnd, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
          onChange={handleDateChange()}
          className="m-4"
          InputProps={ {
            className: !benefit.subsidyEnd ? 'MuiFormLabel-root' : '' ,
            disabled,
          } }
          disabled={disabled}
          // eslint-disable-next-line react/jsx-no-bind
          renderInput={(params) => (
            <TextField
              data-testid={`benefits.${idx}.subsidyEnd`}
              variant="outlined"
              className="w-full"
              {...params}
              error={benefitErrorMessage(errors, idx, 'subsidyEnd').length > 0}
              helperText={benefitErrorMessage(errors, idx, 'subsidyEnd')}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default BenefitSubsidyFields;
