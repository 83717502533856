import BeachAccessRounded from '@mui/icons-material/BeachAccessRounded';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { always, compose } from 'ramda';

import { ReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import { ChecklistCard } from 're-enrollment-checklist/ChecklistCard';
import { useSelector } from 'react-redux';
import {
  selectCobraChecklistProgress,
  selectIsFinalizeCobraChecklistDisabled,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';

export function CobraPlanCard ({ plan }: { plan: ReEnrollmentChecklistPlan; }) {
  const icon = <BeachAccessRounded style={ { fontSize: 32 } } />;
  const history = useHistory();
  const summaryDisabled = useSelector(selectIsFinalizeCobraChecklistDisabled);
  const percentComplete = useSelector(selectCobraChecklistProgress);
  const buttons = (
    <div className="justify-start" data-testid="cobra-card-buttons">
      <Button
        variant="outlined"
        className="mr-5"
        onClick={compose(
          history.push,
          always(`/reenrollment-checklist/cobra-plan/${plan.planYearId}/eligible-count`),
        )}
      >Eligible Count
      </Button>
      <Button
        variant="outlined"
        className="mr-5"
        onClick={compose(
          history.push,
          always(`/reenrollment-checklist/cobra-plan/${plan.planYearId}/oe-packets-options`),
        )}
      >OE Packets
      </Button>
      <Button
        variant="outlined"
        className="mr-5"
        onClick={compose(
          history.push,
          always(`/reenrollment-checklist/cobra-plan/${plan.planYearId}/plan-rates`),
        )}
      >Plan Rates
      </Button>
      <Button
        variant="outlined"
        onClick={compose(
          history.push,
          always(`/reenrollment-checklist/cobra-plan/${plan.planYearId}/summary`),
        )}
        disabled={summaryDisabled}
      >Summary
      </Button>
    </div>
  );

  return (
    <ChecklistCard
      icon={icon}
      title="COBRA"
      planYearStart={plan.planYearStart}
      planYearEnd={plan.planYearEnd}
      buttons={buttons}
      progress={percentComplete}
    />
  );
}
