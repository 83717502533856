import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import SearchInput from 'components/shared/SearchInput';
import {
  selectUserCompanies,
  setSelectedCompany,
  selectLoadingCompanies,
} from 'companies/companies.slice';
import CompanyList from 'companies/CompanyList';
import { Company } from 'companies/company.types';
import { getUserPermissions } from 'shared/api/usersApi';
import Loading from 'components/shared/Loading';
import PageContentPaper from 'components/layout/PageContentPaper';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import store from 'store';

function CompanySelect(): React.ReactElement {
  const [ filter, setFilter ] = useState('');

  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  usePageTitle('Select a Company');

  const companies: Company[] = useSelector(selectUserCompanies);
  const loading = useSelector(selectLoadingCompanies);

  const switchCompany = useCallback(async (company: Company) => {
    try {
      await getUserPermissions(company.compid);
    } catch (ex) {
      dispatch(addHttpErrorNotification(ex));

      return;
    }

    dispatch(setSelectedCompany(company));
    history.push('/dashboard');
  }, [ dispatch, history ]);

  useEffect(() => {
    if (companies.length === 1) {
      switchCompany(companies[0]);
    }
  }, [ companies, switchCompany ]);

  function handleFilter(search: string) {
    setFilter(search);
  }

  function handleCompanySelect(company: Company) {
    switchCompany(company);
  }

  function companyFilter(company: Company) {
    return company.compname.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  }

  function companySelector(): React.ReactElement {
    if (loading === 'pending') {
      return <Loading />;
    } else {
      if (companies.length > 1) {
        return (
          <>
            <PageContentPaper className="flex flex-col">
              <div className="w-1/2">
                <SearchInput placeholder="Company Search" label="" onChange={handleFilter} size="small" />
              </div>
              <CompanyList
                companies={companies.filter(companyFilter)}
                onSelected={handleCompanySelect}
                className="my-4"
              />
            </PageContentPaper>
          </>
        );
      }

      if (companies.length === 0) {
        return (
          <PageContentPaper className="flex flex-col">
            <Typography variant="body1">
            You do not have access to any companies. Please contact your Account Manager.
            </Typography>
          </PageContentPaper>
        );
      }
    }

    return <div />;
  }

  return <div className="flex flex-col m-4 w-full" data-testid="companySelect">{companySelector()}</div>;
}

export default CompanySelect;
