import { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose, T, F, tap } from 'ramda';

import ReportsTile from 'reports/shared/ReportsTile';
import { ReportType, downloadPendingNoticeReport } from 'shared/api/pendingNoticesApi';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { usePendingNoticeCount } from '../hooks/usePendingNoticeCount';
import { CRLType } from 'shared/types/non-plan-year.types';

interface PendingResponsesProps {
  type: ReportType;
  serviceType: CRLType;
}

function PendingResponses({ type, serviceType }: PendingResponsesProps) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const count = usePendingNoticeCount(type, serviceType);
  const [ downloading, setDownloading ] = useState<boolean>(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  function handleExport() {
    startDownloading();
    downloadPendingNoticeReport(compid, serviceType)
      .then(doneDownloading)
      .catch(handleError);
  }

  return (
    <ReportsTile
      count={count}
      title="Pending Notices"
      description="List of participants for whom a Qualifying Event
       notice was entered and is pending to be mailed."
      variant="primary-dark"
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default PendingResponses;
