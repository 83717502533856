import React, { forwardRef, MouseEvent, ReactElement, RefObject } from 'react';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { always, both, compose, cond, equals, gt, length, T, __ } from 'ramda';
import { popperLoadingIndicator } from 'participant-search/ParticipantSearchResults';
import { LeaveRecordsSearchResultRow } from 'leave-records/components/LeaveRecordsSearchResultRow';
import { selectEmployeeSearchResults, selectEmployeeSearchResultsStatus } from 'leave-records/leave-records.slice';

interface Props {
  anchorRef: RefObject<HTMLElement>;
  isOpen: boolean;
  onClose: () => void;
}

function LeaveRecordsSearchResultsContainer({ anchorRef, isOpen, onClose }: Readonly<Props>) {
  const searchResults = useSelector(selectEmployeeSearchResults);
  const searchStatus = useSelector(selectEmployeeSearchResultsStatus);
  const width = anchorRef.current?.offsetWidth ?? 0;

  function handleClose(event: MouseEvent<EventTarget>) {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    onClose();
  }

  return (
    <div className="w-full" data-testid="popper-menu">
      <Popper
        style={ { width, zIndex: 1 } }
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={ { transformOrigin: placement.includes('bottom') ? 'left top' : 'left bottom' } }
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose as any}>
                <div>
                  { cond<string, ReactElement>([
                    [ equals('pending'), always(
                      popperLoadingIndicator(width),
                    ) ],
                    [ both(
                      compose(gt(__, 0), length, always(searchResults)),
                      compose(equals('fulfilled')),
                    ),
                    always(
                      <>
                        {searchResults.map(result => (
                          <LeaveRecordsSearchResultRow
                            onClose={onClose}
                            employeeSearchResult={result}
                            key={result.eeId}
                          />
                        ))}
                      </>,
                    ) ],
                    [ both(
                      compose(equals(0), length, always(searchResults)),
                      compose(equals('fulfilled')),
                    ), always(<MenuItem>No Results Found</MenuItem>) ],
                    [ T, always(<div />) ],
                  ])(searchStatus)}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default forwardRef(LeaveRecordsSearchResultsContainer);
