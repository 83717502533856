import request, { ContentType } from 'shared/api-request';
import { MonthAndYear } from 'shared/types/file-report-history.types';
import { WebFile } from 'file-history/file-history.types';

export function getFileHistoryMonths(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get file history for invalid company.'));
  }

  return request<MonthAndYear[]>(`/api/companies/${companyId}/filehistory`);
}

export function getFileHistoryFiles(companyId: number, year: number, month: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get file list for invalid company.'));
  }

  if (year < 1986) {
    return Promise.reject(new Error('Cannot get file list for invalid year.'));
  }

  if (month < 1 || month > 12) {
    return Promise.reject(new Error('Cannot get file list for invalid month.'));
  }

  return request<WebFile[]>(`/api/companies/${companyId}/filehistory/${year}/${month}`);
}

export function downloadFileHistoryFile(companyId: number, fileId: number, fileName: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download file for invalid company.'));
  }

  if (fileId <= 0) {
    return Promise.reject(new Error('Cannot download file for invalid file ID.'));
  }

  if (fileName.length === 0) {
    return Promise.reject(new Error('Cannot download file for invalid file name.'));
  }

  const uri = `/api/companies/${companyId}/filehistory/download/${fileId}/${fileName}`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.DEFAULT } });
}
