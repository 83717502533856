import request, { ContentType } from 'shared/api-request';
import { validateDate } from './validateDate';
import queryStringTag from './reportQueryStringTag';

export function getActiveParticipantCount(
  companyId: number,
  serviceType: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load active participant total for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/active-participants/${serviceType}`;

  return request<number>(uri);
}

export function downloadActiveParticipantsReport(
  companyId: number,
  serviceType: string,
  startDate?: string,
  endDate?: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download active participant report for invalid company.'));
  }

  try {
    const start = validateDate('Cannot download active participant report for invalid start date.', startDate);
    const end = validateDate('Cannot download active participant report for invalid end date.', endDate);
    const uri =
      queryStringTag`/api/companies/${companyId}/reports/active-participants/${serviceType}/export${start}${end}`;

    return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (err) {
    return Promise.reject(err);
  }
}

export function downloadActiveParticipantsOneLineReport(
  companyId: number,
  serviceType: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download active participant one line report for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/active-participants/${serviceType}/one-line/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function downloadActiveParticipantsFullDataReport(
  companyId: number,
  serviceType: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download active participant full data report for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/active-participants/${serviceType}/full-data/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}
