import request, { ContentType } from 'shared/api-request';
import { MonthAndYear } from 'shared/types/file-report-history.types';
import { ReportHistory } from 'report-history/report-history.types';

export function getReportHistoryMonths(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get report history months for invalid company.'));
  }

  return request<MonthAndYear[]>(`/api/companies/${companyId}/reporthistory/get-available-dates`);
}

export function getReportHistories(companyId: number, year: number, month: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get report history for invalid company.'));
  }

  if (year < 1986) {
    return Promise.reject(new Error('Cannot get report history for invalid year.'));
  }

  if (month < 1 || month > 12) {
    return Promise.reject(new Error('Cannot get report history for invalid month.'));
  }

  var uri = `/api/companies/${companyId}/reporthistory/${year}/${month}/get-report-history-by-month-year`;

  return request<ReportHistory[]>(uri);
}

export function downloadReportHistoryFile(companyId: number, unqId: number, fileName: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download report for invalid company.'));
  }

  if (unqId <= 0) {
    return Promise.reject(new Error('Cannot download report for invalid ID.'));
  }

  if (fileName.length === 0) {
    return Promise.reject(new Error('Cannot download report for invalid file name.'));
  }

  const uri = `/api/companies/${companyId}/reporthistory/download/${unqId}/${fileName}`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.DEFAULT } });
}
