import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { compose, tap, always } from 'ramda';

import request, { APIOptions } from 'shared/api-request';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { FileType } from './file-types';
import store from 'store';

export function useFileTypes() {
  const dispatch = useDispatch<typeof store.dispatch>();

  const [ fileTypes, setFileTypes ] = useState<FileType[] | null>(null);

  const [ setDefault ] = useState(() => compose(setFileTypes, always([])));
  const [ handleError ] = useState(() => compose(
    tap(setDefault),
    dispatch,
    addHttpErrorNotification,
  ));
  const { current: abortCtrl } = useRef(new AbortController());
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    const uri = '/api/file-upload/file-types';
    const apiOpts: APIOptions = { method: 'GET', signal: abortCtrl.signal };
    request<FileType[]>(uri, apiOpts)
      .then((result) => {
        if (mountedRef.current) {
          setFileTypes(result ?? []);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
      abortCtrl.abort();
    };
  }, [ handleError, abortCtrl ]);

  return fileTypes;
}
