import * as yup from 'yup';

import { validationSchema as piSchema } from 'shared/participant-info.validators';
import { payrollid, requiredText } from 'shared/common-validators';

export const validationSchema = (requirePayFreq = true) => {
  const schema = piSchema.concat(yup.object({ ...payrollid }));

  if (requirePayFreq) {
    return schema.shape({
      payfreq: yup
        .string()
        .nullable()
        .required(requiredText),
    });
  }

  return schema;
};
