import * as yup from 'yup';

const requiredText = 'This field is required';

export const validationSchema = yup.object({
  confname: yup
    .string()
    .nullable()
    .trim()
    .max(15, 'Must be 15 characters or less')
    .required(requiredText),
  conlname: yup
    .string()
    .nullable()
    .trim()
    .max(20, 'Must be 20 characters or less')
    .required(requiredText),
  conaddr1: yup
    .string()
    .nullable()
    .trim()
    .max(30, 'Must be 30 characters or less')
    .required(requiredText),
  conaddr2: yup
    .string()
    .nullable()
    .max(30, 'Must be 30 characters or less'),
  concity: yup
    .string()
    .nullable()
    .trim()
    .max(20, 'Must be 20 characters or less')
    .required(requiredText),
  constate: yup
    .string()
    .trim()
    .required(requiredText),
  conzip: yup
    .string()
    .nullable()
    .trim()
    .max(10, 'Must be 10 characters or less')
    .required(requiredText),
  conphone: yup
    .string()
    .nullable()
    .trim()
    .min(10, 'Must be at least 10 characters')
    .max(17, 'Must be 17 characters or less')
    .test('digits', 'Must be at least 10 digits',
      (phone) => !phone || phone.replace(/[A-Za-z$-.]/g, '').length >= 10)
    .required(requiredText),
  confax: yup
    .string()
    .nullable()
    .min(10, 'Must be at least 10 characters')
    .max(17, 'Must be 17 characters or less'),
  conemail: yup
    .string()
    .nullable()
    .trim()
    .max(60, 'Must be 60 characters or less')
    .matches(/.+@.+/, 'Must be a valid email address')
    .required(requiredText),
});
