import React, { useState, ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export interface PasswordInputProps {
  onPasswordChanged: (password: string) => void;
  onInput?: any;
  size?: 'small' | 'medium'; // this gets passed along to the TextField. Default is "medium"
  placeholder?: string;
  [otherProp: string]: any;
}

function PasswordInput({
  onPasswordChanged,
  size,
  placeholder,
  ...rest }:
  PasswordInputProps): React.ReactElement {
  const [ showPassword, setShowPassword ] = useState(false);

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleChanged(evt: ChangeEvent<HTMLInputElement>) {
    const newVal = evt.target.value;
    onPasswordChanged(newVal);
  }

  return (
    <TextField
      placeholder={placeholder}
      size={size}
      onChange={handleChanged}
      type={showPassword ? 'text' : 'password'}
      {...rest}
      InputProps={ { // <-- This is where the toggle button is added.
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleShowPassword}
              onMouseDown={handleShowPassword}
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      } }
    />
  );
}

export default PasswordInput;
