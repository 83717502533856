export const ModalContent = new Map([
  [ 'CA', `You have indicated that this COBRA Event is subject to CA state COBRA continuation 
  laws in addition to federal COBRA law. This will extend length of continuation coverage 
  beyond the COBRA maximum coverage period for the participant. Generally, CA state 
  continuation only applies to fully insured medical, dental, vision, and prescription drug 
  plans issued in California. If your plans are not subject to CA state continuation, please 
  select “Termination-Voluntary” or “Termination-Involuntary” without a state listed as the 
  event type for this participant. Please note, if you select state continuation then CA 
  state continuation will be applied to all benefits indicated in this event. If state 
  continuation should only apply to certain benefits under your plan, please enter a separate 
  COBRA event for all benefits not subject to CA state COBRA continuation.  If you have 
  questions about whether state continuation applies, please consult with your legal counsel 
  and your insurance carrier.` ],
  [ 'CT', `You have indicated that this COBRA Event is subject to CT state COBRA continuation 
  laws in addition to federal COBRA law. This will extend length of continuation coverage 
  beyond the COBRA maximum coverage period for the participant. Generally, CT state 
  continuation only applies to fully insured medical plans issued in Connecticut.  If your 
  plans are not subject to CT state continuation, please select “Termination-Voluntary” or 
  “Termination-Involuntary” without a state listed as the event type for this participant. 
  Please note, if you select state continuation then CT state continuation will be applied to 
  all benefits indicated in this event. If state continuation should only apply to medical 
  benefits under your plan, please enter a separate COBRA event for all benefits not subject 
  to CT state COBRA continuation (e.g., dental and vision plans).  If you have questions about 
  whether state continuation applies, please consult with your legal counsel and your 
  insurance carrier.` ],
  [ 'NY', `You have indicated that this COBRA Event is subject to NY state COBRA continuation 
  laws in addition to federal COBRA law. This will extend length of continuation coverage 
  beyond the COBRA maximum coverage period for the participant. Generally, NY state 
  continuation only applies to fully insured medical plans issued in New York.  If your plans 
  are not subject to NY state continuation, please select “Termination-Voluntary” or 
  “Termination-Involuntary” without a state listed as the event type for this participant. 
  Please note, if you select state continuation then NY state continuation will be applied to 
  all benefits indicated in this event. If state continuation should only apply to medical 
  benefits under your plan, please enter a separate COBRA event for all benefits not subject 
  to NY state COBRA continuation (e.g., dental and vision plans).  If you have questions 
  about whether state continuation applies, please consult with your legal counsel and your 
  insurance carrier.` ],
  [ 'TX', `You have indicated that this COBRA Event is subject to TX state COBRA continuation 
  laws in addition to federal COBRA law. This will extend length of continuation coverage 
  beyond the COBRA maximum coverage period for the participant. Generally, TX state 
  continuation only applies to fully insured medical plans issued in Texas.  If your plans 
  are not subject to TX state continuation, please select “Termination-Voluntary” or 
  “Termination-Involuntary” without a state listed as the event type for this participant. 
  Please note, if you select state continuation then TX state continuation will be applied to 
  all benefits indicated in this event. If state continuation should only apply to medical 
  benefits under your plan, please enter a separate COBRA event for all benefits not subject 
  to TX state COBRA continuation (e.g., dental and vision plans).  If you have questions 
  about whether state continuation applies, please consult with your legal counsel and your 
  insurance carrier.` ],
]);
