import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllDateFilterableFmlaReports,
  getAllFmlaReportsCountsForDashboard,
} from 'shared/api/fmlaReportsApi';

export const fetchFMLAReportCountsForDashboard = createAsyncThunk(
  'fmla-reports/fetchFMLAReportCountsForDashboard',
  getAllFmlaReportsCountsForDashboard,
);

export const fetchDateFilterableFmlaReports = createAsyncThunk(
  'fmla-reports/fetchDateFilterableFmlaReports',
  getAllDateFilterableFmlaReports,
);

