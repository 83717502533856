import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MonthAndYear } from 'shared/types/file-report-history.types';
import { WebFile } from 'file-history/file-history.types';
import { AppThunk } from 'store';
import { assertIsDefined } from 'shared/asserts';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { getFileHistoryFiles, getFileHistoryMonths } from 'shared/api/fileHistoryApi';
import { RootState } from 'store/rootReducer';

export interface FileHistoryState {
  monthYears: MonthAndYear[] | null;
  webFiles: WebFile[] | null;
  selectedMonthYear: MonthAndYear | null;
  selectedReportType: string | null;
}

export const initialState: FileHistoryState = {
  monthYears: [],
  webFiles: [],
  selectedMonthYear: null,
  selectedReportType: null,
};

const fileHistorySlice = createSlice({
  name: 'fileHistory',
  initialState,
  reducers: {
    setMonthYears(state, { payload }: PayloadAction<MonthAndYear[] | null>) {
      state.monthYears = payload;
    },
    setWebFiles(state, { payload }: PayloadAction<WebFile[] | null>) {
      state.webFiles = payload;
    },
    setSelectedMonthYear(state, { payload }: PayloadAction<MonthAndYear | null>) {
      state.selectedMonthYear = payload;
    },
    setSelectedReportType(state, { payload }: PayloadAction<string | null>) {
      state.selectedReportType = payload;
    },
  },
});

export const {
  setMonthYears,
  setWebFiles,
  setSelectedMonthYear,
  setSelectedReportType,
} = fileHistorySlice.actions;

export default fileHistorySlice.reducer;

// thunk
export function loadMonthYears(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setMonthYears(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const monthYears = await getFileHistoryMonths(selectedCompany.compid);
      dispatch(setMonthYears(monthYears));
      dispatch(setSelectedMonthYear(monthYears?.length ? monthYears[0] : null));
    } catch (ex) {
      dispatch(setMonthYears([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadWebFiles(year: number, month: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setWebFiles(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const webFiles = await getFileHistoryFiles(selectedCompany.compid, year, month);
      dispatch(setWebFiles(webFiles));
    } catch (ex) {
      dispatch(setWebFiles([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectMonthYears = (state: RootState): MonthAndYear[] | null => state.fileHistory.monthYears;

export const selectWebFiles = (state: RootState): WebFile[] | null => state.fileHistory.webFiles;

export const selectSelectedMonthYear = (state: RootState): MonthAndYear | null => state.fileHistory.selectedMonthYear;

export const selectSelectedReportType = (state: RootState): string | null => state.fileHistory.selectedReportType;
