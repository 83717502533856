import CommonSelect from './form/CommonSelect';
import { mapToSelectOption } from 'shared/form-helpers';
import { DirectDebitAccount as DirectDebitAccountType } from 'shared/types/direct-debit-accounts.types';

export type DirectDebitAccountOption = DirectDebitAccountType;

export interface DirectDebitAccountProps {
  accounts: DirectDebitAccountOption[] | null;
  value: string;
  label?: string;
  name: string;
  onAccountChanged: (cddid: string) => void;
  error?: boolean;
  className?: string;
  isRequired?: boolean;
  disabled?: boolean;
}

function DirectDebitAccount({
  accounts,
  value,
  label = 'Account to Pay From',
  name,
  onAccountChanged,
  error = false,
  className = '',
  isRequired = true,
  disabled = false,
}: DirectDebitAccountProps) {
  const options = mapToSelectOption(accounts ?? [], 'cddcomment', 'cddid');

  function handleChange(val: string) {
    onAccountChanged(val);
  }

  return (
    <CommonSelect
      options={options}
      label={label}
      name={name}
      value={value}
      onSelectChange={handleChange}
      error={isRequired && error}
      classes={ {
        control: `${className} w-full`,
        error: className,
      } }
    />
  );
}

export default DirectDebitAccount;
