import request, { ContentType } from 'shared/api-request';
import { FilesApiSection } from 'shared/types/files-api.types';

export function listCloudFilesInSection(companyId: number, section: FilesApiSection) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot list files for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/files/${section}/`;

  return request(uri);
}

export function downloadCloudFile(companyId: number, section: FilesApiSection, fileName: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download file for invalid company.'));
  }

  if (fileName.length === 0) {
    return Promise.reject(new Error('Cannot download file for invalid file name.'));
  }

  const uri = `/api/companies/${companyId}/files/${section}/${fileName}`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.DEFAULT } });
}
