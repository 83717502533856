import React, { useState, useEffect } from 'react';

import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import PlanSelect from 'plan-enrollment/plan-selection/PlanSelect';
import { useBalanceReportPlans } from './hooks/useBalanceReportPlans';
import EnrollmentRosterTile from './EnrollmentRosterTile';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import ReportsByDateRange from 'reports/balance/ReportsByDateRange';
import ReductionListTile from './ReductionListTile';
import DebitCardWarnings from 'reports/balance/DebitCardWarnings';
import UnsubstantiatedDebitCard from 'reports/balance/UnsubstantiatedDebitCard';
import PageContentPaper from 'components/layout/PageContentPaper';
import ContributionsNotForwarded from 'reports/balance/ContributionsNotForwarded';
import AnnualAllocationAudit from './AnnualAllocationAudit';

const allowedPlanCodes = [ 'D', 'H', 'M', 'S', 'T' ];

function BalanceAccountReports() {
  const plans = useBalanceReportPlans();
  const [ selectedPlan, setSelectedPlan ] = useState<OpenPlan | undefined>();

  function handlePlanChange(planId?: number) {
    if (planId === undefined || planId < 0) {
      setSelectedPlan(undefined);
    } else if (plans) {
      const plan = plans.find((p) => p.unqid === planId);
      setSelectedPlan(plan);
    }
  }

  useEffect(() => {
    if (plans?.length) {
      setSelectedPlan(plans[0]);
    }
  }, [ plans ]);

  const PlanSelectWithLoader = withLoopIcon(
    PlanSelect,
    'Loading plans...',
    plans === null,
    'w-full plan-enrollment-plans-loading',
  );

  return (
    <PageContentPaper>
      <div className="w-full">
        <div className="w-1/3">
          <PlanSelectWithLoader
            name="planId"
            plans={plans?.filter((p) => allowedPlanCodes.includes(p.plccode)) ?? []}
            value={selectedPlan?.unqid}
            error={false}
            onPlanSelected={handlePlanChange}
            showSubPlan={false}
          />
          <small className="text-gray-chateu text-xs ml-4">
            Select the report type to display for all reports below
          </small>
        </div>

        <div className="py-8 mb-8 border-neutral5 border-solid border-b-2">
          <div className="flex  space-x-4">
            <div className="w-1/2">
              <EnrollmentRosterTile selectedPlan={selectedPlan} />
            </div>
            <div className="w-1/2">
              <ReductionListTile selectedPlan={selectedPlan} />
            </div>
            <div className="flex space-x-4" >
              <div className="flex-grow flex flex-col space-y-4">
                <div className="h-1/2 w-full">
                  <DebitCardWarnings selectedPlan={selectedPlan} />
                </div>
                <div className="h-1/2 w-full">
                  <UnsubstantiatedDebitCard selectedPlan={selectedPlan} />
                </div>
              </div>
              <div className="flex-grow flex flex-col space-y-4">
                <div className="h-1/2 w-full">
                  <AnnualAllocationAudit selectedPlan={selectedPlan} />
                </div>
                {
                  selectedPlan?.plccode.toLocaleLowerCase() === 's'
                    ? (
                      <div className="h-1/2 w-full">
                        <ContributionsNotForwarded />
                      </div>
                    )
                    : <></>
                }
              </div>
            </div>
          </div>
        </div>

        <ReportsByDateRange selectedPlan={selectedPlan} />
      </div>
    </PageContentPaper>
  );
}

export default BalanceAccountReports;
