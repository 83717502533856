import { useState } from 'react';
import { useSelector } from 'react-redux';
import { without } from 'ramda';

import { useActiveParticipantCount } from '../hooks/useActiveParticipantCount';
import { selectSelectedCompany } from 'companies/companies.slice';
import ReportsTile from 'reports/shared/ReportsTile';
import {
  downloadActiveParticipantsFullDataReport,
  downloadActiveParticipantsReport,
  downloadActiveParticipantsOneLineReport,
} from 'shared/api/activeParticipantsApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { CRLType } from 'shared/types/non-plan-year.types';

enum Downloading {
  AP,
  AP_ONE_LINE,
  AP_FULL_DATA
}

interface CRLProps {
  serviceType: CRLType;
}

function ActiveParticipants({ serviceType }: CRLProps) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const count = useActiveParticipantCount(serviceType);
  const [ downloading, setDownloading ] = useState<Downloading[]>([]);

  const errorNotifier = useErrorNotifier();

  function description(type: Downloading) {
    if (serviceType.toUpperCase() === 'COBRA') {
      switch (type) {
      case Downloading.AP: return 'Detailed list of participants currently on COBRA including their dependent names.';
      case Downloading.AP_ONE_LINE: return 'List of each participant active on COBRA not including dependents.';
      case Downloading.AP_FULL_DATA: return 'List of each participant active on COBRA including dependents.';
      }
    } else {
      switch (type) {
      case Downloading.AP: return 'Detailed list of active participants including their dependent names.';
      case Downloading.AP_ONE_LINE: return 'List of each participant active not including dependents.';
      case Downloading.AP_FULL_DATA: return 'List of each participant active including dependents.';
      }
    }
  }

  function download(type: Downloading) {
    switch (type) {
    case Downloading.AP: return downloadActiveParticipantsReport(compid, serviceType);
    case Downloading.AP_ONE_LINE: return downloadActiveParticipantsOneLineReport(compid, serviceType);
    case Downloading.AP_FULL_DATA: return downloadActiveParticipantsFullDataReport(compid, serviceType);
    }
  }

  function handleExport(type: Downloading) {
    return () => {
      if (compid) {
        setDownloading([ ...downloading, type ]);
        download(type)
          .catch(errorNotifier)
          .finally(() => setDownloading(without([ type ], downloading)));
      }
    };
  }

  return (
    <>
      <ReportsTile
        count={count}
        title="Active Participants"
        description={description(Downloading.AP)}
        variant="primary-main"
        onExport={handleExport(Downloading.AP)}
        downloading={downloading.includes(Downloading.AP)}
      />

      <ReportsTile
        count={count}
        title="Active Participants One Line"
        description={description(Downloading.AP_ONE_LINE)}
        variant="primary-main"
        onExport={handleExport(Downloading.AP_ONE_LINE)}
        downloading={downloading.includes(Downloading.AP_ONE_LINE)}
      />

      <ReportsTile
        count={count}
        title="Active Participants Full Data"
        description={description(Downloading.AP_FULL_DATA)}
        variant="primary-main"
        onExport={handleExport(Downloading.AP_FULL_DATA)}
        downloading={downloading.includes(Downloading.AP_FULL_DATA)}
      />
    </>
  );
}

export default ActiveParticipants;
