import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { __, always, assoc, compose } from 'ramda';

import { FileListItem } from 're-enrollment-checklist/re-enrollment-checklist.types';
import { selectOePacketFiles } from 're-enrollment-checklist/re-enrollment-checklist.slice';
import store from 'store';
import handleDownloadDocument from 'shared/utils/download-document';
import { deleteOeDocument } from 're-enrollment-checklist/re-enrollment-checklist.thunks';

interface Props {
  companyId: number;
  planYearId: string;
  showDelete?: boolean;
  showDownload?: boolean;
}

function OeFiles({ companyId, planYearId, showDelete, showDownload }: Props) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const oePacketFiles = useSelector(selectOePacketFiles);

  function handleDelete(fileName: string) {
    return compose(
      dispatch,
      deleteOeDocument,
      assoc('fileName', __, { companyId, planYearId }),
      always(fileName),
    );
  }

  return (
    <div className="flex flex-wrap">
      {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        Array.from(oePacketFiles ?? []).map((f: FileListItem) => (
          <div key={f.name} className="w-1/3 p-2">
            <div className="flex content-center items-center px-2 border rounded-lg border-gray-200 h-32 relative">
              <div className="flex flex-row-reverse absolute w-full top-0">
                {showDelete && (
                  <IconButton color="primary" className="mr-2">
                    <DeleteIcon
                      className="px-1"
                      data-testid="oefile-delete"
                      onClick={handleDelete(`${f.fullName}`)}
                    />
                  </IconButton>
                )}
                {showDownload && (
                  <IconButton color="primary">
                    <DownloadIcon
                      className="px-1"
                      data-testid="oefile-download"
                      onClick={handleDownloadDocument(companyId, planYearId, `${f.fullName}`)}
                    />
                  </IconButton>
                )}
              </div>
              <p className="self-center mt-2"><DescriptionIcon color="primary" /> {f.name}{f.extension}</p>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default OeFiles;
