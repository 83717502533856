import { Response } from 'miragejs';
import { pipe, path, map, objOf } from 'ramda';
import { noContentResponse, createdResponse, okResponse } from './utils.js';

function getNonPlanYearEnrollments(schema) {
  const { models: data } = schema.cobraEnrollments.all() ?? [];
  return okResponse(data)();
}

function getCobraEnrollments(schema) {
  const { models } = schema.cobraEnrollments.all() ?? [];
  const data = models.map((d, i) => ({ ...d.attrs, cQualId: i + 1 }));
  return okResponse(data)();
}

function routing(server) {
  server.get('/companies/:companyId/open-plans', (schema, request) => {
    const pyrid = parseInt(request.queryParams.pyrid ?? '0');
    const { models } = schema.openPlans.all();
    const data = models
      .map((m) => m.attrs)
      .filter((model) => pyrid === 0 ? true : model.pyrid === pyrid);
    return okResponse(data)();
  });

  server.get('/companies/:companyId/open-plans/custom', (schema, request) => {
    const pyrid = parseInt(request.queryParams.pyrid ?? '0');
    const { models } = schema.openPlans.all();
    const data = models
      .map((m) => m.attrs)
      .slice(-2)
      .filter((model) => pyrid === 0 ? true : model.pyrid === pyrid);
    return okResponse(data)();
  });

  server.get('/companies/:companyId/pay-dates/:payFreqId', (schema) => {
    const { models } = schema.payDates.all();
    const data = models.map((m) => m.attrs.payDate);
    return okResponse(data)();
  });

  server.post('/companies/:companyId/plan-enrollment', createdResponse);

  server.put('/companies/:companyId/plan-enrollment', () => {
    return new Response(200, { 'content-length': 0 }, {});
  });

  server.post('/companies/:companyId/plan-enrollment/custom-enrollment', createdResponse);

  server.get('/companies/:companyId/plan-enrollment/get-cobra-enrollments/:empno', getCobraEnrollments);

  server.get('/companies/:companyId/plan-enrollment/get-retiree-enrollments/:empno', getNonPlanYearEnrollments);

  server.get('/companies/:companyId/plan-enrollment/get-loa-enrollments/:empno', getNonPlanYearEnrollments);

  server.get('/companies/:companyId/plan-enrollment/enrollments/:empno', (schema, request) => {
    const dependents = pipe(
      objOf('empno'),
      schema.dependents.where,
      path(['models']),
      map((d) => d.attrs),
    )(request.params.empno);

    const data = pipe(
      path(['models']),
      map((m) => {
        if (m.attrs.planCode === '0') {
          return { ...m.attrs, dependents };
        }
        return m.attrs;
      })
    )(schema.enrolledPlans.all());

    const length = JSON.stringify(data).length;

    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/plan-enrollment/:pyrid/transactions/:empno/plan/:plccode', (schema) => {
    const { models: data } = schema.enrollmentTransactions.all() ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get(
    '/companies/:compid/plan-enrollment/transactions/custom/:empno/plan/:customPlanId/type/pending',
    (schema) => {
    const { models: data } = schema.customPendingTransactions.all() ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
    });

  server.get(
    '/companies/:compid/plan-enrollment/transactions/custom/:empno/plan/:customPlanId/type/paid',
    (schema) => {
      const { models: data } = schema.customPaidTransactions.all() ?? [];
      const length = JSON.stringify(data).length;
      return new Response(200, { 'content-length': length }, { data });
    });

  server.get('/companies/:companyId/plan-enrollment/regular/:employeeNumber/:planYearId/:planCode', (schema) => {
    const { models: plans } = schema.openPlans.all();
    const planDetails = {
      lfsa: false,
      type: 'MRA',
      partStart: '2020-03-15', // begin date
      partEnd: '2020-12-31', // end date
      partAnn: 5000, // annual allocation
      firstPayDateOfChange: '2021-03-31',
      payPeriodDeduction: 500,
    };
    return new Response(200, { 'content-length': 1 }, { data: { plan: plans[0], planDetails } });
  });

  server.get('/companies/:companyId/plan-enrollment/custom/:employeeNumber/:planYearId/:customPlanId', (schema) => {
    const { models: plans } = schema.openPlans.all();
    const planDetails = {
      lfsa: false,
      isBalance: true,
      type: 'CUSTOM',
      partStart: '2020-03-15', // begin date
      partEnd: '2020-12-31', // end date
      currentContributions: 5000, // annual allocation
      coveredDependents: [],
    };
    return new Response(200, { 'content-length': 1 }, { data: { plan: plans[6], planDetails } });
  });

  server.delete(
    '/companies/:companyId/plan-enrollment/:planYearId/:planCode/participant/:employeeNumber',
    noContentResponse,
  );

}

export default routing;
