import GlobalReimbursements from 'reports/global/tiles/GlobalReimbursements';

export function GlobalReports() {
  return (
    <div>
      <h3 className="text-base font-medium uppercase">Global Reports</h3>
      <div className="flex flex-row justify-start space-x-8 my-4">
        <div className="w-1/3">
          <GlobalReimbursements />
        </div>
      </div>
    </div>
  );
}

export default GlobalReports;
