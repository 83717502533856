import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { AdminInvoice } from '../administrative-billing.types';
import { getAdministrativeBillingInvoices } from 'shared/api/administrativeBillingApi';
import { selectSelectedCompany } from 'companies/companies.slice';

export function useAdministrativeBillingInvoices() {
  const errorNotifier = useErrorNotifier();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ invoices, setInvoices ] = useState<AdminInvoice[] | null>(null);

  const [ setDefault ] = useState(() => compose(setInvoices, always(null)));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (compid > 0) {
      setInvoices(null);
      getAdministrativeBillingInvoices(compid)
        .then((result) => {
          if (mountedRef.current) {
            setInvoices(result?.map(el => ({ ...el, open: el.amount - el.paid, companyId: compid })) ?? []);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ handleError, compid ]);

  return invoices;
}
