import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compose, F } from 'ramda';

import WizardStepper, { WizardStep } from 'components/shared/WizardStepper';
import { PlanEnrollmentFormProps, OpenPlan, PlanDetails, MRADetails } from './plan-enrollment.types';
import PageContentPaper from 'components/layout/PageContentPaper';
import { selectParticipant } from 'participant-search/participant.slice';
import {
  resetPlanEnrollment,
  selectPlanEnrollmentDetails,
  selectPlanEnrollmentPayload,
  selectPlanEnrollmentPlan,
  setPlan,
  setPlanDetails,
} from './plan-enrollment.slice';
import CancelModal from 'components/shared/CancelModal';
import Loading from 'components/shared/Loading';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { selectSelectedCompany } from 'companies/companies.slice';
import EnrollmentPlanSelection from './plan-selection/EnrollmentPlanSelection';
import PlanEnrollmentHsa from 'plan-enrollment/plan-enrollment-hsa/PlanEnrollmentHsa';
import PlanEnrollmentSummary from 'plan-enrollment/plan-enrollment-summary/PlanEnrollmentSummary';
import PlanEnrollmentMra from './plan-enrollment-mra/PlanEnrollmentMra';
import PlanEnrollmentCustom from './plan-enrollment-custom/PlanEnrollmentCustom';
import { useHistoryBlock } from 'shared/custom-hooks/useHistoryBlock';
import wizardSteps, { PEStepSlug } from './plan-enrollment-steps';
import { saveEnrollment } from 'shared/api/enrollmentApi';
import store from 'store';

const HSA_PLAN_CODE = 'S';
const CUSTOM_PLAN_CODE = '0';

interface PlanEnrollmentWizardProps extends PlanEnrollmentFormProps {
  onSave: () => void;
}

function PlanEnrollmentWizardStep({
  onNextStep,
  onCancel,
  onSave }: PlanEnrollmentWizardProps,
): React.ReactElement {
  const { stepSlug } = useParams<{stepSlug: PEStepSlug;}>();
  const plan = useSelector(selectPlanEnrollmentPlan);
  const planDetails = useSelector(selectPlanEnrollmentDetails);

  function content() {
    switch (stepSlug) {
    case 'plan-type':
      return (
        <EnrollmentPlanSelection
          plan={plan}
          onCancel={onCancel}
          onNextStep={onNextStep}
        />
      );
    case 'plan-details':
      if (planDetails?.type === 'HSA') {
        return (
          <PlanEnrollmentHsa
            yearBeg={plan?.yearbeg}
            yearEnd={plan?.yearend}
            partStart={planDetails.partStart}
            planName={plan?.planYearName}
            onNextStep={onNextStep}
            onCancel={onCancel}
          />
        );
      } else if (planDetails?.type === 'MRA' && plan) {
        return (
          <PlanEnrollmentMra
            {...planDetails}
            showLFSA={plan.hasFloresHSA || plan.hasOtherHSA}
            plan={plan}
            onNextStep={onNextStep}
            onCancel={onCancel}
          />
        );
      } else if (planDetails?.type === 'CUSTOM' && plan) {
        return (
          <PlanEnrollmentCustom
            isBalance={plan.isBalance}
            yearBeg={plan.yearbeg}
            yearEnd={plan.yearend}
            partStart={planDetails.partStart}
            partEnd={planDetails.partEnd}
            currentContributions={planDetails.currentContributions}
            additionalContributions={planDetails.additionalContributions}
            selectedDependents={planDetails.coveredDependents}
            onNextStep={onNextStep}
            onCancel={onCancel}
          />
        );
      }

      return <></>;
    case 'summary':
      return (
        <PlanEnrollmentSummary
          // Typecast props as any because props may not be present on the
          // discriminated union type. The component receiving these props
          // has logic to determine if the required properties are present
          {...plan as any}
          {...planDetails as any}
          onCancel={onCancel}
          onSubmit={onSave}
        />
      );
    }
  }

  return content();
}

function PlanEnrollmentWizard(): React.ReactElement {
  const [ showModal, setShowModal ] = useState(false);
  const [ saving, setSaving ] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();

  const [ unmountCallback ] = useState(() => compose(dispatch, resetPlanEnrollment));
  const [ blockRegex ] = useState(/^\/plan-enrollment/);
  const navUnblock = useHistoryBlock(blockRegex, unmountCallback);

  const participant = useSelector(selectParticipant);
  const savePayload = useSelector(selectPlanEnrollmentPayload);
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const planDetails = useSelector(selectPlanEnrollmentDetails);

  const {
    stepSlug: activeStepSlug,
    employeeNumber: empno,
  } = useParams<{ stepSlug: PEStepSlug; employeeNumber: string; }>();

  const employeeNumber = empno || participant?.empno;

  function handleSave() {
    setSaving(true);
    saveEnrollment(compid, savePayload)
      .then(() => {
        setSaving(false);
        goToParticipantOverview();
      })
      .catch((err) => {
        setSaving(false);
        compose(dispatch, addHttpErrorNotification)(err);
      });
  }

  function handleStepClick(clickedStep: WizardStep) {
    return history.push(`/plan-enrollment/${employeeNumber}/${clickedStep.slug}`);
  }

  function onNextStep(data: OpenPlan | PlanDetails) {
    if (activeStepSlug === 'plan-type') {
      const plan = data as OpenPlan;
      dispatch(setPlan(plan));

      // set default details details before going to the next step.
      if (plan.plccode === HSA_PLAN_CODE) {
        dispatch(setPlanDetails({ type: 'HSA', partStart: '' }));
      } else if (plan.plccode === CUSTOM_PLAN_CODE) {
        dispatch(setPlanDetails({
          type: 'CUSTOM',
          partStart: '',
          partEnd: '',
          currentContributions: 0,
          additionalContributions: 0,
          coveredDependents: [],
        }));
      } else {
        const pd = { lfsa: false };
        dispatch(setPlanDetails({
          type: 'MRA',
          partStart: '',
          partEnd: '',
          partAnn: 0,
          firstPayDateOfChange: '',
          payPeriodDeduction: 0,
          lfsa: ((planDetails || pd) as MRADetails).lfsa,
        }));
      }
    } else if (activeStepSlug === 'plan-details') {
      dispatch(setPlanDetails(data as PlanDetails));
    }

    const activeStep = wizardSteps.get(activeStepSlug);
    history.push(`/plan-enrollment/${employeeNumber}/${activeStep?.next}`);
  }

  function onWizardCancel() {
    if (activeStepSlug !== 'plan-type') {
      setShowModal(true);
    } else {
      goToParticipantOverview();
    }
  }

  function goToParticipantOverview() {
    if (showModal) {
      setShowModal(false);
    }

    if (navUnblock) {
      navUnblock();
    }

    dispatch(resetPlanEnrollment());
    history.push(`/participant-overview/${employeeNumber}/plan-enrollment`);
  }

  return (
    <>
      <PageContentPaper className="mt-4 mb-8">
        <div className="mb-8">
          <WizardStepper
            steps={wizardSteps}
            allowClick
            onChange={handleStepClick}
          />
        </div>
        <div className="px-8">
          <PlanEnrollmentWizardStep
            onNextStep={onNextStep}
            onCancel={onWizardCancel}
            onSave={handleSave}
          />
        </div>
      </PageContentPaper>
      <CancelModal
        isOpen={showModal}
        onCancel={goToParticipantOverview}
        onClose={compose(setShowModal, F)}
      />
      {saving ? <Loading text="Saving Plan Enrollment" /> : <></>}
    </>
  );
}

export default PlanEnrollmentWizard;
