import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/cobra-qe/cobra-event-types', (schema) => {
    const { models } = schema.cobraQeTypes.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/cobra-qe/cobra-plans', (schema) => {
    const { models } = schema.cobraPlans.all();
    const data = models.map((m) => Object.values(m.attrs));
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/qualifying-events/:type/print/:unqid', () => {
    return new Response(201, { 'content-length': 0 }, {});
  });

  server.post('/companies/:companyId/cobra-qe', () => {
    return new Response(201, { 'content-length': 0 }, {});
  })

  server.get('/companies/:companyId/cobra-qe/:employeeNumber/:unqid', (schema, request) => {
    function mapToArray(planObject) {
      return Object.keys(planObject.attrs).map((key) => planObject.attrs[key]);
    }

    const { models: dependents } = schema.dependents.where({ empno: request.params.employeeNumber });
    const { models: plans } = schema.cobraPlans.all();
    const plan = plans[0].attrs[3];
    const { attrs: participant } = schema.participants.findBy({ empno: request.params.employeeNumber });
    participant.dependents = dependents;

    const data = {
      c_qualid: 1,
      participant,
      eventDate: '2020-04-01',
      eventType: 1,
      coverageBegins: '2020-04-01',
      benefits: [{
        ...plan,
        coveredDependents: [ dependents[0] ],
      }],
      details: {
        statusCode: 2,
        statusMessage: 'Accepted Coverage',
        letterSent:  '',
        postmark: '',
        coverageTermSent: '2014-01-07',
        letterDoc: 27148381,
        responseDoc: 27148381,
        coverageEnds: '2020-12-31',
      },
      availablePlans: plans.map(mapToArray)
    };
    const length = JSON.stringify(data).length;

    return new Response(200, { 'content-length': length }, { data });
  })
}

export default routing;
