import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContentPaper from 'components/layout/PageContentPaper';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';

import Loading from 'components/shared/Loading';
import NoticesTable from 'components/shared/notice-list/NoticesTable';
import { loadPendingQeNotices, selectPendingQeNotices } from 'cobra-pending-qe/cobra-pending-qe-notices.slice';
import store from 'store';

function CobraPendingQENotices(): React.ReactElement {
  const notices = useSelector(selectPendingQeNotices);
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadPendingQeNotices());
  }, [ dispatch ]);

  function handleBackClick() {
    history.push('/dashboard');
  }

  return (
    <div className="flex flex-col m-4 w-full">
      <h5 className="mb-4">
        <IconButton onClick={handleBackClick} size="large"><ArrowBackIcon /></IconButton>
        Pending COBRA Qualifying Event Notices
      </h5>
      <PageContentPaper className="flex flex-col">
        {
          notices === null
            ? <Loading text="Loading notices…" />
            : <NoticesTable notices={notices} />
        }
      </PageContentPaper>
    </div>
  );
}

export default CobraPendingQENotices;
