import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';
import Drawer from '@mui/material/Drawer';
import { always, compose, equals,ifElse, T, F, isNil, when, and, anyPass } from 'ramda';
import dayjs from 'dayjs';
import {
  cancelModalOpened,
  selectCobraPlan,
  selectFetchCobraChecklistPlansStatus,
  selectFetchPlansStatus,
  selectUniqueCobraReEnrollmentChecklistPlans,
  selectDiscontinuedCobraChecklistPlans,
  setNewCobraPlanOnDeck,
  setExistingCobraPlanOnDeck,
  planRateWizardStepIndexChanged,
  setCobraPlanOnDeckInit,
  selectPlanRatesContinueIsDisabled,
  selectCobraPlanTypesStatus,
  selectCobraInsurersStatus,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { noop } from 'shared/utils';
import store from 'store';
import {
  fetchCobraChecklistPlans,
  fetchCobraInsurers,
  fetchCobraPlanTypes,
} from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import AddRateWizardContainer from './AddRateWizardContainer';
import { CobraChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import PlanRatesCard from './PlanRatesCard';
import { ApiFetchStatus } from 'shared/enums/api-fetch-status.enum';

type DrawerStatus = 'open' | 'closed';

function PlanRates() {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const cobraPlanYear = useSelector(selectCobraPlan);
  const uniqueCobraPlans = useSelector(selectUniqueCobraReEnrollmentChecklistPlans);
  const discontinuedCobraPlans = useSelector(selectDiscontinuedCobraChecklistPlans);
  const [ drawerStatus, setDrawerStatus ] = useState<DrawerStatus>('closed');
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const fetchCobraPlansStatus = useSelector(selectFetchCobraChecklistPlansStatus);
  const fetchCobraPlanTypesStatus = useSelector(selectCobraPlanTypesStatus);
  const fetchCobraInsurersStatus = useSelector(selectCobraInsurersStatus);
  const fetchPlansStatus = useSelector(selectFetchPlansStatus);
  const loading = anyPass([
    compose(equals(ApiFetchStatus.PENDING), always(fetchCobraInsurersStatus)),
    compose(equals(ApiFetchStatus.PENDING), always(fetchCobraPlanTypesStatus)),
    compose(equals(ApiFetchStatus.PENDING), always(fetchCobraPlansStatus)),
    compose(equals(ApiFetchStatus.PENDING), always(fetchPlansStatus)),
  ])();
  const isDisabled = useSelector(selectPlanRatesContinueIsDisabled);

  const openHandler = compose(
    setDrawerStatus,
    always<DrawerStatus>('open'),
    dispatch,
    setCobraPlanOnDeckInit,
    T,
  );

  const handleClickUpdateRates = (plan: CobraChecklistPlan) => compose(
    openHandler,
    compose(dispatch, planRateWizardStepIndexChanged, always(2)),
    ifElse(
      isNil,
      compose(dispatch, setNewCobraPlanOnDeck, always(plan.planInProgressId)),
      compose(dispatch, setExistingCobraPlanOnDeck, always(plan.planId)),
    ),
    always(plan.planId),
  );

  const resetCobraOnDeckPlan = compose(
    compose(dispatch, planRateWizardStepIndexChanged, always(0)),
    compose(dispatch, setExistingCobraPlanOnDeck, always(-1)),
    compose(dispatch, setCobraPlanOnDeckInit, F),
  );

  const handleClosePlanOnDeck = compose(
    resetCobraOnDeckPlan,
    setDrawerStatus,
    always<DrawerStatus>('closed'),
  );

  const getUniqueId = (plan: CobraChecklistPlan) => plan.planId || plan.planInProgressId;

  const planYear = `${dayjs(cobraPlanYear.planYearStart).format('MM/DD/YYYY')} 
    - ${dayjs(cobraPlanYear.planYearEnd).format('MM/DD/YYYY')}`;

  useEffect(() => {
    when(
      always(and(!!cobraPlanYear.companyId, !!cobraPlanYear.planYearId)),
      compose(
        when(
          compose(equals(ApiFetchStatus.UNINITIALIZED), always(fetchCobraInsurersStatus)),
          compose(dispatch, fetchCobraInsurers, always(cobraPlanYear.companyId)),
        ),
        when(
          compose(equals(ApiFetchStatus.UNINITIALIZED), always(fetchCobraPlanTypesStatus)),
          compose(dispatch, fetchCobraPlanTypes, always(cobraPlanYear.companyId)),
        ),
        when(
          compose(equals(ApiFetchStatus.UNINITIALIZED), always(fetchCobraPlansStatus)),
          compose(dispatch, fetchCobraChecklistPlans, always(cobraPlanYear)),
        ),
      ),
    )(cobraPlanYear);
  }, [
    dispatch,
    fetchCobraInsurersStatus,
    fetchCobraPlanTypesStatus,
    fetchCobraPlansStatus,
    cobraPlanYear,
  ]);

  return (
    <>
      <FormControl component="fieldset" className="my-8 w-full">
        <p className="font-bold">
          Provide all Medical, Dental, Vision, Medical FSA, HRA, and any Group Health plans eligible for COBRA.
        </p>
        {
          !cobraPlanYear.checklistComplete &&
          <Button
            data-testid="add-new-plan"
            variant="contained"
            color="primary"
            className="mt-4"
            style={ { width: 'max-content' } }
            onClick={openHandler}
          >
          + Add New Plan
          </Button>
        }
        {
          ifElse(
            equals(true),
            always(
              <>
                <Skeleton variant="rectangular" height={250} className="w-full mt-4" />
                <Skeleton variant="rectangular" height={250} className="w-full mt-4" />
              </>,
            ),
            always(uniqueCobraPlans.map(plan => {
              const uniqueId = getUniqueId(plan) as number;

              return (
                <PlanRatesCard
                  key={uniqueId}
                  plan={plan}
                  uniqueId={uniqueId}
                  planYear={planYear}
                  handleClickUpdateRates={handleClickUpdateRates(plan)}
                  resetCobraOnDeckPlan={resetCobraOnDeckPlan}
                />
              );
            })),
          )(loading)
        }
      </FormControl>
      <div className="flex justify-end">
        <Button
          className="mr-4"
          onClick={compose(dispatch, cancelModalOpened, noop)}
        >Cancel
        </Button>
        <Button
          data-testid="eligibility-continue"
          variant="contained"
          color="primary"
          disabled={isDisabled}
          onClick={compose(
            history.push,
            always(`/reenrollment-checklist/cobra-plan/${planYearId}/summary`),
          )}
        >Continue
        </Button>
      </div>
      {
        discontinuedCobraPlans.length > 0 &&
          <div className="mt-8">
            <h5>Discontinued Plans</h5>
            {discontinuedCobraPlans.map(plan => {
              const uniqueId = getUniqueId(plan) as number;

              return (
                <PlanRatesCard
                  key={uniqueId}
                  plan={plan}
                  uniqueId={uniqueId}
                  planYear={planYear}
                  handleClickUpdateRates={handleClickUpdateRates(plan)}
                  resetCobraOnDeckPlan={resetCobraOnDeckPlan}
                />
              );
            })}
          </div>
      }
      <Drawer
        anchor="right"
        open={drawerStatus === 'open'}
        onClose={handleClosePlanOnDeck}
      >
        <div style={ { width: 500 } }>
          <div className="flex flex-row-reverse p-4">
            <CloseIcon className="cursor-pointer" onClick={handleClosePlanOnDeck} />
          </div>
          <AddRateWizardContainer
            onClose={handleClosePlanOnDeck}
          />
        </div>
      </Drawer>
    </>
  );
}

export default PlanRates;
