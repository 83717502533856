import { logToServer } from 'shared/api/companiesApi';

function serverLogger ({ getState }: any) {
  return (next: any) => (action: any) => {
    const compid = getState().companies?.selectedCompany?.compid;

    if (compid) {
      logToServer(compid, {
        scope: 'redux',
        level: 'info',
        message: JSON.stringify(action),
      });
    }

    if (action) {
      return next(action);
    }
  };
}

export default serverLogger;
