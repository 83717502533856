import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/plan-years', (schema) => {
    const { models } = schema.planYears.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/plan-years/:planYearId', (schema, request) => {
    const { models: data } = schema.planYears.where({ pyrid: parseInt(request.params.planYearId) }) ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/plan-years/:planYearId/services', (schema, request) => {
    const { models: data } = schema.services.where({ pyrid: parseInt(request.params.planYearId) }) ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });
}

export default routing;
