import request from 'shared/api-request';
import {
  EmployeeSearchResults,
  LeaveRecordsAbsenceSummary,
  LeaveRecordCaseInfo,
  LeaveRecordCaseSummary,
  LeaveRecordsEmployeeInfo,
} from 'leave-records/leaveRecord.types';

export function getEmployeeById(
  { companyId, eeId }: {companyId: number; eeId: string;}) {
  if (companyId === 0) {
    return Promise.reject(new Error('Cannot get employee details for invalid company.'));
  }
  const uri = `api/companies/${companyId}/fmla/employee/detail/${eeId}`;

  return request<LeaveRecordsEmployeeInfo>(uri);
}

export function getCasesSummaryByEmployeeId(
  { companyId, eeId }: {companyId: number; eeId: string;}) {
  if (companyId === 0) {
    return Promise.reject(new Error('Cannot get leave cases for invalid company.'));
  }

  const uri = `api/companies/${companyId}/fmla/leave/cases/employee/${eeId}`;

  return request<LeaveRecordCaseSummary[]>(uri);
}

export function getLeaveCaseByCaseId(
  { companyId, caseId }: {companyId: number; caseId: string;}) {
  if (companyId === 0) {
    return Promise.reject(new Error('Cannot get leave case for invalid company.'));
  }

  const uri = `api/companies/${companyId}/fmla/leave/case/${caseId}`;

  return request<LeaveRecordCaseInfo>(uri);
}

export function getAbsencesByEmployeeId(
  { companyId, eeId }: {companyId: number; eeId: string;}) {
  if (companyId === 0) {
    return Promise.reject(new Error('Cannot get absences for invalid company.'));
  }

  const uri = `api/companies/${companyId}/fmla/absence/employee/${eeId}`;

  return request<LeaveRecordsAbsenceSummary[]>(uri);
}

export function getEmployeeSearchResultsByCriteria(
  { companyId, criteria }: {companyId: number; criteria: string;}) {
  if (companyId === 0) {
    return Promise.reject(new Error('Cannot get search results for invalid company.'));
  }

  const uri = `api/companies/${companyId}/fmla/employee/search`;

  return request<EmployeeSearchResults[]>(uri, {
    queryParams: {
      criteria,
      rows: '10',
      offset: '0',
    },
    method: 'GET',
  });
}
