import React, { ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';

import { MonthAndYear } from 'shared/types/file-report-history.types';

export interface HistoryMonthSelectProp {
  monthYears: MonthAndYear[];
  value: string;
  onMonthYearChanged: (monthYear: string) => void;
}

function formatOption(option?: MonthAndYear) {
  if (!option) {
    return '';
  }

  return dayjs(`${option.year}-${option.month}`, 'YYYY-M').format('MMMM YYYY');
}

function HistoryMonthSelect({
  monthYears,
  onMonthYearChanged,
  value = '',
}: HistoryMonthSelectProp): React.ReactElement {
  function handleChange({ target: { value: val } }: ChangeEvent<{ name?: string; value: unknown; }>) {
    onMonthYearChanged(val as string);
  }

  return (
    <FormControl variant="standard" className="w-full">
      <InputLabel shrink id="history-month-label">Date</InputLabel>
      <Select
        label="Date"
        labelId="history-month-label"
        onChange={handleChange as any}
        value={value.length > 0 ? value : formatOption(monthYears[0])}
        native
      >
        {
          monthYears.length > 0
            ? monthYears.map((opt) => (
              <option value={`${opt.year}-${opt.month}`} key={`${opt.year}-${opt.month}`}>
                {formatOption(opt)}
              </option>
            ))
            : <option value=""> </option>
        }
      </Select>
    </FormControl>
  );
}

export default HistoryMonthSelect;
