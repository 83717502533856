import React from 'react';

import CommonSelect, { CommonSelectClasses } from 'components/shared/form/CommonSelect';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { SelectOption } from 'shared/types/form.types';

interface LastDeductionSelectProps {
  payDates: SelectOption[] | null;
  value: string;
  error?: boolean;
  classes?: CommonSelectClasses;
  disabled?: boolean;
  onDateChange: (value: string) => void;
}

function LastDeductionSelect({
  payDates,
  value,
  error,
  classes,
  disabled = false,
  onDateChange,
}: LastDeductionSelectProps) {
  const LastDeduction = withLoopIcon(
    CommonSelect,
    'Loading deduction dates...',
    payDates === null,
    'loading-last-deduction-dates',
  );

  return (
    <LastDeduction
      options={payDates!}
      label="Last Deduction"
      name="lastDeduction"
      value={value}
      error={error}
      classes={classes}
      onSelectChange={onDateChange}
    />
  );
}

export default LastDeductionSelect;
