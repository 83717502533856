import { deepMap } from './deep-map';

/**
 * Deeply trim whitespace in strings of an object
 */
export function deepTrim<T extends {}> (obj: T): T {
  return deepMap((val: any) => {
    if (typeof val === 'string') {
      return val.trim();
    }

    return val;
  }, obj);
}
