import React, { KeyboardEvent, useRef, FocusEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose, T, F } from 'ramda';

import ParticipantSearchResults from './ParticipantSearchResults';
import SearchInput from 'components/shared/SearchInput';
import withDebounce from 'components/shared/HOC/WithDebounce';
import { Participant } from './participant-list.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { setSelectedParticipant, searchParticipants, setSearchText, resetSearchResults } from './participant.slice';
import store from 'store';

const DebouncedSearch = withDebounce(SearchInput, 250);

function ParticipantSearch(): React.ReactElement {
  const [ open, setOpen ] = useState(false);
  const openList = compose(setOpen, T, searchChange);
  const closeList = compose(setOpen, F);

  const listRef = useRef<HTMLUListElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const company = useSelector(selectSelectedCompany);
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();

  const selectParticipant = compose(dispatch, setSelectedParticipant);

  function searchChange(text: string) {
    dispatch(setSearchText(text));

    if (text.length) {
      dispatch(searchParticipants());
    }
  }

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    if (listRef.current &&
        event.relatedTarget &&
        !Array.from(listRef.current.children).includes(event.relatedTarget as HTMLElement)) {
      closeList();
    }
  }

  function handleSelect(participant: Participant) {
    closeList();
    selectParticipant(participant);
    history.push(`/participant-overview/${participant.empno}/general-info`);
    setSearchText('');
    dispatch(resetSearchResults());
  }

  function handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    // arrow down into the list
    if (event.key === 'ArrowDown' && listRef.current && listRef.current.children.length > 0) {
      (listRef.current.children[0] as HTMLLIElement).focus();
    }

    // update search text and search immediately on Enter
    if (event.key === 'Enter') {
      searchChange((event.target as HTMLInputElement).value);
    }
  }

  function content(): React.ReactElement {
    if (!company) {
      return <></>;
    }

    let placeHolderText = `Search Participant by PID/${company.iddesc || 'SSN'}/Name`;

    return (
      <>
        <div hidden={company.isFmlaOnly} ref={anchorRef}>
          <DebouncedSearch
            ref={inputRef}
            placeholder={placeHolderText}
            size="small"
            label=""
            className="rounded-full text-sm"
            onDebouncedChange={searchChange}
            onFocus={openList}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
          />
        </div>
        <div>
          {
            open
              ? (
                <ParticipantSearchResults
                  ref={listRef}
                  open={open}
                  anchorRef={anchorRef}
                  onClose={closeList}
                  onParticipantSelected={handleSelect}
                />
              )
              : <></>
          }
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col w-1/2">
      {content()}
    </div>
  );
}

export default ParticipantSearch;
