import { okResponse } from './utils';
import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/users', (schema) => {
    const { models } = schema.summaryUsers.all();
    const data = models.map((m) => m.attrs);
    return okResponse(data)();
  });
  
  server.get('/companies/:companyId/users/get-user-permissions', (schema) => {
    return okResponse(localStorage.get('FloresAdminJWT'))();
  });
  
  server.get('/companies/:companyId/users/:conid', (schema) => {
    const { models } = schema.contactUsers.all();
    const data = models[0];
    return okResponse(data)();
  });

  server.get('/users/:conuserid/companies', (schema, request) => {
    const { models: data } = schema.companies.where({ conuserid: request.params.conuserid }) ?? [];
    return okResponse(data)();
  });

  server.get('/companies/:compid/users/:conuserid/get-contact-info', (schema) => {
    const { attrs: data } = schema.contactInfos.first() ?? [];
    return okResponse(data)();
  });

  server.put(`/companies/:id}/set-contact-info`, (schema) => {
    const { models: data } = schema.contactInfos.first() ?? [];
    return okResponse(data)();
  });

  server.post('/companies/:companyId/users', () => {
    return new Response(201, { 'content-length': 0 }, {});
  });

  server.get('/companies/:companyId/users/get-user-permissions', () => {
    const data = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDb251c2VySUQiOiIzNzA5IiwiQXV0aGVudGljYXRpb25TdGF0ZSI6IkZ1bGwiLCJVc2VyUGVybWlzc2lvbnMiOiJbe1wicHJtaWRcIjoxMTAsXCJsZXZlbFwiOjIsXCJkZXNjcmlwdGlvblwiOlwiVXNlcnNcIn0se1wicHJtaWRcIjoxMjAsXCJsZXZlbFwiOjIsXCJkZXNjcmlwdGlvblwiOlwiRGlyZWN0IERlYml0c1wifV0iLCJleHAiOjE2MzM0OTc0MjMsImlzcyI6IkFkbWluQXBpLVFBIiwiYXVkIjoiQWRtaW5TaXRlLVFBIn0.OYAlE1eVSfB3snLhDjVgmvEEnoAf4L5amKvb8NHFab8';
    return new Response(201, { 'content-length': data.length }, { data });
  });
}

export default routing;
