import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { T, always, compose, cond, equals } from 'ramda';

import WizardStepper, { WizardStep } from 'components/shared/WizardStepper';
import { PlanEnrollmentFormProps, OpenPlan, PlanDetails } from '../plan-enrollment/plan-enrollment.types';
import PageContentPaper from 'components/layout/PageContentPaper';
import { selectParticipant } from 'participant-search/participant.slice';
import {
  resetPlanEnrollment,
  selectPlanEnrollmentDetails,
  selectPlanEnrollmentPayload,
  selectPlanEnrollmentPlan,
  setPlan,
  setPlanDetails,
} from '../plan-enrollment/plan-enrollment.slice';
import CancelModal from 'components/shared/CancelModal';
import Loading from 'components/shared/Loading';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { selectSelectedCompany } from 'companies/companies.slice';
import PlanEnrollmentHsa from 'plan-enrollment/plan-enrollment-hsa/PlanEnrollmentHsa';
import PlanEnrollmentSummary from 'plan-enrollment/plan-enrollment-summary/PlanEnrollmentSummary';
import PlanEnrollmentMra from '../plan-enrollment/plan-enrollment-mra/PlanEnrollmentMra';
import { useHistoryBlock } from 'shared/custom-hooks/useHistoryBlock';
import NotFound from 'components/pages/NotFound';
import { useEnrollmentDetails } from './useEnrollmentDetails';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import PlanEnrollmentCustom from 'plan-enrollment/plan-enrollment-custom/PlanEnrollmentCustom';
import { CUSTOM_PLCCODE } from 'components/shared/GlobalConstants';
import wizardSteps, { UPEStepSlug } from './update-plan-enrollment-steps';
import { updateEnrollment } from 'shared/api/enrollmentApi';
import store from 'store';
import { fetchDependentsForParticipant } from 'store/slices/dependents/dependents.thunk';

const HSA_PLCCODE = 'S';

interface UpdateEnrollmentRouteParams {
  stepSlug: UPEStepSlug;
  employeeNumber: string;
  planYearId: string;
  planCode: string;
  customPlanId: string;
}
interface UpdatePlanEnrollmentWizardProps extends PlanEnrollmentFormProps {
  onSave: () => void;
}

function UpdatePlanEnrollmentWizardStep({
  onNextStep,
  onCancel,
  onSave }: UpdatePlanEnrollmentWizardProps,
): React.ReactElement {
  const { stepSlug } = useParams<UpdateEnrollmentRouteParams>();
  const plan = useSelector(selectPlanEnrollmentPlan);
  const planDetails = useSelector(selectPlanEnrollmentDetails);

  function content() {
    switch (stepSlug) {
    case 'plan-details':
      if (planDetails?.type === 'HSA') {
        return (
          <PlanEnrollmentHsa
            yearBeg={plan?.yearbeg}
            yearEnd={plan?.yearend}
            partStart={planDetails.partStart}
            partEnd={planDetails.partEnd}
            onNextStep={onNextStep}
            onCancel={onCancel}
            isEdit
            planName={plan?.planYearName}
          />
        );
      } else if (planDetails?.type === 'MRA' && plan) {
        return (
          <PlanEnrollmentMra
            {...planDetails}
            showLFSA={planDetails.lfsa}
            plan={plan}
            onNextStep={onNextStep}
            onCancel={onCancel}
            isEdit
          />
        );
      } else if (planDetails?.type === 'CUSTOM' && plan) {
        return (
          <PlanEnrollmentCustom
            isBalance={plan.isBalance}
            yearBeg={plan.yearbeg}
            yearEnd={plan.yearend}
            partStart={planDetails.partStart}
            partEnd={planDetails.partEnd}
            currentContributions={planDetails.currentContributions}
            additionalContributions={planDetails.additionalContributions}
            selectedDependents={planDetails.coveredDependents}
            onNextStep={onNextStep}
            onCancel={onCancel}
            isEdit
          />
        );
      }

      return <></>;
    case 'summary':
      return (
        <PlanEnrollmentSummary
          // Typecast props as any because props may not be present on the
          // discriminated union type. The component receiving these props
          // has logic to determine if the required properties are present
          {...plan as any}
          {...planDetails as any}
          onCancel={onCancel}
          onSubmit={onSave}
          isEdit
        />
      );
    }

    return <NotFound />;
  }

  return content();
}

function UpdatePlanEnrollmentWizard(): React.ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();

  const { current: unmountCallback } = useRef(compose(dispatch, resetPlanEnrollment));
  const { current: blockRegex } = useRef(/^\/update-plan-enrollment/);
  const navUnblock = useHistoryBlock(blockRegex, unmountCallback);

  const participant = useSelector(selectParticipant);
  const savePayload = useSelector(selectPlanEnrollmentPayload);
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const {
    stepSlug: activeStepSlug = 'plan-details',
    employeeNumber: empno,
    planYearId: pyrid,
    planCode,
    customPlanId,
  } = useParams<UpdateEnrollmentRouteParams>();
  const planYearId = parseInt(pyrid || '');

  const [ showModal, setShowModal ] = useState(false);
  const [ saving, setSaving ] = useState(false);
  const { plan, planDetails } = useEnrollmentDetails() ?? { plan: undefined, planDetails: undefined };

  const employeeNumber = empno || participant?.empno;

  function handleSave() {
    setSaving(true);
    updateEnrollment(compid, savePayload)
      .then(() => {
        setSaving(false);
        goToParticipantOverview();
      })
      .catch((err) => {
        setSaving(false);
        compose(dispatch, addHttpErrorNotification)(err);
      });
  }

  useEffect(() => {
    if (!!plan && !!planDetails) {
      dispatch(setPlan(plan));
      dispatch(setPlanDetails({
        ...planDetails,
        type: cond<string, string>([
          [ equals(HSA_PLCCODE), always('HSA') ],
          [ equals(CUSTOM_PLCCODE), always('CUSTOM') ],
          [ T, always('MRA') ],
        ])(plan.plccode),
      } as PlanDetails));
    }
    dispatch(fetchDependentsForParticipant());
  }, [ plan, planDetails, dispatch ]);

  function handleStepClick(s: WizardStep) {
    if (planCode === CUSTOM_PLCCODE) {
      history.push(`/update-plan-enrollment/${employeeNumber}/${planYearId}/${planCode}/${customPlanId}/${s.slug}`);
    } else {
      history.push(`/update-plan-enrollment/${employeeNumber}/${planYearId}/${planCode}/${s.slug}`);
    }
  }

  function onNextStep(data: OpenPlan | PlanDetails) {
    if (activeStepSlug === 'plan-details') {
      dispatch(setPlanDetails(data as PlanDetails));
    }

    const step = wizardSteps.get(activeStepSlug);
    history.push(`/update-plan-enrollment/${employeeNumber}/${planYearId}/${planCode}/${customPlanId}/${step?.next}`);
  }

  function onWizardCancel() {
    if (activeStepSlug !== 'plan-details') {
      setShowModal(true);
    } else {
      goToParticipantOverview();
    }
  }

  function closeModal() {
    setShowModal(false);
  }

  function goToParticipantOverview() {
    if (showModal) {
      setShowModal(false);
    }

    if (navUnblock) {
      navUnblock();
    }

    dispatch(resetPlanEnrollment());
    history.push(`/participant-overview/${employeeNumber}/plan-enrollment`);
  }

  const UpdateStep = withLoopIcon(
    UpdatePlanEnrollmentWizardStep,
    'Loading enrollment details...',
    !plan || !planDetails,
    'update-enrollment-loading-details',
  );

  return (
    <>
      <PageContentPaper className="mt-4 mb-8">
        <div className="mb-8">
          <WizardStepper
            steps={wizardSteps}
            allowClick
            onChange={handleStepClick}
          />
        </div>
        <div className="px-8">
          <UpdateStep
            onNextStep={onNextStep}
            onCancel={onWizardCancel}
            onSave={handleSave}
          />
        </div>
      </PageContentPaper>
      <CancelModal
        isOpen={showModal}
        onCancel={goToParticipantOverview}
        onClose={closeModal}
      />
      {saving ? <Loading text="Saving Plan Enrollment" /> : <></>}
    </>
  );
}

export default UpdatePlanEnrollmentWizard;
