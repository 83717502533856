import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { compose } from 'ramda';

import { Benefit, Dependent } from 'shared/types/non-plan-year.types';
import { QeBenefits, CobraQeFormProps } from '../cobra-qe.types';
import { conformToForm, conformToSlice, hashCoveredDependents } from './benefit-state-helpers';
import BenefitsForm from './BenefitsForm';
import { validationSchema } from './cobra-benefits.validators';
import Button from '@mui/material/Button';

interface BenefitsFormContainerProps extends CobraQeFormProps {
  availablePlans: QeBenefits[];
  dependents: Dependent[];
  selectedBenefits: Benefit[];
}

function BenefitsFormContainer({
  availablePlans,
  dependents,
  selectedBenefits,
  onNextStep,
  onCancel,
}: BenefitsFormContainerProps) {
  const form = useFormik<{benefits: Benefit[];}>({
    initialValues: {
      benefits: conformToForm(selectedBenefits, availablePlans)
        .map(b => hashCoveredDependents(b, dependents)),
    },
    validationSchema,
    onSubmit: compose(validateLength, conformToSlice(dependents, availablePlans)),
  });

  function validateLength(state: QeBenefits) {
    if (state.benefits.length) {
      onNextStep(state);
    } else {
      window.alert('Please fill out at least one benefit');
    }
  }

  return (
    <form data-testid="benefits-form">
      <div className="flex flex-col space-y-4 mt-10">
        <FormikProvider value={form}>
          <BenefitsForm dependents={dependents} availablePlans={availablePlans} />
        </FormikProvider>
      </div>
      <div className="flex justify-end my-4">
        <Button
          color="primary"
          onClick={onCancel}
        >
            Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          data-testid="cobraQeNextStep"
          className="ml-4"
          type="button"
          onClick={form.submitForm}
        >
            Next Step
        </Button>
      </div>
      <div className="flex flex-col space-y-4" />
    </form>
  );
}

export default BenefitsFormContainer;
