import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';

export interface DataPoint {
  label: string;
  value: number;
}

interface CenterLabelDonutChartProps {
  chartData: DataPoint[];
  centerItem?: number;
  formatter?: Intl.NumberFormat;
  radius?: number;
  innerRadius?: number;
  colorScale?: string[];
  fontSize?: number;
  width?: number;
  height?: number;
}

const defaultColors = [
  '#1976D2',
  '#37A52E',
  '#5198DD',
  '#4886C4',
  '#3F75AA',
  '#366391',
  '#2C5277',
  '#192F44',
  '#006CC6',
  '#0088F9',
];

function CenterLabelDonutChart({
  chartData,
  centerItem = -1,
  radius = 110,
  innerRadius = 90,
  colorScale = defaultColors,
  formatter,
  fontSize = 20,
  width,
  height,
}: CenterLabelDonutChartProps): React.ReactElement {
  function labelRadius(): number {
    return innerRadius - radius;
  }

  function labels({ index }: {index: number;}): string | string[] {
    if (centerItem < 0 || centerItem >= chartData.length) {
      return '';
    }

    if (index === 0) {
      let value = chartData[centerItem].value + '';

      if (formatter) {
        value = formatter.format(chartData[centerItem].value);
      }

      return [ chartData[centerItem].label, value ];
    }

    return '';
  }

  function centerHighlightFill(): string {
    if (centerItem < 0 || centerItem >= chartData.length) {
      return colorScale[0];
    }

    const index = centerItem % colorScale.length;

    return colorScale[index];
  }

  return (
    <VictoryPie
      data={chartData.map((d) => ({ y: d.value }))}
      labelRadius={labelRadius}
      radius={radius}
      innerRadius={innerRadius}
      labelPlacement="vertical"
      labelPosition="startAngle"
      labels={labels}
      labelComponent={
        <VictoryLabel
          renderInPortal
          style={[
            {
              fill: 'black',
              fontSize: fontSize,
              fontFamily: 'Roboto',
            },
            {
              fill: centerHighlightFill(),
              fontSize: fontSize * 1.5,
              fontFamily: 'Roboto',
              fontWeight: 500,
            },
          ]}
          backgroundStyle={[
            { fill: 'white', opacity: 1 },
          ]}
        />
      }
      colorScale={colorScale}
      padAngle={2}
      padding={0}
      width={width}
      height={height}
    />
  );
}

export default CenterLabelDonutChart;
