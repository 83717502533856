import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/EditOutlined';
import { always, equals, ifElse } from 'ramda';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { PlanRatesStatus } from 're-enrollment-checklist/re-enrollment-checklist.types';

interface Props {
  status: PlanRatesStatus;
  isNewPlan: boolean;
  checklistComplete: boolean;
  handleUpdateRates: () => void;
  handleChangeActiveStatus: () => void;
}

function RatesStatus({
  status,
  isNewPlan,
  checklistComplete,
  handleUpdateRates,
  handleChangeActiveStatus,
}: Props) {
  const discontinuedClasses = ifElse(
    equals('discontinued'),
    always(' opacity-40'),
    always(''),
  )(status);

  const deleteButtonText = ifElse(
    equals(true),
    always('Delete'),
    always('Discontinue'),
  )(isNewPlan);

  const planRatesStatus = ifElse(
    equals('discontinued'),
    always(<span className="opacity-40"><CancelOutlined fontSize="small" className="mr-1" />Discontinued</span>),
    ifElse(
      equals('reviewed'),
      always(<span className="text-green-600"><CheckCircleOutline fontSize="small" className="mr-1" />Reviewed</span>),
      always(
        <span className="text-red-400"><VisibilityOutlinedIcon fontSize="small" className="mr-1" />Needs Review</span>,
      ),
    ),
  )(status);

  const planActiveStatus = ifElse(
    equals('discontinued'),
    always(<><CheckCircleOutline fontSize="small" className="mr-1" />Reactivate</>),
    always(<><CancelOutlined fontSize="small" className="mr-1" />{deleteButtonText}</>),
  )(status);

  return (
    <ul className="rounded border text-sm w-[170px]">
      <li className={`flex items-center p-3 pb-0${discontinuedClasses}`}>Rates status</li>
      <li className="flex items-center p-3">{planRatesStatus}</li>
      {
        status !== 'discontinued' &&
          <li className="border-t">
            <Button
              className="text-sm justify-start p-3 normal-case w-full"
              onClick={handleUpdateRates}
            >
              <EditIcon fontSize="small" className="mr-1" />Update Rates
            </Button>
          </li>
      }
      <li className="border-t">
        <Button
          data-testid="delete-discontinue-button"
          className="text-sm justify-start p-3 normal-case w-full"
          onClick={handleChangeActiveStatus}
          disabled={checklistComplete}
        >
          {planActiveStatus}
        </Button>
      </li>
    </ul>
  );
}

export default RatesStatus;
