import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavItem.module.css';

export interface NavItemProps {
  path: string;
  name: string;
  icon?: React.ComponentType<React.PropsWithChildren<unknown>>;
}

function NavItem({ path, name, icon }: NavItemProps): React.ReactElement {
  function getIcon() {
    if (icon) {
      // note: It's necessary to do this renaming to get it to render.
      const Icon = icon;

      return <Icon />;
    }

    return <span />;
  }

  return (
    <NavLink
      to={path}
      activeClassName={styles.active}
      className={`${styles.navItem} p-2 rounded-sm h-10 flex flex-row items-center`}
    >
      {getIcon()}
      <p className="ml-1">{name}</p>
    </NavLink>
  );
}

export default NavItem;
