import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';

import styles from './DefaultLayout.module.css';
import Sidebar from './Sidebar';
import AppToolbar from './Header/AppToolbar';

function DefaultLayout({ children }: React.PropsWithChildren<Object>) {
  const [ open, setOpen ] = useState(false);

  function toggleDrawer() {
    setOpen(!open);
  }

  return (
    <div className="min-h-screen flex default-layout">
      <AppBar position="fixed" className={`${styles.header} bg-surface left-0 w-auto`} color="inherit">
        <AppToolbar onToggleDrawer={toggleDrawer} showSearch />
      </AppBar>
      <div className={`${styles.mainContainer} flex-grow`}>
        <Sidebar collapsed={open} onToggleDrawer={toggleDrawer} />
        <div className="bg-surface-dark h-full flex">
          {children}
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;
