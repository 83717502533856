import React, { ReactNode } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

interface MuiThemeProps {
  children?: ReactNode;
}

function MuiTheme({ children }: MuiThemeProps): React.ReactElement {
  const theme = responsiveFontSizes(createTheme({
    palette: {
      primary: {
        light: '#5AAAF7',
        main: '#007BC4',
        dark: '#005093',
      },
      secondary: {
        light: '#FFD149',
        main: '#FFA000',
        dark: '#FFA000',
      },
      text: {
        primary: 'rgba(0,0,0, 0.87)',
        secondary: 'rgba(0,0,0, 0.6)',
        disabled: 'rgba(0,0,0, 0.38)',
      },
    },
    typography: {
      fontSize: 16,
    },
    breakpoints: {
      keys: [ 'xs', 'sm', 'md', 'lg', 'xl' ],
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderColor: '#212121',
            color: '#666',
          },
          containedPrimary: {
            color: '#f3f3f3',
          },
          containedSecondary: { // pretty much only going to be used for the marketing slides
            backgroundColor: '#f3f3f3',
            color: '#3f3f3f',
          },
        },
      },
    },
  }));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MuiTheme;
