import { okResponse } from "./utils";

function routing(server) {
    server.get('/permissions', (schema) => {
        const { models } = schema.permissions.all();
        const data = models.map((m) => m.attrs);
        return okResponse(data)();
    });
}

export default routing;
