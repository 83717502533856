import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { always, compose } from 'ramda';
import { ReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import { ChecklistCard } from 're-enrollment-checklist/ChecklistCard';
import setLegacyChecklistCompletionProgress from 'shared/utils/set-legacy-checklist-completion-progress';

function QtePlanCard ({ plan }: { plan: ReEnrollmentChecklistPlan; }) {
  const icon = <DirectionsBusIcon style={ { fontSize: 32 } } />;
  const history = useHistory();
  const buttons = (
    <div className="justify-start" data-testid="qte-plan-buttons">
      <Button
        variant="outlined"
        className="mr-5"
        data-testid="qte-link"
        onClick={compose(
          history.push,
          always(`/reenrollment-checklist/qte/${plan.planYearId}`),
        )}
      >
        <div className="justify-start">
          <div>Commuter</div>
        </div>
      </Button>
    </div>
  );

  return (
    <ChecklistCard
      icon={icon}
      title="Commuter Account"
      planYearStart={plan.planYearStart}
      planYearEnd={plan.planYearEnd}
      buttons={buttons}
      progress={setLegacyChecklistCompletionProgress(plan.legacyChecklistComplete)}
    />
  );
}

export default QtePlanCard;

