import { ChangeEvent, useRef } from 'react';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Skeleton from '@mui/material/Skeleton';

import { useLocationOptions } from 'shared/custom-hooks/useLocationOptions';

export interface LocationSelectProps {
  onLocationChanged?: (Location: string) => void;
  value?: string;
  error?: boolean;
  className?: string;
  label?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  [otherProp: string]: any;
}

export interface LocationSelectFormProps extends LocationSelectProps {
  locations: LocationSelectOption[] | null;
}

export interface LocationSelectOption {
  loccode: string;
  location: string;
}

function displayErrorText(showError = false) {
  if (showError) {
    return (
      <FormHelperText
        disabled
        error
      >This field is required
      </FormHelperText>
    );
  } else {
    return (<></>);
  }
}

function LocationSelectForm({
  onLocationChanged,
  variant,
  className,
  value,
  label,
  error,
  locations,
  ...rest
}: LocationSelectFormProps) {
  const labelRef = useRef<HTMLLabelElement>(null);

  function handleChange(event: ChangeEvent<{ name?: string; value: unknown; }>) {
    if (onLocationChanged) {
      onLocationChanged(event.target.value as string);
    }
  }

  return (
    <FormControl variant={variant} className={`${className} w-full`}>
      <InputLabel ref={labelRef} id="location-select-label">{label}</InputLabel>
      <Select
        label={label}
        onChange={handleChange as any}
        value={value ?? ''}
        native
        error={error}
        data-testid="locationSelect"
        {...rest}
      >
        <option />
        {locations?.map((loc) => <option value={loc.loccode} key={loc.loccode}>{loc.location}</option>)}
      </Select>
      {displayErrorText(error)}
    </FormControl>
  );
}

function LocationSelect({
  onLocationChanged,
  value,
  error = false,
  label = 'Location',
  className = '',
  variant = 'outlined',
  ...rest
}: LocationSelectProps) {
  const locations = useLocationOptions();

  return locations !== null
    ? (
      <LocationSelectForm
        locations={locations}
        value={value}
        error={error}
        className={className}
        variant={variant}
        label={label}
        onLocationChanged={onLocationChanged}
        {...rest}
      />
    )
    : (
      <div className="w-full" data-testid="location-skeleton">
        <Skeleton variant="rectangular" width="100%" height={60} />
      </div>
    );
}

export default LocationSelect;
