import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/reporthistory/get-available-dates', (schema) => {
    const { models: data } = schema.reportHistoryMonthYears.all();
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/reporthistory/:year/:month/get-report-history-by-month-year', (schema, request) => {
    const { models: data } = schema.reportHistoryClientFiles.where({
      year: parseInt(request.params.year),
      month: parseInt(request.params.month) }) ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });
  
  server.get('/companies/:companyId/reporthistory/download/:fileid/:filename', (schema, request) => {
    const { models: data } = schema.reportHistoryClientFiles.where({
      fileid: request.params.fileid,
      filename: request.params.filename }) ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });
}

export default routing;