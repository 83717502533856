import request from 'shared/api-request';
import { CobraPlanType } from 're-enrollment-checklist/cobra-reenrollment-checklist/cobra-re-enrollment.types';

export function getCobraPlanTypes(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get COBRA plan types for invalid company.'));
  }

  return request<CobraPlanType[]>(`/api/companies/${companyId}/reenrollment-checklist/plan-types/cobra`)
    .then(data => data ?? []);
}
