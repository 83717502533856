import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose, equals, T, F, tap, set, replace, or, view, lensPath } from 'ramda';

import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import PayrollIdParticipantLookupInput, {
  setParticipantValues,
} from 'components/shared/PayrollIdParticipantLookupInput';
import ParticipantDetails from 'components/shared/ParticipantDetails';
import { Participant } from 'participant-search/participant-list.types';
import { validationSchema } from './add-participant.validators';
import { setSelectedParticipant } from 'participant-search/participant.slice';
import request from 'shared/api-request';
import { selectSelectedCompany } from 'companies/companies.slice';
import PayFreqSelect, { PayFreqSelectProps } from 'components/shared/PayFreqSelect';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import CancelModal from 'components/shared/CancelModal';
import Loading from 'components/shared/Loading';
import PageContentPaper from 'components/layout/PageContentPaper';
import { useHistoryBlock } from 'shared/custom-hooks/useHistoryBlock';
import { defaultParticipant } from 'shared/constants';
import { usePayFrequencies } from 'shared/custom-hooks/usePayFrequencies';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { deepTrim } from 'shared/utils/deep-trim';
import store from 'store';

function AddParticipant(): React.ReactElement {
  usePageTitle('Add New Participant');
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const payFreqs = usePayFrequencies();
  const [ blockRegex ] = useState(/^\/add-participant/);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };

  const [ searching, setSearching ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);
  const [ saving, setSaving ] = useState(false);
  const [ doneSaving ] = useState(() => compose(setSaving, F));
  const [ handleError ] = useState(() => compose(tap(doneSaving), dispatch, addHttpErrorNotification));

  const PayFrequencies = withLoopIcon<PayFreqSelectProps>(
    PayFreqSelect,
    'Loading Pay Frequencies...',
    payFreqs === null,
    'pay-frequencies-loading',
  );

  const form = useFormik<Partial<Participant>>({
    initialValues: defaultParticipant,
    validationSchema: validationSchema(payFreqs !== null && payFreqs.length > 0),
    onSubmit: (p) => {
      let pidLens = lensPath([ 'payrollid' ]);
      let body = deepTrim(
        set(
          pidLens,
          replace(/-+/g, '', or(view(pidLens, p), '')),
          p,
        ),
      );
      setSaving(true);
      const uri = `/api/companies/${compid}/participant${body.empno ? `/${body.empno}` : ''}`;
      request<Participant>(uri, { method: p.empno ? 'PUT' : 'POST', body })
        .then((participant) => {
          doneSaving();

          if (navUnblock) {
            navUnblock();
          }

          dispatch(setSelectedParticipant(participant));
          history.push(`/participant-overview/${participant?.empno}/dependents`);
        })
        .catch(handleError);
    },
  });
  const setParticipantDetails = setParticipantValues(form.setFieldValue);

  const handleSearchStarted = compose(setSearching, T);
  const navUnblock = useHistoryBlock(blockRegex, undefined, hasFormChanged());

  useEffect(() => {
    dispatch(setSelectedParticipant(null));
  }, [ dispatch ]);

  function onCancel() {
    if (hasFormChanged()) {
      setShowModal(T);
    } else {
      history.goBack();
    }
  }

  function handleParticipant(p: Participant) {
    setParticipantDetails(p);
    setSearching(false);
  }

  function handleCustomFieldChange(field: string) {
    return (value: string) => form.setFieldValue(field, value);
  }

  function hasFormChanged() {
    return !equals(form.values, defaultParticipant);
  }

  return (
    <>
      <PageContentPaper className="mt-4 mb-8">
        <form data-testid="add-participant-form" className="px-8">
          <div className="flex flex-col space-y-8">
            <p>
            To create an employee account, please provide the employee’s demographic information below. If an account
            was previously created for the employee the form will prepopulate with the data currently in our system.
            </p>
            <div className="flex flex-row pr-4">
              <PayrollIdParticipantLookupInput
                className="w-1/2"
                data-testid="participantLookup"
                onSearchStarted={handleSearchStarted}
                onParticipantFound={handleParticipant}
                name="payrollid"
                value={form.values.payrollid ?? ''}
                error={form.touched.payrollid && !!form.errors.payrollid}
                helperText={form.touched.payrollid && form.errors.payrollid}
                onTextChanged={handleCustomFieldChange('payrollid')}
              />
            </div>

            <ParticipantDetails form={form} />

            <div className="flex flex-row space-x-8">
              <PayFrequencies
                isRequired
                payFreqs={payFreqs}
                name="payfreq"
                label="Pay Frequency"
                value={form.values.payfreq ?? ''}
                error={form.touched.payfreq && !!form.errors.payfreq}
                onPayFreqChanged={handleCustomFieldChange('payfreq')}
                data-testid="payfreq"
              />

              <PayFrequencies
                isRequired={false}
                payFreqs={payFreqs}
                name="payfreq2"
                label="Pay Frequency 2 (Optional)"
                value={form.values.payfreq2 ?? ''}
                error={form.touched.payfreq2 && !!form.errors.payfreq2}
                onPayFreqChanged={handleCustomFieldChange('payfreq2')}
                data-testid="payfreq2"
              />
            </div>
          </div>
          <div className="flex justify-end my-4">
            <Button
              color="primary"
              data-testid="add-participant-cancel"
              onClick={onCancel}
            >
            Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={form.submitForm}
              data-testid="add-participant-save"
              className="ml-4"
              disabled={searching || (!!Object.values(form.touched).length && !!Object.values(form.errors).length)}
            >
            Save Participant
            </Button>
          </div>
        </form>
      </PageContentPaper>
      {saving ? <Loading text="Saving..." /> : <></>}
      <CancelModal
        isOpen={showModal}
        onCancel={compose(tap(history.goBack), setShowModal, F)}
        onClose={compose(setShowModal, F)}
        title="Are you sure you want to navigate away?"
      />
    </>
  );
}

export default AddParticipant;
