import * as yup from 'yup';
import { isNil } from 'ramda';
import dayjs from 'dayjs';

import { isWithinDateRange } from 'shared/utils';

const requiredText = 'This field is required';

export const validationSchema = yup
  .object({
    partStart: yup
      .date()
      .nullable()
      .typeError('Invalid Date Format')
      .required(requiredText),
    partEnd: yup
      .date()
      .typeError('Invalid Date Format')
      .when('partStart', (start: string, schema: any) => {
        return schema.test({
          test: (end: string) => !!start && (dayjs(start).isSame(dayjs(end)) || dayjs(start).isBefore(dayjs(end))),
          message: 'End date must be after start date',
        });
      })
      .nullable()
      .required(requiredText),
    currentContributions: yup.number().min(0),
    additionalContributions: yup.number().min(0),
    yearBeg: yup.string(),
    yearEnd: yup.string(),
  })
  .test(
    'plan-year-provided',
    'Error: No plan year can be determined.',
    ({ yearBeg, yearEnd }) => !(isNil(yearBeg) || isNil(yearEnd)),
  )
  // Skip the next validation if partStart
  // is nil because .required() will catch that
  .test(
    'dates-within-plan-year',
    'Start and end dates must be within the open plan year.',
    ({
      yearBeg, yearEnd, partStart, partEnd,
    }) => (isNil(partStart) || isNil(partEnd)) || (
      isWithinDateRange(yearBeg!, yearEnd!, partStart)() &&
      isWithinDateRange(yearBeg!, yearEnd!, partEnd)()
    ),
  );
