import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import {
  selectUsers,
  loadUsers,
  loadResponsibilities,
  loadPermissions,
  loadLocations,
  loadPayFrequencies,
} from 'manage-settings/manage-users/manage-users.slice';
import PageContentPaper from 'components/layout/PageContentPaper';
import UserCard from 'manage-settings/manage-users/UserCard';
import { compose, always } from 'ramda';
import LoopIcon from '@mui/icons-material/Loop';
import { useHistory } from 'react-router-dom';
import store from 'store';

function ManageUsers(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const users = useSelector(selectUsers);

  useEffect(() => {
    dispatch(loadResponsibilities());
    dispatch(loadPermissions());
    dispatch(loadLocations());
    dispatch(loadPayFrequencies());
    dispatch(loadUsers());
  }, [ dispatch ]);

  return (
    <div className="w-full">
      <Paper className="p-4 mb-4 w-full flex justify-between">
        <h6>Manage Users</h6>
        <Button
          color="primary"
          variant="contained"
          onClick={compose(history.push, always('/add-user/step-1'))}
        >
          + New User
        </Button>
      </Paper>
      <PageContentPaper>
        <Grid container spacing={3}>
          { users?.map(
            user => <Grid item key={user.conid} xs={4}><UserCard user={user} /></Grid>,
          ) || (
            <LoopIcon
              className="animate-spin"
              style={ { animationDirection: 'reverse' } }
            />
          ) }
        </Grid>
      </PageContentPaper>
    </div>
  );
}

export default ManageUsers;
