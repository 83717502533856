import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { fetchFeatureFlags } from './app-settings.thunks';
import { ApiStatus } from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { ApiFetchStatus } from 'shared/enums/api-fetch-status.enum';
import { ReEnrollmentChecklistFlags } from './app-settings.types';

export interface AppSettingsState {
  pageTitle: string;
  fetchFeatureFlagsStatus: ApiStatus;
  reEnrollmentChecklist?: ReEnrollmentChecklistFlags;
}

export const defaultChecklistFlagsState: ReEnrollmentChecklistFlags = {
  showDirectBillV2: false,
  showCustomPlanV2: false,
  showHsaPlanV2: false,
  showFsaPlanV2: false,
  showHraPlanV2: false,
  showQtePlanV2: false,
};

export const initialState: AppSettingsState = {
  pageTitle: '',
  fetchFeatureFlagsStatus: ApiFetchStatus.UNINITIALIZED as ApiStatus,
  reEnrollmentChecklist: defaultChecklistFlagsState,
};

const appSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setPageTitle(state, { payload }:PayloadAction<string>) {
      state.pageTitle = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatureFlags.pending, (state) => {
        state.fetchFeatureFlagsStatus = ApiFetchStatus.PENDING as ApiStatus;
      })
      .addCase(fetchFeatureFlags.rejected, (state) => {
        state.fetchFeatureFlagsStatus = ApiFetchStatus.REJECTED as ApiStatus;
      })
      .addCase(fetchFeatureFlags.fulfilled, (state, action) => {
        state.fetchFeatureFlagsStatus = ApiFetchStatus.FULFILLED as ApiStatus;
        state.reEnrollmentChecklist = action.payload?.reEnrollmentChecklist ?? defaultChecklistFlagsState;
      });
  },
});

export const {
  setPageTitle,
} = appSlice.actions;

export default appSlice.reducer;

// thunk

// selectors
export const selectPageTitle = (state: RootState): string => state.appSettings.pageTitle;

export const selectFetchFeatureFlagsStatus = (state: RootState): string => state.appSettings.fetchFeatureFlagsStatus;

export const selectReenrollmentFeatureFlags = (state: RootState) => state.appSettings.reEnrollmentChecklist;
