import { useEffect } from 'react';
import Dependents from 'components/shared/dependents/Dependents';
import { Dependent, QeDependents } from 'shared/types/non-plan-year.types';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCompany } from 'companies/companies.slice';
import { loadInitialDependents, selectInitialDependents } from 'enter-cobra-qe/cobra-qe.slice';
import store from 'store';

interface CobraQeDependentsProps {
  empno: string;
  initialAddedDependents: Dependent[];
  onNextStep: (data: QeDependents) => void;
  onCancel?: () => void;
}

function CobraQeDependents({
  onNextStep,
  onCancel,
  empno,
  initialAddedDependents = [],
}: CobraQeDependentsProps) {
  const dispatch = useDispatch<typeof store.dispatch>();
  // todo: refactor to useDependents
  const initialDependents = useSelector(selectInitialDependents);

  const company = useSelector(selectSelectedCompany);

  useEffect(() => {
    if (company) {
      dispatch(loadInitialDependents(company.compid, empno));
    }
  }, [ dispatch, company, empno ]);

  return (
    <div className="flex flex-col space-y-4">
      <p>
        Dependents who are losing coverage as a result of the COBRA Qualifying Event should be listed below.
      </p>
      <Dependents
        onNextStep={onNextStep}
        onCancel={onCancel}
        dependents={initialDependents}
        initialAddedDependents={initialAddedDependents}
        submitText="Next Step"
        cancelText="Cancel"
      />
    </div>
  );
}

export default CobraQeDependents;
