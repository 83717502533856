import { useState } from 'react';
import dayjs from 'dayjs';

import PageContentPaper from 'components/layout/PageContentPaper';

import ReportDateRangeFilter from 'reports/shared/ReportDateRangeFilter';
import { useLoaReportCounts } from './hooks/useLoaReportCounts';
import PendingResponses from '../nonPlanYearServices/tiles/PendingResponses';
import ActiveParticipants from '../nonPlanYearServices/tiles/ActiveParticipants';
import PendingNotices from '../nonPlanYearServices/tiles/PendingNotices';
import CurrentActive from '../nonPlanYearServices/tiles/CurrentActive';
import NoticesMailed from '../nonPlanYearServices/tiles/NoticesMailed';
import AcceptanceReceived from '../nonPlanYearServices/tiles/AcceptanceReceived';
import PaymentsReceived from '../nonPlanYearServices/tiles/PaymentsReceived';
import MonthlyRecap from '../nonPlanYearServices/tiles/MonthlyRecap';
import InvoiceStatus from '../nonPlanYearServices/tiles/InvoiceStatus';
import ActiveWithAddress from '../nonPlanYearServices/tiles/ActiveWithAddress';
import TerminatedParticipants from '../nonPlanYearServices/tiles/TerminatedParticipants';
import TurningSixtyFive from '../nonPlanYearServices/tiles/TurningSixtyFive';
import NewEnrollments from '../nonPlanYearServices/tiles/NewEnrollments';
import OverDueInvoices from '../nonPlanYearServices/tiles/OverDueInvoices';

function LoaBillingReports() {
  const [ startDate, setStartDate ] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [ endDate, setEndDate ] = useState<string>(dayjs().format('YYYY-MM-DD'));

  const counts = useLoaReportCounts(startDate, endDate);

  function handleApply(start: string, end: string) {
    setStartDate(start);
    setEndDate(end);
  }

  return (
    <div className="w-full flex space-x-8">
      <PageContentPaper className="w-3/4">
        <div className="space-y-4">
          <div className="w-full">
            <ReportDateRangeFilter onFilterChange={handleApply} />
          </div>
          <div className="grid grid-flow-row grid-cols-3 grid-rows-4 gap-8">
            <NoticesMailed
              serviceType="loa"
              count={counts?.noticesMailed ?? null}
              startDate={startDate}
              endDate={endDate}
            />
            <PaymentsReceived
              startDate={startDate}
              endDate={endDate}
              count={counts?.paymentsReceived ?? null}
              reportType="loa"
            />
            <CurrentActive
              serviceType="loa"
              description="This report looks for participants in the system during the date range entered and will
provide you with detail regarding those participants considered active with Event Dates within the date range."
              count={counts?.currentActive ?? null}
              startDate={startDate}
              endDate={endDate}
            />
            <MonthlyRecap
              count={counts?.monthlyRecap ?? null}
              serviceType="loa"
              startDate={startDate}
              endDate={endDate}
            />
            <AcceptanceReceived
              serviceType="loa"
              description="List of participants that have accepted coverage
and what plans they have elected. ** This report does not confirm payment received."
              count={counts?.acceptancesReceived ?? null}
              startDate={startDate}
              endDate={endDate}
            />
            <InvoiceStatus
              count={counts?.invoiceStatus ?? null}
              serviceType="loa"
              startDate={startDate}
              endDate={endDate}
            />
            <ActiveWithAddress
              count={counts?.activeWithAddress ?? null}
              serviceType="loa"
              description="List of active participants with their addresses."
              startDate={startDate}
              endDate={endDate}
            />
            <TerminatedParticipants
              count={counts?.termsFromLoa ?? null}
              serviceType="loa"
              description="List of participants that have terminated and their reason for termination
within the date range selected."
              startDate={startDate}
              endDate={endDate}
            />
            <TurningSixtyFive
              count={counts?.turningSixtyFive ?? null}
              serviceType="loa"
              startDate={startDate}
              endDate={endDate}
            />
            <NewEnrollments
              count={counts?.newEnrollments ?? null}
              serviceType="loa"
              startDate={startDate}
              endDate={endDate}
            />
            <OverDueInvoices
              count={counts?.overDueInvoices ?? null}
              serviceType="loa"
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </PageContentPaper>
      <div className="w-1/4 flex flex-col space-y-8">
        <PendingResponses serviceType="loa" />
        <PendingNotices type="qe" serviceType="loa" />
        <ActiveParticipants serviceType="loa" />
      </div>
    </div>
  );
}

export default LoaBillingReports;
