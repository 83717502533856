import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { CobraQe, QeEventType } from '../cobra-qe.types';
import classes from './CobraQeSummary.module.css';
import ParticipantSummary from 'components/shared/qe/ParticipantSummary';
import DependentsSummary from 'components/shared/qe/DependentsSummary';
import EventInfoSummary from 'components/shared/qe/EventInfoSummary';

interface CobraQeSummaryProps {
  entry: CobraQe;
  eventTypes: QeEventType[];
  onCancel: () => void;
  onSave: (doAnother: boolean) => void;
}

function CobraQeSummary({ entry, eventTypes, onCancel, onSave }: CobraQeSummaryProps) {
  function handleSubmit(doAnother: boolean) {
    return () => {
      onSave(doAnother);
    };
  }
  const eventInfo = {
    eventDate: entry.eventDate,
    eventType: entry.eventType,
    coverageBegins: entry.coverageBegins,
    paidThrough: entry.paidThrough,
  };

  return (
    <div className="w-full flex flex-col space-y-4">
      <p className="mb-8">Are you sure you want to submit this COBRA Qualifying Event?</p>
      <div className="flex flex-col space-y-32">
        <div className="flex space-x-8">
          <div className="w-1/2">
            <div className="flex justify-between border-b border-solid border-gray-500 pb-1 mb-2">
              <p className={classes.sectionHeader}>Personal Details</p>
              <Link
                to="/enter-cobra-qe/personal-details"
                className={`${classes.link} text-sm`}
                data-testid="detailsLink"
              >
                Edit
              </Link>
            </div>
            <ParticipantSummary participant={entry.participant} />
          </div>
          <div className="w-1/2">
            <div className="flex justify-between border-b border-solid border-gray-500 pb-1 mb-2">
              <p className={classes.sectionHeader}>Dependents</p>
              <Link to="/enter-cobra-qe/dependents" className={`${classes.link} text-sm`} data-testid="dependentsLink">
                Edit
              </Link>
            </div>
            <DependentsSummary dependents={entry.initialDependents.concat(entry.addedDependents)} />
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <div className="flex justify-between border-b border-solid border-gray-500 pb-1 mb-2">
              <p className={classes.sectionHeader}>Event Info</p>
              <Link to="/enter-cobra-qe/event-info" className={`${classes.link} text-sm`} data-testid="eventLink">
                Edit
              </Link>
            </div>
            <EventInfoSummary eventInfo={eventInfo} eventTypes={eventTypes} />
          </div>
          <div className="w-1/2">
            <div className="flex justify-between border-b border-solid border-gray-500 pb-1 mb-2">
              <p className={classes.sectionHeader}>Benefits</p>
              <Link to="/enter-cobra-qe/benefits" className={`${classes.link} text-sm`} data-testid="benefitsLink">
                Edit
              </Link>
            </div>
            <div className="flex flex-col space-y-2" data-testid="benefitsList">
              {
                entry.benefits.map((benefit) => {
                  return (
                    <div key={benefit.plansId}>
                      <p className="text-lg text-black font-medium">{benefit.displayName}</p>
                      <p className="text-sm">
                        <span className="text-black font-medium">Covered: </span>
                        {
                          (
                            [
                              { name: `${entry.participant.fname} ${entry.participant.lname}` },
                              ...benefit.coveredDependents,
                            ]
                          )
                            .map((dep) => dep.name)
                            .join(', ')
                        }
                      </p>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end py-8 space-x-8">
        <Button
          color="primary"
          onClick={onCancel}
          data-testid="cancelCobraQe"
        >
            Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit(false)}
          data-testid="submitCobraQe"
        >
          Submit COBRA QE
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(true)}
          data-testid="submitCobraQeAndDoAgain"
        >
              Submit &amp; Enter Another COBRA QE
        </Button>
      </div>
    </div>
  );
}

export default CobraQeSummary;
