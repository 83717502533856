import { Dayjs } from 'dayjs';

/* istanbul ignore next *//* I think it's low value to test this */
export default function reportQueryStringTag(
  strings: TemplateStringsArray,
  companyId: number,
  serviceType: string,
  start?: Dayjs,
  end?: Dayjs,
) {
  let queryString = [];

  if (start) {
    queryString.push(`startDate=${start.format('YYYY-MM-DD')}`);
  }

  if (end) {
    queryString.push(`endDate=${end.format('YYYY-MM-DD')}`);
  }

  const base = `${strings[0]}${companyId}${strings[1]}${serviceType}${strings[2]}`;

  if (queryString.length) {
    return `${base}?${queryString.join('&')}`;
  }

  return base;
}
/* istanbul ignore stop */
