import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { compose, nthArg } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { FloresDocument } from './view-documents.types';
import DocumentTableRow from './DocumentTableRow';
import { selectSelectedCompany } from 'companies/companies.slice';
import { selectParticipant } from 'participant-search/participant.slice';
import request from 'shared/api-request';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import store from 'store';

export interface DocumentTableProps {
  documents: FloresDocument[] | null;
}

function DocumentsTable({ documents }: DocumentTableProps): React.ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const { empno } = useSelector(selectParticipant) ?? { empno: undefined };
  const [ page, setPage ] = useState(0);

  function handleDownloadDocument(e: FloresDocument): Promise<any> {
    return request<string>(`/api/companies/${compid}/qualifying-events/documents/${empno}/${e.documentNumber}`)
      .catch(compose(dispatch, addHttpErrorNotification));
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Received</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Document</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          documents && documents.length > 0
            ? documents
              .slice(page * 10, page * 10 + 10)
              .map((document) => (
                <DocumentTableRow
                  document={document}
                  key={document.documentNumber}
                  onDownloadDocument={handleDownloadDocument}
                />
              ))
            : <TableRow><TableCell><p>No Documents to display.</p></TableCell></TableRow>
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPage={10}
            rowsPerPageOptions={[ 10 ]}
            colSpan={4}
            count={documents?.length ?? 0}
            page={page}
            onPageChange={compose(setPage, nthArg(1))}
            SelectProps={ { native: true } }
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

export default DocumentsTable;
