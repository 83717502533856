import { identity } from 'ramda';

import { noop } from 'shared/utils';
import store from 'store';
import { cobraChecklistPlanValidationSchema } from './cobra-checklist-plan.validators';
import {
  selectCobraPlanOnDeck,
  setCobraPlanOnDeckValidations,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';

export const validate = (appStore: typeof store) => (next: any) => async (action: any) => {
  if (action) {
    next(action);
  }

  if (
    action.type.startsWith('reEnrollmentChecklist/setCobraPlanOnDeck')
    && !action.type.endsWith('Validations')
  ) {
    const state = appStore.getState();
    const planOnDeck = selectCobraPlanOnDeck(state);
    const validations = await cobraChecklistPlanValidationSchema
      .validate(planOnDeck, { stripUnknown: true, abortEarly: false })
      .then(noop)
      .catch(identity);

    const validation = (validations?.errors ?? [])
      .reduce((value: Record<string, string>, acc: Record<string, string>) => {
        return { ...acc, ...value };
      }, {});
    appStore.dispatch(setCobraPlanOnDeckValidations(validation));
  }
};
