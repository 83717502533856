import { useState } from 'react';
import { useSelector } from 'react-redux';

import ReportsTile from 'reports/shared/ReportsTile';
import { useCustomEnrollmentCount } from './hooks/useCustomEnrollmentCount';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { downloadCustomEnrollmentReport } from 'shared/api/customEnrollmentApi';
import { selectSelectedCompany } from '../../companies/companies.slice';

interface CustomEnrollmentTileProps {
  selectedPlan?: OpenPlan;
}

function CustomEnrollment({ selectedPlan }: CustomEnrollmentTileProps) {
  const count = useCustomEnrollmentCount({ selectedPlan });
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const [ downloading, setDownloading ] = useState(false);
  const errorNotifier = useErrorNotifier();

  function handleExport() {
    if (selectedPlan) {
      setDownloading(true);
      downloadCustomEnrollmentReport(compid, selectedPlan)
        .catch(errorNotifier)
        .finally(() => setDownloading(false));
    }
  }

  return (
    <ReportsTile
      count={count}
      title="Enrollment Roster"
      description="Live data of participants enrolled in each plan as selected."
      variant="primary-main"
      exportDisabled={downloading || !selectedPlan}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default CustomEnrollment;
