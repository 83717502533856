import { omit } from 'ramda';

import request, { ContentType } from '../api-request';
import {
  BaseReEnrollmentApiPayload,
  CobraChecklistPlan,
  CobraReEnrollmentChecklistPlan,
  CoverageType,
  CustomReEnrollmentPlanInformation,
  DirectBillReEnrollmentChecklistPlan,
  FileListItem,
  PlanLevelDescription,
  ReEnrollmentChecklistPlan,
} from 're-enrollment-checklist/re-enrollment-checklist.types';
import safeFilename from 'shared/utils/safe-filename';

export function getReEnrollmentChecklistPlans(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load re-enrollment plans for invalid company'));
  }

  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan-years`;

  return request<ReEnrollmentChecklistPlan[]>(uri)
    .then(plans => plans ?? []);
}

export function updateCobraOePacketInfo(details: CobraReEnrollmentChecklistPlan) {
  if (details.companyId <= 0) {
    return Promise.reject(new Error('Cannot save re-enrollment checklist for invalid company.'));
  }

  if (details.planYearId <= 0) {
    return Promise.reject(new Error('Cannot save re-enrollment checklist for invalid plan year.'));
  }
  const planDetails = omit([ 'oePacketFiles' ], details);
  const uri = `/api/companies/${details.companyId}/reenrollment-checklist/plan-year/${details.planYearId}/cobra`;

  return request<CobraReEnrollmentChecklistPlan>(uri, {
    method: 'PUT',
    body: planDetails,
  });
}

export const uploadCobraOePacketDoc = (companyId: number, planYearId: number) => (files: File[]) => {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot save re-enrollment checklist document for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot save re-enrollment checklist document for invalid plan year.'));
  }
  const formData = new FormData();
  Array.from(files).forEach((file) => formData.append('files', file, safeFilename(file.name)));

  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan-year/${planYearId}/cobra/files`;

  return request<CobraReEnrollmentChecklistPlan>(uri, {
    headers: { 'content-type': ContentType.FORM_DATA },
    method: 'POST',
    body: formData,
  });
};

export const updateChecklistComplete = ({ companyId, planYearId }: ReEnrollmentChecklistPlan) => {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot complete re-enrollment checklist for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot complete re-enrollment checklist for invalid plan year.'));
  }
  const uri =
      `/api/companies/${companyId}/reenrollment-checklist/plan-year/${planYearId}/oe-packets/complete`;

  return request<CobraReEnrollmentChecklistPlan>(uri, {
    method: 'PUT',
  });
};

export function updateCobraPlan(details: CobraChecklistPlan, companyId: number, planYearId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot save re-enrollment checklist for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot save re-enrollment checklist for invalid plan year.'));
  }
  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan/${planYearId}/cobra-plan`;

  return request<CobraChecklistPlan[]>(uri, {
    method: 'PUT',
    body: details,
  })
    .then(plans => plans ?? []);
}

export function getCobraChecklistPlans({ companyId, planYearId }: BaseReEnrollmentApiPayload) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load Cobra checklist plans for invalid company'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot get Cobra checklist plans for invalid plan year.'));
  }
  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan/${planYearId}/cobra-plans`;

  return request<CobraChecklistPlan[]>(uri)
    .then(plans => plans ?? []);
}

export function getCustomPlans(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load Custom plans for invalid company'));
  }

  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan-types/custom-plans`;

  return request<CustomReEnrollmentPlanInformation[]>(uri)
    .then(plans => plans ?? []);
}

export function deleteInProgressPlan(companyId: number, planYearId: number, planInProgressId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot delete in progress plan for invalid company'));
  }

  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan/${planYearId}/cobra-plans/${planInProgressId}`;

  return request<CobraChecklistPlan[]>(uri, { method: 'DELETE' })
    .then(plans => plans ?? []);
}

export const uploadPlanRatesDocuments = (
  companyId: number,
  planYearId: number,
  planId?: number | null,
  planInProgressId?: number | null,
  isNewPlan = false,
) => (files: File[]) => {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot save plan rates documents for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot save plan rates documents for invalid plan year.'));
  }
  const formData = new FormData();
  Array.from(files).forEach((file) => formData.append('files', file, safeFilename(file.name)));

  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan/${planYearId}/cobra/upload-rates-documents`;

  return request<FileListItem[]>(uri, {
    headers: { 'content-type': ContentType.FORM_DATA },
    method: 'POST',
    body: formData,
    queryParams: {
      planId: planId?.toString() ?? '',
      planInProgressId: planInProgressId?.toString() ?? '',
      isNewPlan: isNewPlan.toString(),
    },
  });
};

export function finalizeCobraChecklistPlans(
  { companyId, planYearId, cobraPlanIds } : {companyId: number; planYearId: number; cobraPlanIds: number[];},
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot finalize Cobra checklist plans for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot finalize Cobra checklist plans for invalid plan year.'));
  }
  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan/${planYearId}/finalize`;

  return request<CobraChecklistPlan[]>(uri, {
    method: 'POST',
    body: cobraPlanIds,
  })
    .then(plans => plans ?? []);
}

export function getCobraCoverageTypes({ companyId, planYearId } : BaseReEnrollmentApiPayload) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get coverage types for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot get coverage types for invalid plan year.'));
  }
  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan/${planYearId}/cobra/coverage-types`;

  return request<CoverageType[]>(uri)
    .then(coverageTypes => coverageTypes ?? []);
}

export function updateDirectBillChecklist(details: DirectBillReEnrollmentChecklistPlan) {
  if (details.companyId <= 0) {
    return Promise.reject(new Error('Cannot save re-enrollment checklist for invalid company.'));
  }

  if (details.planYearId <= 0) {
    return Promise.reject(new Error('Cannot save re-enrollment checklist for invalid plan year.'));
  }

  const uri = `/api/companies/${details.companyId}/reenrollment-checklist/plan-year/${details.planYearId}/direct-bill`;

  return request<DirectBillReEnrollmentChecklistPlan>(uri, {
    method: 'PUT',
    body: details,
  });
}

export function getPlanLevelDescriptions({ companyId, planYearId } : BaseReEnrollmentApiPayload) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get plan level descriptions for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot get plan level descriptions for invalid plan year.'));
  }
  const uri = `/api/companies/${companyId}/reenrollment-checklist/plan/${planYearId}/cobra/plan-level-descriptions`;

  return request<PlanLevelDescription[]>(uri)
    .then(data => data ?? []);
}
