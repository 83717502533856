import React from 'react';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { isNil } from 'ramda';

import { OpenPlan, PlanEnrollmentFormProps } from 'plan-enrollment/plan-enrollment.types';
import { validationSchema } from './plan-selection.validators';
import { useOpenPlans } from 'shared/custom-hooks/useOpenPlans';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import PlanSelect from './PlanSelect';

interface EnrollmentPlanSelectionProps extends PlanEnrollmentFormProps {
  plan?: OpenPlan;
}

function EnrollmentPlanSelection({
  plan,
  onNextStep,
  onCancel,
}: EnrollmentPlanSelectionProps): React.ReactElement {
  const form = useFormik<{planId?: number;}>({
    initialValues: { planId: plan?.unqid },
    validationSchema,
    onSubmit: ({ planId }) => {
      if (!isNil(planId)) {
        const found = plans?.find((p) => p.unqid === planId);

        if (found) {
          onNextStep(found);
        }
      }
    },
  });

  const plans = useOpenPlans();

  function handlePlanSelected(planId?: number) {
    form.setFieldValue('planId', planId);
  }

  const PlanSelectWithLoader = withLoopIcon(
    PlanSelect,
    'Loading plans...',
    plans === null,
    'w-full plan-enrollment-plans-loading',
  );

  return (
    <div className="w-full">
      <h6>Participant Enrollment</h6>
      <p className="my-8">Select from the list below the plan in which the employee should be enrolled.</p>
      <form>
        <div className="w-full">
          <PlanSelectWithLoader
            name="planId"
            plans={plans ?? []}
            value={form.values.planId}
            error={!!form.touched.planId && !!form.errors.planId}
            onPlanSelected={handlePlanSelected}
            showSubPlan={false}
          />
        </div>
        <div className="wizard-buttons" >
          <Button
            color="primary"
            onClick={onCancel}
            className="plan-enrollment-cancel"
            tabIndex={-1}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="ml-8 plan-enrollment-next"
            onClick={form.submitForm}
            disabled={!form.isValid}
            tabIndex={-1}
          >
            Next Step
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EnrollmentPlanSelection;
