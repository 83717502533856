import Button from '@mui/material/Button';
import { FormikProvider, useFormik } from 'formik';

import styles from './EventInfoForm.module.css';
import { QeEventInfo } from '../cobra-qe.types';
import { validationSchema } from './event-info.validators';
import EventInfoForm from './EventInfoForm';

export interface QeEventInfoProps {
  data?: QeEventInfo;
  onNextStep: (data: QeEventInfo) => void;
  onCancel: () => void;
}

function EventInfo({ data = {}, onNextStep, onCancel }: QeEventInfoProps) {
  const form = useFormik<Partial<QeEventInfo>>({
    initialValues: {
      eventDate: undefined,
      coverageBegins: undefined,
      eventType: -1,
      paidThrough: undefined,
      ...data,
    },
    validationSchema,
    onSubmit: (values) => {
      onNextStep({
        ...values,
        eventType: parseInt(values.eventType?.toString() ?? '-1'),
      } as QeEventInfo);
    },
  });

  return (
    <form data-testid="event-info">
      <div className="flex flex-col space-y-4">
        <p>Enter the loss of coverage event details below.</p>
        <div className={`flex flex-wrap ${styles.twoCol}`}>
          <FormikProvider value={form}>
            <EventInfoForm />
          </FormikProvider>
        </div>
      </div>
      <div className="flex justify-end my-4">
        <Button
          color="primary"
          onClick={onCancel}
          data-testid="cancel"
          tabIndex={-1}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={form.submitForm}
          data-testid="next-step"
          className="ml-4"
          tabIndex={-1}
        >
          Next Step
        </Button>
      </div>
    </form>
  );
}

export default EventInfo;
