import * as yup from 'yup';

import {
  fname,
  lname,
  email,
  hstreet1,
  hstreet2,
  hcity,
  hstate,
  requiredText,
  errorMessages,
  regexPatterns,
} from 'shared/common-validators';
import { LeaveRequestStepSlug } from 'enter-leave-request/enter-leave-request-steps';

export const personalDetailsSchema = yup.object().shape({
  employee: yup.object({
    eeId: yup
      .string()
      .required(requiredText),
    nameFirst: fname.fname,
    nameLast: lname.lname,
    phone: yup
      .string()
      .matches(regexPatterns.phone, errorMessages.phone.matches)
      .max(14, errorMessages.phone.max)
      .min(10, errorMessages.phone.min)
      .nullable(),
    ...email,
    addr1: hstreet1.hstreet1,
    addr2: hstreet2.hstreet2,
    city: hcity.hcity,
    stateProvCd: hstate.hstate,
    postalCd: yup
      .string()
      .trim()
      .matches(regexPatterns.postalCd, errorMessages.postalCd.matches)
      .min(5, errorMessages.postalCd.min)
      .max(10, errorMessages.postalCd.max)
      .required(requiredText),
  }),
});

const employmentDetailsSchema = yup.object().shape({
  employment: yup.object({
    type: yup
      .string()
      .trim()
      .required(requiredText),
    location: yup
      .string()
      .trim()
      .max(30, 'Location must be 30 characters or less')
      .nullable(),
    supervisorEmail: yup
      .string()
      .trim()
      .email('Must be a valid email address')
      .max(50, 'Email must be 50 characters or less')
      .required(requiredText),
    hireDate: yup
      .date()
      .typeError('Invalid Date Format')
      .nullable()
      .required(requiredText),
    totalHoursWorked: yup
      .number()
      .positive('Must be a positive number')
      .typeError('Please enter a valid number')
      .required(requiredText),
  }),
});

const eventDetailsSchema = yup.object().shape({
  event: yup.object({
    dateStart: yup
      .date()
      .typeError('Invalid Date Format')
      .nullable()
      .required(requiredText),
    dateEnd: yup
      .date()
      .typeError('Invalid Date Format')
      .nullable()
      .required(requiredText),
    reason: yup
      .string()
      .trim()
      .required(requiredText),
    kinRelation: yup
      .string()
      .trim(),
    duration: yup
      .string()
      .trim()
      .required(requiredText),
    explanation: yup
      .string()
      .trim()
      .required(requiredText),
    confirmationEmail: yup
      .string()
      .trim()
      .email('Must be a valid email address')
      .max(50, errorMessages.email.max)
      .required(requiredText),
    faxHealthProvider: yup
      .boolean()
      .required(requiredText),
    providerName: yup
      .string()
      .when('faxHealthProvider', {
        is: true,
        then: yup
          .string()
          .required(requiredText)
          .max(50, 'Provider Name must be 50 characters or less'),
      }),
    providerFax: yup
      .string()
      .when('faxHealthProvider', {
        is: true,
        then: yup
          .string()
          .required(requiredText)
          .matches(regexPatterns.phone, errorMessages.fax.matches)
          .max(14, errorMessages.fax.max)
          .min(10, errorMessages.fax.min),
      }),
    kinName: yup
      .string()
      .max(50)
      .nullable(),
  }),
});

export const leaveRequestSchema = personalDetailsSchema.concat(employmentDetailsSchema).concat(eventDetailsSchema);

export const validation = new Map<LeaveRequestStepSlug, object>([
  [ 'personal-details', personalDetailsSchema ],
  [ 'employment-details', employmentDetailsSchema ],
  [ 'event-info', eventDetailsSchema ],
  [ 'summary', leaveRequestSchema ],
]);
