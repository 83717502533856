import * as React from 'react';
import { PdfTableRow as _PdfTableRow, PdfTableRowProps } from './PdfTableRow';
import { getDefaultBorderIncludes } from './utils';

export interface PdfTableBodyProps extends PdfTableRowProps {
    /**
     * The data associated with the table.
     */
    data?: any[];
}

const PdfTableRow = _PdfTableRow as any;

/**
 * This component displays the data as {@see TableRow}s.
 */
export class PdfTableBody extends React.PureComponent<PdfTableBodyProps> {
  render() {
    const rowCells: any[] = React.Children.toArray((this.props as any).children);
    const { includeLeftBorder, includeBottomBorder, includeRightBorder } = getDefaultBorderIncludes(this.props);
    const dataRows = this.props.data ?? [];

    return (
      (dataRows).map((data, rowIndex) => (
        <PdfTableRow
          {...this.props}
          key={rowIndex}
          data={data}
          includeLeftBorder={includeLeftBorder}
          includeBottomBorder={includeBottomBorder}
          includeRightBorder={includeRightBorder}
          includeTopBorder={false}
        >
          {rowCells}
        </PdfTableRow>
      ))
    );
  }
}
