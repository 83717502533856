import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';
import dayjs from 'dayjs';

import request from 'shared/api-request';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { SelectOption } from 'shared/types/form.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import store from 'store';

function payDateQueryString(startDate?: string, endDate?: string) {
  const start = startDate ? dayjs(startDate) : dayjs('');
  const end = endDate ? dayjs(endDate) : dayjs('');

  if (start.isValid() && end.isValid()) {
    return `?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;
  } else if (start.isValid()) {
    return `?startDate=${start.format('YYYY-MM-DD')}`;
  } else if (end.isValid()) {
    return `?endDate=${end.format('YYYY-MM-DD')}`;
  }

  return '';
}

export function usePayDates(payFreq: string, startDate?: string, endDate?: string) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };

  const [ payDates, setPayDates ] = useState<SelectOption[] | null>(null);

  const { current: setDefault } = useRef(compose(setPayDates, always([])));
  const { current: handleError } = useRef(compose(
    tap(setDefault),
    dispatch,
    addHttpErrorNotification,
  ));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (payFreq.length > 0) {
      const uri = `/api/companies/${compid}/pay-dates/${payFreq}`;
      const qs = payDateQueryString(startDate, endDate);
      request<string[]>(uri + qs)
        .then((dates) => {
          if (mountedRef.current) {
            const options = (dates ?? []).map((date) => ({
              value: dayjs(date).format('YYYY-MM-DD'),
              label: dayjs(date).format('M/D/YYYY'),
            }));

            setPayDates(options);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });
    } else {
      setPayDates([]);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ handleError, compid, payFreq, startDate, endDate ]);

  return payDates;
}
