import { DateFormat_M_D_YYYY } from 'shared/consts/date-time.consts';
import { LeaveRecordsEmployeeInfo } from '../leaveRecord.types';
import { dateOrPlaceholder, valueOrPlaceholder } from 'shared/utils';

interface Props {
  data?: LeaveRecordsEmployeeInfo | null;
}

export function LeaveRecordEmployeeInfoTab({ data }: Readonly<Props>) {
  const placeholder = '-';

  return (
    <div className="w-full m-4" data-testid="employee-info-tab-panel">
      <h6 className="mb-4">Employee Information</h6>
      <dl>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Employee ID Number</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.eeId, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Employee Type</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.employeeType, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Job Title</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.jobTitle, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Hours Worked in the Past 12 Months</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.hoursWorked, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Employee Phone 1</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.phone1, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Employee Phone 2</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.phone2, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Employee Email 1</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.email1, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Employee Email 2</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.email2, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Employee Address Line 1</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.address1, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Employee Address Line 2</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.address2, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Original Hire Date</dt>
            <dd className="font-medium">
              { dateOrPlaceholder(data?.originalHireDate, DateFormat_M_D_YYYY, placeholder) }
            </dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Recent Hire Date</dt>
            <dd className="font-medium">
              { dateOrPlaceholder(data?.recentHireDate, DateFormat_M_D_YYYY, placeholder) }
            </dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">HR Contact Names</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.hrContactNames, placeholder) }</dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Supervisor Name</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.supervisorName, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">STD Coverage</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.hasSTDCoverage, placeholder) }</dd>
          </div>
        </div>
      </dl>
    </div>
  );
}
