import React, { ReactElement, ReactNode } from 'react';

interface TabPanelNumberProps {
  children: ReactNode;
  index: number;
  value: number;
}

interface TabPanelStringProps {
  children: ReactNode;
  index: string;
  value: string;
}

type TabPanelProps = TabPanelNumberProps | TabPanelStringProps;

function TabPanel(props: TabPanelProps): ReactElement {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {children}
    </div>
  );
}

export default TabPanel;

