import * as yup from 'yup';

const requiredText = 'This field is required';

export const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .trim()
    .required(requiredText),
  newPassword: yup
    .string()
    .trim()
    .matches(/[A-Z]/, 'Must have an Upper Case Letter')
    .matches(/[a-z]/, 'Must have a Lower Case Letter')
    .matches(/\d/, 'Must have a Number')
    .matches(/\W/, 'Must have a Special Character')
    .min(8, 'Must be at least 8 characters')
    .required(requiredText),
  confirmNewPassword: yup
    .string()
    .trim()
    .when('newPassword', (p1: string, schema: any) => {
      return schema.test({
        test: (p2: string) => p1 === p2,
        message: 'Passwords do not match',
      });
    })
    .required(requiredText),
});
