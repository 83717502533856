import { useSelector } from 'react-redux';

import { selectSelectedCompany } from 'companies/companies.slice';
import { getParticipant as _getParticipant } from '../api/participantApi';

export function useParticipantService() {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  function getParticipant(payrollId: string) {
    try {
      return _getParticipant(compid, payrollId);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    getParticipant,
  };
}
