function syncCompanyLocalStorage () {
  return (next: any) => (action: any) => {
    if (action.type === 'companies/setSelectedCompany') {
      const stringified = JSON.stringify(action.payload);

      if (localStorage.getItem('company') !== stringified) {
        localStorage.setItem('company', stringified);
      }
    }

    if (action) {
      return next(action);
    }
  };
}

export default syncCompanyLocalStorage;
