import ChangePasswordIcon from '@mui/icons-material/VpnKey';
import ContactsIcon from '@mui/icons-material/Contacts';
import LockIcon from '@mui/icons-material/Lock';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

import { NavConfig } from 'navigation/navigation.types';

const settingsNav: NavConfig[] = [
  {
    path: '/manage-settings/contact-info',
    name: 'Contact Info',
    icon: PermContactCalendarIcon,
  },
  {
    path: '/manage-settings/change-password',
    name: 'Change Password',
    icon: ChangePasswordIcon,
  },
  {
    path: '/manage-settings/multi-factor',
    name: '2-Step Verification',
    icon: LockIcon,
  },
  {
    path: '/manage-settings/manage-users',
    name: 'Manage Users',
    icon: ContactsIcon,
  },
];

export default settingsNav;
