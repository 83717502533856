import { useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import { SelectOption, SelectChangeEvent } from 'shared/types/form.types';
import FormErrorText from 'components/shared/FormErrorText';

export interface CommonSelectClasses {
  control?: string;
  label?: string;
  select?: string;
  error?: string;
}

interface CommonSelectProps {
  options: SelectOption[];
  value: string;
  label: string;
  name: string;
  error?: boolean;
  errorMessage?: string;
  classes?: CommonSelectClasses;
  disabled?: boolean;
  emptyOption?: {
    label?: string;
    value?: string;
  };
  onSelectChange: (value: string) => void;
  onSelectBlur?: (e: React.FocusEvent<any, Element>) => void;
}

function CommonSelect({
  options,
  onSelectChange,
  onSelectBlur,
  value,
  label,
  name,
  error = false,
  errorMessage,
  disabled = false,
  classes = {
    control: 'w-full common-select',
  },
  emptyOption = { label: '', value: '' },
}: CommonSelectProps) {
  const labelRef = useRef<HTMLLabelElement>(null);

  function handleChange({ target: { value: val } }: SelectChangeEvent) {
    onSelectChange(val as string);
  }

  return (
    <FormControl variant="outlined" className={classes.control}>
      <InputLabel ref={labelRef} className={classes.label}><span className="bg-white px-2">{label}</span></InputLabel>
      <Select
        onChange={handleChange as any}
        onBlur={onSelectBlur}
        name={name}
        value={value}
        native
        fullWidth
        error={error}
        className={classes.select}
        disabled={disabled}
      >
        <option value={emptyOption.value}>{emptyOption.label}</option>
        {options.map((opt) => <option value={opt.value} key={opt.value}>{opt.label}</option>)}
      </Select>
      <FormErrorText show={error} className={classes.error}>
        {errorMessage ?? `${label} is required.`}
      </FormErrorText>
    </FormControl>
  );
}

export default CommonSelect;
