import jwt_decode from 'jwt-decode';
import { getJWT } from 'authentication/authentication';

type UserPermission = 'Users' | 'Direct Debits';

/**
 * Check if user has a certain permission
 * @param permissionDescription the description text of the permission
 * @returns the jwt or empty string if it is not set
 * @throws if the REACT_APP_JWT_STORAGE_KEY environment variable is null or undefined
 */
export function hasPermission(permissionDescription: UserPermission): boolean {
  const jwt = getJWT();

  if (!jwt) {
    return false;
  }
  const jwtDecoded = jwt_decode(jwt);

  if ((jwtDecoded as any)?.UserPermissions === undefined) {
    return false;
  }

  return JSON.parse((jwtDecoded as any).UserPermissions).some(
    (p: { description: UserPermission; }) => p.description === permissionDescription);
}
