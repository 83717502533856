import { useRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { compose, path } from 'ramda';

import { FileType } from './file-types';

interface FileTypeSelectProps {
  value?: string;
  onSelectChange: (fileDescription?: string) => void;
  fileTypes: FileType[];
  disabled?: boolean;
}

function FileTypeSelect({ value, onSelectChange, fileTypes, disabled = false }: FileTypeSelectProps) {
  const labelRef = useRef<HTMLLabelElement>(null);

  const { current: handleChange } = useRef(compose(onSelectChange,path([ 'target', 'value' ])));

  return (
    <FormControl variant="outlined" className="w-full">
      <InputLabel ref={labelRef}>File Type</InputLabel>
      <Select className="file-type-select"
        label="File Type"
        value={value ?? ''}
        onChange={handleChange}
        native
        disabled={disabled}
      >
        <option value="" />
        {
          fileTypes.map(file => (
            <option key={file.fileType} value={file.description}>{file.description}</option>
          ))
        }
      </Select>
    </FormControl>
  );
}

export default FileTypeSelect;
