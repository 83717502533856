import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';

import ReportsTile from '../../shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useSelector } from 'react-redux';
import { downloadCurrentActiveReport } from 'shared/api/currentActiveApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { CRLType } from 'shared/types/non-plan-year.types';

interface CurrentActiveProps {
  serviceType: CRLType;
  description: string;
  startDate: string | null;
  endDate: string | null;
  count: number | null;
}

function CurrentActive({ serviceType, description, startDate, endDate, count }: CurrentActiveProps) {
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  function handleExport() {
    startDownloading();
    downloadCurrentActiveReport(compid, serviceType, startDate ?? undefined, endDate ?? undefined)
      .then(doneDownloading)
      .catch(handleError);
  }

  return (
    <ReportsTile
      count={count}
      title="Active During Range"
      description={description}
      exportDisabled={count === null || !startDate || !endDate}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default CurrentActive;
