import dayjs from 'dayjs';
import { DirectBillingEventType } from 'direct-billing/direct-billing.types';
import { QeEventType } from 'enter-cobra-qe/cobra-qe.types';
import { EventInfo } from 'shared/types/non-plan-year.types';

interface EventInfoSummaryProps {
  eventInfo: EventInfo;
  eventTypes: QeEventType[] | DirectBillingEventType[] | null;
}

function EventInfoSummary({ eventInfo, eventTypes }: EventInfoSummaryProps) {
  function eventDescription() {
    if (eventTypes === null || eventTypes.length === 0) {
      return '';
    }

    if (eventTypes[0].hasOwnProperty('r_qualevid')) {
      return (eventTypes as DirectBillingEventType[])
        .find((type) => type.r_qualevid === eventInfo.eventType)?.desc ?? eventInfo.eventType;
    } else {
      return (eventTypes as QeEventType[])
        .find((type) => type.c_qualevid === eventInfo.eventType)?.desc ?? eventInfo.eventType;
    }
  }

  return (
    <div className="text-lg flex flex-col space-y-2">
      <p>
        <span className="text-black font-medium">Event Date: </span>
        {dayjs(eventInfo.eventDate).format('M/D/YYYY')}
      </p>
      <p>
        <span className="text-black font-medium">Event Type: </span>
        {eventDescription()}
      </p>
      <p>
        <span className="text-black font-medium">Coverage Begins: </span>
        {dayjs(eventInfo.coverageBegins).format('M/D/YYYY')}
      </p>
      {
        eventInfo.paidThrough
          ? (
            <p>
              <span className="text-black font-medium">Paid Through: </span>
              {dayjs(eventInfo.paidThrough).format('M/D/YYYY')}
            </p>
          )
          : ''
      }
    </div>

  );
}

export default EventInfoSummary;
