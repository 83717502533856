import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import { compose } from 'ramda';

import Navigation from 'navigation/Navigation';
import { makeNav, NavPredicate } from 'components/layout/make-nav';
import Typography from '@mui/material/Typography';
import CompanyAvatar from 'companies/CompanyAvatar';
import { selectSelectedCompany } from 'companies/companies.slice';
import FloresHeaderLogo from './Header/FloresHeaderLogo';
import sidebarNav from './sidebar-nav-config';
import { Company } from 'companies/company.types';
import { hasPermission } from 'authorization/authorization';

interface SidebarProps {
  collapsed: boolean;
  onToggleDrawer: () => void;
}

function navConfigPredicate(company: Company | null): NavPredicate {
  return function(item) {
    switch (item.name) {
    case 'Scratchpad':
      return process.env.REACT_APP_HIDE_DEV_FEATURES === 'false';
    case 'Start Retiree/LOA Billing':
      return !!company?.hasRetiree && !company.isFmlaOnly;
    case 'Perform Direct Debit':
      return hasPermission('Direct Debits') && !company?.isFmlaOnly;
    case 'Rates History':
      return (!!company?.hasCobra || !!company?.hasRetiree) && !company.isFmlaOnly;
    case 'Enter Leave Request':
    case 'Search Leave Cases':
      return !!company?.hasFmla;
    case 'Open Enrollment Resources':
    case 'Re-Enrollment Checklist':
      return !!company?.isOpenEnrollmentPeriod && !company.isFmlaOnly;
    case 'Add New Participant':
    case 'Terminate Participant':
      return !company?.isCobraOnly && !company?.isFmlaOnly;
    case 'File Upload':
    case 'Report History':
    case 'File History':
      return !company?.isFmlaOnly;
    default:
      return company?.hasCobra || (item.name !== 'Enter COBRA Initial Notice' && item.name !== 'Enter COBRA QE');
    }
  };
}

const filter = compose(makeNav, navConfigPredicate);

function Sidebar({ collapsed, onToggleDrawer }: SidebarProps): React.ReactElement {
  const company = useSelector(selectSelectedCompany);
  const modalContainer = document.body.querySelector('#root');
  const [ nav, setNav ] = useState(filter(company)(sidebarNav));

  useEffect(() => {
    setNav(filter(company)(sidebarNav));
  }, [ company ]);

  const drawerContent: React.ReactElement = (
    <>
      <FloresHeaderLogo />
      <Divider />
      <div className="flex flex-col justify-center items-center my-4">
        <CompanyAvatar company={company} className="w-20 h-20 my-2" />
        <Typography className="text-center" variant="body1" id="company-name">{company?.compname}</Typography>
      </div>
      <Divider />
      <Navigation navConfig={nav} showLegacy />
    </>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={collapsed}
          onClose={onToggleDrawer}
          ModalProps={ {
            keepMounted: true, // Better open performance on mobile.
            container: modalContainer,
          } }
          classes={ {
            paper: `w-65 bg-surface`,
          } }
          data-testid="sidebarDrawer"
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          variant="permanent"
          open
          classes={ {
            paper: `w-65 bg-surface`,
          } }
          data-testid="permSidebarDrawer"
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
