import React from 'react';
import Grid from '@mui/material/Grid';
import LoopIcon from '@mui/icons-material/Loop';
import { compose } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import PageContentPaper from 'components/layout/PageContentPaper';
import { useLegalDocuments } from 'document-library/hooks/useLegalDocuments';
import GridCard from 'components/shared/GridCard';
import { LibraryDocument } from 'document-library/document-library.types';
import { downloadCloudFile } from 'shared/api/filesApi';
import { selectSelectedCompany } from 'companies/companies.slice';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { AppDispatch } from 'store';
import { getDocImage } from '../imageMap';

function LegalDocumentLibrary() {
  const legalDocs = useLegalDocuments();
  const { compid: companyId } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const dispatch = useDispatch<AppDispatch>();

  function downloadLegalDoc(document: LibraryDocument) {
    return () => {
      downloadCloudFile(companyId, document.fileSection ?? 'LegalDocs', document.url)
        .catch((err) => {
          compose(dispatch, addHttpErrorNotification)(err);
        });
    };
  }

  return (
    <PageContentPaper>
      <div className="flex flex-col">
        <div>
          <Grid className="grid-section" container spacing={3}>
            {
              legalDocs === null
                ? <><LoopIcon className="animate-spin" /> Loading...</>
                : legalDocs.map((doc, i) => {
                  const urlFragments = doc.url.split('.');

                  return (
                    <GridCard
                      key={doc.url}
                      src={getDocImage(doc.name)}
                      name={doc.name}
                      type={urlFragments[urlFragments.length - 1].toUpperCase()}
                      onClick={downloadLegalDoc(doc)}
                    />
                  );
                })
            }
          </Grid>
        </div>
      </div>
    </PageContentPaper>
  );
}

export default LegalDocumentLibrary;
