import FormControlLabel from '@mui/material/FormControlLabel';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BlockIcon from '@mui/icons-material/Block';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { FileListItem } from 're-enrollment-checklist/re-enrollment-checklist.types';
import coverLetterBanner from 'images/confirm-cover-letter.png';

interface Props {
  file: FileListItem | null;
  title: string;
  fileType: string;
  reviewStatus: boolean;
  approvalStatus: boolean;
  onChangeReviewStatus: (x0: any) => unknown;
  onChangeApprovalStatus: (x0: any) => unknown;
  onDownload: Function;
  inputsDisabled: boolean;
}

function OeGeneratedFile({
  file,
  title,
  fileType,
  reviewStatus,
  approvalStatus,
  onChangeReviewStatus,
  onChangeApprovalStatus,
  onDownload,
  inputsDisabled,
}: Props) {
  const confirmReviewedLabel =
  'By checking this box I confirm that I have downloaded and reviewed this document for accuracy and errors';
  const buttonBaseClasses: string[] = [
    'text-primary-main',
    'border-primary-main',
    'h-12',
    'basis-full',
    'my-2',
  ];
  const buttonApprovedClasses: string[] = [
    'text-black',
    'border-green-500',
    'bg-green-50',
  ];
  const buttonDisabledClasses: string[] = [ 'opacity-50' ];

  function setApproveButtonClass() {
    let classNamesArray = buttonBaseClasses;
    const isDocApproved = approvalStatus;
    const isDocReviewed = reviewStatus;

    if (isDocApproved) {
      classNamesArray = classNamesArray.concat(buttonApprovedClasses);
      classNamesArray.shift();
    }

    if ((!isDocApproved && !isDocReviewed)) {
      classNamesArray = classNamesArray.concat(buttonDisabledClasses);
    }

    const classNamesString = classNamesArray.join(' ');

    return classNamesString;
  }

  return (
    <>
      {!!file?.fullName &&
      <div key={fileType} className="w-1/3 rounded-2xl border mr-4 border-gray-400">
        <img src={coverLetterBanner} className="rounded-t-2xl" alt={title} />
        <div className="border-t border-gray-400 py-4 px-8">
          <h6><DescriptionIcon color="primary" />{title}</h6>
          {!inputsDisabled && !approvalStatus &&
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id={`${fileType}-review-checkbox`}
                  data-testid={`${fileType}-review-checkbox`}
                  value={reviewStatus}
                  checked={reviewStatus}
                  onChange={onChangeReviewStatus}
                />
              }
              classes={ { root: 'mt-2 mr-0', label: 'text-sm' } }
              label={confirmReviewedLabel}
              labelPlacement="end"
            />}
          <div className="flex flex-wrap justify-center pt-4">
            <Button
              className={setApproveButtonClass()}
              id={`${fileType}-approve-button`}
              data-testid={`${fileType}-approve-button`}
              variant={'outlined'}
              onClick={onChangeApprovalStatus}
              disabled={inputsDisabled || approvalStatus || !reviewStatus}
            >

              {approvalStatus
                ? (<><CheckCircleOutlineIcon className="mr-2 text-green-500" /> Approved</>)
                : 'Approve'}

            </Button>

            {!inputsDisabled && approvalStatus &&
              <Button
                className={buttonBaseClasses.join(' ')}
                id={`${fileType}-unapprove-button`}
                data-testid={`${fileType}-unapprove-button`}
                variant={'outlined'}
                onClick={onChangeApprovalStatus}
              >
                <BlockIcon className="mr-2" /> Unapprove
              </Button>}

            <Button
              className={buttonBaseClasses.join(' ')}
              id={`${fileType}-download-button`}
              data-testid={`${fileType}-download-button`}
              variant={'outlined'}
              onClick={onDownload(`${file.fullName}`)}
            >
              <DownloadIcon className="mr-2" /> Download
            </Button>
          </div>
        </div>
      </div>}
    </>
  );
}

export default OeGeneratedFile;
