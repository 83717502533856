import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { getEnrollmentSummary, downloadEnrollmentSummary } from 'shared/api/reportsApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import GraySummaryTile from 'reports/shared/GraySummaryTile';
import ReportExportButton from 'reports/shared/ReportExportButton';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

interface EnrollmentRosterTileProps {
  selectedPlan?: OpenPlan;
}

function EnrollmentRosterTile({ selectedPlan }: EnrollmentRosterTileProps) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ date, setDate ] = useState<string | null>(dayjs().format('YYYY-MM-DD'));
  const [ dateHasError, setDateHasError ] = useState(false);
  const [ enrollment, setEnrollment ] = useState<number | null>(0);
  const [ downloading, setDownloading ] = useState(false);

  const errorNotifier = useErrorNotifier();
  const [ handleError ] = useState(() => compose(
    tap(compose(setEnrollment, always(0))),
    errorNotifier,
  ));

  const Enrollment = withLoopIcon(enrollmentCount, '', enrollment === null);

  useEffect(() => {
    if (!dateHasError && date && selectedPlan) {
      setEnrollment(null);
      getEnrollmentSummary(compid, selectedPlan.pyrid, selectedPlan.plcid, false, date)
        .then((data) => setEnrollment(data ?? 0))
        .catch(handleError);
    }
  }, [ compid, selectedPlan, handleError, date, dateHasError ]);

  function handleExport() {
    if (!dateHasError && date && selectedPlan) {
      setDownloading(true);
      downloadEnrollmentSummary(compid, selectedPlan.pyrid, selectedPlan.plcid, false, date)
        .catch(handleError)
        .finally(() => setDownloading(false));
    }
  }

  function handleDateChange(newDate: any) {
    setDateHasError(!newDate || !newDate.isValid());
    setDate(newDate?.format('YYYY-MM-DD') ?? '');
  }

  function enrollmentCount() {
    return (
      <h4 data-testid="balance-reports-enrollment-loaded" className="text-high-em text-3xl font-bold">
        {enrollment}
      </h4>
    );
  }

  return (
    <GraySummaryTile>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Report Start Date"
            inputFormat="MM/DD/YYYY"
            value={date ? dayjs(date, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
            onChange={handleDateChange}
            InputProps={
              {
                className: !date ? 'MuiFormLabel-root' : '',
              }
            }
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                data-testid="balance-reports-enrollment-start"
                variant="outlined"
                name="partEnd"
                className="w-full"
                {...params}
                error={dateHasError}
                helperText={dateHasError && 'Please enter a valid date.'}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="mt-4"><Enrollment /></div>
      <h5 className="text-high-em my-4 text-base">Enrollment Roster</h5>
      <p className="text-gray-chateu">Live data of participants enrolled in each plan as selected.</p>
      <div className="mt-12" />
      <hr className="my-4" />
      <div className="w-full flex justify-end">
        <ReportExportButton
          onExport={handleExport}
          disabled={enrollment === null || downloading}
          downloading={downloading}
          title="Export enrollment report"
        />
      </div>
    </GraySummaryTile>
  );
}

export default EnrollmentRosterTile;
