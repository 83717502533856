import { DBBenefit } from 'direct-billing/direct-billing.types';
import { Participant } from 'participant-search/participant-list.types';

interface BenefitsSummaryProps {
  participant: Omit<Participant, 'pyrid' | 'planCode'>;
  benefits: DBBenefit[];
}

function BenefitsSummary({ benefits, participant }: BenefitsSummaryProps) {
  return (
    <div className="flex flex-col space-y-2" data-testid="benefitsList">
      {
        benefits.map((benefit) => {
          return (
            <div key={benefit.selectedPlan?.plansId}>
              <p className="text-lg text-black font-medium">{benefit.selectedPlan?.displayName}</p>
              <p className="text-sm">
                <span className="text-black font-medium">Covered: </span>
                {
                  ([
                    { name: `${participant.fname} ${participant.lname}` },
                    ...(benefit.coveredDependents ?? []),
                  ])
                    .map((dep) => dep.name)
                    .join(', ')
                }
              </p>
            </div>
          );
        })
      }
    </div>
  );
}

export default BenefitsSummary;
