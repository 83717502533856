import { useSelector } from 'react-redux';
import { path } from 'ramda';

import customChecklist from 'images/custom-checklist.svg';
import { EnrollmentResourcePlan } from 'open-enrollment-resources/open-enrollment.types';
import GridCard from 'components/shared/GridCard';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { downloadCustomChecklist } from 'shared/api/enrollmentResourcesApi';
import { selectSelectedCompany } from 'companies/companies.slice';

interface CustomEnrollmentCardProps {
  plan: EnrollmentResourcePlan;
  subPlanIndex: number;
}

export function CustomEnrollmentCard({ plan, subPlanIndex }: CustomEnrollmentCardProps) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const errorNotifier = useErrorNotifier();

  function downloadCustom() {
    const subPlanId = path<number>([ 'subPlans', subPlanIndex, 'pyrPlansId' ], plan) ?? 0;
    downloadCustomChecklist(compid, plan.planYearId, plan.planId, subPlanId)
      .catch(errorNotifier);
  }

  return (
    <GridCard
      src={customChecklist}
      name={`${path([ 'subPlans', subPlanIndex, 'planName' ], plan)} Checklist`}
      onClick={downloadCustom}
      type="RTF"
    />
  );
}

export default CustomEnrollmentCard;
