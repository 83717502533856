import React from 'react';
import Paper from '@mui/material/Paper';

interface PageContentPaperProps {
  children: React.ReactNode;
  className?: string;
}

function PageContentPaper({ children, className = '', ...rest }: PageContentPaperProps): React.ReactElement {
  return <Paper className={`${className} p-4 w-full`} {...rest}>{children}</Paper>;
}

export default PageContentPaper;
