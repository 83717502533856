import { useEffect, useState, useRef } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

import Loading from 'components/shared/Loading';
import {
  loadAvailableBenefits,
  setBenefits,
  selectDirectBillingDependents,
  selectAvailableBenefits,
} from 'direct-billing/direct-billing.slice';
import { DBBenefit, DirectBillingStepProps } from 'direct-billing/direct-billing.types';
import { validationSchema } from './dbbenefits.validators';
import BenefitsForm from 'components/shared/qe/BenefitsForm';
import { selectSelectedCompany } from 'companies/companies.slice';
import FormErrorText from 'components/shared/FormErrorText';
import hashCoveredDependents from './hash-covered-dependents';
import unhashCoveredDependents from './unhash-covered-dependents';
import store from 'store';

const errorMap = (msg: string, i:number) => <FormErrorText className="text-base pb-5" key={i} show message={msg} />;

type BenefitFormValue = Omit<DBBenefit, 'coveredDependents'> & { coveredDependents: string[] | null; };

function DirectBillBenefits({ onCancel }: DirectBillingStepProps) {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const availableBenefits = useSelector(selectAvailableBenefits);
  const allDependents = useSelector(selectDirectBillingDependents);

  const formRef = useRef<HTMLDivElement>(null);

  const [ wholeFormErrors, setWholeFormErrors ] = useState<string[]>([]);

  const form = useFormik<{benefits: BenefitFormValue[];}>({
    initialValues: {
      benefits: (availableBenefits ?? [])
        .map((benefit) => ({
          ...benefit,
          coveredDependents: hashCoveredDependents(benefit.coveredDependents ?? [], allDependents),
        })),
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        const bennies = values.benefits
          .map((benefit: BenefitFormValue | undefined) => ({
            ...benefit,
            cost: parseFloat(benefit?.cost + '') || null,
            subsidy: parseFloat(benefit?.subsidy + '') || null,
            coveredDependents: unhashCoveredDependents(benefit?.coveredDependents ?? [], allDependents),
          }));

        validationSchema.validateSync(
          { benefits: bennies },
          {
            abortEarly: false,
            stripUnknown: false,
          },
        );

        dispatch(setBenefits(bennies as DBBenefit[]));
        history.push('/direct-bill-event/summary');
      } catch (err) {
        setWholeFormErrors(err.errors);

        if (formRef.current?.scrollIntoView) {
          formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      }
    },
  });

  useEffect(() => {
    dispatch(loadAvailableBenefits(compid));
  }, [ dispatch, compid ]);

  return availableBenefits === null
    ? <Loading text="Loading Benefits..." />
    : (
      <div className="w-full flex flex-col space-y-4">
        <p>
          Select the benefits the insured and their dependent(s) are eligible to continue coverage.
        </p>
        <div className="w-full flex flex-col space-y-4" ref={formRef}>
          {wholeFormErrors.length > 0 && wholeFormErrors.map(errorMap)}
        </div>
        <form data-testid="db-benefits-form">
          <div className="flex flex-col mt-4">
            <FormikProvider value={form}>
              <BenefitsForm benefits={availableBenefits} />
            </FormikProvider>
          </div>
        </form>
        <div className="flex justify-end space-x-4">
          <Button
            color="primary"
            data-testid="directBillQeCancel"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="directBillQeNextStep"
            className="ml-4"
            type="button"
            onClick={form.submitForm}
            disabled={!form.isValid}
          >
            Next Step
          </Button>
        </div>
      </div>
    );
}

export default DirectBillBenefits;
