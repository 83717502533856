import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { mergeDeepLeft } from 'ramda';

import { defaultContact } from 'shared/constants';
import { validationSchema } from './add-user-details.validators';
import React from 'react';
import { Contact } from '../contact-info/contact.types';
import ContactDetails from 'components/shared/ContactDetails';
import { selectSelectedUser, setSelectedUser } from 'manage-settings/manage-users/manage-users.slice';
import store from 'store';

interface Props {
  onCancel: () => void;
}

function AddUserStepOne(
  { onCancel }: Props,
) {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const selectedUser = useSelector(selectSelectedUser);
  const form = useFormik<Partial<Contact>>({
    enableReinitialize: true,
    initialValues: {
      ...defaultContact,
      ...selectedUser,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(setSelectedUser(mergeDeepLeft(values, selectedUser ?? {}) as any));
      history.push(`/${selectedUser?.conid ? 'edit' : 'add'}-user/step-2`);
    },
  });

  return (
    <>
      <h6>{`${selectedUser?.conid ? 'Edit' : 'Add'} User`}</h6>
      <p>Enter contact information in the form below for the individual being added as an administrative user.</p>
      <form className="mt-10" onSubmit={form.handleSubmit}>
        <div className="flex flex-col space-y-4">
          <FormikProvider value={form}>
            <ContactDetails />
          </FormikProvider>

          <div className="pt-10 flex flex-row-reverse">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-testid="add-user-next-step"
              className="ml-4"
            >
              Next Step
            </Button>
            <Button
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddUserStepOne;
