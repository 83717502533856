import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { RootState } from 'store/rootReducer';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { getCustomReportPlans } from 'shared/api/customReportsByDateRangeApi';
import { assertIsDefined } from 'shared/asserts';

export interface CustomReportsState {
  plans: OpenPlan[] | null;
  selectedPlan?: OpenPlan;
}

export const initialState: CustomReportsState = {
  plans: [],
};

const customReportsSlice = createSlice({
  name: 'customReports',
  initialState,
  reducers: {
    setCustomReportPlans(state, { payload }:PayloadAction<OpenPlan[] | null>) {
      state.plans = payload;
    },
    setSelectedCustomReportPlan(state, { payload }:PayloadAction<OpenPlan | undefined>) {
      state.selectedPlan = payload;
    },
  },
});

export const {
  setCustomReportPlans,
  setSelectedCustomReportPlan,
} = customReportsSlice.actions;

export default customReportsSlice.reducer;

// thunks
export function loadCustomReportPlans(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setCustomReportPlans(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const plans = (await getCustomReportPlans(selectedCompany.compid)) ?? [];
      dispatch(setCustomReportPlans(plans));
      dispatch(setSelectedCustomReportPlan(plans.length > 0 ? plans[0] : undefined));
    } catch (ex) {
      dispatch(setCustomReportPlans([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectCustomReportPlans = (state: RootState): OpenPlan[] | null => state.customReports.plans;

export const selectSelectedCustomReportPlan =
  (state: RootState): OpenPlan | undefined => state.customReports.selectedPlan;
