import { PropsWithChildren } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

interface FormErrorTextProps {
  show: boolean;
  className?: string;
  message?: string;
}

function FormErrorText({
  show,
  className = '',
  message,
  children,
}: PropsWithChildren<FormErrorTextProps>) {
  return show
    ? (
      <FormHelperText disabled error className={className}>
        { children ?? message ?? 'This field is required.' }
      </FormHelperText>
    )
    : <></>;
}

export default FormErrorText;
