import * as yup from 'yup';

export const validationSchema = yup
  .object({
    benefits: yup
      .array()
      .of(
        yup
          .object()
          .nullable()
          .shape({
            plvlid: yup
              .string(),

            cost: yup
              .number()
              .typeError('Must be a number')
              .min(0, 'Must be a positive number'),

            subsidy: yup
              .number()
              .typeError('Must be a number')
              .min(0, 'Must be a positive number')
              .when('subsidyEnd', {
                is: (subsidyEnd: Date | undefined) => !!subsidyEnd,
                then: yup
                  .number()
                  .typeError('Must be a number')
                  .required('Subsidy is required.'),
                otherwise: yup
                  .number()
                  .nullable()
                  .typeError('Must be a number'),
              }),
            subsidyEnd: yup
              .date()
              .nullable()
              .when('subsidy', {
                is: (subsidy: number | undefined) => subsidy && (subsidy > 0),
                then: yup
                  .date()
                  .typeError('Subsidy End must be a valid date.')
                  .required('Subsidy End is required.'),
              }),
          }, [ 'subsidy', 'subsidyEnd' ] as any)),
  });
