import { downloadDocument } from 'shared/api/cobraChecklistDocumentsApi';

/* istanbul ignore next */
export default function handleDownloadDocument(companyId: number, planYearId: string, fileName: string) {
  return () => downloadDocument(companyId, planYearId, fileName)
    .then((response: any) => {
      let blob = window.URL.createObjectURL(new Blob([ response.data ]));
      let link = document.createElement('a');
      link.href = blob;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(() => {});
}
/* istanbul ignore stop */
