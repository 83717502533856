import React, { SyntheticEvent } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import { useSelector, useDispatch } from 'react-redux';
import { selectSnacks, removeNotification } from './notifications.slice';
import { NOTIFICATION_TIMEOUT, Note } from './notification.types';
import store from 'store';

function TransitionRight(props: any) {
  return <Slide {...props} direction="right" />;
}

function SnackPanel(): React.ReactElement {
  const snacks = useSelector(selectSnacks);
  const dispatch = useDispatch<typeof store.dispatch>();

  function handleClose(snack: Note, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(removeNotification(snack.key));
  }

  function inner(snack: Note): React.ReactElement {
    function closer(evt: SyntheticEvent, reason?: SnackbarCloseReason) {
      handleClose(snack, reason);
    }

    return (
      <Alert elevation={6} variant="filled" severity={snack.type} onClose={closer}>
        {snack.message}
      </Alert>
    );
  }

  function snackDisplay(): React.ReactElement[] | React.ReactElement {
    if (!snacks.length) {
      return <></>;
    }

    /*
    NOTE: Snackbar will only show one at a time. I think that's probably ok.
    If it becomes an issue, we should take a look at [notistack](https://github.com/iamhosseindhv/notistack)
    */
    return snacks.map((snack) => {
      function closer(evt: SyntheticEvent, reason?: SnackbarCloseReason) {
        handleClose(snack, reason);
      }

      return (
        <Snackbar
          data-testid={`snackbar${snack.key}`}
          key={snack.key}
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'left',
          } }
          open
          autoHideDuration={NOTIFICATION_TIMEOUT}
          onClose={closer as any}
          TransitionComponent={TransitionRight}
        >
          {inner(snack)}
        </Snackbar>
      );
    });
  }

  return <>{snackDisplay()}</>;
}

export default SnackPanel;
