import * as yup from 'yup';
import { isEmpty, isNil, T, ifElse, equals, compose, either, always, not, includes, complement } from 'ramda';
import { InsurerUpdateMethod } from './cobra-re-enrollment.types';

export const cobraChecklistPlanErrorMessages = {
  planName: {
    required: 'Plan Name is required',
  },
  carrier: {
    required: 'Carrier/Provider is required',
  },
  newCarrierName: {
    required: 'If your Insurer is not listed, you must provide a Carrier Name',
    max: 'Carrier/Provider must be 30 characters or less',
  },
  planTypeId: {
    required: 'Type of Coverage is required',
  },
  planTypeDescription: {
    required: 'If Coverage Type is "Other", you must provide a description',
  },
  carrierUpdateMethod: {
    required: 'Carrier update method is required',
    invalid: 'Carrier update method is invalid',
  },
  carrierUpdateMethodNotes: {
    required: 'Details must be provided when "Other" is selected for the carrier update method',
  },
  planPolicyNumber: {
    required: 'Policy Number must be provided when "Insurer via Flores" is selected for the carrier update method',
    max: 'Policy Number must be 20 characters or less',
  },
  carrierContactName: {
    required: 'Contact name is required',
  },
  carrierContactEmail: {
    required: 'Contact email is required',
    invalid: 'Contact email must be a valid email address',
    duplicate: 'Email address is a duplicate',
  },
  carrierContactPhone: {
    min: 'Contact phone number must be 10 numbers.',
  },
  confirmRates: {
    required: 'You must confirm your rates to continue',
  },
};

export const cobraChecklistPlanValidationSchema = yup.object({
  planName: yup
    .string()
    .required({ planName: cobraChecklistPlanErrorMessages.planName.required })
    .nullable(),
  carrier: yup
    .string()
    .required({ carrier: cobraChecklistPlanErrorMessages.carrier.required })
    .nullable(),
  newCarrierName: yup
    .string()
    .max(30, { newCarrierName: cobraChecklistPlanErrorMessages.newCarrierName.max })
    .when('carrier', (carrier: string | null, schema: any) => {
      return schema.test({
        test: (newCarrierName: string | null) => {
          return ifElse(
            equals('Insurer Not Listed Below'),
            compose(not, either(isEmpty, isNil), always(newCarrierName)),
            T)(carrier);
        },
        message: { newCarrierName: cobraChecklistPlanErrorMessages.newCarrierName.required },
      });
    })
    .nullable(),
  planTypeId: yup
    .number()
    .required({ planTypeId: cobraChecklistPlanErrorMessages.planTypeId.required })
    .test(
      'plan-type-id-equals-0',
      { planTypeId: cobraChecklistPlanErrorMessages.planTypeId.required },
      (num) => num !== 0,
    )
    .nullable(),
  planTypeDescription: yup
    .string()
    .when('planTypeId', (planTypeId: number | null, schema: any) => {
      return schema.test({
        test: (planTypeDescription: string | null) => {
          return ifElse(
            equals(8),
            compose(not, either(isEmpty, isNil), always(planTypeDescription)),
            T)(planTypeId);
        },
        message: { planTypeDescription: cobraChecklistPlanErrorMessages.newCarrierName.required },
      });
    })
    .nullable(),
  // see: https://stackoverflow.com/questions/59836052/how-to-validate-enums-in-yup
  carrierUpdateMethod: yup
    .mixed<InsurerUpdateMethod>()
    // @ts-expect-error
    .oneOf(Object.values(InsurerUpdateMethod), {
      carrierUpdateMethod: cobraChecklistPlanErrorMessages.carrierUpdateMethod.invalid,
    })
    .required({ carrierUpdateMethod: cobraChecklistPlanErrorMessages.carrierUpdateMethod.required }),
  carrierUpdateMethodNotes: yup
    .string()
    .when('carrierUpdateMethod', (carrierUpdateMethod: InsurerUpdateMethod | null, schema: any) => {
      return schema.test({
        test: (carrierUpdateMethodNotes: string | null) => ifElse(
          equals(InsurerUpdateMethod.Other),
          compose(not, either(isEmpty, isNil), always(carrierUpdateMethodNotes)),
          T)(carrierUpdateMethod),
        message: { carrierUpdateMethodNotes: cobraChecklistPlanErrorMessages.carrierUpdateMethodNotes.required },
      });
    })
    .nullable(),
  planPolicyNumber: yup
    .string()
    .max(20, { planPolicyNumber: cobraChecklistPlanErrorMessages.planPolicyNumber.max })
    .when('carrierUpdateMethod', (carrierUpdateMethod: InsurerUpdateMethod | null, schema: any) => {
      return schema.test({
        test: (planPolicyNumber: string | null) => ifElse(
          equals(InsurerUpdateMethod.CarrierByTuesdayFile),
          compose(not, either(isEmpty, isNil), always(planPolicyNumber)),
          T)(carrierUpdateMethod),
        message: { planPolicyNumber: cobraChecklistPlanErrorMessages.planPolicyNumber.required },
      });
    })
    .nullable(),
  carrierContactName: yup
    .string()
    .required({ carrierContactName: cobraChecklistPlanErrorMessages.carrierContactName.required })
    .nullable(),
  carrierContactEmail: yup
    .string()
    .required({ carrierContactEmail: cobraChecklistPlanErrorMessages.carrierContactEmail.required })
    .nullable(),
  carrierContactEmailTemp: yup
    .string()
    .required({ carrierContactEmailTemp: cobraChecklistPlanErrorMessages.carrierContactEmail.required })
    .email({ carrierContactEmailTemp: cobraChecklistPlanErrorMessages.carrierContactEmail.invalid })
    .when('carrierContactEmail', (carrierContactEmail: string | null, schema: any) => {
      return schema.test({
        test: (carrierContactEmailTemp: string | null) => ifElse(
          complement(isNil),
          complement(includes(carrierContactEmailTemp)),
          T)(carrierContactEmail),
        message: { carrierContactEmailTemp: cobraChecklistPlanErrorMessages.carrierContactEmail.duplicate },
      });
    })
    .nullable(),
  carrierContactPhone: yup
    .string()
    .test(
      'phone-empty-or-10-chars-check',
      { carrierContactPhone: cobraChecklistPlanErrorMessages.carrierContactPhone.min },
      (phone) => !phone || phone.length >= 14,
    )
    .nullable(),
  confirmRates: yup
    .boolean()
    .required({ confirmRates: cobraChecklistPlanErrorMessages.confirmRates.required })
    .oneOf([ true ], { confirmRates: cobraChecklistPlanErrorMessages.confirmRates.required }),
});
