import { ChangeEvent, ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export interface ReportTypeSelectProps {
  reportTypes: string[];
  value: string | null;
  onReportTypeChanged: (reportType: string) => void;
}

function HistoryReportTypeSelect({
  reportTypes,
  onReportTypeChanged,
  value = '',
}: ReportTypeSelectProps): ReactElement {
  function handleChange({ target: { value: val } }: ChangeEvent<{ name?: string; value: unknown; }>) {
    onReportTypeChanged(val as string);
  }

  return (
    <FormControl variant="standard" className="w-full">
      <InputLabel shrink id="history-report-type-label">Report Type</InputLabel>
      <Select
        label="Report Type"
        labelId="history-report-type-label"
        onChange={handleChange as any}
        value={value}
        native
      >
        <option value="">All</option>
        {
          reportTypes.length > 0
            ? reportTypes.map((opt) => (
              <option value={opt}>{opt}</option>
            ))
            : <option value=""> </option>
        }
      </Select>
    </FormControl>
  );
}

export default HistoryReportTypeSelect;
