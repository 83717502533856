import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { compose, tap, T, F, pick, lensPath, set, replace, or, view } from 'ramda';

import { CobraInitialNoticePayload, NoticeFields } from './cobra-initial-notice.types';
import { Participant } from 'participant-search/participant-list.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { handleSelectChange, handleDateChange } from 'shared/form-helpers';
import { defaultParticipant } from 'shared/constants';
import { useSuccessNotifier, useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { useHistoryBlock } from 'shared/custom-hooks/useHistoryBlock';
import request from 'shared/api-request';
import Loading from 'components/shared/Loading';
import PayrollIdParticipantLookupInput, {
  setParticipantValues,
} from 'components/shared/PayrollIdParticipantLookupInput';
import ParticipantDetails from 'components/shared/ParticipantDetails';
import PageContentPaper from 'components/layout/PageContentPaper';
import PayFreqSelect from 'components/shared/PayFreqSelect';
import CancelModal from 'components/shared/CancelModal';
import { validationSchema } from './cobra-initial-notice.validators';
import { Dependent } from 'shared/types/non-plan-year.types';
import { usePayFrequencies } from 'shared/custom-hooks/usePayFrequencies';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { deepTrim } from '../shared/utils/deep-trim';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const initialValues: NoticeFields = {
  ...defaultParticipant,
  sendToEmployee: true,
  sendToSpouse: false,
  spouseBirthDate: '',
  spouseGender: '',
  spouseName: '',
};

const participantFields = [
  'payrollid',
  'empno',
  'socsec',
  'birthdate',
  'lname',
  'fname',
  'sex',
  'hphone',
  'hstreet1',
  'hstreet2',
  'hcity',
  'hstate',
  'hzip',
  'location',
  'payfreq',
  'payfreq2',
  'email',
];

const noticeFields = [ 'sendToEmployee', 'sendToSpouse', 'spouseName', 'spouseBirthDate', 'spouseGender' ];

function formatDateValue(val?: string) {
  return val ? dayjs(val, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;
}

function displayErrorText(y: boolean) {
  if (y) {
    return (
      <FormHelperText
        disabled
        error
        data-testid="enter-initial-notice-selectError"
      >
          This field is required
      </FormHelperText>
    );
  } else {
    return (<></>);
  }
}

function EnterCobraInitialNotice() {
  const history = useHistory();
  const [ blockRegex ] = useState(/^\/enter-cobra-initial-notice/);
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const formRef = useRef<HTMLDivElement>(null);
  const payFreqs = usePayFrequencies();

  const [ searching, setSearching ] = useState(false);
  const [ wholeFormErrors, setWholeFormErrors ] = useState<string[]>([]);
  const [ saving, setSaving ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);
  const [ hideModal ] = useState(() => compose(setShowModal, F));

  const form = useFormik<NoticeFields>({
    initialValues: {
      ...initialValues,
      birthdate: null,
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        validationSchema.validateSync(
          values,
          {
            abortEarly: false,
            stripUnknown: false,
          },
        );

        const payload = makeNoticePayload(values);
        saveInitialNotice(payload);
      } catch (err) {
        setWholeFormErrors(err.errors);

        if (formRef.current?.scrollIntoView) {
          formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      }
    },
  });
  const setParticipantDetails = setParticipantValues(form.setFieldValue);

  const navUnblock = useHistoryBlock(blockRegex, undefined, form.dirty);

  const genderLabelRef = useRef<HTMLLabelElement>(null);

  const handleError = compose(tap(compose(setSaving, F)), useErrorNotifier());
  const successNotifier = useSuccessNotifier('Initial Notice saved successfully.');

  function handleSearchStarted() {
    setSearching(T);
    form.setFieldValue('empno', '');
    form.setFieldValue('socsec', '');
  }

  const PayFreqs = withLoopIcon(
    PayFreqSelect,
    'Loading pay frequencies...',
    payFreqs === null,
    'init-notice-loading-pay-freqs',
  );

  function makeNoticePayload(values: NoticeFields): CobraInitialNoticePayload {
    let payload = {
      ...pick(noticeFields, values),
      participant: pick(participantFields, values),
    };
    let pidLens = lensPath([ 'participant', 'payrollid' ]);

    return set(
      pidLens,
      replace(/-+/g, '', or(view(pidLens, payload), '')),
      payload,
    );
  }

  function navigateAway() {
    if (navUnblock) {
      navUnblock();
    }

    hideModal();
    history.goBack();
  }

  function saveInitialNotice(values: CobraInitialNoticePayload) {
    setSaving(true);
    const uri = `/api/companies/${compid}/initial-notices`;

    request(uri, { method: 'POST', body: deepTrim(values) })
      .then(() => {
        form.resetForm();
        setSaving(false);
        successNotifier();
      })
      .catch(handleError);
  }

  function onCancel() {
    if (form.dirty) {
      setShowModal(T);
    } else {
      history.goBack();
    }
  }

  function handleParticipant(part: Participant) {
    const { sendToEmployee, sendToSpouse } = form.values;
    const participant = pick(participantFields, { birthdate: null, ...part });
    setParticipantDetails(participant);

    const spouse = part.dependents?.find((d: Dependent) => d.relation === 'S');

    if (spouse) {
      form.setFieldValue('spouseName', spouse.name, false);
      form.setFieldValue('spouseBirthDate', spouse.dateOfBirth ?? '', false);
      form.setFieldValue('spouseGender', spouse.sex ?? '', false);
    }

    form.setFieldValue('sendToEmployee', sendToEmployee);
    form.setFieldValue('sendToSpouse', sendToSpouse);

    setSearching(false);
  }

  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">Enter COBRA Initial Notice</h2>
      <div className="w-full md:w-2/3">
        <PageContentPaper className="pt-10">
          <form data-testid="enter-initial-notice-form" className="px-8">
            <>
              <div className="flex flex-col space-y-8" ref={formRef}>
                <div className="w-full">
                  <PayrollIdParticipantLookupInput
                    className="w-1/2"
                    data-testid="enter-initial-notice-participantLookup"
                    onSearchStarted={handleSearchStarted}
                    onParticipantFound={handleParticipant}
                    name="payrollid"
                    value={form.values.payrollid ?? ''}
                    error={form.touched.payrollid && !!form.errors.payrollid}
                    helperText={form.touched.payrollid && form.errors.payrollid}
                    onTextChanged={form.handleChange('payrollid')}
                  />
                </div>

                <div className="w-full flex flex-col">
                  {
                    wholeFormErrors.length
                      ? wholeFormErrors.map((msg, i) => (
                        <FormHelperText
                          className="text-base pb-5"
                          key={i}
                          disabled
                          error
                        >
                          {msg}
                        </FormHelperText>
                      ))
                      : <></>
                  }
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sendToEmployee"
                          color="primary"
                          value
                          checked={form.values.sendToEmployee}
                          onChange={form.handleChange}
                          className="enter-initial-notice-send-employee"
                        />
                      }
                      classes={ { label: 'text-base' } }
                      label="Send notice to employee"
                      labelPlacement="end"
                    />
                  </FormControl>

                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sendToSpouse"
                          color="primary"
                          value
                          checked={form.values.sendToSpouse}
                          onChange={form.handleChange}
                          className="enter-initial-notice-send-spouse"
                        />
                      }
                      classes={ { label: 'text-base' } }
                      label="Send notice to spouse"
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>

                <ParticipantDetails form={form} />

                <div className="flex">
                  <TextField
                    className="w-1/2"
                    variant="outlined"
                    name="email"
                    label="Email Address"
                    value={form.values.email ?? ''}
                    error={form.touched.email && !!form.errors.email}
                    helperText={form.touched.email && form.errors.email}
                    onChange={form.handleChange('email')}
                    fullWidth
                    data-testid="enter-initial-notice-email"
                  />

                  <div className="w-1/2 ml-4">
                    <PayFreqs
                      payFreqs={payFreqs}
                      isRequired={false}
                      name="payfreq"
                      label="Pay Frequency"
                      value={form.values.payfreq ?? ''}
                      error={form.touched.payfreq && !!form.errors.payfreq}
                      onPayFreqChanged={form.handleChange('payfreq')}
                      data-testid="enter-initial-notice-payfreq"
                    />
                  </div>
                </div>

                <div className="flex">
                  <TextField
                    className="w-1/2"
                    variant="outlined"
                    name="spouseName"
                    label="Spouse Name (Optional)"
                    value={form.values.spouseName ?? ''}
                    error={form.touched.spouseName && !!form.errors.spouseName}
                    helperText={form.touched.spouseName && form.errors.spouseName}
                    onChange={form.handleChange('spouseName')}
                    fullWidth
                    data-testid="enter-initial-notice-spouseName"
                  />

                  <div className="flex flex-row w-1/2 ml-4">
                    <LocalizationProvider dateAdapter={AdapterDayJS}>
                      <DatePicker
                        label="Spouse Date of Birth (Optional)"
                        value={formatDateValue(form.values.spouseBirthDate)}
                        onChange={handleDateChange('spouseBirthDate', form)}
                        inputFormat="MM/DD/YYYY"
                        InputProps={
                          {
                            className: !form.values.spouseBirthDate ? 'MuiFormLabel-root' : '',
                            tabIndex: -1,
                          }
                        }
                        // eslint-disable-next-line react/jsx-no-bind
                        renderInput={(params) => (
                          <TextField
                            data-testid="enter-initial-notice-spouse-dob"
                            variant="outlined"
                            className="w-full"
                            {...params}
                            error={form.touched.spouseBirthDate && Boolean(form.errors.spouseBirthDate)}
                            helperText={form.touched.spouseBirthDate && form.errors.spouseBirthDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <FormControl variant="outlined" className="w-1/2">
                  <InputLabel ref={genderLabelRef} id="spouse-gender-select-label">Spouse Gender (Optional)</InputLabel>
                  <Select
                    label="Spouse Gender (Optional)"
                    onChange={handleSelectChange('spouseGender', form)}
                    value={form.values.spouseGender}
                    native
                    fullWidth
                    data-testid="enter-initial-notice-spouse-gender"
                    className={!form.values.spouseGender ? 'MuiFormLabel-root' : ''}
                    error={form.touched.spouseGender && Boolean(form.errors.spouseGender)}
                    tabIndex={-1}
                  >
                    <option />
                    <option value="F">Female</option>
                    <option value="M">Male</option>
                  </Select>
                  {displayErrorText(!!form.errors.spouseGender)}
                </FormControl>

              </div>
              <div className="flex justify-end my-4">
                <Button
                  color="primary"
                  className="enter-initial-notice-cancel"
                  onClick={onCancel}
                >
                Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={form.submitForm}
                  className="ml-4 enter-initial-notice-save"
                  disabled={searching || (!!Object.values(form.touched).length && !!Object.values(form.errors).length)}
                >
                Save Entry
                </Button>
              </div>
            </>
          </form>
        </PageContentPaper>
      </div>
      {saving && <Loading text="Saving Initial Notice..." />}
      <CancelModal
        isOpen={showModal}
        onCancel={navigateAway}
        onClose={hideModal}
        title="Are you sure you want to navigate away?"
      />
    </div>
  );
}

export default EnterCobraInitialNotice;
