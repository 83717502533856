import { useState, ReactElement, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { always, compose, andThen, pipe } from 'ramda';

import PageContentPaper from 'components/layout/PageContentPaper';
import { currencyFormatter } from 'shared/utils';
import { ContributionsNotForwarded } from './contributions-not-forwarded.types';
import { fetchParticipant } from 'participant-search/participant.thunks';
import store from 'store';
import { setFetchParticipantStatus } from 'participant-search/participant.slice';
import { Participant } from 'participant-search/participant-list.types';
import { ApiStatus } from 'shared/types/api-status.types';

function createData({
  employeeNumber,
  payrollId,
  name,
  amount,
  status,
  missingInfo,
  contributions,
}: ContributionsNotForwarded) {
  return {
    employeeNumber,
    payrollId,
    name,
    amount,
    status,
    missingInfo,
    contributions,
  };
}

type Props = {
  rows: ContributionsNotForwarded[];
}

function ContributionsNotForwardedTable({ rows }: Props): ReactElement {
  let data: ReturnType<typeof createData>[] = rows.map(createData);

  return (
    <PageContentPaper className="m-4" data-testid="contributions-not-forwarded">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>PID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Contributions Not Forwarded</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContentPaper>
  );
}

function StatusWarning({ children }: PropsWithChildren<Object>) {
  return (
    <div className="py-4 missing-info">
      <Alert severity="error">
        { children }
      </Alert>
    </div>
  );
}

type UnwrapFunction<T> = () => Promise<T>;
type DispatchWithUnwrap = {unwrap: UnwrapFunction<Participant>;};

function Row(props: { row: ReturnType<typeof createData>; }) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();
  const { row } = props;
  const [ open, setOpen ] = useState(false);

  let resetFetchStatus = compose<ApiStatus,PayloadAction<ApiStatus>,DispatchWithUnwrap>(
    dispatch,
    setFetchParticipantStatus,
    always<ApiStatus>('uninitialized'),
  );

  function navigateToOverview(participant: Participant) {
    resetFetchStatus();
    history.push(`/participant-overview/${participant.empno}/general-info`);
  }

  let doUnwrap = ({ unwrap } : DispatchWithUnwrap) => unwrap();

  const makeStatusWarning = () => {
    if (row.missingInfo?.length) {
      return (
        <StatusWarning>
          This participant is missing information that is required to set up their HSA Account.
          Please click
          <span
            className="text-primary-main cursor-pointer participant-link"
            onClick={pipe(
              always(row.payrollId),
              fetchParticipant,
              dispatch,
              doUnwrap,
              andThen(navigateToOverview),
            )}
          > here
          </span> and confirm the participant has a full mailing address (no PO Box),
          Date of Birth, Email Address, Phone Number, and HSA enrollment. This data is currently
          missing, and is required to set up an account.
        </StatusWarning>
      );
    } else if (!row.missingInfo?.length && row.status === 'Not Sent To WealthCare Saver') {
      return (
        <StatusWarning>
          This participant's account cannot currently be established with our HSA partner. Please contact
          your account manager for more information.
        </StatusWarning>
      );
    } else if (row.status === 'CIP Testing Not Complete') {
      return (
        <StatusWarning>
          This participant needs to complete CIP Testing before their account can be marked as active.
          They can do so by logging in to our website,
          <a href="https://www.flores247.com" className="text-blue-600"> https://www.flores247.com</a>,
          and accessing their HSA account for more information. Learn more about CIP Testing by clicking
          <a href="/CIP Verification.pdf" target="_blank" className="text-blue-600"> here</a>.
        </StatusWarning>
      );
    } else if (row.status === 'CIP Testing Failed-Participant must contact Flores') {
      return (
        <StatusWarning>
          This participant has failed CIP testing, and will need to contact Flores directly before their
          account can be opened. They can do so by calling 800-532-3327 or emailing
          <a href="mailto:customerservice@flores247.com"> customerservice@flores247.com</a>.
          Learn more about CIP Testing by clicking
          <a href="/CIP Verification.pdf" target="_blank" className="text-blue-600"> here</a>.
        </StatusWarning>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <TableRow className="table-row" sx={ { '& > *': { borderBottom: 'unset' } } }>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={compose(setOpen, always(!open))}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.employeeNumber}
        </TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{currencyFormatter.format(row.amount)}</TableCell>
        <TableCell align="right">
          <span
            className="text-primary-main cursor-pointer status"
            onClick={compose(setOpen, always(!open))}
          >{ row.status }
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={ { paddingBottom: 0, paddingTop: 0 } } colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={ { margin: 1 } }>
              {makeStatusWarning()}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Deduction Date</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.contributions.map((contributionRow, i) => (
                    <TableRow key={i} className="sub-table-row">
                      <TableCell component="th" scope="row">
                        {dayjs(contributionRow.deductionDate).format('MM/DD/YYYY')}
                      </TableCell>
                      <TableCell>{currencyFormatter.format(contributionRow.amount)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ContributionsNotForwardedTable;
