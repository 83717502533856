import { useHistory } from 'react-router-dom';
import { always, compose } from 'ramda';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import hraIcon from 'images/hra-round.svg';
import { ReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import { ChecklistCard } from 're-enrollment-checklist/ChecklistCard';
import setLegacyChecklistCompletionProgress from 'shared/utils/set-legacy-checklist-completion-progress';

function HraPlanCard ({ plan }: { plan: ReEnrollmentChecklistPlan; }) {
  const icon = (
    <CardMedia
      component="img"
      src={hraIcon}
      title="HRA"
    />
  );

  const history = useHistory();
  const buttons = (
    <div className="justify-start" data-testid="hra-plan-buttons">
      <Button
        variant="outlined"
        className="mr-5"
        data-testid="hra-link"
        onClick={compose(
          history.push,
          always(`/reenrollment-checklist/hra/${plan.planYearId}`),
        )}
      >
        <div className="justify-start">
          <div>Health Reimbursement Account</div>
        </div>
      </Button>
    </div>
  );

  return (
    <ChecklistCard
      icon={icon}
      title="Health Reimbursement Account"
      planYearStart={plan.planYearStart}
      planYearEnd={plan.planYearEnd}
      buttons={buttons}
      progress={setLegacyChecklistCompletionProgress(plan.legacyChecklistComplete)}
    />
  );
}

export default HraPlanCard;

