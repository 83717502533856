import request from 'shared/api-request';
import { CobraReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';

export function downloadDocument(companyId: number, planYearId: string, fileName: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download document for invalid company.'));
  }

  return request(`/api/companies/${companyId}/reenrollment-checklist/plan-year/${planYearId}/cobra/file/${fileName}`);
}

export function deleteDocument(
  { companyId, planYearId, fileName }: {companyId: number; planYearId: string; fileName: string;},
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot delete document for invalid company.'));
  }

  return request<CobraReEnrollmentChecklistPlan>(
    `/api/companies/${companyId}/reenrollment-checklist/plan-year/${planYearId}/cobra/file/${fileName}`,
    { method: 'DELETE' },
  );
}

export function createOeDocuments({ companyId, planYearId }: {companyId: number; planYearId: string;}) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot generate OE documents for invalid company.'));
  }

  if (+planYearId <= 0) {
    return Promise.reject(new Error('Cannot generate OE documents for invalid plan year.'));
  }

  return request<CobraReEnrollmentChecklistPlan>(
    `/api/companies/${companyId}/reenrollment-checklist/plan/${planYearId}/generate/oe-forms`,
    { method: 'POST' },
  );
}
