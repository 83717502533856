import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EnrollmentResourcePlan } from 'open-enrollment-resources/open-enrollment.types';
import { RootState } from 'store/rootReducer';
import { AppThunk } from 'store';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { getCompanyOpenEnrollment } from 'shared/api/enrollmentResourcesApi';
import { assertIsDefined } from 'shared/asserts';

export interface EnrollmentResourcesState {
  plans: EnrollmentResourcePlan[] | null;
}

export const initialState: EnrollmentResourcesState = {
  plans: [],
};

const openEnrollmentResourcesSlice = createSlice({
  name: 'enrollmentResources',
  initialState,
  reducers: {
    setEnrollmentResourcePlans(state, { payload }: PayloadAction<EnrollmentResourcePlan[] | null>) {
      state.plans = payload;
    },
  },
});

export const {
  setEnrollmentResourcePlans,
} = openEnrollmentResourcesSlice.actions;

export default openEnrollmentResourcesSlice.reducer;

// thunk
export function loadEnrollmentResourcePlans(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setEnrollmentResourcePlans(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const plans = await getCompanyOpenEnrollment(selectedCompany.compid);
      dispatch(setEnrollmentResourcePlans(plans));
    } catch (ex) {
      dispatch(setEnrollmentResourcePlans([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectEnrollmentResourcePlans = (state: RootState): EnrollmentResourcePlan[] | null => {
  return state.enrollmentResources.plans;
};
