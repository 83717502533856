import AdapterDayJS from '@mui/lab/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { ErrorMessage, FormikContextType } from 'formik';

import { handleDateChange } from 'shared/form-helpers';

interface Props {
  className?: string;
  label: string;
  path: string;
  value: string;
  minDate?: unknown;
  maxDate?: unknown;
  form: FormikContextType<any>;
  hasError: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
}

export function FormikMuiDatePicker({
  className,
  label,
  path,
  value,
  minDate,
  maxDate,
  form,
  hasError,
  handleChange,
  handleBlur,
}: Readonly<Props>): React.ReactElement {
  return (
    <div className={className}>
      <LocalizationProvider dateAdapter={AdapterDayJS}>
        <DatePicker
          label={label}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleDateChange(path, form)}
          InputProps={ { tabIndex: -1 } }
          InputAdornmentProps={ { classes: { positionEnd: 'ml-1' } } }
          // eslint-disable-next-line react/jsx-no-bind
          renderInput={(params) => (
            <TextField
              data-testid={path}
              fullWidth
              variant="outlined"
              name={path}
              {...params}
              error={hasError}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
        />
      </LocalizationProvider>
      <ErrorMessage
        className="text-sm text-red-600 pl-4 pt-1"
        component="div"
        name={path}
      />
    </div>
  );
}
