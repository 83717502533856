import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { selectDirectBillingDetails } from 'direct-billing/direct-billing.slice';

interface DefinitionProps {
  term: string;
  def?: string | number;
  defClass?: string;
}

function Definition({ term, def = '', defClass = '' }: DefinitionProps) {
  return (
    <div className="flex space-x-2">
      <dt>{term}:</dt>
      <dd className={`font-bold ${defClass}`}>{def}</dd>
    </div>
  );
}

function DirectBillingQeDetails() {
  const details = useSelector(selectDirectBillingDetails);

  function formatDate(str?: string) {
    return str ? dayjs(str, 'YYYY-MM-DD').format('M/D/YYYY') : '';
  }

  function statusColor(status: string) {
    if (/^terminated/i.test(status)) {
      return 'text-red-600';
    }

    if (/^accepted/i.test(status)) {
      return 'text-green-500';
    }

    return '';
  }

  return (
    <div className="w-full p-6 border border-solid border-neutral5 rounded-md">
      <dl className="space-y-6 text-base text-med-em">
        <Definition term="Status" def={details?.statusMessage} defClass={statusColor(details?.statusMessage ?? '')} />
        <Definition term="Date Letter Sent" def={formatDate(details?.letterSent)} />
        <Definition term="Letter Doc #" def={details?.letterDoc} />
        <Definition term="Resp Postmark" def={formatDate(details?.postmark)} />
        <Definition term="Response Doc #" def={details?.responseDoc} />
        <Definition term="Cover Ends" def={formatDate(details?.coverageEnds)} />
        <Definition term="Coverage Term Sent" def={formatDate(details?.coverageTermSent)} />
      </dl>
    </div>
  );
}

export default DirectBillingQeDetails;
