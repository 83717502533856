import { ReactElement, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import { Dependent, QeDependents } from 'shared/types/non-plan-year.types';
import DependentsTable from './DependentsTable';
import AddNewDependent from './add-new-dependent/AddNewDependent';
import styles from './Dependents.module.css';
import { noop } from 'shared/utils';
import { ChangeNotifier } from 'shared/types/form.types';

interface DependentsProps extends ChangeNotifier {
  initialAddedDependents?: Dependent[];
  dependents?: Dependent[];
  submitText: string;
  onNextStep: (data: QeDependents) => void;
  cancelText?: string;
  saving?: boolean;
  onCancel?: () => void;
}

function Dependents({
  dependents = [],
  initialAddedDependents = [],
  onNextStep,
  onCancel,
  onHasChanged = noop,
  submitText,
  saving,
  cancelText = 'Cancel',
}: DependentsProps): ReactElement {
  const [ openAddDependentModal, setOpenAddDependentModal ] = useState(false);
  const [ addedDependents, setAddedDependents ] = useState<Dependent[]>(initialAddedDependents);

  function handleDelete(dependent: Dependent) {
    const newDependents = addedDependents.filter((d) => {
      return d.name !== dependent.name ||
        d.relation !== dependent.relation ||
        d.dateOfBirth !== dependent.dateOfBirth ||
        d.ssn !== dependent.ssn ||
        d.dateOfBirth !== dependent.dateOfBirth;
    });
    setAddedDependents(newDependents);
  }

  function handleNext() {
    onNextStep({ initialDependents: dependents, addedDependents });
    onHasChanged(false);
    // Reset added dependents for internally tracked state to remain in sync with parent
    setAddedDependents([]);
  }

  function handleNewDependentClicked() {
    setOpenAddDependentModal(true);
  }

  function handleAddDependent(dep: Dependent) {
    setAddedDependents([ ...addedDependents, dep ]);
    onHasChanged(true);
    setOpenAddDependentModal(false);
  }

  function handleCancelAddDependent() {
    setOpenAddDependentModal(false);
  }

  function totalDependents() {
    return dependents.length + addedDependents.length;
  }

  function canAddDependents() {
    return totalDependents() < 10;
  }

  function addDependentTooltip() {
    return canAddDependents() ? '' : 'No more than 10 dependents can be added.';
  }

  return (
    <div data-testid="dependents-container">
      <div className="flex justify-end">
        <Tooltip title={addDependentTooltip()}>
          <span>
            <Button
              variant="outlined"
              color="primary"
              className="p-2"
              onClick={handleNewDependentClicked}
              data-testid="addDependent"
              disabled={!canAddDependents()}
            >
              <AddIcon className="mr-2" />
                  New Dependent
            </Button>
          </span>
        </Tooltip>
      </div>
      <div className="flex flex-row pr-4">
        <DependentsTable
          initialDependents={dependents}
          addedDependents={addedDependents}
          onDependentDeleted={handleDelete}
        />
      </div>
      <div className="flex justify-end my-4">
        { onCancel ?
          <Button
            color="primary"
            onClick={onCancel}
          >
            {cancelText}
          </Button> : '' }

        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          data-testid="cobraQeNextStep"
          className="ml-4"
          disabled={saving}
        >
          {submitText}
        </Button>
        <AddNewDependent
          title="Add Dependent"
          isOpen={openAddDependentModal}
          data-testid="addDependentModal"
          onClose={handleCancelAddDependent}
          onSaveDependent={handleAddDependent}
          onCancel={handleCancelAddDependent}
          classes={ {
            modalContainer: `${styles.modal} absolute left-1/2 text-center`,
          } }
        />
      </div>
    </div>
  );
}

export default Dependents;
