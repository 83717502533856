import request from 'shared/api-request';
import { CobraInitialNotice } from 'components/shared/notice-list/notice-list.types';

export function getCobraPendingInitialNotices(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get COBRA pending initial notices for invalid company.'));
  }

  return request<CobraInitialNotice[]>(`/api/companies/${companyId}/reports/pending-notices/initial`);
}
