import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useParams } from 'react-router';
import { setPageTitle } from 'components/app-settings.slice';
import store from 'store';
import { useEffect } from 'react';
import { setSelectedParticipant } from 'participant-search/participant.slice';

function LegacyChecklists() {
  const { compid: companyId, medplancd: medPlanCode } = useSelector(selectSelectedCompany)
    ?? { compid: 0, medplancd: '' };
  const { planYearId = '0', planType = '0' } = useParams<{planYearId?: string; planType: string;}>();
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    dispatch(setPageTitle(''));
    dispatch(setSelectedParticipant(null));
  }, [ dispatch ]);

  return (
    <div className="h-screen">
      <iframe
        // eslint-disable-next-line max-len
        src={`/reenroll/reenrollment.html?planId=${medPlanCode}&compid=${companyId}&pyrid=${planYearId}&plantype=${planType}`}
        className="w-full h-full"
        title={`${planType.toUpperCase} checklist`}
        data-testid="hsa-checklist"
        // eslint-disable-next-line max-len
        sandbox="allow-scripts allow-forms allow-top-navigation allow-top-navigation-by-user-activation allow-same-origin"
      />
    </div>
  );
}

export default LegacyChecklists;

