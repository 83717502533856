import request, { ContentType } from 'shared/api-request';
import {
  FmlaCompanyIdAndDates,
  FmlaCompanyIdDatesAndReportType,
} from 'reports/fmla/fmla-report-types';

export function getAllFmlaReportsCountsForDashboard(reportData: FmlaCompanyIdAndDates) {
  if (reportData.compid === 0) {
    return Promise.reject(new Error('Cannot retrieve FMLA data for invalid company'));
  }

  return Promise.allSettled([
    getReportCount({ ...reportData, reportType: 'open-cases' }),
    getReportCount({ ...reportData, reportType: 'intermittent-leave-cases' }),
    getReportCount({ ...reportData, reportType: 'action-needed' }),
    getReportCount({ ...reportData, reportType: 'rtw-confirmation-needed' }),
  ]).then(([
    allOpenLeaveCaseListCount,
    intermittentLeaveCaseListCount,
    pendingInfoCount,
    returnToWorkConfirmationCount,
  ]) => {
    return {
      allOpenLeaveCaseListCount: allSettledUnwrap(allOpenLeaveCaseListCount),
      intermittentLeaveCaseListCount: allSettledUnwrap(intermittentLeaveCaseListCount),
      pendingInfoCount: allSettledUnwrap(pendingInfoCount),
      returnToWorkConfirmationCount: allSettledUnwrap(returnToWorkConfirmationCount),
    };
  });
}

export function getAllDateFilterableFmlaReports(reportData: FmlaCompanyIdAndDates) {
  if (reportData.compid === 0) {
    return Promise.reject(new Error('Cannot retrieve FMLA data for invalid company'));
  }

  return Promise.allSettled([
    getReportCount({ ...reportData, reportType: 'all-absences' }),
    getReportCount({ ...reportData, reportType: 'usage-totals' }),
    getReportCount({ ...reportData, reportType: 'all-leave-cases' }),
  ]).then(([
    allAbsencesListCount,
    usageTotalsCount,
    allLeaveCases,
  ]) => {
    return {
      allAbsencesListCount: allSettledUnwrap(allAbsencesListCount),
      usageTotalsCount: allSettledUnwrap(usageTotalsCount),
      allLeaveCases: allSettledUnwrap(allLeaveCases),
    };
  });
}

export function downloadFmlaExcel({
  compid,
  reportType,
  startDate = '',
  endDate = '',
}: FmlaCompanyIdDatesAndReportType) {
  if (compid === 0) {
    return Promise.reject(new Error('Cannot retrieve FMLA data for invalid company'));
  }

  const uri = `api/companies/${compid}/fmla/reports/${reportType}/xlsx`;

  return request(
    uri,
    {
      method: 'GET',
      headers: { accept: ContentType.XLSX },
      queryParams: {
        startDate,
        endDate,
      },
    });
}

function getReportCount({
  compid,
  reportType,
  endDate = '',
  startDate = '',
}: FmlaCompanyIdDatesAndReportType) {
  const uri = `api/companies/${compid}/fmla/reports/${reportType}/count`;

  return request<number>(
    uri,
    {
      method: 'GET',
      queryParams: {
        startDate,
        endDate,
      },
    });
}

const allSettledUnwrap = (res: PromiseSettledResult<number | null>): number | null => {
  return res.status === 'fulfilled'
    ? res.value
    : null;
};
