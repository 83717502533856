import MenuItem from '@mui/material/MenuItem';
import AddParticipantIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router';
import { compose, always } from 'ramda';

function AddParticipantItem() {
  const history = useHistory();

  function handleAddParticipant() {
    compose(history.push, always('/add-participant'))();
  }

  return (
    <MenuItem
      className="flex justify-start w-full flex-nowrap space-x-3 participant-search-add-participant"
      onClick={handleAddParticipant}
    >
      <AddParticipantIcon className="w-11 h-11" color="disabled" />
      <p className="flex-grow text-neutral3">Add New Participant</p>
    </MenuItem>
  );
}

export default AddParticipantItem;
