import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { NonPlanYearInvoiceDetail } from './view-invoices.types';
import { currencyFormatter } from 'shared/utils';

interface InvoiceDetailTableRowProps {
  invoiceDetail: NonPlanYearInvoiceDetail;
}

function InvoiceDetailTableRow({ invoiceDetail }: InvoiceDetailTableRowProps): React.ReactElement {
  return (
    <TableRow className="invoice-detail-row">
      <TableCell>{invoiceDetail.planName}</TableCell>
      <TableCell>{invoiceDetail.levelDescription}</TableCell>
      <TableCell>{currencyFormatter.format(invoiceDetail.detailAmount)}</TableCell>
    </TableRow>
  );
}

export default InvoiceDetailTableRow;
