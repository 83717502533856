import React from 'react';
import Paper from '@mui/material/Paper';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import AgentAvatar from './AgentAvatar';
import styles from './AccountManagerTile.module.css';

interface AccountManagerTileProps {
  agentName?: string;
  email?: string;
  className?: string;
  title: string;
  phone?: string;
}

function AccountManagerTile({
  agentName = '',
  email = '',
  className,
  title,
  phone = '',
}: Readonly<AccountManagerTileProps>): React.ReactElement {
  return (
    <Paper elevation={1} className={`${className} ${styles.accountManagerTile} relative`}>
      <div className={`${styles.gradient} rounded-t w-full absolute block top-0 left-0`} />

      <div className="flex flex-col justify-start h-full">
        <div className="flex justify-center mt-20">
          <AgentAvatar agentName={agentName} />
        </div>

        <div className="flex-grow flex flex-col justify-center">
          <p className="text-center">{title}</p>
          <p className="text-black text-center" data-testid="am-name">{agentName}</p>
        </div>

        <div className={`${styles.tileFooter} flex justify-center m-4 pt-4 space-x-4`}>
          <a href="tel:800.532.3327" className="" title="Call 800.532.3327">
            <PhoneIcon className="w-8 text-black" />
          </a>
          <div className="text-black w-full" data-testid="am-phone">{phone}</div>
          <a href={`mailto:${email}`} className="" title={`Email ${email}`} data-testid="am-email">
            <MailOutlineIcon className="w-8 text-black" />
          </a>
        </div>
      </div>
    </Paper>
  );
}

export default AccountManagerTile;
