import request, { ContentType } from '../api-request';

export function downloadNoResponseWithAddressReport(
  companyId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download no response with address report for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/no-response-with-address/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function getNoResponseWithAddressCount(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load no response with address for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/no-response-with-address/count`;

  return request<number>(uri);
}
