import request from '../api-request';
import { LibraryDocument, EducationDocument } from 'document-library/document-library.types';

export function getForms(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get forms documents for invalid company.'));
  }

  return request<LibraryDocument[]>(`/api/companies/${companyId}/document-library/forms`);
}

export function getDocumentLibraryEducation(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get education documents for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/document-library/education`;

  return request<EducationDocument[]>(uri);
}

export function getLegalDocuments(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get legal documents for invalid company.'));
  }

  return request<LibraryDocument[]>(`/api/companies/${companyId}/document-library/legal-docs`);
}
