import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CobraQENotice } from 'components/shared/notice-list/notice-list.types';
import { RootState } from 'store/rootReducer';
import { assertIsDefined } from 'shared/asserts';
import { AppThunk } from 'store';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { getCobraPendingQeList } from 'shared/api/cobraQeApi';

export interface CobraPendingQeNoticesState {
  notices: CobraQENotice[] | null;
}

export const initialState: CobraPendingQeNoticesState = {
  notices: [],
};

const cobraPendingQeNoticesSlice = createSlice({
  name: 'cobraPendingQeNotices',
  initialState,
  reducers: {
    setPendingQeNotices(state, { payload }: PayloadAction<CobraQENotice[] | null>) {
      state.notices = payload;
    },
  },
});

export const {
  setPendingQeNotices,
} = cobraPendingQeNoticesSlice.actions;

export default cobraPendingQeNoticesSlice.reducer;

// thunk
export function loadPendingQeNotices(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setPendingQeNotices(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const notices = await getCobraPendingQeList(selectedCompany.compid);
      dispatch(setPendingQeNotices(notices));
    } catch (ex) {
      dispatch(setPendingQeNotices([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectPendingQeNotices = (state: RootState): CobraQENotice[] | null => state.cobraPendingQeNotices.notices;
