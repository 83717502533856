import { Dayjs } from 'dayjs';
import request from '../api-request';
import { NonPlanYearServiceSummaryReportResponse, NonPlanYearServicesResponse } from 'reports-tile/reports-tile.types';

export function getNonPlanYearServiceSummaryReport(apiUri: string, startDate: Dayjs, endDate: Dayjs) {
  return request<NonPlanYearServiceSummaryReportResponse>(apiUri, {
    method: 'GET',
    queryParams: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    },
  });
}

export function getNonPlanYearServicesSummary(compid: number | undefined, startDate: Dayjs, endDate: Dayjs) {
  if (!compid) {
    return Promise.reject(new Error('Invalid compid.'));
  }
  const apiUri = `/api/companies/${compid}/reports/non-plan-year-services-summary`;

  return request<NonPlanYearServicesResponse>(apiUri, {
    method: 'GET',
    queryParams: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    },
  });
}
