import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { DBBenefit } from 'direct-billing/direct-billing.types';
import { getNoBenefitsMessage } from 'shared/api/cobraQeApi';
import { selectDirectBillingEventInfo } from 'direct-billing/direct-billing.slice';
import BenefitsFormSection from 'components/shared/qe/BenefitsFormSection';

interface BenefitsFormProps {
  benefits: DBBenefit[];
}

function BenefitsForm({ benefits }: BenefitsFormProps) {
  const { coverageBegins } = useSelector(selectDirectBillingEventInfo);

  function slug(str = performance.now().toString()) {
    return str.toLocaleLowerCase().replace(/\s/g, '-');
  }

  return (
    <div>
      {
        benefits.length
          ? benefits.map((b, i) => <BenefitsFormSection benefit={b} index={i} key={slug(b.planTypeName)} />)
          : <p>{getNoBenefitsMessage(dayjs(coverageBegins).format('MM/DD/YYYY'))}</p>
      }
    </div>
  );
}

export default BenefitsForm;
