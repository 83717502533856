import React from 'react';

import InvoicesTable from './InvoicesTable';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import withModal from 'components/shared/HOC/WithModal';
import { useInvoices } from './useInvoices';

interface ViewInvoicesProps {
  isCobra: boolean;
}

function ViewInvoices({ isCobra }: ViewInvoicesProps): React.ReactElement {
  const invoices = useInvoices(isCobra);

  const Invoices = withLoopIcon(
    InvoicesTable,
    'Loading invoices...',
    invoices === null,
  );

  return <Invoices invoices={invoices} isCobra={isCobra} />;
}

export const InvoicesModal = withModal<ViewInvoicesProps>(ViewInvoices);

export default ViewInvoices;
