import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { always, cond, equals, T } from 'ramda';
import TableContainer from '@mui/material/TableContainer';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import store from 'store';
import {
  selectContributionsNotForwarded,
  selectContributionsNotForwardedFetchStatus,
} from 'action-items/action-items.slice';
import { selectSelectedCompany } from 'companies/companies.slice';
import ContributionsNotForwardedTable
  from 'reports/shared/ContributionsNotForwardedTable/ContributionsNotForwardedTable';
import PageContentPaper from 'components/layout/PageContentPaper';
import { fetchHsaContributionsNotForwarded } from 'action-items/action-items.thunks';
import { selectFetchParticipantStatus } from 'participant-search/participant.slice';
import Loading from 'components/shared/Loading';

function ContributionsNotForwarded() {
  const { compid: companyId } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const contributionsNotForwarded = useSelector(selectContributionsNotForwarded);
  const fetchStatus = useSelector(selectContributionsNotForwardedFetchStatus);
  const fetchParticipantStatus = useSelector(selectFetchParticipantStatus);

  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    if (fetchStatus === 'uninitialized') {
      dispatch(fetchHsaContributionsNotForwarded(companyId));
    }
  }, [ dispatch, companyId, fetchStatus ]);

  return (
    <div className="w-4/5">
      {fetchParticipantStatus === 'pending' ? <Loading /> : <></>}
      {cond<string, ReactElement>(
        [
          [ equals('fulfilled'), always(
            <ContributionsNotForwardedTable rows={contributionsNotForwarded} />,
          ) ],
          [ T, always(
            <PageContentPaper className="m-4">
              <TableContainer component={Paper}>
                <Skeleton
                  data-testid="loading"
                  variant="rectangular"
                  height={70}
                  style={ { marginBottom: '0.1rem' } }
                />
                <Skeleton
                  data-testid="loading"
                  variant="rectangular"
                  height={70}
                  style={ { marginBottom: '0.1rem' } }
                />
                <Skeleton
                  data-testid="loading"
                  variant="rectangular" height={70}
                />
              </TableContainer>
            </PageContentPaper>,
          ) ],
        ],
      )(fetchStatus)}
    </div>
  );
}

export default ContributionsNotForwarded;
