import * as yup from 'yup';
import dayjs from 'dayjs';

import { fname, lname, ssn } from 'shared/common-validators';

export const validationSchema = yup.object({
  dateOfBirth: yup
    .date()
    .transform(
      (value, originalValue) => dayjs(originalValue).toDate(),
    )
    .max(dayjs(), 'Birthdate must be before today')
    .typeError('Please enter a valid date')
    .nullable(),
  relation: yup
    .string(),
  sex: yup
    .string(),
  ...ssn,
  ...fname,
  ...lname,
});
