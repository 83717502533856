import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { always, compose, F, ifElse, T, when } from 'ramda';

import { setEventTypes } from 'enter-cobra-qe/cobra-qe.slice';
import { handleDateChange } from 'shared/form-helpers';
import EventSelect from './EventTypeSelect';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { useEventTypes } from 'shared/custom-hooks/useEventTypes';
import { StateContinuationModal } from './StateContinuation';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import store from 'store';
import Skeleton from '@mui/material/Skeleton';

interface EventInfoFormProps {
  letterSent?: boolean;
  respPostmark?: boolean;
}

function EventInfoForm({ letterSent = false, respPostmark = false }: EventInfoFormProps) {
  const form = useFormikContext<any>();
  const events = useEventTypes();
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    dispatch(setEventTypes(events ?? []));
  }, [ events, dispatch ]);

  const EventSelectWithLoading = withLoopIcon(
    EventSelect,
    'Loading event types...',
    events === null,
    'event-type-loading',
  );

  const [ eventChangeModal, setEventChangeModal ] = useState(false);
  const [ eventTypeState, setEventTypeState ] = useState('');

  const eventTypeTest = when(
    (x: number) => x >= 25 && x <= 33 && x !== 27,
    compose(
      compose(setEventChangeModal, T),
      ifElse(
        (x: number) => x !== -1,
        compose(setEventTypeState,
          (x: any) => events?.find((type) => type.c_qualevid === parseInt(x))?.desc.substr(-2) ?? ''),
        compose(setEventTypeState, always('')),
      ),
    ),
  );

  function handleSelectChange(value: string) {
    form.handleChange('eventType')(value);
    eventTypeTest(parseInt(value));
  }

  return (
    <>
      <div className="grid grid-rows-2 grid-flow-col gap-4 w-full">
        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Event Date"
            value={form.values.eventDate ? dayjs(form.values.eventDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
            onChange={handleDateChange('eventDate', form)}
            InputProps={
              {
                className: !form.values.eventDate ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
                disabled: respPostmark,
              }
            }
            disabled={respPostmark}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                variant="outlined"
                name="eventDate"
                data-testid="event-date"
                className="w-full event-info-event-date"
                {...params}
                error={form.touched.eventDate && Boolean(form.errors.eventDate)}
                helperText={form.touched.eventDate && form.errors.eventDate as any}
              />
            )}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Coverage Begins"
            value={
              form.values.coverageBegins ? dayjs(form.values.coverageBegins, 'YYYY-MM-DD').format('MM/DD/YYYY') : null
            }
            onChange={handleDateChange('coverageBegins', form)}
            className="mb-4"
            InputProps={
              {
                className: !form.values.coverageBegins ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
                disabled: respPostmark || letterSent,
              }
            }
            disabled={respPostmark || letterSent}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                variant="outlined"
                data-testid="coverage-begins"
                name="coverageBegins"
                className="w-full event-info-coverage-begins"
                {...params}
                error={form.touched.coverageBegins && Boolean(form.errors.coverageBegins)}
                helperText={form.touched.coverageBegins && form.errors.coverageBegins as any}
              />
            )}
          />
        </LocalizationProvider>

        {
          events !== null
            ?
            <EventSelectWithLoading
              onSelectChange={handleSelectChange}
              error={!!form.touched.eventType && !!form.errors.eventType}
              value={form.values.eventType?.toString() ?? '-1'}
              eventTypes={events}
              className="mb-4 w-full event-info-type"
              disabled={respPostmark}
            />
            :
            <div className="w-full" data-testid="event-type-skeleton">
              <Skeleton variant="rectangular" width="100%" height={60} />
            </div>
        }

        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Paid Through (Optional)"
            value={form.values.paidThrough ? dayjs(form.values.paidThrough, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
            onChange={handleDateChange('paidThrough', form)}
            className="mb-4 pl-2"
            InputProps={
              {
                className: !form.values.coverageBegins ? 'MuiFormLabel-root' : '',
                tabIndex: -1,
                disabled: respPostmark || letterSent,
              }
            }
            disabled={respPostmark}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                data-testid="paid-through"
                variant="outlined"
                name="paidThrough"
                className="w-full event-info-paid-thru"
                {...params}
                error={form.touched.paidThrough && Boolean(form.errors.paidThrough)}
                helperText={form.touched.paidThrough && form.errors.paidThrough as any}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <StateContinuationModal
        isOpen={eventChangeModal}
        onClose={compose(setEventChangeModal, F)}
        title="State Continuation Confirmation"
        eventTypeState={eventTypeState}
      />
    </>
  );
}

export default EventInfoForm;
