import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/reports/pending-responses/:type', (schema, request) => {
    return new Response(200, { 'content-length': 1 }, { data: 20 });
  });

  server.get('/companies/:companyId/reports/pending-responses/:type/export', (schema, request) => {
    return new Response(204, { 'content-length': 0 });
  });
}

export default routing;
