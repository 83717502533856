import { Response } from 'miragejs';

export function noContentResponse() {
  return new Response(204, { 'content-length': 0 });
}

export function createdResponse() {
  return new Response(201, { 'content-length': 0 }, {});
}

export function okResponse(data) {
  return () => {
    return new Response(200, { 'content-length': JSON.stringify(data).length }, { data })
  };
}
