import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHistoricalCobraPlans, getHistoricalDirectBillPlans } from 'shared/api/historicalRatesApi';

export let fetchRateHistory = createAsyncThunk(
  'ratesHistory/fetchRatesHistory',
  (companyId: number) => {
    return getHistoricalCobraPlans(companyId);
  },
);

export let fetchDirectBillRateHistory = createAsyncThunk(
  'ratesHistory/fetchDirectBillRatesHistory',
  (companyId: number) => {
    return getHistoricalDirectBillPlans(companyId);
  },
);
