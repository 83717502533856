import PageContentPaper from 'components/layout/PageContentPaper';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compose, tap, F } from 'ramda';

import WizardStepper, { WizardStep } from 'components/shared/WizardStepper';
import { CobraQeFormProps, QeBenefits, QeEventInfo } from './cobra-qe.types';
import CobraQePersonalDetails from './personal-details/CobraQePersonalDetails';
import CobraQeSummary from './summary/CobraQeSummary';
import {
  resetEntry,
  selectCobraQeEntry,
  selectEventTypes,
  selectQeBenefits,
  selectQeDependents,
  selectQeEventInfo,
  selectQePersonalDetails,
  updateBenefits,
  updateDependents,
  updateEventInfo,
  updatePersonalDetails,
  selectAllDependents,
} from './cobra-qe.slice';
import EventInfo from './event-info/EventInfo';
import CancelModal from 'components/shared/CancelModal';
import request from 'shared/api-request';
import { selectSelectedCompany } from 'companies/companies.slice';
import Loading from 'components/shared/Loading';
import CobraQeBenefits from './benefits/CobraQeBenefits';
import CobraQeDependents from './dependents/CobraQeDependents';
import { useHistoryBlock } from 'shared/custom-hooks/useHistoryBlock';
import NotFoundPage from 'components/pages/NotFound';
import { setSelectedParticipant } from 'participant-search/participant.slice';
import { useErrorNotifier, useSuccessNotifier } from 'shared/custom-hooks/useNotifiers';
import wizardSteps, { EQEStepSlug } from './enter-cobra-qe-steps';
import { QeParticipant, QeDependents } from 'shared/types/non-plan-year.types';
import store from 'store';

interface CobraQeWizardProps extends CobraQeFormProps {
  onSave: (doAnother: boolean) => void;
}

function CobraQeWizard({ onNextStep, onCancel, onSave }: CobraQeWizardProps): React.ReactElement {
  const { stepSlug } = useParams<{ stepSlug: EQEStepSlug; }>();
  const details = useSelector(selectQePersonalDetails);
  const dependents = useSelector(selectQeDependents);
  const allDependents = useSelector(selectAllDependents);
  const eventInfo = useSelector(selectQeEventInfo);
  const benefits = useSelector(selectQeBenefits);
  const entry = useSelector(selectCobraQeEntry);
  const eventTypes = useSelector(selectEventTypes);

  function content(): React.ReactElement {
    switch (stepSlug) {
    case 'personal-details':
      return <CobraQePersonalDetails onNextStep={onNextStep} onCancel={onCancel} details={details} />;
    case 'event-info':
      return <EventInfo onNextStep={onNextStep} onCancel={onCancel} data={eventInfo} />;
    case 'dependents':
      return (
        <CobraQeDependents
          onNextStep={onNextStep}
          onCancel={onCancel}
          empno={details.empno}
          initialAddedDependents={dependents.addedDependents}
        />
      );
    case 'benefits':
      return (
        <CobraQeBenefits
          selectedBenefits={benefits}
          dependents={allDependents}
          effectiveDate={eventInfo.coverageBegins!}
          onNextStep={onNextStep}
          onCancel={onCancel}
        />
      );
    case 'summary':
      return <CobraQeSummary entry={entry} onCancel={onCancel} onSave={onSave} eventTypes={eventTypes} />;
    default:
      return <NotFoundPage />;
    }
  }

  return content();
}

function EnterCobraQePage(): React.ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { current: unmountCallback } = useRef(compose(dispatch, resetEntry));
  const { current: blockRegex } = useRef(/^\/enter-cobra-qe/);
  const navUnblock = useHistoryBlock(blockRegex, unmountCallback);
  const { stepSlug: activeStepSlug = 'personal-details' } = useParams<{ stepSlug: EQEStepSlug; }>();

  const company = useSelector(selectSelectedCompany);
  const entry = useSelector(selectCobraQeEntry);

  const [ showModal, setShowModal ] = useState(false);
  const [ saving, setSaving ] = useState(false);

  const handleError = compose(tap(compose(setSaving, F)), useErrorNotifier());
  const successNotifier = useSuccessNotifier('COBRA QE saved successfully.');

  // this is only here to clear out selected participant on mount
  useEffect(() => {
    dispatch(setSelectedParticipant(null));
  }, [ dispatch ]);

  function handleSave(doAnother: boolean) {
    setSaving(true);
    request(`/api/companies/${company?.compid}/cobra-qe`, { method: 'POST', body: entry })
      .then(() => {
        setSaving(false);
        dispatch(resetEntry());
        dispatch(setSelectedParticipant(null));
        successNotifier();

        if (navUnblock) {
          navUnblock();
        }

        history.push(doAnother ? '/enter-cobra-qe/personal-details' : '/dashboard');
      })
      .catch(handleError);
  }

  function onNextStep(data: QeParticipant | QeEventInfo | QeDependents | QeBenefits) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if ((data as QeParticipant).payrollid !== undefined) {
      dispatch(updatePersonalDetails(data as QeParticipant));
    } else if ((data as QeEventInfo).eventType !== undefined) {
      dispatch(updateEventInfo((data as QeEventInfo)));
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if ((data as QeDependents).initialDependents !== undefined) {
      dispatch(updateDependents((data as QeDependents)));
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if ((data as QeBenefits).benefits !== undefined) {
      dispatch(updateBenefits((data as QeBenefits)));
    }

    const step = wizardSteps.get(activeStepSlug);
    history.push(`/enter-cobra-qe/${step?.next}`);
  }

  function handleStepClick(step: WizardStep) {
    history.push(`/enter-cobra-qe/${step.slug}`);
  }

  function onWizardCancel() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function goToDashboard() {
    if (showModal) {
      setShowModal(false);
    }

    if (navUnblock) {
      navUnblock();
    }

    dispatch(resetEntry());
    history.push('/dashboard');
  }

  return (
    <>
      <PageContentPaper className="mt-4 mb-8">
        <div className="mb-8">
          <WizardStepper
            steps={wizardSteps}
            allowClick
            onChange={handleStepClick}
          />
        </div>
        <div className="px-8">
          <CobraQeWizard
            onNextStep={onNextStep}
            onCancel={onWizardCancel}
            onSave={handleSave}
          />
        </div>
      </PageContentPaper>
      <CancelModal
        isOpen={showModal}
        onCancel={goToDashboard}
        onClose={closeModal}
      />
      {saving ? <Loading text="Submitting COBRA Qualifying Event" /> : <></>}
    </>
  );
}

export default EnterCobraQePage;
