import React, { ReactElement } from 'react';
import { always, compose, equals, ifElse, andThen } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import {
  CancelModalStatus,
  selectCobraPlan,
  selectCobraReEnrollmentChecklistPlansByType,
  selectDoesRedirectToOePackets,
  selectIsFinalizeCobraChecklistDisabled,
  selectPlanRatesContinueIsDisabled,
  selectRedirectModalStatus,
  setRedirectModalStatus,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { CobraChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import SummaryAccordion from 're-enrollment-checklist/cobra-reenrollment-checklist/summary/SummaryAccordion';
import { finalizeCobraPlans } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import store from 'store';
import { noop } from 'shared/utils';
import CancelModal from 'components/shared/CancelModal';
import FormHelperText from '@mui/material/FormHelperText';

function SummaryTab(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const cobraOePacketPlan = useSelector(selectCobraPlan);
  const { checklistComplete } = cobraOePacketPlan;
  const isFinalizeDisabled = useSelector(selectIsFinalizeCobraChecklistDisabled);
  const oeCoverLetterAnswer = !!cobraOePacketPlan.cobraOeTemplateCoverLetter ? 'Yes' : 'No';
  const redirectModalStatus = useSelector(selectRedirectModalStatus);

  const cobraPlans = useSelector(selectCobraReEnrollmentChecklistPlansByType);
  const setOeHandlerAnswer = compose(
    ifElse(
      equals(1),
      always('Yes (results in a fee of $15 per packet plus printing and shipping, $75 minimum.)'),
      always('No (no fee)'),
    ),
  )(cobraOePacketPlan.cobraOeHandler);
  const setCoverLetterAnswer = compose(
    ifElse(
      equals(1),
      always('Yes'),
      always(oeCoverLetterAnswer),
    ),
  )(cobraOePacketPlan.cobraOeHandler);

  const skipOePackets = useSelector(selectDoesRedirectToOePackets);

  const changeRedirectModalStatus = (status: CancelModalStatus) => compose(
    dispatch, setRedirectModalStatus, always(status),
  );
  const isPlanRatesIncomplete = useSelector(selectPlanRatesContinueIsDisabled);

  return (
    <div>
      <div className="my-10">
        <div className="flex justify-between">
          <p>Cobra Eligible Count</p>
          <Button
            className="text-sm"
            variant="text"
            onClick={compose(history.push, always(`/reenrollment-checklist/cobra-plan/${planYearId}/eligible-count`))}
          >Edit
          </Button>
        </div>
        <hr />
        { !cobraOePacketPlan.eligibilitySectionComplete &&
          <FormHelperText error className="mt-2" data-testid="eligibility-error">
            This section is incomplete. Please click "Edit" above and complete the required fields.
          </FormHelperText>
        }
        <div className="w-3/5">
          <div className="my-5">
            <h6>
              Number of employees who are currently covered under one or more
              of your COBRA-eligible plans who could potentially be offered COBRA coverage.
            </h6>
            <p>{cobraOePacketPlan.cobraOeNewEligibleCount}</p>
          </div>
        </div>

      </div>
      <div className="my-10">
        <div className="flex justify-between">
          <p>OE Packets</p>
          <Button
            className="text-sm"
            variant="text"
            onClick={compose(
              history.push,
              always(`/reenrollment-checklist/cobra-plan/${planYearId}/oe-packets-options`),
            )}
          >Edit
          </Button>
        </div>
        <hr />
        { !cobraOePacketPlan.oeOptionsSectionComplete &&
          <FormHelperText error className="mt-2" data-testid="oe-packets-error">
            This section is incomplete. Please click "Edit" above and complete the required fields.
          </FormHelperText>
        }
        <div className="w-3/5">
          <div className="my-5">
            <h6>Would you like Flores to mail your packets to participants directly?</h6>
            { cobraOePacketPlan.oeOptionsSectionComplete &&
              <p>{setOeHandlerAnswer}</p>
            }
          </div>

          <div className="my-5">
            <h6>
              Would you like to use Flores' system to generate a template cover letter and
              election form that you can mail to your participants at no additional cost?
            </h6>
            { cobraOePacketPlan.oeOptionsSectionComplete &&
              <p>{setCoverLetterAnswer}</p>
            }
          </div>
        </div>
      </div>

      <div className="my-10">
        <div className="flex justify-between">
          <p>Plan Rates</p>
          <Button
            className="text-sm"
            variant="text"
            onClick={compose(
              history.push,
              always(`/reenrollment-checklist/cobra-plan/${planYearId}/plan-rates`),
            )}
          >Edit
          </Button>
        </div>
        <hr />
        { isPlanRatesIncomplete &&
          <FormHelperText error className="mt-2" data-testid="plan-rates-error">
            This section is incomplete. Please click "Edit" above and review plans and rates.
          </FormHelperText>
        }

        <div className="my-5">
          {Object
            .entries(cobraPlans)
            .map(([ type, plans ]: [string, CobraChecklistPlan[]]) => (
              <Accordion key={type}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{type}</Typography>
                </AccordionSummary>
                {plans.map(plan => (
                  <SummaryAccordion key={plan.planId} plan={plan} />
                ))}
              </Accordion>
            ))
          }
        </div>
      </div>
      <div className="flex flex-row-reverse">
        {
          !checklistComplete &&
            <Button
              data-testid="complete-button"
              variant="contained"
              color="primary"
              disabled={isFinalizeDisabled}
              onClick={compose(
                andThen(
                  ifElse(
                    always(skipOePackets),
                    compose(
                      history.push,
                      always(`/reenrollment-checklist`),
                    ),
                    changeRedirectModalStatus('open'),
                  ),
                ),
                dispatch,
                finalizeCobraPlans,
                noop,
              )}
            >Complete and Submit
            </Button>
        }

        <Button
          className="mr-4"
          onClick={compose(
            history.push,
            always(`/reenrollment-checklist`),
          )}
        >
          Cancel
        </Button>
        { isFinalizeDisabled &&
          <FormHelperText error className="mr-4 self-center" data-testid="finalize-error">
            There are more items that need your attention before you can complete this checklist.
          </FormHelperText>
        }
        <CancelModal
          title=""
          prompt="You have elected to have Flores generate Open Enrollment Packets for your COBRA Participants.
          This will require some additional information. You will now see a new checklist for COBRA Open Enrollment
          Packets on the Re-Enrollment landing page.
          Would you like to go to this checklist now?"
          closeText="Yes, take me to the checklist"
          cancelText="No, take me to the Re-Enrollment Checklist landing page"
          isOpen={redirectModalStatus === 'open'}
          onClose={compose(
            changeRedirectModalStatus('closed'),
            history.push,
            always(`/reenrollment-checklist/cobra/${planYearId}/cover-letter-info`),
          )}
          onCancel={compose(
            changeRedirectModalStatus('closed'),
            history.push,
            always(`/reenrollment-checklist`),
          )}
        />
      </div>
    </div>
  );
}

export default SummaryTab;
