function yupErrorsToObject(errors: string[]) {
  return errors.reduce((acc: Record<string, any>,cur: string) => {
    let [ property, ...message ] = cur.split(' ');
    acc[property] = message.join(' ');

    return acc;
  }, {} as Record<string, any>);
}

export default yupErrorsToObject;
