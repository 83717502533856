import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFeatureFlags } from 'shared/api/appSettingsApi';
import { RootState } from 'store/rootReducer';

export const fetchFeatureFlags = createAsyncThunk(
  'settings/fetchFeatureFlags',
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { compid: companyId } = state.companies.selectedCompany ?? { compid: 0 };

    return getFeatureFlags(companyId);
  },
);
