import * as React from 'react';
import { getDefaultBorderIncludes } from './utils';
import { PdfTableRow as _PdfTableRow, PdfTableRowProps } from './PdfTableRow';

type PdfTableHeaderProps = PdfTableRowProps

const PdfTableRow = _PdfTableRow as any;

/**
 * This component displays the titles for the rows.
 */
// eslint-disable-next-line react/prefer-stateless-function
export class PdfTableHeader extends React.PureComponent<PdfTableHeaderProps> {
  render() {
    let {
      includeLeftBorder,
      includeBottomBorder,
      includeRightBorder,
      includeTopBorder,
    } = getDefaultBorderIncludes(this.props);

    const rowCells: any[] = React.Children.toArray((this.props as any).children);

    return (
      <PdfTableRow
        {...this.props}
        key={'header'}
        includeLeftBorder={includeLeftBorder}
        includeBottomBorder={includeBottomBorder}
        includeRightBorder={includeRightBorder}
        includeTopBorder={includeTopBorder}
      >
        {
          rowCells.map((rc, columnIndex) => React.cloneElement(rc, {
            key: columnIndex,
            isHeader: true,
            fontSize: this.props.fontSize,
            textAlign: this.props.textAlign,
            includeLeftBorder: columnIndex === 0,
            includeRightBorder: columnIndex !== (rowCells.length - 1),
          }))
        }
      </PdfTableRow>
    );
  }
}
