import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { always, compose } from 'ramda';
import { ReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import { ChecklistCard } from 're-enrollment-checklist/ChecklistCard';
import setLegacyChecklistCompletionProgress from 'shared/utils/set-legacy-checklist-completion-progress';
import BeachAccessRounded from '@mui/icons-material/BeachAccessRounded';

function CobraLegacyPlanCard ({ plan }: Readonly<{plan: ReEnrollmentChecklistPlan;}>) {
  const icon = <BeachAccessRounded style={ { fontSize: 32 } } />;
  const history = useHistory();
  const buttons = (
    <div className="justify-start" data-testid="cobra-legacy-buttons">
      <Button
        variant="outlined"
        className="mr-5"
        data-testid="cobra-legacy-link"
        onClick={compose(
          history.push,
          always(`/reenrollment-checklist/cobra/${plan.planYearId}`),
        )}
      >
        <div className="justify-start">
          <div>COBRA Account</div>
        </div>
      </Button>
    </div>
  );

  return (
    <ChecklistCard
      icon={icon}
      title="COBRA"
      planYearStart={plan.planYearStart}
      planYearEnd={plan.planYearEnd}
      buttons={buttons}
      progress={setLegacyChecklistCompletionProgress(plan.legacyChecklistComplete)}
    />
  );
}

export default CobraLegacyPlanCard;

