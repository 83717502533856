import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { always, compose, tap } from 'ramda';

import { selectSelectedCompany } from 'companies/companies.slice';
import { getForms } from 'shared/api/documentLibraryApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { LibraryDocument } from 'document-library/document-library.types';
import store from 'store';

export function useDocumentLibraryForms() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const errorNotifier = useErrorNotifier();

  const [ formDocuments, setFormDocuments ] = useState<LibraryDocument[] | null>(null);

  const { compid: companyId } = useSelector(selectSelectedCompany) ?? { compid: undefined };

  const setDefault = compose(setFormDocuments, always([]));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (companyId) {
      getForms(companyId)
        .then((result) => {
          if (mountedRef.current) {
            setFormDocuments(result ?? []);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ dispatch, companyId, handleError ]);

  return formDocuments;
}
