import LinearProgress from '@mui/material/LinearProgress';
import { Theme } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

export const BorderLinearProgress = withStyles((theme: Theme) => createStyles({
  root: {
    height: 10,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#19D759',
  },
}),
)(LinearProgress);
