import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { getContactInfo } from 'shared/api/contactInfoApi';
import { RootState } from 'store/rootReducer';
import { Contact } from 'manage-settings/contact-info/contact.types';

export interface ContactInfoState {
  details: Contact | null;
}

export const defaultContact = {
  confname: '',
  conlname: '',
  conaddr1: '',
  conaddr2: '',
  concity: '',
  constate: '',
  conzip: '',
  conphone: '',
  confax: '',
  conemail: '',
} as Contact;

export const initialState: ContactInfoState = {
  details: defaultContact,
};

const contactInfoSlice = createSlice({
  name: 'contactInfo',
  initialState,
  reducers: {
    loadContactInfoStart(state) {
      state.details = null;
    },
    setDetails(state, { payload }: PayloadAction<Contact | null>) {
      state.details = payload;
    },
  },
});

export const {
  loadContactInfoStart,
  setDetails,
} = contactInfoSlice.actions;

export default contactInfoSlice.reducer;

// thunks
export function loadContactInfo(companyId: number, userId: number): AppThunk {
  return async (dispatch) => {
    dispatch(loadContactInfoStart());

    try {
      const details = await getContactInfo(companyId, userId);
      dispatch(setDetails(details));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
      dispatch(setDetails(defaultContact));
    }
  };
}

// selectors
export const selectContactInfo = (state: RootState) => state.contactInfo.details;
