import dayjs from 'dayjs';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';

import { BorderLinearProgress } from 'components/shared/BorderLinearProgress';

interface Props {
  icon: JSX.Element;
  planYearStart: string;
  planYearEnd: string;
  title: string;
  buttons: JSX.Element;
  progress: number;
}

export function ChecklistCard ({
  icon,
  planYearStart,
  planYearEnd,
  title,
  buttons,
  progress,
}: Props) {
  return (
    <Paper className="mt-4">
      <div className="w-full flex flex-row">
        <div className="w-32 flex justify-center pt-4">
          <Avatar className="bg-primary-main" style={ { width: 50, height: 50 } }>
            { icon }
          </Avatar>
        </div>
        <div className="w-full flex flex-col">
          <small className="pt-4">
            {`${
              dayjs(planYearStart).format('MM/DD/YYYY')
            } - ${
              dayjs(planYearEnd).format('MM/DD/YYYY')
            }`}
          </small>
          <h5>{ title }</h5>
          <div className="w-full flex pr-8 my-6">
            { buttons }
          </div>
        </div>
      </div>
      <div className="w-full">
        <BorderLinearProgress variant="determinate" value={progress} />
      </div>
    </Paper>
  );
}
