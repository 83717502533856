import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { compose, T, F } from 'ramda';

import PageContentPaper from 'components/layout/PageContentPaper';
import Loading from 'components/shared/Loading';
import UpdateCobraQeForm from './UpdateCobraQeForm';
import { CobraQe } from 'enter-cobra-qe/cobra-qe.types';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { updateCobraQe } from 'shared/api/cobraQeApi';
import { useCobraQe } from './useCobraQe';
import { useState } from 'react';
import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import { selectParticipant } from 'participant-search/participant.slice';
import { selectSelectedCompany } from 'companies/companies.slice';

function UpdateCobraQe() {
  usePageTitle('Update COBRA QE');
  const history = useHistory();
  const { empno } = useSelector(selectParticipant) ?? { empno: '' };
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const { cQualId } = useParams<{cQualId:string;}>();

  const handleError = useErrorNotifier();

  const entry = useCobraQe(empno, parseInt(cQualId));
  const [ saving, setSaving ] = useState(false);
  const startSaving = compose(setSaving, T);
  const doneSaving = compose(setSaving, F);

  function handleClose() {
    history.goBack();
  }

  function handleSave(cobraQe: CobraQe) {
    startSaving();
    updateCobraQe(compid, cobraQe)
      .then(handleClose)
      .catch(handleError)
      .finally(doneSaving);
  }

  function content() {
    return (
      <>
        <PageContentPaper className="mt-4">
          <UpdateCobraQeForm
            onCancel={handleClose}
            onSave={handleSave}
            dependents={entry!.participant.dependents ?? []}
            selectedBenefits={entry!.benefits}
            entry={entry!}
          />
        </PageContentPaper>
        { saving && <Loading text="Saving QE..." /> }
      </>
    );
  }

  function couldNotLoad() {
    return (
      <PageContentPaper className="mt-4">
        <p>There was a problem loading the QE. Please contact your Account Manager.</p>
      </PageContentPaper>
    );
  }

  switch (entry) {
  case null: return <Loading text="Loading QE Entry..." />;
  case undefined: return couldNotLoad();
  default: return content();
  }
}

export default UpdateCobraQe;
