import dayjs from 'dayjs';
import { validateDate } from 'shared/api/validateDate';
import request, { ContentType } from 'shared/api-request';

export function downloadGlobalReimbursementsReport(companyId: number, startDate?: string, endDate?: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download monthly payments report for invalid company.'));
  }

  try {
    let uri = `/api/companies/${companyId}/reports/global-reimbursements/export`;
    const start = validateDate('Cannot download monthly payments report for invalid start date.',
      startDate ?? dayjs().format());
    const end = validateDate('Cannot download monthly payments report for invalid end date.',
      endDate ?? dayjs().format());
    let queryString = [];

    if (start) {
      queryString.push(`startDate=${start.format('YYYY-MM-DD')}`);
    }

    if (end) {
      queryString.push(`endDate=${end.format('YYYY-MM-DD')}`);
    }

    if (queryString.length) {
      uri += `?${queryString.join('&')}`;
    }

    return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (err) {
    return Promise.reject(err);
  }
}
