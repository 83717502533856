import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';

import { Benefit, Dependent, QeParticipant, QeDependents } from 'shared/types/non-plan-year.types';
import {
  CobraQe,
  QeBenefits,
  QeEventInfo,
  QeEventType,
} from './cobra-qe.types';
import { AppThunk } from 'store';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import request from 'shared/api-request';
import { assertIsDefined } from 'shared/asserts';
import { getDependents } from 'shared/api/dependentsApi';
import { deepTrim } from '../shared/utils/deep-trim';
import { lensPath, or, replace, set, view } from 'ramda';

export interface CobraQeState {
  entry: CobraQe;
  eventTypes: QeEventType[];
  loadingQe: boolean;
}

export const initialState: CobraQeState = {
  entry: {
    participant: {
      empno: '',
      socsec: '',
      payrollid: '',
      birthdate: null,
      lname: '',
      fname: '',
      sex: null,
      hphone: null,
      hstreet1: null,
      hstreet2: null,
      hcity: null,
      hstate: null,
      hzip: null,
      location: null,
      payfreq: null,
      payfreq2: null,
      email: null,
    },
    eventDate: '',
    eventType: -1,
    coverageBegins: '',
    initialDependents: [],
    addedDependents: [],
    benefits: [],
  },
  eventTypes: [],
  loadingQe: false,
};

const cobraQeSlice = createSlice({
  name: 'cobraQe',
  initialState,
  reducers: {
    loadQeStart(state) {
      state.loadingQe = true;
    },
    loadInitialDependentsStart(state) {
      state.entry.initialDependents = [];
    },
    loadQeSuccess(state, { payload }: PayloadAction<CobraQe>) {
      state.entry = payload;
      state.loadingQe = false;
    },
    loadQeFail(state) {
      state.loadingQe = false;
    },
    resetEntry(state) {
      state.entry = { ...initialState.entry };
    },
    updatePersonalDetails(state, { payload }: PayloadAction<QeParticipant>) {
      let pidLens = lensPath([ 'payrollid' ]);
      let formattedPayload = set(
        pidLens,
        replace(/-+/g, '', or(view(pidLens, payload), '')),
        payload,
      );
      state.entry.participant = deepTrim(formattedPayload);
    },
    updateEventInfo(state, { payload }: PayloadAction<QeEventInfo>) {
      state.entry.eventDate = payload.eventDate;
      state.entry.eventType = payload.eventType;
      state.entry.coverageBegins = payload.coverageBegins;
      state.entry.paidThrough = payload.paidThrough;
    },
    updateDependents(state, { payload }: PayloadAction<QeDependents>) {
      state.entry.initialDependents = payload.initialDependents;
      state.entry.addedDependents = payload.addedDependents;
    },
    updateBenefits(state, { payload }: PayloadAction<QeBenefits>) {
      state.entry.benefits = payload.benefits;
    },
    setEventTypes(state, { payload }: PayloadAction<QeEventType[]>) {
      state.eventTypes = payload;
    },
  },
});

export const {
  resetEntry,
  updatePersonalDetails,
  updateEventInfo,
  updateDependents,
  updateBenefits,
  setEventTypes,
  loadQeStart,
  loadQeFail,
  loadQeSuccess,
  loadInitialDependentsStart,
} = cobraQeSlice.actions;

export default cobraQeSlice.reducer;

// thunks
export function loadCobraQe(compid: number, employeeNumber: string, unqid: number): AppThunk {
  return async (dispatch) => {
    dispatch(loadQeStart());

    try {
      const qe = await request<CobraQe>(`/api/companies/${compid}/cobra-qe/${employeeNumber}/${unqid}`);
      assertIsDefined(qe);
      dispatch(loadQeSuccess(qe));
    } catch (ex) {
      dispatch(loadQeFail());
      dispatch(addHttpErrorNotification(ex as Error));
    }
  };
}

export function loadInitialDependents(compid: number, employeeNumber: string): AppThunk {
  return async (dispatch) => {
    dispatch(loadInitialDependentsStart());

    try {
      const dependents = await getDependents(compid, employeeNumber);
      dispatch(updateDependents({
        initialDependents: dependents ?? [],
        addedDependents: [],
      }));
    } catch (err) {
      dispatch(addHttpErrorNotification(err as Error));
      dispatch(updateDependents({
        initialDependents: [],
        addedDependents: [],
      }));
    }
  };
}

// selectors
export const selectCobraQeEntry = (state: RootState): CobraQe => state.cobraQe.entry;

export const selectQePersonalDetails = (rootState: RootState): QeParticipant => {
  return rootState.cobraQe.entry.participant;
};

export const selectQeEventInfo = (state: RootState): QeEventInfo => {
  const { eventDate, eventType, coverageBegins, paidThrough } = state.cobraQe.entry;

  return { eventDate, eventType, coverageBegins, paidThrough };
};

export const selectQeDependents = (state: RootState): QeDependents => {
  const { initialDependents, addedDependents } = state.cobraQe.entry;

  return { initialDependents, addedDependents };
};

export const selectQeBenefits = (state: RootState): Benefit[] => {
  const { benefits } = state.cobraQe.entry;

  return benefits;
};

export const selectEventTypes = (state: RootState): QeEventType[] => {
  return state.cobraQe.eventTypes;
};

export const selectLoadingQe = (state: RootState) => state.cobraQe.loadingQe;

export const selectInitialDependents = (state: RootState) => state.cobraQe.entry.initialDependents;

/**
 * Select a combined list of dependents for display purposes.
 * If initialDependents and addedDependents need to be updated separately, prefer `selectQeDependents`
 */
export const selectAllDependents = (
  { cobraQe: { entry: { initialDependents, addedDependents } } }: RootState,
): Dependent[] => [ ...initialDependents, ...addedDependents ];
