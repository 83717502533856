import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';
import dayjs from 'dayjs';
import Paper from '@mui/material/Paper';

import { selectSelectedCompany } from 'companies/companies.slice';
import { useSelector } from 'react-redux';
import { downloadGlobalReimbursementsReport } from 'shared/api/globalReimbursementsApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import ReportExportButton from 'reports/shared/ReportExportButton';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

function GlobalReimbursements() {
  const [ startDate, setStartDate ] = useState<string>(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [ endDate, setEndDate ] = useState<string>(dayjs().endOf('month').format('YYYY-MM-DD'));
  const [ dateHasError, setDateHasError ] = useState(false);
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  function handleExport() {
    startDownloading();
    downloadGlobalReimbursementsReport(compid, startDate, endDate)
      .then(doneDownloading)
      .catch(handleError);
  }

  function handleStartDateChange(newDate: any) {
    setDateHasError(!newDate || !newDate.isValid());
    setStartDate(newDate?.format('YYYY-MM-DD') ?? '');
  }

  function handleEndDateChange(newDate: any) {
    setDateHasError(!newDate || !newDate.isValid());
    setEndDate(newDate?.format('YYYY-MM-DD') ?? '');
  }

  return (
    <Paper elevation={2} className="p-4 border-neutral5">
      <div className="flex flex-row justify-between">
        <h5 className="text-base text-high-em">
          Global Reimbursements
        </h5>
      </div>
      <div className="w-full">
        <div className="flex flex-row justify-between my-4">
          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <DatePicker
              className="w-1/3"
              label="Report Start Date"
              inputFormat="MM/DD/YYYY"
              value={startDate ? dayjs(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
              onChange={handleStartDateChange}
              InputProps={
                {
                  className: `${!startDate ? 'MuiFormLabel-root' : ''} text-sm`,
                  classes: {
                    input: 'px-2 py-3',
                    adornedEnd: 'pr-1',
                  },
                }
              }
              InputAdornmentProps={ { classes: { positionEnd: 'ml-1' } } }
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  data-testid="monthly-report-date-range-filter-start"
                  variant="outlined"
                  className="w-full"
                  {...params}
                  error={dateHasError}
                  helperText={dateHasError && 'Please enter valid dates.'}
                />
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <DatePicker
              className="w-1/3"
              label="Report End Date"
              inputFormat="MM/DD/YYYY"
              value={endDate ? dayjs(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null}
              onChange={handleEndDateChange}
              InputProps={
                {
                  className: `${!endDate ? 'MuiFormLabel-root' : ''} text-sm`,
                  classes: {
                    input: 'px-2 py-3',
                    adornedEnd: 'pr-1',
                  },
                }
              }
              InputAdornmentProps={ { classes: { positionEnd: 'ml-1' } } }
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  data-testid="monthly-report-date-range-filter-end"
                  variant="outlined"
                  className="w-full"
                  {...params}
                  error={dateHasError}
                  helperText={dateHasError && 'Please enter valid dates.'}
                />
              )}
            />
          </LocalizationProvider>

          <ReportExportButton
            variant="light"
            onExport={handleExport}
            title="Export Global Reimbursements"
            disabled={dateHasError}
            downloading={downloading}
          />
        </div>
      </div>
      <p className="mt-4 text-gray-chateu">
        All participant reimbursements for all plans in the selected date range.
      </p>
    </Paper>
  );
}

export default GlobalReimbursements;
