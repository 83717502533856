import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import PlanSelect from 'plan-enrollment/plan-selection/PlanSelect';
import CustomReportsByDateRange from './CustomReportsByDateRange';
import PageContentPaper from 'components/layout/PageContentPaper';
import CustomEnrollment from './CustomEnrollment';
import CustomEnrollmentWithDeps from './CustomEnrollmentWithDeps';
import {
  setSelectedCustomReportPlan,
  selectCustomReportPlans,
  selectSelectedCustomReportPlan, loadCustomReportPlans,
} from './custom-reports.slice';
import store from 'store';

function CustomReports() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const plans = useSelector(selectCustomReportPlans);
  const selectedPlan = useSelector(selectSelectedCustomReportPlan);

  /* istanbul ignore next */ /* couldn't figure out a test to cover this without huge problems */
  function handlePlanChange(planId?: number) {
    if (planId === undefined || planId < 0) {
      dispatch(setSelectedCustomReportPlan(undefined));
    } else if (plans) {
      const plan = plans.find((p) => p.unqid === planId);
      dispatch(setSelectedCustomReportPlan(plan));
    }
  }
  /* istanbul ignore stop */

  useEffect(() => {
    dispatch(loadCustomReportPlans());
  }, [ dispatch ]);

  const PlanSelectWithLoader = withLoopIcon(
    PlanSelect,
    'Loading plans...',
    plans === null,
    'w-full plan-enrollment-plans-loading',
  );

  return (
    <PageContentPaper>
      <div className="w-full">
        <div className="w-1/3">
          <PlanSelectWithLoader
            name="planId"
            plans={plans!}
            value={selectedPlan?.unqid}
            error={false}
            onPlanSelected={handlePlanChange}
            showSubPlan={false}
          />
          <small className="text-gray-chateu text-xs ml-4">
            Select the report type to display for all reports below
          </small>
        </div>

        <div className="py-8 mb-8 border-neutral5 border-solid border-b-2">
          <div className="flex">
            <div className="flex-grow flex flex-row space-x-4" >
              <div className="h-1/2 w-full">
                <CustomEnrollment selectedPlan={selectedPlan} />
              </div>
              <div className="h-1/2 w-full">
                <CustomEnrollmentWithDeps selectedPlan={selectedPlan} />
              </div>
            </div>
          </div>
        </div>

        <CustomReportsByDateRange selectedPlan={selectedPlan} />
      </div>
    </PageContentPaper >
  );
}

export default CustomReports;
