import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/qualifying-events/:type/print/:unqid', () => {
    return new Response(201, { 'content-length': 0 }, {});
  });

  server.get('/companies/:companyId/qualifying-events/:type/invoices/:employeeNumber', (schema) => {
    const { models } = schema.invoices.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/qualifying-events/:type/checks/:employeeNumber', (schema) => {
    const { models } = schema.checks.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/qualifying-events/:type/documents/:employeeNumber', (schema) => {
    const { models } = schema.documents.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/qualifying-events/:type/document/:documentNumber', () => {
    return new Response(200, { 'content-length': 0 }, {});
  });
}

export default routing;
