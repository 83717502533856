import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { compose, F } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import withLoopIcon from '../components/shared/HOC/WithLoopIcon';
import LoopIcon from '@mui/icons-material/Loop';

import PageContentPaper from 'components/layout/PageContentPaper';
import TabPanel from 'components/shared/TabPanel';
import GeneralInfo from './GeneralInfo';
import { selectSelectedCompany } from 'companies/companies.slice';
import { addHttpErrorNotification, addHttpSuccessNotification } from 'notifications/notifications.slice';
import { selectParticipant, setSelectedParticipant } from 'participant-search/participant.slice';
import { Participant } from 'participant-search/participant-list.types';
import Dependents from 'components/shared/dependents/Dependents';
import Loading from 'components/shared/Loading';
import { Dependent } from 'shared/types/non-plan-year.types';
import PlanEnrollmentTab from './PlanEnrollmentTab';
import ParticipantSubHeader from 'components/shared/ParticipantSubHeader';
import CancelModal from 'components/shared/CancelModal';
import { getDependents, saveDependents } from 'shared/api/dependentsApi';
import { updateParticipant } from 'shared/api/participantApi';
import store from 'store';

export interface DependentsState {
  initialDependents: Dependent[] | null;
  addedDependents: Dependent[] | null;
}

type PoTab = 'general-info' | 'dependents' | 'plan-enrollment';

function ParticipantOverview() {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { employeeNumber, tab = 'general-info' } = useParams<{employeeNumber: string; tab?: PoTab;}>();
  const selectedParticipant = useSelector(selectParticipant);
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ nextTab, setNextTab ] = useState<PoTab>(tab);
  const [ showModal, setShowModal ] = useState(false);
  const [ tabHasChanges, setTabHasChanges ] = useState(false);
  const [ saving, setSaving ] = useState(false);
  const [ participant, setParticipant ] = useState<Participant | null>(selectedParticipant);
  const [ dependents, setDependents ] = useState<DependentsState>({
    initialDependents: null,
    addedDependents: null,
  });

  function updateDependents(
    values: DependentsState,
  ) {
    if (values.addedDependents?.length) {
      setSaving(true);
      saveDependents(compid, participant!.empno, values.addedDependents)
        .then(() => setDependents({
          initialDependents: [ ...(values.initialDependents ?? []), ...(values.addedDependents ?? []) ],
          addedDependents: [],
        }))
        .catch(compose(dispatch, addHttpErrorNotification))
        .then(() => setSaving(false));
    }
  }

  function onSubmit(p: Participant) {
    setSaving(true);

    updateParticipant(compid, p)
      .then(() => {
        dispatch(setSelectedParticipant(p));
        setParticipant(p);
        dispatch(addHttpSuccessNotification('Saved!'));
      })
      .catch(compose(dispatch, addHttpErrorNotification))
      .finally(() => setSaving(false));
  }

  useEffect(() => {
    if (participant?.empno !== employeeNumber) {
      setParticipant(selectedParticipant);
      setDependents({ initialDependents: null, addedDependents: null });
    }
  }, [ employeeNumber, participant, selectedParticipant ]);

  useEffect(() => {
    if (tab === 'dependents' && !dependents.initialDependents && participant) {
      getDependents(compid, participant.empno)
        .then((data) => setDependents({ initialDependents: data ?? [], addedDependents: [] }))
        .catch(compose(dispatch, addHttpErrorNotification));
    }
  }, [ dependents.initialDependents, dispatch, tab, participant, compid ]);

  function onChangeTab(event: React.ChangeEvent<{}>, newValue: PoTab) {
    if (newValue !== tab) {
      setNextTab(newValue);

      if (tabHasChanges) {
        setShowModal(true);
      } else {
        history.push(`/participant-overview/${employeeNumber}/${newValue}`);
      }
    }
  }

  function switchTabsAfterModal() {
    setShowModal(false);
    setTabHasChanges(false);
    history.push(`/participant-overview/${employeeNumber}/${nextTab}`);
  }

  const Subheader = withLoopIcon(
    ParticipantSubHeader,
    'Loading',
    !participant,
    'po-loading',
  );

  return (
    <>
      <div className="flex flex-col m-4 w-full po-container">
        <h5 className="mb-4">Participant Overview</h5>
        <div className="w-1/2 pb-4">
          <Subheader participant={participant!} />
        </div>
        <PageContentPaper className="flex flex-col">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            className="-ml-4 -mt-4"
            value={tab}
            onChange={onChangeTab}
          >
            <Tab data-testid="general-info-tab" value="general-info" label="General Info" />
            <Tab data-testid="dependents-tab" value="dependents" label="Dependents" />
            <Tab data-testid="plan-enrollment-tab" value="plan-enrollment" label="Plan Enrollment" />
          </Tabs>
          <TabPanel value={tab} index="general-info">
            {
              tab !== 'general-info' || !participant
                ? <div className="general-info-loading"><LoopIcon className="animate-spin" /> Loading</div>
                : (
                  <div data-testid="general-info-content">
                    <GeneralInfo
                      details={participant}
                      onSubmit={onSubmit}
                      onHasChanged={setTabHasChanges}
                    />
                  </div>
                )
            }
          </TabPanel>
          <TabPanel value={tab} index="dependents">
            <div data-testid="dependents-content">
              {
                tab !== 'dependents' || !dependents.initialDependents
                  ? <div className="deps-loading"><LoopIcon className="animate-spin" /> Loading</div>
                  : (
                    <Dependents
                      submitText="Save Dependents"
                      onNextStep={updateDependents}
                      onHasChanged={setTabHasChanges}
                      dependents={dependents.initialDependents}
                      initialAddedDependents={dependents.addedDependents ?? []}
                      saving={saving}
                    />
                  )
              }
            </div>
          </TabPanel>
          <TabPanel value={tab} index="plan-enrollment">
            <div data-testid="plan-enrollment-content">
              {
                tab !== 'plan-enrollment' || !participant
                  ? <><LoopIcon className="animate-spin" /> Loading</>
                  : <PlanEnrollmentTab employeeNumber={participant.empno} />
              }
            </div>
          </TabPanel>
        </PageContentPaper>
      </div>
      <CancelModal
        isOpen={showModal}
        onCancel={switchTabsAfterModal}
        onClose={compose(setShowModal, F)}
        title="Are you sure you want to navigate away?"
      />
      {saving ? <Loading text="Saving..." /> : <></>}
    </>
  );
}

export default ParticipantOverview;
