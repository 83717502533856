export function maskPhoneNumber(value: string) {
  const sanitized = value.replace(/[^\d]/g, '');
  let newStr = '';

  if (sanitized.length < 4) {
    newStr = sanitized;
  } else if (sanitized.length < 7) {
    newStr = `(${sanitized.slice(0, 3)}) ${sanitized.slice(3)}`;
  } else {
    newStr = `(${sanitized.slice(0, 3)}) ${sanitized.slice(3,6)}-${sanitized.slice(6, 10)}`;
  }

  return newStr;
}
