import { DirectDebitAccount } from 'shared/types/direct-debit-accounts.types';
import request from 'shared/api-request';

export function getDirectDebitAccounts(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load direct debit accounts for invalid company.'));
  }

  return request<DirectDebitAccount[]>(`/api/companies/${companyId}/direct-debit-accounts`);
}

export function payAdminInvoice(companyId: number, invoiceNumber: number, cddId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot pay invoice for invalid company.'));
  }

  if (invoiceNumber < 0) {
    return Promise.reject(new Error('Cannot pay invoice for invalid invoice number.'));
  }

  if (cddId < 0) {
    return Promise.reject(new Error('Cannot pay invoice for invalid account ID.'));
  }

  return request(
    `/api/companies/${companyId}/administrative-billing/pay-admin-invoice`,
    { method: 'POST', body: { invoiceNumber, cddId } },
  );
}
