import React from 'react';
import { useFormikContext } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import {
  selectDependents,
} from 'store/slices/dependents/dependents.slice';

interface Props {
  title?: string;
  namespace?: string;
}

function DependentSelect({ namespace, title = '' }: Props) {
  const dependents = useSelector(selectDependents);
  const { handleChange, values } = useFormikContext<any>();
  const applyNamespace = (fieldName: string) => isNil(namespace) ? fieldName : `${namespace}.${fieldName}`;

  function content() {
    return (
      <div>
        {title ? <p className="text-2xl mb-4">{title}</p> : <></>}
        <div className="flex flex-wrap">
          {
            dependents.map((d, i) => (
              <FormControlLabel
                className="w-1/3 mr-2 mb-2"
                control={
                  <Checkbox
                    value={JSON.stringify(d)}
                    checked={values[applyNamespace('coveredDependents')].includes(JSON.stringify(d))}
                    name={applyNamespace('coveredDependents')}
                    color="primary"
                    className={`${applyNamespace(d.name)}${i}`}
                    onChange={handleChange}
                  />
                }
                label={d.name}
                labelPlacement="end"
                key={`${d.name}${i}`}
              />
            ))
          }
        </div>
      </div>
    );
  }

  return isNil(dependents) || !dependents.length ? <></> : content();
}

export default DependentSelect;
