import { PropsWithChildren } from 'react';
import Paper from '@mui/material/Paper';

function GraySummaryTile({ children }: PropsWithChildren<{}>) {
  return (
    <Paper className="flex flex-col bg-neutral6 p-4 pt-6 rounded" elevation={0}>
      {children}
    </Paper>
  );
}

export default GraySummaryTile;
