import Paper from '@mui/material/Paper';
import { isNil } from 'ramda';

import ReportExportButton from '../shared/ReportExportButton';
import { noop } from 'shared/utils';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';

export type Variant = 'primary-main' | 'primary-dark';

interface ColorClasses {
  background: string;
  titleText: string;
  descriptionText: string;
}

const variantMap = new Map<Variant | undefined, ColorClasses>([
  [
    'primary-main', {
      background: 'bg-primary-main',
      titleText: 'text-white',
      descriptionText: 'text-white opacity-75',
    },
  ],
  [
    'primary-dark', {
      background: 'bg-primary-dark',
      titleText: 'text-white',
      descriptionText: 'text-white opacity-75',
    },
  ],
  [
    undefined, {
      background: '',
      titleText: 'text-high-em',
      descriptionText: 'text-gray-chateu',
    },
  ],
]);

interface ReportsTileProps {
  count: number | null;
  title: string;
  description: string;
  variant?: Variant;
  onExport?: () => void;
  exportDisabled?: boolean;
  downloading?: boolean;
}

function ReportsTile({
  count,
  title,
  description,
  variant,
  onExport = noop,
  exportDisabled,
  downloading,
}: ReportsTileProps) {
  const variantClasses = variantMap.get(variant);

  function countHeading() {
    return (
      <h4 className={`text-3xl font-bold ${variantClasses?.titleText}`}>
        { count }
      </h4>
    );
  }

  const CountWithLoader = withLoopIcon(
    countHeading,
    '',
    isNil(count),
    `w-full ${!isNil(variant) ? 'text-white' : ''} report-count-loading`,
  );

  return (
    <Paper elevation={2} className={`p-4 border-neutral5 ${variantClasses?.background}`}>
      <div className="flex flex-row justify-between">
        <CountWithLoader />
        <ReportExportButton
          variant={variant ? 'dark' : 'light'}
          onExport={onExport}
          title={`Export ${title}`}
          disabled={!!exportDisabled}
          downloading={!!downloading}
        />
      </div>
      <h5 className={`text-base ${variantClasses?.titleText}`}>
        { title }
      </h5>
      <p className={`mt-4 ${variantClasses?.descriptionText}`}>
        { description }
      </p>
    </Paper>
  );
}

export default ReportsTile;
