import React, { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';

import StateSelect from 'components/shared/StateSelect';
import { Contact } from 'manage-settings/contact-info/contact.types';

function ContactDetails(): ReactElement {
  const form = useFormikContext<Partial<Contact>>();

  function handleCustomFieldChange(field: string) {
    return (value: string) => form.setFieldValue(field, value);
  }

  return (
    <>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          label="First name"
          name="confname"
          value={form.values.confname ?? ''}
          error={form.touched.confname && !!form.errors.confname}
          helperText={form.touched.confname && form.errors.confname}
          onChange={form.handleChange('confname')}
          fullWidth
          data-testid="firstName"
        />
        <TextField
          variant="outlined"
          label="Last name"
          name="conlname"
          value={form.values.conlname ?? ''}
          error={form.touched.conlname && !!form.errors.conlname}
          helperText={form.touched.conlname && form.errors.conlname}
          onChange={form.handleChange('conlname')}
          fullWidth
          data-testid="lastName"
        />
      </div>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          label="Title (Optional)"
          name="contitle"
          value={form.values.contitle ?? ''}
          error={form.touched.contitle && !!form.errors.contitle}
          helperText={form.touched.contitle && form.errors.contitle}
          onChange={form.handleChange('contitle')}
          fullWidth
          data-testid="title"
        />
        <TextField
          variant="outlined"
          label="Department (Optional)"
          name="condept"
          value={form.values.condept ?? ''}
          error={form.touched.condept && !!form.errors.condept}
          helperText={form.touched.condept && form.errors.condept}
          onChange={form.handleChange('condept')}
          fullWidth
          data-testid="department"
        />
      </div>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          name="email"
          label="Email Address"
          value={form.values.conemail ?? ''}
          error={form.touched.conemail && !!form.errors.conemail}
          helperText={form.touched.conemail && form.errors.conemail}
          onChange={form.handleChange('conemail')}
          fullWidth
          data-testid="email"
        />
        <div className="flex flex-row space-x-4">
          <TextField
            variant="outlined"
            name="conphone"
            label="Phone"
            value={form.values.conphone ?? ''}
            error={form.touched.conphone && !!form.errors.conphone}
            helperText={form.touched.conphone && form.errors.conphone}
            onChange={form.handleChange('conphone')}
            fullWidth
            data-testid="phone"
          />
          <TextField
            variant="outlined"
            name="confax"
            label="Fax (Optional)"
            value={form.values.confax ?? ''}
            error={form.touched.confax && !!form.errors.confax}
            helperText={form.touched.confax && form.errors.confax}
            onChange={form.handleChange('confax')}
            fullWidth
            data-testid="phone"
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          label="Street Address"
          name="conaddr1"
          value={form.values.conaddr1 ?? ''}
          error={form.touched.conaddr1 && !!form.errors.conaddr1}
          helperText={form.touched.conaddr1 && form.errors.conaddr1}
          onChange={form.handleChange('conaddr1')}
          fullWidth
          data-testid="conaddr1"
        />
      </div>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          label="Street Address 2 (Optional)"
          name="conaddr2"
          value={form.values.conaddr2 ?? ''}
          error={form.touched.conaddr2 && !!form.errors.conaddr2}
          helperText={form.touched.conaddr2 && form.errors.conaddr2}
          onChange={form.handleChange('conaddr2')}
          fullWidth
          data-testid="conaddr2"
        />
      </div>
      <div className="flex flex-row space-x-4">
        <TextField
          variant="outlined"
          name="concity"
          label="City"
          value={form.values.concity ?? ''}
          error={form.touched.concity && !!form.errors.concity}
          helperText={form.touched.concity && form.errors.concity}
          onChange={form.handleChange('concity')}
          className="w-1/2"
          data-testid="city"
        />

        <div className="w-1/4" data-testid="state">
          <StateSelect
            onStateChanged={handleCustomFieldChange('constate')}
            name="constate"
            label="State"
            value={form.values.constate ?? undefined}
            variant="outlined"
            error={form.touched.constate && !!form.errors.constate}
          />
        </div>

        <TextField
          variant="outlined"
          name="conzip"
          label="Zip"
          value={form.values.conzip ?? ''}
          error={form.touched.conzip && !!form.errors.conzip}
          helperText={form.touched.conzip && form.errors.conzip}
          onChange={form.handleChange('conzip')}
          className="w-1/4"
          data-testid="zip"
        />
      </div>
    </>
  );
}

export default ContactDetails;
