import React, { useEffect } from 'react';
import { Route as _Route, RouteProps, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { selectSelectedCompany } from 'companies/companies.slice';
import { isLoggedIn } from 'authentication/authentication';
import WizardLayout from 'components/layout/WizardLayout';
import DefaultLayout from 'components/layout/DefaultLayout';
import { WizardStep } from 'components/shared/WizardStepper';

const Route = _Route as any;

interface BaseAppRouteProps extends RouteProps {
  title: string;
  path: string;
  authRequired?: boolean;
}

interface DefaultAppRouteProps extends BaseAppRouteProps {
  layout: 'default';
}

interface LandingAppRouteProps extends BaseAppRouteProps {
  layout: 'landing';
}

interface WizardAppRouteProps extends BaseAppRouteProps {
  layout: 'wizard';
  steps?: Map<string, WizardStep>;
  showBackButton?: boolean;
}

type AppRouteProps = DefaultAppRouteProps | LandingAppRouteProps | WizardAppRouteProps;

const unprotected = [ '/landing', '/company-select', '/verify-token' ];

function AppRoute(props: AppRouteProps): React.ReactElement {
  const {
    children,
    path,
    exact = false,
    title,
    authRequired = true,
    layout,
    ...rest
  } = props;
  const history = useHistory();
  const company = useSelector(selectSelectedCompany);
  const loginUrl = process.env.REACT_APP_LOGIN_URL ?? 'https://flores247.com/';

  useEffect(() => {
    if (authRequired) {
      if (!isLoggedIn()) {
        window.location.replace(loginUrl);
      }

      let isPartial = window.localStorage.getItem('isPartialLogin') === 'Y';

      if (isPartial) {
        history.push('/verify-token');
      } else if (!company && !unprotected.includes(path)) {
        history.push('/company-select');
      }
    }
  }, [ loginUrl, company, history, path, authRequired ]);

  function renderChildren() {
    if (props.layout === 'wizard') {
      const showButton = isNil(props.showBackButton) ? true : props.showBackButton;

      return <WizardLayout steps={props.steps} showBackButton={showButton}>{children as any}</WizardLayout>;
    }

    if (layout === 'landing') {
      return <>{children}</>;
    }

    return <DefaultLayout>{children as any}</DefaultLayout>;
  }

  function protectedRender(): React.ReactElement {
    if (!isLoggedIn() || (!company && !unprotected.includes(path))) {
      return (<div />);
    }

    if (!!children) {
      return (
        <Route
          path={path}
          exact={exact}
          {...rest}
          render={renderChildren}
        />
      );
    }

    return (<Route path={path} exact={exact} {...rest} />);
  }

  return protectedRender();
}

export default AppRoute;
