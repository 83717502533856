import { useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { compose, T, F, tap } from 'ramda';

import { selectSelectedCompany } from 'companies/companies.slice';
import { TerminateDetails } from './terminate-participant.types';
import withModal from 'components/shared/HOC/WithModal';
import TerminateParticipantForm, { TerminateParticipantFormProps } from './TerminateParticipantForm';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import TerminateParticipantRow from './TerminateParticipantRow';
import CancelModal from 'components/shared/CancelModal';
import request from 'shared/api-request';
import Loading from 'components/shared/Loading';

function TerminateParticipant() {
  const { compid, iddesc = 'SSN' } = useSelector(selectSelectedCompany) ?? { compid: undefined, iddesc: undefined };
  const handleError = useErrorNotifier();
  const [ terminateParticipants, setTerminateParticipants ] = useState<TerminateDetails[]>([]);
  const [ terminating, setTerminating ] = useState(false);

  const [ confirmModalIsOpen, setConfirmModalIsOpen ] = useState(false);
  const showConfirmModal = compose(setConfirmModalIsOpen, T);
  const hideConfirmModal = compose(setConfirmModalIsOpen, F);

  const [ cancelModalIsOpen, setCancelModalIsOpen ] = useState(false);
  const showCancelModal = compose(setCancelModalIsOpen, T);
  const hideCancelModal = compose(setCancelModalIsOpen, F);

  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const showAddModal = compose(setModalIsOpen, T);
  const hideAddModal = compose(setModalIsOpen, F);

  function handleAddParticipant(details: TerminateDetails) {
    if (hasMaxParticipants()) {
      handleError(new Error('Cannot add more than 10 participants.'));

      return;
    }

    if (terminateParticipants.some((t) => t.participant.empno === details.participant.empno)) {
      return;
    }

    setTerminateParticipants([ ...terminateParticipants, details ]);
    hideAddModal();
  }

  function handleRemove(details: TerminateDetails) {
    const index = terminateParticipants.findIndex((d) => d.participant.payrollid === details.participant.payrollid);

    if (index > -1) {
      setTerminateParticipants([
        ...terminateParticipants.slice(0, index),
        ...terminateParticipants.slice(index + 1),
      ]);
    }
  }

  function handleCancel() {
    setTerminateParticipants([]);
  }

  function getPayload() {
    return terminateParticipants.map((detail) => ({
      empno: detail.participant.empno,
      terminateBenefits: detail.terminateBenefits,
      lastDeduction: detail.lastDeduction,
    }));
  }

  function handleTerminate() {
    hideConfirmModal();

    if (terminateParticipants.length > 0) {
      setTerminating(true);
      const uri = `/api/companies/${compid}/participants`;
      request(uri, { method: 'DELETE', body: getPayload() })
        .then(() => {
          setTerminateParticipants([]);
          setTerminating(false);
        })
        .catch(compose(handleError, tap(compose(setTerminating, F))));
    }
  }

  function hasMaxParticipants() {
    return terminateParticipants.length > 9;
  }

  function buttons() {
    return (
      <div className="flex w-full justify-end space-x-12 my-8" >
        <Button
          color="primary"
          data-testid="terminate-participants-cancel"
          onClick={showCancelModal}
        >
            Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={showConfirmModal}
          data-testid="terminate-participants-button"
        >
            Terminate Participants
        </Button>
      </div>
    );
  }

  function terminateList() {
    return (
      <Paper className="p-8">
        <Table className="terminate-participants-table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{iddesc || 'SSN'}</TableCell>
              <TableCell>Date Benefits Terminate</TableCell>
              <TableCell>Last Deduction</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              terminateParticipants.map((details) => (
                <TerminateParticipantRow
                  key={details.participant.payrollid}
                  details={details}
                  onRemove={handleRemove}
                />
              ))
            }
          </TableBody>
        </Table>
        {buttons()}
      </Paper>
    );
  }

  const AddParticipantModal = withModal<TerminateParticipantFormProps>(TerminateParticipantForm);

  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">Terminate Participant</h2>
      <div className="w-full">
        <div className="w-full flex flex-row space-x-8 mb-8">
          <Paper className="w-1/2 py-6 px-4">
            <p className="mb-6 w-2/3">
              Enter termination dates for up to 10 participants
              to terminate all benefits in which the participant is currently enrolled.
            </p>
            <Button
              className="terminate-participant-show-add-modal"
              variant="outlined"
              color="primary"
              onClick={showAddModal}
              disabled={hasMaxParticipants()}
              data-testid="add-terminate-participant"
            >
              <AddIcon className="mr-2" />
              Add Participant
            </Button>
            {hasMaxParticipants() ? <p>Cannot add more than 10 participants.</p> : <></>}
          </Paper>
        </div>
        { terminateParticipants.length > 0 ? terminateList() : <></> }
      </div>
      <CancelModal
        isOpen={cancelModalIsOpen}
        onCancel={handleCancel}
        onClose={hideCancelModal}
        title="Cancel Termination?"
        prompt="This will remove the entered participants and no terminations will be processed."
        closeText="Proceed with Termination"
        cancelText="Yes, Cancel"
      />
      <CancelModal
        isOpen={confirmModalIsOpen}
        onCancel={handleTerminate}
        onClose={hideConfirmModal}
        title={`Terminate ${terminateParticipants.length} Participants?`}
        prompt="You can't undo this action."
        closeText="Cancel"
        cancelText="Yes! Terminate"
      />
      <AddParticipantModal
        isOpen={modalIsOpen}
        classes={ {
          modalContainer: 'bg-surface p-4 w-1/3',
        } }
        title="Participant Termination"
        onClose={hideAddModal}
        onCancel={hideAddModal}
        onAdd={handleAddParticipant}
      />
      { terminating ? <Loading text="Terminating participants..." /> : <></> }
    </div>
  );
}

export default TerminateParticipant;
