import React, { ReactElement, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import PageContentPaper from 'components/layout/PageContentPaper';
import DirectBillGeneral from './general/DirectBillGeneral';
import TabPanel from 'components/shared/TabPanel';
import CancelModal from 'components/shared/CancelModal';
import {
  selectCancelModalStatus,
  cancelModalClosed,
  ApiStatus,
  selectFetchPlansStatus,
  selectReEnrollmentPlansByPlanYearId,
  selectSaveDirectBillChecklistStatus,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { all, always, any, compose, cond, equals, T } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { selectSelectedCompany } from 'companies/companies.slice';
import { fetchReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import Skeleton from '@mui/material/Skeleton';
import Loading from 'components/shared/Loading';

type DirectBillTab = 'general' | 'plan-rates' | 'summary';

function DirectBillReEnrollmentChecklist() {
  usePageTitle('Direct Bill Enrollment');
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const cancelModalStatus = useSelector(selectCancelModalStatus);
  const plansRequestStatus = useSelector(selectFetchPlansStatus);
  const saveDirectBillChecklistStatus = useSelector(selectSaveDirectBillChecklistStatus);

  const { compid: companyId } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const { planYearId = '0', tab = 'general' } = useParams<{planYearId: string; tab?: DirectBillTab;}>();
  const plan = useSelector(selectReEnrollmentPlansByPlanYearId(+planYearId));

  const fetchStatus = cond<string[], ApiStatus>([
    [ any(equals('pending')), always('pending') ],
    [ any(equals('rejected')), always('rejected') ],
    [ all(equals('fulfilled')), always('fulfilled') ],
  ])([ plansRequestStatus ]);

  useEffect(() => {
    if (!plan) {
      dispatch(fetchReEnrollmentChecklistPlan(companyId));
    }
  }, [ planYearId, dispatch, plan, companyId ]);

  function handleTabChange (_: React.ChangeEvent<{}>, newTab: DirectBillTab) {
    history.push(`/reenrollment-checklist/direct-bill/${planYearId}/${newTab}`);
  }

  return (
    <>
      <PageContentPaper className="mt-4 mb-8" data-testid="cobra-plan-tabbed-form">
        <div className="flex flex-col justify-around">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            className="-ml-4 -mt-4 flex justify-around"
            value={tab}
            centered
            onChange={handleTabChange}
          >
            <Tab
              data-testid="general-tab"
              value="general"
              label="General"
            />
            <Tab
              data-testid="plan-rates-tab"
              value="plan-rates"
              label="Plan Rates"
            />
            <Tab
              data-testid="summary-tab"
              value="summary"
              label="Summary"
            />
          </Tabs>
          <hr />

          <TabPanel value={tab} index="general">
            {
              cond<string, ReactElement>([
                [ equals('pending'), always(
                  <>
                    <Skeleton variant="rectangular" height={60} className="w-2/5 my-5 mt-10" />
                    <Skeleton variant="rectangular" height={60} className="w-2/5 my-5" />
                    <Skeleton variant="rectangular" height={60} className="w-2/5 my-5" />
                  </>,
                ) ],
                [ T, always(<DirectBillGeneral />) ]],
              )(fetchStatus)
            }
          </TabPanel>
          <TabPanel value={tab} index="plan-rates">
            <div>
                Plan Rates
            </div>
          </TabPanel>
          <TabPanel value={tab} index="summary">
            <div>
                Summary
            </div>
          </TabPanel>
        </div>
      </PageContentPaper>
      {
        saveDirectBillChecklistStatus === 'pending'
          ? <Loading />
          : <></>
      }
      <CancelModal
        isOpen={cancelModalStatus === 'open'}
        onCancel={compose(history.push, always('/reenrollment-checklist'))}
        onClose={compose(dispatch, cancelModalClosed)}
      />
    </>
  );
}

export default DirectBillReEnrollmentChecklist;
