import { okResponse } from "./utils";

function routing(server) {
    server.get('/companies/:companyId/enrollment-resources/fsa/template-exists', okResponse(true));

    server.get('/companies/:companyId/enrollment-resources/fsa/download-template', okResponse(true));

    server.get(
        '/companies/:companyId/enrollment-resources/custom/:planYearId/:planId/:subPlanId/download-checklist',
        okResponse(true),
    );

    server.get('/companies/:companyId/enrollment-resources/plans', (schema) => {
        const { models: data } = schema.enrollmentResourcePlans.all();
        return okResponse(data)();
    });
}

export default routing;
