import store from 'store/index';
import { addHttpErrorNotification } from 'notifications/notifications.slice';

export const handleErrors = (appStore: typeof store) => (next: any) => async (action: any) => {
  if (action) {
    next(action);
  }

  if (action.error?.message === 'cancelled') {
    return;
  }

  if (action.type.includes('rejected') && action.payload === undefined) {
    appStore.dispatch(addHttpErrorNotification(new Error(action.error?.message ?? 'There was a problem')));
  }
};

export function handleErrorsMiddleware(): any {
  return (next: any) => async (action: any) => {
    return handleErrors(store)(next)(action);
  };
}
