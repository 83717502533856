import { useState } from 'react';
import { useSelector } from 'react-redux';

import ReportsTile from 'reports/shared/ReportsTile';
import { useCustomEnrollmentWithDepsCount } from './hooks/useCustomEnrollmentWithDepsCount';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { downloadCustomEnrollmentWithDepsReport } from 'shared/api/customEnrollmentWithDepsApi';
import { selectSelectedCompany } from '../../companies/companies.slice';

interface CustomEnrollmentWithDepsTileProps {
  selectedPlan?: OpenPlan;
}

function CustomEnrollmentWithDeps({ selectedPlan }: CustomEnrollmentWithDepsTileProps) {
  const count = useCustomEnrollmentWithDepsCount({ selectedPlan });
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const [ downloading, setDownloading ] = useState(false);
  const errorNotifier = useErrorNotifier();

  function handleExport() {
    if (selectedPlan) {
      setDownloading(true);
      downloadCustomEnrollmentWithDepsReport(compid, selectedPlan)
        .catch(errorNotifier)
        .finally(() => setDownloading(false));
    }
  }

  return (
    <ReportsTile
      count={count}
      title="Enrollment Roster With Dependents"
      description="Live data of participants enrolled in each
plan as selected including enrolled dependents."
      variant="primary-main"
      exportDisabled={downloading || !selectedPlan}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default CustomEnrollmentWithDeps;
