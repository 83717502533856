import React, { RefObject, KeyboardEvent, MouseEvent, useEffect, useRef, useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

import { Participant } from './participant-list.types';
import ParticipantResultsRow from './ParticipantResultsRow';
import { selectSearchResults, selectSearchText } from './participant.slice';
import AddParticipantItem from './AddParticipantItem';

interface ParticipantSearchResultsProps {
  anchorRef: RefObject<HTMLElement>;
  open: boolean;
  onClose: () => void;
  onParticipantSelected: (participant: Participant) => void;
}

export function popperLoadingIndicator(width: number): React.ReactElement {
  return (
    <div
      className="animate-pulse bg-gradient-to-b from-gray-400 to-transparent w-40 h-20"
      style={ { minWidth: `${width}px` } }
      data-testid="participant-results-loading"
    />
  );
}

function ParticipantSearchResults({
  anchorRef,
  open,
  onClose,
  onParticipantSelected,
}: ParticipantSearchResultsProps, ref: React.Ref<HTMLUListElement>): React.ReactElement {
  const [ width, setWidth ] = useState(0);
  const prevOpen = useRef(open);
  const participants = useSelector(selectSearchResults);
  const searchText = useSelector(selectSearchText);

  function handleParticipantSelect(participant: Participant) {
    onParticipantSelected(participant);
    onClose();
  }

  function handleClose(event: MouseEvent<EventTarget>) {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    onClose();
  }

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault();
      onClose();
    }
  }

  useEffect(() => {
    if (anchorRef.current !== null) {
      setWidth(anchorRef.current.offsetWidth);
    }

    // return focus to the element that handles visibility when we transitioned from !open -> open
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [ anchorRef, open ]);

  function defaultItems() {
    if (searchText.length) {
      return [
        <MenuItem disabled key="1">
          <p style={ { minWidth: `${width}px` } } className="p-4">No participants found</p>
        </MenuItem>,
        <AddParticipantItem key="2" />,
      ];
    }

    return <AddParticipantItem />;
  }

  function menuList(): React.ReactElement {
    if (participants?.length) {
      return (
        <MenuList
          ref={ref}
          autoFocusItem={open}
          id="menu-list-grow"
          onKeyDown={handleListKeyDown}
          style={ { minWidth: `${width}px` } }
        >
          {
            participants.map((p) => (
              <ParticipantResultsRow
                key={p.empno}
                participant={p}
                onParticipantSelected={handleParticipantSelect}
              />
            ))
          }
          <AddParticipantItem />
        </MenuList>
      );
    }

    return (
      <MenuList
        ref={ref}
        autoFocusItem={open}
        id="menu-list-grow"
        onKeyDown={handleListKeyDown}
        style={ { minWidth: `${width}px` } }
      >
        {defaultItems()}
      </MenuList>
    );
  }

  return (
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start">
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={ { transformOrigin: placement.includes('bottom') ? 'left top' : 'left bottom' } }
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose as any}>
              {participants === null ? popperLoadingIndicator(width) : menuList()}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default forwardRef<HTMLUListElement, ParticipantSearchResultsProps>(ParticipantSearchResults);
