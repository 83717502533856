import * as yup from 'yup';

import { payrollid, requiredText } from 'shared/common-validators';

export const validationSchema = yup.object({
  employeeNumber: payrollid.payrollid,
  terminateBenefits: yup
    .date()
    .nullable()
    .typeError('Terminate Benefits must be a valid date.')
    .required(requiredText),
  numDeductionDates: yup.number(),
  lastDeduction: yup
    .string()
    .nullable()
    .when('numDeductionDates', {
      is: (numDeductionDates: number) => (numDeductionDates > 0),
      then: yup
        .string()
        .trim()
        .nullable()
        .required(requiredText),
    }),
});
