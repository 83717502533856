import { useState, ChangeEvent } from 'react';
import { useParams } from 'react-router';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { ReportType } from './reports.types';
import ReportsTabPanels from './ReportsTabPanels';
import styles from './ReportsTabs.module.css';
import GlobalReports from './global/GlobalReports';

interface ReportsTabsProps {
  reportTypes: ReportType[];
}

const tabTypeMap: Map<ReportType, string> = new Map([
  [ 'balance', 'Balance Accounts' ],
  [ 'cobra', 'COBRA' ],
  [ 'retiree', 'Retiree Billing' ],
  [ 'loa', 'LOA Billing' ],
  [ 'custom', 'Custom Reimbursement' ],
  [ 'fmla', 'Leave Solutions' ],
]);

function ReportsTabs({ reportTypes }: ReportsTabsProps) {
  const { tab } = useParams<{tab?: ReportType;}>();
  const [ activeTab, setActiveTab ] = useState<ReportType>(tab ?? reportTypes[0]);

  function onChangeTab(e: ChangeEvent<{}>, newTab: ReportType) {
    if (newTab !== activeTab) {
      setActiveTab(newTab);
    }
  }

  return (
    <div className="w-full">
      <GlobalReports />
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        className="mb-4"
        value={activeTab}
        onChange={onChangeTab}
        classes={ { flexContainer: styles.tabsBottomBoder } }
      >
        {
          reportTypes.map((type) => (
            <Tab value={type} label={tabTypeMap.get(type)} key={`${type}-tab`} className="min-w-0 max-w-none" />
          ))
        }
        {
          !reportTypes.includes('fmla') && (
            <Tab
              value="fmla-marketing"
              label="Leave Solutions"
              key="fmla-marketing-tab"
              className="min-w-0 max-w-none"
              data-testid="fmla-marketing-tab"
            />
          )
        }
      </Tabs>
      <ReportsTabPanels
        reportTypes={!reportTypes.includes('fmla') ? [ ...reportTypes, 'fmla-marketing' ] : reportTypes}
        activeTab={activeTab}
      />
    </div>
  );
}

export default ReportsTabs;
