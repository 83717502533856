import * as React from 'react';
import { PdfTableCell, PdfTableCellProps } from './PdfTableCell';

interface PdfDataTableCellProps extends PdfTableCellProps {
    /**
     * The data associated with the cell.
     */
    data?: any;

    /**
     * The content to display for this cell.
     * @param data the data passed in.
     */
    getContent: (data: any) => React.ReactNode | JSX.Element | string | number;
}

/**
 * This component is used to display data in the the {@see TableRow} component.
 */
// eslint-disable-next-line react/prefer-stateless-function
export class PdfDataTableCell extends React.PureComponent<PdfDataTableCellProps> {
  render() {
    return (
      <PdfTableCell
        {...this.props}
      >
        {this.props.getContent(this.props.data)}
      </PdfTableCell>
    );
  }
}
