import React, { useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

interface LFSAFieldsProps {
  showLFSA: boolean;
  form: any;
  floresAdministered: boolean;
}

function LFSAFields({ showLFSA, form, floresAdministered }: LFSAFieldsProps): React.ReactElement {
  const labelRef = useRef<HTMLLabelElement>(null);

  function handleLevelChange({ target: { value: val } }: React.ChangeEvent<{ name?: string; value: unknown; }>) {
    form.setFieldValue('level', val);
  }

  function limitedPurposeCheck() {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="limitedPurpose"
              color="primary"
              value
              checked={form.values.limitedPurpose}
              onChange={form.handleChange}
              className="plan-enrollment-limited-purpose-checkbox"
            />
          }
          label="Limited Purpose"
          labelPlacement="end"
        />
        <FormHelperText>Select if employee is also enrolled in HSA</FormHelperText>
      </FormGroup>
    );
  }

  function levelSelect() {
    return (
      <FormControl
        variant="outlined"
        className={`${!form.values.limitedPurpose ? 'invisible' : ''} plan-enrollment-plan-level-select`}
      >
        <InputLabel ref={labelRef}>Level</InputLabel>
        <Select
          label="Level"
          native
          fullWidth
          name="level"
          value={form.values.level ?? ''}
          error={!!form.touched.level && !!form.errors.level}
          onChange={handleLevelChange as any}
        >
          <option />
          <option value="10">Employee Only</option>
          <option value="11">Family</option>
        </Select>
        {
          !!form.touched.level && !!form.errors.level
            ? <FormHelperText disabled error>{form.errors.level}</FormHelperText>
            : <></>
        }
      </FormControl>
    );
  }

  if (!showLFSA) {
    return <></>;
  }

  if (floresAdministered) {
    return (
      <p>
        Please note the Health Care FSA will be a Limited Purpose
        FSA if this participant is also enrolled in the HSA.
      </p>
    );
  }

  return (
    <>
      {limitedPurposeCheck()}
      {levelSelect()}
    </>
  );
}

export default LFSAFields;
