import { AnyAction } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import logger from 'redux-logger';

import rootReducer, { RootState } from './rootReducer';
import serverLogger from 'store/middleware/server-logger';
import syncCompanyLocalStorage from 'store/middleware/sync-company-local-storage';
import {
  validateCobraPlans,
  handleErrorsMiddleware,
  validateCobraPlanOnDeck,
  validateDirectBillChecklist,
} from 'store/middleware/validators';
import { dependentsMiddleware } from 'store/middleware/dependents/dependents';

const store = configureStore({
  reducer: rootReducer,
  middleware: process.env.REACT_APP_LOG_LEVEL === 'debug'
    ? (getDefaultMiddleware) => getDefaultMiddleware().concat([
      logger,
      serverLogger,
      syncCompanyLocalStorage,
      validateCobraPlans,
      validateCobraPlanOnDeck,
      validateDirectBillChecklist,
      handleErrorsMiddleware,
      dependentsMiddleware,
    ])
    : (getDefaultMiddleware) => getDefaultMiddleware().concat([
      serverLogger,
      syncCompanyLocalStorage,
      validateCobraPlans,
      validateCobraPlanOnDeck,
      validateDirectBillChecklist,
      handleErrorsMiddleware,
      dependentsMiddleware,
    ]),
});

export type AppDispatch = typeof store.dispatch

// todo: the type for this is wrong. void is R, the return from the action creating function
export type AppThunk = ThunkAction<Promise<void> | void, RootState, unknown, AnyAction>;

export default store;
