import React from 'react';

import InvoiceDetailsTable from './InvoiceDetailsTable';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { useInvoiceDetails } from './useInvoiceDetails';

interface ViewInvoiceDetailsProps {
  isCobra: boolean;
  invoiceNumber: number;
  isExpanded: boolean;
}

function ViewInvoiceDetails({ isCobra, invoiceNumber, isExpanded }: ViewInvoiceDetailsProps): React.ReactElement {
  const invoiceDetails = useInvoiceDetails(isCobra, invoiceNumber);

  const InvoiceDetails = withLoopIcon(
    InvoiceDetailsTable,
    'Loading invoice details...',
    invoiceDetails === null && isExpanded,
  );

  return <InvoiceDetails invoiceDetails={invoiceDetails} isExpanded={isExpanded} />;
}

export default ViewInvoiceDetails;
