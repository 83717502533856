import { always, equals, identity as id, ifElse } from 'ramda';

import request, { ContentType } from 'shared/api-request';
import { CRLType } from 'shared/types/non-plan-year.types';

export type ReportType = 'initial' | 'qe';

export function getPendingNoticeCount(companyId: number, type: ReportType, serviceType: CRLType) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load pending responses for invalid company.'));
  }

  return request<number>(`/api/companies/${companyId}/reports/pending-notices/export/count/${type}/${serviceType}`);
}

export function downloadPendingNoticeReport(
  companyId: number,
  serviceType: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download pending notices report for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/pending-notices/${serviceType}/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function getPendingNoticeSummary(
  companyId: number,
  serviceType: 'cobra-qe' | 'initial',
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load pending notices summary for invalid company.'));
  }

  const path = ifElse(equals('cobra-qe'), always('qe/cobra'), id)(serviceType);

  return request<number>(`/api/companies/${companyId}/reports/pending-notices/count/${path}`);
}
