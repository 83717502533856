import React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { always, complement, cond, equals, pathOr, isNil, compose, either, isEmpty } from 'ramda';
import { InsurerUpdateMethod } from 're-enrollment-checklist/cobra-reenrollment-checklist/cobra-re-enrollment.types';
import RatesTable from 're-enrollment-checklist/cobra-reenrollment-checklist/plan-rates/step-three/RatesTable';
import { CobraChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';

interface Props {
    plan: CobraChecklistPlan;
}

function SummaryAccordion({ plan }: Props) {
  const hasLevels = compose(
    equals('Y'),
    pathOr('', [ 'hasLevels' ]),
  )(plan);

  const hasUploadedFiles = compose(
    complement(either(isNil, isEmpty)),
    pathOr(null, [ 'reenrollmentRatesDocs' ]),
  )(plan);

  return (
    <AccordionDetails>
      <Typography>{plan.planName}</Typography>
      <div className="m-5 flex flex-wrap">

        <div className="w-1/4 p-1">
          <strong>
              Carrier/Provider:
          </strong>
          <p>{plan.carrier}</p>
        </div>

        {plan.planPolicyNumber && (
          <div className="w-1/4 p-1">
            <strong>
                Policy Number:
            </strong>
            <p>{plan.planPolicyNumber}</p>
          </div>
        )}

        <div className="w-1/4 p-1">
          <strong>
              Activate account based on:
          </strong>
          <p>{plan.activateAccountType === 0 ? 'Election' : 'Payment (recommended)'}</p>
        </div>

        <div className="w-1/4 p-1">
          <strong>
              Update on paid through dates:
          </strong>
          <p>{plan.updatePaidThrough === 1 ? 'Yes' : 'No'}</p>
        </div>

        <div className="w-1/4 p-1">
          <strong>
              Responsible for updating COBRA data for your Company with the insurance carriers?
          </strong>
          {cond([
            [ equals(InsurerUpdateMethod.ClientViaFridayReport),
              always(<p>Client via Flores report</p>) ],
            [ equals(InsurerUpdateMethod.CarrierByTuesdayFile),
              always(<p>Insurer via Flores standard update file</p>) ],
            [ equals(InsurerUpdateMethod.Other), always(<p>Other</p>) ],
          ])(plan.carrierUpdateMethod as never)}
        </div>

        <div className="w-1/4 p-1">
          <strong>
              Eligibility Updates - Contact Name
          </strong>
          <p>{plan.carrierContactName}</p>
        </div>

        {!!plan.carrierContactPhone && (
          <div className="w-1/4 p-1">
            <strong>
                Eligibility Updates - Contact Phone
            </strong>
            <p>{plan.carrierContactPhone}</p>
          </div>
        )}
      </div>
      {!hasLevels && hasUploadedFiles && (
        <div className="w-full">
          <strong>Rate Information Files</strong>
          {plan.reenrollmentRatesDocs.map(i => (<p key={i.name}>{i.name}.{i.extension}</p>))}
        </div>
      )}
      {hasLevels && <RatesTable plans={plan.planLevels} disabled />}
    </AccordionDetails>
  );
}

export default SummaryAccordion;
