import React from 'react';
import { useHistory } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { EmployeeSearchResults } from 'leave-records/leaveRecord.types';
import { avatarColor, getInitials } from 'components/shared/avatar-color-palette';

interface Props {
  employeeSearchResult: EmployeeSearchResults;
  onClose: () => void;
}

export function LeaveRecordsSearchResultRow({ employeeSearchResult: result, onClose }: Readonly<Props>) {
  const history = useHistory();

  function onSelect() {
    history.push(`/leave-records/all-cases/${result.eeId}`);
    onClose();
  }

  return (
    <div data-testid="leave-result-item">
      <MenuItem
        onClick={onSelect}
        className="flex justify-between w-full flex-nowrap space-x-3"
        key={result.eeId}
      >
        <Avatar
          className={`p-2 w-10 text-sm ${avatarColor(result.firstName, result.lastName)}`}
        >
          {getInitials(result.firstName, result.lastName)}
        </Avatar>
        <p className="whitespace-nowrap flex-grow">
          {result.firstName} {result.lastName}
        </p>
        <p className="whitespace-nowrap">
          {result.eeId}
        </p>
        {!!result.taxIdLast4 && (
          <p className="whitespace-nowrap">
            ***-**-{result.taxIdLast4}
          </p>
        )}
      </MenuItem>
    </div>
  );
}
