import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  allPass,
  always,
  anyPass,
  append,
  compose,
  concat,
  either,
  equals,
  find,
  groupBy,
  head,
  ifElse,
  last,
  lensPath,
  map,
  pathOr,
  pick,
  propEq,
  set,
  view,
} from 'ramda';
import getPercentageFromBooleanArray from 'shared/utils/get-percentage-from-boolean-array';

import { RootState } from 'store/rootReducer';
import {
  CobraChecklistPlan,
  CobraReEnrollmentChecklistPlan,
  CoverageType,
  CustomReEnrollmentPlanInformation,
  DirectBillReEnrollmentChecklistPlan,
  FileListItem,
  OnDeckCobraPlan,
  PlanLevelDescription,
  ReEnrollmentChecklistPlan,
} from './re-enrollment-checklist.types';
import { applyPlanTypeDiscriminator } from './re-enrollment-utilities';
import {
  completeChecklist,
  deleteOeDocument,
  fetchCobraChecklistPlans,
  fetchCobraInsurers,
  fetchCobraPlanTypes,
  fetchCoverageTypes,
  fetchCustomPlans,
  fetchReEnrollmentChecklistPlan,
  finalizeCobraPlans,
  generateOeDocuments,
  saveCobraOePacketInfo,
  saveCobraPlan,
  deleteCobraInProgressPlan,
  saveDirectBillChecklist,
  fetchPlanLevelDescriptions,
} from './re-enrollment-checklist.thunks';
import {
  CobraInsurer,
  CobraPlanType,
  InsurerUpdateMethod,
} from './cobra-reenrollment-checklist/cobra-re-enrollment.types';
import { ActivateAccountType } from 'shared/enums/insurers.enum';

export type ApiStatus = 'uninitialized' | 'pending' | 'rejected' | 'fulfilled';

export type CancelModalStatus = 'open' | 'closed';

export type PlanValidation = Record<string, any>;

export interface ReEnrollmentChecklistState {
  fetchPlansStatus: ApiStatus;
  saveOePacketInfoStatus: ApiStatus;
  submitFileStatus: ApiStatus;
  completeChecklistRequestStatus: ApiStatus;
  cancelModalStatus: CancelModalStatus;
  redirectModalStatus: CancelModalStatus;
  saveCobraPlanStatus: ApiStatus;
  fetchCobraChecklistPlansStatus: ApiStatus;
  generateOeDocumentsStatus: ApiStatus;
  plans: ReEnrollmentChecklistPlan[];
  cobraReEnrollmentChecklistPlans: CobraChecklistPlan[];
  cobraPlanOnDeck: OnDeckCobraPlan;
  validations: PlanValidation[];
  planRateWizardStepIndex: number;
  cobraPlanTypes: CobraPlanType[];
  cobraPlanTypesStatus: ApiStatus;
  cobraInsurers: CobraInsurer[];
  cobraInsurersStatus: ApiStatus;
  cobraPlanOnDeckValidations: Record<string, string>;
  fetchCustomPlanStatus: ApiStatus;
  customPlans: CustomReEnrollmentPlanInformation[];
  coverageTypes: CoverageType[];
  planLevelDescriptions: PlanLevelDescription[];
  fetchCoverageTypesRequestStatus: ApiStatus;
  fetchPlanLevelDescriptionsStatus: ApiStatus;
  deleteCobraInProgressPlanStatus: ApiStatus;
  saveDirectBillChecklistStatus: ApiStatus;
}

export const initialState: ReEnrollmentChecklistState = {
  fetchPlansStatus: 'uninitialized',
  saveOePacketInfoStatus: 'uninitialized',
  submitFileStatus: 'uninitialized',
  completeChecklistRequestStatus: 'uninitialized',
  saveCobraPlanStatus: 'uninitialized',
  fetchCobraChecklistPlansStatus: 'uninitialized',
  generateOeDocumentsStatus: 'uninitialized',
  cancelModalStatus: 'closed',
  redirectModalStatus: 'closed',
  plans: [],
  cobraReEnrollmentChecklistPlans: [],
  cobraPlanOnDeck: {},
  validations: [],
  planRateWizardStepIndex: 0,
  cobraPlanTypes: [],
  cobraPlanTypesStatus: 'uninitialized',
  cobraInsurers: [],
  cobraInsurersStatus: 'uninitialized',
  cobraPlanOnDeckValidations: {},
  fetchCustomPlanStatus: 'uninitialized',
  customPlans: [],
  coverageTypes: [],
  planLevelDescriptions: [],
  fetchCoverageTypesRequestStatus: 'uninitialized',
  fetchPlanLevelDescriptionsStatus: 'uninitialized',
  deleteCobraInProgressPlanStatus: 'uninitialized',
  saveDirectBillChecklistStatus: 'uninitialized',
};

const reEnrollmentChecklistState = createSlice({
  name: 'reEnrollmentChecklist',
  initialState,
  reducers: {
    setPlans(state, { payload }: PayloadAction<ReEnrollmentChecklistPlan[]>) {
      state.plans = payload;
    },
    setCobraOeHandler(state, { payload }: PayloadAction<number | undefined>) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'cobraOeHandler' ]);
      state.plans = set(lens, payload, state.plans);
    },
    setCobraOeTemplateCoverLetter(state, { payload }: PayloadAction<boolean>) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'cobraOeTemplateCoverLetter' ]);
      state.plans = set(lens, payload, state.plans);
    },
    setCobraOeElectionFormApproved(state, { payload }: PayloadAction<boolean>) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'formReviewStatus', 'cobraElection' ]);
      state.plans = set(lens, payload, state.plans);
    },
    setCobraOeCoverLetterApproved(state, { payload }: PayloadAction<boolean>) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'formReviewStatus', 'coverLetter' ]);
      state.plans = set(lens, payload, state.plans);
    },
    setCobraOePrintOptionsBlackAndWhite(state, { payload }: PayloadAction<boolean>) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'printOptions', 'blackAndWhite' ]);
      state.plans = set(lens, payload, state.plans);
    },
    setCobraOePrintOptionsTwoSided(state, { payload }: PayloadAction<boolean>) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'printOptions', 'twoSided' ]);
      state.plans = set(lens, payload, state.plans);
    },
    setCobraOePacketFiles(state, { payload }: PayloadAction<FileListItem[]>) {
      state.plans = makeUpdatedCobraPlan<FileListItem[]>(state.plans, 'oePacketFiles', payload);
    },
    clearOePacketFiles(state) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'oePacketFiles' ]);
      state.plans = set(lens, [], state.plans);
    },
    addOePacketFiles(state, { payload: filesToAdd }: PayloadAction<FileListItem[]>) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'oePacketFiles' ]);
      let files: FileListItem[] = view(lens, state.plans);
      state.plans = set(lens, files.concat(filesToAdd), state.plans);
    },
    removeOePacketFileByName(state, { payload: fileName }: PayloadAction<string>) {
      let index = state.plans.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index, 'oePacketFiles' ]);
      let files: FileListItem[] = view(lens, state.plans);
      state.plans = set(lens, files.filter(file => file.fullName !== fileName), state.plans);
    },
    setCobraOeSendPackets(state, { payload }: PayloadAction<string>) {
      state.plans = makeUpdatedCobraPlan<string>(state.plans, 'cobraOeSendPackets', payload);
    },
    setCobraOeResponseDeadline(state, { payload }: PayloadAction<string>) {
      state.plans = makeUpdatedCobraPlan<string>(state.plans, 'cobraOeResponseDeadline', payload);
    },
    setCobraOeResponseRequired(state, { payload }: PayloadAction<number>) {
      state.plans = makeUpdatedCobraPlan<number>(state.plans, 'cobraOeResponseRequired', payload);
    },
    setValidations(state, { payload }: PayloadAction<PlanValidation[]>) {
      state.validations = payload;
    },
    setCobraOeValidations(state, { payload }: PayloadAction<PlanValidation>) {
      let index = state.validations.findIndex(p => p.type === 'cobra');
      let lens = lensPath([ index ]);
      state.validations = set(lens, payload, state.validations);
    },
    setCobraOeSubmitFileStatus(state, { payload }: PayloadAction<ApiStatus>) {
      state.submitFileStatus = payload;
    },
    cancelModalOpened(state) {
      state.cancelModalStatus = 'open';
    },
    cancelModalClosed(state) {
      state.cancelModalStatus = 'closed';
    },
    setCobraPlanNewEligibilityCount(state, { payload }: PayloadAction<number>) {
      state.plans = makeUpdatedCobraPlan<number>(state.plans, 'cobraOeNewEligibleCount', payload);
    },
    planRateWizardStepIndexChanged(state, { payload }: PayloadAction<number>) {
      state.planRateWizardStepIndex = payload;
    },
    setNewCobraPlanOnDeck(state, { payload }: PayloadAction<number | null>) {
      const plan: any = state.cobraReEnrollmentChecklistPlans
        .find(p => p.planInProgressId === payload);
      state.cobraPlanOnDeck = plan ?? {};
    },
    setExistingCobraPlanOnDeck(state, { payload }: PayloadAction<number | null>) {
      const plan: any = state.cobraReEnrollmentChecklistPlans
        .find(p => p.planId === payload);
      state.cobraPlanOnDeck = plan ?? {};
    },
    setCobraPlanOnDeckPlanName(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'planName' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckInsurer(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'carrier' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckPlanTypeId(state, { payload }: PayloadAction<number>) {
      const coverageTypes = state.coverageTypes;
      let name = pathOr(
        '',
        [ 'name' ],
        find(propEq('planTypeId', payload), coverageTypes));

      if (payload !== 8) {
        state.cobraPlanOnDeck = set(
          lensPath([ 'planTypeDescription' ]), undefined, state.cobraPlanOnDeck,
        );
      }

      state.cobraPlanOnDeck = set(lensPath([ 'planTypeName' ]), name, state.cobraPlanOnDeck);
      state.cobraPlanOnDeck = set(lensPath([ 'planTypeId' ]), payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckPlanTypeName(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'planTypeName' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckPolicyNumber(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'planPolicyNumber' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckActivateAccountType(state, { payload }: PayloadAction<number>) {
      let lens = lensPath([ 'activateAccountType' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckUpdatePaidThrough(state, { payload }: PayloadAction<number>) {
      let lens = lensPath([ 'updatePaidThrough' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraInsurers(state, { payload }:PayloadAction<CobraInsurer[]>) {
      state.cobraInsurers = payload;
    },
    setCobraPlanOnDeckPlanTypeDescription(state, { payload }: PayloadAction<string | undefined>) {
      let lens = lensPath([ 'planTypeDescription' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckHasLevels(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'hasLevels' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckNewCarrierName(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'newCarrierName' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierContactName(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'carrierContactName' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierContactEmail(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'carrierContactEmail' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
      lens = lensPath([ 'carrierContactEmailArray' ]);
      const newArr = payload.length > 0 ? payload.split(';') : [];
      state.cobraPlanOnDeck = set(lens, newArr, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierContactEmailTemp(state, { payload }: PayloadAction<string>) {
      const lens = lensPath([ 'carrierContactEmailTemp' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierContactEmailArray(state, { payload }: PayloadAction<string[]>) {
      const lens = lensPath([ 'carrierContactEmailArray' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierContactPhone(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'carrierContactPhone' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierId(state, { payload }: PayloadAction<number>) {
      let lens = lensPath([ 'carrierId' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierNameAndId(state, { payload }: PayloadAction<string>) {
      let carrierLens = lensPath([ 'carrier' ]);
      state.cobraPlanOnDeck = set(carrierLens, payload, state.cobraPlanOnDeck);

      const insurers = state.cobraInsurers;

      const insurerId = pathOr(
        null,
        [ 'cobraInsurerId' ],
        find(propEq('insurer', payload), insurers),
      );

      let idLens = lensPath([ 'carrierId' ]);
      state.cobraPlanOnDeck = set(idLens, insurerId, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierUpdateMethod(state, { payload }: PayloadAction<InsurerUpdateMethod>) {
      const methodLens = lensPath([ 'carrierUpdateMethod' ]);
      state.cobraPlanOnDeck = set(methodLens, payload, state.cobraPlanOnDeck);

      if (payload !== InsurerUpdateMethod.Other) {
        const notesLens = lensPath([ 'carrierUpdateMethodNotes' ]);
        state.cobraPlanOnDeck = set(notesLens, '', state.cobraPlanOnDeck);
      }
    },
    setCobraPlanOnDeckCarrierUpdateMethodNotes(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'carrierUpdateMethodNotes' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckCarrierNotes(state, { payload }: PayloadAction<string>) {
      let lens = lensPath([ 'carrierNotes' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckInit(state, { payload }: PayloadAction<boolean>) {
      let lens = lensPath([ 'cobraPlanOnDeckInit' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckValidations(state, { payload }:PayloadAction<Record<string, string>>) {
      state.cobraPlanOnDeckValidations = payload;
    },
    setCobraPlanOnDeckNewPlanLevel(state, { payload }) {
      state.cobraPlanOnDeck.planLevels = append(
          { levelCoverageTier: payload.levelName, levelDescriptionId: payload.levelId } as any,
          state.cobraPlanOnDeck.planLevels ?? [],
      );
    },
    resetCobraPlanOnDeckNewPlanLevels(state) {
      state.cobraPlanOnDeck.planLevels = [];
    },
    setCobraPlanOnDeckConfirmRates(state, { payload }: PayloadAction<boolean>) {
      let lens = lensPath([ 'confirmRates' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckPlanLevelEffectiveTo(state, { payload }) {
      state.cobraPlanOnDeck.planLevels = state
        .cobraPlanOnDeck
        .planLevels
        ?.map(level => ({ ...level, levelCostEndDate: payload }));
    },
    setCobraPlanOnDeckPlanLevelEffectiveFrom(state, { payload }) {
      state.cobraPlanOnDeck.planLevels = state
        .cobraPlanOnDeck
        .planLevels
        ?.map(level => ({ ...level, levelCostBeginDate: payload }));
    },
    setCobraPlanOnDeckPlanRate(state, { payload }: PayloadAction<{levelCoverageTier: string; levelCost: number;}>) {
      let index = state
        .cobraPlanOnDeck
        .planLevels
        ?.findIndex(p => p.levelCoverageTier === payload.levelCoverageTier) ?? -1;
      let lens = lensPath([ index, 'levelCost' ]);
      state.cobraPlanOnDeck.planLevels = set(lens, payload.levelCost, state.cobraPlanOnDeck.planLevels);
    },
    removeCobraPlanOnDeckPlanLevel(state, { payload }: PayloadAction<string>) {
      state.cobraPlanOnDeck.planLevels = state
        .cobraPlanOnDeck
        .planLevels
        ?.filter(p => p.levelCoverageTier !== payload) ?? [];
    },
    setCobraPlanOnDeckRatesDocuments(
      state,
      { payload }: PayloadAction<{ files: FileListItem[]; planInProgressId: number; }>,
    ) {
      const { files, planInProgressId } = payload;
      let lens = lensPath([ 'reenrollmentRatesDocs' ]);

      const planIndex = state.cobraReEnrollmentChecklistPlans
        .findIndex(p => p.planInProgressId === planInProgressId);

      if (planIndex !== -1) {
        state.cobraReEnrollmentChecklistPlans[planIndex] =
          set(lens, files, state.cobraReEnrollmentChecklistPlans[planIndex]);
      }

      state.cobraPlanOnDeck = set(lens, files, state.cobraPlanOnDeck);
    },
    setCobraPlanOnDeckPlanInProgressId(state, { payload }: PayloadAction<number>) {
      let lens = lensPath([ 'planInProgressId' ]);
      state.cobraPlanOnDeck = set(lens, payload, state.cobraPlanOnDeck);
    },
    setRedirectModalStatus(state, { payload }: PayloadAction<CancelModalStatus>) {
      state.redirectModalStatus = payload;
    },
    resetCobraPlanOnDeckRatesDocuments(state) {
      state.cobraPlanOnDeck.reenrollmentRatesDocs = [];
    },
    setCobraPlanTerminating(state, { payload }: PayloadAction<CobraChecklistPlan>) {
      const planIndex = state.cobraReEnrollmentChecklistPlans
        .findIndex(p => p.planId === payload.planId);
      const lens = lensPath([ 'terminating' ]);
      state.cobraReEnrollmentChecklistPlans[planIndex] =
        set(lens, !payload.terminating, state.cobraReEnrollmentChecklistPlans[planIndex]);
    },
    setOeValidationsByPlanType(state, { payload }: PayloadAction<PlanValidation>) {
      let index = state.validations.findIndex(p => p.type === payload.type);
      let lens = lensPath([ index ]);
      state.validations = set(lens, payload, state.validations);
    },
    setDirectBillOeBegins(state, { payload }: PayloadAction<string>) {
      state.plans = updatePlanByType<string>(state.plans, 'directBillOeBegins', payload, 'directBill');
    },
    setDirectBillOeEnds(state, { payload }: PayloadAction<string>) {
      state.plans = updatePlanByType<string>(state.plans, 'directBillOeEnds', payload, 'directBill');
    },
    setDirectBillResponseDeadline(state, { payload }: PayloadAction<string>) {
      state.plans = updatePlanByType<string>(state.plans, 'directBillResponseDeadline', payload, 'directBill');
    },
  },
  extraReducers: (builder) => {
    const saveOePacketInfo = (state: ReEnrollmentChecklistState, action: any) => {
      state.saveOePacketInfoStatus = 'fulfilled';

      if (!!action.payload) {
        let index = state.plans.findIndex(p => p.type === 'cobra');
        state.plans = state.plans.map((plan, idx) => {
          if (idx !== index) {
            return plan;
          }

          return applyPlanTypeDiscriminator({
            ...plan,
            ...action.payload,
          });
        });
      }
    };

    builder
      .addCase(fetchReEnrollmentChecklistPlan.pending, (state) => {
        state.fetchPlansStatus = 'pending';
      })
      .addCase(fetchReEnrollmentChecklistPlan.rejected, (state) => {
        state.fetchPlansStatus = 'rejected';
      })
      .addCase(fetchReEnrollmentChecklistPlan.fulfilled, (state, action) => {
        state.fetchPlansStatus = 'fulfilled';
        state.plans = action
          .payload
          .map(applyPlanTypeDiscriminator)
          .map(plan => ({ ...plan, activateAccountType: plan.activateAccountType ?? ActivateAccountType.PAYMENT }));
        state.validations =
            compose(
              map(pick([ 'type' , 'planYearStart' ])),
              map(applyPlanTypeDiscriminator),
            )(action.payload);
      })
      .addCase(saveCobraOePacketInfo.pending, (state) => {
        state.saveOePacketInfoStatus = 'pending';
      })
      .addCase(saveCobraOePacketInfo.rejected, (state) => {
        state.saveOePacketInfoStatus = 'rejected';
      })
      .addCase(saveCobraOePacketInfo.fulfilled, saveOePacketInfo)
      .addCase(completeChecklist.pending, (state) => {
        state.completeChecklistRequestStatus = 'pending';
      })
      .addCase(completeChecklist.rejected, (state) => {
        state.completeChecklistRequestStatus = 'rejected';
      })
      .addCase(completeChecklist.fulfilled, (state) => {
        state.completeChecklistRequestStatus = 'fulfilled';
      })
      .addCase(saveCobraPlan.pending, (state) => {
        state.saveCobraPlanStatus = 'pending';
      })
      .addCase(saveCobraPlan.rejected, (state) => {
        state.saveCobraPlanStatus = 'rejected';
      })
      .addCase(saveCobraPlan.fulfilled, (state, action) => {
        state.saveCobraPlanStatus = 'fulfilled';
        state.cobraReEnrollmentChecklistPlans = action.payload;
      })
      .addCase(fetchCobraChecklistPlans.pending, (state) => {
        state.fetchCobraChecklistPlansStatus = 'pending';
      })
      .addCase(fetchCobraChecklistPlans.rejected, (state) => {
        state.fetchCobraChecklistPlansStatus = 'rejected';
      })
      .addCase(fetchCobraChecklistPlans.fulfilled, (state, action) => {
        state.fetchCobraChecklistPlansStatus = 'fulfilled';
        state.cobraReEnrollmentChecklistPlans = action.payload;
      })
      .addCase(fetchCobraPlanTypes.pending, (state) => {
        state.cobraPlanTypesStatus = 'pending';
      })
      .addCase(fetchCobraPlanTypes.rejected, (state) => {
        state.cobraPlanTypesStatus = 'rejected';
      })
      .addCase(fetchCobraPlanTypes.fulfilled, (state, action) => {
        state.cobraPlanTypesStatus = 'fulfilled';
        state.cobraPlanTypes = action.payload;
      })
      .addCase(fetchCobraInsurers.pending, (state) => {
        state.cobraInsurersStatus = 'pending';
      })
      .addCase(fetchCobraInsurers.rejected, (state) => {
        state.cobraInsurersStatus = 'rejected';
      })
      .addCase(fetchCobraInsurers.fulfilled, (state, action) => {
        state.cobraInsurersStatus = 'fulfilled';
        state.cobraInsurers = action.payload;
      })
      .addCase(fetchCustomPlans.pending, (state) => {
        state.fetchCustomPlanStatus = 'pending';
      })
      .addCase(fetchCustomPlans.rejected, (state) => {
        state.fetchCustomPlanStatus = 'rejected';
      })
      .addCase(fetchCustomPlans.fulfilled, (state, action) => {
        state.fetchCustomPlanStatus = 'fulfilled';
        state.customPlans = action.payload;
      })
      // stealing these statuses since they all return the same data
      // and will be the same trigger to a loading indicator
      .addCase(deleteOeDocument.pending, (state) => {
        state.saveOePacketInfoStatus = 'pending';
      })
      .addCase(deleteOeDocument.rejected, (state) => {
        state.saveOePacketInfoStatus = 'rejected';
      })
      .addCase(deleteOeDocument.fulfilled, saveOePacketInfo)
      .addCase(generateOeDocuments.pending, (state) => {
        state.saveOePacketInfoStatus = 'pending';
        state.generateOeDocumentsStatus = 'pending';
      })
      .addCase(generateOeDocuments.rejected, (state) => {
        state.saveOePacketInfoStatus = 'rejected';
        state.generateOeDocumentsStatus = 'rejected';
      })
      .addCase(generateOeDocuments.fulfilled, saveOePacketInfo)
      .addCase(finalizeCobraPlans.pending, (state) => {
        state.saveOePacketInfoStatus = 'pending';
      })
      .addCase(finalizeCobraPlans.rejected, (state) => {
        state.saveOePacketInfoStatus = 'rejected';
      })
      .addCase(finalizeCobraPlans.fulfilled, (state, action) => {
        state.saveOePacketInfoStatus = 'fulfilled';
        state.cobraReEnrollmentChecklistPlans = action.payload;
      })
      .addCase(fetchCoverageTypes.pending, (state) => {
        state.fetchCoverageTypesRequestStatus = 'pending';
      })
      .addCase(fetchCoverageTypes.rejected, (state) => {
        state.fetchCoverageTypesRequestStatus = 'rejected';
      })
      .addCase(fetchCoverageTypes.fulfilled, (state, action) => {
        state.fetchCoverageTypesRequestStatus = 'fulfilled';
        state.coverageTypes = action.payload;
      })
      .addCase(fetchPlanLevelDescriptions.pending, (state) => {
        state.fetchPlanLevelDescriptionsStatus = 'pending';
      })
      .addCase(fetchPlanLevelDescriptions.rejected, (state) => {
        state.fetchPlanLevelDescriptionsStatus = 'rejected';
      })
      .addCase(fetchPlanLevelDescriptions.fulfilled, (state, action) => {
        state.fetchPlanLevelDescriptionsStatus = 'fulfilled';
        state.planLevelDescriptions = action.payload;
      })
      .addCase(deleteCobraInProgressPlan.pending, (state) => {
        state.deleteCobraInProgressPlanStatus = 'pending';
      })
      .addCase(deleteCobraInProgressPlan.rejected, (state) => {
        state.deleteCobraInProgressPlanStatus = 'rejected';
      })
      .addCase(deleteCobraInProgressPlan.fulfilled, (state, action) => {
        state.deleteCobraInProgressPlanStatus = 'fulfilled';
        state.cobraReEnrollmentChecklistPlans = action.payload;
      })
      .addCase(saveDirectBillChecklist.pending, (state) => {
        state.saveDirectBillChecklistStatus = 'pending';
      })
      .addCase(saveDirectBillChecklist.rejected, (state) => {
        state.saveDirectBillChecklistStatus = 'rejected';
      })
      .addCase(saveDirectBillChecklist.fulfilled, (state) => {
        state.saveDirectBillChecklistStatus = 'fulfilled';
      });
  },
});

export const {
  setPlans,
  setCobraOeHandler,
  setCobraOeTemplateCoverLetter,
  setCobraOeElectionFormApproved,
  setCobraOeCoverLetterApproved,
  clearOePacketFiles,
  addOePacketFiles,
  removeOePacketFileByName,
  setCobraOeSendPackets,
  setCobraOeResponseDeadline,
  setCobraOeResponseRequired,
  setCobraOeValidations,
  setCobraOePacketFiles,
  setValidations,
  setCobraOePrintOptionsBlackAndWhite,
  setCobraOePrintOptionsTwoSided,
  setCobraOeSubmitFileStatus,
  cancelModalClosed,
  cancelModalOpened,
  setCobraPlanNewEligibilityCount,
  planRateWizardStepIndexChanged,
  setNewCobraPlanOnDeck,
  setExistingCobraPlanOnDeck,
  setCobraPlanOnDeckPlanName,
  setCobraPlanOnDeckInsurer,
  setCobraPlanOnDeckPlanTypeId,
  setCobraPlanOnDeckPlanTypeName,
  setCobraPlanOnDeckPlanTypeDescription,
  setCobraPlanOnDeckPolicyNumber,
  setCobraPlanOnDeckActivateAccountType,
  setCobraPlanOnDeckUpdatePaidThrough,
  setCobraInsurers,
  setCobraPlanOnDeckHasLevels,
  setCobraPlanOnDeckNewCarrierName,
  setCobraPlanOnDeckCarrierContactName,
  setCobraPlanOnDeckCarrierContactEmail,
  setCobraPlanOnDeckCarrierContactEmailTemp,
  setCobraPlanOnDeckCarrierContactEmailArray,
  setCobraPlanOnDeckCarrierContactPhone,
  setCobraPlanOnDeckCarrierId,
  setCobraPlanOnDeckCarrierUpdateMethod,
  setCobraPlanOnDeckCarrierUpdateMethodNotes,
  setCobraPlanOnDeckCarrierNotes,
  setCobraPlanOnDeckInit,
  setCobraPlanOnDeckValidations,
  setCobraPlanOnDeckNewPlanLevel,
  resetCobraPlanOnDeckNewPlanLevels,
  setCobraPlanOnDeckConfirmRates,
  setCobraPlanOnDeckPlanRate,
  removeCobraPlanOnDeckPlanLevel,
  setCobraPlanOnDeckPlanLevelEffectiveTo,
  setCobraPlanOnDeckPlanLevelEffectiveFrom,
  setCobraPlanOnDeckRatesDocuments,
  setCobraPlanOnDeckPlanInProgressId,
  setRedirectModalStatus,
  resetCobraPlanOnDeckRatesDocuments,
  setCobraPlanTerminating,
  setOeValidationsByPlanType,
  setDirectBillOeBegins,
  setDirectBillOeEnds,
  setDirectBillResponseDeadline,
  setCobraPlanOnDeckCarrierNameAndId,
} = reEnrollmentChecklistState.actions;

export default reEnrollmentChecklistState.reducer;

const makeUpdatedCobraPlan = <T>(
  plans: ReEnrollmentChecklistPlan[],
  path: string,
  payload: T,
) => {
  let index = plans.findIndex(p => p.type === 'cobra');
  let lens = lensPath([ index, path ]);

  return set(lens, payload, plans);
};

const updatePlanByType = <T>(
  plans: ReEnrollmentChecklistPlan[],
  path: string,
  payload: T,
  planType: string,
) => {
  let index = plans.findIndex(p => p.type === planType);
  let lens = lensPath([ index, path ]);

  return set(lens, payload, plans);
};

const viewLens = (plans: ReEnrollmentChecklistPlan[], path: string, type: string) => {
  const index = plans.findIndex(p => p.type === type);
  const newLens = lensPath([ index, path ]);

  return view(newLens, plans);
};

export const selectFetchPlansStatus = (state: RootState) => state.reEnrollmentChecklist.fetchPlansStatus;

export const selectReEnrollmentChecklistPlans = (state: RootState) => state.reEnrollmentChecklist.plans;

export const selectReEnrollmentPlansByPlanYearId = (planYearId: number) => (state: RootState) => state
  .reEnrollmentChecklist
  .plans.find((p) => p.planYearId === planYearId);

export const selectReEnrollmentChecklistRenewalDate = (state: RootState): string => {
  const sortedDates = state.reEnrollmentChecklist.plans
    .map(p => p.renewalDate)
    .sort((a: string, b: string) => dayjs(a).unix() - dayjs(b).unix());

  const closestFutureDate = compose(
    head,
    (dates: string[]) => dates
      .filter((d) => dayjs(d).isAfter(dayjs())),
  )(sortedDates) as string;

  const closestPastDate = last(sortedDates) as string;

  return !!closestFutureDate ? closestFutureDate : closestPastDate;
};

export const selectCobraPlan = (state: RootState): CobraReEnrollmentChecklistPlan => {
  return (state.reEnrollmentChecklist.plans
    .find(plan => plan.type === 'cobra') ?? {}) as CobraReEnrollmentChecklistPlan;
};

export const selectCobraOeProgress = (state: RootState) => {
  const cobraPlan = selectCobraPlan(state);
  const {
    coverLetterSectionComplete = false,
    oePacketsSectionComplete = false,
    oePacketsChecklistComplete = false,
  } = cobraPlan;
  const statuses = {
    oePacketsChecklistComplete,
  };
  const optionalStatuses = {
    coverLetterSectionComplete,
    oePacketsSectionComplete,
  };

  const steps = Object.values(statuses);
  const optionalSteps = Object.values(optionalStatuses);
  const cobraHandler = selectCobraOeHandler(state) === 0;
  const templateCoverLetter = selectCobraOeTemplateCoverLetter(state);
  const totalComplete = (cobraHandler && !templateCoverLetter) ? steps : concat(steps, optionalSteps);

  return getPercentageFromBooleanArray(totalComplete);
};

export const selectCobraChecklistProgress = (state: RootState) => {
  const cobraPlan = selectCobraPlan(state);
  const isPlanRatesInvalid = selectPlanRatesContinueIsDisabled(state);
  const {
    eligibilitySectionComplete = false,
    oeOptionsSectionComplete = false,
    checklistComplete = false,
  } = cobraPlan;
  const statuses = {
    eligibilitySectionComplete,
    oeOptionsSectionComplete,
    planRatesSectionComplete: !isPlanRatesInvalid,
    checklistComplete,
  };
  const steps = Object.values(statuses);

  return getPercentageFromBooleanArray(steps);
};

export const selectCobraOeHandler = (state: RootState): number | undefined => {
  return viewLens(state.reEnrollmentChecklist.plans, 'cobraOeHandler', 'cobra');
};

export const selectCobraOeTemplateCoverLetter = (state: RootState): boolean => {
  return viewLens(state.reEnrollmentChecklist.plans, 'cobraOeTemplateCoverLetter', 'cobra');
};

export const selectCobraOeElectionFormApproved = (state: RootState): boolean => {
  const index = state.reEnrollmentChecklist.plans.findIndex(p => p.type === 'cobra');
  const newLens = lensPath([ index, 'formReviewStatus', 'cobraElection' ]);

  return view(newLens, state.reEnrollmentChecklist.plans);
};

export const selectCobraOeCoverLetterApproved = (state: RootState): boolean => {
  const index = state.reEnrollmentChecklist.plans.findIndex(p => p.type === 'cobra');
  const newLens = lensPath([ index, 'formReviewStatus', 'coverLetter' ]);

  return view(newLens, state.reEnrollmentChecklist.plans);
};

export const selectCobraOePrintOptionBlackAndWhite = (state: RootState): boolean => {
  const index = state.reEnrollmentChecklist.plans.findIndex(p => p.type === 'cobra');
  const newLens = lensPath([ index, 'printOptions', 'blackAndWhite' ]);

  return view(newLens, state.reEnrollmentChecklist.plans);
};

export const selectCobraOePrintOptionTwoSided = (state: RootState): boolean => {
  const index = state.reEnrollmentChecklist.plans.findIndex(p => p.type === 'cobra');
  const newLens = lensPath([ index, 'printOptions', 'twoSided' ]);

  return view(newLens, state.reEnrollmentChecklist.plans);
};

export const oeFileNamePrefixes = {
  cobraOePacketFilePrefix: 'CobraOEPack-',
  cobraOePacketAdditionalFilePrefix: 'CobraOEPack-Add-',
  cobraRateFilePrefix: 'CobraRateSheet-',
  coverLetter: 'CoverLetter-',
  electionForm: 'ElectionForm-',
};

const coverLetterPrefix = `${oeFileNamePrefixes.cobraOePacketFilePrefix}${oeFileNamePrefixes.coverLetter}`;
const electionFormPrefix = `${oeFileNamePrefixes.cobraOePacketFilePrefix}${oeFileNamePrefixes.electionForm}`;

export const selectOePacketFiles = (state: RootState): FileListItem[] => {
  const allFiles = viewLens(state.reEnrollmentChecklist.plans, 'oePacketFiles', 'cobra') ?? [] as FileListItem[];

  return allFiles
    .filter((file: FileListItem) => (file.fullName.startsWith(oeFileNamePrefixes.cobraOePacketAdditionalFilePrefix)));
};

export const selectCobraOeCoverLetter = (state: RootState): FileListItem | null => {
  const allFiles = viewLens(state.reEnrollmentChecklist.plans, 'oePacketFiles', 'cobra') ?? [] as FileListItem[];

  return allFiles.find((file: FileListItem) => (file.fullName.startsWith(coverLetterPrefix)));
};

export const selectCobraOeElectionForm = (state: RootState): FileListItem | null => {
  const allFiles = viewLens(state.reEnrollmentChecklist.plans, 'oePacketFiles', 'cobra') ?? [] as FileListItem[];

  return allFiles.find((file: FileListItem) => (file.fullName.startsWith(electionFormPrefix)));
};

export const selectCobraOePlanYearStart = (state: RootState): number | undefined => {
  return viewLens(state.reEnrollmentChecklist.plans, 'planYearStart', 'cobra');
};

export const selectCobraOeValidations = (state: RootState): PlanValidation => {
  return state.reEnrollmentChecklist.validations.find(t => t.type === 'cobra') ?? [] as PlanValidation;
};

export const selectSaveCobraOePacketInfoStatus = (state: RootState): string => {
  return state.reEnrollmentChecklist.saveOePacketInfoStatus;
};

export const selectCobraOeSubmitDocStatus = (state: RootState): string => {
  return state.reEnrollmentChecklist.submitFileStatus;
};

export const selectCompleteChecklistRequestStatus = (state: RootState): string => {
  return state.reEnrollmentChecklist.completeChecklistRequestStatus;
};

export const selectCompanyId = (state: RootState): number => {
  const index = state.reEnrollmentChecklist.plans.findIndex(p => p.type === 'cobra');
  const newLens = lensPath([ index, 'companyId' ]);

  return view(newLens, state.reEnrollmentChecklist.plans);
};

export const selectPlanByPlanCode = (planCode: string) => (state: RootState): ReEnrollmentChecklistPlan => {
  const index = state.reEnrollmentChecklist.plans.findIndex(p => p.planCode === planCode);
  const newLens = lensPath([ index ]);

  return view(newLens, state.reEnrollmentChecklist.plans);
};

export const selectCancelModalStatus = (state: RootState): string => state.reEnrollmentChecklist.cancelModalStatus;

export const selectCobraPlanNewEligibleCount = (state: RootState): number => {
  return viewLens(state.reEnrollmentChecklist.plans, 'cobraOeNewEligibleCount', 'cobra');
};

export const selectPreviousYearCobraPlanNewEligibleCount = (state: RootState): number => {
  return viewLens(state.reEnrollmentChecklist.plans, 'previousYearCobraOeNewEligibleCount', 'cobra');
};

export const selectEligibilityCountDifference = (state: RootState): number => {
  const previous = selectPreviousYearCobraPlanNewEligibleCount(state);
  const current = selectCobraPlanNewEligibleCount(state);

  return Math.abs(((previous - current) / previous) * 100);
};

export const selectSaveCobraPlanStatus = (state: RootState): string => {
  return state.reEnrollmentChecklist.saveCobraPlanStatus;
};

export const selectFetchCobraChecklistPlansStatus = (state: RootState): string => {
  return state.reEnrollmentChecklist.fetchCobraChecklistPlansStatus;
};

export const selectUniqueCobraReEnrollmentChecklistPlans = (state: RootState) => {
  return state.reEnrollmentChecklist.cobraReEnrollmentChecklistPlans
    .filter(p => !p.terminating)
    .map(p => {
      const hasDocs = p.reenrollmentRatesDocs.length > 0;
      const hasRates = p.planLevels.length > 0 && p.planLevels.every(level => !!level.levelCost);
      const ratesStatus = ifElse(
        allPass([
          compose(equals(true), always(!!p.planInProgressId)),
          either(
            compose(equals(true), always(hasRates)),
            compose(equals(true), always(hasDocs)),
          ),
        ]),
        always('reviewed'),
        always('incomplete'),
      )(p);

      const planWithRatesStatus = Object.assign({}, p, { ratesStatus }) as CobraChecklistPlan;

      return planWithRatesStatus;
    });
};

export const selectDiscontinuedCobraChecklistPlans = (state: RootState) => {
  return state.reEnrollmentChecklist.cobraReEnrollmentChecklistPlans
    .filter(p => p.terminating)
    .map(p => {
      const planWithRatesStatus = Object.assign({}, p, { ratesStatus: 'discontinued' }) as CobraChecklistPlan;

      return planWithRatesStatus;
    });
};

export const selectCobraReEnrollmentChecklistPlansByType = (state: RootState) => {
  return groupBy((plan) => plan.planTypeName, state.reEnrollmentChecklist.cobraReEnrollmentChecklistPlans
    .filter(p => !p.terminating));
};

export const selectPlanRateWizardStepIndex = (state: RootState): number => {
  return state.reEnrollmentChecklist.planRateWizardStepIndex;
};

export const selectCobraPlanTypes = (state: RootState): CobraPlanType[] => {
  return state.reEnrollmentChecklist.cobraPlanTypes;
};

export const selectCobraPlanTypesStatus = (state: RootState): ApiStatus => {
  return state.reEnrollmentChecklist.cobraPlanTypesStatus;
};

export const selectCobraInsurers = (state: RootState): CobraInsurer[] => {
  return state.reEnrollmentChecklist.cobraInsurers;
};

export const selectCobraInsurersStatus = (state: RootState): ApiStatus => {
  return state.reEnrollmentChecklist.cobraInsurersStatus;
};

export const selectCobraPlanOnDeck = (state: RootState): OnDeckCobraPlan => {
  return state.reEnrollmentChecklist.cobraPlanOnDeck;
};

export const selectCobraPlanLevelsAllPositive = (state: RootState): boolean => {
  return state.reEnrollmentChecklist.cobraPlanOnDeck.planLevels?.every(plan => plan.levelCost >= 0) ?? false;
};

export const selectCobraPlanOnDeckCarrierId = (state: RootState): number | null | undefined => {
  return state.reEnrollmentChecklist.cobraPlanOnDeck.carrierId;
};

export const selectCobraPlanOnDeckCarrierNotes = (state: RootState): string | undefined => {
  return state.reEnrollmentChecklist.cobraPlanOnDeck.carrierNotes;
};

export const selectCobraPlanOnDeckValidations = (state: RootState): Record<string, string> => {
  return state.reEnrollmentChecklist.cobraPlanOnDeckValidations;
};

export const selectCobraPlanOnDeckConfirmRates = (state: RootState): boolean | undefined => {
  return state.reEnrollmentChecklist.cobraPlanOnDeck.confirmRates;
};

export const selectCobraPlanOnDeckRatesEffectiveTo = (state: RootState) => {
  // they will all be the same, so just grab the first one
  const newLens = lensPath([ 0, 'levelCostEndDate' ]);

  return view(newLens, state.reEnrollmentChecklist.cobraPlanOnDeck.planLevels);
};

export const selectCobraPlanOnDeckRatesEffectiveFrom = (state: RootState) => {
  // they will all be the same, so just grab the first one
  const newLens = lensPath([ 0, 'levelCostBeginDate' ]);

  return view(newLens, state.reEnrollmentChecklist.cobraPlanOnDeck.planLevels);
};

export const selectLevelCoverageTiersOnDeck = (state: RootState) => {
  let plan = selectCobraPlanOnDeck(state);

  return plan.planLevels ?? [];
};

export const selectCustomPlanNames = (state: RootState) => {
  return state.reEnrollmentChecklist.customPlans.map(p => p.planName);
};

export const selectCustomPlanNamesFetchStatus = (state: RootState) => {
  return state.reEnrollmentChecklist.fetchCustomPlanStatus;
};

export const selectOnDeckPlanHasLevels = (state: RootState) => {
  return state.reEnrollmentChecklist.cobraPlanOnDeck.hasLevels;
};

export const selectIsOnDeckPlanNew = (state: RootState) => {
  return !state.reEnrollmentChecklist.cobraPlanOnDeck.planId;
};

export const selectOnDeckPlanRatesDocuments = (state: RootState) => {
  return selectCobraPlanOnDeck(state).reenrollmentRatesDocs ?? [];
};

export const selectGenerateOeDocumentsStatus = (state: RootState) => {
  return state.reEnrollmentChecklist.generateOeDocumentsStatus;
};

export const selectDoesRedirectToOePackets = (state: RootState) => {
  const cobraPlan = selectCobraPlan(state);

  return cobraPlan.cobraOeHandler === 0 && !cobraPlan.cobraOeTemplateCoverLetter;
};

export const selectCobraPlanIds = (state: RootState): number[] => {
  return state
    .reEnrollmentChecklist
    .cobraReEnrollmentChecklistPlans
    .filter(p => !!p.planInProgressId)
    .map(p => p.planInProgressId) as number[];
};

export const selectRedirectModalStatus = (state: RootState) => {
  return state.reEnrollmentChecklist.redirectModalStatus;
};

export const selectCanShowOePackets = (state: RootState) => {
  const {
    cobraOeHandler = 0,
    cobraOeTemplateCoverLetter = false,
    checklistComplete = false,
  } = selectCobraPlan(state);

  const hasOptedToCreatePackets = cobraOeHandler === 1 || cobraOeTemplateCoverLetter;

  return checklistComplete && hasOptedToCreatePackets;
};

export const selectCoverageTypesRequestStatus = (state: RootState) => {
  return state.reEnrollmentChecklist.fetchCoverageTypesRequestStatus;
};

export const selectCoverageTypes = (state: RootState) => {
  return state.reEnrollmentChecklist.coverageTypes;
};

export const selectPlanLevelDescriptionsStatus = (state: RootState) => {
  return state.reEnrollmentChecklist.fetchPlanLevelDescriptionsStatus;
};

export const selectPlanLevelDescriptions = (state: RootState) => {
  return state.reEnrollmentChecklist.planLevelDescriptions;
};

export const selectDeleteCobraInProgressPlanStatus = (state: RootState) => {
  return state.reEnrollmentChecklist.deleteCobraInProgressPlanStatus;
};

export const selectSaveDirectBillChecklistStatus = (state: RootState) => {
  return state.reEnrollmentChecklist.saveDirectBillChecklistStatus;
};

export const selectPlanRatesContinueIsDisabled = (state: RootState) => {
  const plans = selectUniqueCobraReEnrollmentChecklistPlans(state);

  return plans.length ? plans.some(p => p.ratesStatus === 'incomplete') : true;
};

export const selectIsFinalizeCobraChecklistDisabled = (state: RootState) => {
  const planRatesContinueIsDisabled = selectPlanRatesContinueIsDisabled(state);
  const { eligibilitySectionComplete, oeOptionsSectionComplete } = selectCobraPlan(state);

  return anyPass<number>([
    always(!eligibilitySectionComplete),
    always(!oeOptionsSectionComplete),
    always(planRatesContinueIsDisabled),
  ])();
};

export const selectDirectBillPlan = (state: RootState): DirectBillReEnrollmentChecklistPlan => {
  return (state.reEnrollmentChecklist.plans
    .find(plan => plan.type === 'directBill') ?? {}) as DirectBillReEnrollmentChecklistPlan;
};

export const selectDirectBillOePlanYearStart = (state: RootState): number | undefined => {
  return viewLens(state.reEnrollmentChecklist.plans, 'planYearStart', 'directBill');
};

export const selectDirectBillOeValidations = (state: RootState): PlanValidation => {
  return state.reEnrollmentChecklist.validations.find(t => t.type === 'directBill') ?? [] as PlanValidation;
};
