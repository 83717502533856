import { MouseEventHandler } from 'react';
import Grid, { GridSize } from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

/**
 * Description:
 * Material Card for display in a Grid layout.
 *
 * Example:
 * ```
 * <Grid container spacing={3}>
 *   {
 *     items.map(item => (
 *       <GridCard
 *        src="embedUrl"
 *        name="Card Title"
 *        href="linkUrl"
 *      />
 *     )
 *   }
 * </Grid>
 * ```
 */

interface Props {
  name: string;
  src?: string;
  type?: string;
  component?: 'video' | 'img' | 'iframe';
  href?: string;
  col?: GridSize;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

function GridCard({ src, name, href, type = 'PDF', component = 'img', col = 3, onClick }: Props) {
  function makeCard() {
    return (
      <Card>
        <CardActionArea onClick={onClick}>
          {
            src
              ? (
                <CardMedia
                  component={component}
                  src={src}
                  title={name}
                />
              )
              : <></>
          }
          <CardContent>
            <p className="pb-2">{type}</p>
            <p className="pb-5">{name}</p>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Grid item xs={col}>
      {
        href
          ? (
            <a target="_blank" rel="noreferrer" href={href}>
              {makeCard()}
            </a>
          )
          : makeCard()
      }
    </Grid>
  );
}

export default GridCard;
