import Avatar from '@mui/material/Avatar';
import { colorFromLetters } from 'components/shared/avatar-color-palette';
import { EmployeeSearchResults } from 'leave-records/leaveRecord.types';

interface Props {
  employeeSummary: EmployeeSearchResults;
}

export function LeaveRecordsEmployeeHeader({ employeeSummary }: Readonly<Props>) {
  return (
    <div className="flex flex-nowrap w-full space-x-4 mb-4 items-center">
      <Avatar className={
        `p-2 text-sm ${colorFromLetters(employeeSummary.firstName + employeeSummary.lastName)}`
      }
      >{employeeSummary.firstName.charAt(0).toUpperCase()}{employeeSummary.lastName.charAt(0).toUpperCase()}
      </Avatar>
      <p className="text-neutral-500">
        {`${employeeSummary.firstName} ${employeeSummary.lastName}`}
      </p>
      <p className="text-sm text-neutral-500 pl-4">PID# {employeeSummary.eeId}</p>
      {
        employeeSummary.taxIdLast4 && (
          <p className="text-sm text-neutral-500 pl-4" data-testid="employee-ssn">SSN {employeeSummary.taxIdLast4}</p>
        )
      }
    </div>
  );
}
