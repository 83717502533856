import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import LoopIcon from '@mui/icons-material/Loop';

import { SelectOption, SelectChangeEvent } from 'shared/types/form.types';

interface PayDateSelectProps {
  payDates: SelectOption[];
  loading: boolean;
  selected: string;
  error?: boolean;
  onPayDateSelected: (payDate: string) => void;
  name?: string;
}

function PayDateSelect({
  payDates,
  loading,
  selected,
  error,
  onPayDateSelected,
}: PayDateSelectProps): React.ReactElement {
  function handleChange({ target: { value: val } }: SelectChangeEvent) {
    onPayDateSelected(val as string);
  }

  function loadingIcon() {
    return (
      <div className="w-full plan-enrollment-pay-dates-loading">
        <LoopIcon className="animate-spin" /> Loading pay dates...
      </div>
    );
  }

  function selectField() {
    return (
      <FormControl variant="outlined" className="w-full plan-enrollment-pay-date-select">
        <InputLabel>First Pay Date Of Change</InputLabel>
        <Select
          label="First Pay Date of Change"
          onChange={handleChange as any}
          value={selected}
          native
          fullWidth
          error={error}
        >
          <option value="" />
          {payDates.map((pd) => <option value={pd.value} key={pd.value}>{pd.label}</option>)}
        </Select>
        {
          error
            ? <FormHelperText disabled error>Please select a pay date.</FormHelperText>
            : (
              <FormHelperText>
                Pay date in which the employee will have the amount entered in the "Pay Period Deduction" field first
                deducted from their paycheck. If this date is not listed, please contact your Account Manager.
              </FormHelperText>
            )
        }
      </FormControl>
    );
  }

  return loading ? loadingIcon() : selectField();
}

export default PayDateSelect;
