import React, { ReactElement, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { compose, always, cond, equals, T, all, any } from 'ramda';
import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import {
  ApiStatus,
  cancelModalClosed,
  selectCancelModalStatus,
  selectCoverageTypesRequestStatus,
  selectFetchPlansStatus,
  selectReEnrollmentPlansByPlanYearId,
  selectSaveCobraOePacketInfoStatus,
  selectSaveCobraPlanStatus,
  selectDeleteCobraInProgressPlanStatus,
  selectIsFinalizeCobraChecklistDisabled,
  selectPlanLevelDescriptionsStatus,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import {
  fetchCoverageTypes,
  fetchPlanLevelDescriptions,
  fetchReEnrollmentChecklistPlan,
} from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import { selectSelectedCompany } from 'companies/companies.slice';
import PageContentPaper from 'components/layout/PageContentPaper';
import TabPanel from 'components/shared/TabPanel';
import Loading from 'components/shared/Loading';
import CancelModal from 'components/shared/CancelModal';
import OePacketsOptions from 're-enrollment-checklist/cobra-reenrollment-checklist/oe-packets-options/OePacketsOptions';
import CobraEligibilityCount
  from 're-enrollment-checklist/cobra-reenrollment-checklist/cobra-eligibility-count/CobraEligibilityCount';
import store from 'store';
import PlanRates from './plan-rates/PlanRates';
import SummaryTab from 're-enrollment-checklist/cobra-reenrollment-checklist/summary/Summary';
import { toNumber } from 'shared/utils';

type CobraTab = 'eligible-count' | 'oe-packets-options' | 'plan-rates' | 'summary';

function CobraPlanReEnrollmentChecklist(): ReactElement {
  usePageTitle('COBRA Enrollment');

  const history = useHistory();
  const { compid: companyId } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const { planYearId = '0', tab = 'eligible-count' } = useParams<{planYearId: string; tab?: CobraTab;}>();
  const plan = useSelector(selectReEnrollmentPlansByPlanYearId(+planYearId));
  const dispatch = useDispatch<typeof store.dispatch>();
  const plansRequestStatus = useSelector(selectFetchPlansStatus);
  const coverageTypeRequestStatus = useSelector(selectCoverageTypesRequestStatus);
  const planLevelDescriptionsStatus = useSelector(selectPlanLevelDescriptionsStatus);
  const updateCobraPlanStatus = useSelector(selectSaveCobraPlanStatus);
  const updatePlanYearStatus = useSelector(selectSaveCobraOePacketInfoStatus);
  const cancelModalStatus = useSelector(selectCancelModalStatus);
  const deleteCobraInProgressPlanStatus = useSelector(selectDeleteCobraInProgressPlanStatus);
  const summaryDisabled = useSelector(selectIsFinalizeCobraChecklistDisabled);

  const fetchStatus = cond<string[], ApiStatus>([
    [ any(equals('pending')), always('pending') ],
    [ any(equals('rejected')), always('rejected') ],
    [ all(equals('fulfilled')), always('fulfilled') ],
  ])([ plansRequestStatus, coverageTypeRequestStatus, planLevelDescriptionsStatus ]);

  useEffect(() => {
    if (!plan) {
      dispatch(fetchReEnrollmentChecklistPlan(companyId));
    }

    if (coverageTypeRequestStatus === 'uninitialized') {
      dispatch(fetchCoverageTypes({ companyId, planYearId: toNumber(planYearId) }));
    }

    if (planLevelDescriptionsStatus === 'uninitialized') {
      dispatch(fetchPlanLevelDescriptions({ companyId, planYearId: toNumber(planYearId) }));
    }
  }, [
    planYearId,
    dispatch,
    plan,
    companyId,
    coverageTypeRequestStatus,
    planLevelDescriptionsStatus,
  ]);

  function handleTabChange (_: React.ChangeEvent<{}>, newTab: string) {
    history.push(`/reenrollment-checklist/cobra-plan/${planYearId}/${newTab}`);
  }

  return (
    <>
      <PageContentPaper className="mt-4 mb-8" data-testid="cobra-plan-tabbed-form">
        <div className="flex flex-col justify-around">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            className="-ml-4 -mt-4 flex justify-around"
            value={tab}
            centered
            onChange={handleTabChange}
          >
            <Tab
              data-testid="eligible-count-tab"
              value="eligible-count"
              label="Cobra Eligible Count"
            />
            <Tab
              data-testid="oe-packets-options-tab"
              value="oe-packets-options"
              label="OE Packets"
            />
            <Tab
              data-testid="plan-rates-tab"
              value="plan-rates"
              label="Plan Rates"
            />
            <Tab
              data-testid="summary-tab"
              value="summary"
              label="Summary"
              disabled={summaryDisabled}
            />
          </Tabs>
          <hr />
          <TabPanel value={tab} index="eligible-count">
            {
              cond<string, ReactElement>([
                [ equals('pending'), always(
                  <>
                    <Skeleton variant="rectangular" height={100} className="w-full mt-4" />
                    <Skeleton variant="rectangular" height={25} className="w-4/5 my-2" />
                    <Skeleton variant="rectangular" height={50} className="w-3/5 my-2" />
                  </>,
                ) ],
                [ T, always(<CobraEligibilityCount />) ]],
              )(fetchStatus)
            }
          </TabPanel>
          <TabPanel value={tab} index="oe-packets-options">
            {
              cond<string, ReactElement>([
                [ equals('pending'), always(
                  <>
                    <Skeleton variant="rectangular" height={22} className="w-1/2 mt-4" />
                    <Skeleton variant="rectangular" height={91} className="w-1/3 my-2" />
                  </>,
                ) ],
                [ T, always(<OePacketsOptions />) ]],
              )(fetchStatus)
            }
          </TabPanel>
          <TabPanel value={tab} index="plan-rates">
            {
              cond<string, ReactElement>([
                [ equals('pending'), always(
                  <>
                    <Skeleton variant="rectangular" height={100} className="w-full mt-8" />
                    <Skeleton variant="rectangular" height={50} className="w-1/5 my-2" />
                    <Skeleton variant="rectangular" height={250} className="w-full mt-4" />
                    <Skeleton variant="rectangular" height={250} className="w-full mt-4" />
                  </>,
                ) ],
                [ T, always(<PlanRates />) ]],
              )(fetchStatus)
            }
          </TabPanel>
          <TabPanel value={tab} index="summary">
            {
              cond<string, ReactElement>([
                [ equals('pending'), always(
                  <>
                    <Skeleton variant="rectangular" height={100} className="w-full mt-8" />
                  </>,
                ) ],
                [ T, always(<SummaryTab />) ]],
              )(fetchStatus)
            }
          </TabPanel>
        </div>
      </PageContentPaper>
      {

        updateCobraPlanStatus === 'pending'
        || updatePlanYearStatus === 'pending'
        || deleteCobraInProgressPlanStatus === 'pending'
          ? <Loading />
          : <></>
      }
      <CancelModal
        isOpen={cancelModalStatus === 'open'}
        onCancel={compose(history.push, always('/reenrollment-checklist'))}
        onClose={compose(dispatch, cancelModalClosed)}
      />
    </>
  );
}

export default CobraPlanReEnrollmentChecklist;
