import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { leafPath } from 'shared/utils';

const FormikErrorFocus = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let name = leafPath(errors);

      if (name) {
        const selector = `[name="${name}"]`;
        const errorElement = document.querySelector<HTMLElement>(selector);

        errorElement?.focus();
      }
    }
  }, [ errors, isSubmitting, isValidating ]);

  return null;
};

export default FormikErrorFocus;
