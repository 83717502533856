import React from 'react';

import CheckTable from './CheckTable';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { useChecks } from './useChecks';
import withModal from 'components/shared/HOC/WithModal';

interface ViewChecksProps {
  isCobra: boolean;
}

function ViewChecks({ isCobra }: ViewChecksProps): React.ReactElement {
  const checks = useChecks(isCobra);

  const Checks = withLoopIcon(
    CheckTable,
    'Loading checks...',
    checks === null,
  );

  return <Checks checks={checks} />;
}

export const ChecksModal = withModal(ViewChecks);

export default ViewChecks;
