import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useFormikContext } from 'formik';

import { Dependent } from 'shared/types/non-plan-year.types';
import { DBBenefit } from 'direct-billing/direct-billing.types';

interface BenefitDependentsProps {
  dependents: Dependent[];
  index: number;
  disabled?: boolean;
}

function DBBenefitDependents({ dependents, index, disabled = false }: BenefitDependentsProps) {
  const { values, handleChange } = useFormikContext<{benefits: DBBenefit[];}>();

  return (
    <>
      <h6>Covered Dependents</h6>
      <div className="grid grid-cols-2 grid-flow-row">
        {dependents.map((d, ii) => (
          <FormControlLabel
            control={
              <Checkbox
                value={ii}
                checked={!!values.benefits[index]?.coveredDependents?.includes(`${ii}` as any)}
                name={`benefits.${index}.coveredDependents`}
                color="primary"
                data-testid={`checkbox-${d.name.toLocaleLowerCase().replace(/\W+/g, '-')}`}
                onChange={!disabled ? handleChange : undefined}
                inputProps={ { disabled } }
              />
            }
            disabled={disabled}
            label={d.name}
            labelPlacement="end"
            key={ii}
          />
        ))}
      </div>
    </>
  );
}

export default DBBenefitDependents;
