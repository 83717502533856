import request from '../api-request';
import { HistoricalPlanWithRatesResponse } from 'rates-history/rates-history.types';

export function getHistoricalCobraPlans(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get historical cobra plans for invalid company'));
  }
  const uri = `/api/companies/${companyId}/cobra-plan-rates`;

  return request<HistoricalPlanWithRatesResponse>(uri)
    .then(plans => plans ?? { plans: [], planYears: [] });
}

export function getHistoricalDirectBillPlans(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get historical direct bill plans for invalid company'));
  }
  const uri = `/api/companies/${companyId}/retiree-plan-rates`;

  return request<HistoricalPlanWithRatesResponse>(uri)
    .then(plans => plans ?? { plans: [], planYears: [] });
}
