import React, { useState } from 'react';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { handleDateChange } from 'shared/form-helpers';
import { validationSchema } from './plan-enrollment-hsa.validators';
import { PlanEnrollmentFormProps } from '../plan-enrollment.types';
import FormHelperText from '@mui/material/FormHelperText';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

interface Props extends PlanEnrollmentFormProps {
  partStart?: string;
  partEnd?: string;
  yearBeg?: string;
  yearEnd?: string;
  planName?: string;
}

function PlanEnrollmentHsa({
  partStart = '',
  partEnd,
  yearBeg,
  yearEnd,
  planName,
  isEdit = false,
  onNextStep,
  onCancel,
}: Props) {
  const form = useFormik({
    validationSchema: validationSchema(isEdit),
    initialValues: {
      partStart,
      partEnd,
      yearBeg,
      yearEnd,
    },
    onSubmit: (values) => {
      try {
        validationSchema(isEdit).validateSync(
          values,
          {
            abortEarly: false,
            stripUnknown: false,
          },
        );

        onNextStep({
          partStart: values.partStart,
          partEnd: values.partEnd,
          type: 'HSA',
        });
      } catch (err) {
        setWholeFormErrors(err.errors);
      }
    },
  });
  const [ wholeFormErrors, setWholeFormErrors ] = useState<string[]>([]);

  return (
    <>
      <h6>{isEdit && 'Update '}Participant Enrollment</h6>
      <p className="my-8">Enter the employee's HSA effective date.</p>
      <p className="my-8">{planName}</p>
      <form className="plan-enrollment-hsa-form space-y-8">
        {
          wholeFormErrors.length
            ? wholeFormErrors.map((m, i) => (
              <FormHelperText
                className="text-base pb-5"
                key={i}
                disabled
                error
              >
                {m}
              </FormHelperText>
            ))
            : <></>
        }
        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Begin Date"
            inputFormat="MM/DD/YYYY"
            value={form.values.partStart ? dayjs(form.values.partStart, 'YYYY-MM-DD')
              .format('MM/DD/YYYY') : null}
            onChange={handleDateChange('partStart', form)}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <TextField
                variant="outlined"
                name="partStart"
                className="w-full"
                {...params}
                error={form.touched.partStart && !!form.errors.partStart}
                helperText={form.touched.partStart && form.errors.partStart}
              />
            )}
          />
        </LocalizationProvider>

        {
          isEdit
            ? (
              <LocalizationProvider dateAdapter={AdapterDayJS}>
                <DatePicker
                  label="End Date"
                  inputFormat="MM/DD/YYYY"
                  value={form.values.partEnd ? dayjs(form.values.partEnd, 'YYYY-MM-DD')
                    .format('MM/DD/YYYY') : null}
                  onChange={handleDateChange('partEnd', form)}
                  // eslint-disable-next-line react/jsx-no-bind
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      name="partEnd"
                      className="w-full"
                      {...params}
                      error={form.touched.partEnd && !!form.errors.partEnd}
                      helperText={form.touched.partEnd && form.errors.partEnd}
                    />
                  )}
                />
              </LocalizationProvider>
            )
            : <></>
        }

        <div className="wizard-buttons">
          <Button
            color="primary"
            onClick={onCancel}
            className="plan-enrollment-cancel"
            tabIndex={-1}
          >
              Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="ml-8 plan-enrollment-next"
            onClick={form.submitForm}
            tabIndex={-1}
          >
              Next Step
          </Button>
        </div>
      </form>
    </>
  );
}

export default PlanEnrollmentHsa;
