import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReportsTile from 'reports/shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import {
  downloadAnnualAllocationAudit,
} from 'shared/api/annualAllocationAuditApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import {
  loadAnnualAllocationAuditCount,
  selectAnnualAllocationAuditCount,
} from 'reports/reports.slice';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import store from 'store';

interface AnnualAllocationAuditProps {
  selectedPlan?: OpenPlan;
}

function AnnualAllocationAudit({ selectedPlan }: AnnualAllocationAuditProps) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const count = useSelector(selectAnnualAllocationAuditCount);
  const [ downloading, setDownloading ] = useState(false);

  useEffect(() => {
    if (selectedPlan) {
      dispatch(loadAnnualAllocationAuditCount(compid, selectedPlan.pyrid));
    }
  }, [ dispatch, compid, selectedPlan ]);

  const errorNotifier = useErrorNotifier();

  function handleExport() {
    if (selectedPlan) {
      setDownloading(true);
      downloadAnnualAllocationAudit(compid, selectedPlan.pyrid)
        .catch(errorNotifier)
        .finally(() => setDownloading(false));
    }
  }

  return (
    <ReportsTile
      count={count}
      title="Annual Allocation Audit"
      description="This report contains the annual allocation audit for the given plan year."
      variant="primary-dark"
      exportDisabled={downloading}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default AnnualAllocationAudit;
