import React, { useState, useMemo } from 'react';
import Chip from '@mui/material/Chip';
import LoopIcon from '@mui/icons-material/Loop';
import { always, compose, isNil } from 'ramda';

import PageContentPaper from 'components/layout/PageContentPaper';
import { useDocumentLibraryForms } from 'document-library/hooks/useDocumentLibraryForms';
import Grid from '@mui/material/Grid';
import GridCard from 'components/shared/GridCard';
import { getDocImage } from '../imageMap';

function FormsDocumentLibrary() {
  const [ active, setActive ] = useState<number | null>(null);
  const forms = useDocumentLibraryForms();

  const titles = useMemo(() => forms?.reduce(
    (acc: string[], { planYear: { planYearName } }) => acc.includes(
      planYearName) ? acc : [ ...acc, planYearName ], [],
  ), [ forms ]);

  return (
    <PageContentPaper>
      <div className="flex flex-col">
        <div className="w-full flex pb-4">
          <Chip
            label="All"
            variant={isNil(active) ? undefined : 'outlined'}
            className="cursor-pointer"
            onClick={compose(setActive, always(null))}
          />
          {
            titles?.map((title, i) => (
              <Chip
                label={title}
                variant={i === active ? undefined : 'outlined'}
                key={title}
                className="ml-2 cursor-pointer"
                onClick={compose(setActive, always(i))}
              />
            ))
          }
        </div>
        {
          titles?.map((title, i) => (
            (isNil(active) || active === i) && (
              <div>
                <div className="mt-10 mb-10">
                  {title}
                </div>
                <Grid className="grid-section" container spacing={3}>
                  {
                    forms?.filter(f => f.planYear.planYearName === title)
                      .map((form) => (
                        <GridCard
                          src={getDocImage(form.name)}
                          name={form.name}
                          type="PDF"
                          href={form.url}
                        />
                      ))
                  }
                </Grid>
              </div>
            )
          )) || <><LoopIcon className="animate-spin" /> Loading...</>
        }
      </div>
    </PageContentPaper>
  );
}

export default FormsDocumentLibrary;
