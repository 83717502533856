import React, { ReactElement, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { addIndex, always, compose, equals, map as nonIndexedMap, nthArg, pipe, slice, when } from 'ramda';

interface DataTableProps<T> {
  headers: string[];
  cells: T[];
  isPaginated?: boolean;
  pageSize?: number;
  initialPage?: number;
}

export function DataTable<T extends {}>(
  { headers, cells, pageSize = 10, initialPage = 0, isPaginated = false }: DataTableProps<T>,
): ReactElement {
  const [ page, setPage ] = useState(initialPage);
  const [ size ] = useState(pageSize);

  const map = addIndex(nonIndexedMap);
  const sliceWhenPaginated = when(
    compose(equals(true), always(isPaginated)),
    slice(page * size, page * size + size),
  );

  function makeTableCell(t: unknown, i: number) {
    return (
      <TableRow key={`row${i}`}>
        {
          Object.values(t as T).map((v, ii) => (
            <TableCell className="data-table-cell" key={`row${i}cell${ii}`}>
              <p className="text-black text-sm">
                {v as any}
              </p>
            </TableCell>
          ))
        }
      </TableRow>
    );
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((th, i) => <TableCell key={`${th}${i}`}>{th}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            cells.length > 0
              ? pipe(
                sliceWhenPaginated,
                map(makeTableCell),
              )(cells) as any
              : <TableRow><TableCell colSpan={headers.length}><p>No transactions to display</p></TableCell></TableRow>
          }
        </TableBody>
        {
          isPaginated && cells.length > 0
            ? (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPage={size}
                    rowsPerPageOptions={[ size ]}
                    colSpan={headers.length}
                    count={cells.length}
                    page={page}
                    onPageChange={compose(setPage, nthArg(1))}
                    SelectProps={ { native: true } }
                  />
                </TableRow>
              </TableFooter>
            )
            : <></>
        }
      </Table>
    </>
  );
}

export default DataTable;
