import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';

import CommonSelect from 'components/shared/form/CommonSelect';
import {
  handleCustomFieldChange,
  formikFieldErrorText,
  formikFieldHasError,
} from 'shared/form-helpers';
import { FormikMuiDatePicker } from 'components/shared/form/formik/FormikMuiDatePicker';
import { LeaveRequestFormFields } from 'enter-leave-request/enter-leave-request.types';
import { employmentTypeOptions } from 'enter-leave-request/enter-leave-request-fields';

export function EnterLeaveRequestEmploymentDetails(): React.ReactElement {
  const form = useFormikContext<LeaveRequestFormFields>();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form.validateForm();
  }, []);

  return (
    <>
      <div className="flex space-x-4 mb-4" data-testid="enter-leave-request-employment-details">
        <CommonSelect
          classes={ { control: 'basis-1/2 common-select' } }
          options={employmentTypeOptions}
          value={form.values.employment.type}
          name="employment.type"
          label="Employment Type"
          onSelectChange={handleCustomFieldChange('employment.type', form)}
          onSelectBlur={form.handleBlur}
          error={formikFieldHasError('employment.type', form)}
        />
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Location or division (Optional)"
          name="employment.location"
          value={form.values.employment.location}
          error={formikFieldHasError('employment.location', form)}
          helperText={formikFieldErrorText('employment.location', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="location"
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Supervisor Name (Optional)"
          name="employment.supervisorName"
          value={form.values.employment.supervisorName}
          error={formikFieldHasError('employment.supervisorName', form)}
          helperText={formikFieldErrorText('employment.supervisorName', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="supervisorName"
        />
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Supervisor Email"
          name="employment.supervisorEmail"
          value={form.values.employment.supervisorEmail}
          error={formikFieldHasError('employment.supervisorEmail', form)}
          helperText={formikFieldErrorText('employment.supervisorEmail', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="supervisorEmail"
        />
      </div>

      <div className="flex space-x-4 mb-4">
        <FormikMuiDatePicker
          className="basis-1/2"
          label="Hire Date"
          path="employment.hireDate"
          value={form.values.employment.hireDate}
          maxDate={dayjs()}
          hasError={formikFieldHasError('employment.hireDate', form)}
          form={form}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
        />
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Total hours worked in the past 12 months"
          name="employment.totalHoursWorked"
          value={form.values.employment.totalHoursWorked}
          error={formikFieldHasError('employment.totalHoursWorked', form)}
          helperText={formikFieldErrorText('employment.totalHoursWorked', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="totalHoursWorked"
        />
      </div>
    </>
  );
}
