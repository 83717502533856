import dayjs from 'dayjs';

import request, { ContentType } from '../api-request';
import { validateDate } from './validateDate';
import queryStringTag from './reportQueryStringTag';

export function downloadAcceptanceReceivedReport(
  companyId: number,
  type: string,
  startDate?: string,
  endDate?: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download acceptance received report for invalid company.'));
  }

  try {
    const start = validateDate(
      'Cannot download acceptance received report for invalid start date.',
      startDate ?? dayjs().format(),
    );
    const end = validateDate(
      'Cannot download acceptance received report for invalid end date.',
      endDate ?? dayjs().format(),
    );

    const uri =
      queryStringTag`/api/companies/${companyId}/reports/acceptance-received/${type}/export${start}${end}`;

    return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (err) {
    return Promise.reject(err);
  }
}
