import React from 'react';
import { Link } from 'react-router-dom';

import dashLogo from 'images/dash-logo.svg';
import styles from './FloresHeaderLogo.module.css';

function FloresHeaderLogo(): React.ReactElement {
  return (
    <div className={`${styles.logoContainer} p-4`}>
      <Link to="/dashboard"><img src={dashLogo} className={`${styles.logo}`} alt="Flores" /></Link>
    </div>
  );
}

export default FloresHeaderLogo;
