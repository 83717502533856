import React, { ReactElement, ComponentType } from 'react';
import LoopIcon from '@mui/icons-material/Loop';

function withLoopIcon<P>(
  WrappedComponent: ComponentType<React.PropsWithChildren<P>>,
  loadingMessage: string,
  isLoading: boolean,
  className = '',
) {
  function WithLoopIcon(props: P): ReactElement {
    return (
      isLoading
        ? (
          <div className={className}>
            <LoopIcon
              className="animate-spin"
              style={ { animationDirection: 'reverse' } }
            />  <span>{loadingMessage}</span>
          </div>
        )
        : <WrappedComponent {...props} />
    );
  }

  const wrappedComponentName = WrappedComponent.displayName ?? WrappedComponent.name;
  WithLoopIcon.displayName = `withLoopIcon(${wrappedComponentName})`;

  return WithLoopIcon;
}

export default withLoopIcon;
