import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FmlaReportCounts } from 'reports/fmla/fmla-report-types';
import { ApiStatus } from 'shared/types/api-status.types';
import {
  fetchDateFilterableFmlaReports,
  fetchFMLAReportCountsForDashboard,
} from 'reports/fmla/fmla-reports.thunks';
import { RootState } from 'store/rootReducer';

export interface FMLAReportsState {
    dateRangeStart: string;
    dateRangeEnd: string;
    fmlaReportCounts: FmlaReportCounts;
    nonFilterableFmlaReportCountsStatus: ApiStatus;
    filterableFmlaReportCountsStatus: ApiStatus;
}

export const initialState: FMLAReportsState = {
  dateRangeStart: '',
  dateRangeEnd: '',
  fmlaReportCounts: {
    allOpenLeaveCaseListCount: null,
    intermittentLeaveCaseListCount: null,
    pendingInfoCount: null,
    returnToWorkConfirmationCount: null,
    allAbsencesListCount: null,
    usageTotalsCount: null,
    allLeaveCases: null,
  },
  nonFilterableFmlaReportCountsStatus: 'uninitialized',
  filterableFmlaReportCountsStatus: 'uninitialized',
};

const FMLAReportsSlice = createSlice({
  name: 'fmlaReports',
  initialState,
  reducers: {
    setFMLADateRange(state, { payload }: PayloadAction<{start: string; end: string;}>) {
      state.dateRangeStart = payload.start;
      state.dateRangeEnd = payload.end;
      state.fmlaReportCounts = {
        ...state.fmlaReportCounts,
        allAbsencesListCount: null,
        usageTotalsCount: null,
        allLeaveCases: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFMLAReportCountsForDashboard.pending, (state) => {
      state.nonFilterableFmlaReportCountsStatus = 'pending';
      state.fmlaReportCounts = { ...state.fmlaReportCounts,
        allAbsencesListCount: null,
        usageTotalsCount: null,
        allLeaveCases: null,
      };
    });
    builder.addCase(fetchFMLAReportCountsForDashboard.rejected, (state) => {
      state.nonFilterableFmlaReportCountsStatus = 'rejected';
    });
    builder.addCase(fetchFMLAReportCountsForDashboard.fulfilled, (state, action) => {
      state.nonFilterableFmlaReportCountsStatus = 'fulfilled';
      state.fmlaReportCounts = { ...state.fmlaReportCounts, ...action.payload };
    });
    builder.addCase(fetchDateFilterableFmlaReports.pending, (state) => {
      state.filterableFmlaReportCountsStatus = 'pending';
      state.fmlaReportCounts = {
        ...state.fmlaReportCounts,
        allAbsencesListCount: null,
        usageTotalsCount: null,
        allLeaveCases: null,
      };
    });
    builder.addCase(fetchDateFilterableFmlaReports.rejected, (state) => {
      state.filterableFmlaReportCountsStatus = 'rejected';
    });
    builder.addCase(fetchDateFilterableFmlaReports.fulfilled, (state, action) => {
      state.filterableFmlaReportCountsStatus = 'fulfilled';
      state.fmlaReportCounts = { ...state.fmlaReportCounts, ...action.payload };
    });
  },
});

export const {
  setFMLADateRange,
} = FMLAReportsSlice.actions;

export default FMLAReportsSlice.reducer;

export const selectStartAndEndDateRange = (state: RootState): {startDate: string; endDate: string;} => {
  return { startDate: state.fmlaReports.dateRangeStart, endDate: state.fmlaReports.dateRangeEnd };
};

export const selectReportsCounts = (state: RootState): FmlaReportCounts => state
  .fmlaReports
  .fmlaReportCounts;

export const selectNonFilterableFetchFmlaReportCountsStatus = (state: RootState) => {
  return state.fmlaReports.nonFilterableFmlaReportCountsStatus;
};
