import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';

export type LeaveRecordsRoutes = 'leave-records' | 'all-cases' | 'case';

interface Props {
  currentRoute?: LeaveRecordsRoutes;
  eeId?: string;
}

export function LeaveRecordsPageHeader({ currentRoute = 'leave-records', eeId = '' }: Readonly<Props>) {
  const previousRouteMap = new Map<LeaveRecordsRoutes, string>([
    [ 'all-cases', '/leave-records' ],
    [ 'case', `/leave-records/all-cases/${eeId}` ],
  ]);
  const previousRoute = previousRouteMap.get(currentRoute) ?? '/leave-records';
  const history = useHistory();

  function goBack() {
    history.push(previousRoute);
  }

  return (
    <div className="flex items-center mt-4 mb-8">
      {
        currentRoute !== 'leave-records' && (
          <IconButton
            aria-label="Go Back"
            data-testid="go-back-button"
            onClick={goBack}
            size="large"
          >
            <ArrowBackIosIcon />
          </IconButton>
        )
      }
      <h2 className="page-title my-0">Participant Leave Records</h2>
    </div>
  );
}
