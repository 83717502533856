import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { Participant } from 'participant-search/participant-list.types';
import { getParticipant, searchForParticipant } from 'shared/api/participantApi';
import { ApiStatus } from 'shared/types/api-status.types';
import { fetchParticipant } from 'participant-search/participant.thunks';

export interface ParticipantState {
  selected: Participant | null;
  loading: boolean;
  recentSelections: Participant[];
  searchText: string;
  searchResults: Participant[] | null; // null indicates searching
  fetchParticipantStatus: ApiStatus;
}

export const initialState: ParticipantState = {
  selected: null,
  loading: false,
  recentSelections: [],
  searchText: '',
  searchResults: [],
  fetchParticipantStatus: 'uninitialized',
};

const participantSlice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    loadParticipantStart(state) {
      state.loading = true;
    },
    loadParticipantEnd(state) {
      state.loading = false;
    },
    setSelectedParticipant(state, { payload }: PayloadAction<Participant | null>) {
      state.selected = payload;

      if (payload !== null && !state.recentSelections.some((s) => s.empno === payload.empno)) {
        if (state.recentSelections.length === 3) {
          state.recentSelections = [ payload, ...state.recentSelections.slice(0, 2) ];
        } else {
          state.recentSelections.unshift(payload);
        }
      }
    },
    resetRecentSearches(state) {
      state.recentSelections = [];
    },
    setSearchResults(state, { payload }: PayloadAction<Participant[] | null>) {
      state.searchResults = payload;
    },
    setSearchText(state, { payload }: PayloadAction<string>) {
      state.searchText = payload;
    },
    resetSearchResults(state) {
      state.searchResults = [];
    },
    setFetchParticipantStatus(state, { payload }:PayloadAction<ApiStatus>) {
      state.fetchParticipantStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParticipant.pending, (state) => {
        state.fetchParticipantStatus = 'pending';
      })
      .addCase(fetchParticipant.rejected, (state) => {
        state.fetchParticipantStatus = 'rejected';
      })
      .addCase(fetchParticipant.fulfilled, (state, action) => {
        state.selected = action.payload;
        state.fetchParticipantStatus = 'fulfilled';
      });
  },
});

export const {
  loadParticipantStart,
  loadParticipantEnd,
  setSelectedParticipant,
  resetRecentSearches,
  setSearchResults,
  setSearchText,
  resetSearchResults,
  setFetchParticipantStatus,
} = participantSlice.actions;

export default participantSlice.reducer;

// thunks
export function loadParticipant(compid: number, payrollId: string): AppThunk {
  return async (dispatch) => {
    dispatch(loadParticipantStart());

    try {
      const participant = await getParticipant(compid, payrollId);
      dispatch(setSelectedParticipant(participant));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
    } finally {
      dispatch(loadParticipantEnd());
    }
  };
}

let abortController: AbortController | null = null;

export function searchParticipants(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setSearchResults(null));

    const {
      companies: { selectedCompany },
      participant: { searchText },
    } = getState();

    try {
      if (abortController !== null) {
        abortController.abort();
      }
      abortController = new AbortController();
      const participants = await searchForParticipant(selectedCompany?.compid ?? 0, searchText, abortController);

      dispatch(setSearchResults(participants ?? []));
    } catch (err) {
      if (err.name !== 'AbortError') {
        dispatch(addHttpErrorNotification(err));
        dispatch(setSearchResults([]));
      }
    } finally {
      abortController = null;
    }
  };
}

// selectors
export const selectParticipant = (state: RootState) => state.participant.selected;

export const selectParticipantLoading = (state: RootState) => state.participant.loading;

/** @deprecated prefer selectRecentSelections */
export const selectRecentSearches = (state: RootState) => state.participant.recentSelections;

export const selectRecentSelections = (state: RootState) => state.participant.recentSelections;

export const selectSearchResults = ({ participant }: RootState) => {
  return participant.searchText.length ? participant.searchResults : participant.recentSelections;
};

export const selectSearchText = (state: RootState) => state.participant.searchText;

export const selectFetchParticipantStatus = (state: RootState) => state.participant.fetchParticipantStatus;
