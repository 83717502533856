import { ReactElement } from 'react';
import ReportsTile from 'reports/shared/ReportsTile';
import { downloadFmlaExcel } from 'shared/api/fmlaReportsApi';
import { useDownloading } from 'shared/custom-hooks/useDownloading';
import { FMLAGenericTileProps } from 'reports/fmla/FMLAReports';

export function GenericFMLAReportTile({
  count,
  reportData,
  title,
  description,
  variant,
}: Readonly<FMLAGenericTileProps>): ReactElement {
  const {
    downloading,
    handleExport,
  } = useDownloading(downloadFmlaExcel, { ...reportData });

  return (
    <ReportsTile
      count={count}
      title={title}
      description={description}
      variant={variant}
      exportDisabled={count === null}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}
