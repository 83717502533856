import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';
import { compose, F, T, isNil } from 'ramda';
import { useHistory } from 'react-router-dom';

import { CobraEnrollment } from './enrollment.types';
import { CancelDirectBillingEventModal } from 'participant-overview/enrollment/CancelBillingEvent';

interface DisplayCobRetLoaEnrollmentRowProps {
  enrollment: CobraEnrollment;
  onPrintNotice: (e: CobraEnrollment) => Promise<any>;
}

function DisplayCobRetLoaEnrollmentRow({
  enrollment,
  onPrintNotice,
}: DisplayCobRetLoaEnrollmentRowProps): React.ReactElement {
  const [ isOpen, setIsOpen ] = useState(false);
  const history = useHistory();

  const [ downloading, setDownloading ] = useState(false);

  function handlePrint() {
    setDownloading(true);
    onPrintNotice(enrollment).finally(compose(setDownloading, F));
  }

  function handleUpdateClick() {
    if (enrollment.cQualId) {
      if (!isNil(enrollment.arpaEnrolled)) {
        history.push(`/update-cobra-qe/${enrollment.cQualId}`);
      } else {
        history.push(`/update-direct-billing-event/${enrollment.cQualId}`);
      }
    }
  }

  return (
    <TableRow className="participant-overview-crl-enrollments-row">
      <TableCell>
        <p className="text-black text-sm">
          {dayjs(enrollment.eventDate).format('MM/DD/YYYY')}
        </p>
      </TableCell>
      <TableCell>
        <p className="text-black text-sm">
          {enrollment.reason}
        </p>
      </TableCell>
      <TableCell>
        <p className="text-black text-sm">
          {enrollment.status}
        </p>
        {
          !!enrollment.arpaEnrolled
            ? <p className="text-black text-sm font-bold">{enrollment.arpaEnrolled}</p>
            : <></>
        }
      </TableCell>
      <TableCell>
        <p className="text-black text-sm">
          {dayjs(enrollment.startDate).format('MM/DD/YYYY')}
        </p>
      </TableCell>
      <TableCell>
        <p className="text-black text-sm">
          {enrollment.endDate ? dayjs(enrollment.endDate).format('MM/DD/YYYY') : ''}
        </p>
      </TableCell>
      <TableCell>
        <p className="text-black text-sm">
          {enrollment.lastPaymentDate ? dayjs(enrollment.lastPaymentDate).format('MM/DD/YYYY') : ''}
        </p>
      </TableCell>
      <TableCell>
        {
          !!enrollment.cQualId
            ? (
              <>
                <Button
                  color="primary"
                  type="button"
                  className="cobra-enrollment-update"
                  tabIndex={-1}
                  onClick={handleUpdateClick}
                >
                  <Tooltip title="Update" aria-label="update">
                    <EditIcon />
                  </Tooltip>
                </Button>
              </>
            )
            : <></>
        }
        {
          (isNil(enrollment.docNumber) || enrollment.docNumber === 0)
            ? (
              <Button
                color="primary"
                type="button"
                data-testid="cobra-enrollment-print"
                tabIndex={-1}
                disabled={downloading}
                onClick={handlePrint}
              >
                <Tooltip title="Print Notice" aria-label="print notice">
                  <GetAppIcon />
                </Tooltip>
              </Button>
            )
            : <></>
        }
        {
          isNil(enrollment.arpaEnrolled)
            ? (
              <Button
                type="button"
                className="text-danger"
                tabIndex={-1}
                onClick={compose(setIsOpen, T)}
              >
                <Tooltip title="Cancel Billing" aria-label="cancel billing">
                  <CancelIcon />
                </Tooltip>
              </Button>
            )
            : <></>
        }
      </TableCell>
      <CancelDirectBillingEventModal
        enrollment={enrollment}
        title="Cancel Direct Billing Event"
        isOpen={isOpen}
        onClose={compose(setIsOpen, F)}
        onCancel={compose(setIsOpen, F)}
      />
    </TableRow>
  );
}

export default DisplayCobRetLoaEnrollmentRow;
