import { ReactElement } from 'react';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import { all, always, andThen, compose, equals, ifElse } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  cancelModalOpened,
  selectCobraPlan,
  selectCompanyId,
  selectCobraOePrintOptionBlackAndWhite,
  selectCobraOePrintOptionTwoSided,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import OeFiles from 're-enrollment-checklist/cobra/oe-packets/OeFiles';
import { completeChecklist } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import { noop } from 'shared/utils';
import store from 'store';
import { PlanCodes } from 'shared/enums/plan-codes.enum';

interface Props {
    onComplete: () => void;
}

function SummaryInfo({ onComplete }: Props): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const plan = useSelector(selectCobraPlan);
  const companyId = useSelector(selectCompanyId);
  const blackAndWhite = useSelector(selectCobraOePrintOptionBlackAndWhite);
  const twoSided = useSelector(selectCobraOePrintOptionTwoSided);
  const disabled = plan.cobraOeHandler === 0 && !plan.cobraOeTemplateCoverLetter
    ? !plan.generalSectionComplete
    : !all(equals(true),
      [
        plan.generalSectionComplete,
        plan.coverLetterSectionComplete,
        plan.oePacketsSectionComplete,
      ]);
  const handleContinue = ifElse(
    always(plan.oePacketsChecklistComplete),
    compose(history.push, always('/reenrollment-checklist')),
    compose(
      compose(
        andThen(
          onComplete,
        ),
        dispatch, compose(completeChecklist, always(PlanCodes.COBRA)),
      ),
    ),
  );

  return (
    <>
      <div>
        <div className="my-10">
          <div className="flex justify-between">
            <p>Cover Letter Info</p>
            <Button
              className="text-sm"
              variant="text"
              onClick={
                compose(
                  history.push,
                  always(`/reenrollment-checklist/cobra/${planYearId}/cover-letter-info`))
              }
            >Edit
            </Button>
          </div>
          <hr />
          <div className="w-3/5">
            <div className="my-5">
              <h6 className="inline">Packets must be sent by:</h6>
              <p className="inline mx-3">{dayjs(plan.cobraOeSendPackets).format('MM/DD/YYYY')}</p>
            </div>

            <div className="my-5">
              <h6 className="inline">Packets must be returned by:</h6>
              <p className="inline mx-3">{dayjs(plan.cobraOeResponseDeadline).format('MM/DD/YYYY')}</p>
            </div>

            <div className="my-5">
              <h6>
                If a participant does not return an election form,
                are they termed or assumed to continue with their current elections?
              </h6>
              <p>
                {plan.cobraOeResponseRequired === 2
                  ? 'Assumed to continue with their current elections (recommended)'
                  : 'They are termed'}
              </p>
            </div>
          </div>
        </div>

        <div className="my-10">

          <div className="flex justify-between">
            <p>OE Packets</p>
            <Button
              className="text-sm"
              variant="text"
              onClick={compose(history.push, always(`/reenrollment-checklist/cobra/${planYearId}/oe-packets`))}
            >Edit
            </Button>
          </div>
          <hr />
          <div className="w-3/5">
            <div className="my-5">
              <h6>Included Files</h6>
              <OeFiles showDownload companyId={companyId} planYearId={planYearId} />
            </div>
          </div>

          <div className="w-3/5">
            <div className="my-5">
              <h6>Page Count:</h6>
              {!!twoSided ? 'Two Sided (Recommended)' : 'Single Sided'}
            </div>
          </div>

          <div className="w-3/5">
            <div className="my-5">
              <h6>Color Options:</h6>
              {!!blackAndWhite ? 'Black and White' : 'Color'}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row-reverse">
        <Button
          data-testid="complete-button"
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disabled={disabled}
        >Complete and Submit
        </Button>

        <Button
          className="mr-4"
          onClick={compose(dispatch, cancelModalOpened, noop)}
        >Cancel
        </Button>
      </div>
    </>
  );
}

export default SummaryInfo;
