import { identity } from 'ramda';
import {
  selectCobraPlan, selectCobraOePlanYearStart,
  setCobraOeValidations,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { noop } from 'shared/utils';
import yupErrorsToObject from 'store/middleware/validators/utils/yupErrorsToObject';
import validationSchema from 're-enrollment-checklist/cobra/cover-letter/cobraOePacket.validators';
import store from 'store';

export const validate = (appStore: typeof store) => (next: any) => async (action: any) => {
  if (action) {
    next(action);
  }

  if (
    action.type.includes('reEnrollmentChecklist/setCobra')
      && !action.type.includes('reEnrollmentChecklist/setCobraOeValidations')
  ) {
    const state = appStore.getState();
    const cobraPlan = selectCobraPlan(state);
    const validations = await validationSchema
      .validate(cobraPlan, { stripUnknown: true, abortEarly: false })
      .then(noop)
      .catch(identity);

    const validation = yupErrorsToObject(validations?.errors ?? []);
    const planYearStart = selectCobraOePlanYearStart(state);
    appStore.dispatch(setCobraOeValidations({
      type: 'cobra',
      planYearStart,
      ...validation,
    }));
  }
};
