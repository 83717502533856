import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { compose, F, T } from 'ramda';

import { AdminInvoice } from './administrative-billing.types';
import { currencyFormatter } from 'shared/utils';
import { PayInvoiceModal } from './PayInvoice';
import { downloadAdministrativeBillingInvoice } from 'shared/api/administrativeBillingApi';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { useDispatch } from 'react-redux';
import store from 'store';

interface AdministrativeBillingRowProps {
  adminInvoice: AdminInvoice;
  dateFormat?: string;
}

function AdministrativeBillingRow({
  adminInvoice,
  dateFormat = 'MM/DD/YYYY',
}: AdministrativeBillingRowProps): React.ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();

  const [ isOpen, setIsOpen ] = useState(false);

  function formatDate(date?: string): string {
    return date ? dayjs(date).format(dateFormat) : '';
  }

  function isPaid() {
    return adminInvoice.amount - adminInvoice.paid === 0;
  }

  function handleViewClick() {
    downloadAdministrativeBillingInvoice(adminInvoice.companyId, adminInvoice.invoiceNumber)
      .catch((err) => {
        compose(dispatch, addHttpErrorNotification)(err);
      });
  }

  return (
    <TableRow>
      <TableCell className="text-sm">{adminInvoice.invoiceNumber}</TableCell>
      <TableCell className="text-sm">{formatDate(adminInvoice.invoiceDate)}</TableCell>
      <TableCell className="text-sm">{formatDate(adminInvoice.dueDate)}</TableCell>
      <TableCell className="text-sm">{currencyFormatter.format(adminInvoice.amount)}</TableCell>
      <TableCell className="text-sm">
        <div className="text-green-600 bg-green-100 font-bold" style={ { width: 'fit-content' } }>
          {adminInvoice.paid === 0 ? null : currencyFormatter.format(adminInvoice.paid)}
        </div>
      </TableCell>
      <TableCell className="text-sm">
        <div className="text-yellow-600 bg-yellow-200 font-bold" style={ { width: 'fit-content' } }>
          {currencyFormatter.format(adminInvoice.open)}
        </div>
      </TableCell>
      <TableCell>
        <Button
          color="primary"
          onClick={handleViewClick}
          className="text-sm view-admin-invoice"
        >
          View
        </Button>
        <Button
          color="primary"
          onClick={compose(setIsOpen, T)}
          className="text-sm pay-admin-invoice"
          disabled={isPaid()}
        >
          Pay
        </Button>
      </TableCell>
      <PayInvoiceModal
        invoice={adminInvoice}
        title="Pay Invoice"
        isOpen={isOpen}
        onClose={compose(setIsOpen, F)}
        onCancel={compose(setIsOpen, F)}
      />
    </TableRow>
  );
}

export default AdministrativeBillingRow;
