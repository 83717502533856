import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactUser, Permission, Responsibility, SummaryUser } from 'manage-settings/manage-users/manage-users.types';
import { CompanyLocation } from 'enter-cobra-qe/cobra-qe.types';
import { PayFreqSelectOption } from 'components/shared/PayFreqSelect';
import { RootState } from 'store/rootReducer';
import { AppThunk } from 'store';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { getResponsibilities } from 'shared/api/responsibilitiesApi';
import { getPermissions } from 'shared/api/permissionsApi';
import { assertIsDefined } from 'shared/asserts';
import { getLocations, getPayFrequencies } from 'shared/api/companiesApi';
import { getSummaryUsers, getContactUser } from 'shared/api/usersApi';
import { defaultContact } from 'shared/constants';

export interface ManageUserState {
  responsibilities: Responsibility[] | null;
  permissions: Permission[] | null;
  locations: CompanyLocation[] | null;
  payFrequencies: PayFreqSelectOption[] | null;
  users: SummaryUser[] | null;
  selectedUser: ContactUser | null;
}

export const defaultSelectedUser = {
  ...defaultContact,
  responsibilities: [],
  locations: [],
  payFrequencies: [],
  permissions: [],
};

export const initialState: ManageUserState = {
  responsibilities: [],
  permissions: [],
  locations: [],
  payFrequencies: [],
  users: [],
  selectedUser: defaultSelectedUser,
};

const manageUsersSlice = createSlice({
  name: 'manageUsers',
  initialState,
  reducers: {
    setResponsibilities(state, { payload }: PayloadAction<Responsibility[] | null>) {
      state.responsibilities = payload;
    },
    setPermissions(state, { payload }: PayloadAction<Permission[] | null>) {
      state.permissions = payload;
    },
    setLocations(state, { payload }: PayloadAction<CompanyLocation[] | null>) {
      state.locations = payload;
    },
    setPayFrequencies(state, { payload }: PayloadAction<PayFreqSelectOption[] | null>) {
      state.payFrequencies = payload;
    },
    setUsers(state, { payload }: PayloadAction<SummaryUser[] | null>) {
      state.users = payload;
    },
    setSelectedUser(state, { payload }: PayloadAction<ContactUser | null>) {
      state.selectedUser = payload;
    },
    resetSelectedUser(state) {
      state.selectedUser = defaultSelectedUser;
    },
  },
});

export const {
  setResponsibilities,
  setPermissions,
  setLocations,
  setPayFrequencies,
  setUsers,
  setSelectedUser,
  resetSelectedUser,
} = manageUsersSlice.actions;

export default manageUsersSlice.reducer;

// thunk
export function loadResponsibilities(): AppThunk {
  return async (dispatch) => {
    dispatch(setResponsibilities(null));

    try {
      const responsibilities = await getResponsibilities();
      dispatch(setResponsibilities(responsibilities));
    } catch (ex) {
      dispatch(setResponsibilities([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadPermissions(): AppThunk {
  return async (dispatch) => {
    dispatch(setPermissions(null));

    try {
      const permissions = await getPermissions();
      dispatch(setPermissions(permissions));
    } catch (ex) {
      dispatch(setPermissions([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadLocations(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLocations(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const locations = await getLocations(selectedCompany.compid);
      dispatch(setLocations(locations));
    } catch (ex) {
      dispatch(setLocations([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadPayFrequencies(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setPayFrequencies(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const payFrequencies = await getPayFrequencies(selectedCompany.compid);
      dispatch(setPayFrequencies(payFrequencies));
    } catch (ex) {
      dispatch(setPayFrequencies([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadUsers(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setUsers(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const users = await getSummaryUsers(selectedCompany.compid);
      dispatch(setUsers(users));
    } catch (ex) {
      dispatch(setUsers([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadSelectedUser(conid: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setSelectedUser(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const user = await getContactUser(selectedCompany.compid, conid);
      dispatch(setSelectedUser(user));
    } catch (ex) {
      dispatch(setSelectedUser(null));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectResponsibilities = (state: RootState): Responsibility[] | null => state.manageUsers.responsibilities;

export const selectPermissions = (state: RootState): Permission[] | null => state.manageUsers.permissions;

export const selectLocations = (state: RootState): CompanyLocation[] | null => state.manageUsers.locations;

export const selectPayFrequencies =
  (state: RootState): PayFreqSelectOption[] | null => state.manageUsers.payFrequencies;

export const selectUsers = (state: RootState): SummaryUser[] | null => state.manageUsers.users;

export const selectSelectedUser = (state: RootState): ContactUser | null => state.manageUsers.selectedUser;
