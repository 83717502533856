import * as yup from 'yup';
import {
  fname,
  lname,
  hstreet1,
  hstreet2,
  hcity,
  hstate,
  requiredText,
  errorMessages,
  regexPatterns,
} from 'shared/common-validators';
import { ReportAbsenceStepSlug } from './report-an-absence-steps';

export const personalDetailsSchema = yup.object().shape({
  employee: yup.object({
    eeId: yup
      .string()
      .required(requiredText),
    nameFirst: fname.fname,
    nameLast: lname.lname,
    dob: yup
      .date()
      .typeError('Invalid Date Format')
      .nullable(),
    phone: yup
      .string()
      .matches(regexPatterns.phone, errorMessages.phone.matches)
      .max(14, errorMessages.phone.max)
      .min(10, errorMessages.phone.min)
      .nullable(),
    addr1: hstreet1.hstreet1,
    addr2: hstreet2.hstreet2,
    city: hcity.hcity,
    stateProvCd: hstate.hstate,
    postalCd: yup
      .string()
      .trim()
      .matches(regexPatterns.postalCd, errorMessages.postalCd.matches)
      .min(5, errorMessages.postalCd.min)
      .max(10, errorMessages.postalCd.max)
      .required(requiredText),
  }),
});

export const eventInfoSchema = yup.object().shape({
  event: yup.object({
    absenceDate: yup
      .date()
      .typeError('Invalid Date Format')
      .required(requiredText),
    hours: yup
      .number()
      .min(0, 'Value must be between 0 and 24')
      .max(24, 'Value must be between 0 and 24')
      .required(requiredText),
    minutes: yup
      .number()
      .min(0, 'Value must be between 0 and 59')
      .max(59, 'Value must be between 0 and 59')
      .required(requiredText),
    title: yup
      .string()
      .max(50, 'Must be 50 characters or less')
      .nullable(),
  }),
});

export const reportAbsenceSchema = personalDetailsSchema.concat(eventInfoSchema);

export const validation = new Map<ReportAbsenceStepSlug, object>([
  [ 'personal-details', personalDetailsSchema ],
  [ 'event-info', eventInfoSchema ],
  [ 'summary', reportAbsenceSchema ],
]);
