import { SelectOption } from 'shared/types/form.types';

export const defaultPersonalDetailsFields = {
  eeId: '',
  nameFirst: '',
  nameLast: '',
  email: '',
  phone: '',
  addr1: '',
  addr2: '',
  city: '',
  stateProvCd: '',
  postalCd: '',
};

export const defaultEmploymentDetailsFields = {
  type: '',
  location: '',
  supervisorName: '',
  supervisorEmail: '',
  hireDate: '',
  totalHoursWorked: '',
};

export const defaultEventInfoFields = {
  dateStart: '',
  dateEnd: '',
  reason: '',
  kinRelation: '',
  duration: '',
  explanation: '',
  confirmationEmail: '',
  faxHealthProvider: false,
  providerName: '',
  providerFax: '',
  kinName: '',
};

export const defaultLeaveRequestValues = {
  employee: defaultPersonalDetailsFields,
  employment: defaultEmploymentDetailsFields,
  event: defaultEventInfoFields,
};

export const employmentTypeOptions: SelectOption[] = [
  { label: 'Full Time', value: 'Full Time' },
  { label: 'Part Time', value: 'Part Time' },
  { label: 'Other', value: 'Other' },
];

export const absenceOrAccommodationRequestReasons: SelectOption[] = [
  {
    label: 'Leave of absence for my own health condition',
    value: 'Leave of absence for my own health condition',
  },
  {
    label: 'Leave of absence to care for a family member with a health condition',
    value: 'Leave of absence to care for a family member with a health condition',
  },
  {
    label: 'Maternity/bonding leave of absence as the birth parent',
    value: 'Maternity/bonding leave of absence as the birth parent',
  },
  {
    label: 'Bonding leave of absence as the non-birth parent',
    value: 'Bonding leave of absence as the non-birth parent',
  },
  {
    label: 'Bonding leave of absence related to adoption',
    value: 'Bonding leave of absence related to adoption',
  },
  {
    label: 'Bonding leave of absence related to a foster placement',
    value: 'Bonding leave of absence related to a foster placement',
  },
  {
    label: 'Working accommodation for a disability',
    value: 'Working accommodation for a disability',
  },
  {
    label: 'Military caregiver leave of absence',
    value: 'Military caregiver leave of absence',
  },
  {
    label: 'Military bonding leave of absence',
    value: 'Military bonding leave of absence',
  },
  {
    label: 'USERRA (Military leave)',
    value: 'USERRA (Military leave)',
  },
  {
    label: 'Non-medical personal leave of absence',
    value: 'Non-medical personal leave of absence',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const leaveDurationOptions: SelectOption[] = [
  {
    label: 'Continuous (one block of time)',
    value: 'Continuous (one block of time)',
  },
  {
    label: 'Intermittent (as needed for flare ups and appointments)',
    value: 'Intermittent (as needed for flare ups and appointments)',
  },
  {
    label: 'Reduced Schedule',
    value: 'Reduced Schedule',
  },
  {
    label: 'Unknown',
    value: 'Unknown',
  },
];
