import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';

import ReportsTile from '../../shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useSelector } from 'react-redux';
import { downloadInitialNoticeSentReport } from 'shared/api/initialNoticeSentApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

interface InitialNoticeSentProps {
  startDate: string | null;
  endDate: string | null;
  count: number | null;
}

function InitialNoticeSent({ startDate, endDate, count }: InitialNoticeSentProps) {
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  function handleExport() {
    startDownloading();
    downloadInitialNoticeSentReport(compid, startDate ?? undefined, endDate ?? undefined)
      .then(doneDownloading)
      .catch(handleError);
  }

  return (
    <ReportsTile
      count={count}
      title="Initial Notices Sent"
      description="Lists the status of mailings for any COBRA Initial Notices
and will provide you with detail regarding mailing activity. "
      exportDisabled={count === null || !startDate || !endDate}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default InitialNoticeSent;
