import React, { ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { always, compose, cond, equals, pathOr, T } from 'ramda';
import store from 'store';
import { toNumber } from 'shared/utils';
import PlanRow from 'rates-history/PlanRow';
import { HistoricalPlanWithRates, PlanYear } from 'rates-history/rates-history.types';

interface Props {
    plans: HistoricalPlanWithRates[];
    planYears: PlanYear[];
    reducer: ActionCreatorWithPayload<number>;
    fetchStatus: string;
}

function HistoryTable({ plans, planYears, reducer, fetchStatus }: Props) {
  const dispatch = useDispatch<typeof store.dispatch>();

  return (
    <>
      <FormControl variant="outlined" className="w-2/5 my-4 ">
        <InputLabel>Plan Year</InputLabel>
        <Select
          label="Plan Year"
          onChange={compose(
            dispatch,
            reducer,
            toNumber,
            pathOr('-1', [ 'target', 'value' ]),
          )}
          native
        >
          <option value={-1} >All Plan Years</option>
          {planYears.map((p) => <option value={p.planYearId} key={p.planYearId}>{p.planYearName}</option>)}
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {
              cond<string, ReactElement| ReactElement[]>([
                [ equals('pending'), always(
                  <>
                    <Skeleton
                      data-testid="loading"
                      variant="rectangular"
                      height={70}
                      style={ { marginBottom: '0.1rem' } }
                    />
                    <Skeleton
                      data-testid="loading"
                      variant="rectangular"
                      height={70}
                      style={ { marginBottom: '0.1rem' } }
                    />
                    <Skeleton
                      data-testid="loading"
                      variant="rectangular" height={70}
                    />
                  </>,
                ) ],
                [ T, always(plans
                  .filter(p => p.coverageTiers.length > 0)
                  .map((plan) => (
                    <PlanRow plan={plan} key={plan.planId} />
                  ))) ]],
              )(fetchStatus)
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default HistoryTable;
