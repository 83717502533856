import { ChangeEvent, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, F, forEachObjIndexed, mergeLeft, pipe, tap } from 'ramda';
import LoopIcon from '@mui/icons-material/Loop';
import TextField from '@mui/material/TextField';

import { selectSelectedCompany } from 'companies/companies.slice';
import { Participant } from 'participant-search/participant-list.types';
import { debounce, noop } from 'shared/utils';
import { getParticipant } from 'shared/api/participantApi';
import { defaultParticipant } from 'shared/constants';
import { omitNil } from 'shared/utils/omit-nil';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

type FieldSetter = (field: string, value: any, shouldValidate?: boolean | undefined) => unknown;

export function setParticipantValues(setter: FieldSetter): (obj: object) => void {
  return forEachObjIndexed((value: any, key: string) => {
    if (key === 'empno' || key === 'socsec' || !!value) {
      setter(key, value);
    }
  });
}

export interface PayrollIdParticipantLookupInputProps {
  placeholder?: string;
  initialValue?: string; // initial value, default ''
  onSearchStarted?: () => void;
  onParticipantFound?: (participant: Participant) => void;
  onTextChanged?: (text: string) => void;
  searchOn?: 'blur' | 'change';
  size?: 'small' | 'medium'; // this gets passed along to the TextField. Default is "medium"
  variant?: 'outlined' | 'standard' | 'filled'; // passed along to TextField. Default is "outlined"
  [otherProp: string]: any;
}

function PayrollIdParticipantLookupInput({
  placeholder = '',
  initialValue = '',
  onSearchStarted,
  onParticipantFound,
  onTextChanged,
  searchOn = 'blur',
  size = 'medium',
  variant = 'outlined',
  className,
  ...rest
}: PayrollIdParticipantLookupInputProps) {
  const handleError = useErrorNotifier();
  const [ previousPayrollId, setPreviousPayrollId ] = useState<string>(initialValue);
  const [ payrollId, setPayrollId ] = useState(initialValue);
  const [ searching, setSearching ] = useState(false);

  const { compid, iddesc = 'SSN' } = useSelector(selectSelectedCompany) ?? { compid: undefined, iddesc: undefined };
  const [ debouncedSearch ] = useState(() => debounce(pipe(
    tap(onTextChanged ?? noop),
    tap(searchForParticipant),
  ), 750));
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  function handlePayrollIdChanged(value: string) {
    const transormed = compose(padPayrollId, deHyphenate)(value);
    setPayrollId(transormed);

    if (onTextChanged) {
      onTextChanged(value);
    }

    if (searchOn === 'change') {
      debouncedSearch(transormed);
    }
  }

  function onChange({ target: { value } }: ChangeEvent<{name?: string; value: unknown;}>) {
    handlePayrollIdChanged(value as string);
  }

  function padPayrollId(pid: string) {
    if (pid.length && pid.length < 9) {
      return pid.padStart(9, '0');
    }

    return pid;
  }

  function deHyphenate(pid: string) {
    return pid.replace(/-+/g, '');
  }

  function searchForParticipant(pid: string) {
    if (compid && onParticipantFound && pid !== previousPayrollId) {
      if (onSearchStarted) {
        onSearchStarted();
      }

      setSearching(true);
      setPreviousPayrollId(pid);

      getParticipant(compid, pid.replace(/[^\d]+/g, ''))
        .then((p) => {
          onParticipantFound(mergeLeft(omitNil(p), defaultParticipant));
        })
        .catch((err) => {
          onParticipantFound(defaultParticipant as Participant);

          if (err.status !== 404) {
            handleError(err);
          }
        })
        .finally(compose(setSearching, F));
    }
  }

  function handleBlur() {
    let paddedPayrollId = padPayrollId(payrollId);
    handlePayrollIdChanged(paddedPayrollId);
    searchForParticipant(paddedPayrollId);
  }

  return (
    <>
      <TextField
        variant={variant}
        size={size}
        value={payrollId}
        onChange={onChange}
        fullWidth
        label={iddesc || 'SSN'}
        placeholder={placeholder}
        data-testid="payrollIdParticipantLookupInput"
        onBlur={searchOn === 'blur' ? handleBlur : undefined}
        {...rest}
        InputLabelProps={ {
          shrink: true,
        } }
        inputProps={ {
          maxLength: 11,
          role: 'textbox',
        } }
        InputProps={ {
          className,
          endAdornment: searching
            ? <LoopIcon className="animate-spin participant-lookup-searching" />
            : <></>,
        } }
      />

    </>
  );
}

export default PayrollIdParticipantLookupInput;
