import React, { ReactElement } from 'react';
import SummaryInfo from './SummaryInfo';
import { useHistory } from 'react-router-dom';
import { always, compose } from 'ramda';

function SummaryTab(): ReactElement {
  const history = useHistory();
  const onComplete = compose(
    history.push,
    always(`/reenrollment-checklist`),
  );

  return (
    <SummaryInfo onComplete={onComplete} />
  );
}

export default SummaryTab;

