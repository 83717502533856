import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { selectSelectedCompany } from 'companies/companies.slice';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { getOpenPlans } from 'shared/api/openPlansApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

export function useOpenPlans() {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };

  const [ openPlans, setOpenPlans ] = useState<OpenPlan[] | null>(null);
  const [ setDefault ] = useState(() => compose(setOpenPlans, always([])));
  const { current: handleError } = useRef(compose(
    tap(setDefault),
    useErrorNotifier(),
  ));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    getOpenPlans(compid)
      .then((result) => {
        if (mountedRef.current) {
          setOpenPlans(result?.map((n, i) => ({ ...n, unqid: i })) ?? []);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
    };
  }, [ compid, setOpenPlans, handleError ]);

  return openPlans;
}
