import { Response } from 'miragejs';

import cobraDateRangeCounts from '../fixtures/cobra-date-range-counts.json';

function routing(server) {
  server.get('/companies/:companyId/reports/date-range/cobra', (schema) => {
    const data = cobraDateRangeCounts;
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });
}

export default routing;
