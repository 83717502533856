import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { getReductionListTotal, downloadReductionList } from 'shared/api/reportsApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import GraySummaryTile from 'reports/shared/GraySummaryTile';
import ReportExportButton from 'reports/shared/ReportExportButton';
import { useDeductionDateOptions } from 'reports/useDeductionDateOptions';
import CommonSelect from 'components/shared/form/CommonSelect';
import { currencyFormatter } from 'shared/utils';

interface ReductionListTileProps {
  selectedPlan?: OpenPlan;
}

function ReductionListTile({ selectedPlan }: ReductionListTileProps) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const deductionOptions = useDeductionDateOptions();

  const [ payDate, setPayDate ] = useState('');
  const [ total, setTotal ] = useState<number | null>(0);
  const [ downloading, setDownloading ] = useState(false);

  const errorNotifier = useErrorNotifier();
  const [ handleError ] = useState(() => compose(
    tap(compose(setTotal, always(0))),
    errorNotifier,
  ));

  useEffect(() => {
    if (compid && selectedPlan && payDate.length > 0) {
      handlePayDateChange(payDate);
    }
  }, [ compid, selectedPlan, payDate ]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleExport() {
    if (compid && selectedPlan && payDate.length > 0) {
      setDownloading(true);
      downloadReductionList(compid, selectedPlan.pyrid, selectedPlan.plcid, payDate)
        .catch(handleError)
        .finally(() => setDownloading(false));
    }
  }

  function handlePayDateChange(date: string) {
    setPayDate(date);

    if (compid && selectedPlan) {
      setTotal(null);
      getReductionListTotal(compid, selectedPlan.pyrid, selectedPlan.plcid, date)
        .then(setTotal)
        .catch(handleError);
    }
  }

  function reductionTotal() {
    return (
      <h4 data-testid="balance-reports-reduction-total-loaded" className="text-high-em text-3xl font-bold">
        {currencyFormatter.format(total ?? 0)}
      </h4>
    );
  }

  const DeductionDateSelect = withLoopIcon(CommonSelect, 'Loading deduction dates...', deductionOptions === null);
  const ReductionTotal = withLoopIcon(reductionTotal, '', total === null);

  return (
    <GraySummaryTile>
      <div>
        <DeductionDateSelect
          options={deductionOptions!}
          onSelectChange={handlePayDateChange}
          value={payDate}
          label="Deduction Date"
          name="deduction-date"
        />
      </div>
      <div className="mt-4"><ReductionTotal /></div>
      <h5 className="text-high-em my-4 text-base">Reduction List</h5>
      <p className="text-gray-chateu">Live data of the amount per pay period
        we expect each participant to contribute based on their annual election, per plan.
      </p>
      <hr className="my-4" />
      <div className="w-full flex justify-end">
        <ReportExportButton
          onExport={handleExport}
          disabled={total === null || payDate === '' || downloading}
          downloading={downloading}
          title="Export enrollment report"
        />
      </div>
    </GraySummaryTile>
  );
}

export default ReductionListTile;
