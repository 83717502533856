import { NavConfig } from 'navigation/navigation.types';

export type NavPredicate = (item: NavConfig) => boolean;

export function makeNav(predicate: NavPredicate) {
  return function (nav: NavConfig[]) {
    return nav.filter(predicate);
  };
}

