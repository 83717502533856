export type ApiHosts = {
  FMLA_HOST: string;
};

export const ApiHostsMap = {
  FMLA_HOST: process.env.REACT_APP_FMLA_HOST,
};

export enum ApiHostsEnum {
  FMLA_HOST = 'FMLA_HOST',
}

export type ApiHostsType = keyof ApiHosts;
