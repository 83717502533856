import React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { assertIsDefined } from 'shared/asserts';
import { logout } from './authentication';

import { Link } from 'react-router-dom';

function Logout(): React.ReactElement {
  function handleLogout() {
    assertIsDefined(process.env.REACT_APP_LOGIN_URL);
    logout();
    window.location.assign(process.env.REACT_APP_LOGIN_URL);
  }

  return (
    <Link onClick={handleLogout} to="" className="mr-2">
      <ExitToAppIcon /> Logout
    </Link>
  );
}

export default Logout;
