import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose, curry, T, F, hasPath, always, ifElse, pipe, andThen, otherwise, thunkify } from 'ramda';

import { selectSelectedCompany } from 'companies/companies.slice';
import { CobraInitialNotice, CobraQENotice, Notice } from './notice-list.types';
import request from 'shared/api-request';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { useParticipantService } from 'shared/custom-hooks/useParticipantService';
import { loadParticipant, setSelectedParticipant } from 'participant-search/participant.slice';
import Loading from 'components/shared/Loading';
import { colorFromLetters } from 'components/shared/avatar-color-palette';
import { printQualifyingEvent } from 'shared/api/qualifyingEventsApi';
import store from 'store';

interface NoticeTableRowProps {
  notice: Notice;
  dateFormat?: string;
  onNoticeDeleted: (p: Notice) => void;
}

function NoticeTableRow({
  notice,
  dateFormat = 'MM/DD/YYYY',
  onNoticeDeleted,
}: NoticeTableRowProps): React.ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { getParticipant } = useParticipantService();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };

  const [ deleting, setDeleting ] = useState(false);
  const [ printing, setPrinting ] = useState(false);
  const [ loadingParticipant , setLoadingParticipant ] = useState(false);
  const startLoadingParticipant = compose(setLoadingParticipant, T);
  const doneLoadingParticipant = compose(setLoadingParticipant, F);

  const handleError = useErrorNotifier();

  function handleEditClick() {
    if (notice.hasOwnProperty('qualid')) {
      const n = notice as CobraQENotice;
      startLoadingParticipant();
      getParticipant(n.payrollId)
        .then((p) => {
          dispatch(setSelectedParticipant(p));
          history.push(`/update-cobra-qe/${n.qualid}`);
        })
        .catch(handleError)
        .finally(doneLoadingParticipant);
    }
  }

  async function handleDeleteClick() {
    if (window.confirm('Are you sure you want to delete this notice?')) {
      setDeleting(true);

      let uri = `/api/companies/${compid}`;

      if (notice.hasOwnProperty('c_initid')) {
        const n = (notice as CobraInitialNotice);
        uri =
          `${uri}/initial-notices/${n.employeeNumber}/${n.c_initid}`;
      } else {
        const n = (notice as CobraQENotice);
        uri =
          `${uri}/cobra-qe/${n.employeeNumber}/${n.qualid}`;
      }

      try {
        await request(uri, { method: 'DELETE' });
        setDeleting(false);
        onNoticeDeleted(notice);
      } catch (err) {
        setDeleting(false);
        handleError(err);
      }
    }
  }

  function avatarColor(): string {
    return colorFromLetters(notice.firstName + notice.lastName);
  }

  function noticeInitials(): string {
    return notice.firstName.charAt(0).toUpperCase() + notice.lastName.charAt(0).toUpperCase();
  }

  function formatDate(date?: string): string {
    return date ? dayjs(date).format(dateFormat) : '';
  }

  function rowClasses(): string {
    const base = 'transition-opacity duration-100 ease-linear';

    return deleting ? `${base} opacity-50` : base;
  }

  function columns(): React.ReactElement {
    if (notice.hasOwnProperty('qualid')) {
      // pending qualifying event notice
      const qeNotice = notice as CobraQENotice;

      return (
        <>
          <TableCell className="text-sm">{formatDate(qeNotice.qualdate)}</TableCell>
          <TableCell className="text-sm">{qeNotice.eventType}</TableCell>
          <TableCell className="text-sm">{formatDate(qeNotice.covstart)}</TableCell>
          <TableCell className="text-sm">{formatDate(qeNotice.paidthru)}</TableCell>
        </>
      );
    }

    // pending initial notice
    const initNotice = notice as CobraInitialNotice;

    return <TableCell className="text-sm">{initNotice.destinationName}</TableCell>;
  }

  function updateButton() {
    if (notice.hasOwnProperty('qualid')) {
      return (
        <>
          <Button
            type="button"
            className="qe-notice-edit text-sm"
            tabIndex={-1}
            onClick={handleEditClick}
          >
            <EditIcon className="text-sm" /> Edit
          </Button>
        </>
      );
    }

    return <></>;
  }

  function printButton() {
    return ifElse(
      hasPath([ 'qualid' ]),
      always(
        <Button
          onClick={
            pipe(
              compose(setPrinting, T),
              thunkify(printQualifyingEvent)(compid as number, 'cobra', notice.unqid),
              andThen(compose(setPrinting, F)),
              otherwise(pipe(
                handleError,
                compose(setPrinting, F),
              )),
            )
          }
          disabled={printing}
          className="text-sm print-notice"
        >
          <GetAppIcon className="text-sm" /> Print
        </Button>,
      ),
      always(<></>),
    )(notice);
  }

  return (
    <TableRow className={rowClasses()}>
      <TableCell>
        <Avatar className={`text-white text-sm ${avatarColor()}`}>
          {noticeInitials()}
        </Avatar>
      </TableCell>
      <TableCell className="text-sm">{notice.firstName}</TableCell>
      <TableCell className="text-sm">{notice.lastName}</TableCell>
      <TableCell className="text-sm">{notice.ssn}</TableCell>
      <TableCell
        className="text-sm cursor-pointer"
        onClick={
          pipe(
            compose(dispatch, curry(loadParticipant)(compid as number), always(notice.ssn)),
            compose(history.push, always(`/participant-overview/${notice.employeeNumber}/general-info`)),
          )
        }
      >
        {notice.employeeNumber}
      </TableCell>
      <TableCell className="text-sm">{formatDate(notice.dateToSend)}</TableCell>
      {columns()}
      <TableCell>
        {updateButton()}
        <Button
          onClick={handleDeleteClick}
          data-testid={`${notice.firstName}-delete-notice`}
          className="text-sm delete-notice"
          disabled={deleting}
        >
          <DeleteIcon className="text-sm" /> Delete
        </Button>
        {printButton()}
        {loadingParticipant && <Loading text="Loading..." />}
      </TableCell>
    </TableRow>
  );
}

export default NoticeTableRow;
