import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { assocPath, compose, tap, always } from 'ramda';
import dayjs from 'dayjs';

import { MRADetails, PlanEnrollmentState } from 'plan-enrollment/plan-enrollment.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { selectParticipant } from 'participant-search/participant.slice';
import request, { APIOptions } from 'shared/api-request';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { selectPlanEnrollmentPlan } from 'plan-enrollment/plan-enrollment.slice';
import { CUSTOM_PLCCODE } from 'components/shared/GlobalConstants';
import store from 'store';

export function useEnrollmentDetails() {
  const dispatch = useDispatch<typeof store.dispatch>();

  const [ details, setDetails ] = useState<PlanEnrollmentState | null>(null);

  const plan = useSelector(selectPlanEnrollmentPlan);
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const { empno } = useSelector(selectParticipant) ?? { empno: undefined };
  const { planYearId, planCode, customPlanId } =
    useParams<{ planYearId: string; planCode: string; customPlanId: string; }>();

  const { current: abortCtrl } = useRef(new AbortController());
  const { current: setDefault } = useRef(compose(setDetails, always({})));
  const { current: handleError } = useRef(compose(
    tap(setDefault),
    dispatch,
    addHttpErrorNotification,
  ));
  const mountedRef = useRef(false);

  useEffect(() => {
    if (!plan) {
      mountedRef.current = true;
      let uri = '';

      if (planCode === CUSTOM_PLCCODE) {
        uri = `api/companies/${compid}/plan-enrollment/custom/${empno}/${planYearId}/${customPlanId}`;
      } else {
        uri = `api/companies/${compid}/plan-enrollment/regular/${empno}/${planYearId}/${planCode}`;
      }

      const apiOpts: APIOptions = { method: 'GET', signal: abortCtrl.signal };

      request<PlanEnrollmentState>(uri, apiOpts)
        .then((result) => {
          if (mountedRef.current) {
            setDetails((result?.planDetails as MRADetails).firstPayDateOfChange
              ? assocPath(
                [ 'planDetails', 'firstPayDateOfChange' ],
                dayjs((result?.planDetails as MRADetails).firstPayDateOfChange).format('YYYY-MM-DD'),
                result)
              : result ?? {});
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });

      return () => {
        mountedRef.current = false;
        abortCtrl.abort();
      };
    }
  }, [ dispatch, compid, empno, planCode, planYearId, customPlanId, setDefault, handleError, abortCtrl, plan ]);

  return details;
}
