import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { compose, nthArg } from 'ramda';

import { NonPlanYearInvoice } from './view-invoices.types';
import InvoiceTableRow from './InvoiceTableRow';

export interface InvoiceTableProps {
  invoices: NonPlanYearInvoice[] | null;
  isCobra: boolean;
}

function InvoicesTable({ invoices, isCobra }: InvoiceTableProps): React.ReactElement {
  const [ page, setPage ] = useState(0);

  return (
    <>
      <Table className="invoice-table">
        <TableHead>
          <TableRow>
            <TableCell>Invoice #</TableCell>
            <TableCell>Generated</TableCell>
            <TableCell>Period Start</TableCell>
            <TableCell>Period End</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell>Open</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            invoices && invoices.length > 0
              ? invoices
                .slice(page * 10, page * 10 + 10)
                .map((invoice) => (
                  <InvoiceTableRow
                    invoice={invoice}
                    isCobra={isCobra}
                    key={invoice.invoiceNumber}
                  />
                ),
                )
              : <TableRow><TableCell colSpan={7}><p>No invoices to display.</p></TableCell></TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={10}
              rowsPerPageOptions={[ 10 ]}
              colSpan={8}
              count={invoices?.length ?? 0}
              page={page}
              onPageChange={compose(setPage, nthArg(1))}
              SelectProps={ { native: true } }
            />
          </TableRow>
        </TableFooter>
      </Table>

    </>
  );
}

export default InvoicesTable;
