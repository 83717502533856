import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/administrative-billing', (schema) => {
    const { models } = schema.adminInvoices.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/administrative-billing', (schema) => {
    const { models } = schema.adminInvoices.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/direct-debit-accounts', (schema) => {
    const { models } = schema.directDebitAccounts.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/direct-debit-accounts', (schema) => {
    const { models } = schema.directDebitAccounts.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.post('/companies/:companyId/administrative-billing/pay-admin-invoice', () => {
    return new Response(201, { 'content-length': 0 }, {});
  })
}

export default routing;
