import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { always, compose, equals, ifElse, when } from 'ramda';
import CancelModal from 'components/shared/CancelModal';
import { CobraChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import {
  selectCobraPlan,
  setExistingCobraPlanOnDeck,
  setCobraPlanTerminating,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { saveCobraPlan, deleteCobraInProgressPlan } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import RatesStatus from './RatesStatus';
import { useState } from 'react';

interface Props {
  plan: CobraChecklistPlan;
  uniqueId: number;
  planYear: string;
  handleClickUpdateRates: () => void;
  resetCobraOnDeckPlan: () => void;
}

function PlanRatesCard({
  plan,
  uniqueId,
  planYear,
  handleClickUpdateRates,
  resetCobraOnDeckPlan,
}: Props) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { checklistComplete } = useSelector(selectCobraPlan);
  const [ modalIsOpen, setModalIsOpen ] = useState(false);

  const toggleModalState = compose(
    setModalIsOpen,
    always(!modalIsOpen),
  );

  const deleteInProgressPlan = compose(dispatch, deleteCobraInProgressPlan, always(plan.planInProgressId ?? 0));
  const setExistingPlanTerminating = compose(
    resetCobraOnDeckPlan,
    compose(dispatch, saveCobraPlan),
    compose(dispatch, setExistingCobraPlanOnDeck, always(plan.planId)),
    compose(dispatch, setCobraPlanTerminating, always(plan)),
    when(
      compose(equals(true), always(plan.terminating)),
      deleteInProgressPlan,
    ),
  );

  const handleDeletePlan = compose(
    ifElse(
      equals(true),
      deleteInProgressPlan,
      setExistingPlanTerminating,
    ),
    always(plan.isNewPlan),
  );

  const handleChangeActiveStatus = compose(
    ifElse(
      equals(true),
      always(handleDeletePlan),
      always(toggleModalState),
    ),
    always(plan.terminating),
  );

  const terminatingClasses = ifElse(
    equals(true),
    always(' opacity-40'),
    always(''),
  )(plan.terminating);

  const modalContent = ifElse(
    compose(equals(true), always(plan.isNewPlan)),
    always(`Are you sure you want to delete the plan ${plan.planName}?`),
    always(`You are about to mark this benefit as discontinued. This means that you are not 
    offering this benefit for the ${planYear} plan year. For any discontinued benefits, you 
    should contact your Account Manager to confirm whether participants currently on COBRA 
    with this benefit should be transferred to a new benefit plan, and if so what plan they 
    should be enrolled in instead.`),
  )(plan);

  const modalButtonText = ifElse(
    compose(equals(true), always(plan.isNewPlan)),
    always('Delete'),
    always('Discontinue'),
  )(plan);

  return (
    <div className="flex my-5 py-5 border-t" key={uniqueId}>
      <div className={`px-5 w-3/5${terminatingClasses}`}>
        <div>
          <label className="text-sm">Title</label>
          <div className="text-2xl">{plan.planName}</div>
        </div>
        <div className="mt-5 flex">
          <div className="w-3/5">
            <label className="text-sm">Plan Type</label>
            <div className="text-2xl">{plan.planTypeName}</div>
          </div>
          <div>
            <label className="text-sm">Carrier/Provider</label>
            <div className="text-2xl">{plan.carrier}</div>
          </div>
        </div>
      </div>
      <div className="w-2/5 flex items-start justify-end">
        <RatesStatus
          status={plan.ratesStatus || 'reviewed'}
          isNewPlan={plan.isNewPlan}
          checklistComplete={checklistComplete}
          handleUpdateRates={handleClickUpdateRates}
          handleChangeActiveStatus={handleChangeActiveStatus()}
        />
        {
          !plan.terminating &&
            <CancelModal
              isOpen={modalIsOpen}
              onCancel={handleDeletePlan}
              onClose={toggleModalState}
              title={modalContent}
              prompt=""
              cancelText={modalButtonText}
            />
        }
      </div>
    </div>
  );
}

export default PlanRatesCard;
