import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { compose, T, F, tap, always, view, replace, lensPath, set, or } from 'ramda';

import {
  resetEntry,
  selectDirectBillingEventTypes,
  selectDirectBillingQeEntry,
  selectDirectBillSavePayload,
} from '../direct-billing.slice';
import { DirectBillingStepProps } from '../direct-billing.types';
import classes from './DirectBillingSummary.module.css';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { saveDirectBillingEvent } from 'shared/api/directBillingApi';
import { selectSelectedCompany } from 'companies/companies.slice';
import Loading from 'components/shared/Loading';
import ParticipantSummary from 'components/shared/qe/ParticipantSummary';
import DependentsSummary from 'components/shared/qe/DependentsSummary';
import EventInfoSummary from 'components/shared/qe/EventInfoSummary';
import BenefitsSummary from 'components/shared/qe/BenefitsSummary';
import { setSelectedParticipant } from 'participant-search/participant.slice';
import { useHistoryBlock } from 'shared/custom-hooks/useHistoryBlock';
import store from 'store';

function DirectBillingSummary({ onCancel }: DirectBillingStepProps) {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const entry = useSelector(selectDirectBillingQeEntry);
  const payload = useSelector(selectDirectBillSavePayload);
  const eventTypes = useSelector(selectDirectBillingEventTypes);
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ blockRegex, setBlockRegex ] = useState(/^\/direct-bill-event/);
  const navUnblock = useHistoryBlock(blockRegex);

  const [ saving, setSaving ] = useState(false);
  const startSaving = compose(setSaving, T);
  const doneSaving = compose(setSaving, F);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneSaving), errorNotifier);

  function handleSubmit(doAnother: boolean) {
    return () => {
      startSaving();
      let pidLens = lensPath([ 'participant', 'payrollid' ]);
      let body = set(
        pidLens,
        replace(/-+/g, '', or(view(pidLens, payload), '')),
        payload,
      );

      saveDirectBillingEvent(compid, body)
        .then(() => {
          dispatch(setSelectedParticipant(null));
          dispatch(resetEntry());
          doneSaving();

          if (navUnblock) {
            navUnblock();
          }
          history.push(doAnother ? '/direct-bill-event/personal-details' : '/dashboard');
        })
        .catch(handleError);
    };
  }

  useEffect(() => {
    return () => {
      if (navUnblock) {
        navUnblock();
      }
    };
  }, [ navUnblock ]);

  const cancel = compose(onCancel, setBlockRegex, always(/^\/dashboard/));

  return (
    <div className="w-full flex flex-col space-y-4">
      <p className="mb-8">Are you sure you want to submit this billing event?</p>
      <div className="flex flex-col space-y-32">
        <div className="flex space-x-8">
          <div className="w-1/2">
            <div className="flex justify-between border-b border-solid border-gray-500 pb-1 mb-2">
              <p className={classes.sectionHeader}>Personal Details</p>
              <Link
                to="/direct-bill-event/personal-details"
                className={`${classes.link} text-sm`}
                data-testid="detailsLink"
              >
                Edit
              </Link>
            </div>
            <ParticipantSummary participant={entry.participant} />
          </div>
          <div className="w-1/2">
            <div className="flex justify-between border-b border-solid border-gray-500 pb-1 mb-2">
              <p className={classes.sectionHeader}>Dependents</p>
              <Link
                to="/direct-bill-event/dependents"
                className={`${classes.link} text-sm`}
                data-testid="dependentsLink"
              >
                Edit
              </Link>
            </div>
            <DependentsSummary dependents={entry.dependents.added.concat(entry.dependents.initial ?? [])} />
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <div className="flex justify-between border-b border-solid border-gray-500 pb-1 mb-2">
              <p className={classes.sectionHeader}>Event Info</p>
              <Link to="/direct-bill-event/event-info" className={`${classes.link} text-sm`} data-testid="eventLink">
                Edit
              </Link>
            </div>
            <EventInfoSummary eventInfo={entry.eventInfo} eventTypes={eventTypes} />
          </div>
          <div className="w-1/2">
            <div className="flex justify-between border-b border-solid border-gray-500 pb-1 mb-2">
              <p className={classes.sectionHeader}>Benefits</p>
              <Link to="/direct-bill-event/benefits" className={`${classes.link} text-sm`} data-testid="benefitsLink">
                Edit
              </Link>
            </div>
            <BenefitsSummary benefits={entry.benefits} participant={entry.participant} />
          </div>
        </div>
      </div>
      <div className="flex justify-end py-8 space-x-8">
        <Button
          color="primary"
          onClick={cancel}
          data-testid="cancelDirectBillEvent"
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit(false)}
          data-testid="submitDirectBillEvent"
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(true)}
          data-testid="submitDirectBillEventAndDoAgain"
        >
          Submit &amp; Enter Another Billing Event
        </Button>
      </div>
      {saving && <Loading text="Submitting Billing Event" />}
    </div>
  );
}

export default DirectBillingSummary;
