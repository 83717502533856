import React from 'react';
import { NavConfig } from './navigation.types';

import NavItem from './NavItem';
interface NavigationProps {
  navConfig: NavConfig[];
  showLegacy: boolean;
}

function navItemMapper(nav: NavConfig): React.ReactElement {
  let path = nav.path;

  return <NavItem path={path} icon={nav.icon} name={nav.name} key={nav.name} />;
}

function Navigation({ navConfig }: NavigationProps): React.ReactElement {
  return (
    <nav className="flex flex-col justify-start m-1">
      { navConfig.map(navItemMapper) }
    </nav>
  );
}

export default Navigation;
