import React, { ReactElement } from 'react';
import dayjs from 'dayjs';

import DataTable from 'components/shared/DataTable';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { useCustomTransactions } from './useCustomTransactions';
import withModal from 'components/shared/HOC/WithModal';
import TransactionExportButton from 'participant-overview/enrollment/view-transactions/TransactionExportButton';
import PdfDataTableGenerator from 'components/shared/PdfDataTableGenerator';
import { currencyFormatter } from 'shared/utils';

interface ViewCustomTransactionsProps {
  pyrid: string;
  customPlanId: string;
  isPending?: boolean;
}

const paidTableHeaders = [
  'Plan',
  'Participant',
  'Service Date',
  'Date Paid',
  'Submitted',
  'Paid',
];

const pendingTableHeaders = [
  'Plan',
  'Participant',
  'Service Date',
  'Paid',
  'Rejection Reason',
];

function CustomTransactionsTable({ headers, transactions, isPending }:
  { headers: string[]; transactions: (string | number)[]; isPending: boolean; }) {
  return (
    <>
      {
        isPending
          ? (<h6>Pending Transactions</h6>)
          :
          (
            <>
              <h6>Paid Transactions</h6>
              <div className="flex justify-end">
                <TransactionExportButton
                  document={
                    <PdfDataTableGenerator
                      title="View Paid Transactions"
                      headers={headers}
                      cells={transactions}
                    />
                  }
                  fileName="Transactions"
                />
              </div>
            </>
          )
      }
      <DataTable headers={headers} cells={transactions} isPaginated pageSize={10} />
    </>
  );
}

export function ViewCustomTransactions({ pyrid, customPlanId, isPending }: ViewCustomTransactionsProps): ReactElement {
  const transactions = useCustomTransactions(pyrid, customPlanId, !!isPending);

  let formattedTransactions = null;

  if (isPending) {
    formattedTransactions = transactions?.map(({ participantName, planName, ...t }) => ({
      planName,
      participantName,
      serviceDate: dayjs(t.serviceDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      submittedAmount: currencyFormatter.format(t.submittedAmount ?? 0),
      rejectionReason: t.rejectionReason,
    }));
  } else {
    formattedTransactions = transactions?.map(({ participantName, planName, ...t }) => ({
      planName,
      participantName,
      serviceDate: dayjs(t.serviceDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      paidDate: dayjs(t.paidDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      submittedAmount: currencyFormatter.format(t.submittedAmount ?? 0),
      paid: currencyFormatter.format(t.paid ?? 0),
    }));
  }

  const CustomTransactions = withLoopIcon(
    CustomTransactionsTable,
    'Loading ' + (isPending ? 'pending' : 'paid') + ' transactions...',
    transactions === null,
  );

  return (
    <CustomTransactions
      transactions={formattedTransactions as any[]}
      headers={isPending ? pendingTableHeaders : paidTableHeaders}
      isPending={!!isPending}
    />
  );
}

export default withModal(ViewCustomTransactions);
