import dayjs from 'dayjs';

import request, { ContentType } from '../api-request';
import { validateDate } from './validateDate';

export function downloadInitialNoticeSentReport(
  companyId: number,
  startDate?: string,
  endDate?: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download initial notice sent report for invalid company.'));
  }

  try {
    const start = validateDate(
      'Cannot download initial notice sent report for invalid start date.',
      startDate ?? dayjs().format(),
    );
    const end = validateDate(
      'Cannot download initial notice sent report for invalid end date.',
      endDate ?? dayjs().format(),
    );

    const uri = `/api/companies/${companyId}/reports/initial-notice-sent/export`
      + `?startDate=${start?.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}`;

    return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (err) {
    return Promise.reject(err);
  }
}
