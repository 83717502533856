interface Props {
  text?: string;
  className?: string;
}

export function SubmissionError({
  text = 'There was a problem with your submission',
  className = 'text-center',
}: Readonly<Props>): React.ReactElement {
  return (
    <div className={className} data-testid="submission-error">
      <p className="text-red-600">{text}</p>
    </div>
  );
}
