import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, nthArg } from 'ramda';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import store from 'store';
import {
  cobraPlanYearFilterChanged,
  selectFetchCobraRatesHistoryStatus,
  selectHistoricalCobraPlans,
  selectCobraHistoricalPlanYears,
  selectFetchDirectBillRatesHistoryStatus,
  selectHistoricalDirectBillPlans,
  selectDirectBillHistoricalPlanYears, directBillPlanYearFilterChanged,
} from 'rates-history/rates-history.slice';
import { selectSelectedCompany } from 'companies/companies.slice';
import { fetchDirectBillRateHistory, fetchRateHistory } from 'rates-history/rates-history.thunk';
import TabPanel from 'components/shared/TabPanel';
import HistoryTable from 'rates-history/HistoryTable';

type TabValues = 'cobra' | 'directBill'

function RatesHistory() {
  const [ tab, setTab ] = useState<TabValues>('cobra');
  const dispatch = useDispatch<typeof store.dispatch>();
  const cobraFetchStatus = useSelector(selectFetchCobraRatesHistoryStatus);
  const cobraPlans = useSelector(selectHistoricalCobraPlans);
  const cobraPlanYears = useSelector(selectCobraHistoricalPlanYears);

  const directBillFetchStatus = useSelector(selectFetchDirectBillRatesHistoryStatus);
  const directBillPlans = useSelector(selectHistoricalDirectBillPlans);
  const directBillPlanYears = useSelector(selectDirectBillHistoricalPlanYears);
  const company = useSelector(selectSelectedCompany);
  const companyId = company?.compid ?? 0;

  const companyHasCobra = company?.hasCobra;
  const companyHasRetiree = company?.hasRetiree;

  useEffect(() => {
    dispatch(fetchRateHistory(companyId));
    dispatch(fetchDirectBillRateHistory(companyId));
  }, [ dispatch, companyId ]);

  return (
    <div className="w-full xl:w-3/4 flex flex-col p-4">
      <h2 className="page-title">Rates History</h2>
      <Paper elevation={2} className="w-full flex flex-col p-4 border-neutral5">
        <div className="flex flex-col justify-around">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            className="-ml-4 -mt-4 flex justify-around"
            value={tab}
            centered
            onChange={compose(setTab, nthArg(1))}
          >
            {companyHasCobra && (
              <Tab
                data-testid="cobra"
                value="cobra"
                label="Cobra Historical Rates"
              />)}
            {companyHasRetiree && (
              <Tab
                data-testid="directBill"
                value="directBill"
                label="Direct Bill Historical Rates"
              />)}
          </Tabs>
          <hr />
          <TabPanel value={tab} index="cobra" >
            <HistoryTable
              fetchStatus={cobraFetchStatus}
              planYears={cobraPlanYears}
              plans={cobraPlans}
              reducer={cobraPlanYearFilterChanged}
            />
          </TabPanel>
          <TabPanel value={tab} index="directBill" >
            <HistoryTable
              fetchStatus={directBillFetchStatus}
              planYears={directBillPlanYears}
              plans={directBillPlans}
              reducer={directBillPlanYearFilterChanged}
            />
          </TabPanel>
        </div>
      </Paper>
    </div>
  );
}

export default RatesHistory;
