import { useState } from 'react';
import Button from '@mui/material/Button';
import { useFormik, FormikProps } from 'formik';
import { compose, T } from 'ramda';

import PayrollIdParticipantLookupInput, {
  setParticipantValues,
} from 'components/shared/PayrollIdParticipantLookupInput';
import { Participant } from 'participant-search/participant-list.types';
import { DBParticipant, DirectBillingStepProps } from '../direct-billing.types';
import { validationSchema } from 'enter-cobra-qe/personal-details/cobra-qe-personal-details.validators';
import ParticipantDetails from 'components/shared/ParticipantDetails';
import { useDispatch, useSelector } from 'react-redux';
import { resetEntry, selectDirectBillingQeEntry, setParticipant } from '../direct-billing.slice';
import { setSelectedParticipant } from 'participant-search/participant.slice';
import { defaultParticipant } from 'shared/constants';
import { useHistory } from 'react-router-dom';
import { QeParticipant } from 'shared/types/non-plan-year.types';
import store from 'store';

function DirectBillingPersonalDetails({ onCancel }: DirectBillingStepProps) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();
  const [ searching, setSearching ] = useState(false);
  const details = useSelector(selectDirectBillingQeEntry);

  const form = useFormik<Partial<QeParticipant>>({
    initialValues: {
      ...defaultParticipant,
      ...details.participant,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(setParticipant(values as DBParticipant));
      dispatch(setSelectedParticipant(values as Participant));
      history.push(`/direct-bill-event/event-info`);
    },
  });
  const setParticipantDetails = setParticipantValues(form.setFieldValue);

  const handleSearchStarted = compose(setSearching, T);

  function handleParticipant(p: Participant) {
    dispatch(resetEntry());
    setParticipantDetails(p);
    setSearching(false);
  }

  function handleCustomFieldChange(field: string) {
    return (value: string) => form.setFieldValue(field, value);
  }

  return (
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    <form data-testid="directBillingPersonalDetails">
      <div className="flex flex-col space-y-4">
        <p>
            Enter the demographic information for the Retiree/LOA participant below.
        </p>
        <div className="flex flex-row pr-4">
          <PayrollIdParticipantLookupInput
            className="w-1/2"
            data-testid="participantLookup"
            onSearchStarted={handleSearchStarted}
            onParticipantFound={handleParticipant}
            name="payrollid"
            value={form.values.payrollid ?? ''}
            error={form.touched.payrollid && !!form.errors.payrollid}
            helperText={form.touched.payrollid && form.errors.payrollid}
            onTextChanged={handleCustomFieldChange('payrollid')}
          />
        </div>
        <ParticipantDetails form={form as FormikProps<Partial<QeParticipant>>} />
      </div>
      <div className="flex justify-end my-4">
        <Button
          color="primary"
          data-testid="cancelDirectBilling"
          onClick={onCancel}
        >
            Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={form.submitForm}
          data-testid="directBillingNextStep"
          className="ml-4"
          disabled={searching || (!!Object.values(form.touched).length && !!Object.values(form.errors).length)}
        >
            Next Step
        </Button>
      </div>
    </form>
    /* eslint-enable @typescript-eslint/no-unnecessary-condition */
  );
}

export default DirectBillingPersonalDetails;
