import dayjs from 'dayjs';
import {
  CancelDirectBillingPayload,
  DBBenefit,
  DirectBillingEventType,
  DirectBillingQe,
  DirectBillSavePayload,
} from 'direct-billing/direct-billing.types';
import request from '../api-request';

export function getDirectBillEventTypes(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load direct billing event types for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/direct-bill/event-types`;

  return request<DirectBillingEventType[]>(uri);
}

export function saveDirectBillingEvent(companyId: number, body: DirectBillSavePayload) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot save Retiree/LOA billing event for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/direct-billing-events`;

  return request(uri, { method: 'POST', body });
}

export function getAvailableBenefits(companyId: number, effectiveDate: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get available benefits for invalid company.'));
  }

  const date = dayjs(effectiveDate);

  if (!date.isValid()) {
    return Promise.reject(new Error(`Cannot get available benefits for invalid effective date: ${effectiveDate}`));
  }

  const uri = `/api/companies/${companyId}/direct-bill/plans?effectiveDate=${date.format('YYYY-MM-DD')}`;

  return request<DBBenefit[]>(uri);
}

export function updateDirectBillingEvent(companyId: number, body: DirectBillSavePayload) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot update Retiree/LOA billing event for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/direct-billing-events`;

  return request<DirectBillSavePayload>(uri, { method: 'PUT', body });
}

export function cancelDirectBillingEvent(companyId: number, body: CancelDirectBillingPayload) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot cancel Retiree/LOA billing event for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/direct-billing-events/cancel`;

  return request(uri, { method: 'DELETE', body });
}

export function getDirectBillingEvent(companyId: number, rQualId: number, employeeNumber: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load Retiree/LOA billing event for invalid company.'));
  }

  if (rQualId <= 0) {
    return Promise.reject(new Error('Cannot load Retiree/LOA billing event for invalid event id.'));
  }

  if (!/^\d{9}$/.test(employeeNumber)) {
    return Promise.reject(
      new Error(`Cannot load Retiree/LOA billing event for invalid employee id ${employeeNumber}.`),
    );
  }

  const uri = `/api/companies/${companyId}/direct-billing-events/${rQualId}/employees/${employeeNumber}`;

  return request<DirectBillingQe>(uri);
}
