import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { CobraQePlusPlans } from 'enter-cobra-qe/cobra-qe.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { getCobraQe } from 'shared/api/cobraQeApi';
import store from 'store';

export function useCobraQe(employeeNumber: string, cQualId: number) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ entry, setEntry ] = useState<CobraQePlusPlans | undefined | null>(null);

  const setDefault = compose(setEntry, always(undefined));
  const handleError = compose(
    tap(setDefault),
    dispatch,
    addHttpErrorNotification,
  );
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    getCobraQe(compid, employeeNumber, cQualId)
      .then((result) => {
        if (mountedRef.current) {
          setEntry(result);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ employeeNumber, cQualId, compid ]);

  return entry;
}
