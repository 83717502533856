import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/filehistory', (schema) => {
    const { models: data } = schema.fhMonthYears.all();
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/filehistory/:year/:month', (schema, request) => {
    const { models: data } = schema.fhWebFiles.where({
      year: parseInt(request.params.year),
      month: parseInt(request.params.month) }) ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });
  
  server.get('/companies/:companyId/filehistory/download/:fileid/:filename', (schema, request) => {
    const { models: data } = schema.fhWebFiles.where({
      fileid: request.params.fileid,
      filename: request.params.filename }) ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });
}

export default routing;