import { ReactElement } from 'react';
import Button from '@mui/material/Button';
import { useFormik, FormikProps } from 'formik';
import { compose, pipe, tap, T } from 'ramda';

import { Participant } from 'participant-search/participant-list.types';
import { validationSchema } from './general-info.validators';
import PayFreqSelect, { PayFreqSelectProps } from 'components/shared/PayFreqSelect';
import ParticipantDetails from 'components/shared/ParticipantDetails';
import { OnSubmit } from './participant-overview.types';
import { noop } from 'shared/utils';
import { ChangeNotifier } from 'shared/types/form.types';
import { defaultParticipant } from 'shared/constants';
import { usePayFrequencies } from 'shared/custom-hooks/usePayFrequencies';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';

interface GeneralInfoProps extends ChangeNotifier {
  details?: Participant;
  onSubmit: OnSubmit<Participant>;
}

function GeneralInfo({ details, onSubmit, onHasChanged = noop }: GeneralInfoProps): ReactElement {
  const payFreqs = usePayFrequencies();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...defaultParticipant,
      ...details,
    },
    validationSchema: validationSchema(payFreqs !== null && payFreqs.length > 0),
    onSubmit: (values) => {
      onSubmit(values as Participant);
      onHasChanged(false);
    },
  });

  function handleCustomFieldChange(field: string) {
    return (value: string) => form.setFieldValue(field, value);
  }

  const PayFrequencies = withLoopIcon<PayFreqSelectProps>(
    PayFreqSelect,
    'Loading Pay Frequencies...',
    payFreqs === null,
    'pay-frequencies-loading',
  );

  return (
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    <form data-testid="generalInfo" className="mt-8">
      <div className="flex flex-col space-y-8">
        <ParticipantDetails
          form={form as FormikProps<Partial<Participant>>}
          onHasChanged={onHasChanged}
        />
        <div className="flex flex-row space-x-8">
          <div data-testid="payfreq" className="w-1/5">
            <PayFrequencies
              isRequired
              payFreqs={payFreqs}
              name="payfreq"
              label="Pay Frequency"
              value={form.values.payfreq ?? ''}
              error={form.touched.payfreq && !!form.errors.payfreq}
              onPayFreqChanged={pipe(tap(compose(onHasChanged, T)), handleCustomFieldChange('payfreq'))}
            />
          </div>

          <div data-testid="payfreq2" className="w-1/5">
            <PayFrequencies
              isRequired={false}
              payFreqs={payFreqs}
              name="payfreq2"
              label="Pay Frequency 2 (Optional)"
              value={form.values.payfreq2 ?? ''}
              error={form.touched.payfreq2 && !!form.errors.payfreq2}
              onPayFreqChanged={pipe(tap(compose(onHasChanged, T)), handleCustomFieldChange('payfreq2'))}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end my-4">
        <Button
          color="primary"
          data-testid="cancelGeneralInfo"
          onClick={form.handleReset}
        >
            Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={form.submitForm}
          data-testid="saveGeneralInfo"
          className="ml-4"
          disabled={!!Object.values(form.touched).length && !!Object.values(form.errors).length}
        >
            Save Participant
        </Button>
      </div>
    </form>
    /* eslint-enable @typescript-eslint/no-unnecessary-condition */
  );
}

export default GeneralInfo;
