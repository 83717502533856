export const defaultParticipant = {
  payrollid: '',
  empno: '',
  socsec: '',
  birthdate: '',
  lname: '',
  fname: '',
  sex: '',
  hphone: '',
  hstreet1: '',
  hstreet2: '',
  hcity: '',
  hstate: '',
  hzip: '',
  location: null,
  payfreq: '',
  payfreq2: '',
  email: '',
  dependents: [],
};
Object.freeze(defaultParticipant);

export const defaultContact = {
  conlname: '',
  confname: '',
  conphone: '',
  confax: '',
  contitle: '',
  condept: '',
  conaddr1: '',
  conaddr2: '',
  concity: '',
  constate: '',
  conzip: '',
  conemail: '',
};
Object.freeze(defaultContact);
