import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MonthAndYear } from 'shared/types/file-report-history.types';
import { ReportHistory } from 'report-history/report-history.types';
import { RootState } from 'store/rootReducer';
import { getReportHistories, getReportHistoryMonths } from 'shared/api/reportHistoryApi';
import { AppThunk } from 'store';
import { assertIsDefined } from 'shared/asserts';
import { addHttpErrorNotification } from 'notifications/notifications.slice';

export interface ReportHistoryState {
  monthYears: MonthAndYear[] | null;
  reports: ReportHistory[] | null;
  selectedMonthYear: MonthAndYear | null;
  selectedReportType: string | null;
}

export const initialState: ReportHistoryState = {
  monthYears: [],
  reports: [],
  selectedMonthYear: null,
  selectedReportType: null,
};

const reportHistorySlice = createSlice({
  name: 'reportHistory',
  initialState,
  reducers: {
    setReportMonthYears(state, { payload }: PayloadAction<MonthAndYear[] | null>) {
      state.monthYears = payload;
    },
    setReports(state, { payload }: PayloadAction<ReportHistory[] | null>) {
      state.reports = payload;
    },
    setSelectedMonthYear(state, { payload }: PayloadAction<MonthAndYear | null>) {
      state.selectedMonthYear = payload;
    },
    setSelectedReportType(state, { payload }: PayloadAction<string | null>) {
      state.selectedReportType = payload;
    },
  },
});

export const {
  setReportMonthYears,
  setReports,
  setSelectedMonthYear,
  setSelectedReportType,
} = reportHistorySlice.actions;

export default reportHistorySlice.reducer;

// thunk
export function loadReportMonthYears(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setReportMonthYears(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const monthYears = await getReportHistoryMonths(selectedCompany.compid);
      dispatch(setReportMonthYears(monthYears));
      dispatch(setSelectedMonthYear(monthYears?.length ? monthYears[0] : null));
    } catch (ex) {
      dispatch(setReportMonthYears([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadReports(year: number, month: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setReports(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const reports = await getReportHistories(selectedCompany.compid, year, month);
      dispatch(setReports(reports));
    } catch (ex) {
      dispatch(setReports([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectReportMonthYears = (state: RootState): MonthAndYear[] | null => state.reportHistory.monthYears;

export const selectReports = (state: RootState): ReportHistory[] | null => state.reportHistory.reports;

export const selectSelectedMonthYear = (state: RootState): MonthAndYear | null => state.reportHistory.selectedMonthYear;

export const selectSelectedReportType = (state: RootState): string | null => state.reportHistory.selectedReportType;
