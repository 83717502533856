import React, { useEffect, useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import dayjs, { Dayjs } from 'dayjs';
import ParticipantCountSummary from './ParticipantCountSummary';
import NonPlanYearServiceSummary from './NonPlanYearServiceSummary';
import FmlaSummary from './FmlaSummary';
import { NonPlanYearServicesResponse } from 'reports-tile/reports-tile.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { getNonPlanYearServicesSummary } from 'shared/api/nonPlanYearServicesApi';
import { useSelector } from 'react-redux';

function NonPlanYearServices(): React.ReactElement {
  const [ loading, setLoading ] = useState(false);
  const [ nypsStart, setNypsStart ] = useState<Dayjs | null>(null);
  const [ nypsEnd, setNypsEnd ] = useState<Dayjs | null>(null);
  const [ areChildrenLoading, setAreChildrenLoading ] = useState(true);
  const [ data, setData ] = useState<NonPlanYearServicesResponse | null>(null);

  const mountedRef = useRef<boolean>();
  const { compid, hasFmla } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const handleError = useErrorNotifier();

  function onChildrenLoaded () {
    setAreChildrenLoading(false);
  }

  useEffect(() => {
    // calculate these once on mount
    // default selection should be previous month, range should be prior 12 months
    setNypsStart(dayjs().startOf('month').subtract(1, 'month'));
    setNypsEnd(dayjs().startOf('month').subtract(13, 'month'));
  }, []);

  useEffect(() => {
    mountedRef.current = true;

    async function getServices(): Promise<void> {
      try {
        if (!nypsStart) {
          return;
        }
        setLoading(true);
        const start = nypsStart.startOf('month');
        const end = nypsStart.endOf('month');

        const response = await getNonPlanYearServicesSummary(compid, start, end);

        if (mountedRef.current) {
          setData(response);
        }
      } catch (ex) {
        if (mountedRef.current) {
          handleError(ex as Error);
        }
      } finally {
        if (mountedRef.current) {
          setLoading(false);
          onChildrenLoaded();
        }
      }
    }

    if (!!compid && !!nypsStart) {
      getServices();
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ compid, nypsStart, handleError ]);

  function cobraSummary(): React.ReactElement {
    if (data?.services.hasCobra) {
      return (
        <NonPlanYearServiceSummary
          className="w-1/2"
          startDate={nypsStart?.format('YYYY-MM-DD') ?? ''}
          endDate={nypsEnd?.format('YYYY-MM-DD') ?? ''}
          title="COBRA"
          apiUri={`/api/companies/${compid}/reports/cobra-summary`}
          exportType="cobra"
          onLoaded={onChildrenLoaded}
          data={data.summaries.cobra}
        />
      );
    }

    return <></>;
  }

  function cobraCounts(): React.ReactElement {
    if (data?.services.hasCobra) {
      return (
        <div className="flex flex-col justify-start w-1/2" data-testid="cobraCounts">
          <ParticipantCountSummary
            className="mb-4"
            title="Pending COBRA QE Notices"
            loading={loading}
            participants={data.notices.qeParticipants}
            detailsPath="/pending-qe-notices"
          />

          <ParticipantCountSummary
            title="Pending COBRA Initial Notices"
            participants={data.notices.initialNotices}
            loading={loading}
            detailsPath="/pending-initial-notices"
          />
        </div>
      );
    }

    return <></>;
  }

  function retireeSummary(): React.ReactElement {
    if (data?.services.hasRetiree) {
      return (
        <NonPlanYearServiceSummary
          className="w-1/2"
          startDate={nypsStart?.format('YYYY-MM-DD') ?? ''}
          endDate={nypsEnd?.format('YYYY-MM-DD') ?? ''}
          title="Retiree Billing"
          apiUri={`/api/companies/${compid}/reports/retiree-summary`}
          exportType="retiree"
          onLoaded={onChildrenLoaded}
          data={data.summaries.retiree}
        />
      );
    }

    return <></>;
  }

  function loaSummary(): React.ReactElement {
    if (data?.services.hasLOA) {
      return (
        <NonPlanYearServiceSummary
          className="w-1/2"
          startDate={nypsStart?.format('YYYY-MM-DD') ?? ''}
          endDate={nypsEnd?.format('YYYY-MM-DD') ?? ''}
          title="LOA Billing"
          apiUri={`/api/companies/${compid}/reports/loa-summary`}
          exportType="loa"
          onLoaded={onChildrenLoaded}
          data={data.summaries.loa}
        />
      );
    }

    return <></>;
  }

  function fmlaSummary(): React.ReactElement {
    if (hasFmla) {
      return (
        <FmlaSummary />
      );
    }

    return <></>;
  }

  return (
    <>
      {
        // When one child invokes onChildrenLoaded() it removes this spinner
        areChildrenLoading
          ? (
            <>
              <Skeleton variant="rectangular" height={570} className="basis-full" data-testid="nonPlanYearSkeleton" />
            </>
          )
          : <></>
      }
      <div className="flex flex-wrap space-y-4">
        <div className="flex w-full space-x-4">
          {cobraSummary()}
          {cobraCounts()}
        </div>

        <div className="flex w-full space-x-4">
          {retireeSummary()}
          {loaSummary()}
        </div>

        <div className="flex w-full space-x-4">
          {fmlaSummary()}
          <div className="w-1/2" />
        </div>
      </div>
    </>
  );
}

export default NonPlanYearServices;
