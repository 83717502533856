export enum NotificationType {
  ERROR='error',
  WARNING='warning',
  INFO='info',
  SUCCESS='success',
}

export interface Note {
  area: 'notifications' | 'snackbar' | 'both';
  type: NotificationType;
  message: string;
  key: string;
}

export const MAX_NOTIFICATION_LIST_SIZE = 20;

export const NOTIFICATION_TIMEOUT = 5000; // milliseconds
