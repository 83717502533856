import React from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import IconButton from '@mui/material/IconButton';
import { downloadFmlaExcel } from 'shared/api/fmlaReportsApi';
import { FmlaCompanyIdDatesAndReportType } from 'reports/fmla/fmla-report-types';
import { useDownloading } from 'shared/custom-hooks/useDownloading';
import { isNil } from 'ramda';

interface FmlaSummaryTileProps {
  title: string;
  className?: string;
  reportData: FmlaCompanyIdDatesAndReportType;
  count: number | null;
}

function FmlaSummaryTile({
  title,
  className = '',
  reportData,
  count,
}: Readonly<FmlaSummaryTileProps>): React.ReactElement {
  const { handleExport } = useDownloading(downloadFmlaExcel, { ...reportData });

  return (
    <div className={className} data-testid={`${reportData.reportType}-tile`}>
      <div className="flex justify-end mb-4">
        <IconButton
          size="small"
          type="button"
          data-testid={`${reportData.reportType}-export-btn`}
          onClick={handleExport}
          disabled={isNil(count)}
        >
          <SaveAltIcon fontSize="small" />
        </IconButton>
      </div>
      <p className="text-high-em">{title}</p>
      <h4 className="text-high-em">{count}</h4>
    </div>
  );
}

export default FmlaSummaryTile;
