import React from 'react';
import { Document as _Document, Page as _Page, Text as _Text, StyleSheet as _StyleSheet } from '@react-pdf/renderer';
import {
  PdfTable as _PdfTable,
  PdfTableHeader as _PdfTableHeader,
  PdfTableCell as _PdfTableCell,
  PdfTableBody as _PdfTableBody,
  PdfDataTableCell as _PdfDataTableCell,
} from 'shared/pdf-data-table';
import { EnrollmentTransaction } from '../enrollment.types';
import dayjs from 'dayjs';
import { currencyFormatter } from 'shared/utils';

interface Props {
  cells: EnrollmentTransaction[];
}

const Document = _Document as any;
const Page = _Page as any;
const Text = _Text as any;
const StyleSheet = _StyleSheet as any;
const PdfTable = _PdfTable as any;
const PdfTableHeader = _PdfTableHeader as any;
const PdfTableCell = _PdfTableCell as any;
const PdfTableBody = _PdfTableBody as any;
const PdfDataTableCell = _PdfDataTableCell as any;

const styles = StyleSheet.create({
  title: { margin: '5% 5% 0' },
});

// Create Document Component
function MraPdf({ cells }: Props) {
  return (
    <Document>
      <Page>
        <Text style={styles.title}>Transactions</Text>
        <PdfTable
          data={cells}
        >
          <PdfTableHeader>
            <PdfTableCell>
              Effective
            </PdfTableCell>
            <PdfTableCell>
              Incurred
            </PdfTableCell>
            <PdfTableCell>
              Amount
            </PdfTableCell>
            <PdfTableCell>
              Ref#
            </PdfTableCell>
            <PdfTableCell>
              Type
            </PdfTableCell>
          </PdfTableHeader>
          <PdfTableBody>
            {/* eslint-disable react/jsx-no-bind */}
            <PdfDataTableCell
              getContent={
                (r: EnrollmentTransaction) => dayjs(r.effectiveDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
              }
            />
            <PdfDataTableCell
              getContent={
                (r: EnrollmentTransaction) => dayjs(r.incurredDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
              }
            />
            <PdfDataTableCell getContent={(r: EnrollmentTransaction) => currencyFormatter.format(r.amount)} />
            <PdfDataTableCell getContent={(r: EnrollmentTransaction) => r.referenceNumber} />
            <PdfDataTableCell getContent={(r: EnrollmentTransaction) => r.type} />
            {/* eslint-enable react/jsx-no-bind */}
          </PdfTableBody>
        </PdfTable>
      </Page>
    </Document>
  );
}

export default MraPdf;
