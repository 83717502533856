import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { getDocumentLibraryEducation } from 'shared/api/documentLibraryApi';
import { selectSelectedCompany } from 'companies/companies.slice';
import { EducationDocument } from '../../document-library.types';

export function useDocumentLibraryEducation() {
  const errorNotifier = useErrorNotifier();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ educationDocuments, setEducationDocuments ] = useState<EducationDocument[] | null>(null);

  const [ setDefault ] = useState(() => compose(setEducationDocuments, always(null)));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (compid > 0) {
      setEducationDocuments(null);
      getDocumentLibraryEducation(compid)
        .then((result) => {
          if (mountedRef.current) {
            setEducationDocuments(result ?? []);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ handleError, compid ]);

  return educationDocuments;
}
