import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { compose, always } from 'ramda';

import { getUser } from 'authentication/authentication';
import request, { APIOptions } from 'shared/api-request';
import PasswordInput from 'components/shared/PasswordInput';
import { addHttpErrorNotification, addHttpSuccessNotification } from 'notifications/notifications.slice';
import PageContentPaper from 'components/layout/PageContentPaper';
import { validationSchema } from './change-password.validators';
import store from 'store';

function ChangePassword(): React.ReactElement {
  const user = useSelector(getUser);
  const dispatch = useDispatch<typeof store.dispatch>();
  const form = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit,
  });
  let changeSuccess = compose(dispatch, addHttpSuccessNotification, always('Password Changed'));
  let changeFailed = compose(dispatch, addHttpErrorNotification);

  function onSubmit(values: any) {
    const cpOptions: APIOptions = {
      method: 'PUT',
      body: {
        UserName: user?.username,
        OldPassword: values.currentPassword,
        NewPassword: values.newPassword,
      },
    };
    request<boolean>(`/api/users/${user?.conuserid}/change-password`, cpOptions)
      .then(changeSuccess)
      .catch(changeFailed);
  }

  return (
    <form data-testid="cp-form" onSubmit={form.handleSubmit} >
      <PageContentPaper>
        <Typography variant="h4" className="mb-12">Change Password</Typography>
        <Typography className="flex mb-12" variant="body1">Set a unique password to protect your account.</Typography>

        <PasswordInput
          className="flex mb-12 mr-12"
          label="Enter your password"
          name="currentPassword"
          data-testid="currentPassword"
          variant="outlined"
          error={form.touched.currentPassword && !!form.errors.currentPassword}
          onPasswordChanged={form.handleChange('currentPassword')}
          size="medium"
          helperText={form.touched.currentPassword && form.errors.currentPassword}
        />

        <PasswordInput
          className="flex mb-12 mr-12"
          label="New password"
          name="newPassword"
          data-testid="newPassword"
          variant="outlined"
          error={form.touched.newPassword && !!form.errors.newPassword}
          onPasswordChanged={form.handleChange('newPassword')}
          size="medium"
          helperText={form.touched.newPassword && form.errors.newPassword}
        />

        <PasswordInput
          className="flex mb-12 mr-12"
          label="Confirm New password"
          name="confirmNewPassword"
          data-testid="confirmNewPassword"
          variant="outlined"
          error={form.touched.confirmNewPassword && !!form.errors.confirmNewPassword}
          onPasswordChanged={form.handleChange('confirmNewPassword')}
          size="medium"
          helperText={form.touched.confirmNewPassword && form.errors.confirmNewPassword}
        />

        <Divider />
        <div className="flex flex-row m-4">
          <div className="flex flex-grow flex-col m-4" />
          <div className="flex flex-col m-4">
            <div className={'flex flex-row justify-items-stretch m-5'} >
              <Button
                data-testid="cp-button"
                variant="contained"
                color="primary"
                type="submit"
              >
                Change Password
              </Button>
            </div>
          </div>
        </div>
      </PageContentPaper>
    </form>
  );
}

export default ChangePassword;
