import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import { compose, T } from 'ramda';

import PayrollIdParticipantLookupInput from 'components/shared/PayrollIdParticipantLookupInput';
import { Participant } from 'participant-search/participant-list.types';
import { TerminateDetails } from './terminate-participant.types';
import { handleDateChange } from 'shared/form-helpers';
import FormErrorText from 'components/shared/FormErrorText';
import LastDeductionSelect from './LastDeductionSelect';
import { validationSchema } from './terminate-participant.validators';
import { usePayDates } from 'shared/custom-hooks/usePayDates';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

export interface TerminateParticipantFormProps {
  onCancel: () => void;
  onAdd: (details: TerminateDetails) => void;
}

interface TerminateValues extends Partial<Omit<TerminateDetails, 'participant'>> {
  employeeNumber?: string;
  numDeductionDates: number;
}

function TerminateParticipantForm({ onCancel, onAdd }: TerminateParticipantFormProps) {
  const [ searching, setSearching ] = useState(false);
  const [ participant, setParticipant ] = useState<Participant | undefined | null>();
  const payDates = usePayDates(participant ? participant.payfreq || participant.payfreq2 || '' : '');
  const form = useFormik<TerminateValues>({
    initialValues: {
      employeeNumber: '',
      terminateBenefits: undefined,
      lastDeduction: undefined,
      numDeductionDates: payDates ? payDates.length : 0,
    },
    validationSchema,
    onSubmit(values: TerminateValues) {
      form.resetForm();
      onAdd({
        participant: participant!,
        terminateBenefits: values.terminateBenefits!,
        lastDeduction: values.lastDeduction,
      });
    },
  });
  const handleSearchStarted = compose(setSearching, T);

  useEffect(() => {
    form.setFieldValue('numDeductionDates', payDates ? payDates.length : 0);
  }, [ payDates ]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleParticipant(p: Participant) {
    setParticipant(!!p.empno ? p : null);
    form.setFieldValue('lastDeduction', '');
    setSearching(false);
  }

  function dateToValue(value: string | Dayjs) {
    return dayjs(value, 'YYYY-MM-DD').format('MM/DD/YYYY');
  }

  function handleCustomFieldChange(field: string) {
    return (value: string) => {
      form.setFieldValue(field, value);
    };
  }

  function lastDeductionSelect() {
    if (participant === undefined) {
      return <p>Please search for a participant.</p>;
    }

    if (participant === null) {
      return <FormErrorText message="No matching participant was found. Please try again." show className="w-full" />;
    }

    const payFreq = participant.payfreq || participant.payfreq2 || '';

    return (
      <LastDeductionSelect
        payDates={payFreq ? payDates : []}
        value={form.values.lastDeduction ?? ''}
        error={!!form.touched.lastDeduction && !!form.errors.lastDeduction}
        classes={ {
          control: 'w-full last-deduction-select',
        } }
        onDateChange={handleCustomFieldChange('lastDeduction')}
      />
    );
  }

  return (
    <>
      <form className="terminate-participant-form">
        <p className="w-full my-8">
          Enter the date all benefits should terminate and the last deduction date for the employee.
        </p>
        <div className="flex flex-col space-y-8">
          <div className="w-full">
            <PayrollIdParticipantLookupInput
              className="w-full"
              onSearchStarted={handleSearchStarted}
              onParticipantFound={handleParticipant}
              name="employeeNumber"
              value={form.values.employeeNumber ?? ''}
              error={!!form.touched.employeeNumber && !!form.errors.employeeNumber}
              helperText={form.touched.employeeNumber && form.errors.employeeNumber}
              onTextChanged={handleCustomFieldChange('employeeNumber')}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <DatePicker
              label="Date Benefits Terminate"
              inputFormat="MM/DD/YYYY"
              value={form.values.terminateBenefits ? dateToValue(form.values.terminateBenefits) : null}
              onChange={handleDateChange('terminateBenefits', form)}
              InputProps={
                { className: !form.values.terminateBenefits ? 'MuiFormLabel-root' : '' }
              }
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  data-testid="date-benefits-terminate"
                  variant="outlined"
                  className="w-full"
                  {...params}
                  error={form.touched.terminateBenefits && Boolean(form.errors.terminateBenefits)}
                  helperText={form.touched.terminateBenefits && form.errors.terminateBenefits}
                />
              )}
            />
          </LocalizationProvider>

          {lastDeductionSelect()}
        </div>
        <div className="flex justify-end space-x-12 my-8">
          <Button
            color="primary"
            className="terminate-participant-cancel"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={form.submitForm}
            className="terminate-participant-add"
            disabled={searching || !participant || payDates === null || !form.isValid}
          >
            Add Participant
          </Button>
        </div>
      </form>
    </>
  );
}

export default TerminateParticipantForm;
