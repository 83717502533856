import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PageContentPaper from 'components/layout/PageContentPaper';
import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import { selectParticipant } from 'participant-search/participant.slice';
import { selectSelectedCompany } from 'companies/companies.slice';
import {
  loadDirectBillingEvent,
  resetEntry,
  selectDirectBillingQeEntry,
} from 'direct-billing/direct-billing.slice';
import UpdateDirectBillingForm from 'update-direct-bill-event/UpdateDirectBillingForm';
import store from 'store';

function UpdateDirectBilling() {
  usePageTitle('Update Retiree/LOA Billing');
  const history = useHistory();
  const { empno } = useSelector(selectParticipant) ?? { empno: '' };
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const entry = useSelector(selectDirectBillingQeEntry);
  const { rQualId } = useParams<{rQualId:string;}>();
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    dispatch(loadDirectBillingEvent(compid, parseInt(rQualId), empno));
  }, [ dispatch, compid, empno, rQualId ]);

  function handleClose() {
    dispatch(resetEntry());
    history.goBack();
  }

  function content() {
    return (
      <PageContentPaper className="mt-4">
        {
          !!entry.r_qualid
            ? <UpdateDirectBillingForm onCancel={handleClose} entry={entry} />
            : <p>Loading retiree/LOA details...</p>
        }
      </PageContentPaper>
    );
  }

  return content();
}

export default UpdateDirectBilling;
