import { isEmpty } from 'ramda';

import request from 'shared/api-request';
import { CRLType } from 'shared/types/non-plan-year.types';

export function printQualifyingEvent(companyId: number, type: CRLType, unqid: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot print qualifying event for invalid company.'));
  }

  if (unqid <= 0) {
    return Promise.reject(new Error('Cannot print qualifying event for invalid ID.'));
  }

  if (isEmpty(type)) {
    return Promise.reject(new Error('Cannot print qualifying event for invalid CRL Type.'));
  }

  return request<number>(
    `/api/companies/${companyId}/qualifying-events/${type !== 'cobra' ? 'r' : 'c'}/print/${unqid}`,
  );
}

