import { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRighIcon from '@mui/icons-material/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import {
  compose, always, cond, equals, when, gt, inc, lt, length,
  dec, isEmpty, anyPass, isNil, both, complement, ifElse, andThen, any, either, path, lte, F, T, and,
} from 'ramda';
import {
  planRateWizardStepIndexChanged,
  selectPlanRateWizardStepIndex,
  selectCobraPlanOnDeckValidations,
  selectLevelCoverageTiersOnDeck,
  selectOnDeckPlanRatesDocuments,
  resetCobraPlanOnDeckNewPlanLevels,
  resetCobraPlanOnDeckRatesDocuments,
  selectCobraPlan,
  selectCobraPlanOnDeck,
  setCobraPlanOnDeckPlanLevelEffectiveFrom,
  setCobraPlanOnDeckPlanLevelEffectiveTo,
  selectCobraPlanLevelsAllPositive,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import store from 'store';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { saveCobraPlan } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import { YesNoChar } from 'shared/enums/generic.enum';

interface Props {
  onClose: () => void;
}

function AddRateWizardContainer({ onClose } : Props) {
  const activeStep = useSelector(selectPlanRateWizardStepIndex);
  const dispatch = useDispatch<typeof store.dispatch>();
  const {
    carrier,
    planTypeId,
    hasLevels,
    planInProgressId,
    planId,
  } = useSelector(selectCobraPlanOnDeck);
  const planLevelsValid = useSelector(selectCobraPlanLevelsAllPositive);
  const isAddNewPlan = and(isNil(planInProgressId), isNil(planId));
  const validations = useSelector(selectCobraPlanOnDeckValidations);
  const { checklistComplete, planYearEnd, planYearStart } = useSelector(selectCobraPlan);
  const setEffectiveDates = compose(
    compose(dispatch, setCobraPlanOnDeckPlanLevelEffectiveTo, always(planYearEnd)),
    compose(dispatch, setCobraPlanOnDeckPlanLevelEffectiveFrom, always(planYearStart)),
  );

  const notLastStep = gt(2);
  const notFirstStep = lt(0);

  const prevHandler = compose(
    when(
      notFirstStep,
      compose(
        dispatch,
        planRateWizardStepIndexChanged,
        dec,
      ),
    ),
    always(activeStep),
  );

  const handleSaveCobraPlan = compose(
    andThen(compose(
      onClose,
    )),
    dispatch,
    saveCobraPlan,
    setEffectiveDates,
    ifElse(
      equals(YesNoChar.NO),
      compose(dispatch, resetCobraPlanOnDeckNewPlanLevels),
      compose(dispatch, resetCobraPlanOnDeckRatesDocuments),
    ),
    always(hasLevels),
  );

  const nextHandler = compose(
    ifElse(
      notLastStep,
      compose(
        dispatch,
        planRateWizardStepIndexChanged,
        inc,
      ),
      handleSaveCobraPlan,
    ),
    always(activeStep),
  );

  const empty = (val: any) => () => isEmpty(val) || isNil(val);

  const isStepOne = equals(0);
  const isStepTwo = equals(1);
  const isStepThree = equals(2);

  const stepOneValidation = anyPass<number>([
    complement(empty(validations.planName)),
    complement(empty(validations.carrier)),
    complement(empty(validations.planTypeId)),
    both(
      compose(equals(8), always(planTypeId) as any),
      complement(empty(validations.planTypeDescription)),
    ),
    both(
      compose(equals('Insurer Not Listed Below'), always(carrier) as any),
      complement(empty(validations.newCarrierName)),
    ),
  ]);

  const stepTwoValidation = anyPass<number>([
    complement(empty(validations.carrierContactName)),
    complement(empty(validations.carrierContactEmail)),
    complement(empty(validations.carrierContactPhone)),
    complement(empty(validations.carrierUpdateMethodNotes)),
    complement(empty(validations.planPolicyNumber)),
  ]);

  const levels = useSelector(selectLevelCoverageTiersOnDeck);
  const files = useSelector(selectOnDeckPlanRatesDocuments);
  const hasEmptyRates = always(any(compose(either(isNil, isEmpty), path([ 'levelCost' ])))(levels));

  const stepThreeValidation = anyPass<number>([
    compose(
      ifElse(
        equals(YesNoChar.NO),
        always(lte(length(files), 0)),
        anyPass<boolean>([
          always(lte(length(levels), 0)),
          hasEmptyRates,
          always(!planLevelsValid),
          always(!!validations.confirmRates),
        ]),
      ),
      always(hasLevels),
    ),
  ]);

  const isContinueDisabled = ifElse(
    always(checklistComplete),
    ifElse(isStepThree, T, F),
    cond<number, boolean>([
      [ isStepOne, stepOneValidation ],
      [ isStepTwo, stepTwoValidation ],
      [ isStepThree, stepThreeValidation ],
    ]),
  );

  const setContinueButtonText = compose(
    ifElse(
      equals(2),
      ifElse(
        always(isAddNewPlan),
        always('Add Plan'),
        always('Update Plan'),
      ),
      always(<>Continue <ChevronRighIcon /></>),
    ),
    always(activeStep),
  );

  return (
    <>
      <div>
        <span className="ml-8">COBRA Insurers/Eligible Plan Entry</span>
        <div className="mb-8">
          <Stepper className="mt-4" activeStep={activeStep} alternativeLabel>
            {new Array(3).fill(null).map((_, i) => (
              <Step key={i}>
                <StepLabel />
              </Step>
            ))}
          </Stepper>

          <div className="p-8">
            {
              cond<number, ReactElement>([
                [ isStepOne, always(<StepOne isChecklistComplete={checklistComplete} />) ],
                [ isStepTwo, always(<StepTwo isChecklistComplete={checklistComplete} />) ],
                [ isStepThree, always(<StepThree isChecklistComplete={checklistComplete} />) ],
              ])(activeStep)
            }
          </div>

          <div className="flex flex-row-reverse mr-8">
            <Button
              variant="contained"
              onClick={nextHandler}
              disabled={isContinueDisabled(activeStep)}
              data-testid="wizard-save-button"
            >
              {setContinueButtonText()}
            </Button>

            { !isStepOne(activeStep) && (
              <Button
                data-testid="wizard-back-button"
                onClick={prevHandler}
              >
                <ChevronLeftIcon /> Back
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddRateWizardContainer;
