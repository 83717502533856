import { always, compose, cond, pathEq, T } from 'ramda';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import dayjs from 'dayjs';
import { HistoricalPlanWithRates } from 'rates-history/rates-history.types';

interface Props {
    plan: HistoricalPlanWithRates;
}

function PlanRow({ plan }: Props) {
  const hasRates = plan
    .coverageTiers
    .reduce((acc, cur) => {
      return acc + cur.tierRates.length;
    }, 0) > 0;

  const getRateAsCurrencyString = (rate: number) => {
    return rate.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const isCobra = pathEq([ 'planType' ], 'cobra', plan);

  const add2Percent = (rate: number) => {
    return rate + ((rate / 100) * 2);
  };

  return (
    <>
      {cond([
        [ always(hasRates), always(
          <>
            <TableRow data-testid="plan-row">
              <TableCell className="w-full" component="th" scope="row">
                {plan.planName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={ { paddingBottom: 0, paddingTop: 0 } } colSpan={6}>
                <Box sx={ { margin: 1 } }>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Coverage Tier</TableCell>
                        <TableCell>Year Begin</TableCell>
                        <TableCell>Year End</TableCell>
                        <TableCell>Rate</TableCell>
                        {
                          isCobra
                            ? (
                              <TableCell
                                data-testId="2-percent-rate-header"
                              >Rate + 2%
                              </TableCell>
                            )
                            : (<></>)
                        }
                      </TableRow>
                    </TableHead>
                    {plan.coverageTiers
                      .filter(c => c.tierRates.length > 0)
                      .map((coverageTier) => (
                        <TableBody data-testid={coverageTier.tierId} key={coverageTier.tierId}>
                          <TableRow>
                            <TableCell>
                              {coverageTier.tierName}
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            {isCobra
                              ? (<TableCell />)
                              : (<></>)
                            }
                          </TableRow>
                          {coverageTier.tierRates.map((rate) => (
                            <TableRow key={rate.rateId}>
                              <TableCell />
                              <TableCell>{dayjs(rate.rateBeginDate).format('MM/DD/YYYY')}</TableCell>
                              <TableCell>{dayjs(rate.rateEndDate).format('MM/DD/YYYY')}</TableCell>
                              <TableCell>{getRateAsCurrencyString(rate.rate)}</TableCell>
                              {
                                isCobra ? (
                                  <TableCell data-testid={`${rate.rateId}-rate-with-2-percent`}>{
                                    compose(
                                      getRateAsCurrencyString,
                                      add2Percent,
                                    )(rate.rate)}
                                  </TableCell>
                                )
                                  : (<></>)
                              }
                            </TableRow>
                          ))}
                        </TableBody>
                      ))}
                  </Table>
                </Box>
              </TableCell>
            </TableRow>
          </>,
        ) ],
        [ T, always(<></>) ],
      ])()}
    </>
  );
}

export default PlanRow;
