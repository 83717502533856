import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import CheckIcon from '@mui/icons-material/Check';
import SvgIcon from '@mui/material/SvgIcon';
import { useParams } from 'react-router-dom';

import { noop } from 'shared/utils';
import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import StepLabel from '@mui/material/StepLabel';

export type WizardStepStatus = 'incomplete' | 'active' | 'complete';

export interface WizardStep {
  icon: typeof SvgIcon;
  label: string;
  slug: string;
  next?: string;
  prev?: string;
}

interface WizardStepperProps {
  steps: Map<string, WizardStep>;
  onChange?: (step: WizardStep) => void;
  allowClick?: boolean;
}

function WizardStepper({
  steps,
  onChange = noop,
  allowClick = false,
}: WizardStepperProps): React.ReactElement {
  const { stepSlug } = useParams<{stepSlug: string;}>();

  usePageTitle(steps.get(stepSlug)?.label ?? '');

  function handleStepClick(step: WizardStep) {
    return () => {
      if (allowClick) {
        onChange(step);
      }
    };
  }

  function iconColors(step: WizardStep, isComplete: boolean): string {
    let classes = '';

    if (isComplete) {
      classes = 'bg-white text-primary-main border-primary-main';
    } else {
      classes = 'text-white border-transparent ';
      classes += step.slug === stepSlug ? 'bg-primary-main' : 'bg-gray-400';
    }

    return classes;
  }

  function icon(step: WizardStep, isComplete: boolean): React.ReactNode {
    const Icon = isComplete ? CheckIcon : step.icon;

    return (
      <div className={`rounded-full p-3 border-2 ${iconColors(step, isComplete)}`}>
        <Icon fontSize="large" />
      </div>
    );
  }

  function renderSteps() {
    let isComplete = true;
    const stepIterator = steps.values();
    let rendered: React.ReactElement[] = [];

    for (let step of stepIterator) {
      if (step.slug === stepSlug) {
        isComplete = false;
      }

      const canClick = allowClick && isComplete;
      rendered.push(
        <Step key={step.slug} className="py-0">
          <StepLabel
            className={`-my-11 ${canClick ? '' : 'cursor-default'}`}
            // active={step.slug === stepSlug}
            onClick={canClick ? handleStepClick(step) : noop}
            // completed={isComplete}
            icon={icon(step, isComplete)}
            data-testid={`wizStepBtn-${step.slug}`}
          >
            <p>{step.label}</p>
          </StepLabel>
        </Step>,
      );
    }

    return rendered;
  }

  return (
    <>
      <Stepper alternativeLabel className="pt-12 pb-10" connector={<StepConnector className="mx-4" />} >
        {renderSteps()}
      </Stepper>
    </>
  );
}

export default WizardStepper;
