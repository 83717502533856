import React, { ReactElement, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import dayjs from 'dayjs';

import withTransactionModal from './WithTransactionModal';
import { EnrollmentTransaction } from '../enrollment.types';
import { currencyFormatter } from 'shared/utils';
import TransactionRow from './TransactionRow';
import Pdf from './HsaPdf';
import TransactionExportButton from './TransactionExportButton';
import TransactionPaginator from './TransactionPaginator';

interface HsaTransactionsProps {
  transactions: EnrollmentTransaction[];
}

export function HsaTransactions({ transactions }: HsaTransactionsProps): ReactElement {
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);

  return (
    <>
      <div className="flex justify-end">
        <TransactionExportButton document={<Pdf cells={transactions} />} fileName="HSA Transactions" />
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Posted</TableCell>
            <TableCell>Pay Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            transactions
              .slice(page * pageSize, page * pageSize + pageSize)
              .map(
                (t, i) => (
                  <TransactionRow key={i} cells={[
                    dayjs(t.effectiveDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                    dayjs(t.incurredDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                    currencyFormatter.format(t.amount),
                    t.type,
                    t.referenceNumber ]}
                  />
                ),
              )
          }
        </TableBody>
        <TransactionPaginator
          count={transactions.length}
          page={page}
          pageSize={pageSize}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
        />
      </Table>
    </>
  );
}

export default withTransactionModal(HsaTransactions);
