import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { Dependent } from 'shared/types/non-plan-year.types';
import DependentsTableRow from './DependentsTableRow';

interface DependentsTableProps {
  initialDependents: Dependent[];
  addedDependents: Dependent[];
  onDependentDeleted?: (d: Dependent) => void;
}

function DependentsTable({
  initialDependents,
  addedDependents,
  onDependentDeleted,
}: DependentsTableProps): React.ReactElement {
  function totalDependents() {
    return initialDependents.length + addedDependents.length;
  }

  function handleDelete(dependent: Dependent) {
    if (onDependentDeleted) {
      onDependentDeleted(dependent);
    }
  }

  function header(): React.ReactElement {
    return (
      <TableRow>
        <TableCell />
        <TableCell className="text-sm">Dependent Name</TableCell>
        <TableCell className="text-sm">Dependent SSN</TableCell>
        <TableCell className="text-sm">Date of Birth</TableCell>
        <TableCell className="text-sm">Relation</TableCell>
        <TableCell className="text-sm">Gender</TableCell>
        <TableCell className="text-sm">Action</TableCell>
      </TableRow>
    );
  }

  function body(): React.ReactElement {
    if (totalDependents() < 1) {
      return (
        <TableRow>
          <TableCell colSpan={7}>
            <p className="italic">No dependents are available</p>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {initialDependents.map((d, index) => (
          <DependentsTableRow
            dependent={d}
            key={index}
          />
        ))}
        {addedDependents.map((d, index) => (
          <DependentsTableRow
            dependent={d}
            onDependentDeleted={handleDelete}
            key={index}
          />
        ))}
      </>
    );
  }

  return (
    <div className="w-full">
      <Table className="w-full">
        <TableHead>
          {header()}
        </TableHead>
        <TableBody>
          {body()}
        </TableBody>
      </Table>
    </div>
  );
}

export default DependentsTable;
