import { useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import dayjs from 'dayjs';
import { path } from 'ramda';

import { Benefit } from 'shared/types/non-plan-year.types';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface BenefitsSubsidyFieldsProps {
  index: number;
  disabled?: boolean;
}

function BenefitSubsidyFields({ index, disabled = false }: BenefitsSubsidyFieldsProps) {
  const { values, setFieldValue, handleChange, errors } = useFormikContext<{benefits: Benefit[];}>();

  function handleDateChange() {
    return (value: any) => setFieldValue(
      `benefits.${index}.subsidyEnd`, value ? dayjs(value, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
    );
  }

  return (
    <>
      <TextField
        variant="outlined"
        label="ER Subsidy"
        name={`benefits.${index}.subsidy`}
        value={values.benefits[index]?.subsidy ?? ''}
        InputProps={ {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          disabled,
        } }
        className="mt-4 mr-4"
        data-testid={`benefits.${index}.subsidy`}
        onChange={handleChange}
        error={!!path([ 'benefits', index, 'subsidy' ], errors)}
        helperText={path([ 'benefits', index, 'subsidy' ], errors)}
      />
      <LocalizationProvider dateAdapter={AdapterDayJS}>
        <DatePicker
          label="Subsidy Ends"
          value={values.benefits[index]?.subsidyEnd
            ? dayjs(values.benefits[index]?.subsidyEnd, 'YYYY-MM-DD').format('MM/DD/YYYY')
            : null}
          onChange={handleDateChange()}
          className="m-4"
          InputProps={ {
            className: !values.benefits[index]?.subsidyEnd ? 'MuiFormLabel-root' : '' ,
            disabled,
          } }
          disabled={disabled}
          // eslint-disable-next-line react/jsx-no-bind
          renderInput={(params) => (
            <TextField
              data-testid={`benefits.${index}.subsidyEnd`}
              variant="outlined"
              name={`benefits.${index}.subsidyEnd`}
              className="w-full mt-4"
              {...params}
              error={!!path([ 'benefits', index, 'subsidyEnd' ], errors)}
              helperText={path([ 'benefits', index, 'subsidyEnd' ], errors)}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default BenefitSubsidyFields;
