import { useState, useRef, useEffect } from 'react';
import { compose, tap, always } from 'ramda';
import { useSelector } from 'react-redux';

import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { selectSelectedCompany } from 'companies/companies.slice';
import { getDeductionDates } from 'shared/api/companiesApi';
import { SelectOption } from 'shared/types/form.types';
import { mapToSelectOption } from 'shared/form-helpers';

export function useDeductionDateOptions() {
  const errorNotifier = useErrorNotifier();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ deductionOptions, setDeductionOptions ] = useState<SelectOption[] | null>(null);

  const [ setDefault ] = useState(() => compose(setDeductionOptions, always([])));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    getDeductionDates(compid)
      .then((dates) => mapToSelectOption(dates ?? [], 'payFrequencies', 'payDate'))
      .then((result) => {
        if (mountedRef.current) {
          setDeductionOptions(result);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
    };
  }, [ handleError, compid ]);

  return deductionOptions;
}

