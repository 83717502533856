import React, { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { always, compose, F } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { AdminInvoice } from './administrative-billing.types';
import { currencyFormatter } from 'shared/utils';
import withModal from 'components/shared/HOC/WithModal';
import DirectDebitAccountSelect from 'components/shared/DirectDebitAccountSelect';
import { validationSchema } from './pay-invoice.validators';
import { payAdminInvoice } from 'shared/api/directDebitAccountsApi';
import { selectSelectedCompany } from 'companies/companies.slice';
import { addHttpErrorNotification, addHttpSuccessNotification } from 'notifications/notifications.slice';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import store from 'store';
import {
  selectDirectDebitAccounts,
  selectFetchDirectDebitAccountStatus,
} from 'store/slices/direct-debit/direct-debit.slice';
import { fetchDirectDebitAccount } from 'store/slices/direct-debit/direct-debit.thunk';

export interface InvoiceTableProps {
  invoice: AdminInvoice | null;
  onCancel: () => void;
}

function PayInvoice({ invoice, onCancel }: InvoiceTableProps): ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const directDebitAccounts = useSelector(selectDirectDebitAccounts);
  const directDebitFetchStatus = useSelector(selectFetchDirectDebitAccountStatus);
  useEffect(() => {
    if (directDebitFetchStatus === 'uninitialized') {
      dispatch(fetchDirectDebitAccount());
    }
  }, [ dispatch, directDebitFetchStatus ]);
  const { compid: companyId } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const [ paying, setPaying ] = useState(false);

  const handleError = compose(
    onCancel,
    dispatch,
    addHttpErrorNotification,
  );
  const handleSuccess = compose(
    onCancel,
    () => {
      if (invoice) {
        invoice.paid = invoice.amount;
        invoice.open = 0;
      }
    },
    dispatch,
    addHttpSuccessNotification,
    always('Successfully submitted payment'),
  );

  const form = useFormik({
    initialValues: {
      cddid: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setPaying(true);
      payAdminInvoice(companyId, invoice?.invoiceNumber ?? 0, parseInt(values.cddid))
        .then(handleSuccess)
        .catch(handleError)
        .finally(compose(setPaying, F));
    },
  });

  function handleChange(val: string) {
    form.setFieldValue('cddid', val);
  }

  const AccountSelectWithLoading = withLoopIcon(
    DirectDebitAccountSelect,
    'Loading accounts...',
    directDebitAccounts === null,
    'direct-debit-accounts-loading',
  );

  return (
    <div>
      <Table className="pay-invoice-table">
        <TableHead>
          <TableRow>
            <TableCell>Invoice #</TableCell>
            <TableCell>Invoice Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className="check-row">
            <TableCell className="border-0">{invoice?.invoiceNumber}</TableCell>
            <TableCell className="border-0">{dayjs(invoice?.invoiceDate).format('M/D/YYYY')}</TableCell>
            <TableCell className="border-0">{dayjs(invoice?.dueDate).format('M/D/YYYY')}</TableCell>
            <TableCell className="border-0">{currencyFormatter.format(invoice?.amount ?? 0)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <form onSubmit={form.handleSubmit} className="mt-4">
        <AccountSelectWithLoading
          isRequired
          accounts={directDebitAccounts}
          name="cddid"
          value={form.values.cddid.toString()}
          error={form.touched.cddid && !!form.errors.cddid}
          onAccountChanged={handleChange}
        />

        <div className="flex flex-row-reverse mt-4">
          <Button
            variant="contained"
            color="primary"
            className={`ml-2 ${paying && 'animate-ping'}`}
            type="submit"
            disabled={directDebitAccounts === null || !form.values.cddid || paying}
          >Pay Invoice
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={onCancel}
          >Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export const PayInvoiceModal = withModal(PayInvoice);

export default PayInvoice;
