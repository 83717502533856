import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PageContentPaper from 'components/layout/PageContentPaper';
import Loading from 'components/shared/Loading';
import {
  selectCaseDetailsStatus,
  selectEmployeeDetails,
  selectCaseDetails,
  selectEmployeeAbsencesStatus,
  selectEmployeeDetailsStatus,
} from 'leave-records/leave-records.slice';
import { fetchCaseDetails, fetchEmployeeDetails } from 'leave-records/leave-records.thunk';
import store from 'store';
import { selectSelectedCompany } from 'companies/companies.slice';
import { LeaveRecordTabPanels } from './LeaveRecordTabPanels';
import { EmployeeSearchResults, LeaveRecordTab } from '../leaveRecord.types';
import { equals, any } from 'ramda';
import { LeaveRecordsEmployeeHeader } from 'leave-records/components/LeaveRecordsEmployeeHeader';
import { LeaveRecordsPageHeader } from 'leave-records/components/LeaveRecordsPageHeader';

export function LeaveRecordDetails() {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { eeId, caseId, tab = 'case-info' }
    = useParams<{ eeId: string; caseId: string; tab?: LeaveRecordTab;}>();
  const companyId = useSelector(selectSelectedCompany)?.compid ?? 0;
  const [ activeTab, setActiveTab ] = useState<LeaveRecordTab>(tab);

  const caseDetails = useSelector(selectCaseDetails);
  const employeeDetails = useSelector(selectEmployeeDetails);
  const employeeSummary: EmployeeSearchResults = {
    firstName: employeeDetails?.firstName ?? '',
    lastName: employeeDetails?.lastName ?? '',
    eeId: employeeDetails?.eeId ?? '',
    taxIdLast4: employeeDetails?.taxIdLast4 ?? null,
  };

  const employeeDetailsStatus = useSelector(selectEmployeeDetailsStatus);
  const caseDetailsStatus = useSelector(selectCaseDetailsStatus);
  const employeeAbsencesStatus = useSelector(selectEmployeeAbsencesStatus);
  const isLoading = any(equals('pending'))([ employeeDetailsStatus, caseDetailsStatus, employeeAbsencesStatus ]);

  function handleTabChange (_: React.ChangeEvent<{}>, newTab: LeaveRecordTab) {
    setActiveTab(newTab);
    history.push(`/leave-records/${eeId}/case/${caseId}/${newTab}`);
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (employeeDetailsStatus === 'uninitialized') {
      dispatch(fetchEmployeeDetails({ companyId, eeId }));
    }

    dispatch(fetchCaseDetails({ companyId, caseId }));
  }, []);

  return (
    <div className="w-full mx-8 my-4" data-testid="leave-record-details-page">
      <LeaveRecordsPageHeader currentRoute="case" eeId={employeeSummary.eeId} />
      <LeaveRecordsEmployeeHeader employeeSummary={employeeSummary} />
      <div className="w-full flex space-x-8">
        <PageContentPaper>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            className="-ml-4 -mt-4 flex justify-around"
            value={tab}
            centered
            onChange={handleTabChange}
          >
            <Tab
              data-testid="case-info-tab"
              value="case-info"
              label="Case Information"
            />
            <Tab
              data-testid="employee-info-tab"
              value="employee-info"
              label="Employee Information"
            />
            <Tab
              data-testid="paperwork-dates-tab"
              value="paperwork-dates"
              label="Paperwork Dates"
            />
            <Tab
              data-testid="current-approvals-tab"
              value="current-approvals"
              label="Current Approvals"
            />
          </Tabs>
          <LeaveRecordTabPanels
            activeTab={activeTab}
            caseDetails={caseDetails}
            employeeDetails={employeeDetails}
          />
          {
            isLoading && (
              <Loading />
            )
          }
        </PageContentPaper>
      </div>
    </div>
  );
}
