import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { equals, ifElse, always, compose, gt } from 'ramda';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

import HsaContributionsNotForwardedCard from 'action-items/action-item-cards/HsaContributionsNotForwardedCard';
import {
  selectContributionsNotForwardedCount,
  selectContributionsNotForwardedFetchStatus,
} from './action-items.slice';
import { fetchHsaContributionsNotForwarded } from 'action-items/action-items.thunks';
import store from 'store';
import { selectSelectedCompany } from 'companies/companies.slice';

function ActionItems() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid: companyId } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const fetchStatus = useSelector(selectContributionsNotForwardedFetchStatus);
  const hsaContributionsCount = useSelector(selectContributionsNotForwardedCount);

  useEffect(() => {
    dispatch(fetchHsaContributionsNotForwarded(companyId));
  }, [ dispatch, companyId ]);

  let showCardLoading = always(<Skeleton variant="rectangular" width="100%" height={176} />);
  let showActionItems = always(
    <Paper className="p-4 action-items-container">
      <div className="flex justify-between mb-4">
        <p className="text-high-em w-1/2">Action Items</p>
      </div>
      <div className="w-full flex flex-wrap">
        <HsaContributionsNotForwardedCard count={hsaContributionsCount} />
      </div>
    </Paper>,
  );
  let showNothing = always(<></>);

  let maybeShowCard = compose(
    ifElse(
      gt(hsaContributionsCount),
      showActionItems,
      showNothing,
    ),
    always(0),
  );

  return ifElse(
    equals('pending'),
    showCardLoading,
    maybeShowCard,
  )(fetchStatus);
}

export default ActionItems;
