import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageContentPaper from 'components/layout/PageContentPaper';
import { selectSelectedCompany } from 'companies/companies.slice';
import { createAndSubmitForm } from 'shared/utils';

function OpenEnrollment(): ReactElement {
  const history = useHistory();
  const { medplancd = '' } = useSelector(selectSelectedCompany) ?? {};

  useEffect(() => {
    if (medplancd) {
      createAndSubmitForm(document.createElement('form'), medplancd)
        .then(() => {
          history.goBack();
        });
    }
  }, [ history, medplancd ]);

  return (
    <>
      <PageContentPaper className="m-4">
        <p>Redirecting to open enrollment checklist...</p>
      </PageContentPaper>
    </>
  );
}

export default OpenEnrollment;
