import React from 'react';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import styles from './AppToolbar.module.css';
import UserMenu from './UserMenu';
import ParticipantSearch from 'participant-search/ParticipantSearch';
import FloresHeaderLogo from './FloresHeaderLogo';

interface AppToolbarProps {
  onToggleDrawer?: () => void;
  showSearch: boolean;
}

function AppToolbar({ onToggleDrawer, showSearch }: AppToolbarProps): React.ReactElement {
  return (
    <Toolbar classes={ { regular: styles.toolbar } }>
      {
        onToggleDrawer
          ? (
            <Hidden lgUp>
              <IconButton
                aria-label="open navigation"
                onClick={onToggleDrawer}
                className="mr-1"
                data-testid="menuButton"
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )
          : <FloresHeaderLogo />
      }
      {
        showSearch
          ? <ParticipantSearch />
          : <></>
      }
      <span className="flex-grow" />
      <UserMenu />
    </Toolbar>
  );
}

export default AppToolbar;
