import { Dependent } from 'shared/types/non-plan-year.types';
import request from 'shared/api-request';

export function getDependents(companyId: number, employeeNumber: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load dependents for invalid company.'));
  }

  if (employeeNumber.length === 0) {
    // Resolve gracefully because we legitimately may not have an employeeNumber
    return Promise.resolve([]);
  }

  return request<Dependent[]>(`/api/companies/${companyId}/participant/${employeeNumber}/dependents`);
}

export function saveDependents(companyId: number, employeeNumber: string, dependents: Dependent[]) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load dependents for invalid company.'));
  }

  if (employeeNumber.length === 0) {
    return Promise.reject(new Error('Cannot load dependents for invalid participant.'));
  }

  return request<boolean>(
    `/api/companies/${companyId}/participant/${employeeNumber}/dependents`,
    { method: 'POST', body: dependents },
  );
}
