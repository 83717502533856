import store from 'store/index';
import { resetDependents } from 'store/slices/dependents/dependents.slice';

export const dependentsReset = (appStore: typeof store) => (next: any) => async (action: any) => {
  if (action) {
    next(action);
  }

  if (
    action.type.includes('participant/setSelectedParticipant')
  ) {
    appStore.dispatch(resetDependents());
  }
};
