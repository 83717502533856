import { Dependent } from 'shared/types/non-plan-year.types';

interface DependentsSummaryProps {
  dependents: Dependent[];
}

function DependentsSummary({ dependents }: DependentsSummaryProps) {
  return (
    <div className="flex flex-col space-y-2" data-testid="dependentsList">
      {
        dependents
          .map((dep, idx) => <p className="text-black text-lg font-medium" key={idx}>{dep.name}</p>)
      }
    </div>
  );
}

export default DependentsSummary;
