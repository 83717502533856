import React from 'react';
import Avatar from '@mui/material/Avatar';

import styles from './AgentAvatar.module.css';

interface AgentAvatarProps {
  agentName: string;
}

function AgentAvatar({ agentName }: AgentAvatarProps): React.ReactElement {
  function imgSrc(): string {
    if (agentName.length > 0) {
      return `https://www.flores247.com/AMPhotos/${agentName.replace(/\s+/g, '.')}.jpg`;
    }

    return '';
  }

  return (
    <Avatar
      className={`${styles.agentAvatar} rounded-full border-white border-solid border-8`}
      alt={agentName}
      src={imgSrc()}
    />
  );
}

export default AgentAvatar;
