import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDependents } from 'shared/api/dependentsApi';
import { RootState } from 'store/rootReducer';

export let fetchDependentsForParticipant = createAsyncThunk(
  'dependents/fetchDependentsForParticipant',
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { compid: companyId } = state.companies.selectedCompany ?? { compid: 0 };
    const { empno: participantNumber } = state.participant.selected ?? { empno: '' };

    return getDependents(companyId, participantNumber);
  },
);

