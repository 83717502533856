import request from 'shared/api-request';
import { CobraInsurer } from 're-enrollment-checklist/cobra-reenrollment-checklist/cobra-re-enrollment.types';

export function getCobraInsurers(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get COBRA insurers for invalid company.'));
  }

  return request<CobraInsurer[]>(`/api/companies/${companyId}/insurers/all`)
    .then(data => data ?? []);
}

export function createCobraInsurer(insurer: Partial<CobraInsurer>) {
  if ((insurer.companyId ?? 0) <= 0) {
    return Promise.reject(new Error('Cannot create COBRA insurers for invalid company.'));
  }

  return request<CobraInsurer[]>(
    `/api/companies/${insurer.companyId}/insurers/cobra`,
    {
      method: 'POST',
      body: insurer,
    })
    .then(data => data ?? []);
}
