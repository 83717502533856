import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoopIcon from '@mui/icons-material/Loop';
import { uniq } from 'ramda';

import PageContentPaper from 'components/layout/PageContentPaper';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import HistoryMonthSelect, { HistoryMonthSelectProp } from 'components/shared/HistoryMonthSelect';
import FileHistoryTable from './FileHistoryTable';
import {
  loadMonthYears, loadWebFiles,
  selectMonthYears,
  selectSelectedMonthYear, selectSelectedReportType,
  selectWebFiles, setSelectedMonthYear, setSelectedReportType,
} from 'file-history/file-history.slice';
import { getYearAndMonth } from 'shared/utils';
import HistoryReportTypeSelect, { ReportTypeSelectProps } from 'components/shared/HistoryReportTypeFilter';
import store from 'store';

function FileHistory() {
  const monthYears = useSelector(selectMonthYears);
  const historyFiles = useSelector(selectWebFiles);
  const selectedMonthYear = useSelector(selectSelectedMonthYear);
  const selectedReportType = useSelector(selectSelectedReportType);
  const [ reportTypes, setReportTypes ] = useState<string[]>([]);

  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    const uniqueReportTypes = uniq(
      historyFiles?.filter(({ description }) => !!description)
        .map((report) => report.description?.substring(0, report.description.lastIndexOf(' ')) ?? '')
        ?? []);
    setReportTypes(uniqueReportTypes);
  }, [ historyFiles ]);

  useEffect(() => {
    dispatch(loadMonthYears());
  }, [ dispatch ]);

  useEffect(() => {
    if (selectedMonthYear) {
      dispatch(loadWebFiles(selectedMonthYear.year, selectedMonthYear.month));
    }
  }, [ dispatch, selectedMonthYear ]);

  function handleMonthChange(monthYear: string) {
    const yearAndMonth = getYearAndMonth(monthYear);
    dispatch(setSelectedMonthYear(yearAndMonth));
  }

  function handleReportTypeChange(type: string) {
    dispatch(setSelectedReportType(type || null));
  }

  function content() {
    if (historyFiles === null) {
      return (
        <div className="flex justify-center items-center flex-grow file-history-files-loading">
          <LoopIcon className="animate-spin " data-testid="reportLoadingIcon" />
        </div>
      );
    }

    return (
      <FileHistoryTable reportType={selectedReportType} webFiles={historyFiles} />
    );
  }

  const FileHistories = withLoopIcon<HistoryMonthSelectProp>(
    HistoryMonthSelect,
    'Loading File History...',
    monthYears === null,
    'file-history-months-loading',
  );

  const ReportTypes = withLoopIcon<ReportTypeSelectProps>(
    HistoryReportTypeSelect,
    'Loading Report Types...',
    historyFiles === null,
    'report-types-loading',
  );

  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">File History</h2>
      <div className="w-full md:w-2/3">
        <PageContentPaper className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div>
              <FileHistories
                monthYears={monthYears!}
                onMonthYearChanged={handleMonthChange}
                value={selectedMonthYear ? `${selectedMonthYear.year}-${selectedMonthYear.month}` : ''}
              />
            </div>
            <div>
              <ReportTypes
                reportTypes={reportTypes}
                onReportTypeChanged={handleReportTypeChange}
                value={selectedReportType}
              />
            </div>
          </div>
          <div className="flex flex-wrap xl:divide-x flex-grow" >{content()}</div>
        </PageContentPaper>
      </div>
    </div>
  );
}

export default FileHistory;
