import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';

import { getUser, setUser } from 'authentication/authentication';
import { selectSelectedCompany } from 'companies/companies.slice';
import PageContentPaper from 'components/layout/PageContentPaper';
import Loading from 'components/shared/Loading';
import StateSelect from 'components/shared/StateSelect';
import { saveContactInfo } from 'shared/api/contactInfoApi';
import { useErrorNotifier, useSuccessNotifier } from 'shared/custom-hooks/useNotifiers';
import { Contact } from './contact.types';
import { validationSchema } from './contact-info.validators';
import { loadContactInfo, selectContactInfo } from 'manage-settings/contact-info/contact-info.slice';
import store from 'store';

const defaultContact = {
  confname: '',
  conlname: '',
  conaddr1: '',
  conaddr2: '',
  concity: '',
  constate: '',
  conzip: '',
  conphone: '',
  confax: '',
  conemail: '',
} as Contact;

function ContactInfo(): React.ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const handleError = useErrorNotifier();
  const saveSuccess = useSuccessNotifier('Update successful');

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const contactInfo = useSelector(selectContactInfo);

  const form = useFormik<Partial<Contact>>({
    enableReinitialize: true,
    initialValues: {
      ...defaultContact,
      ...contactInfo,
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    const user = getUser();
    dispatch(loadContactInfo(compid, user!.conuserid));
  }, [ dispatch, compid ]);

  function handleStateChange(s: string) {
    form.setFieldValue('constate', s);
  }

  function onSubmit(values: any) {
    const user = getUser();
    saveContactInfo(compid, { ...values, compid })
      .then(() => {
        if (user) {
          user.username = contactInfo!.conemail;
          setUser(user);
        }

        saveSuccess();
      })
      .catch(handleError);
  }

  function contactInfoCreation(): React.ReactElement {
    return (!contactInfo ? <Loading text="Loading contact details..." /> : contactInfoHTML());
  }

  function contactInfoHTML(): React.ReactElement {
    return (
      <form data-testid="ci-form" onSubmit={form.handleSubmit} >
        <PageContentPaper>
          <Typography variant="h4">Contact Info</Typography>
          <div className="flex m-4">
            <TextField variant="outlined" label="First name" name="confname"
              className="m-4"
              value={form.values.confname ?? ''}
              error={form.touched.confname && !!form.errors.confname}
              helperText={form.touched.confname && form.errors.confname}
              onChange={form.handleChange}
              fullWidth
              data-testid="firstName"
            />

            <TextField variant="outlined" label="Last name" name="conlname"
              className="m-4"
              value={form.values.conlname ?? ''}
              error={form.touched.conlname && !!form.errors.conlname}
              helperText={form.touched.conlname && form.errors.conlname}
              onChange={form.handleChange}
              fullWidth
              data-testid="lastName"
            />
          </div>

          <div className="flex m-4">
            <TextField variant="outlined" label="Street Address 1" name="conaddr1"
              className="m-4"
              value={form.values.conaddr1 ?? ''}
              error={form.touched.conaddr1 && !!form.errors.conaddr1}
              helperText={form.touched.conaddr1 && form.errors.conaddr1}
              onChange={form.handleChange}
              fullWidth
              data-testid="street1"
            />

            <TextField variant="outlined" name="conaddr2" label="Street Address 2"
              className="m-4"
              value={form.values.conaddr2 ?? ''}
              error={form.touched.conaddr2 && !!form.errors.conaddr2}
              helperText={form.touched.conaddr2 && form.errors.conaddr2}
              onChange={form.handleChange}
              fullWidth
              data-testid="street2"
            />
          </div>

          <div className="flex m-4 justify-between">

            <TextField variant="outlined" name="concity" label="City"
              className="m-4"
              value={form.values.concity ?? ''}
              error={form.touched.concity && !!form.errors.concity}
              helperText={form.touched.concity && form.errors.concity}
              onChange={form.handleChange}
              fullWidth
              data-testid="city"
            />

            <div className="flex">
              <StateSelect onStateChanged={handleStateChange}
                name="constate" label="State"
                value={form.values.constate}
                variant="outlined"
                error={form.touched.constate && !!form.errors.constate}
                fullWidth
                data-testid="state"
                className="m-4"
              />

              <TextField variant="outlined" name="conzip" label="Zip"
                className="m-4"
                value={form.values.conzip ?? ''}
                error={form.touched.conzip && !!form.errors.conzip}
                helperText={form.touched.conzip && form.errors.conzip}
                onChange={form.handleChange}
                fullWidth
                data-testid="zip"
              />
            </div>
          </div>

          <div className="flex m-4">
            <TextField variant="outlined" name="conphone" label="Phone"
              className="m-4"
              value={form.values.conphone ?? ''}
              error={form.touched.conphone && !!form.errors.conphone}
              helperText={form.touched.conphone && form.errors.conphone}
              onChange={form.handleChange}
              fullWidth
              data-testid="phone"
            />

            <TextField variant="outlined" name="confax" label="Fax"
              className="m-4"
              value={form.values.confax ?? ''}
              error={form.touched.confax && !!form.errors.confax}
              helperText={form.touched.confax && form.errors.confax}
              onChange={form.handleChange}
              fullWidth
              data-testid="fax"
            />
          </div>

          <div className="flex m-4">
            <TextField variant="outlined" name="conemail" label="Email"
              className="m-4"
              value={form.values.conemail ?? ''}
              error={form.touched.conemail && !!form.errors.conemail}
              helperText={form.touched.conemail && form.errors.conemail}
              onChange={form.handleChange}
              fullWidth
              data-testid="email"
            />
          </div>
          <Divider />
          <div className="flex m-4">
            <div className="flex flex-grow flex-col m-4" />
            <div className="flex flex-col m-4">
              <div className={'flex justify-items-stretch m-5'} >

                <Button
                  color="primary"
                  onClick={form.handleReset}
                  data-testid="reset"
                  className="m-4"
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  data-testid="saveChanges"
                  className="m-4"
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </PageContentPaper>
      </form>
    );
  }

  return <div data-testid="contactInfoScreen">{contactInfoCreation()}</div>;
}

export default ContactInfo;
