import request, { APIOptions } from 'shared/api-request';
import { Contact } from 'manage-settings/contact-info/contact.types';

export function getContactInfo(companyId: number, userId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get contact info with invalid company'));
  }

  if (userId <= 0) {
    return Promise.reject(new Error('Cannot get contact info for invalid user'));
  }

  return request<Contact>(`/api/companies/${companyId}/users/${userId}/get-contact-info`);
}

export function saveContactInfo(companyId: number, info: Contact) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot save contact info with invalid company'));
  }

  const apiOptions: APIOptions = {
    method: 'PUT',
    body: { ...info, compid: companyId },
  };

  return request<boolean>(`/api/companies/${companyId}/set-contact-info`, apiOptions);
}
