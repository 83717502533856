import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import store from 'store';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import {
  selectFetchSlidesStatus,
  selectSlides,
} from 'marketing-info-tile/slideshow.slice';
import { fetchSlides } from 'marketing-info-tile/slideshow.thunks';
import EmblaCarouselSlide from './EmblaCarouselSlide';

interface EmblaCarouselProps {
  delay?: number;
}

function EmblaCarousel({ delay = 10000 }: Readonly<EmblaCarouselProps>): React.ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [ emblaRef, emblaApi ] = useEmblaCarousel(
    { loop: true },
    [
      Autoplay({
        playOnInit: true,
        delay,
        stopOnMouseEnter: true,
        stopOnInteraction: false,
      }),
    ],
  );
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const fetchStatus = useSelector(selectFetchSlidesStatus);
  const slides = useSelector(selectSlides);

  const handleClick = useCallback(() => {
    if (emblaApi) {
      setSelectedIndex(emblaApi.selectedScrollSnap());
      const { autoplay } = emblaApi.plugins();
      autoplay?.reset();
    }
  }, [ emblaApi ]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
      handleClick();
    }
  }, [ emblaApi, handleClick ]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      handleClick();
    }
  }, [ emblaApi, handleClick ]);

  const onSlideChange = useCallback(() => {
    if (emblaApi) {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    }
  }, [ emblaApi ]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSlideChange);
    }
  });

  useEffect(() => {
    dispatch(fetchSlides());
  }, [ dispatch ]);

  function renderSlideNavigation(): React.ReactElement {
    if (slides.length > 0) {
      return (
        <MobileStepper
          className="embla__controls"
          data-testid="carousel-controls"
          steps={slides.length}
          position="static"
          variant="dots"
          activeStep={selectedIndex}
          nextButton={
            <Button size="small" onClick={scrollNext} data-testid="carousel-next-btn">
              Next <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={scrollPrev} data-testid="carousel-back-btn">
              <KeyboardArrowLeft /> Back
            </Button>
          }
        />
      );
    }

    return <></>;
  }

  return fetchStatus === 'fulfilled'
    ? (
      <>
        { renderSlideNavigation() }
        <div className="embla overflow-hidden" ref={emblaRef}>
          <div className="embla__container flex">
            {
              slides.length > 0
                ? slides.map((slide, index) => {
                  return (<EmblaCarouselSlide slide={slide} key={`slide-${slide.unqid}}`} />);
                })
                : <></>
            }
          </div>
        </div>
      </>
    )
    : <Skeleton variant="rectangular" width="100%" height={350} />;
}

export default EmblaCarousel;
