import dayjs from 'dayjs';

import request, { ContentType } from '../api-request';
import { BalanceAccountCount } from 'reports/balance/balance-account.types';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';

function validateParams(
  message: string,
  companyId: number,
  planYearId: number,
  startDate: string,
  endDate: string,
) {
  if (companyId <= 0) {
    throw new Error(`Cannot ${message} for invalid company.`);
  }

  if (planYearId <= 0) {
    throw new Error(`Cannot ${message} for invalid plan year.`);
  }

  const start = startDate ? dayjs(startDate) : dayjs();
  const end = endDate ? dayjs(endDate) : dayjs();

  if (!start.isValid()) {
    throw new Error(`Cannot ${message} for invalid start date.`);
  }

  if (!end.isValid()) {
    throw new Error(`Cannot ${message} for invalid end date.`);
  }

  return [ start, end ];
}

export function getCustomBalanceReportCounts(
  companyId: number,
  planYearId: number,
  planCodeId: number,
  startDate: string,
  endDate: string,
) {
  try {
    const [ start, end ] = validateParams('load balance report data', companyId, planYearId, startDate, endDate);

    const uri = `/api/companies/${companyId}/reports/balance-account-custom-counts/${planYearId}/${planCodeId}` +
    `?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;

    return request<BalanceAccountCount>(uri);
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export function downloadCustomReimbursementRecapReport
(
  companyId: number,
  planYearId: number,
  planCode: number,
  startDate: string,
  endDate: string,
) {
  try {
    const [ start, end ] = validateParams('download report', companyId, planYearId, startDate, endDate);

    const exportUri = `/api/companies/${companyId}/reports/reimbursement-report-custom/${planYearId}/${planCode}/`
    + `export?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;

    return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export function downloadCustomBalanceReport
(
  companyId: number,
  planYearId: number,
  planCode: number,
  startDate: string,
  endDate: string,
) {
  try {
    const [ start, end ] = validateParams('download custom balance report', companyId, planYearId, startDate, endDate);

    const exportUri = `/api/companies/${companyId}/reports/balance-report-custom/${planYearId}/${planCode}/`
    + `export?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;

    return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export function getCustomReportPlans(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get custom plans for invalid company.'));
  }

  return request<OpenPlan[]>(`/api/companies/${companyId}/report-custom-plans`);
}
