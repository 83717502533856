import request from '../api-request';
import { Debit, PostDirectDebit } from 'direct-debit/direct-debit.types';

export function getDirectDebits(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get direct debits for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/direct-debit`;

  return request<Debit[]>(uri);
}

export function performDirectDebit(companyId: number, body: PostDirectDebit) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot perform direct debit for an invalid company.'));
  }

  if (body.cddid <= 0) {
    return Promise.reject(new Error('Cannot perform direct debit for an invalid cdd number.'));
  }

  const uri = `/api/companies/${companyId}/direct-debit`;

  return request(uri, { method: 'POST', body });
}
