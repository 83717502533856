import { isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';

export function useHistoryBlock(
  regex: RegExp,
  unMountCallback?: () => void,
  addedCondition = true,
) {
  const history = useHistory();
  const [ navUnblock, setNavUnblock ] = useState<UnregisterCallback | null>(null);

  useEffect(() => {
    const unblock = history.block((location) => {
      if (!regex.test(location.pathname) && addedCondition) {
        // Note: to future developers. It is claimed in the react-router documentation that you can supply your own modal
        // This is a lie. If you track through the code, the only allowed return values from this function are void, false,
        // or a string. If you return false or void, navigation is not blocked. If you return any string, even '',
        // window.confirm is used, not the modal you want. It will show your modal, but it pops up a confirm and uses that
        // to determine whether to block or allow navigation.
        return 'Changes you have made so far will not be saved. Are you sure you want to cancel?';
      }
    });

    // this callback here is a workaround to store a function as state
    setNavUnblock(() => unblock);

    return () => {
      if (!isNil(unMountCallback)) {
        unMountCallback();
      }

      unblock();
    };
  }, [ history, regex, unMountCallback, addedCondition ]);

  return navUnblock;
}
