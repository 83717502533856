import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { Link, useHistory } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Logout from 'authentication/Logout';
import UserMenuButton from './UserMenuButton';
import CompanyAvatar from 'companies/CompanyAvatar';
import {
  selectSelectedCompany,
  selectUserCompanies,
} from 'companies/companies.slice';

function UserMenu(): React.ReactElement {
  const history = useHistory();
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLElement | null>(null);
  const company = useSelector(selectSelectedCompany);
  const companies = useSelector(selectUserCompanies);

  function handleOpen(event: React.MouseEvent) {
    setAnchorEl(event.currentTarget as HTMLElement);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleViewAllCompanies() {
    history.push('/company-select');
    window.location.reload();
  }

  function openerIcon(): React.ReactElement {
    if (!!company) {
      return (
        <>
          <Typography component="p" className="text-sm normal-case">{company.compname}</Typography>
          <ArrowDropDownIcon htmlColor={'#666666'} />
          <CompanyAvatar company={company} className="w-15 h-15 ml-2" />
        </>
      );
    }

    return <Avatar className="w-15 h-15" >&nbsp;</Avatar>;
  }

  function viewAllCompanies() {
    if (companies.length > 1) {
      return (
        <MenuItem onClick={handleViewAllCompanies}>
          <Link className="mr-2" to="/company-select"><GroupIcon />View All Companies</Link>
        </MenuItem>
      );
    } else {
      return <span />;
    }
  }

  function menuItems(): React.ReactElement {
    if (!!company) {
      // note: we know that there will be more items in this menu for companies
      return (
        <span>
          <Divider />
          <div className="flex flex-col items-center">
            <CompanyAvatar company={company} className="w-20 h-20" />
            <Typography className="text-center" variant="body1" id="company-name">{company.compname}</Typography>
          </div>
          <MenuItem onClick={handleClose} className="justify-center mt-3 mb-3">
            <UserMenuButton
              name="Manage your Settings"
              page="/manage-settings"
            />
          </MenuItem>
          <Divider />
          {viewAllCompanies()}
          <Divider />
          <MenuItem onClick={handleClose}><Logout /></MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <div className="flex flex-auto flex-row items-center justify-around text-xs">
              <a href="https://www.flores-associates.com/fsaforms/privacystandards.pdf"
                target="_blank" rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </MenuItem>
        </span>
      );
    }

    return <MenuItem><Logout /></MenuItem>;
  }

  function renderMenu(): React.ReactElement {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpen}
          data-testid="userMenuButton"
          className="max-w-xs"
        >
          {openerIcon()}
        </Button>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
          transformOrigin={ { vertical: 'top', horizontal: 'center' } }
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          data-testid="userMenu"
          classes={ {
            list: `max-w-sm`,
          } }
        >
          {menuItems()}
        </Menu>
      </>
    );
  }

  return renderMenu();
}

export default UserMenu;
