import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getEmployeeById,
  getCasesSummaryByEmployeeId,
  getLeaveCaseByCaseId,
  getAbsencesByEmployeeId,
  getEmployeeSearchResultsByCriteria,
} from 'shared/api/leaveRecordsApi';

export const fetchEmployeeDetails = createAsyncThunk(
  'leaveRecords/fetchEmployeeDetails',
  getEmployeeById,
);

export const fetchCasesSummary = createAsyncThunk(
  'leaveRecords/fetchCasesSummary',
  getCasesSummaryByEmployeeId,
);

export const fetchCaseDetails = createAsyncThunk(
  'leaveRecords/fetchCaseDetails',
  getLeaveCaseByCaseId,
);

export const fetchEmployeeAbsences = createAsyncThunk(
  'leaveRecords/fetchEmployeeAbsences',
  getAbsencesByEmployeeId,
);

export const fetchEmployeeSearchResultsByCriteria = createAsyncThunk(
  'leaveRecords/fetchEmployeeSearchResultsByCriteria',
  getEmployeeSearchResultsByCriteria,
);
