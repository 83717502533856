import { ReactElement, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import dayjs from 'dayjs';

import { Debit } from './direct-debit.types';
import { currencyFormatter } from 'shared/utils';
import { DirectDebitConfirmationModal } from './DirectDebitConfirmation';
import { compose, F, isNil, T } from 'ramda';

interface DirectDebitRowProps {
  debit: Debit;
  dateFormat?: string;
}

function DirectDebitRow({
  debit,
  dateFormat = 'MM/DD/YYYY',
}: DirectDebitRowProps): ReactElement {
  const [ isOpen, setIsOpen ] = useState(false);

  function formatDate(date?: string): string {
    return date ? dayjs(date).format(dateFormat) : '';
  }

  return (
    <TableRow>
      <TableCell className="text-sm">{formatDate(debit.paydate)}</TableCell>
      <TableCell className="text-sm">{debit.frequencies}</TableCell>
      <TableCell className="text-sm">{ isNil(debit.amount) ? '' : currencyFormatter.format(debit.amount) }</TableCell>
      <TableCell className="text-sm">{debit.status}</TableCell>
      {
        debit.status === '' && debit.isPayable && debit.amount > 0
          ?
          <TableCell>
            <CreditCardIcon />
            <Button
              color="primary"
              onClick={compose(setIsOpen, T)}
              className="text-sm perform-debit"
            >
              Perform Debit
            </Button>
          </TableCell>
          : <TableCell />
      }
      <DirectDebitConfirmationModal
        debit={debit}
        title="Confirm Perform Debit"
        isOpen={isOpen}
        onClose={compose(setIsOpen, F)}
        onCancel={compose(setIsOpen, F)}
      />
    </TableRow>
  );
}

export default DirectDebitRow;
