import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useFormikContext } from 'formik';

import { Benefit, Dependent } from 'shared/types/non-plan-year.types';

interface BenefitDependentsProps {
  dependents: Dependent[];
  idx: number;
  benefit: Benefit;
  disabled?: boolean;
}

function BenefitDependents({ dependents, idx, benefit, disabled = false }: BenefitDependentsProps) {
  const { handleChange } = useFormikContext<{benefits: Benefit[];}>();

  return (
    <>
      <h6>Covered Dependents</h6>
      <div className="grid grid-cols-2 grid-flow-row">
        {dependents.map((d, ii) => (
          <FormControlLabel
            control={
              <Checkbox
                value={ii}
                checked={benefit.coveredDependents.includes(`${ii}` as any)}
                name={`benefits.${idx}.coveredDependents`}
                color="primary"
                data-testid={`checkbox-${d.name.toLocaleLowerCase().replace(/\W+/g, '-')}`}
                onChange={!disabled ? handleChange : undefined}
                inputProps={ { disabled } }
              />
            }
            disabled={disabled}
            label={d.name}
            labelPlacement="end"
            key={ii}
          />
        ))}
      </div>
    </>
  );
}

export default BenefitDependents;
