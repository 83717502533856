import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { always, ifElse, isEmpty } from 'ramda';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {
  formikFieldErrorText,
  formikFieldHasError,
  handleCustomFieldChange,
} from 'shared/form-helpers';
import CommonSelect from 'components/shared/form/CommonSelect';
import { FormikMuiDatePicker } from 'components/shared/form/formik/FormikMuiDatePicker';
import { LeaveRequestFormFields } from 'enter-leave-request/enter-leave-request.types';
import {
  absenceOrAccommodationRequestReasons,
  leaveDurationOptions,
} from 'enter-leave-request/enter-leave-request-fields';

export function EnterLeaveRequestEventInfo(): React.ReactElement {
  const form = useFormikContext<LeaveRequestFormFields>();

  const setMaxStartDate = ifElse(
    isEmpty,
    always(null),
    always(dayjs(form.values.event.dateEnd).subtract(1, 'day')),
  )(form.values.event.dateEnd);

  const setMinEndDate = ifElse(
    isEmpty,
    always(null),
    always(dayjs(form.values.event.dateStart).add(1, 'day')),
  )(form.values.event.dateStart);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form.validateForm();
  }, []);

  return (
    <>
      <div className="flex space-x-4 mb-4" data-testid="enter-leave-request-event-info">
        <FormikMuiDatePicker
          className="basis-1/2"
          label="Leave of Absence or Accommodation Start Date"
          path="event.dateStart"
          value={form.values.event.dateStart}
          maxDate={setMaxStartDate}
          hasError={formikFieldHasError('event.dateStart', form)}
          form={form}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
        />
        <FormikMuiDatePicker
          className="basis-1/2"
          label="Leave of Absence or Accommodation End Date"
          path="event.dateEnd"
          value={form.values.event.dateEnd}
          minDate={setMinEndDate}
          hasError={formikFieldHasError('event.dateEnd', form)}
          form={form}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <CommonSelect
          classes={ { control: 'common-select basis-full' } }
          options={absenceOrAccommodationRequestReasons}
          value={form.values.event.reason}
          name="event.reason"
          label="Reason for Leave of Absence or Accommodation Request"
          onSelectChange={handleCustomFieldChange('event.reason', form)}
          onSelectBlur={form.handleBlur}
          error={formikFieldHasError('event.reason', form)}
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Family member relationship (Optional)"
          name="event.kinRelation"
          value={form.values.event.kinRelation}
          error={formikFieldHasError('event.kinRelation', form)}
          helperText={formikFieldErrorText('event.kinRelation', form) ??
            // eslint-disable-next-line max-len
            'If you are taking a leave of absence to care for a family member, please indicate their relationship to you.'}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="event.kinRelation"
        />
        <CommonSelect
          classes={ { control: 'common-select basis-1/2' } }
          options={leaveDurationOptions}
          value={form.values.event.duration}
          name="event.reason"
          label="Duration of the leave of absence or accommodation"
          onSelectChange={handleCustomFieldChange('event.duration', form)}
          onSelectBlur={form.handleBlur}
          error={formikFieldHasError('event.duration', form)}
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          className="w-full"
          multiline
          rows={3}
          variant="outlined"
          label="Briefly describe the reason for your leave of absence or accommodation request"
          name="event.explanation"
          value={form.values.event.explanation}
          error={formikFieldHasError('event.explanation', form)}
          helperText={formikFieldErrorText('event.explanation', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="event.explanation"
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Email"
          name="event.confirmationEmail"
          value={form.values.event.confirmationEmail}
          error={formikFieldHasError('event.confirmationEmail', form)}
          helperText={formikFieldErrorText('event.confirmationEmail', form)
            ?? 'Enter your email address to receive a leave request confirmation email.'}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="event.confirmationEmail"
        />
      </div>

      <div className="flex space-x-4 mb-4">
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                name="event.faxHealthProvider"
                color="primary"
                value
                checked={form.values.event.faxHealthProvider}
                onChange={form.handleChange}
                data-testid="fax-health-provider"
              />
            }
            classes={ { label: 'text-base' } }
            label="Fax a copy of the paperwork to my health care provider on my behalf."
            labelPlacement="end"
          />
        </FormControl>
      </div>
      {
        form.values.event.faxHealthProvider &&
        (
          <div data-testid="fax-fields">
            <div className="flex space-x-4 mb-4">
              <TextField
                className="basis-1/2"
                variant="outlined"
                label="Provider Name"
                name="event.providerName"
                value={form.values.event.providerName}
                error={formikFieldHasError('event.providerName', form)}
                helperText={formikFieldErrorText('event.providerName', form)}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                data-testid="provider-name"
              />
              <TextField
                className="basis-1/2"
                variant="outlined"
                label="Fax Number"
                name="event.providerFax"
                value={form.values.event.providerFax}
                error={formikFieldHasError('event.providerFax', form)}
                helperText={formikFieldErrorText('event.providerFax', form)}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                data-testid="provider-fax"
              />
            </div>
            <div className="flex space-x-4 mb-4">
              <TextField
                className="basis-1/2"
                variant="outlined"
                label="Family Member's Name (Optional)"
                name="event.kinName"
                value={form.values.event.kinName}
                error={formikFieldHasError('event.kinName', form)}
                helperText={formikFieldErrorText('event.kinName', form)}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                data-testid="kin-name"
              />
              <div className="basis-1/2" />
            </div>
          </div>
        )
      }
    </>
  );
}
