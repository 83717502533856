import { okResponse } from "./utils";

function routing(server) {
    server.get('/api/companies/:companyId/reenrollment-checklist/plan-years', (schema) => {
        const { models: data } = schema.reEnrollmentPlans.all();
        return okResponse(data)();
    });
}

export default routing;
