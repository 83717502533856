import React, { ReactElement } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

import styles from './CancelModel.module.css';

interface Props {
  title?: string;
  prompt?: string;
  closeText?: string;
  cancelText?: string;
  onCancel: () => void;
  onClose: () => void;
  isOpen: boolean;
}

function CancelModal({
  title = 'Are you sure you want to cancel?',
  prompt = 'Changes you have made so far will not be saved.',
  closeText = 'Stay',
  cancelText = 'Navigate away',
  isOpen,
  onCancel,
  onClose,
}: Props): ReactElement {
  function makeBody() {
    return (
      <div className={`${styles.modal} absolute left-1/2 p-4 text-center bg-surface`}>
        <ErrorOutline className="w-12 h-12 uppercase my-3 text-caution" />
        <h5 data-testid="cancel-modal-title">{title}</h5>
        <p className="py-5" data-testid="cancel-modal-prompt">
          {prompt}
        </p>
        <div className="flex py-5">
          <Button
            data-testid="cancel-modal-close"
            className={'text-sm font-light flex-auto'}
            onClick={handleClose}
            variant="contained"
          >
            {closeText}
          </Button>
          <div className="flex-auto" />
          <Button
            data-testid="cancel-modal-confirm"
            variant="contained"
            color="inherit"
            onClick={handleCancel}
            className={'text-sm uppercase font-light flex-auto bg-danger text-white'}
          >
            {cancelText}
          </Button>
        </div>
      </div>
    );
  }

  function handleCancel() {
    handleClose();
    onCancel();
  }

  function handleClose() {
    onClose();
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="cancel-modal-title"
      aria-describedby="cancel-modal-description"
    >
      {makeBody()}
    </Modal>
  );
}

export default CancelModal;
