import Button from '@mui/material/Button';
import { F, T, compose, pipe } from 'ramda';

import { CUSTOM_PLCCODE } from '../../../components/shared/GlobalConstants';

interface Props {
  planCode: string;
  setIsModalOpen: any;
  setIsPending: any;
}

function ViewTransactionButton({ planCode, setIsModalOpen, setIsPending }: Props) {
  return (
    <div className="w-full flex justify-end" >
      {
        planCode === CUSTOM_PLCCODE
          ? (
            <div className="flex w-1/2">
              <Button
                variant="outlined"
                color="primary"
                type="button"
                data-testid="export-custom-paid-transactions"
                className="m-8"
                fullWidth
                tabIndex={-1}
                onClick={pipe(compose(setIsModalOpen, T), compose(setIsPending, F))}
              >
                View Paid Transactions
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                data-testid="export-custom-pending-transactions"
                className="m-8"
                fullWidth
                tabIndex={-1}
                onClick={pipe(compose(setIsModalOpen, T), compose(setIsPending, T))}
              >
                View Pending Transactions
              </Button>
            </div>
          )
          : (
            <div className="flex w-1/5">
              <Button
                variant="outlined"
                color="primary"
                type="button"
                data-testid="export-transactions"
                className="mt-5"
                fullWidth
                tabIndex={-1}
                onClick={compose(setIsModalOpen, T)}
              >
                View Transactions
              </Button>
            </div>
          )
      }
    </div>
  );
}

export default ViewTransactionButton;
