import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assertIsDefined } from 'shared/asserts';
import { RootState } from 'store/rootReducer';

import { OpenPlan, PlanDetails, PlanEnrollmentPayload, PlanEnrollmentState } from './plan-enrollment.types';

export const initialState: PlanEnrollmentState = {
  plan: undefined,
  planDetails: undefined,
};

const planEnrollmentSlice = createSlice({
  name: 'planEnrollment',
  initialState,
  reducers: {
    setPlan(state, { payload }: PayloadAction<OpenPlan>) {
      state.plan = payload;
    },
    setPlanDetails(state, { payload }: PayloadAction<PlanDetails>) {
      state.planDetails = payload;
    },
    resetPlanEnrollment(state) {
      state.plan = initialState.plan;
      state.planDetails = initialState.planDetails;
    },
  },
});

export const {
  setPlan,
  setPlanDetails,
  resetPlanEnrollment,
} = planEnrollmentSlice.actions;

export default planEnrollmentSlice.reducer;

// thunks

// selectors
export const selectPlanEnrollmentPlan = (state: RootState): OpenPlan | undefined => state.planEnrollment.plan;

export const selectPlanEnrollmentDetails = (state: RootState): PlanDetails | undefined => {
  return state.planEnrollment.planDetails;
};

export const selectPlanEnrollmentPayload = (state: RootState): Partial<PlanEnrollmentPayload> => {
  const {
    companies: { selectedCompany },
    participant: { selected: participant },
    planEnrollment: { plan, planDetails },
  } = state;

  assertIsDefined(selectedCompany);
  assertIsDefined(participant);

  const { pyrid, plcid } = plan ?? {};
  const {
    partStart,
    partEnd = plan?.yearend,
  } = planDetails ?? {};
  let partAnn,
    firstPayDateOfChange,
    payPeriodDeduction,
    planLevel,
    plccode = plan?.plccode,
    currentContributions,
    additionalContributions,
    coveredDependents;

  if (planDetails?.type === 'MRA') {
    partAnn = planDetails.partAnn;
    firstPayDateOfChange = planDetails.firstPayDateOfChange;
    payPeriodDeduction = planDetails.payPeriodDeduction;

    if (planDetails.lfsa) {
      planLevel = planDetails.planLevel;
      plccode = '-';
    }
  }

  if (planDetails?.type === 'CUSTOM') {
    coveredDependents = planDetails.coveredDependents;
    additionalContributions = planDetails.additionalContributions;
    currentContributions = planDetails.currentContributions;
  }

  return {
    medplancd: selectedCompany.medplancd,
    empno: participant.empno,
    pyrid,
    plcid,
    plccode,
    partStart,
    partEnd,
    partAnn,
    firstPayDateOfChange,
    payPeriodDeduction,
    planLevel,
    currentContributions,
    additionalContributions,
    coveredDependents,
  };
};
