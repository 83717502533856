import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { useSelector } from 'react-redux';

import NoticeTableRow from './NoticeTableRow';
import { Notice, CobraInitialNotice, CobraQENotice } from './notice-list.types';
import SearchInput from 'components/shared/SearchInput';
import { selectSelectedCompany } from 'companies/companies.slice';

interface NoticesTableProps {
  notices: Notice[];
}

function noticeCompare(notice: Notice): (n: Notice) => boolean {
  return (n: Notice): boolean => {
    return n.employeeNumber === notice.employeeNumber &&
      (
        (
          notice.hasOwnProperty('c_initid')
            ? (notice as CobraInitialNotice).c_initid === (n as CobraInitialNotice).c_initid
            : true
        ) ||

        (
          notice.hasOwnProperty('qualid')
            ? (notice as CobraQENotice).qualid === (n as CobraQENotice).qualid
            : true
        )
      );
  };
}

function noticeFilter(search: string): (n: Notice) => boolean {
  const comp = search.toLocaleLowerCase();

  return (n: Notice): boolean => {
    return n.firstName.toLocaleLowerCase().includes(comp) ||
      n.lastName.toLocaleLowerCase().includes(comp) ||
      n.employeeNumber.includes(comp) ||
      n.ssn.includes(comp);
  };
}

function NoticesTable({ notices }: NoticesTableProps): React.ReactElement {
  const [ allNotices, setAllNotices ] = useState<Notice[ ]>(notices);
  const [ filteredNotices, setFilteredNotices ] = useState<Notice[ ]>(notices);
  const company = useSelector(selectSelectedCompany);
  useEffect(() => {
    setAllNotices(notices);
    setFilteredNotices(notices);
  }, [ notices ]);

  function handleDelete(notice: Notice) {
    let foundIdx = allNotices.findIndex(noticeCompare(notice));

    if (foundIdx >= 0) {
      setAllNotices([
        ...allNotices.slice(0, foundIdx),
        ...allNotices.slice(foundIdx + 1),
      ]);
    }

    foundIdx = filteredNotices.findIndex(noticeCompare(notice));

    if (foundIdx >= 0) {
      setFilteredNotices([
        ...filteredNotices.slice(0, foundIdx),
        ...filteredNotices.slice(foundIdx + 1),
      ]);
    }
  }

  function handleSearchChange(search: string) {
    if (!search) {
      setFilteredNotices(allNotices);
    } else {
      setFilteredNotices(allNotices.filter(noticeFilter(search)));
    }
  }

  function header(): React.ReactElement {
    if (notices.length < 1) {
      return (
        <TableRow>
          <TableCell ><p className="italic">No notices are available</p>
          </TableCell>
        </TableRow>
      );
    }

    if (notices[0].hasOwnProperty('qualid')) {
      // pending qualifying event notices
      return (
        <TableRow>
          <TableCell />
          <TableCell className="text-sm">First Name</TableCell>
          <TableCell className="text-sm">Last Name</TableCell>
          <TableCell className="text-sm">ID#</TableCell>
          <TableCell className="text-sm">PID</TableCell>
          <TableCell className="text-sm">Date to Send</TableCell>
          <TableCell className="text-sm">Event Date</TableCell>
          <TableCell className="text-sm">Event Type</TableCell>
          <TableCell className="text-sm">Coverage Start</TableCell>
          <TableCell className="text-sm">Paid Through</TableCell>
          <TableCell className="text-sm">Actions</TableCell>
        </TableRow>
      );
    }

    // pending initial notices
    return (
      <TableRow>
        <TableCell />
        <TableCell className="text-sm">First Name</TableCell>
        <TableCell className="text-sm">Last Name</TableCell>
        <TableCell className="text-sm">ID#</TableCell>
        <TableCell className="text-sm">PID</TableCell>
        <TableCell className="text-sm">Date to Send</TableCell>
        <TableCell className="text-sm">Notice Recipient</TableCell>
        <TableCell className="text-sm">Actions</TableCell>
      </TableRow>
    );
  }

  function body(): React.ReactElement[] {
    return filteredNotices.map((p) => (
      <NoticeTableRow
        notice={p}
        onNoticeDeleted={handleDelete}
        key={p.unqid}
      />
    ));
  }
  var placeHolderText = `Search Participant by PID/${!company || !company.iddesc ? 'SSN' : company.iddesc}/Name`;

  return (
    <div className="w-full">
      <div className="flex my-4 pl-2">
        <div className="w-1/3">
          <SearchInput size="small" placeholder={placeHolderText} onChange={handleSearchChange} />
        </div>
        <h5 className="leading-10 pl-4">Pending Total: { notices.length }</h5>
      </div>
      <Table className="w-full">
        <TableHead>
          {header()}
        </TableHead>
        <TableBody>
          {body()}
        </TableBody>
      </Table>
    </div>
  );
}

export default NoticesTable;
