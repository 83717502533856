export const defaultPersonalDetailsFields = {
  eeId: '',
  nameFirst: '',
  nameLast: '',
  dob: '',
  phone: '',
  addr1: '',
  addr2: '',
  city: '',
  stateProvCd: '',
  postalCd: '',
};

export const defaultEventInfoFields = {
  absenceDate: '',
  hours: '',
  minutes: '',
  title: '',
};

export const headerTextEnum = {
  personalDetails: 'Please use the form below to report intermittent absences to Flores Leave Solutions.',
  eventInfo: 'Enter the absence details below.',
  summary: 'Are you sure you want to submit this Absence?',
};

export const submitTextEnum = {
  next: 'Next Step',
  submit: 'Submit Absence',
};
