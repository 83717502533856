import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as _BrowserRouter } from 'react-router-dom';
import { Provider as _Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import './styles/tailwind.css';
import './styles/index.css';
import store from './store';
import App from './components/App';
import MuiTheme from './components/shared/MuiTheme';
import makeServer from 'server';
import { loginInit } from 'authentication/authentication';
import SnackPanel from 'notifications/SnackPanel';
import { reactPlugin } from 'shared/telemetry/appInsights';

// handle logging in or redirecting to login before the app starts
loginInit(); // todo: refactor this to return user?

if (process.env.REACT_APP_USE_MIRAGE === 'true') {
  const server = makeServer({ environment: 'development' });
  server.logging = process.env.REACT_APP_LOG_LEVEL === 'debug';
}

const Provider = _Provider as any;
const BrowserRouter = _BrowserRouter as any;

// NOTE: do not put any layout here. This file is for main app configuration.
// Main layout and routing configuration is in App.tsx
const container = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiTheme>
        <AppInsightsContext.Provider value={reactPlugin}>
          <BrowserRouter >
            <App />
          </BrowserRouter>
        </AppInsightsContext.Provider>
        <SnackPanel />
      </MuiTheme>
    </Provider>
  </React.StrictMode>,
  container,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
