import CommonSelect from './form/CommonSelect';
import { mapToSelectOption } from 'shared/form-helpers';

export interface PayFreqSelectOption {
  pfqid: string;
  pfqdesc: string;
}

export interface PayFreqSelectProps {
  payFreqs: PayFreqSelectOption[] | null;
  value: string;
  label?: string;
  name: string;
  onPayFreqChanged: (PayFreq: string) => void;
  error?: boolean;
  className?: string;
  isRequired?: boolean;
  disabled?: boolean;
}

function PayFreqSelect({
  payFreqs,
  value,
  label = 'Pay Frequency',
  name,
  onPayFreqChanged,
  error = false,
  className = '',
  isRequired = true,
  disabled = false,
}: PayFreqSelectProps) {
  const options = mapToSelectOption(payFreqs ?? [], 'pfqdesc', 'pfqid');

  function handleChange(val: string) {
    onPayFreqChanged(val);
  }

  return (
    <CommonSelect
      options={options}
      label={label}
      name={name}
      value={value}
      onSelectChange={handleChange}
      error={isRequired && error}
      classes={ {
        control: `${className} w-full`,
        error: className,
      } }
    />
  );
}

export default PayFreqSelect;
