import Button from '@mui/material/Button';
import withModal from 'components/shared/HOC/WithModal';
import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined';

interface Props {
  onNewAbsence: () => void;
  onGoToDash: () => void;
}

export function ReportAnAbsenceConfirmation({
  onNewAbsence,
  onGoToDash,
}: Readonly<Props>): React.ReactElement {
  return (
    <div className="p-4 text-center bg-surface" data-testid="report-absence-confirmation-modal">
      <div className="text-green-600">
        <CheckCircleOutlineOutlined color="inherit" fontSize="large" />
      </div>
      <h5 className="mb-8">Absence has been submitted</h5>
      <Button
        variant="contained"
        className="mr-4"
        onClick={onNewAbsence}
        data-testid="btn-report-absence-add-new"
      >
        Report Another Absence
      </Button>
      <Button
        variant="contained"
        onClick={onGoToDash}
        data-testid="btn-report-absence-dashboard"
      >
        Go to Dashboard
      </Button>
    </div>
  );
}

export default withModal(ReportAnAbsenceConfirmation);
