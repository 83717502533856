import RateInformationSelection from './step-three/RateInformationSelection';
import RatesTable from 're-enrollment-checklist/cobra-reenrollment-checklist/plan-rates/step-three/RatesTable';
import NewRateSelect from 're-enrollment-checklist/cobra-reenrollment-checklist/plan-rates/step-three/NewRateSelect';
import {
  selectCobraPlanOnDeckConfirmRates,
  selectCobraPlanOnDeckValidations,
  selectIsOnDeckPlanNew,
  selectLevelCoverageTiersOnDeck,
  selectOnDeckPlanHasLevels,
  setCobraPlanOnDeckConfirmRates,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { useDispatch, useSelector } from 'react-redux';
import RatesFileUploadSection
  from 're-enrollment-checklist/cobra-reenrollment-checklist/plan-rates/step-three/RatesFileUploadSection';
import { F, T, always, compose, cond, equals, nthArg, when } from 'ramda';
import { YesNoChar } from 'shared/enums/generic.enum';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import store from 'store';
import { useState } from 'react';

interface Props {
  isChecklistComplete?: boolean;
}

function StepThree({ isChecklistComplete = false }:Props) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const hasLevels = useSelector(selectOnDeckPlanHasLevels);
  const isNewPlan = useSelector(selectIsOnDeckPlanNew);
  const plans = useSelector(selectLevelCoverageTiersOnDeck);
  const [ hasConfirmRatesError, setHasConfirmRatesError ] = useState(false);
  const validations = useSelector(selectCobraPlanOnDeckValidations);
  const confirmRates = useSelector(selectCobraPlanOnDeckConfirmRates) ?? false;

  const handleConfirmPlanRatesChange = compose(
    when(always(hasConfirmRatesError), compose(setHasConfirmRatesError, F)),
    dispatch,
    setCobraPlanOnDeckConfirmRates,
    nthArg(1),
  );

  return (
    <>
      <RateInformationSelection disabled={isChecklistComplete} />
      {cond<string, JSX.Element>([
        [ equals(YesNoChar.YES), always(
          <div data-testid="add-rates-section" className="my-5">
            <hr />
            <div className="my-5">
              <p className="font-bold">Rates</p>
              <p className="mb-5">
                    When providing your updated rates, <strong>please do not add a 2% administration fee</strong> - the
                    Flores system will calculate your monthly COBRA
                    rates based on the 100% monthly premium cost you provide.
              </p>
              <RatesTable plans={plans} disabled={isChecklistComplete} />
              { !isChecklistComplete && isNewPlan && <NewRateSelect /> }
              { !isChecklistComplete &&
                <div className="mt-5">
                  <FormControlLabel
                    className="w-full mb-6"
                    onBlur={when(
                      always(!!validations.confirmRates),
                      compose(setHasConfirmRatesError, T),
                    )}
                    control={
                      <Checkbox
                        data-testid="confirm-plan-rates"
                        value={confirmRates}
                        checked={confirmRates}
                        name="updatePaidThrough"
                        color="primary"
                        onChange={handleConfirmPlanRatesChange}
                      />
                    }
                    label="I confirm that the above rates are accurate,
                     final, and do not include any 2% admin fee for COBRA"
                    labelPlacement="end"
                    componentsProps={ { typography: { variant: 'body2' } } }
                  />
                  { hasConfirmRatesError &&
                    <FormHelperText className="Mui-error ml-8">{validations.confirmRates}</FormHelperText>
                  }
                </div>
              }
            </div>
          </div>,
        ) ],
        [ equals(YesNoChar.NO), always(
          <RatesFileUploadSection disabled={isChecklistComplete} />,
        ) ],
      ])(hasLevels as string)}
    </>
  );
}

export default StepThree;
