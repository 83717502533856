import { Participant } from 'participant-search/participant-list.types';

interface ParticipantSummaryProps {
  participant: Omit<Participant, 'pyrid' | 'planCode'>;
}

function ParticipantSummary({ participant }: ParticipantSummaryProps) {
  return (
    <div className="flex flex-col space-y-2">
      <p className="text-lg text-black font-semibold">{participant.fname} {participant.lname}</p>
      <p>
        <span className="text-black font-medium">Address: </span>
        {participant.hstreet1},&nbsp;
        {participant.hstreet2 ? `${participant.hstreet2}, ` : ''}{participant.hcity},&nbsp;
        {participant.hstate} {participant.hzip}
      </p>
      <p>
        <span className="text-black font-medium">Phone: </span>
        {participant.hphone}
      </p>
    </div>
  );
}

export default ParticipantSummary;
