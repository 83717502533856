import dayjs from 'dayjs';
import request, { ContentType } from '../api-request';
import { validateDate } from './validateDate';
import queryStringTag from './reportQueryStringTag';

export function downloadTurningSixtyFiveReport(
  companyId: number,
  type: 'cobra' | 'retiree' | 'loa',
  startDate?: string,
  endDate?: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download turning sixty five report for invalid company.'));
  }

  try {
    const start = validateDate('Cannot download turning sixty five report for invalid start date.',
      startDate ?? dayjs().format());
    const end = validateDate('Cannot download turning sixty five report for invalid end date.',
      endDate ?? dayjs().format());

    const uri = queryStringTag`/api/companies/${companyId}/reports/turning-sixty-five/${type}/export${start}${end}`;

    return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (err) {
    return Promise.reject(err);
  }
}
