import { useState, useRef, useEffect } from 'react';
import { compose, tap, always } from 'ramda';
import { useSelector } from 'react-redux';

import { getInvoices } from 'shared/api/invoicesApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { NonPlanYearInvoice } from './view-invoices.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { selectParticipant } from 'participant-search/participant.slice';

export function useInvoices(isCobra = true) {
  const errorNotifier = useErrorNotifier();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const { empno } = useSelector(selectParticipant) ?? { empno: 0 };

  const [ invoices, setInvoices ] = useState<NonPlanYearInvoice[] | null>(null);

  const [ setDefault ] = useState(() => compose(setInvoices, always([])));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    getInvoices(compid, isCobra, empno.toString())
      .then((result) => {
        if (mountedRef.current) {
          setInvoices(result ?? []);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
    };
  }, [ handleError, compid, empno, isCobra ]);

  return invoices;
}
