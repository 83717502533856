import React, { ComponentType } from 'react';

import { DocumentLibraryType } from './document-library.types';
import TabPanel from 'components/shared/TabPanel';
import FormsDocumentLibrary from './forms/FormsDocumentLibrary';
import LegalDocumentLibrary from './legal/LegalDocumentLibrary';
import EducationDocumentLibrary from './education/EducationDocumentLibrary';

const tabTypeMap: Map<DocumentLibraryType, ComponentType<React.PropsWithChildren<unknown>>> = new Map([
  [ 'forms', FormsDocumentLibrary ],
  [ 'legal', LegalDocumentLibrary ],
  [ 'education', EducationDocumentLibrary ],
]);

interface DocumentLibraryTabPanelsProps {
  documentLibraryTypes: DocumentLibraryType[];
  activeTab: DocumentLibraryType;
}

function DocumentLibraryTabPanels({ documentLibraryTypes, activeTab }: DocumentLibraryTabPanelsProps) {
  function renderComp(type: DocumentLibraryType) {
    const Comp = tabTypeMap.get(type);

    return Comp ? <div data-testid={`${type}-tab-panel`}><Comp /></div> : <></>;
  }

  return (
    <>
      {
        documentLibraryTypes.map((type) => {
          return (
            <TabPanel value={activeTab} index={type} key={`${type}-tab-panel`}>
              { activeTab === type ? renderComp(type) : <></> }
            </TabPanel>
          );
        })
      }
    </>
  );
}

export default DocumentLibraryTabPanels;
