import React, { useState } from 'react';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Textfield from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';

import { handleDateChange } from 'shared/form-helpers';
import { validationSchema } from './plan-enrollment-custom.validators';
import { PlanEnrollmentFormProps } from '../plan-enrollment.types';
import { Dependent } from 'shared/types/non-plan-year.types';
import DependentSelect from 'components/shared/DependentSelect';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface Props extends PlanEnrollmentFormProps {
  partStart?: string;
  partEnd?: string;
  yearBeg?: string;
  yearEnd?: string;
  currentContributions?: number;
  additionalContributions?: number;
  selectedDependents?: Dependent[];
  isBalance: boolean;
  planName?: string;
}

function PlanEnrollmentCustom({
  partStart = '',
  partEnd = '',
  yearBeg = '',
  yearEnd = '',
  currentContributions,
  additionalContributions,
  selectedDependents = [],
  isBalance,
  planName,
  isEdit = false,
  onNextStep,
  onCancel,
}: Props) {
  const form = useFormik({
    validationSchema,
    initialValues: {
      partStart,
      partEnd,
      yearBeg,
      yearEnd,
      currentContributions,
      additionalContributions,
      coveredDependents: selectedDependents.map(d => JSON.stringify(d)),
    },
    onSubmit: (values) => {
      try {
        validationSchema.validateSync(
          values,
          {
            abortEarly: false,
            stripUnknown: false,
          },
        );

        onNextStep({
          partStart: values.partStart,
          partEnd: values.partEnd,
          coveredDependents: values.coveredDependents.map(d => JSON.parse(d)),
          additionalContributions: values.additionalContributions ?? 0,
          currentContributions: values.currentContributions ?? 0,
          type: 'CUSTOM',
        });
      } catch (err) {
        setWholeFormErrors(err.errors);
      }
    },
  });
  const [ wholeFormErrors, setWholeFormErrors ] = useState<string[]>([]);

  function showPlanName() {
    if (isEdit && planName) {
      return <p className="my-8">{planName}</p>;
    }

    return <></>;
  }

  return (
    <>
      <h6>{isEdit && 'Update '}Participant Enrollment</h6>
      <p className="my-8">Enter the employee's enrollment by completing the form below.</p>
      {showPlanName()}
      <form className="plan-enrollment-custom-form space-y-8">
        {
          wholeFormErrors.length
            ? wholeFormErrors.map((m, i) => (
              <FormHelperText
                className="text-base pb-5"
                key={i}
                disabled
                error
              >
                {m}
              </FormHelperText>
            ))
            : <></>
        }
        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Enrollment Begins"
            inputFormat="MM/DD/YYYY"
            value={form.values.partStart ? dayjs(form.values.partStart, 'YYYY-MM-DD')
              .format('MM/DD/YYYY') : null}
            onChange={handleDateChange('partStart', form)}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <Textfield
                variant="outlined"
                name="partStart"
                className="w-full"
                {...params}
                error={form.touched.partStart && !!form.errors.partStart}
                helperText={form.touched.partStart && form.errors.partStart}
              />
            )}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayJS}>
          <DatePicker
            label="Enrollment Ends"
            inputFormat="MM/DD/YYYY"
            value={form.values.partEnd ? dayjs(form.values.partEnd, 'YYYY-MM-DD')
              .format('MM/DD/YYYY') : null}
            onChange={handleDateChange('partEnd', form)}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={(params) => (
              <Textfield
                variant="outlined"
                name="partEnd"
                className="w-full"
                {...params}
                error={form.touched.partEnd && !!form.errors.partEnd}
                helperText={form.touched.partEnd && form.errors.partEnd}
              />
            )}
          />
        </LocalizationProvider>

        {
          isBalance ? (
            <>
              <Textfield
                className="plan-enrollment-current-contributions"
                variant="outlined"
                name="currentContributions"
                label="Total Current Contributions"
                type="number"
                disabled
                value={form.values.currentContributions ?? ''}
                error={!!form.touched.currentContributions && !!form.errors.currentContributions}
                helperText={form.touched.currentContributions && form.errors.currentContributions}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                fullWidth
                InputProps={ {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                } }
              />
              <Textfield
                className="plan-enrollment-additional-contributions"
                variant="outlined"
                name="additionalContributions"
                label="Additional Contributions"
                type="number"
                value={form.values.additionalContributions ?? ''}
                error={!!form.touched.additionalContributions && !!form.errors.additionalContributions}
                helperText={form.touched.additionalContributions && form.errors.additionalContributions}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                fullWidth
                InputProps={ {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                } }
              />
            </>
          ) : <></>
        }
        <FormikProvider value={form}>
          <DependentSelect title="Covered Dependents" />
        </FormikProvider>
        <div className="wizard-buttons">
          <Button
            color="primary"
            onClick={onCancel}
            className="plan-enrollment-cancel"
            tabIndex={-1}
          >
              Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="ml-8 plan-enrollment-next"
            onClick={form.submitForm}
            tabIndex={-1}
          >
              Next Step
          </Button>
        </div>
      </form>
    </>
  );
}

export default PlanEnrollmentCustom;
