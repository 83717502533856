import React, { ReactElement, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { usePageTitle } from 'shared/custom-hooks/usePageTitle';
import {
  cancelModalClosed,
  selectCancelModalStatus,
  selectCompleteChecklistRequestStatus,
  selectFetchPlansStatus,
  selectReEnrollmentPlansByPlanYearId,
  selectSaveCobraOePacketInfoStatus,
  selectCobraOeSubmitDocStatus,
  selectCobraPlan,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { fetchReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import { selectSelectedCompany } from 'companies/companies.slice';
import PageContentPaper from 'components/layout/PageContentPaper';
import TabPanel from 'components/shared/TabPanel';
import Loading from 'components/shared/Loading';
import CoverLetterInfoTab from './cover-letter/CoverLetterInfoTab';
import SummaryTab from './summary/SummaryTab';
import OePacketsTab from './oe-packets/OePacketsTab';
import CancelModal from 'components/shared/CancelModal';
import { compose, always, cond, equals, T } from 'ramda';
import store from 'store';

type CobraTab = 'cover-letter-info' | 'oe-packets' | 'summary'

function CobraReEnrollmentChecklist(): ReactElement {
  usePageTitle('COBRA Open Enrollment Packets');

  const history = useHistory();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const { planYearId = '0', tab = 'general-info' } = useParams<{planYearId: string; tab?: CobraTab;}>();
  const plan = useSelector(selectReEnrollmentPlansByPlanYearId(+planYearId));
  const dispatch = useDispatch<typeof store.dispatch>();
  const saveStatus = useSelector(selectSaveCobraOePacketInfoStatus);
  const fetchStatus = useSelector(selectFetchPlansStatus);
  const submitDocStatus = useSelector(selectCobraOeSubmitDocStatus);
  const completeChecklistStatus = useSelector(selectCompleteChecklistRequestStatus);
  const cancelModalStatus = useSelector(selectCancelModalStatus);
  const {
    coverLetterSectionComplete,
    oePacketsSectionComplete,
  } = useSelector(selectCobraPlan);

  useEffect(() => {
    if (!plan) {
      dispatch(fetchReEnrollmentChecklistPlan(compid));
    }
  }, [ planYearId, dispatch, plan, compid ]);

  function handleTabChange (_: React.ChangeEvent<{}>, newTab: string) {
    history.push(`/reenrollment-checklist/cobra/${planYearId}/${newTab}`);
  }

  return (
    <>
      <PageContentPaper className="mt-4 mb-8">
        <div className="flex flex-col justify-around">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            className="-ml-4 -mt-4 flex justify-around"
            value={tab}
            centered
            onChange={handleTabChange}
          >
            <Tab
              data-testid="cover-letter-tab"
              value="cover-letter-info"
              label="Cover Letter Info"
            />
            <Tab
              data-testid="oe-packets-tab"
              value="oe-packets"
              label="OE Packets"
              disabled={!coverLetterSectionComplete}
            />
            <Tab
              disabled={!oePacketsSectionComplete || !coverLetterSectionComplete}
              data-testid="summary-tab"
              value="summary"
              label="Summary"
            />
          </Tabs>
          <hr />
          <TabPanel value={tab} index="cover-letter-info">
            {
              cond<string, ReactElement>([
                [ equals('pending'), always(
                  <>
                    <Skeleton variant="rectangular" height={50} className="w-2/5 my-5" />
                    <Skeleton variant="rectangular" height={50} className="w-2/5 my-5" />
                    <Skeleton variant="rectangular" height={50} className="w-2/5 my-5" />
                    <Skeleton variant="rectangular" height={150} className="w-4/5 my-5" />
                    <Skeleton variant="rectangular" height={150} className="w-4/5 my-5" />
                  </>,
                ) ],
                [ T, always(<CoverLetterInfoTab />) ]],
              )(fetchStatus)
            }
          </TabPanel>
          <TabPanel value={tab} index="oe-packets">
            {
              cond<string, ReactElement>([
                [ equals('pending'), always(
                  <>
                    <Skeleton variant="rectangular" height={50} className="w-1/2 my-5" />
                    <div className="flex">
                      <Skeleton variant="rectangular" height={250} className="w-1/3 my-5 mr-5" />
                      <Skeleton variant="rectangular" height={250} className="w-1/3 my-5" />
                    </div>
                    <Skeleton variant="rectangular" height={500} className="w-full my-5" />
                  </>,
                ) ],
                [ T, always(<OePacketsTab />) ]],
              )(fetchStatus)
            }
          </TabPanel>
          <TabPanel value={tab} index="summary">
            {
              cond<string, ReactElement>([
                [ equals('pending'), always(
                  <>
                    <Skeleton variant="rectangular" height={250} className="my-5" />
                    <Skeleton variant="rectangular" height={300} className="my-5" />
                    <Skeleton variant="rectangular" height={300} className="my-5" />
                  </>,
                ) ],
                [ T, always(<SummaryTab />) ]],
              )(fetchStatus)
            }
          </TabPanel>
        </div>
      </PageContentPaper>
      {
        saveStatus === 'pending'
        || submitDocStatus === 'pending'
        || completeChecklistStatus === 'pending'
          ? <Loading />
          : <></>
      }
      <CancelModal
        isOpen={cancelModalStatus === 'open'}
        onCancel={compose(history.push, always('/reenrollment-checklist'))}
        onClose={compose(dispatch, cancelModalClosed)}
      />
    </>
  );
}

export default CobraReEnrollmentChecklist;
