import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Dependents from 'components/shared/dependents/Dependents';
import { DirectBillingStepProps } from '../direct-billing.types';
import {
  setAddedDependents,
  loadInitialDependents,
  selectInitialDependents,
} from 'direct-billing/direct-billing.slice';
import { QeDependents } from 'shared/types/non-plan-year.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import store from 'store';

function DirectBillingQeDependents({
  onCancel,
}: DirectBillingStepProps) {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const compid = useSelector(selectSelectedCompany)?.compid ?? 0;
  const initialDependents = useSelector(selectInitialDependents);

  useEffect(() => {
    dispatch(loadInitialDependents(compid));
  }, [ dispatch, compid ]);

  function handleNextStep(data: QeDependents) {
    dispatch(setAddedDependents(data.addedDependents));
    history.push('/direct-bill-event/benefits');
  }

  return (
    <div className="flex flex-col space-y-4">
      <p>
        Dependents who are continuing coverage under the plan should be listed below.
      </p>
      <Dependents
        onNextStep={handleNextStep}
        onCancel={onCancel}
        dependents={initialDependents ?? []}
        initialAddedDependents={[]}
        submitText="Next Step"
        cancelText="Cancel"
      />
    </div>
  );
}

export default DirectBillingQeDependents;
