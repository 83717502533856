import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { always, andThen, compose, gt, ifElse, isNil, lte, pathOr, when } from 'ramda';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import {
  cancelModalOpened,
  selectCobraPlanNewEligibleCount,
  selectEligibilityCountDifference,
  setCobraPlanNewEligibilityCount,
  selectCobraPlan,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { noop, toNumber } from 'shared/utils';
import store from 'store';
import { saveCobraOePacketInfo } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import CancelModal from 'components/shared/CancelModal';

type warningModalStatus = 'closed' | 'open'

function CobraEligibilityCount(): ReactElement {
  const [ warningModalStatus, setWarningModalStatus ] = useState<warningModalStatus>('closed');
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const eligibilityCount = useSelector(selectCobraPlanNewEligibleCount);
  const diff = useSelector(selectEligibilityCountDifference);
  const hasError = lte(eligibilityCount, 0);
  const { checklistComplete } = useSelector(selectCobraPlan);

  const onCountChange = compose(
    dispatch,
    setCobraPlanNewEligibilityCount,
    toNumber,
    pathOr('0', [ 'target', 'value' ]),
  );

  const handleNavigation = compose(
    history.push,
    always(`/reenrollment-checklist/cobra-plan/${planYearId}/oe-packets-options`),
  );

  const onSave = compose(
    andThen(handleNavigation),
    dispatch,
    saveCobraOePacketInfo,
  );

  const handleContinue = compose(
    ifElse(
      always(checklistComplete),
      handleNavigation,
      compose(
        ifElse(
          gt(diff),
          compose(setWarningModalStatus, always<warningModalStatus>('open')),
          onSave,
        ),
        always(20),
      ),
    ),
  );

  const setEligibilityCount = when(isNil, always(''))(eligibilityCount);

  return (
    <>
      <FormControl component="fieldset" className="my-8">
        <h5>
          Please verify your number of employees who are currently covered under one or more
          of your COBRA-eligible plans who could potentially be offered COBRA coverage.
        </h5>
        <p>
          <i>
          For example, an active employee with family medical coverage and individual only dental
          counts as one covered active employee.
          </i>
        </p>
        <div className="w-2/5 my-5">

          <TextField
            variant="outlined"
            label="COBRA Eligible Count"
            name="cobraEligibilityCount"
            type="number"
            value={setEligibilityCount}
            onChange={onCountChange}
            error={hasError}
            helperText={hasError && 'Eligibility count must be greater than 0'}
            fullWidth
            data-testid="cobra-eligibility-count"
            disabled={checklistComplete}
          />
        </div>
      </FormControl>
      <div className="flex flex-row-reverse">
        <Button
          data-testid="eligibility-continue"
          variant="contained"
          color="primary"
          disabled={hasError}
          onClick={handleContinue}
        >Continue
        </Button>
        <Button
          className="mr-4"
          onClick={compose(dispatch, cancelModalOpened, noop)}
        >Cancel
        </Button>
      </div>
      <CancelModal
        title="Eligibility count changed by 20% or more from the previous year."
        prompt="You've changed the eligible count by 20% or more from the previous year.
          This will result in a significant change to your administrative billing, and should
          represent a roughly 20% increase or decrease in the size of your organization over the past year.
          Are you sure this is correct?"
        cancelText="Yes, continue."
        closeText="No, stay here."
        isOpen={warningModalStatus === 'open'}
        onClose={compose(setWarningModalStatus, always<warningModalStatus>('closed'))}
        onCancel={onSave}
      />
    </>
  );
}

export default CobraEligibilityCount;
