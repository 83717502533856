import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';

import ReportsTile from 'reports/shared/ReportsTile';
import { downloadPendingResponsesReport } from 'shared/api/pendingResponsesApi';
import { usePendingResponseCount } from '../hooks/usePendingResponseCount';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useSelector } from 'react-redux';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { CRLType } from 'shared/types/non-plan-year.types';

interface PendingResponsesProps {
  serviceType: CRLType;
}

function PendingResponses({ serviceType }: PendingResponsesProps) {
  const count = usePendingResponseCount(serviceType);
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  function handleExport() {
    startDownloading();
    downloadPendingResponsesReport(compid, serviceType)
      .then(doneDownloading)
      .catch(handleError);
  }

  return (
    <ReportsTile
      count={count}
      title="Pending Responses"
      description="List of participants to whom a Qualifying Event notice was mailed,
        and a response has not yet been received."
      variant="primary-dark"
      exportDisabled={downloading || count === null}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default PendingResponses;
