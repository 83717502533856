import request, { ContentType } from '../api-request';
import { CRLType } from 'shared/types/non-plan-year.types';

export function getPendingResponseCount(companyId: number, type: CRLType) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load pending responses for invalid company.'));
  }

  return request<number>(`/api/companies/${companyId}/reports/pending-responses/${type}`);
}

export function downloadPendingResponsesReport(
  companyId: number,
  type: CRLType,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download pending responses report for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/reports/pending-responses/${type}/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

