import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { always, compose, equals, ifElse, nthArg, pathOr, when, T, F, move, findIndex, propEq, or } from 'ramda';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import {
  selectCobraInsurers,
  setCobraPlanOnDeckActivateAccountType,
  setCobraPlanOnDeckPlanTypeId,
  setCobraPlanOnDeckPlanTypeDescription,
  setCobraPlanOnDeckPlanName,
  setCobraPlanOnDeckUpdatePaidThrough,
  setCobraPlanOnDeckNewCarrierName,
  selectCobraPlanOnDeckValidations,
  selectCoverageTypes,
  setCobraPlanOnDeckCarrierNameAndId,
  selectCobraPlanOnDeck,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { toNumber } from 'shared/utils';
import store from 'store';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ButtonBase from '@mui/material/ButtonBase';
import FormHelperText from '@mui/material/FormHelperText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { SelectOptions } from 'shared/enums/generic.enum';
import { ActivateAccountType } from 'shared/enums/insurers.enum';

interface Props {
  isChecklistComplete?: boolean;
}

function StepOne({ isChecklistComplete = false }: Props) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const insurers = useSelector(selectCobraInsurers);
  const {
    isNewPlan,
    planTypeId,
    carrier,
    newCarrierName,
    planName,
    activateAccountType,
    updatePaidThrough,
    planTypeDescription,
  } = useSelector(selectCobraPlanOnDeck);
  const carrierFieldsDisabled = or(isChecklistComplete, equals(false, isNewPlan));
  const coverageTypes = useSelector(selectCoverageTypes);
  const validations = useSelector(selectCobraPlanOnDeckValidations);
  const [ hasPlanNameError, setHasPlanNameError ] = useState(false);
  const [ hasCarrierError, setHasCarrierError ] = useState(false);
  const [ hasNewCarrierNameError, setHasNewCarrierNameError ] = useState(false);
  const [ hasPlanTypeIdError, setHasPlanTypeIdError ] = useState(false);
  const [ hasPlanTypeDescriptionError, setHasPlanTypeDescriptionError ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);

  function handleBlur(error: string, setter: React.Dispatch<React.SetStateAction<boolean>>) {
    return when(always(!!error), compose(setter, T));
  }

  function handlePopoverOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'paid-through-popover' : undefined;

  const handlePlanName = compose(
    when(always(hasPlanNameError), compose(setHasPlanNameError, F)),
    dispatch,
    setCobraPlanOnDeckPlanName,
    pathOr('', [ 'target', 'value' ]),
  );

  const handleCarrier = compose(
    when(always(hasNewCarrierNameError), compose(setHasNewCarrierNameError, F)),
    when(always(hasCarrierError), compose(setHasCarrierError, F)),
    dispatch,
    setCobraPlanOnDeckCarrierNameAndId,
    pathOr('', [ 'target', 'value' ]),
  );

  const handleNewCarrierName = compose(
    when(always(hasNewCarrierNameError), compose(setHasNewCarrierNameError, F)),
    dispatch,
    setCobraPlanOnDeckNewCarrierName,
    pathOr('', [ 'target', 'value' ]),
  );

  const handlePlanTypeIdChange = compose(
    when(always(hasPlanTypeDescriptionError), compose(setHasPlanTypeDescriptionError, F)),
    when(always(hasPlanTypeIdError), compose(setHasPlanTypeIdError, F)),
    dispatch,
    setCobraPlanOnDeckPlanTypeId,
    toNumber,
    pathOr('0', [ 'target', 'value' ]),
  );

  const handlePlanTypeDescription = compose(
    when(always(hasPlanTypeDescriptionError), compose(setHasPlanTypeDescriptionError, F)),
    dispatch,
    setCobraPlanOnDeckPlanTypeDescription,
    pathOr('', [ 'target', 'value' ]),
  );

  const handleActivateAccountType = compose(
    dispatch,
    setCobraPlanOnDeckActivateAccountType,
    toNumber,
    pathOr(0, [ 'target', 'value' ]),
  );

  const handleUpdatePaidThrough = compose(
    dispatch,
    setCobraPlanOnDeckUpdatePaidThrough,
    ifElse(equals(true), always(1), always(0)),
    nthArg(1),
  );

  const orderedInsurers = move(findIndex(propEq('insurer', SelectOptions.InsurerNotListed), insurers), 0)(insurers);

  useEffect(() => {
    if (!activateAccountType) {
      dispatch(setCobraPlanOnDeckActivateAccountType(ActivateAccountType.PAYMENT));
    }
  });

  useEffect(() => {
    if (updatePaidThrough === undefined) {
      dispatch(setCobraPlanOnDeckUpdatePaidThrough(1));
    }
  });

  return (
    <>
      <FormControl component="fieldset" className="w-full mt-8">
        <TextField
          variant="outlined"
          label="Plan Name"
          name="planName"
          value={planName ?? ''}
          onChange={handlePlanName}
          onBlur={handleBlur(validations.planName, setHasPlanNameError)}
          fullWidth
          data-testid="cobra-plan-rates-plan-name"
          error={hasPlanNameError}
          helperText={hasPlanNameError && validations.planName}
          disabled={carrierFieldsDisabled}
        />
      </FormControl>

      <FormControl variant="outlined" className="w-full mt-4">
        <InputLabel>Carrier/Provider</InputLabel>
        <Select
          label="Carrier/Provider"
          name="carrier"
          onChange={handleCarrier}
          onBlur={handleBlur(validations.carrier, setHasCarrierError)}
          value={carrier}
          native
          fullWidth
          data-testid="cobra-plan-rates-carrier"
          error={hasCarrierError}
          disabled={carrierFieldsDisabled}
        >
          <option value="" />
          {orderedInsurers.map((i) => <option value={i.insurer} key={i.insurer}>{i.insurer}</option>)}
        </Select>
        {
          hasCarrierError && (
            <FormHelperText className="Mui-error">{validations.carrier}</FormHelperText>
          )
        }
      </FormControl>

      {
        carrier === SelectOptions.InsurerNotListed && (
          <FormControl component="fieldset" className="w-full mt-4">
            <TextField
              variant="outlined"
              label="Carrier Name"
              name="newCarrierName"
              value={newCarrierName ?? ''}
              onChange={handleNewCarrierName}
              onBlur={handleBlur(validations.newCarrierName, setHasNewCarrierNameError)}
              fullWidth
              data-testid="cobra-plan-rates-carrier-name"
              error={hasNewCarrierNameError}
              helperText={hasNewCarrierNameError && validations.newCarrierName}
              disabled={carrierFieldsDisabled}
            />
          </FormControl>
        )
      }

      <FormControl variant="outlined" className="w-full mt-4">
        <InputLabel>Type of Coverage</InputLabel>
        <Select
          label="Type of Coverage"
          onChange={handlePlanTypeIdChange}
          onBlur={handleBlur(validations.planTypeId, setHasPlanTypeIdError)}
          value={planTypeId}
          native
          fullWidth
          data-testid="cobra-plan-rates-type-of-coverage"
          error={hasPlanTypeIdError}
          disabled={carrierFieldsDisabled}
        >
          <option value="" />
          {coverageTypes.map((ct) => <option value={ct.planTypeId} key={ct.name}>{ct.name}</option>)}
        </Select>
        {
          hasPlanTypeIdError && (
            <FormHelperText className="Mui-error">{validations.planTypeId}</FormHelperText>
          )
        }
      </FormControl>

      {
        planTypeId === 8 && (
          <FormControl component="fieldset" className="w-full mt-4">
            <TextField
              variant="outlined"
              label="If Other, Please Specify"
              name="planTypeDescription"
              value={planTypeDescription ?? ''}
              onChange={handlePlanTypeDescription}
              onBlur={handleBlur(validations.planTypeDescription, setHasPlanTypeDescriptionError)}
              fullWidth
              data-testid="cobra-plan-rates-plan-type-description"
              error={hasPlanTypeDescriptionError}
              helperText={hasPlanTypeDescriptionError && validations.planTypeDescription}
              disabled={carrierFieldsDisabled}
            />
          </FormControl>
        )
      }

      <p className="font-bold mt-8">Activate account based on</p>

      <RadioGroup
        aria-label="Activate account based on"
        name="activateAccountTypeRadioGroup"
        value={activateAccountType ?? ActivateAccountType.PAYMENT}
        className="mt-8"
        onChange={handleActivateAccountType}
      >
        <FormControlLabel
          value={ActivateAccountType.PAYMENT}
          control={<Radio color="primary" disabled={isChecklistComplete} />}
          label="Payment - COBRA coverage should not be reinstated until the continuant has
           returned their completed election form and also paid their first month's premium. (recommended)"
        />
        <FormControlLabel
          value={ActivateAccountType.ELECTION}
          className="mt-4"
          control={<Radio color="primary" disabled={isChecklistComplete} />}
          label="Election - COBRA coverage can be reinstated upon the continuant returning
           their completed election form."
        />
      </RadioGroup>

      <FormControlLabel
        className="w-full mt-8"
        control={
          <Checkbox
            value={updatePaidThrough ?? 0}
            checked={updatePaidThrough === 1}
            name="updatePaidThrough"
            color="primary"
            onChange={handleUpdatePaidThrough}
            disabled={isChecklistComplete}
          />
        }
        label="Update on paid through dates"
        labelPlacement="end"
      />

      <ButtonBase
        aria-describedby={popoverId}
        data-testid="paid-through-trigger"
        onMouseOver={handlePopoverOpen}
        onMouseOut={handlePopoverClose}
        className="relative ml-8 text-base text-sky-600 cursor-help"
        disableTouchRipple
      >
        What is the paid through date?
      </ButtonBase>
      <Popover
        id="paid-through-popover"
        data-testid="paid-through-popover"
        sx={ { pointerEvents: 'none' } }
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
        transformOrigin={ { vertical: 'bottom', horizontal: 'center' } }
        disableRestoreFocus
        PaperProps={ { style: { width: 350 } } }
      >
        <Typography sx={ { p: 4 } } variant="body2">Flores' recommended option. The carrier will update
          members based upon the date their premiums are paid through. This will not allow the member to
          show as eligible through the end date of their COBRA term, only their paid through date.
        </Typography>
      </Popover>
    </>
  );
}

export default StepOne;
