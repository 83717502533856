import React from 'react';

import Navigation from 'navigation/Navigation';
import settingsNavConfig from './settings-nav-config';
import PageContentPaper from 'components/layout/PageContentPaper';
import { makeNav } from 'components/layout/make-nav';
import { hasPermission } from 'authorization/authorization';
import { NavConfig } from 'navigation/navigation.types';

function navConfigPredicate(item: NavConfig) {
  if (item.name === 'Manage Users') {
    return hasPermission('Users');
  }

  return true;
}

const filter = makeNav(navConfigPredicate);

function SettingsSidebar(): React.ReactElement {
  return (
    <PageContentPaper data-testid="settingsSidebarDrawer">
      <Navigation navConfig={filter(settingsNavConfig)} showLegacy={false} />
    </PageContentPaper>
  );
}

export default SettingsSidebar;
