import request, { ContentType } from '../api-request';
import { OpenPlan } from '../../plan-enrollment/plan-enrollment.types';

export function getCustomEnrollmentCount(companyId: number, plan: OpenPlan) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load custom enrollment for invalid company.'));
  }

  if (plan.plcid <= 0) {
    return Promise.reject(new Error('Cannot load custom enrollment for invalid plan.'));
  }

  return request<number>(`/api/companies/${companyId}/reports/custom-enrollment/${plan.plcid}/count`);
}

export function downloadCustomEnrollmentReport(companyId: number, plan: OpenPlan) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download custom enrollment report for invalid company.'));
  }

  if (plan.plcid <= 0) {
    return Promise.reject(new Error('Cannot download custom enrollment report for invalid plan.'));
  }

  if (plan.pyrid <= 0) {
    return Promise.reject(new Error('Cannot download custom enrollment report for invalid plan year.'));
  }

  const exportUri = `/api/companies/${companyId}/reports/custom-enrollment/${plan.pyrid}/${plan.plcid}/export`;

  return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}
