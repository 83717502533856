import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Slide } from './marketing-info-tile.types';

interface EmblaCarouselSlideProps {
  slide: Slide;
}

function EmblaCarouselSlide({ slide }: Readonly<EmblaCarouselSlideProps>): React.ReactElement {
  function renderImage(): React.ReactElement {
    if (slide.image) {
      return (
        <div data-testid={`slide-image-${slide.unqid}`}
          className="flex items-end w-2/3 h-2/3 ml-2 bg-contain bg-bottom bg-no-repeat" style={
            {
              backgroundImage: `url(${slide.image})`,
            } }
        />
      );
    } else {
      return (<></>);
    }
  }

  function renderAction1Button(): React.ReactElement {
    if (slide.action1Link && slide.action1Label) {
      return (
        <div className="flex justify-left mt-5" data-testid={`slide-button-1-${slide.unqid}`}>
          <Button size="small" variant="contained" color="secondary"
            href={slide.action1Link} target="_blank" className="text-sm"
          >{slide.action1Label}
          </Button>
        </div>
      );
    }

    return <></>;
  }

  function renderAction2Button(): React.ReactElement {
    if (slide.action2Link && slide.action2Label) {
      return (
        <div className="flex justify-left mt-5 ml-3" data-testid={`slide-button-2-${slide.unqid}`}>
          <Button size="small" variant="contained" color="secondary"
            href={slide.action2Link} className="text-sm"
          >{slide.action2Label}
          </Button>
        </div>
      );
    }

    return <></>;
  }

  return (
    <div className="embla__slide basis-full flex-shrink-0 flex-grow-0">
      <Paper
        className="p-5 h-full"
        style={ { backgroundColor: slide.backgroundColor } }
      >
        <div className="flex h-full">
          <div className="flex flex-col justify-between">
            <div className="flex">
              <Typography variant="h5" style={ { color: slide.textColor, fontWeight: 'bold' } }>
                {slide.headline}
              </Typography>
            </div>
            <div className="flex">
              <Typography variant="body2" style={ { color: slide.textColor } }>
                {slide.paragraph}
              </Typography>
            </div>
            <div className="flex">
              { renderAction1Button() }
              { renderAction2Button() }
            </div>
          </div>
          { renderImage() }
        </div>
      </Paper>
    </div>
  );
}

export default EmblaCarouselSlide;
