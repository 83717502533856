import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { getContributionsNotForwardedCount } from 'shared/api/contributionsNotForwardedApi';
import { getAnnualAllocationAuditCount } from 'shared/api/annualAllocationAuditApi';
import { RootState } from 'store/rootReducer';

export interface ReportsState {
  contributionsNotForwardedCount: number | null;
  annualAllocationAuditCount: number | null;
}

export const initialState: ReportsState = {
  contributionsNotForwardedCount: 0,
  annualAllocationAuditCount: 0,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    loadContributionsNotForwardedCountStart(state) {
      state.contributionsNotForwardedCount = null;
    },
    setContributionsNotForwardedCount(state, { payload }: PayloadAction<number | null>) {
      state.contributionsNotForwardedCount = payload;
    },
    loadAnnualAllocationAuditCountStart(state) {
      state.annualAllocationAuditCount = null;
    },
    setAnnualAllocationAuditCount(state, { payload }: PayloadAction<number | null>) {
      state.annualAllocationAuditCount = payload;
    },
  },
});

export const {
  loadContributionsNotForwardedCountStart,
  setContributionsNotForwardedCount,
  loadAnnualAllocationAuditCountStart,
  setAnnualAllocationAuditCount,
} = reportsSlice.actions;

export default reportsSlice.reducer;

// thunks
export function loadContributionsNotForwardedCount(
  companyId: number,
): AppThunk {
  return async (dispatch) => {
    dispatch(loadContributionsNotForwardedCountStart());

    try {
      const count = await getContributionsNotForwardedCount(companyId);
      dispatch(setContributionsNotForwardedCount(count));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
      dispatch(setContributionsNotForwardedCount(0));
    }
  };
}

// thunks
export function loadAnnualAllocationAuditCount(
  companyId: number, planYearId: number,
): AppThunk {
  return async (dispatch) => {
    dispatch(loadAnnualAllocationAuditCountStart());

    try {
      const count = await getAnnualAllocationAuditCount(companyId, planYearId);
      dispatch(setAnnualAllocationAuditCount(count));
    } catch (err) {
      dispatch(addHttpErrorNotification(err));
      dispatch(setAnnualAllocationAuditCount(0));
    }
  };
}

// selectors
export const selectContributionsNotForwardedCount = (
  state: RootState) => state.reports.contributionsNotForwardedCount;

export const selectAnnualAllocationAuditCount = (
  state: RootState) => state.reports.annualAllocationAuditCount;
