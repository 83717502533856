import { Server, Model, hasMany } from 'miragejs';

// import seed data (fixtures) and routes here
import companies from './fixtures/companies';
import users from './fixtures/users';
import planYears from './fixtures/plan-years';
import services from './fixtures/services';
import qeNotices from './fixtures/qe-notices';
import initNotices from './fixtures/init-notices';
import planYearServiceSummaries from './fixtures/plan-year-service-summaries.json';
import marketingSlides from './fixtures/marketing-slides';
import contactInfos from './fixtures/contact-info';
import fileTypes from './fixtures/filetypes.json';
import nonPlanYearServices from './fixtures/non-plan-year-services';
import participants from './fixtures/participants.json';
import fhMonthYears from './fixtures/file-history-month-year';
import fhWebFiles from './fixtures/file-history-webfiles';
import reportHistoryClientFiles from './fixtures/report-history-clientfiles';
import reportHistoryMonthYears from './fixtures/report-history-month-year';
import locations from './fixtures/locations';
import dependents from './fixtures/dependents';
import cobraQeTypes from './fixtures/cobra-qe-types.json';
import cobraPlans from './fixtures/cobra-qe-plans.json';
import payfreqs from './fixtures/payfreqs.json';
import openPlans from './fixtures/open-plans.json';
import payDates from './fixtures/pay-dates.json';
import cobraEnrollments from './fixtures/cobra-enrollment.json';
import enrolledPlans from './fixtures/enrolled-plans.json';
import enrollmentTransactions from './fixtures/enrollment-transactions.json';
import customPendingTransactions from './fixtures/custom-transactions-pending.json';
import customPaidTransactions from './fixtures/custom-transactions-paid.json';
import invoices from './fixtures/invoices.json';
import documents from './fixtures/documents.json';
import checks from './fixtures/checks.json';
import reportTypes from './fixtures/report-types.json';
import balanceReportCounts from './fixtures/balance-report-counts.json';
import deductionDates from './fixtures/deduction-dates.json';
import cobraDateRangeCounts from './fixtures/cobra-date-range-counts.json';
import directBillEventTypes from './fixtures/direct-bill-event-types.json';
import directBillPlans from './fixtures/direct-bill-plans.json';
import adminInvoices from './fixtures/admin-invoices.json';
import directDebitAccounts from './fixtures/direct-debit-accounts.json';
import debits from './fixtures/direct-debits.json';
import documentLibraryForms from './fixtures/document-library-forms.json';
import educationDocuments from './fixtures/education-documents.json';
import responsibilities from './fixtures/responsibilities.json';
import permissions from './fixtures/permissions.json';
import summaryUsers from './fixtures/summary-users.json';
import contactUsers from './fixtures/contact-users.json';
import enrollmentResourcePlans from './fixtures/enrollment-resource-plans.json';
import reEnrollmentPlans from './fixtures/re-enrollment-plans.json';

import companyRoutes from './routes/companies';
import userRoutes from './routes/users';
import planYearRoutes from './routes/plan-years';
import reportRoutes from './routes/reports';
import qeNoticeRoutes from './routes/qe-notices';
import initNoticeRoutes from './routes/init-notices';
import fileHistoryRoutes from './routes/file-history';
import reportHistoryRoutes from './routes/report-history';
import marketingSlideRoutes from './routes/marketing-slides';
import fileUploadRoutes from './routes/fileupload';
import participantRoutes from './routes/participants';
import cobraQeRoutes from './routes/cobra-qe';
import planEnrollmentRoutes from './routes/plan-enrollment';
import qualifyingEventRoutes from './routes/qualifying-events';
import pendingResponsesRoutes from './routes/pending-responses';
import cobraDateRangeRoutes from './routes/cobra-date-range';
import directBillRoutes from './routes/direct-bill';
import adminBillingRoutes from './routes/administrative-billing';
import multiFactorAuthenticationRoutes from './routes/multi-factor-authentication';
import documentLibraryRoutes from './routes/document-library';
import directDebitRoutes from './routes/direct-debit';
import responsibilitiesRoutes from './routes/responsibilities';
import permissionsRoutes from './routes/permissions';
import enrollmentResourcesRoutes from './routes/enrollment-resources';
import reEnrollmentPlansRoutes from './routes/re-enrollment';

function makeServer({ environment = 'test' } = {}) {
  return new Server({
    environment,
    urlPrefix: `http://${process.env.REACT_APP_API_HOST}`,
    namespace: 'api',

    // define available models and relationships here
    models: {
      company: Model,
      user: Model.extend({
        companies: hasMany(),
      }),
      planYear: Model,
      service: Model,
      report: Model,
      qeNotice: Model,
      initNotice: Model,
      planYearServiceSummary: Model,
      fhMonthYear: Model,
      fhWebFile: Model,
      marketingSlide: Model,
      contactInfo: Model,
      fileType: Model,
      nonPlanYearService: Model,
      participant: Model,
      reportHistoryClientFile: Model,
      reportHistoryMonthYear: Model,
      Location: Model,
      dependent: Model,
      cobraQeType: Model,
      cobraPlan: Model,
      payfreqs: Model,
      openPlan: Model,
      payDate: Model,
      cobraEnrollment: Model,
      enrolledPlan: Model,
      enrollmentTransactions: Model,
      customPaidTransactions: Model,
      customPendingTransactions: Model,
      invoice: Model,
      document: Model,
      check: Model,
      reportTypes: Model,
      balanceReportCounts: Model,
      deductionDates: Model,
      cobraDateRangeCounts: Model,
      directBillEventTypes: Model,
      directBillPlans: Model,
      adminInvoices: Model,
      directDebitAccounts: Model,
      debits: Model,
      documentLibraryForms: Model,
      educationDocuments: Model,
      summaryUsers: Model,
      contactUsers: Model,
      responsibilities: Model,
      permissions: Model,
      enrollmentResourcePlans: Model,
      reEnrollmentPlans: Model,
    },

    // declare seeds here
    fixtures: {
      companies,
      users,
      planYears,
      services,
      qeNotices,
      initNotices,
      planYearServiceSummaries,
      fhMonthYears,
      fhWebFiles,
      marketingSlides,
      contactInfos,
      fileTypes,
      nonPlanYearServices,
      participants,
      reportHistoryMonthYears,
      reportHistoryClientFiles,
      locations,
      dependents,
      cobraQeTypes,
      cobraPlans,
      payfreqs,
      openPlans,
      payDates,
      cobraEnrollments,
      enrolledPlans,
      enrollmentTransactions,
      customPaidTransactions,
      customPendingTransactions,
      invoices,
      documents,
      checks,
      reportTypes,
      balanceReportCounts,
      deductionDates,
      cobraDateRangeCounts,
      directBillEventTypes,
      directBillPlans,
      adminInvoices,
      directDebitAccounts,
      debits,
      documentLibraryForms,
      educationDocuments,
      summaryUsers,
      contactUsers,
      permissions,
      responsibilities,
      enrollmentResourcePlans,
      reEnrollmentPlans,
    },

    seeds(server) {
      // loads seed data
      server.loadFixtures();
    },

    routes() {
      this.passthrough(); // allow any unrecognized routes to go to the real server
      // this.timing = 3000; // uncomment this line to simulate a 3sec network delay
      // wire up routes here
      companyRoutes(this);
      userRoutes(this);
      planYearRoutes(this);
      reportRoutes(this);
      qeNoticeRoutes(this);
      initNoticeRoutes(this);
      fileHistoryRoutes(this);
      marketingSlideRoutes(this);
      fileUploadRoutes(this);
      participantRoutes(this);
      reportHistoryRoutes(this);
      cobraQeRoutes(this);
      planEnrollmentRoutes(this);
      qualifyingEventRoutes(this);
      pendingResponsesRoutes(this);
      cobraDateRangeRoutes(this);
      directBillRoutes(this);
      adminBillingRoutes(this);
      multiFactorAuthenticationRoutes(this);
      documentLibraryRoutes(this);
      directDebitRoutes(this);
      responsibilitiesRoutes(this);
      permissionsRoutes(this);
      enrollmentResourcesRoutes(this);
      reEnrollmentPlansRoutes(this);
    },
  });
}

export default makeServer;
