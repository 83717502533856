import * as yup from 'yup';
import { validationSchema as piSchema } from '../shared/participant-info.validators';
import { requiredText } from '../shared/common-validators';

export const validationSchema = (requirePayFreq = true) => {
  if (requirePayFreq) {
    return piSchema.concat(
      yup.object({
        payfreq: yup
          .string()
          .trim()
          .nullable()
          .required(requiredText),
      }),
    );
  }

  return piSchema;
};
