import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { path } from 'ramda';

import { AvailablePlan, DBBenefit } from 'direct-billing/direct-billing.types';
import { selectDirectBillingDependents } from 'direct-billing/direct-billing.slice';
import CommonSelect from 'components/shared/form/CommonSelect';
import { mapToSelectOption } from 'shared/form-helpers';
import styles from 'components/shared/qe/Benefits.module.css';
import BenefitDependents from 'components/shared/qe/BenefitDependents';
import BenefitSubsidyFields from './BenefitSubsidyFields';

const selectClasses = {
  select: 'mx-4',
  label: 'ml-4',
  error: 'planSelectHelper',
};

const emptyOption = {
  label: 'None Selected',
  value: '-1',
};

interface DBFormBenefitsSectionProps {
  benefit: DBBenefit;
  index: number;
}

function BenefitsFormSection({ benefit, index }: DBFormBenefitsSectionProps) {
  const { values, setFieldValue, handleChange, errors } = useFormikContext<{ benefits: DBBenefit[]; }>();
  const dependents = useSelector(selectDirectBillingDependents);

  function displayErSubsidy() {
    /*
    note: to whoever works on refactoring COBRA QE so we can use this shared component between it and Direct Billing.
    This used to check the selected company for hasCobraErsubsidy == true before rendering the subsidy fields.
    The Direct Billing form always shows these fields. We'll need to come up with some signal (prop, store, etc) to tell
    this component whether or not it should show these fields.
    */
    return <BenefitSubsidyFields index={index} />;
  }

  function showDependents() {
    return dependents.length > 0
      ? (
        <BenefitDependents
          dependents={dependents}
          index={index}
        />
      )
      : <></>;
  }

  function handleBenefitSelectChange(value: string) {
    const selected = benefit.plans.find((p) => value === p.planLevelId.toString()) ?? null;
    setFieldValue(`benefits.${index}.selectedPlan`, selected);
  }

  return (
    <div
      key={index}
      className={`${styles.summaryContainer} flex flex-col mb-8`}
      data-testid={`benefitSelector-${benefit.planTypeName}`}
    >
      <h6 className={`text-primary-dark ml-4 mt-4`}>{benefit.planTypeName}</h6>
      <div className="flex flex-row">
        <div className="flex flex-grow flex-col mt-4 mr-4">
          <CommonSelect
            options={mapToSelectOption<AvailablePlan>(benefit.plans, 'displayName', 'planLevelId')}
            value={values.benefits[index]?.selectedPlan?.planLevelId.toString() ?? emptyOption.value}
            label="Plan"
            name={`benefits.${index}.selectedPlan.planLevelId`}
            error={!!path([ 'benefits', index, 'selectedPlan', 'planLevelId' ], errors)}
            data-testid={`benefits.${index}.planSelect`}
            onSelectChange={handleBenefitSelectChange}
            classes={selectClasses}
            emptyOption={emptyOption}
          />

          <div className="flex flex-row">
            <TextField
              variant="outlined"
              label="Cost"
              name={`benefits.${index}.cost`}
              data-testid={`benefits.${index}.cost`}
              placeholder="Default"
              value={values.benefits[index]?.cost}
              InputProps={ {
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              } }
              className="m-4"
              onChange={handleChange}
              error={!!path([ 'benefits', index, 'cost' ], errors)}
              helperText={path([ 'benefits', index, 'cost' ], errors)}
            />
            {displayErSubsidy()}
          </div>
        </div>
        <div className="flex flex-col ml-4">
          {showDependents()}
        </div>
      </div>
    </div>
  );
}

export default BenefitsFormSection;
