import request from 'shared/api-request';
import { LeaveRequestFormFields } from 'enter-leave-request/enter-leave-request.types';
import { deepTrim } from 'shared/utils/deep-trim';
import { ReportAbsenceFormFields } from 'report-an-absence/report-an-absence.types';

export function submitLeaveRequestForm(companyId: number, data: LeaveRequestFormFields) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot submit form for invalid company'));
  }

  const uri = `api/companies/${companyId}/fmla/leave/request`;

  return request(uri, { method: 'POST', body: deepTrim(data) });
}

export function submitReportAnAbsenceForm(companyId: number, data: ReportAbsenceFormFields) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot submit form for invalid company'));
  }

  const uri = `api/companies/${companyId}/fmla/absence/report`;

  return request(uri, { method: 'POST', body: deepTrim(data) });
}
