import React from 'react';
import Divider from '@mui/material/Divider';

import { PayPeriodDeduction } from './enrollment.types';
import PayDeductionValue from './PayDeductionValue';
import { currencyFormatter } from 'shared/utils';

interface PayPeriodDeductionsProps {
  deductions: PayPeriodDeduction[];
}

function PayPeriodDeductions({ deductions }: PayPeriodDeductionsProps): React.ReactElement {
  return (
    <div className="w-full flex flex-col border-2 border-neutral5 rounded-5 border-solid p-4">
      <h6 className="text-base text-high-em mb-5">Pay Period Deduction</h6>
      <div className="flex mb-4">
        <div className="w-1/2 text-base text-med-em font-normal">Beginning</div>
        <div className="w-1/2 text-base text-med-em font-normal">Amount</div>
      </div>
      <div className="w-full flex flex-col">
        {
          deductions.length === 0
            ? <p>No records to display.</p>
            : deductions.map((deduction, idx) => {
              const amount = currencyFormatter.format(deduction.amount);

              return (
                <div key={deduction.beginning}>
                  {idx !== 0 ? <Divider className="my-2 -mx-4" /> : <></>}
                  <PayDeductionValue beginning={deduction.beginning} amount={amount} />
                </div>
              );
            })
        }
      </div>
    </div>
  );
}

export default PayPeriodDeductions;
