import * as yup from 'yup';
import { isNil } from 'ramda';
import dayjs from 'dayjs';

import { isWithinDateRange } from 'shared/utils';

const requiredText = 'This field is required';

export const validationSchema = (isEdit: boolean) => (
  yup
    .object({
      partStart: yup
        .date()
        .nullable()
        .typeError('Invalid Date Format')
        .required(requiredText),
      partEnd: isEdit
        ? yup
          .date()
          .typeError('Invalid Date Format')
          .when('partStart', (start: string, schema: any) => {
            return schema.test({
              test: (end: string) => !!start && (dayjs(start).isSame(dayjs(end)) || dayjs(start).isBefore(dayjs(end))),
              message: 'End date must be after start date',
            });
          })
          .nullable()
          .required(requiredText)
        : yup.date(),
      yearBeg: yup.string(),
      yearEnd: yup.string(),
    })
    .test(
      'plan-year-provided',
      'Error: No plan year can be determined.',
      ({ yearBeg, yearEnd }) => !(isNil(yearBeg) || isNil(yearEnd)),
    )
  // Skip the next validation if partStart
  // is nil because .required() will catch that
  // skip partEnd validation if nil or not in edit mode
    .test(
      'dates-within-plan-year',
      'Start and end dates must be within the open plan year.',
      ({
        yearBeg, yearEnd, partStart, partEnd,
      }) => (isNil(partStart) || isWithinDateRange(yearBeg!, yearEnd!, partStart)())
      && (!isEdit || isNil(partEnd) || isWithinDateRange(yearBeg!, yearEnd!, partEnd)()),
    )
);
