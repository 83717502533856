import React, { ReactElement } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';

interface Props {
  document: ReactElement;
  fileName: string;
}

function TransactionExportButton({ document, fileName }: Props) {
  return (
    <Button
      variant="outlined"
      color="primary"
      type="button"
      data-testid="export-transactions"
      className=""
      tabIndex={-1}
    >
      <PDFDownloadLink
        document={document}
        fileName={fileName}
      ><GetAppIcon /> Export
      </PDFDownloadLink>
    </Button>
  );
}

export default TransactionExportButton;
