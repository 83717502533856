import request from 'shared/api-request';
import {
  ContributionsNotForwarded,
} from 'reports/shared/ContributionsNotForwardedTable/contributions-not-forwarded.types';

export function getHsaContributionNotForwardedStatuses(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get HSA contribution statuses for invalid company'));
  }
  const uri = `/api/companies/${companyId}/reports/hsa-contributions-not-forwarded/statuses`;

  return request<ContributionsNotForwarded[]>(uri)
    .then(statuses => statuses ?? []);
}
