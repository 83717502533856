import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { Debit } from '../direct-debit.types';
import { getDirectDebits } from 'shared/api/directDebitApi';
import { selectSelectedCompany } from 'companies/companies.slice';

export function useDirectDebits() {
  const errorNotifier = useErrorNotifier();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ debits, setDebits ] = useState<Debit[] | null>(null);

  const [ setDefault ] = useState(() => compose(setDebits, always(null)));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (compid > 0) {
      setDebits(null);
      getDirectDebits(compid)
        .then((result) => {
          if (mountedRef.current) {
            setDebits(result ?? []);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ handleError, compid ]);

  return debits;
}
