import React from 'react';

import { Dependent } from 'shared/types/non-plan-year.types';

interface CoveredDependentBoxProps {
  dependents: Dependent[];
}

function CoveredDependentBox({ dependents }: CoveredDependentBoxProps): React.ReactElement {
  return (
    <div className="w-full flex flex-col border-2 border-neutral5 rounded-5 border-solid p-4">
      <h6 className="text-base text-high-em mb-5">Covered Dependents</h6>
      <ul className="list-none list-inside">
        {dependents.map((dep, idx) => <li key={`${idx}-${dep.name}`}>{dep.name}</li>)}
      </ul>
    </div>
  );
}

export default CoveredDependentBox;
