import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { CustomPlanTransaction } from '../enrollment.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { selectParticipant } from 'participant-search/participant.slice';
import request, { APIOptions } from 'shared/api-request';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import store from 'store';

export function useCustomTransactions(pyrid: string, customPlanId: string, isPending: boolean) {
  const dispatch = useDispatch<typeof store.dispatch>();

  const [ transactions, setTransactions ] = useState<CustomPlanTransaction[] | null>(null);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const { empno } = useSelector(selectParticipant) ?? { empno: undefined };

  const { current: abortCtrl } = useRef(new AbortController());
  const { current: setDefault } = useRef(compose(setTransactions, always([])));
  const { current: handleError } = useRef(compose(
    tap(setDefault),
    dispatch,
    addHttpErrorNotification,
  ));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    const uri = `api/companies/${compid}/plan-enrollment/transactions/custom/` +
      `${empno}/plan/${customPlanId}/type/${isPending ? 'pending' : 'paid'}`;
    const apiOpts: APIOptions = { method: 'GET', signal: abortCtrl.signal };

    request<CustomPlanTransaction[]>(uri, apiOpts)
      .then((result) => {
        if (mountedRef.current) {
          setTransactions(result ?? []);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
      abortCtrl.abort();
    };
  }, [ compid, empno, isPending, pyrid, customPlanId, handleError, abortCtrl ]);

  return transactions;
}
