import { Response } from 'miragejs';

function routing(server) {
  server.get('/marketing-slides', (schema) => {
    const { models } = schema.marketingSlides.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });
}

export default routing;