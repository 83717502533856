import React from 'react';
import DragAndDropUpload from 'components/shared/DragAndDropUpload';
import {
  selectCobraPlanOnDeck,
  selectCompanyId,
  selectOnDeckPlanRatesDocuments,
  setCobraPlanOnDeckPlanInProgressId,
  setCobraPlanOnDeckRatesDocuments,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { useDispatch, useSelector } from 'react-redux';
import { uploadPlanRatesDocuments } from 'shared/api/reEnrollmentChecklistApi';
import { useParams } from 'react-router-dom';
import store from 'store';
import { andThen, compose, pathOr, tap } from 'ramda';
interface Props {
  disabled?: boolean;
}

function RatesFileUploadSection({ disabled = false }: Props) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const ratesDocuments = useSelector(selectOnDeckPlanRatesDocuments);
  const companyId = useSelector(selectCompanyId);
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const onDeckPlan = useSelector(selectCobraPlanOnDeck);
  const upload = compose(
    andThen(
      compose(
        dispatch,
        setCobraPlanOnDeckRatesDocuments,
        tap(
          compose(
            dispatch,
            setCobraPlanOnDeckPlanInProgressId,
            pathOr<number>(0,[ 'planInProgressId' ]),
          ),
        ),
      ),
    ),
    uploadPlanRatesDocuments(
      companyId,
      +planYearId,
      onDeckPlan.planId,
      onDeckPlan.planInProgressId,
      onDeckPlan.isNewPlan ?? true),
  );

  return (
    <div key={ratesDocuments.length} data-testid="rates-file-upload-section">
      <DragAndDropUpload onDrop={upload} disabled={disabled} />
      {ratesDocuments.map((file) => (
        <div className="my-5">
          <div className="p-2 border rounded-lg border-gray-200 relative">
            {file.name}
          </div>
        </div>
      ))}
    </div>
  );
}

export default RatesFileUploadSection;

