import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'shared/types/api-status.types';
import { RootState } from 'store/rootReducer';
import { DirectDebitAccount } from 'shared/types/direct-debit-accounts.types';
import { fetchDirectDebitAccount } from 'store/slices/direct-debit/direct-debit.thunk';

export interface DirectDebitSlice {
  directDebitAccounts: DirectDebitAccount[] | null;
  fetchDirectDebitAccountStatus: ApiStatus;
}

export const initialState: DirectDebitSlice = {
  directDebitAccounts: null,
  fetchDirectDebitAccountStatus: 'uninitialized',
};

const directDebitSlice = createSlice({
  name: 'directDebitSlice',
  initialState,
  reducers: {
    resetDirectDebit(state) {
      state.directDebitAccounts = [];
      state.fetchDirectDebitAccountStatus = 'uninitialized';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDirectDebitAccount.pending, (state) => {
        state.fetchDirectDebitAccountStatus = 'pending';
      })
      .addCase(fetchDirectDebitAccount.rejected, (state) => {
        state.fetchDirectDebitAccountStatus = 'rejected';
      })
      .addCase(fetchDirectDebitAccount.fulfilled, (state, action) => {
        state.fetchDirectDebitAccountStatus = 'fulfilled';
        state.directDebitAccounts = action.payload;
      });
  },
});

export const { resetDirectDebit } = directDebitSlice.actions;

export default directDebitSlice.reducer;

export const selectDirectDebitAccounts = (state: RootState) => {
  return state.directDebit.directDebitAccounts;
};

export const selectFetchDirectDebitAccountStatus = (state: RootState) => {
  return state.directDebit.fetchDirectDebitAccountStatus;
};
