import dayjs from 'dayjs';

import { RetireeDateRangeCounts } from 'reports/retiree/retiree-report-types';
import request from '../api-request';
import { validateDate } from './validateDate';
import queryStringTag from './reportQueryStringTag';

export function getRetireeCountsByDateRange(companyId: number, startDate?: string, endDate?: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load Retiree participant counts for invalid company.'));
  }

  try {
    const serviceType = 'retiree';
    const start = validateDate(
      'Cannot load Retiree participant counts for invalid start date.',
      startDate ?? dayjs().format(),
    );
    const end = validateDate(
      'Cannot load Retiree participant counts for invalid end date.',
      endDate ?? dayjs().format(),
    );

    const uri = queryStringTag`/api/companies/${companyId}/reports/date-range/${serviceType}${start}${end}`;

    return request<RetireeDateRangeCounts>(uri);
  } catch (err) {
    return Promise.reject(err);
  }
}
