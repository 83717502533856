import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PageContentPaper from 'components/layout/PageContentPaper';
import { LeaveRecordsSearchInput } from 'leave-records/components/LeaveRecordsSearchInput';
import { LeaveRecordsPageHeader } from 'leave-records/components/LeaveRecordsPageHeader';
import store from 'store';
import {
  getRecentlyViewedFromLocalStorage,
  resetLeaveRecordsApiStatus,
  selectRecentlyViewedEmployeeAbsences,
  updateRecentlyViewed,
} from 'leave-records/leave-records.slice';
import { LeaveRecordsTableRow } from 'leave-records/components/LeaveRecordsTableRow';
import { LeaveRecordCaseSummaryAndName } from 'leave-records/leaveRecord.types';

export function ParticipantLeaveRecords() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const recentlyViewed = useSelector(selectRecentlyViewedEmployeeAbsences);
  const history = useHistory();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(resetLeaveRecordsApiStatus());
    dispatch(getRecentlyViewedFromLocalStorage());
  }, []);

  const handleViewEdit = (result: LeaveRecordCaseSummaryAndName) => () => {
    dispatch(updateRecentlyViewed(result));
    history.push(`/leave-records/${result.eeId}/case/${result.caseListId}/case-info`);
  };

  return (
    <div data-testid="leave-records-page" className="w-full mt-4 mb-8 px-8">
      <LeaveRecordsPageHeader />
      <PageContentPaper>
        <LeaveRecordsSearchInput />
      </PageContentPaper>
      {!!recentlyViewed.length && (
        <div className="mt-5">
          <PageContentPaper>
            <h2 className="page-title my-0">Recently Viewed</h2>
            <TableContainer data-testid="recently-viewed">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="text-sm font-normal whitespace-nowrap pl-20">Full Name</TableCell>
                    <TableCell className="text-sm font-normal whitespace-nowrap">Employee ID</TableCell>
                    <TableCell className="text-sm font-normal whitespace-nowrap">Leave Start</TableCell>
                    <TableCell className="text-sm font-normal whitespace-nowrap">Leave End</TableCell>
                    <TableCell className="text-sm font-normal whitespace-nowrap">Reason for Leave</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentlyViewed.map(c => (
                    <LeaveRecordsTableRow
                      onViewEdit={handleViewEdit(c)}
                      showFullName
                      caseSummary={c}
                      key={`leave-case-row-${c.caseListId}}`}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </PageContentPaper>
        </div>
      )}
    </div>
  );
}
