import { ReactElement, useEffect } from 'react';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { always, cond, equals, compose, when, allPass, any } from 'ramda';
import store from 'store';
import PageContentPaper from 'components/layout/PageContentPaper';
import { getUser } from 'authentication/authentication';
import { selectSelectedCompany } from 'companies/companies.slice';
import {
  ApiStatus,
  selectFetchPlansStatus,
  selectReEnrollmentChecklistPlans,
  selectReEnrollmentChecklistRenewalDate,
  selectCustomPlanNamesFetchStatus,
  selectCanShowOePackets,
  selectFetchCobraChecklistPlansStatus,
} from './re-enrollment-checklist.slice';
import {
  selectFetchFeatureFlagsStatus,
} from 'components/app-settings.slice';
import { loadContactInfo, selectContactInfo } from 'manage-settings/contact-info/contact-info.slice';
import { CobraOePacketCard } from 're-enrollment-checklist/cobra-checklist/CobraOePacketCard';
import { CobraPlanCard } from 're-enrollment-checklist/cobra-reenrollment-checklist/CobraPlanCard';
import {
  fetchCobraChecklistPlans,
  fetchCustomPlans,
  fetchReEnrollmentChecklistPlan,
} from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import { fetchFeatureFlags } from 'components/app-settings.thunks';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import HsaPlanCard from 're-enrollment-checklist/hsa-reenrollment-checklist/HsaPlanCard';
import CustomPlanCard from 're-enrollment-checklist/custom-reenrollment-checklist/CustomPlanCard';
import FsaPlanCard from 're-enrollment-checklist/fsa-reenrollment-checklist/FsaPlanCard';
import HraPlanCard from 're-enrollment-checklist/hra-reenrollment-checklist/HraPlanCard';
import QtePlanCard from 're-enrollment-checklist/qte-reenrollment-checklist/QtePlanCard';
import DirectBillPlanCard from 're-enrollment-checklist/direct-bill-reenrollment-checklist/DirectBillPlanCard';
import { ReEnrollmentChecklistPlan } from './re-enrollment-checklist.types';
import { ApiFetchStatus } from 'shared/enums/api-fetch-status.enum';
import { PlanTypesString } from 'shared/enums/plan-types-string.enum';
import CobraLegacyPlanCard from './cobra-checklist/CobraLegacyCard';

interface ChecklistPlans {
  cobra?: ReEnrollmentChecklistPlan;
  directBill?: ReEnrollmentChecklistPlan;
  custom?: ReEnrollmentChecklistPlan;
  hsa?: ReEnrollmentChecklistPlan;
  fsa?: ReEnrollmentChecklistPlan;
  hra?: ReEnrollmentChecklistPlan;
  qte?: ReEnrollmentChecklistPlan;
}

function populateChecklistPlans(plans: ReEnrollmentChecklistPlan[]): ChecklistPlans {
  const result: ChecklistPlans = {};

  plans.forEach(plan => {
    switch (plan.type) {
    case PlanTypesString.COBRA:
      result.cobra = result.cobra ?? plan; // only get 1st. revisit when legacy removed.
      break;
    case PlanTypesString.DIRECTBILL:
      result.directBill = result.directBill ?? plan; // only get 1st. revisit when legacy removed.
      break;
    case PlanTypesString.CUSTOM:
      result.custom = result.custom ?? plan; // only get 1st. revisit when legacy removed.
      break;
    case PlanTypesString.HSA:
      result.hsa = result.hsa ?? plan; // only get 1st. revisit when legacy removed.
      break;
    case PlanTypesString.FSA:
      result.fsa = result.fsa ?? plan; // only get 1st. revisit when legacy removed.
      break;
    case PlanTypesString.HRA:
      result.hra = result.hra ?? plan; // only get 1st. revisit when legacy removed.
      break;
    case PlanTypesString.QTE:
      result.qte = result.qte ?? plan; // only get 1st. revisit when legacy removed.
      break;
    default:
      return;
    }
  });

  return result;
}

function ReEnrollmentChecklist(): ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const errorNotifier = useErrorNotifier();
  const { compid: companyId } = useSelector(selectSelectedCompany)
    ?? { compid: 0, medplancd: '' };
  const { confname: firstName } = useSelector(selectContactInfo) ?? { confname: '' };
  const dueDate = useSelector(selectReEnrollmentChecklistRenewalDate);
  const daysLeft = dayjs(dueDate).diff(dayjs(), 'days');
  const plans = useSelector(selectReEnrollmentChecklistPlans);
  const checklistPlans = populateChecklistPlans(plans);
  const fetchPlanStatus = useSelector(selectFetchPlansStatus);
  const fetchCustomPlanNameStatus = useSelector(selectCustomPlanNamesFetchStatus);
  const fetchCobraChecklistPlansStatus = useSelector(selectFetchCobraChecklistPlansStatus);
  const fetchFeatureFlagsStatus = useSelector(selectFetchFeatureFlagsStatus);
  const canShowOePackets = useSelector(selectCanShowOePackets);

  const fetchStatuses = [
    fetchPlanStatus,
    fetchCustomPlanNameStatus,
    fetchCobraChecklistPlansStatus,
    fetchFeatureFlagsStatus,
  ];
  const isPending = () => any(
    equals(ApiFetchStatus.PENDING),
  )(fetchStatuses);
  const isRejected = () => any(
    equals(ApiFetchStatus.REJECTED),
  )(fetchStatuses);
  const isFulfilled = () => equals(fetchPlanStatus, ApiFetchStatus.FULFILLED)
    || equals(fetchCustomPlanNameStatus, ApiFetchStatus.FULFILLED);

  useEffect(() => {
    if (!firstName) {
      const user = getUser();
      dispatch(loadContactInfo(companyId, user!.conuserid));
    }
  }, [ dispatch, companyId, firstName, errorNotifier ]);

  useEffect(() => {
    dispatch(fetchReEnrollmentChecklistPlan(companyId));
    dispatch(fetchCustomPlans(companyId));
  }, [ dispatch, companyId ]);

  useEffect(() => {
    when(
      equals(ApiFetchStatus.UNINITIALIZED),
      compose(dispatch, fetchFeatureFlags),
    )(fetchFeatureFlagsStatus);

    when(
      allPass([
        always(!!checklistPlans.cobra),
        compose(equals(ApiFetchStatus.UNINITIALIZED), always(fetchCobraChecklistPlansStatus)),
        compose(equals(ApiFetchStatus.FULFILLED), always(fetchPlanStatus)),
      ]),
      compose(dispatch, fetchCobraChecklistPlans),
    )(checklistPlans.cobra as ReEnrollmentChecklistPlan);
  }, [
    dispatch,
    fetchFeatureFlagsStatus,
    fetchCobraChecklistPlansStatus,
    fetchPlanStatus,
    checklistPlans.cobra,
    companyId,
  ]);

  function loading() {
    return (
      <div className="flex flex-row justify-between" data-testid="checklist-skeletons">
        <div className="w-3/4">
          <Skeleton variant="rectangular" width="100%" height={176} />
          <Skeleton variant="rectangular" width="100%" height={176} className="mt-4" />
          <Skeleton variant="rectangular" width="100%" height={176} className="mt-4" />
        </div>
        <div className="w-1/4 mx-5">
          <Skeleton variant="rectangular" width="100%" height={93} />
        </div>
      </div>
    );
  }

  function renderCobra() {
    if (!checklistPlans.cobra) {
      return (<></>);
    }

    return (
      checklistPlans.cobra.legacyChecklistComplete && !checklistPlans.cobra.checklistComplete
        ? (<CobraLegacyPlanCard plan={checklistPlans.cobra} />)
        : (
          <>
            { canShowOePackets && (<CobraOePacketCard plan={checklistPlans.cobra} />) }
            <CobraPlanCard plan={checklistPlans.cobra} />
          </>
        )
    );
  }

  function renderDirectBill() {
    return (
      !checklistPlans.directBill
        ? (<></>)
        : (<DirectBillPlanCard plan={checklistPlans.directBill} />)
    );
  }

  function renderCustom() {
    return (
      !checklistPlans.custom
        ? (<></>)
        : (<CustomPlanCard plan={checklistPlans.custom} />)
    );
  }

  function renderHsa() {
    return (
      !checklistPlans.hsa
        ? (<></>)
        : (<HsaPlanCard plan={checklistPlans.hsa} />)
    );
  }

  function renderFsa() {
    return (
      !checklistPlans.fsa
        ? (<></>)
        : (<FsaPlanCard plan={checklistPlans.fsa} />)
    );
  }

  function renderHra() {
    return (
      !checklistPlans.hra
        ? (<></>)
        : (<HraPlanCard plan={checklistPlans.hra} />)
    );
  }

  function renderQte() {
    return (
      !checklistPlans.qte
        ? (<></>)
        : (<QtePlanCard plan={checklistPlans.qte} />)
    );
  }

  function pageContent() {
    return (
      <div className="flex flex-row justify-between">
        <div className="w-3/4">
          <PageContentPaper className="px-5">
            <h5>Hi {firstName}, welcome to the re-enrollment checklist!</h5>
            <div>
              At your earliest convenience, please complete the form(s) below, and we will ensure
              your plan information is up to date prior to the start of your new plan year.
            </div>
          </PageContentPaper>
          { renderCobra() }
          { renderDirectBill() }
          { renderCustom() }
          { renderHsa() }
          { renderFsa() }
          { renderHra() }
          { renderQte() }
        </div>
        <div className="w-1/4 mx-5">
          <PageContentPaper className="bg-sand" >
            <h5 className="uppercase">
              <QueryBuilderIcon />
              {Math.abs(daysLeft)} day{daysLeft === 1 ? '' : 's'} {daysLeft < 0 ? 'overdue' : 'left'}
            </h5>
            <hr />
            <div>
              to enroll by {dayjs(dueDate).format('MMMM D, YYYY')}
            </div>
          </PageContentPaper>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col p-4 mx-5">
      <h2 className="page-title">Re-Enrollment Checklist</h2>
      {
        cond<ApiStatus, JSX.Element>([
          [ isPending, loading ],
          [ isRejected, always(<></>) ],
          [ isFulfilled, pageContent ],
        ])()
      }
    </div>
  );
}

export default ReEnrollmentChecklist;
