import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import { selectSelectedCompany } from 'companies/companies.slice';
import { getReportTypes } from 'shared/api/reportsApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { ReportType } from './reports.types';

export function useReportTypes() {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const errorNotifier = useErrorNotifier();

  const [ reportTypes, setReportTypes ] = useState<ReportType[] | null>(null);
  const [ setDefault ] = useState(() => compose(setReportTypes, always([])));
  const [ handleError ] = useState(() => compose(tap(setDefault), errorNotifier));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (compid) {
      getReportTypes(compid)
        .then((result) => {
          if (mountedRef.current) {
            setReportTypes(result ?? []);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ handleError, compid ]);

  return reportTypes;
}
