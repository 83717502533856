import HealthAndSafety from '@mui/icons-material/HealthAndSafety';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { always, compose } from 'ramda';
import { ReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import { ChecklistCard } from 're-enrollment-checklist/ChecklistCard';
import { selectCustomPlanNames } from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { useSelector } from 'react-redux';
import setLegacyChecklistCompletionProgress from 'shared/utils/set-legacy-checklist-completion-progress';

function CustomPlanCard ({ plan }: { plan: ReEnrollmentChecklistPlan; }) {
  const icon = <HealthAndSafety style={ { fontSize: 32 } } />;
  const history = useHistory();
  const planNames = useSelector(selectCustomPlanNames);
  const buttons = (
    <div className="justify-start" data-testid="custom-plan-buttons">
      <Button
        variant="outlined"
        className="mr-5"
        data-testid="custom-link"
        onClick={compose(
          history.push,
          always(`/reenrollment-checklist/custom/${plan.planYearId}`),
        )}
      >
        <div className="justify-start">
          <div>Custom Reimbursement Plans</div>
          {planNames.map(planName => (
            <div>
              {planName}
            </div>
          ))}
        </div>
      </Button>
    </div>
  );

  return (
    <ChecklistCard
      icon={icon}
      title="Custom Account"
      planYearStart={plan.planYearStart}
      planYearEnd={plan.planYearEnd}
      buttons={buttons}
      progress={setLegacyChecklistCompletionProgress(plan.legacyChecklistComplete)}
    />
  );
}

export default CustomPlanCard;

