import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CobraInitialNotice } from 'components/shared/notice-list/notice-list.types';
import { RootState } from 'store/rootReducer';
import { getCobraPendingInitialNotices } from 'shared/api/cobraInitialNoticesApi';
import { AppThunk } from 'store';
import { assertIsDefined } from 'shared/asserts';
import { addHttpErrorNotification } from 'notifications/notifications.slice';

export interface CobraPendingInitialNoticesState {
  notices: CobraInitialNotice[] | null;
}

export const initialState: CobraPendingInitialNoticesState = {
  notices: [],
};

const cobraPendingInitialNoticesSlice = createSlice({
  name: 'cobraPendingInitialNotices',
  initialState,
  reducers: {
    setPendingInitialNotices(state, { payload }: PayloadAction<CobraInitialNotice[] | null>) {
      state.notices = payload;
    },
  },
});

export const {
  setPendingInitialNotices,
} = cobraPendingInitialNoticesSlice.actions;

export default cobraPendingInitialNoticesSlice.reducer;

// thunk
export function loadPendingInitialNotices(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setPendingInitialNotices(null));

    try {
      const { companies: { selectedCompany } } = getState();
      assertIsDefined(selectedCompany?.compid);
      const notices = await getCobraPendingInitialNotices(selectedCompany.compid);
      dispatch(setPendingInitialNotices(notices));
    } catch (ex) {
      dispatch(setPendingInitialNotices([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectPendingInitialNotices = (state: RootState): CobraInitialNotice[] | null => {
  return state.cobraPendingInitialNotices.notices;
};
