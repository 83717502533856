import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { compose, pathOr, always, T, F, when, ifElse, equals, append, filter, complement, join } from 'ramda';
import { maskPhoneNumber } from 'shared/utils/mask-phone-number';
import {
  setCobraPlanOnDeckCarrierUpdateMethod,
  setCobraPlanOnDeckCarrierContactName,
  setCobraPlanOnDeckCarrierContactEmail,
  setCobraPlanOnDeckCarrierContactEmailTemp,
  setCobraPlanOnDeckCarrierContactEmailArray,
  setCobraPlanOnDeckCarrierContactPhone,
  setCobraPlanOnDeckCarrierUpdateMethodNotes,
  selectCobraPlanOnDeckValidations,
  setCobraPlanOnDeckPolicyNumber,
  selectCobraPlanOnDeck,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import store from 'store';
import { InsurerUpdateMethod } from 're-enrollment-checklist/cobra-reenrollment-checklist/cobra-re-enrollment.types';
import { toNumber } from 'shared/utils';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FormHelperText from '@mui/material/FormHelperText';
interface Props {
  isChecklistComplete?: boolean;
}

function StepTwo({ isChecklistComplete = false }: Props) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const {
    carrierUpdateMethod,
    carrierUpdateMethodNotes,
    planPolicyNumber,
    carrierContactName,
    carrierContactPhone,
    carrierContactEmail,
    carrierContactEmailTemp,
    carrierContactEmailArray,
  } = useSelector(selectCobraPlanOnDeck);
  const validations = useSelector(selectCobraPlanOnDeckValidations);
  const [ hasNotesError, setHasNotesError ] = useState(false);
  const [ hasPolicyNumberError, setHasPolicyNumberError ] = useState(false);
  const [ hasNameError, setHasNameError ] = useState(false);
  const [ hasEmailError, setHasEmailError ] = useState(false);
  const [ hasPhoneError, setHasPhoneError ] = useState(false);

  const contactEmailArray = carrierContactEmailArray ?? [];

  function handleBlur(error: string, setter: React.Dispatch<React.SetStateAction<boolean>>) {
    return when(always(!!error), compose(setter, T));
  }

  const handleContactEmailChange = compose(
    compose(setHasEmailError, F),
    dispatch,
    setCobraPlanOnDeckCarrierContactEmailTemp,
    pathOr('', [ 'target', 'value' ]),
  );

  const joinAndSetEmailAddressString = compose(
    dispatch,
    setCobraPlanOnDeckCarrierContactEmail,
    always(join(';', append(carrierContactEmailTemp, contactEmailArray))),
  );

  const resetTempEmail = compose(
    compose(setHasEmailError, F),
    dispatch,
    setCobraPlanOnDeckCarrierContactEmailTemp,
    always(''),
  );

  const handleAddCarrierContactEmail = compose(
    ifElse(
      compose(equals(true), always(!!validations.carrierContactEmailTemp)),
      compose(setHasEmailError, T),
      compose(resetTempEmail, joinAndSetEmailAddressString),
    ),
  );

  const removeCarrierContactEmail = (email: string) => compose(
    dispatch,
    setCobraPlanOnDeckCarrierContactEmail,
    join(';'),
    always(filter(complement(equals(email)), contactEmailArray)),
  );

  const handleCarrierUpdateMethod = compose(
    compose(setHasNotesError, F),
    compose(setHasPolicyNumberError, F),
    dispatch,
    setCobraPlanOnDeckCarrierUpdateMethod,
    toNumber,
    pathOr(InsurerUpdateMethod.ClientViaFridayReport, [ 'target', 'value' ]),
  );

  const handleNotes = compose(
    when(always(hasNotesError), compose(setHasNotesError, F)),
    dispatch,
    setCobraPlanOnDeckCarrierUpdateMethodNotes,
    pathOr('', [ 'target', 'value' ]),
  );

  const setPolicyNumberLabel = ifElse(
    equals(InsurerUpdateMethod.CarrierByTuesdayFile),
    always('Policy Number'),
    always('Policy Number (optional)'),
  )(carrierUpdateMethod);

  const handlePolicyNumber = compose(
    when(always(hasPolicyNumberError), compose(setHasPolicyNumberError, F)),
    dispatch,
    setCobraPlanOnDeckPolicyNumber,
    pathOr('', [ 'target', 'value' ]),
  );

  const handleContactName = compose(
    when(always(hasNameError), compose(setHasNameError, F)),
    dispatch,
    setCobraPlanOnDeckCarrierContactName,
    pathOr('', [ 'target', 'value' ]),
  );

  const handleContactPhone = compose(
    when(always(hasPhoneError), compose(setHasPhoneError, F)),
    dispatch,
    setCobraPlanOnDeckCarrierContactPhone,
    maskPhoneNumber,
    pathOr('', [ 'target', 'value' ]),
  );

  useEffect(() => {
    if (!carrierUpdateMethod) {
      dispatch(setCobraPlanOnDeckCarrierUpdateMethod(1));
    }
  });

  useEffect(() => {
    if (carrierContactEmail) {
      const emailArray = carrierContactEmail.split(';');
      dispatch(setCobraPlanOnDeckCarrierContactEmailArray(emailArray));
    }
  }, [ dispatch, carrierContactEmail ]);

  return (
    <>
      <p className="font-bold mt-8">
        Who is responsible for updating COBRA data for your Company with the insurance carriers?
      </p>

      <RadioGroup
        aria-label="update method"
        name="carrierUpdateMethod"
        value={carrierUpdateMethod ?? '1'}
        className="mt-8"
        onChange={handleCarrierUpdateMethod}
      >
        <FormControlLabel
          value={InsurerUpdateMethod.ClientViaFridayReport}
          control={<Radio color="primary" disabled={isChecklistComplete} />}
          label="Client via Flores report - Flores will provide a weekly summary report of COBRA updates
          to be processed by HR. COBRA status can also be viewed in real-time on our administration portal
          as needed. We request that you regularly audit the bills you receive from the insurance carrier
          to ensure billing matches COBRA enrollment"
        />
        <FormControlLabel
          value={InsurerUpdateMethod.CarrierByTuesdayFile}
          className="mt-4"
          control={<Radio color="primary" disabled={isChecklistComplete} />}
          label="Insurer via Flores standard update file - Flores will send a standard file to your
          insurance carrier on a weekly basis. Flores will rely upon the insurer's processing department
          for updates to be made timely and accurately. We request that you regularly audit the bills you
          receive from the insurance carrier to ensure billing matches COBRA enrollment"
        />
        <FormControlLabel
          value={InsurerUpdateMethod.Other}
          className="mt-4"
          control={<Radio color="primary" disabled={isChecklistComplete} data-testid="cobra-plan-rates-other" />}
          label="Other"
        />
      </RadioGroup>

      {
        carrierUpdateMethod === InsurerUpdateMethod.Other && (
          <FormControl component="fieldset" className="w-full mt-4">
            <TextField
              variant="outlined"
              label="If other, please explain"
              name="carrierUpdateMethodNotes"
              value={carrierUpdateMethodNotes ?? ''}
              onChange={handleNotes}
              onBlur={handleBlur(validations.carrierUpdateMethodNotes, setHasNotesError)}
              fullWidth
              data-testid="cobra-plan-rates-notes"
              error={hasNotesError}
              helperText={hasNotesError && validations.carrierUpdateMethodNotes}
              disabled={isChecklistComplete}
            />
          </FormControl>
        )
      }
      <FormControl component="fieldset" className="w-full mt-4">
        <TextField
          variant="outlined"
          label={setPolicyNumberLabel}
          name="planPolicyNumber"
          value={planPolicyNumber ?? ''}
          onChange={handlePolicyNumber}
          onBlur={handleBlur(validations.planPolicyNumber, setHasPolicyNumberError)}
          fullWidth
          data-testid="cobra-plan-rates-policy-number"
          error={hasPolicyNumberError}
          helperText={hasPolicyNumberError && validations.planPolicyNumber}
          disabled={isChecklistComplete}
        />
      </FormControl>

      <hr />
      <p className="font-bold mt-8">Information for Eligibility Updates</p>

      <FormControl component="fieldset" className="w-full mt-8">
        <TextField
          variant="outlined"
          label="Contact Name"
          name="carrierContactName"
          value={carrierContactName ?? ''}
          onChange={handleContactName}
          onBlur={handleBlur(validations.carrierContactName, setHasNameError)}
          fullWidth
          data-testid="cobra-plan-rates-contact-name"
          error={hasNameError}
          helperText={hasNameError && validations.carrierContactName}
          disabled={isChecklistComplete}
        />
      </FormControl>
      <div className="flex">
        <FormControl component="fieldset" className="w-full mt-4 nowrap">
          <TextField
            variant="outlined"
            label="Add Contact Email"
            name="addCarrierContactEmail"
            value={carrierContactEmailTemp ?? ''}
            onChange={handleContactEmailChange}
            fullWidth
            data-testid="addCarrierContactEmail"
            error={hasEmailError}
            disabled={isChecklistComplete}
          />
        </FormControl>
        <Button
          data-testid="add-contact-email-button"
          variant="outlined"
          className="w-1/3 mt-4 ml-4 px-4 whitespace-nowrap"
          onClick={handleAddCarrierContactEmail}
          disabled={isChecklistComplete}
        >
          Add Email
        </Button>
      </div>
      { hasEmailError &&
        <FormHelperText error className="py-2 px-4">
          {validations.carrierContactEmailTemp}
        </FormHelperText>
      }
      { contactEmailArray.length > 0 &&
        <ul className="mb-4">
          { contactEmailArray.map((email: string, i: number) => (
            <li
              key={email}
              data-testid={email}
              className="flex items-center"
            >
              <span className="w-full">{email}</span>
              { !isChecklistComplete && (
                <Button
                  data-testid={`remove-${email}`}
                  variant="text"
                  className="w-1/3 ml-4"
                  onClick={removeCarrierContactEmail(email)}
                >
                  <DeleteIcon />
                </Button>
              )}
            </li>
          ))}
        </ul>
      }
      <FormControl component="fieldset" className="w-full mt-4">
        <TextField
          variant="outlined"
          label="Contact Phone (optional)"
          name="carrierContactPhone"
          value={carrierContactPhone ?? ''}
          onChange={handleContactPhone}
          onBlur={handleBlur(validations.carrierContactPhone, setHasPhoneError)}
          fullWidth
          data-testid="cobra-plan-rates-contact-phone"
          inputProps={ { maxLength: 14 } }
          error={hasPhoneError}
          helperText={hasPhoneError && validations.carrierContactPhone}
          disabled={isChecklistComplete}
        />
      </FormControl>
    </>
  );
}

export default StepTwo;
