import { Response } from 'miragejs';

function routing(server) {
  server.get('/companies/:companyId/direct-debit', (schema) => {
    const { models } = schema.debits.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.post('/companies/:companyId/direct-debit', () => {
    return new Response(204, { 'content-length': 0 }, {});
  })
}

export default routing;
