import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import BarChartIcon from '@mui/icons-material/BarChart';
import BookIcon from '@mui/icons-material/Book';
import AdministrativeBillingIcon from '@mui/icons-material/Description';
import DocumentLibraryIcon from '@mui/icons-material/MenuBook';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { NavConfig } from 'navigation/navigation.types';

const sidebarNav: NavConfig[] = [
  {
    path: '/scratchpad',
    name: 'Scratchpad',
    icon: DesktopWindowsIcon,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: WebOutlinedIcon,
  },
  {
    path: '/reenrollment-checklist',
    name: 'Re-Enrollment Checklist',
    icon: CheckBoxOutlinedIcon,
  },
  {
    path: '/open-enrollment-resources',
    name: 'Open Enrollment Resources',
    icon: BookIcon,
  },
  {
    path: '/file-upload',
    name: 'File Upload',
    icon: PublishOutlinedIcon,
  },
  {
    path: '/document-library',
    name: 'Document Library',
    icon: DocumentLibraryIcon,
  },
  {
    path: '/administrative-billing',
    name: 'Administrative Billing',
    icon: AdministrativeBillingIcon,
  },
  {
    path: '/add-participant',
    name: 'Add New Participant',
    icon: PersonAddIcon,
  },
  {
    path: '/terminate-participant',
    name: 'Terminate Participant',
    icon: DeleteIcon,
  },
  {
    path: '/reports',
    name: 'Reports',
    icon: BarChartIcon,
  },
  {
    path: '/report-history',
    name: 'Report History',
    icon: AccessTimeOutlinedIcon,
  },
  {
    path: '/file-history',
    name: 'File History',
    icon: AccessTimeOutlinedIcon,
  },
  {
    path: '/perform-direct-debit',
    name: 'Perform Direct Debit',
    icon: CreditCardIcon,
  },
  {
    path: '/rates-history',
    name: 'Rates History',
    icon: WorkHistoryOutlinedIcon,
  },
  {
    path: '/enter-cobra-initial-notice',
    name: 'Enter COBRA Initial Notice',
    icon: EditOutlinedIcon,
  },
  {
    path: '/enter-cobra-qe/personal-details',
    name: 'Enter COBRA QE',
    icon: EditOutlinedIcon,
  },
  {
    path: '/direct-bill-event/personal-details',
    name: 'Start Retiree/LOA Billing',
    icon: EditOutlinedIcon,
  },
  {
    path: '/leave-records',
    name: 'Search Leave Cases',
    icon: ManageSearchIcon,
  },
  {
    path: '/enter-leave-request/personal-details',
    name: 'Enter Leave Request',
    icon: EditOutlinedIcon,
  },
];

export default sidebarNav;

