import request from '../api-request';
import { AdminInvoice } from 'administrative-billing/administrative-billing.types';

export function getAdministrativeBillingInvoices(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get administrative invoices for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/administrative-billing/`;

  return request<AdminInvoice[]>(uri);
}

export function downloadAdministrativeBillingInvoice(companyId: number, invoiceNumber: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot print administrative invoice for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/administrative-billing/${invoiceNumber}/print`;

  return request<string>(uri);
}
