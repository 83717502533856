import dayjs from 'dayjs';

import { PlanServiceSummary } from 'reports-tile/reports-tile.types';
import { ReportType } from 'reports/reports.types';
import request, { ContentType } from '../api-request';
import { BalanceAccountCount } from 'reports/balance/balance-account.types';

export function getReportTypes(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load report types for invalid company.'));
  }

  return request<ReportType[]>(`/api/companies/${companyId}/reports/report-types`);
}

export function downloadReimbursementReport(
  companyId: number,
  planYearId: number,
  planCode: number,
  custom: boolean,
  startDate: string,
  endDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download report for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download report for invalid plan year.'));
  }

  const exportUri = `/api/companies/${companyId}/reports/plan-years/${planYearId}/services/${planCode}/${custom}/`
    + `export?startDate=${startDate}&endDate=${endDate}`;

  return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function downloadReimbursementRecapReport
(
  companyId: number,
  planYearId: number,
  planCode: number,
  startDate: string,
  endDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download report for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download report for invalid plan year.'));
  }

  const exportUri = `/api/companies/${companyId}/reports/reimbursement-report/`
    + `${planYearId}/${planCode}/export?startDate=${startDate}&endDate=${endDate}`;

  return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function downloadNonPlanYearParticipantReport(
  companyId: number,
  type: 'retiree' | 'loa',
  startDate: string,
  endDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download report for invalid company.'));
  }

  const exportUri = `/api/companies/${companyId}/reports/${type}-participants/`
    + `export?startDate=${startDate}&endDate=${endDate}`;

  return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function downloadBalanceByLocationReport
(
  companyId: number,
  planYearId: number,
  planCode: number,
  startDate: string,
  endDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download balance by location report for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download balance by location report for invalid plan year.'));
  }

  const exportUri = `/api/companies/${companyId}/reports/balance-report-location/`
    + `${planYearId}/${planCode}/export?startDate=${startDate}&endDate=${endDate}`;

  return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function downloadBalanceByPayFrequencyReport
(
  companyId: number,
  planYearId: number,
  planCode: number,
  startDate: string,
  endDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download balance by pay frequency report for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download balance by pay frequency report for invalid plan year.'));
  }

  const exportUri = `/api/companies/${companyId}/reports/balance-report-pay-frequency/`
    + `${planYearId}/${planCode}/export?startDate=${startDate}&endDate=${endDate}`;

  return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function getServiceSummaries(companyId: number, planYearId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load service data for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot load service data for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/plan-years/${planYearId}/service-summaries`;

  return request<PlanServiceSummary[]>(uri);
}

export function getEnrollmentSummary(
  companyId: number,
  planYearId: number,
  planCodeId: number,
  isCustom: boolean,
  startDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load enrollment data for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot load enrollment data for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/plan-years/${planYearId}/enrollments/${planCodeId}/${isCustom}` +
    `?startDate=${startDate}`;

  return request<number>(uri);
}

export function downloadEnrollmentSummary(
  companyId: number,
  planYearId: number,
  planCodeId: number,
  isCustom: boolean,
  startDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load enrollment data for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot load enrollment data for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/plan-years/${planYearId}/enrollments/${planCodeId}/${isCustom}` +
    `/export?startDate=${startDate}`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function getBalanceReportCounts(
  companyId: number,
  planYearId: number,
  planCodeId: number,
  startDate: string,
  endDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load balance report data for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot load balance report data for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/balance-account-counts/${planYearId}/${planCodeId}` +
    `?startDate=${startDate}&endDate=${endDate}`;

  return request<BalanceAccountCount>(uri);
}

export function getReductionListTotal(
  companyId: number,
  planYearId: number,
  planCodeId: number,
  payDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load reduction list total for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot load reduction list total for invalid plan year.'));
  }

  const date = dayjs(payDate, 'YYYY-MM-DD');

  if (!date.isValid()) {
    return Promise.reject(new Error('Cannot load reduction list total for invalid deduction date.'));
  }

  const uri = `/api/companies/${companyId}/reports/plan-years/${planYearId}/reduction-list-total/${planCodeId}` +
    `?payDate=${date.format('YYYY-MM-DD')}`;

  return request<number>(uri);
}

export function downloadReductionList(
  companyId: number,
  planYearId: number,
  planCodeId: number,
  payDate: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download reduction list report for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download reduction list report for invalid plan year.'));
  }

  const date = dayjs(payDate, 'YYYY-MM-DD');

  if (!date.isValid()) {
    return Promise.reject(new Error('Cannot download reduction list report for invalid deduction date.'));
  }

  const uri = `/api/companies/${companyId}/reports/plan-years/${planYearId}/reduction-list/${planCodeId}/export` +
    `?payDate=${date.format('YYYY-MM-DD')}`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function getDebitCardWarningsTotal(
  companyId: number,
  planYearId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load debit card warnings total for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot load debit card warnings total for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/debit-card-warnings/${planYearId}`;

  return request<number>(uri);
}

export function downloadDebitCardWarnings(
  companyId: number,
  planYearId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download debit card warnings report for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download debit card warnings report for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/debit-card-warnings/${planYearId}/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function getUnsubstantiatedDebitCardTotal(
  companyId: number,
  planYearId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load unsubstantiated debit card total for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot load unsubstantiated debit card total for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/unsubstantiated-debit-card/${planYearId}`;

  return request<number>(uri);
}

export function downloadUnsubstantiatedDebitCard(
  companyId: number,
  planYearId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download unsubstantiated debit card report for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download unsubstantiated debit card report for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/unsubstantiated-debit-card/${planYearId}/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}
