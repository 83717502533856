import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import request, { APIOptions } from 'shared/api-request';
import { QeEventType } from 'enter-cobra-qe/cobra-qe.types';
import { useErrorNotifier } from './useNotifiers';
import { selectSelectedCompany } from 'companies/companies.slice';

export function useEventTypes() {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };

  const [ eventTypes, setEventTypes ] = useState<QeEventType[] | null>(null);

  const { current: abortCtrl } = useRef(new AbortController());
  const { current: setDefault } = useRef(compose(setEventTypes, always([])));
  const { current: handleError } = useRef(compose(
    tap(setDefault),
    useErrorNotifier(),
  ));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    const uri = `/api/companies/${compid}/cobra-qe/cobra-event-types`;
    const apiOpts: APIOptions = { method: 'GET', signal: abortCtrl.signal };
    request<QeEventType[]>(uri, apiOpts)
      .then((result) => {
        if (mountedRef.current) {
          setEventTypes(result ?? []);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
      abortCtrl.abort();
    };
  }, [ handleError, abortCtrl, compid ]);

  return eventTypes;
}
