import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { always, compose, tap } from 'ramda';
import dayjs from 'dayjs';

import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import ReportsTile from 'reports/shared/ReportsTile';
import {
  getBalanceReportCounts,
  downloadReimbursementReport,
  downloadReimbursementRecapReport,
  downloadBalanceByLocationReport,
  downloadBalanceByPayFrequencyReport,
} from 'shared/api/reportsApi';
import { BalanceAccountCount } from 'reports/balance/balance-account.types';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import ReportDateRangeFilter from 'reports/shared/ReportDateRangeFilter';

interface ReportsByDateRangeProps {
  selectedPlan?: OpenPlan;
}

function ReportsByDateRange({ selectedPlan }: ReportsByDateRangeProps) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ startDate, setStartDate ] = useState<string | null>(dayjs().format('MM/DD/YYYY'));
  const [ endDate, setEndDate ] = useState<string | null>(dayjs().format('MM/DD/YYYY'));
  const [ counts, setCounts ] = useState<BalanceAccountCount | null>(null);
  const [ downloadingBalance, setDownloadingBalance ] = useState(false);
  const [ downloadingReimbursement, setDownloadingReimbursement ] = useState(false);
  const [ downloadingBalanceByLocation, setDownloadingBalanceByLocation ] = useState(false);
  const [ downloadingBalanceByPayFrequency, setDownloadingBalanceByPayFrequency ] = useState(false);

  const Counts = withLoopIcon(makeCountTiles, '', !!selectedPlan && !counts && !!startDate && !!endDate);

  const errorNotifier = useErrorNotifier();
  const [ handleError ] = useState(() => compose(
    tap(compose(setCounts, always(null))),
    errorNotifier,
  ));

  useEffect(() => {
    if (startDate && endDate && selectedPlan) {
      setCounts(null);
      getBalanceReportCounts(
        compid,
        selectedPlan.pyrid,
        selectedPlan.plcid,
        startDate,
        endDate,
      )
        .then(setCounts)
        .catch(handleError);
    }
  }, [ compid, endDate, startDate, selectedPlan, handleError ]);

  function handleApply(start: string, end: string) {
    setStartDate(start);
    setEndDate(end);
  }

  function handleBalanceExport() {
    if (selectedPlan) {
      setDownloadingBalance(true);
      downloadReimbursementReport(compid, selectedPlan.pyrid, selectedPlan.plcid, false, startDate || '', endDate || '')
        .catch(handleError)
        .finally(() => setDownloadingBalance(false));
    }
  }

  function handleBalanceByLocationExport() {
    if (selectedPlan) {
      setDownloadingBalanceByLocation(true);
      downloadBalanceByLocationReport(compid, selectedPlan.pyrid, selectedPlan.plcid, startDate || '', endDate || '')
        .catch(handleError)
        .finally(() => setDownloadingBalanceByLocation(false));
    }
  }

  function handleBalanceByPayFrequencyExport() {
    if (selectedPlan) {
      setDownloadingBalanceByPayFrequency(true);
      downloadBalanceByPayFrequencyReport(
        compid,
        selectedPlan.pyrid,
        selectedPlan.plcid,
        startDate || '',
        endDate || '')
        .catch(handleError)
        .finally(() => setDownloadingBalanceByPayFrequency(false));
    }
  }

  function handleReimbursementRecapExport() {
    if (selectedPlan) {
      setDownloadingReimbursement(true);
      downloadReimbursementRecapReport(compid, selectedPlan.pyrid, selectedPlan.plcid,
        startDate || '', endDate || '')
        .catch(handleError)
        .finally(() => setDownloadingReimbursement(false));
    }
  }

  function makeCountTiles() {
    return !counts
      ? <></>
      : (
        <div className="grid grid-cols-4 gap-4 mt-4" data-testid="balance-reports-counts-loaded" >
          <ReportsTile
            count={counts.reimbursement}
            title="Reimbursement Recap"
            description="Monthly Breakdown of total dollars reimbursed in each plan."
            exportDisabled={downloadingReimbursement}
            onExport={handleReimbursementRecapExport}
            downloading={downloadingReimbursement}
          />
          <ReportsTile
            count={counts.balance}
            title="Balance Report"
            description="Live data of contributions and reimbursement per participant per plan."
            exportDisabled={downloadingBalance}
            onExport={handleBalanceExport}
            downloading={downloadingBalance}
          />
          <ReportsTile
            count={counts.balance}
            title="Balances by Location"
            description="Live data of contributions and reimbursements per participant, per plan, per location.
                  Locations are notated by sheets at the bottom of the excel spreadsheet."
            exportDisabled={downloadingBalanceByLocation}
            onExport={handleBalanceByLocationExport}
            downloading={downloadingBalanceByLocation}
          />
          <ReportsTile
            count={counts.balance}
            title="Balances By Pay Frequency"
            description="Live data of contributions and reimbursements per participant, per plan, per pay
                  frequency. Pay frequencies are notated by the sheets at the bottom of the excel spreadsheet."
            exportDisabled={downloadingBalanceByPayFrequency}
            onExport={handleBalanceByPayFrequencyExport}
            downloading={downloadingBalanceByPayFrequency}
          />
        </div>
      );
  }

  return (
    <div>
      <ReportDateRangeFilter onFilterChange={handleApply} />
      <div>
        <Counts />
      </div>
    </div>
  );
}

export default ReportsByDateRange;
