import React from 'react';
import Avatar from '@mui/material/Avatar';

import { Company } from './company.types';
import styles from './CompanyAvatar.module.css';

interface CompanyAvatarProps {
  company: Company | null;
  className?: string;
}

function CompanyAvatar({ company, className = '' }: CompanyAvatarProps): React.ReactElement {
  function imgSrc(): string {
    return company?.filepath
      ? `https://admin.flores247.com/fsaforms/logos/${company.filepath}/logo.jpg`
      : '';
  }

  return <Avatar alt={company?.compname} className={className} classes={ { img: styles.img } } src={imgSrc()} />;
}

export default CompanyAvatar;
