import React, { ReactElement } from 'react';
import dayjs from 'dayjs';

import { Props } from './plan-enrollment-summary.types';
import Button from '@mui/material/Button';
import { PlanLevel, planLevelMap } from 'plan-enrollment/plan-enrollment.types';

const buttonText = 'Save Enrollment';

interface DefinitionProps {
  dd: string;
  dt: string;
}

function Definition({ dd, dt }: DefinitionProps) {
  return (
    <>
      <dt className="pt-3 text-sm text-med-em">{dt}</dt>
      <dd className="font-bold">{dd}</dd>
    </>
  );
}

/**
 * Generate markup based on discriminated union
 */
function MakeMRAMarkup(props: Props): ReactElement {
  if (props.type === 'MRA') {
    const { payPeriodDeduction, firstPayDateOfChange, partAnn } = props;
    const doc = dayjs(firstPayDateOfChange, 'YYYY-MM-DD').format('MM/DD/YYYY');

    return (
      <>
        <Definition dt="Pay Period Deduction" dd={`$${payPeriodDeduction}`} />
        <Definition dt="Annual Allocation" dd={`$${partAnn}`} />
        <Definition dt="First Pay Date of Change" dd={doc} />
        {
          props.lfsa
            ? <Definition dt="Level" dd={planLevelMap.get(props.planLevel as PlanLevel) ?? ''} />
            : <></>
        }
      </>
    );
  }

  return <></>;
}

function MakeCustomMarkup(props: Props): ReactElement {
  if (props.type === 'CUSTOM') {
    const { coveredDependents } = props;

    return (
      <>
        {
          props.isBalance
            ?
            <>
              <Definition dt="Total Current Contributions" dd={`$${props.currentContributions}`} />
              <Definition dt="Additional Contributions" dd={`$${props.additionalContributions}`} />
            </>
            : <></>
        }
        <dt className="pt-3 text-sm text-med-em">Covered Dependents</dt>
        {coveredDependents.map((m, i) => (<dd className="font-bold" key={i}>{m.name}</dd>))}
      </>
    );
  }

  return <></>;
}

function PlanEnrollmentSummary(props: Props): ReactElement {
  const {
    type,
    partStart,
    partEnd,
    plcdesc,
    yearbeg,
    yearend,
    lfsa = false,
    isEdit = false,
    onCancel,
    onSubmit,
  } = props;
  const start = dayjs(partStart, 'YYYY-MM-DD').format('MM/DD/YYYY');
  const end = dayjs(partEnd, 'YYYY-MM-DD').format('MM/DD/YYYY');
  const yearStart = dayjs(yearbeg, 'YYYY-MM-DD').format('MM/DD/YYYY');
  const yearEnd = dayjs(yearend, 'YYYY-MM-DD').format('MM/DD/YYYY');

  return (
    <>
      <h5>{isEdit && 'Update '}Participant Enrollment</h5>
      <p>
        {`Please review the enrollment information below for accuracy. Once confirmed, click "${buttonText}" \n` +
        ` to add the employee's plan enrollment to the system.`}
      </p>
      <div className="pt-4 pl-2">
        <h6>Summary</h6>
        <dl>
          <dt className="pt-3 text-sm text-med-em">Plan Type</dt>
          <dd className="font-bold">
            <span className="font-medium">{`${yearStart} - ${yearEnd}`}
            </span> {plcdesc}{lfsa ? ' - Limited Purpose' : ''}
          </dd>

          <Definition dt="Begin Date" dd={start} />

          {
            type !== 'HSA' || !!partEnd
              ? <Definition dt="End Date" dd={end} />
              : <></>
          }

          {MakeMRAMarkup(props)}
          {MakeCustomMarkup(props)}
        </dl>
      </div>

      <div className="flex justify-end my-4">
        <Button
          color="primary"
          onClick={onCancel}
          data-testid="cancel"
          className="plan-enrollment-cancel"
          tabIndex={-1}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={onSubmit}
          data-testid="save-enrollment"
          className="plan-enrollment-save ml-4"
          tabIndex={-1}
        >
          {buttonText}
        </Button>
      </div>
    </>

  );
}

export default PlanEnrollmentSummary;
