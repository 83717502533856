import { LeaveRecordCaseInfo } from '../leaveRecord.types';
import { dateOrPlaceholder, valueOrPlaceholder } from 'shared/utils';
import { DateFormat_M_D_YYYY } from 'shared/consts/date-time.consts';

interface Props {
  data?: LeaveRecordCaseInfo | null;
}

export function LeaveRecordPaperworkDatesTab({ data }: Readonly<Props>) {
  const placeholder = '-';

  return (
    <div className="w-full m-4" data-testid="paperwork-dates-tab-panel">
      <h6 className="mb-4">Paperwork Dates</h6>
      <dl>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Initial Paperwork Sent Date</dt>
            <dd className="font-medium">
              { dateOrPlaceholder(data?.paperworkSentDate, DateFormat_M_D_YYYY, placeholder) }
            </dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Initial Paperwork Sent Method</dt>
            <dd className="font-medium">{ valueOrPlaceholder(data?.paperworkSentMethod, placeholder) }</dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Initial Paperwork Due Date</dt>
            <dd className="font-medium">
              { dateOrPlaceholder(data?.paperworkDueDate, DateFormat_M_D_YYYY, placeholder) }
            </dd>
          </div>
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Initial Paperwork Due Date Extension</dt>
            <dd className="font-medium">
              {dateOrPlaceholder(data?.paperworkDueDateExtension, DateFormat_M_D_YYYY, placeholder) }
            </dd>
          </div>
        </div>
        <div className="flex mb-8">
          <div className="w-1/3">
            <dt className="text-sm text-neutral-500">Initial Paperwork Received Date</dt>
            <dd className="font-medium">
              { dateOrPlaceholder(data?.paperworkReceivedDate, DateFormat_M_D_YYYY, placeholder) }
            </dd>
          </div>
        </div>
        <dt className="text-sm text-neutral-500">Touchpoint Notes</dt>
        <dd className="mb-8 font-medium">{ valueOrPlaceholder(data?.touchpointNotes, placeholder) }</dd>
      </dl>
    </div>
  );
}
