import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { any, equals } from 'ramda';
import { Button } from '@mui/material';
import PageContentPaper from 'components/layout/PageContentPaper';
import AddIcon from '@mui/icons-material/Add';

import store from 'store';
import { selectSelectedCompany } from 'companies/companies.slice';
import {
  selectCasesSummary,
  selectCasesSummaryStatus,
  selectEmployeeDetails,
  selectEmployeeDetailsStatus,
} from 'leave-records/leave-records.slice';
import { fetchCasesSummary, fetchEmployeeDetails } from 'leave-records/leave-records.thunk';
import { EmployeeSearchResults } from 'leave-records/leaveRecord.types';
import { LeaveRecordsTable } from 'leave-records/components/LeaveRecordsTable';
import { LeaveRecordsSearchInput } from 'leave-records/components/LeaveRecordsSearchInput';
import { LeaveRecordsEmployeeHeader } from 'leave-records/components/LeaveRecordsEmployeeHeader';
import { LeaveRecordsPageHeader } from 'leave-records/components/LeaveRecordsPageHeader';
import Loading from 'components/shared/Loading';

export function LeaveRecordsByEmployee() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();
  const companyId = useSelector(selectSelectedCompany)?.compid ?? 0;
  const { eeId } = useParams<{ eeId: string; }>();
  const allLeaveCases = useSelector(selectCasesSummary);
  const employeeDetails = useSelector(selectEmployeeDetails);
  const employeeSummary: EmployeeSearchResults = {
    firstName: employeeDetails?.firstName ?? '',
    lastName: employeeDetails?.lastName ?? '',
    eeId: employeeDetails?.eeId ?? '',
    taxIdLast4: employeeDetails?.taxIdLast4 ?? null,
  };
  const employeeDetailsStatus = useSelector(selectEmployeeDetailsStatus);
  const casesSummaryStatus = useSelector(selectCasesSummaryStatus);
  const isLoading = any(equals('pending'))([ employeeDetailsStatus, casesSummaryStatus ]);

  function onViewReportAbsence() {
    history.push(`/leave-records/${eeId}/absence-report`);
  }

  function onEnterLeaveRequest() {
    history.push(`/enter-leave-request/personal-details`);
  }

  useEffect(() => {
    dispatch(fetchEmployeeDetails({ companyId, eeId }));

    dispatch(fetchCasesSummary({ companyId, eeId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ eeId ]);

  return (
    <div className="w-full mt-4 mb-8 px-8" data-testid="leave-records-by-employee-page">
      <LeaveRecordsPageHeader currentRoute="all-cases" eeId={eeId} />
      <PageContentPaper className="mb-4">
        <LeaveRecordsSearchInput />
      </PageContentPaper>
      <div className="flex justify-between items-center">
        <LeaveRecordsEmployeeHeader employeeSummary={employeeSummary} />
        <div className="flex mb-4">
          <Button
            data-testid="view-report-absence-btn"
            className="mr-4 whitespace-nowrap"
            size="small"
            variant="outlined"
            onClick={onViewReportAbsence}
          >
            View/Report Absence
          </Button>
          <Button
            data-testid="enter-leave-request-btn"
            className="whitespace-nowrap"
            size="small"
            variant="contained"
            onClick={onEnterLeaveRequest}
            startIcon={<AddIcon />}
          >
            Enter Leave Request
          </Button>
        </div>
      </div>
      <PageContentPaper className="p-0">
        <LeaveRecordsTable cases={allLeaveCases} />
      </PageContentPaper>
      {
        isLoading && (
          <Loading />
        )
      }
    </div>
  );
}
