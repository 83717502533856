import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import styles from './NotFound.module.css';
import notFoundImg from 'images/404.svg';
import PageContentPaper from 'components/layout/PageContentPaper';

function NotFoundPage(): React.ReactElement {
  const history = useHistory();

  function back() {
    history.goBack();
  }

  return (
    <PageContentPaper className="m-4">
      <div className="lg:flex flex-row justify-between">
        <div className="flex-shrink order-2">
          <img src={notFoundImg} alt="" className="w-full" />
        </div>
        <div className="flex flex-col order-1 w-full lg:w-1/2 justify-between p-10 align-middle">
          <Typography variant="h1" className={`${styles.oops} font-bold`}>Oops!</Typography>
          <Typography variant="h4" color="primary" className="">Page Not Found</Typography>
          <Typography variant="body1" className="">
          Sorry we're unable to find the page you're looking for.
          </Typography>
          <Button variant="contained" color="primary" className="text-uppercase mt-10" onClick={back}>
            Go back
          </Button>
        </div>
      </div>
    </PageContentPaper>
  );
}

export default NotFoundPage;
