import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil, omit, pick, path, clone, clamp } from 'ramda';
import dayjs, { Dayjs } from 'dayjs';

import { RootState } from 'store/rootReducer';
import {
  DBBenefit,
  DBParticipant,
  DirectBillingEventType,
  DirectBillingQe,
  DirectBillSavePayload,
  DBDetails,
} from './direct-billing.types';
import { defaultParticipant } from 'shared/constants';
import { AppThunk } from 'store';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { getAvailableBenefits, getDirectBillEventTypes, getDirectBillingEvent } from 'shared/api/directBillingApi';
import { Dependent, EventInfo } from 'shared/types/non-plan-year.types';
import { getDependents } from 'shared/api/dependentsApi';

export interface DirectBillingState {
  entry: DirectBillingQe;
  eventTypes: DirectBillingEventType[] | null;
  availableBenefits: DBBenefit[] | null;
}

export const initialState: DirectBillingState = {
  entry: {
    participant: defaultParticipant,
    eventInfo: {
      eventDate: '',
      eventType: -1,
      coverageBegins: '',
    },
    dependents: {
      initial: [],
      added: [],
    },
    benefits: [],
  },
  eventTypes: [],
  availableBenefits: [],
};

const directBillingQeSlice = createSlice({
  name: 'directBillingQe',
  initialState,
  reducers: {
    resetEntry(state) {
      state.entry = { ...initialState.entry };
    },
    setEventTypes(state, { payload }: PayloadAction<DirectBillingEventType[] | null>) {
      state.eventTypes = payload;
    },
    setAvailableBenefits(state, { payload }: PayloadAction<DBBenefit[] | null>) {
      state.availableBenefits = payload;
    },
    setBenefits(state, { payload }: PayloadAction<DBBenefit[]>) {
      state.entry.benefits = payload.filter(benefit => !isNil(benefit.selectedPlan));
    },
    setAddedDependents(state, { payload }: PayloadAction<Dependent[]>) {
      state.entry.dependents.added = payload;
    },
    setInitialDependents(state, { payload }: PayloadAction<Dependent[] | null>) {
      state.entry.dependents.initial = payload;
    },
    setEntry(state, { payload }) {
      state.entry = payload;
    },
    setEventInfo(state, { payload }: PayloadAction<EventInfo>) {
      state.entry.eventInfo = payload;
    },
    setParticipant(state, { payload }:PayloadAction<DBParticipant>) {
      state.entry.participant = payload;
    },
  },
});

export const {
  resetEntry,
  setEventTypes,
  setAddedDependents,
  setInitialDependents,
  setEntry,
  setBenefits,
  setAvailableBenefits,
  setEventInfo,
  setParticipant,
} = directBillingQeSlice.actions;

export default directBillingQeSlice.reducer;

// thunks
export function loadAvailableBenefits(companyId: number, coverageDate?: string | Dayjs): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setAvailableBenefits(null));

    try {
      const coverageBegins: string | Dayjs | undefined = path(
        [ 'directBillingQe', 'entry', 'eventInfo', 'coverageBegins' ],
        getState(),
      );
      const date = dayjs(coverageDate || coverageBegins).format('YYYY-MM-DD');
      const benefitPlans = await getAvailableBenefits(companyId, date);

      const benefitsWithLifeAndOther = benefitPlans?.reduce((
        acc: DBBenefit[],
        benefit: DBBenefit,
      ) => {
        const lifeOrOther = benefit.plans.find(p => [ 6, 8 ].includes(p.planType));

        return lifeOrOther
          ? [ ...acc, ...new Array(clamp(1, 4, benefit.plans.length)).fill(null).map(() => clone(benefit)) ]
          : [ ...acc, benefit ];
      }, []);

      dispatch(setAvailableBenefits(benefitsWithLifeAndOther ?? []));
    } catch (ex) {
      dispatch(setAvailableBenefits([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadInitialDependents(companyId: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setInitialDependents(null));

    try {
      const { directBillingQe: { entry: { participant: { empno } } } } = getState();

      if (empno.length > 0) {
        const dependents = await getDependents(companyId, empno);
        dispatch(setInitialDependents(dependents ?? []));
      }
    } catch (ex) {
      dispatch(setInitialDependents([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadEventTypes(companyId: number): AppThunk {
  return async (dispatch) => {
    dispatch(setEventTypes(null));

    try {
      const types = await getDirectBillEventTypes(companyId);
      dispatch(setEventTypes(types));
    } catch (ex) {
      dispatch(setEventTypes(null));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

export function loadDirectBillingEvent(companyId: number, rQualId: number, employeeNumber: string): AppThunk {
  return async (dispatch) => {
    dispatch(resetEntry());
    dispatch(setAvailableBenefits(null));

    try {
      const entry = await getDirectBillingEvent(companyId, rQualId, employeeNumber);
      dispatch(setEntry(entry));

      const bennies = entry?.benefits ?? [];
      dispatch(setBenefits(bennies));
      dispatch(setAvailableBenefits(bennies));
    } catch (ex) {
      dispatch(setAvailableBenefits([]));
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectDirectBillingQeEntry = (state: RootState): DirectBillingQe => state.directBillingQe.entry;

export const selectDirectBillingParticipant =
  (state: RootState): DBParticipant => state.directBillingQe.entry.participant;

export const selectDirectBillingEventTypes =
  (state: RootState): DirectBillingEventType[] | null => state.directBillingQe.eventTypes;

export const selectDirectBillingEventInfo =
  (state: RootState): EventInfo => state.directBillingQe.entry.eventInfo;

export const selectDirectBillingDetails =
  (state: RootState): DBDetails | undefined => state.directBillingQe.entry.details;

export const selectAvailableBenefits = (state: RootState): DBBenefit[] | null => {
  const { availableBenefits, entry: { benefits: selectedBenefits } } = state.directBillingQe;

  if (availableBenefits === null) {
    return null;
  }

  return availableBenefits.map((available) => {
    const selected = selectedBenefits.find((s) => s.selectedPlan?.planTypeName === available.planTypeName);

    if (!selected) {
      return available;
    }

    return {
      ...omit([ 'plans', 'planTypeName' ], selected),
      ...pick([ 'plans', 'planTypeName' ], available),
    };
  });
};

export const selectDirectBillingBenefits =
  (state: RootState): DBBenefit[] => state.directBillingQe.entry.benefits;

export const selectDirectBillingDependents = (state: RootState): Dependent[] => {
  const { initial, added } = state.directBillingQe.entry.dependents;

  return added.concat(initial ?? []);
};

export const selectDirectBillSavePayload = (state: RootState): DirectBillSavePayload => {
  const {
    r_qualid,
    participant,
    eventInfo,
    benefits,
    dependents: { added: addedDependents },
  } = state.directBillingQe.entry;

  return {
    r_qualid,
    participant,
    eventInfo,
    addedDependents,
    benefits: benefits.map((benefit) => ({
      ...omit([ 'plans', 'planTypeName' ], benefit),
      cost: benefit.cost ?? 0,
      subsidy: benefit.subsidy ?? 0,
    })),
  };
};

export const selectInitialDependents = (state: RootState): Dependent[] | null => {
  const { initial } = state.directBillingQe.entry.dependents;

  return initial;
};

export const selectAddedDependents = (state: RootState): Dependent[] => {
  const { added } = state.directBillingQe.entry.dependents;

  return added;
};
