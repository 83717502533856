import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, tap, always } from 'ramda';

import request, { APIOptions } from 'shared/api-request';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { LocationSelectOption } from 'components/shared/LocationSelect';
import { selectSelectedCompany } from 'companies/companies.slice';
import store from 'store';

export function useLocationOptions() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };

  const [ locations, setLocations ] = useState<LocationSelectOption[] | null>(null);

  const { current: abortCtrl } = useRef(new AbortController());
  const { current: setDefault } = useRef(compose(setLocations, always([])));
  const { current: handleError } = useRef(compose(
    tap(setDefault),
    dispatch,
    addHttpErrorNotification,
  ));
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    const uri = `/api/companies/${compid}/locations`;
    const apiOpts: APIOptions = { method: 'GET', signal: abortCtrl.signal };
    request<LocationSelectOption[]>(uri, apiOpts)
      .then((result) => {
        if (mountedRef.current) {
          setLocations(result ?? []);
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          handleError(err);
        }
      });

    return () => {
      mountedRef.current = false;
      abortCtrl.abort();
    };
  }, [ handleError, abortCtrl, compid ]);

  return locations;
}
