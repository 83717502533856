import { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { always, andThen, anyPass, complement, compose, equals, ifElse, isNil, pathOr } from 'ramda';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  selectDirectBillPlan,
  setDirectBillOeBegins,
  setDirectBillOeEnds,
  setDirectBillResponseDeadline,
  cancelModalOpened,
  selectDirectBillOeValidations,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { saveDirectBillChecklist } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import { noop } from 'shared/utils';
import store from 'store';

function DirectBillGeneral(): ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const {
    directBillOeBegins,
    directBillOeEnds,
    directBillResponseDeadline,
    checklistComplete,
  } = useSelector(selectDirectBillPlan);
  const validations = useSelector(selectDirectBillOeValidations);

  function formatDate(event: any) {
    return dayjs(event, 'YYYY-MM-DD').format();
  }

  const setDate = (date: string) => {
    return date ? dayjs(date, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;
  };

  const isContinueDisabled = anyPass([
    compose(isNil, pathOr(null, [ 'directBillOeBegins' ])),
    compose(isNil, pathOr(null, [ 'directBillOeEnds' ])),
    compose(isNil, pathOr(null, [ 'directBillResponseDeadline' ])),
    complement(compose(isNil, pathOr(null, [ 'validations', 'directBillOeBegins' ]))),
    complement(compose(isNil, pathOr(null, [ 'validations', 'directBillOeEnds' ]))),
    complement(compose(isNil, pathOr(null, [ 'validations', 'directBillResponseDeadline' ]))),
  ])({ directBillOeBegins, directBillOeEnds, directBillResponseDeadline, validations });

  const history = useHistory();
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const changeRoute = compose(history.push, always(`/reenrollment-checklist/direct-bill/${planYearId}/plan-rates`));

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayJS}>
        <form className="my-10">
          <div className="w-2/5 my-5">
            <DatePicker
              label="Direct Bill Open Enrollment Start Date"
              value={setDate(directBillOeBegins)}
              onChange={compose(
                dispatch,
                setDirectBillOeBegins,
                formatDate,
              )}
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  data-testid="directBillOeBegins"
                  variant="outlined"
                  error={!!validations.directBillOeBegins}
                  helperText={validations.directBillOeBegins}
                />
              )}
            />
          </div>
          <div className="w-2/5 my-5">
            <DatePicker
              label="Direct Bill Open Enrollment End Date"
              value={setDate(directBillOeEnds)}
              onChange={compose(
                dispatch,
                setDirectBillOeEnds,
                formatDate,
              )}
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  data-testid="directBillOeEnds"
                  variant="outlined"
                  error={!!validations.directBillOeEnds}
                  helperText={validations.directBillOeEnds}
                />
              )}
            />
          </div>
          <div className="w-2/5 my-5">
            <DatePicker
              label="What date must packets be returned by?"
              value={setDate(directBillResponseDeadline)}
              onChange={compose(
                dispatch,
                setDirectBillResponseDeadline,
                formatDate,
              )}
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  data-testid="directBillResponseDeadline"
                  variant="outlined"
                  error={!!validations.directBillResponseDeadline}
                  helperText={validations.directBillResponseDeadline}
                />
              )}
            />
          </div>
        </form>
      </LocalizationProvider>
      <div className="flex flex-row-reverse">
        <Button
          data-testid="cover-letter-continue"
          variant="contained"
          color="primary"
          onClick={compose(
            ifElse(
              equals(false),
              compose(andThen(changeRoute), dispatch, compose(saveDirectBillChecklist)),
              changeRoute,
            ),
            always(checklistComplete),
          )}
          disabled={isContinueDisabled}
        >Continue
        </Button>
        <Button
          className="mr-4"
          onClick={compose(dispatch, cancelModalOpened, noop)}
        >Cancel
        </Button>
      </div>
    </>
  );
}

export default DirectBillGeneral;
