import { Response } from 'miragejs';
import balanceReportCounts from '../fixtures/balance-report-counts.json';
import { noContentResponse, okResponse } from './utils.js';

function getBalanceCounts(schema) {
  const data = schema.balanceReportCounts.new(balanceReportCounts).attrs;
  return new Response(200, { 'content-length': 1 }, data);
}

function routing(server) {
  const summary = {
    activeParticipants: 4,
    noticesMailed: 12,
    paymentsProcessed: 50,
  };

  server.get('/companies/:companyId/reports/cobra-summary', okResponse(summary));

  server.get('/companies/:companyId/reports/retiree-summary', okResponse(summary));

  server.get('/companies/:companyId/reports/loa-summary', okResponse(summary));

  server.get('/companies/:companyId/reports/pending-notices/count/qe', okResponse(6));

  server.get('/companies/:companyId/reports/pending-notices/count/initial', okResponse(1));

  server.get('/companies/:companyId/reports/plan-years/:planYearId/service-summaries', (schema, request) => {
    const { models: data } = schema.planYearServiceSummaries
      .where({ pyrid: parseInt(request.params.planYearId) }) ?? [];
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/reports/plan-years/:planYearId/enrollments/:planCodeId/:isCustom', (schema, request) => {
    const enrollment = parseInt(request.queryParams.startDate.replace(/-/g, '')) % 150;
    return new Response(200, { 'content-length': 1 }, { data: enrollment });
  });

  server.get('/companies/:companyId/reports/plan-years/:planYearId/enrollments/:planCodeId/:isCustom/export', noContentResponse);

  server.get('/companies/:companyId/reports/report-types', (schema, request) => {
    const { models } = schema.reportTypes.all();
    const data = models.map((d) => d.attrs.type);
    if (request.params.companyId !== '4075') {
      data.splice(3);
    }
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get(
    '/companies/:companyId/reports/plan-years/:planYearId/reduction-list-total/:planCodeId',
    okResponse(1755.97),
  );

  server.get(
    '/companies/:companyId/reports/plan-years/:planYearId/reduction-list/:planCodeId/export',
    noContentResponse,
  );

  server.get(
    '/companies/:companyId/reports/balance-account-counts/:planYearId/:plcid',
    getBalanceCounts,
  );

  server.get(
    '/companies/:companyId/reports/balance-account-custom-counts/:planYearId/:plcid/:subPlanId',
    getBalanceCounts,
  );

  server.get('/companies/:companyId/reports/contributions-not-forwarded', okResponse(36));

  server.get('/companies/:companyId/reports/contributions-not-forwarded/export', noContentResponse);

  server.get('/companies/:companyId/reports/debit-card-warnings/:planYearId', okResponse(342));

  server.get('/companies/:companyId/reports/debit-card-warnings/:planYearId/export', noContentResponse);

  server.get('/companies/:companyId/reports/unsubstantiated-debit-card/:planYearId', okResponse(245));

  server.get('/companies/:companyId/reports/unsubstantiated-debit-card/:planYearId/export', noContentResponse);

  server.get('/companies/:companyId/reports/active-participants/cobra', okResponse(10));

  server.get('/companies/:companyId/reports/active-participants/cobra/export', noContentResponse);

  server.get('/companies/:companyId/reports/pending-notices/count/:type', okResponse(35));

  server.get('/companies/:companyId/reports/pending-notices/export/:type', noContentResponse);

  server.get('/companies/:companyId/reports/current-active/:type/export', noContentResponse);
}

export default routing;
