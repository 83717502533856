import request, { ContentType } from '../api-request';

export function getAnnualAllocationAuditCount(
  companyId: number, planYearId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load annual allocation audit total for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot load annual allocation audit total for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/annual-allocation/${planYearId}`;

  return request<number>(uri);
}

export function downloadAnnualAllocationAudit(
  companyId: number, planYearId: number,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download annual allocation audit report for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download annual allocation audit report for invalid plan year.'));
  }

  const uri = `/api/companies/${companyId}/reports/annual-allocation/${planYearId}/export`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}
