export interface CobraPlanType {
    unqid: number;
    cobraPlanTypeId: number;
    name: string;
    usage: string;
}

export enum InsurerUpdateMethod
{
  NoneSelected,
  ClientViaFridayReport,
  CarrierByTuesdayFile,
  FloresManually,
  CustomFileTransfer,
  Other,
}

export interface CobraInsurer {
   companyId: number;
   unqid: number;
   cobraInsurerId: number;
   insurer: string;
   address1: string;
   address2: string;
   city: string;
   state: string;
   zip: string;
   phone: string;
   agent: string;
   contact: string;
   contactPhone: string;
   contactEmail: string;
   updateMethod?: InsurerUpdateMethod;
   updateMethodNotes?: string;
   notes?: string;
}
