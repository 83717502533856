import React, { useEffect } from 'react';
import styles from './NonPlanYearServiceSummary.module.css';
import FmlaSummaryTile from './FmlaSummaryTile';
import { fetchFMLAReportCountsForDashboard } from 'reports/fmla/fmla-reports.thunks';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { selectNonFilterableFetchFmlaReportCountsStatus, selectReportsCounts } from 'reports/fmla/fmla-reports-slice';
import { equals } from 'ramda';
import Skeleton from '@mui/material/Skeleton';
import { selectSelectedCompany } from 'companies/companies.slice';

function FmlaSummary(): React.ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const fetchFmlaCountsStatus = useSelector(selectNonFilterableFetchFmlaReportCountsStatus);
  const reportCounts = useSelector(selectReportsCounts);
  const loaded = equals(fetchFmlaCountsStatus, 'fulfilled');

  useEffect(() => {
    dispatch(fetchFMLAReportCountsForDashboard({ compid }));
  }, [ dispatch, compid ]);

  return (
    <>
      {
        !loaded
          ? (
            <Skeleton variant="rectangular" height={396} className="w-1/2" data-testid="fmla-skeleton" />
          )
          : (
            <div className={`${styles.summaryContainer} w-1/2 divide-y`} data-testid="fmla-summary">
              <div className="flex flex-row justify-between align-middle py-2 px-4">
                <p className="text-high-em">Leave Solutions</p>
              </div>
              <div className="flex flex-wrap flex-grow" >
                <FmlaSummaryTile
                  className={`${styles.dataSection} basis-full xl:basis-1/2 px-4 xl:border-r justify-start`}
                  title="Open Cases"
                  reportData={ { compid, reportType: 'open-cases' } }
                  count={reportCounts.allOpenLeaveCaseListCount}
                />
                <FmlaSummaryTile
                  className={`${styles.dataSection} basis-full xl:basis-1/2 border-t xl:border-t-0 justify-start`}
                  title="Intermittent Absences in Past 2 Weeks"
                  reportData={ { compid, reportType: 'intermittent-leave-cases' } }
                  count={reportCounts.intermittentLeaveCaseListCount}
                />
                <FmlaSummaryTile
                  className={`${styles.dataSection} basis-full xl:basis-1/2 border-t xl:border-r justify-start`}
                  title="Action Needed"
                  reportData={ { compid, reportType: 'action-needed' } }
                  count={reportCounts.pendingInfoCount}
                />
                <FmlaSummaryTile
                  className={`${styles.dataSection} basis-full xl:basis-1/2 border-t justify-start`}
                  title="Return to Work Confirmation Needed"
                  reportData={ { compid, reportType: 'rtw-confirmation-needed' } }
                  count={reportCounts.returnToWorkConfirmationCount}
                />
              </div>
            </div>
          )
      }
    </>
  );
}

export default FmlaSummary;
