import React from 'react';
import dayjs from 'dayjs';

interface PayDeductionValueProps {
  beginning: string;
  amount: string;
}

function PayDeductionValue({ beginning, amount }: PayDeductionValueProps): React.ReactElement {
  return (
    <div className="flex flex-row my-2">
      <div className="w-1/2 text-base text-low-em flex flex-col justify-end leading-tight">
        {dayjs(beginning).format('M/D/YYYY')}
      </div>
      <div className="w-1/2 text-2-5/8xl text-high-em font-medium leading-none" >
        {amount}
      </div>
    </div>
  );
}

export default PayDeductionValue;
