import { ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import FormErrorText from 'components/shared/FormErrorText';

interface PlanSelectProps {
  value?: number;
  plans: OpenPlan[];
  onPlanSelected: (planId?: number) => void;
  error: boolean;
  name?: string;
  showSubPlan: boolean;
}

function PlanSelect({
  value = -1,
  plans,
  onPlanSelected,
  error,
  name = 'planId',
  showSubPlan }: PlanSelectProps) {
  function handleChange({ target: { value: val } }: ChangeEvent<{ name?: string; value: unknown; }>) {
    const planId = parseInt(val as string);
    onPlanSelected(planId);
  }

  return (
    <FormControl variant="outlined" className="w-full plan-enrollment-plan-select">
      <Select
        onChange={handleChange as any}
        value={value}
        name={name}
        native
      >
        <option value={-1} />
        {plans.map((p) => (
          <option
            value={p.unqid}
            key={p.unqid}
          >{showSubPlan ? `${p.planYearName} - ${p.subName ?? ''}` : p.planYearName}
          </option>
        ))}
      </Select>
      <FormErrorText show={error} message="Please select a plan" />
    </FormControl>
  );
}

export default PlanSelect;
