import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoopIcon from '@mui/icons-material/Loop';
import { uniq } from 'ramda';

import PageContentPaper from 'components/layout/PageContentPaper';
import withLoopIcon from 'components/shared/HOC/WithLoopIcon';
import HistoryMonthSelect, { HistoryMonthSelectProp } from 'components/shared/HistoryMonthSelect';
import ReportHistoryTable from './ReportHistoryTable';
import {
  loadReportMonthYears,
  loadReports,
  selectReportMonthYears,
  selectReports,
  selectSelectedMonthYear,
  setSelectedMonthYear,
  selectSelectedReportType,
  setSelectedReportType,
} from 'report-history/report-history.slice';
import { getYearAndMonth } from 'shared/utils';
import HistoryReportTypeSelect, { ReportTypeSelectProps } from 'components/shared/HistoryReportTypeFilter';
import store from 'store';

function ReportHistory() {
  const monthYears = useSelector(selectReportMonthYears);
  const reports = useSelector(selectReports);
  const selectedMonthYear = useSelector(selectSelectedMonthYear);
  const selectedReportType = useSelector(selectSelectedReportType);
  const [ reportTypes, setReportTypes ] = useState<string[]>([]);

  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    const uniqueReportTypes = uniq(
      reports?.map(
        (report) => report.file_name.substring(0, report.file_name.lastIndexOf(' ')),
      ) ?? [],
    );
    setReportTypes(uniqueReportTypes);
  }, [ reports ]);

  useEffect(() => {
    dispatch(loadReportMonthYears());
  }, [ dispatch ]);

  useEffect(() => {
    if (selectedMonthYear) {
      dispatch(loadReports(selectedMonthYear.year, selectedMonthYear.month));
    }
  }, [ dispatch, selectedMonthYear ]);

  function handleMonthChange(monthYear: string) {
    const yearAndMonth = getYearAndMonth(monthYear);
    dispatch(setSelectedMonthYear(yearAndMonth));
  }

  function handleReportTypeChange(type: string) {
    dispatch(setSelectedReportType(type || null));
  }

  function content(): React.ReactElement {
    if (reports === null) {
      return (
        <div className="flex justify-center items-center flex-grow report-history-files-loading">
          <LoopIcon className="animate-spin" data-testid="reportLoadingIcon" />
        </div>
      );
    }

    return (
      <ReportHistoryTable reportType={selectedReportType} reportHistory={reports} />
    );
  }

  const ReportTypes = withLoopIcon<ReportTypeSelectProps>(
    HistoryReportTypeSelect,
    'Loading Report Types...',
    reports === null,
    'report-types-loading',
  );

  const ReportHistoryFiles = withLoopIcon<HistoryMonthSelectProp>(
    HistoryMonthSelect,
    'Loading Report History...',
    monthYears === null,
    'report-history-months-loading',
  );

  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">Report History</h2>
      <div className="w-full md:w-2/3">
        <PageContentPaper className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div>
              <ReportHistoryFiles
                monthYears={monthYears!}
                onMonthYearChanged={handleMonthChange}
                value={selectedMonthYear ? `${selectedMonthYear.year}-${selectedMonthYear.month}` : ''}
              />
            </div>
            <div>
              <ReportTypes
                reportTypes={reportTypes}
                onReportTypeChanged={handleReportTypeChange}
                value={selectedReportType}
              />
            </div>
          </div>
          <div className="flex flex-wrap xl:divide-x flex-grow" >{content()}</div>
        </PageContentPaper>
      </div>
    </div>
  );
}

export default ReportHistory;
