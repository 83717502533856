import React from 'react';

import { debounce } from 'shared/utils';

interface WrappedComponentProps {
  onChange: (...args: any[]) => void;
}

interface WithDebounceProps {
  onDebouncedChange: (value: string) => void;
}

function withDebounce<P extends WrappedComponentProps>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>, timeout = 250,
) {
  function WithDebounce({ onDebouncedChange, ...props }: WithDebounceProps & Omit<P, 'onChange'>): React.ReactElement {
    function handleChange(value: string) {
      onDebouncedChange(value);
    }

    const debouncedHandler = debounce(handleChange, timeout);

    return (
      // @ts-expect-error while this compiles and works, TypeScript can't sort out the typing how I need it to for complex reasons
      <WrappedComponent
        onChange={debouncedHandler}
        {...props}
      />
    );
  }

  const wrappedComponentName = WrappedComponent.displayName ?? WrappedComponent.name;
  WithDebounce.displayName = `withDebounce(${wrappedComponentName})`;

  return WithDebounce;
}

export default withDebounce;
