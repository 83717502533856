import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { always, compose, tap } from 'ramda';

import ReportsTile from 'reports/shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';
import {
  downloadUnsubstantiatedDebitCard,
  getUnsubstantiatedDebitCardTotal,
} from 'shared/api/reportsApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

interface UnsubstantiatedDebitCardProps {
  selectedPlan?: OpenPlan;
}

function UnsubstantiatedDebitCard({ selectedPlan }: UnsubstantiatedDebitCardProps) {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const [ count, setCount ] = useState<number | null>(0);
  const [ downloading, setDownloading ] = useState(false);

  const errorNotifier = useErrorNotifier();
  const [ handleError ] = useState(() => compose(
    tap(compose(setCount, always(0))),
    errorNotifier,
  ));

  useEffect(() => {
    if (selectedPlan) {
      setCount(null);
      getUnsubstantiatedDebitCardTotal(
        compid,
        selectedPlan.pyrid,
      )
        .then(setCount)
        .catch(handleError);
    }
  }, [ selectedPlan, compid, handleError ]);

  function handleExport() {
    if (selectedPlan) {
      setDownloading(true);
      downloadUnsubstantiatedDebitCard(compid, selectedPlan.pyrid)
        .catch(handleError)
        .finally(() => setDownloading(false));
    }
  }

  return (
    <ReportsTile
      count={count}
      title="Unsubstantiated Debit Card"
      description="List of unsubstantiated debit card changes."
      variant="primary-main"
      exportDisabled={downloading}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default UnsubstantiatedDebitCard;
