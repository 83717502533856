import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReportsTile from 'reports/shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import {
  downloadContributionsNotForwarded,
} from 'shared/api/contributionsNotForwardedApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { loadContributionsNotForwardedCount, selectContributionsNotForwardedCount } from 'reports/reports.slice';
import store from 'store';

function ContributionsNotForwarded() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const count = useSelector(selectContributionsNotForwardedCount);
  const [ downloading, setDownloading ] = useState(false);

  useEffect(() => {
    dispatch(loadContributionsNotForwardedCount(compid));
  }, [ dispatch, compid ]);

  const errorNotifier = useErrorNotifier();

  function handleExport() {
    setDownloading(true);
    downloadContributionsNotForwarded(compid)
      .catch(errorNotifier)
      .finally(() => setDownloading(false));
  }

  return (
    <ReportsTile
      count={count}
      title="HSA Contributions Not Forwarded"
      description="This report lists contributions that are pending deposit
      either due to a failed CIP result, or missing applicant details."
      variant="primary-dark"
      exportDisabled={downloading}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default ContributionsNotForwarded;
