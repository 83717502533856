import request from '../api-request';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';

export function getOpenPlans(companyId?: number) {
  if (!companyId || companyId <= 0) {
    return Promise.reject(new Error('Cannot load plans for invalid company'));
  }

  return request<OpenPlan[]>(`/api/companies/${companyId}/open-plans`);
}

export function getOpenCustomPlans(companyId?: number) {
  if (!companyId || companyId <= 0) {
    return Promise.reject(new Error('Cannot load custom plans for invalid company'));
  }

  return request<OpenPlan[]>(`/api/companies/${companyId}/open-plans/custom`);
}

