import React, { ComponentType } from 'react';

import { ReportType } from './reports.types';
import TabPanel from 'components/shared/TabPanel';
import BalanceAccountReports from './balance/BalanceAccountReports';
import CustomReimbursementReports from './custom/CustomReports';
import COBRAReports from './cobra/COBRAReports';
import RetireeBillingReports from './retiree/RetireeBillingReports';
import LoaBillingReports from './loa/LoaBillingReports';
import { FMLAReports } from 'reports/fmla/FMLAReports';
import { FMLAMarketingTabPanel } from './fmla/FMLAMarketingTabPanel';

const tabTypeMap: Map<ReportType, ComponentType<React.PropsWithChildren<unknown>>> = new Map([
  [ 'balance', BalanceAccountReports ],
  [ 'cobra', COBRAReports ],
  [ 'retiree', RetireeBillingReports ],
  [ 'loa', LoaBillingReports ],
  [ 'custom', CustomReimbursementReports ],
  [ 'fmla', FMLAReports ],
  [ 'fmla-marketing', FMLAMarketingTabPanel ],
]);

interface ReportsTabPanelsProps {
  reportTypes: ReportType[];
  activeTab: ReportType;
}

function ReportsTabPanels({ reportTypes, activeTab }: ReportsTabPanelsProps) {
  function renderComp(type: ReportType) {
    const Comp = tabTypeMap.get(type);

    return Comp ? <div data-testid={`${type}-tab-panel`}><Comp /></div> : <></>;
  }

  return (
    <>
      {
        reportTypes.map((type) => {
          return (
            <TabPanel value={activeTab} index={type} key={`${type}-tab-panel`}>
              { activeTab === type ? renderComp(type) : <></> }
            </TabPanel>
          );
        })
      }
    </>
  );
}

export default ReportsTabPanels;
