import { useState, ChangeEvent } from 'react';
import { useParams } from 'react-router';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { DocumentLibraryType } from './document-library.types';
import DocumentLibraryTabPanels from './DocumentLibraryTabPanels';
import styles from './DocumentLibraryTabs.module.css';

const tabTypeMap: Map<DocumentLibraryType, string> = new Map([
  [ 'forms', 'FORMS' ],
  [ 'legal', 'LEGAL' ],
  [ 'education', 'EDUCATION' ],
]);

function DocumentLibraryTabs() {
  const documentLibraryTabs = [ 'forms', 'legal', 'education', 'videos' ] as DocumentLibraryType[];
  const { tab } = useParams<{tab?: DocumentLibraryType;}>();
  const [ activeTab, setActiveTab ] = useState<DocumentLibraryType>(tab ?? documentLibraryTabs[0]);

  function onChangeTab(e: ChangeEvent<{}>, newTab: DocumentLibraryType) {
    if (newTab !== activeTab) {
      setActiveTab(newTab);
    }
  }

  return (
    <div className="w-full">
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        className="mb-4"
        value={activeTab}
        onChange={onChangeTab}
        classes={ { flexContainer: styles.tabsBottomBoder } }
      >
        {
          documentLibraryTabs.map((type) => (
            <Tab value={type} label={tabTypeMap.get(type)} key={`${type}-tab`} className="min-w-0 max-w-none" />
          ))
        }
      </Tabs>
      <DocumentLibraryTabPanels
        documentLibraryTypes={documentLibraryTabs}
        activeTab={activeTab}
      />
    </div>
  );
}

export default DocumentLibraryTabs;
