import allowableDependentCareExpenses from 'images/allowable-dependent-care-expenses.svg';
import calendarOfEvents from 'images/calendar-of-events.svg';
import claimProcessFlowChart from 'images/claim-process-flow-chart.svg';
import fedForm2441ChildAndDependentCareExpenses from 'images/fed-form-2441-child-and-dependent-care-expenses.svg';
import fsaStoreEligibilityList from 'images/fsa-store-eligibility-list.svg';
import guideToAllowableStatusChanges from 'images/guidee-to-allowable-status-changes.svg';
import guideToAllowableOTCExpenses from 'images/guide-to-allowable-otc-expenses.svg';
import guideToReimbursableDependentCareExpenses from 'images/guide-to-reimbursable-dependent-care-expenses.svg';
import hipaaPrivacyStandards from 'images/hipaa-privacy-standards.svg';
import ninetyPercentMerchantList from 'images/90-merchant-list.svg';
import commuterBenefitAccountGuide from 'images/commuter-benefit-account-guide.svg';
import dependentCareFSAGuide from 'images/dependent-care-fsa-guide.svg';
import directDeposit from 'images/direct-deposit.svg';
import healthCareFSAGuide from 'images/health-care-fsa-guide.svg';
import allowableMedicalExpenses from 'images/allowable-medical-expenses.svg';
import iiasApproved from 'images/iias-approved.svg';
import reimbursableMedicalExpenses from 'images/reimbursable-medical-expenses.svg';
import defaultImage from 'images/default.svg';
import dashTraining from 'images/dash-training-manual.svg';

const imageMap = new Map<string, string>([
  [ '90% merchant list', ninetyPercentMerchantList ],
  [ 'allowable dependent care expenses (publication 503)', allowableDependentCareExpenses ],
  [ 'calendar of events', calendarOfEvents ],
  [ 'claim process flow chart', claimProcessFlowChart ],
  [ 'fed form 2441 child and dependent care expenses', fedForm2441ChildAndDependentCareExpenses ],
  [ 'fsa store eligibility list', fsaStoreEligibilityList ],
  [ 'guide to allowable otc expenses', guideToAllowableOTCExpenses ],
  [ 'guide to allowable status changes', guideToAllowableStatusChanges ],
  [ 'guide to reimbursable dependent care expenses', guideToReimbursableDependentCareExpenses ],
  [ 'hipaa privacy standards', hipaaPrivacyStandards ],
  [ 'hipaa security statement', hipaaPrivacyStandards ],
  [ 'commuter benefit account guide', commuterBenefitAccountGuide ],
  [ 'dependent care fsa guide', dependentCareFSAGuide ],
  [ 'direct deposit', directDeposit ],
  [ 'direct deposit authorization 2019', directDeposit ],
  [ 'direct deposit authorization 2020', directDeposit ],
  [ 'direct deposit authorization 2021', directDeposit ],
  [ 'direct deposit authorization 2022', directDeposit ],
  [ 'direct deposit authorization 2023', directDeposit ],
  [ 'direct deposit authorization', directDeposit ],
  [ 'health care fsa guide', healthCareFSAGuide ],
  [ 'allowable medical expenses (publication 502)', allowableMedicalExpenses ],
  [ 'iias approved vendor list', iiasApproved ],
  [ 'guide to reimbursable medical expenses', reimbursableMedicalExpenses ],
  [ 'default', defaultImage ],
  [ 'dash training manual', dashTraining ],
]);

export function getDocImage(name: string) {
  const key = name.trim().toLocaleLowerCase();

  return imageMap.get(key) ?? imageMap.get('default');
}
