import { mergeLeft, isNil } from 'ramda';

/**
 * Shallow removes properties whose values are nil
 */
export function omitNil<T extends {}> (obj: T): Partial<T> {
  return Object.entries(obj).reduce(
    (acc, [ key, value ]) => isNil(value) ? acc : mergeLeft(acc, { [key]: value }),
    {},
  );
}
