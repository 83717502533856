import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import FileHistoryTableRow from './FileHistoryTableRow';
import { WebFile } from './file-history.types';

interface FileHistoryTableProp {
    webFiles: WebFile[];
    reportType: string | null;
}

function FileHistoryTable({ webFiles, reportType = '' }: FileHistoryTableProp) : React.ReactElement {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          webFiles
            .filter(wf => wf.description?.includes(reportType ?? ''))
            .map((wf) => (
              <FileHistoryTableRow
                webfile={wf}
                key={wf.unqid}
              />
            ))
        }
      </TableBody>
    </Table>
  );
}

export default FileHistoryTable;
