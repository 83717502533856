import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { compose, always } from 'ramda';

import { addHttpErrorNotification, addHttpSuccessNotification } from 'notifications/notifications.slice';
import store from 'store';

/**
 * Get a function that can be used to trigger a success notification snackbar.
 *
 * @param msg this string is bound to the returned function so when you
 * @returns {() => void}
 */
export function useSuccessNotifier(msg: string) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [ successNotifier ] = useState(() => compose(
    dispatch,
    addHttpSuccessNotification,
    always(msg),
  ));

  return successNotifier;
}

/**
 * Get a function that can be used to trigger an error notification snackbar.
 *
 * @returns {(error: Error) => void}
 */
export function useErrorNotifier() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [ errorNotifier ] = useState(() => compose(dispatch, addHttpErrorNotification));

  return errorNotifier;
}
