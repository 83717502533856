import { okResponse } from './utils.js';
import participants from '../fixtures/participants.json';
import eventTypes from '../fixtures/direct-bill-event-types.json';
import benefits from '../fixtures/direct-bill-plans.json';
import dependents from '../fixtures/dependents.json';

function routing(server) {
  server.get('/companies/:companyId/direct-bill/event-types', (schema) => {
    const { models } = schema.directBillEventTypes.all();
    const data = models.map((m) => m.attrs);
    return okResponse(data)();
  });

  server.get('/companies/:companyId/direct-bill/plans', (schema) => {
    const { models } = schema.directBillPlans.all();
    const data = models.map((m) => m.attrs);
    return okResponse(data)();
  });

  server.get('/companies/:companyId/direct-billing-events/:rQualId/employees/:employeeNumber', () => {
    const data = {
      r_qualid: 1,
      participant: participants[1],
      eventInfo: {
        eventDate: '2021-01-01',
        eventType: eventTypes[0].r_qualevid,
        coverageBegins: '2021-01-02',
        paidThrough: '2022-01-02',
      },
      dependents: {
        initial: [dependents[0]],
        added: [],
      },
      benefits: benefits.map((b) => ({ ...b, selectedPlan: b.plans[0], coveredDependents: [dependents[0]] })),
      details: {
        statusCode: 2,
        statusMessage: 'Accepted Coverage',
        letterSent: '',
        postmark: '',
        coverageTermSent: '2014-01-07',
        letterDoc: 27148381,
        responseDoc: 27148381,
        coverageEnds: '2020-12-31',
      },
    };

    return okResponse(data)();
  });
}

export default routing;
