import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from './company.types';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { User } from 'authentication/user.types';
import { assertIsDefined } from 'shared/asserts';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import { getUserCompanies } from 'shared/api/companiesApi';
import { ApiStatus } from '../shared/types/api-status.types';

export interface CompanyState {
  companies: Company[];
  selectedCompany: Company | null;
  loading: ApiStatus;
}

const savedCompany = localStorage.getItem('company');

export const initialState: CompanyState = {
  companies: [],
  selectedCompany: savedCompany ? JSON.parse(savedCompany) : null,
  loading: 'uninitialized',
};

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    loadCompaniesStart(state) {
      state.loading = 'pending';
    },
    loadCompaniesSuccess(state, { payload }: PayloadAction<Company[]>) {
      state.companies = payload;
      state.loading = 'fulfilled';
    },
    loadCompaniesFailed(state) {
      state.loading = 'rejected';
    },
    setSelectedCompany(state, { payload }: PayloadAction<Company>) {
      state.selectedCompany = payload;
    },
  },
});

export const {
  loadCompaniesStart,
  loadCompaniesSuccess,
  loadCompaniesFailed,
  setSelectedCompany,
} = companySlice.actions;

export default companySlice.reducer;

export function getCompaniesForUser(user: User): AppThunk {
  return async (dispatch) => {
    dispatch(loadCompaniesStart());

    try {
      const companies: Company[] | null = await getUserCompanies(user.conuserid);
      assertIsDefined(companies);
      dispatch(loadCompaniesSuccess(companies));
    } catch (ex) {
      dispatch(loadCompaniesFailed());
      dispatch(addHttpErrorNotification(ex));
    }
  };
}

// selectors
export const selectUserCompanies = (state: RootState): Company[] => state.companies.companies;

export const selectSelectedCompany = (state: RootState): Company | null => state.companies.selectedCompany;

export const selectLoadingCompanies = (state: RootState): ApiStatus => state.companies.loading;

export const selectSelectedCompanyId = (state: RootState): number => state.companies.selectedCompany?.compid ?? 0;
