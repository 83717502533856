import { Response } from 'miragejs';

function routing(server) {
  server.get('/file-upload/file-types', (schema) => {
    const { models } = schema.fileTypes.all();
    const data = models.map((m) => m.attrs);
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.post('/file-upload/upload-files/:fileSection/:medplancd', () => {
    return new Response(200, { 'content-length': 1 }, { data: true, message: "File uploaded successfully" });
  });
}

export default routing;
