import { assoc, cond, pathEq, T } from 'ramda';
import { PlanTypesString } from 'shared/enums/plan-types-string.enum';

export const applyPlanTypeDiscriminator = cond([
  [ pathEq([ 'planCode' ], '5'), assoc('type', PlanTypesString.COBRA) ],
  [ pathEq([ 'planCode' ], 'S'), assoc('type', PlanTypesString.HSA) ],
  [ pathEq([ 'planCode' ], '0'), assoc('type', PlanTypesString.CUSTOM) ],
  [ pathEq([ 'planCode' ], '6'), assoc('type', PlanTypesString.DIRECTBILL) ],
  [ pathEq([ 'planCode' ], '7'), assoc('type', PlanTypesString.DIRECTBILL) ],
  [ pathEq([ 'planCode' ], 'D'), assoc('type', PlanTypesString.FSA) ],
  [ pathEq([ 'planCode' ], 'M'), assoc('type', PlanTypesString.FSA) ],
  [ pathEq([ 'planCode' ], '3'), assoc('type', PlanTypesString.HRA) ],
  [ pathEq([ 'planCode' ], 'H'), assoc('type', PlanTypesString.HRA) ],
  [ pathEq([ 'planCode' ], 'T'), assoc('type', PlanTypesString.QTE) ],
  [ T, assoc('type', 'other') ],
]);
