import React from 'react';

import IconButton from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

export interface UserMenuButtonProps {
  name?: string;
  page?: string;
}

function UserMenuButton({ name, page }: UserMenuButtonProps): React.ReactElement {
  const history = useHistory();

  function navigate() {
    navigateToPage(page ?? '');
  }

  function navigateToPage(navToPage: string) {
    history.push(navToPage);
  }

  return (
    <IconButton
      data-testid="user-menu-button"
      variant="outlined"
      className="rounded-full bg-white text-black normal-case"
      onClick={navigate}
      size="large"
    >{name}
    </IconButton>
  );
}

export default UserMenuButton;
