import { ReactElement } from 'react';
import EnrolledPlan from './EnrolledPlan';
import { Enrollment } from './enrollment.types';

export interface ListEnrollmentsProps {
  enrollments: Enrollment[];
  divider: () => ReactElement;
}

function ListEnrollments({ enrollments, divider }: ListEnrollmentsProps): ReactElement {
  const last = enrollments.length - 1;

  function key(e: Enrollment) {
    return `${e.planYearStart}${e.planYearEnd}${e.planName}`;
  }

  return (
    <>
      {
        enrollments.map((enrollment, idx) => (
          <div key={key(enrollment)}>
            <EnrolledPlan enrollment={enrollment} />
            {idx !== last ? divider() : <></>}
          </div>
        ))
      }
    </>
  );
}

export default ListEnrollments;
