import { useState } from 'react';
import { always, compose, isNil } from 'ramda';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import LoopIcon from '@mui/icons-material/Loop';

import GridCard from 'components/shared/GridCard';
import PageContentPaper from 'components/layout/PageContentPaper';
import { useDocumentLibraryEducation } from './hooks/useDocumentLibraryEducation';
import { getDocImage } from '../imageMap';

function EducationDocumentLibrary() {
  const [ active, setActive ] = useState<number | null>(null);
  const educationDocuments = useDocumentLibraryEducation();

  return (
    <PageContentPaper>
      <div className="flex flex-col">
        <div className="w-full flex flex-col p-4">
          <div className="w-full flex pb-4">
            <Chip
              label="All"
              variant={isNil(active) ? undefined : 'outlined'}
              className="cursor-pointer"
              onClick={compose(setActive, always(null))}
            />
            {
              educationDocuments === null
                ? <><LoopIcon className="animate-spin" /> Loading...</>
                : educationDocuments.map((doc) => (
                  <Chip
                    label={doc.year}
                    variant={doc.year === active ? undefined : 'outlined'}
                    key={doc.year}
                    className="ml-2 cursor-pointer"
                    onClick={compose(setActive, always(doc.year))}
                  />
                ))
            }
          </div>
          {
            educationDocuments?.map((doc) => (
              <div>
                {(isNil(active) || active === doc.year) ? (
                  <>
                    <div className="mt-10 mb-10">
                      {doc.planYearName}
                    </div>
                    <Grid className="grid-section" container spacing={3}>
                      {
                        doc.documents.map((d) => (
                          <GridCard
                            src={getDocImage(d.name)}
                            name={d.name}
                            type="PDF"
                            href={d.url}
                          />
                        ))
                      }
                      {
                        <GridCard
                          src={getDocImage('dash training manual')}
                          name={'Dash Training Manual'}
                          type={'PDF'}
                          href={'https://www.flores-associates.com/fsaforms/DASH-Training-Manual-Administrators.pdf'}
                        />
                      }
                    </Grid>
                  </>
                ) : (<></>)}
              </div>
            ))
          }
        </div>
      </div>
    </PageContentPaper>
  );
}

export default EducationDocumentLibrary;
