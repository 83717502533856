import Loading from 'components/shared/Loading';
import ReportsTabs from './ReportsTabs';
import { useReportTypes } from './useReportTypes';

function Reports() {
  const reportTypes = useReportTypes();

  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">Reports</h2>
      <div className="w-full flex">
        {
          reportTypes !== null
            ? <ReportsTabs reportTypes={reportTypes} />
            : <Loading text="Loading report types" />
        }
      </div>
    </div>
  );
}

export default Reports;
