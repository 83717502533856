import Button from '@mui/material/Button';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import styles from './DirectBillingEventInfoForm.module.css';
import { validationSchema } from 'enter-cobra-qe/event-info/event-info.validators';
import EventInfoForm from './DirectBillingEventInfoForm';
import { DirectBillingStepProps } from 'direct-billing/direct-billing.types';
import { selectDirectBillingEventInfo, setEventInfo } from 'direct-billing/direct-billing.slice';
import { useHistory } from 'react-router-dom';
import { EventInfo } from 'shared/types/non-plan-year.types';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import store from 'store';

interface EventInfoValues {
  eventDate?: string | null;
  eventType: string;
  coverageBegins?: string | null;
  paidThrough?: string | null;
}

function mapToForm(eventInfo: EventInfo) {
  return {
    eventDate: eventInfo.eventDate ? dayjs(eventInfo.eventDate).format('YYYY-MM-DD') : null,
    coverageBegins: eventInfo.coverageBegins ? dayjs(eventInfo.coverageBegins).format('YYYY-MM-DD') : null,
    paidThrough: eventInfo.paidThrough ? dayjs(eventInfo.paidThrough).format('YYYY-MM-DD') : null,
    eventType: eventInfo.eventType?.toString() ?? '-1',
  };
}

function mapToStore(values: EventInfoValues) {
  return {
    eventDate: values.eventDate ? dayjs(values.eventDate).format('YYYY-MM-DD') : '',
    coverageBegins: values.coverageBegins ? dayjs(values.coverageBegins).format('YYYY-MM-DD') : '',
    paidThrough: values.paidThrough ? dayjs(values.paidThrough).format('YYYY-MM-DD') : '',
    eventType: parseInt(values.eventType),
  };
}

function DirectBillingEventInfo({ onCancel }: DirectBillingStepProps) {
  const data = useSelector(selectDirectBillingEventInfo);
  const initialValues = useMemo(() => mapToForm(data), [ data ]);
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const form = useFormik<EventInfoValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(setEventInfo(mapToStore(values)));
      history.push(`/direct-bill-event/dependents`);
    },
  });

  return (
    <form data-testid="event-info">
      <div className="flex flex-col space-y-4">
        <p>Enter the Retiree/LOA direct billing event details below.</p>
        <div className={`flex flex-wrap ${styles.twoCol}`}>
          <FormikProvider value={form}>
            <EventInfoForm />
          </FormikProvider>
        </div>
      </div>
      <div className="flex justify-end my-4">
        <Button
          color="primary"
          onClick={onCancel}
          data-testid="cancel"
          tabIndex={-1}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={form.submitForm}
          data-testid="next-step"
          className="ml-4"
          tabIndex={-1}
        >
          Next Step
        </Button>
      </div>
    </form>
  );
}

export default DirectBillingEventInfo;
