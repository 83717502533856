import dayjs from 'dayjs';

import { CRLType } from 'shared/types/non-plan-year.types';
import request, { ContentType } from '../api-request';
import { validateDate } from './validateDate';
import queryStringTag from './reportQueryStringTag';

export function downloadNoticesMailedReport(
  companyId: number,
  type: CRLType,
  startDate?: string,
  endDate?: string,
) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download notices mailed report for invalid company.'));
  }

  try {
    const start = validateDate(
      'Cannot download notices mailed report for invalid start date.',
      startDate ?? dayjs().format(),
    );
    const end = validateDate(
      'Cannot download notices mailed report for invalid end date.',
      endDate ?? dayjs().format(),
    );

    const uri = queryStringTag`/api/companies/${companyId}/reports/notices-mailed/${type}/export${start}${end}`;

    return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
  } catch (err) {
    return Promise.reject(err);
  }
}
