import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useDispatch, useSelector } from 'react-redux';
import { always, compose, either, gt, ifElse, isNil, pathOr, when, length } from 'ramda';
import {
  setCobraPlanOnDeckHasLevels,
  selectOnDeckPlanHasLevels,
  selectOnDeckPlanRatesDocuments,
  selectLevelCoverageTiersOnDeck,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import store from 'store';
import { YesNoChar } from 'shared/enums/generic.enum';

interface Props {
  disabled?: boolean;
}

function RateInformationSelection({ disabled = false }: Props) {
  const dispatch = useDispatch<typeof store.dispatch>();
  const hasRates = always(gt(length(useSelector(selectLevelCoverageTiersOnDeck)), 0));
  const hasFiles = always(gt(length(useSelector(selectOnDeckPlanRatesDocuments)), 0));

  const handleChange = compose(
    dispatch,
    setCobraPlanOnDeckHasLevels,
    pathOr(YesNoChar.NO, [ 'target', 'value' ]),
  );

  const setRateInformationNow = compose(dispatch, setCobraPlanOnDeckHasLevels, always(YesNoChar.YES));
  const setRateInformationUpload = compose(dispatch, setCobraPlanOnDeckHasLevels, always(YesNoChar.NO));

  const hasLevels = compose(
    when(
      isNil,
      ifElse(
        either(hasRates, hasFiles),
        ifElse(
          hasRates,
          setRateInformationNow,
          setRateInformationUpload,
        ),
        setRateInformationNow,
      ),
    ),
  )(useSelector(selectOnDeckPlanHasLevels));

  return (
    <div>
      <p className="font-bold mt-8">Rate Information</p>
      <RadioGroup
        aria-label="Rate Information"
        name="hasLevelsRadioGroup"
        data-testid="rate-selection-input"
        value={hasLevels}
        className="mt-8"
        onChange={handleChange}
      >
        <FormControlLabel
          data-testid="rates-submit-file"
          value={YesNoChar.NO}
          control={<Radio color="primary" disabled={disabled} />}
          label="I will upload a plan rate sheet (for Age Banded plan rates)"
        />
        <FormControlLabel
          data-testid="rates-enter-info"
          value={YesNoChar.YES}
          className="mt-4"
          control={<Radio color="primary" disabled={disabled} />}
          label="I will enter rate information now"
        />
      </RadioGroup>
    </div>
  );
}

export default RateInformationSelection;
