import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { pick, pipe, tap, isNil } from 'ramda';
import { useSelector } from 'react-redux';

import { MRADetails, OpenPlan, PlanEnrollmentFormProps, PlanLevel } from 'plan-enrollment/plan-enrollment.types';
import { handleDateChange } from 'shared/form-helpers';
import { currencyFormatter } from 'shared/utils';
import { validationSchema } from './plan-enrollment-mra.validators';
import { selectParticipant } from 'participant-search/participant.slice';
import PayDateSelect from './PayDateSelect';
import LFSAFields from './LFSAFields';
import { usePayDates } from 'shared/custom-hooks/usePayDates';
import FormErrorText from '../../components/shared/FormErrorText';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface MRAForm extends Partial<MRADetails> {
  payDates?: string[];
  yearBeg: string;
  yearEnd: string;
  limitedPurpose?: boolean;
  level?: string;
  isHra?: boolean;
  allocationMax? : number | null;
}

interface PlanEnrollmentMraProps extends PlanEnrollmentFormProps {
  plan: OpenPlan;
  lfsa: boolean;
  partStart: string; // begin date
  partEnd: string; // end date
  partAnn: number; // annual allocation
  firstPayDateOfChange: string;
  payPeriodDeduction: number;
  totalContributions?: number;
  showLFSA?: boolean;
  planLevel?: PlanLevel;
}

function getAllocationMax(plan?: OpenPlan) {
  if (!plan) {
    return null;
  }

  if (plan.plccode === 'M') {
    return plan.medmax;
  }

  if (plan.plccode === 'D') {
    return plan.depmax;
  }

  return null;
}

function PlanEnrollmentMra({
  plan,
  lfsa = false,
  partStart,
  partEnd,
  partAnn,
  firstPayDateOfChange,
  payPeriodDeduction,
  totalContributions = 0,
  showLFSA = false,
  planLevel = 10,
  isEdit = false,
  onNextStep,
  onCancel,
}: PlanEnrollmentMraProps): React.ReactElement {
  const form = useFormik<MRAForm>({
    initialValues: {
      payDates: [],
      yearBeg: plan.yearbeg,
      yearEnd: plan.yearend,
      partStart,
      partEnd,
      firstPayDateOfChange,
      partAnn: partAnn || undefined, // so the initial 0 value isn't populated
      payPeriodDeduction,
      level: showLFSA ? planLevel.toString() : undefined,
      // let the user select if not flores administered, and it doesn't exist otherwise
      limitedPurpose: showLFSA ? lfsa : undefined,
      isHra: plan.plccode === 'H',
      allocationMax: getAllocationMax(plan),
    },
    validationSchema,
    onSubmit: async (values: MRAForm) => {
      try {
        validationSchema.validateSync(
          {
            ...values,
          },
          {
            abortEarly: false,
            stripUnknown: false,
          },
        );

        if (values.limitedPurpose) {
          onNextStep({
            type: 'MRA',
            lfsa: true,
            planLevel: parseInt(values.level ?? '10') as PlanLevel,
            partStart: values.partStart ?? '',
            partEnd: values.partEnd ?? '',
            partAnn: values.partAnn ?? 0,
            firstPayDateOfChange: values.firstPayDateOfChange ?? '',
            payPeriodDeduction: values.payPeriodDeduction ?? 0,
          });
        } else {
          onNextStep({
            type: 'MRA',
            lfsa: false,
            partStart: values.partStart ?? '',
            partEnd: values.partEnd ?? '',
            partAnn: values.partAnn ?? 0,
            firstPayDateOfChange: values.firstPayDateOfChange ?? '',
            payPeriodDeduction: values.payPeriodDeduction ?? 0,
          });
        }
      } catch (err: any) {
        setWholeFormErrors(err.errors);
      }
    },
  });

  const [ wholeFormErrors, setWholeFormErrors ] = useState<string[]>([]);
  const { payfreq, payfreq2 } = useSelector(selectParticipant) ?? { payfreq: '', payfreq2: '' };
  const [ calculatedDeduction, setCalculatedDeduction ] = useState<string>();

  const payDates = usePayDates((payfreq || payfreq2 || ''), form.values.partStart, plan.yearend);

  function disableNextButton() {
    const fields = [ 'partStart', 'partEnd', 'partAnn' ];

    if (!form.values.isHra) {
      fields.push('payPeriodDeduction');
      fields.push('firstPayDateOfChange');
    }

    const allFilled = Object
      .values(pick(fields, form.values))
      .map((v) => !isNil(v) ? v.toString() : '')
      .filter((v) => isNil(v) || v === '')
      .length === 0;

    return !form.isValid || !allFilled;
  }

  function handlePayDateSelected(payDate: string) {
    form.setFieldValue('firstPayDateOfChange', payDate);
  }

  function calculateAllocation(
    formikForm: typeof form,
  ) {
    return (event: any) => {
      const {
        partAnn: allocation,
        firstPayDateOfChange: firstDate,
      } = formikForm.values;
      const incomingField = typeof event === 'string' ? 'date' : 'allocation';

      if (
        // Make sure the other field is set, and the incoming value
        // meets the type of the other field. Otherwise we won't
        // capture the first change
        (allocation && incomingField === 'date') ||
        (firstDate && incomingField === 'allocation')
      ) {
        // Form.values does not contain the incoming change, so we
        // need to determine which value to take from the form and
        // which to take from the incoming change
        const { dofc, alloc } = incomingField === 'date'
          ? { dofc: event, alloc: allocation }
          : { dofc: firstDate, alloc: event.target.value };
        const index = payDates ? payDates.findIndex(d => d.value === dofc) : -1;

        if (index === -1 || !alloc) {
          setCalculatedDeduction('');

          return;
        }

        const dates = index > -1 ? payDates!.slice(index) : [];

        setCalculatedDeduction(
          ((alloc - totalContributions) / dates.length).toFixed(2),
        );
      }
    };
  }

  function getAnnualAllocationMax() {
    const allocation = 'Annual Allocation';

    if (plan.plccode === 'M') {
      return `${allocation} (Max: ${currencyFormatter.format(plan.medmax)})`;
    } else if (plan.plccode === 'D') {
      return `${allocation} (Max: ${currencyFormatter.format(plan.depmax)})`;
    }

    return allocation;
  }

  return (
    <>
      <h6>{isEdit && 'Update '}Participant Enrollment</h6>
      <p className="my-8">Enter the employee's enrollment by completing the form below.</p>
      <p className="my-8">{plan.planYearName}</p>
      <form className="plan-enrollment-mra-form">
        <div className="w-full flex flex-col space-y-8">
          {
            wholeFormErrors.length
              ? wholeFormErrors.map((m, i) => (
                <FormHelperText
                  className="text-base"
                  key={i}
                  disabled
                  error
                >
                  {m}
                </FormHelperText>
              ))
              : <></>
          }

          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <DatePicker
              label="Begin Date"
              inputFormat="MM/DD/YYYY"
              value={form.values.partStart ? dayjs(form.values.partStart, 'YYYY-MM-DD')
                .format('MM/DD/YYYY') : null}
              onChange={handleDateChange('partStart', form)}
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  name="partStart"
                  onBlur={form.handleBlur}
                  className="w-full"
                  {...params}
                  error={form.touched.partStart && !!form.errors.partStart}
                  helperText={form.touched.partStart && form.errors.partStart}
                />
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <DatePicker
              label="End Date"
              inputFormat="MM/DD/YYYY"
              value={form.values.partEnd ? dayjs(form.values.partEnd, 'YYYY-MM-DD')
                .format('MM/DD/YYYY') : null}
              onChange={handleDateChange('partEnd', form)}
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  name="partEnd"
                  onBlur={form.handleBlur}
                  className="w-full"
                  {...params}
                  error={form.touched.partEnd && !!form.errors.partEnd}
                  helperText={form.touched.partEnd && form.errors.partEnd}
                />
              )}
            />
          </LocalizationProvider>

          <FormControl>
            <TextField
              className="plan-enrollment-deduction"
              variant="outlined"
              name="payPeriodDeduction"
              label="Pay Period Deduction"
              type="number"
              value={form.values.payPeriodDeduction ?? ''}
              error={!!form.touched.payPeriodDeduction && !!form.errors.payPeriodDeduction}
              helperText={
                !!calculatedDeduction &&
                  `Based on the annual allocation and first pay date of change, the pay period deduction should be $${
                    calculatedDeduction
                  }`
              }
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              fullWidth
              InputProps={ {
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              } }
            />
            <FormErrorText show={!!form.errors.payPeriodDeduction} message={form.errors.payPeriodDeduction} />
          </FormControl>

          <TextField
            className="plan-enrollment-annual-allocation"
            variant="outlined"
            name="partAnn"
            label={getAnnualAllocationMax()}
            type="number"
            value={form.values.partAnn ?? ''}
            error={!!form.touched.partAnn && !!form.errors.partAnn}
            helperText={form.touched.partAnn && form.errors.partAnn}
            onChange={pipe(
              tap(calculateAllocation(form)),
                form.handleChange as any,
            )}
            onBlur={form.handleBlur}
            fullWidth
            InputProps={ {
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            } }
          />

          <PayDateSelect
            name="firstPayDateOfChange"
            loading={payDates === null}
            payDates={payDates ?? []}
            selected={form.values.firstPayDateOfChange ?? ''}
            error={!!form.touched.firstPayDateOfChange && !!form.errors.firstPayDateOfChange}
            onPayDateSelected={
              pipe(
                tap(calculateAllocation(form)),
                  handlePayDateSelected as any,
              )
            }
          />

          <LFSAFields showLFSA={showLFSA} floresAdministered={plan.hasFloresHSA && !plan.hasOtherHSA} form={form} />

          <div className="wizard-buttons">
            <Button
              color="primary"
              onClick={onCancel}
              className="plan-enrollment-cancel"
              tabIndex={-1}
            >
                Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="ml-8 plan-enrollment-next"
              onClick={form.submitForm}
              tabIndex={-1}
              disabled={disableNextButton()}
            >
                Next Step
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default PlanEnrollmentMra;
