import { createSlice } from '@reduxjs/toolkit';
import { Dependent } from 'shared/types/non-plan-year.types';
import { fetchDependentsForParticipant } from 'store/slices/dependents/dependents.thunk';
import { ApiStatus } from 'shared/types/api-status.types';
import { RootState } from 'store/rootReducer';

export interface DependentsSlice {
  dependents: Dependent[];
  fetchDependentsForParticipantStatus: ApiStatus;
}

export const initialState: DependentsSlice = {
  dependents: [],
  fetchDependentsForParticipantStatus: 'uninitialized',
};

const dependentsSlice = createSlice({
  name: 'dependentsSlice',
  initialState,
  reducers: {
    resetDependents(state) {
      state.fetchDependentsForParticipantStatus = 'uninitialized';
      state.dependents = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDependentsForParticipant.pending, (state) => {
        state.fetchDependentsForParticipantStatus = 'pending';
      })
      .addCase(fetchDependentsForParticipant.rejected, (state) => {
        state.fetchDependentsForParticipantStatus = 'rejected';
      })
      .addCase(fetchDependentsForParticipant.fulfilled, (state, action) => {
        state.fetchDependentsForParticipantStatus = 'fulfilled';
        state.dependents = action.payload ?? [];
      });
  },
});

export const { resetDependents } = dependentsSlice.actions;

export default dependentsSlice.reducer;

export const selectDependents = (state: RootState) => {
  return state.dependents.dependents;
};

