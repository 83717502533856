import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from '@mui/icons-material/Event';
import { WizardStep } from 'components/shared/WizardStepper';

export type ReportAbsenceStepSlug = 'personal-details' | 'event-info' | 'summary';

const wizardSteps: Map<ReportAbsenceStepSlug, WizardStep> = new Map([
  [
    'personal-details',
    {
      label: 'Personal Details',
      icon: PersonOutlineIcon,
      slug: 'personal-details',
      next: 'event-info',
    },
  ],
  [
    'event-info',
    {
      label: 'Event Info',
      icon: EventIcon,
      slug: 'event-info',
      next: 'summary',
      prev: 'personal-details',
    },
  ],
  [
    'summary',
    {
      label: 'Summary',
      icon: DescriptionIcon,
      slug: 'summary',
      prev: 'event-info',
    },
  ],
]);

export default wizardSteps;
