import React, { useState } from 'react';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { compose, isNil, F, T, tap } from 'ramda';

import { Enrollment } from './enrollment.types';
import PayPeriodDeductions from './PayPeriodDeductions';
import PlanValue, { defaultValueClass } from './PlanValue';
import { currencyFormatter } from 'shared/utils';
import { selectParticipant } from 'participant-search/participant.slice';
import MraTransactions from './view-transactions/MraTransactions';
import HsaTransactions from './view-transactions/HsaTransactions';
import { selectSelectedCompany } from 'companies/companies.slice';
import CoveredDependentBox from './CoveredDependents';
import { CUSTOM_PLCCODE } from 'components/shared/GlobalConstants';
import ViewTransactionButton from './view-transactions/ViewTransactionButton';
import ViewCustomTransactions from 'participant-overview/enrollment/custom-transactions/ViewCustomTransactions';
import { deleteRegularEnrollment, selectDeletingEnrollment } from './enrollments.slice';
import CancelModal from 'components/shared/CancelModal';
import store from 'store';

const cancelPrompt = `Are you sure you want to delete this participant's enrollment? Enrollment should only be deleted
  if it was originally entered in error. If a benefit end date is needing to be added to the participant's account,
  select 'Cancel' and then select 'Update Enrollment' to enter the appropriate benefit end date.`;
interface EnrolledPlanProps {
  enrollment: Enrollment;
}

function EnrolledPlan({ enrollment }: EnrolledPlanProps): React.ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const deleting = useSelector(selectDeletingEnrollment);
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  const [ showCancelModal, setShowCancelModal ] = useState<boolean>(false);
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  const [ isPending, setIsPending ] = useState<boolean>(false);
  const { empno } = useSelector(selectParticipant) ?? { empno: undefined };
  const company = useSelector(selectSelectedCompany);
  const history = useHistory();

  function formatDateRange(start: string, end: string): string {
    return `${dayjs(start).format('M/D/YYYY')}-${dayjs(end).format('M/D/YYYY')}`;
  }

  function formatValue(label: string, value?: number, valueClass = defaultValueClass) {
    if (isNil(value)) {
      return <></>;
    }

    return (
      <div className="w-1/4 my-4">
        <PlanValue label={label} valueClassName={valueClass} value={currencyFormatter.format(value)} />
      </div>
    );
  }

  function makeTransactionButton() {
    if (empno && company) {
      switch (enrollment.planCode) {
      case 'S':
        return (
          <HsaTransactions
            pyrid={enrollment.pyrid}
            empno={empno}
            compid={company.compid}
            plccode={enrollment.planCode}
            onClose={compose(setIsModalOpen, F)}
            isOpen={isModalOpen}
            transactions={[]}
          />
        );
      case CUSTOM_PLCCODE:
        return (
          <ViewCustomTransactions
            pyrid={enrollment.pyrid}
            isPending={isPending}
            customPlanId={enrollment.customPlanId ?? ''}
            onClose={compose(setIsModalOpen, F)}
            isOpen={isModalOpen}
          />
        );
      default:
        return (
          <MraTransactions
            pyrid={enrollment.pyrid}
            empno={empno}
            compid={company.compid}
            plccode={enrollment.planCode}
            onClose={compose(setIsModalOpen, F)}
            isOpen={isModalOpen}
            transactions={[]}
          />
        );
      }
    }

    return <></>;
  }

  function handleUpdateClick() {
    const { pyrid, planCode, customPlanId } = enrollment;

    history.push(`/update-plan-enrollment/${empno}/${pyrid}/${planCode}`
      + (planCode === CUSTOM_PLCCODE ? `/${customPlanId}` : '') + `/plan-details`);
  }

  function confirmedDelete() {
    dispatch(deleteRegularEnrollment(compid, empno ?? '', enrollment));
  }

  function debitCardStatus(status: string | null) {
    if (isNil(status)) {
      return (<></>);
    }
    const firstIndexOfSpace = status.indexOf(' ');

    return (
      <div className="w-full flex flex-row">
        <div className="text-lg text-high-em font-normal">Debit Card Status:</div>
        <div className={`${statusColor(status)} text-lg font-bold ml-2`}>
          {status.substring(0, firstIndexOfSpace)}
        </div>
        <div className={`text-lg text-high-em font-normal ml-2`}>{status.substring(firstIndexOfSpace)}</div>
      </div>
    );
  }

  function statusColor(status: string) {
    if (/^Suspended/i.test(status)) {
      return 'text-danger';
    }

    if (/^Active/i.test(status)) {
      return 'text-green-500';
    }

    return '';
  }

  return (
    <section className="flex flex-wrap w-full participant-overview-enrolled-plan">
      <h6 className="w-1/2 text-lg text-primary-main">{enrollment.planName}</h6>
      <div className="w-1/2 flex flex-col items-end">
        {
          !enrollment.isRollIn
            ? (
              <Button className="text-sm w-1/3" onClick={handleUpdateClick} disabled={deleting}>
                <EditIcon className="text-md mr-2" /> Update Enrollment
              </Button>
            )
            : <></>
        }
        <Button className="text-sm w-1/3 text-danger" onClick={compose(setShowCancelModal, T)} disabled={deleting}>
          <DeleteIcon className={`text-md mr-2 ${deleting && 'animate-ping'}`} /> Delete Enrollment
        </Button>
      </div>
      <div className="flex w-full mt-10" >
        <div className="w-4/5 flex flex-wrap">
          <div className="w-full mb-4">
            <p>
              <b className="text-high-em">Plan Year </b>
              {formatDateRange(enrollment.planYearStart, enrollment.planYearEnd)}
            </p>
            <p>
              <b className="text-high-em">Enrollment Period </b>
              {formatDateRange(enrollment.enrollmentStart, enrollment.enrollmentEnd)}
            </p>
          </div>
          {formatValue('Annual Allocation', enrollment.annualAllocation, `${defaultValueClass} text-3-1/2xl`)}
          {formatValue('Contributions', enrollment.totalContributions)}
          {formatValue('Reimbursements', enrollment.totalReimbursements)}
          {formatValue('Forfeitures', enrollment.totalForfeitures)}
          {formatValue('Rolled In', enrollment.totalRolledIn)}
          {formatValue('Rolled Out', enrollment.totalRolledOut)}
          {formatValue('Remaining', enrollment.remainingAllocation)}
          {formatValue('Balance', enrollment.balance)}
          {formatValue('Pending', enrollment.pending)}
          {formatValue('Processed', enrollment.processed)}
          {formatValue('Paid', enrollment.paid)}
          {debitCardStatus(enrollment.debitCardStatus || null)}
        </div>
        <div className="w-1/5">
          {
            isNil(enrollment.payPeriodDeductions)
              ? <></>
              : <PayPeriodDeductions deductions={enrollment.payPeriodDeductions} />
          }
          {
            isNil(enrollment.dependents)
              ? <></>
              : <CoveredDependentBox dependents={enrollment.dependents} />
          }
          {makeTransactionButton()}
        </div>
      </div>
      <ViewTransactionButton
        planCode={enrollment.planCode}
        setIsModalOpen={setIsModalOpen}
        setIsPending={setIsPending}
      />
      <CancelModal
        isOpen={showCancelModal}
        onCancel={compose(tap(confirmedDelete), setShowCancelModal, F)}
        onClose={compose(setShowCancelModal, F)}
        title="Are you sure you want to delete?"
        prompt={cancelPrompt}
        cancelText="Yes, delete enrollment"
        closeText="Cancel"
      />
    </section>
  );
}

export default EnrolledPlan;
