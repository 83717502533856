import React, { ReactElement, ComponentType, FunctionComponent } from 'react';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

export interface ModalPropsClasses {
  position?: string;
  modalContainer?: string;
  titleText?: string;
  contentContainer?: string;
  footerContainer?: string;
}

interface ModalProps {
  isOpen: boolean;
  title?: string;
  className?: string;
  classes?: ModalPropsClasses;
  footer?: FunctionComponent<React.PropsWithChildren<{}>>;
  onClose: (args?: unknown) => void;
}

const defaultClasses: ModalPropsClasses = {
  position: 'flex justify-center items-center',
  modalContainer: 'bg-surface p-4 w-1/2',
  titleText: '',
  contentContainer: '',
  footerContainer: '',
};

function withModal<P extends object>(WrappedComponent: ComponentType<React.PropsWithChildren<P>>) {
  function WithModal({
    isOpen,
    title = '',
    className = '',
    classes,
    footer,
    onClose,
    ...otherProps
  }: P & ModalProps): ReactElement {
    const {
      position,
      modalContainer,
      titleText,
      contentContainer,
      footerContainer,
    } = { ...defaultClasses, ...classes };

    function renderFooter() {
      if (footer) {
        return <div className={footerContainer}>{footer({})}</div>;
      }

      return <></>;
    }

    return (
      <Modal open={isOpen} className={`${position} with-modal`} onClose={onClose}>
        <div className={modalContainer}>
          <div className="flex items-center with-modal-title-bar">
            <h6 className={titleText}>{title}</h6>
            <div className="flex-auto" />
            <IconButton
              aria-label="close modal"
              className="with-modal-close-button"
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={`with-modal-content ${contentContainer}`}>
            <WrappedComponent {...otherProps as P} />
          </div>
          { renderFooter() }
        </div>
      </Modal>
    );
  }

  const wrappedComponentName = WrappedComponent.displayName ?? WrappedComponent.name;
  WithModal.displayName = `withModal(${wrappedComponentName})`;

  return WithModal;
}

export default withModal;
