import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { F, T, always, andThen, anyPass, compose, equals, hasPath, ifElse, isNil, path, when } from 'ramda';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import DatePicker from '@mui/lab/DatePicker';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';

import {
  cancelModalOpened,
  selectCobraPlan,
  selectCobraOeValidations,
  setCobraOeResponseDeadline,
  setCobraOeResponseRequired,
  setCobraOeSendPackets,
} from 're-enrollment-checklist/re-enrollment-checklist.slice';
import TextField from '@mui/material/TextField';
import { saveCobraOePacketInfo, generateOeDocuments } from 're-enrollment-checklist/re-enrollment-checklist.thunks';
import { noop, toNumber } from 'shared/utils';
import store from 'store';

function CoverLetterInfoTab(): ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const {
    cobraOeSendPackets,
    cobraOeResponseDeadline,
    cobraOeResponseRequired,
    oePacketsChecklistComplete,
    companyId,
  } = useSelector(selectCobraPlan);
  const validations = useSelector(selectCobraOeValidations);
  const [ isFormValid, setIsFormValid ] = useState<boolean>(true);
  const history = useHistory();
  const { planYearId = '0' } = useParams<{planYearId: string;}>();
  const changeRoute = compose(history.push, always(`/reenrollment-checklist/cobra/${planYearId}/oe-packets`));

  const handleValidate = compose(
    ifElse(
      compose(
        anyPass([
          hasPath([ 'cobraOeSendPackets' ]),
          hasPath([ 'cobraOeResponseDeadline' ]),
        ]),
        always(validations),
      ),
      compose(F, setIsFormValid, F),
      compose(T, setIsFormValid, T),
    ),
  );

  const handleSave = compose(
    andThen(
      compose(
        andThen(
          changeRoute,
        ),
        dispatch,
        compose(generateOeDocuments, always({ companyId, planYearId })),
      ),
    ),
    dispatch,
    saveCobraOePacketInfo,
  );

  const handleContinue = compose(
    ifElse(
      equals(false),
      compose(
        when(
          equals(true),
          handleSave,
        ),
        handleValidate,
      ),
      changeRoute,
    ),
    always(oePacketsChecklistComplete),
  );

  const isContinueDisabled = anyPass([
    compose(isNil, always(cobraOeSendPackets)),
    compose(isNil, always(cobraOeResponseDeadline)),
  ])();

  function formatDate(event: any): string {
    return dayjs(event, 'YYYY-MM-DD').format();
  }

  function displayDate(date: string): string | null {
    return date ? dayjs(date).format('MM/DD/YYYY') : null;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayJS}>
        <form className="my-10">
          <div className="w-2/5 my-5">
            <DatePicker
              label="What date must packets be sent by?"
              value={displayDate(cobraOeSendPackets)}
              onChange={compose(
                dispatch,
                setCobraOeSendPackets,
                formatDate,
              )}
              disabled={oePacketsChecklistComplete}
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  data-testid="cobraOeSendPackets"
                  variant="outlined"
                  name="cobraOeBegins"
                  error={!isFormValid && !!validations.cobraOeSendPackets}
                  helperText={!isFormValid && validations.cobraOeSendPackets}
                />
              )}
            />
          </div>
          <div className="w-2/5 my-5">
            <DatePicker
              label="What date must packets be returned by?"
              value={displayDate(cobraOeResponseDeadline)}
              onChange={compose(
                dispatch,
                setCobraOeResponseDeadline,
                formatDate,
              )}
              disabled={oePacketsChecklistComplete}
              // eslint-disable-next-line react/jsx-no-bind
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  data-testid="cobraOeResponseDeadline"
                  variant="outlined"
                  name="cobraOeResponseDeadline"
                  error={!isFormValid && !!validations.cobraOeResponseDeadline}
                  helperText={!isFormValid && validations.cobraOeResponseDeadline}
                />
              )}
            />
          </div>
          <div className="mt-10">
            <p className="mt-5">
            If a participant does not return an election form,
            are they termed or assumed to continue with their current elections?
            </p>
            <RadioGroup
              aria-label="oe response required?"
              name="cobraOeResponseRequired"
              // response can be 0, 1, or 2. Needs to be 1 or 2
              value={(cobraOeResponseRequired === 1 ? 1 : 2).toString()}
              onChange={compose(
                dispatch,
                setCobraOeResponseRequired,
                toNumber,
                path<any>([ 'target', 'value' ]),
              )}
            >
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label="Assumed to continue with their current elections (recommended)"
                disabled={oePacketsChecklistComplete}
              />
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label="They are termed"
                disabled={oePacketsChecklistComplete}
              />
            </RadioGroup>
          </div>
        </form>
      </LocalizationProvider>

      <div className="flex flex-row-reverse">
        <Button
          data-testid="cover-letter-continue"
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disabled={isContinueDisabled}
        >Continue
        </Button>

        <Button
          className="mr-4"
          onClick={compose(dispatch, cancelModalOpened, noop)}
        >Cancel
        </Button>
      </div>
    </>
  );
}

export default CoverLetterInfoTab;
