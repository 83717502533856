import React, { ChangeEvent, useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import dayjs, { Dayjs } from 'dayjs';

interface MonthSelectProps {
  // prefer YYYY-MM-DD, but any supported format is fine.
  startDate: Dayjs;
  endDate: Dayjs;
  format?: string; // Changes date format in options. See https://day.js.org/docs/en/display/format
  value?: string;
  onMonthChanged: (month: string) => void;
  [otherProp: string]: any;
}

interface MonthSelectOption {
  value: string;
  key: string;
  label: string;
}

function MonthSelect({
  startDate,
  endDate,
  format = 'M/D/YYYY',
  value,
  onMonthChanged,
  ...rest
}: MonthSelectProps): React.ReactElement {
  const [ options, setOptions ] = useState<MonthSelectOption[]>([]);

  useEffect(() => {
    function makeOpt(diff: number): MonthSelectOption {
      const month = startDate.add(diff, 'month');

      const labelStart = month.startOf('month').format(format);
      const labelEnd = month.endOf('month').format(format);

      const option = {
        value: month.format('YYYY-MM-01'),
        key: month.format('YYYY-MM'),
        label: `${labelStart} - ${labelEnd}`,
      };

      return option;
    }

    if (!startDate.isValid() || !endDate.isValid()) {
      return;
    }

    const opts: MonthSelectOption[] = [];
    const monthDiff = endDate.startOf('day').diff(dayjs(startDate).startOf('day'), 'month');

    // if diff is negative, startDate is before endDate. We interpret that as "display a reverse order list of months"
    if (monthDiff < 0) {
      for (let idx = 0; idx >= monthDiff; idx--) {
        const option = makeOpt(idx);
        opts.push(option);
      }
    } else {
      for (let idx = 0; idx <= monthDiff; idx++) {
        const option = makeOpt(idx);
        opts.push(option);
      }
    }

    setOptions(opts);
  }, [ startDate, endDate, format, onMonthChanged ]);

  function handleChange({ target: { value: val } }: ChangeEvent<{ name?: string; value: unknown; }>) {
    onMonthChanged(val as string);
  }

  return (
    <Select onChange={handleChange as any} className="w-full" native {...rest} value={value}>
      {options.map((opt) => <option value={opt.value} key={opt.key}>{opt.label}</option>)}
    </Select>
  );
}

export default MonthSelect;
