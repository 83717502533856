import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import {
  always, compose, includes, ifElse, lensPath, set,
  without, view, append, pipe, when, length, equals, both,
} from 'ramda';

import {
  selectLocations,
  selectPayFrequencies,
  selectPermissions,
  selectResponsibilities, selectSelectedUser,
} from '../manage-users/manage-users.slice';

interface Props {
  onCancel: () => void;
  onSubmit: (selections: any) => void;
}

type Path = 'locations' | 'payFrequencies' | 'responsibilities' | 'permissions';

function AddUserStepTwo ({ onCancel, onSubmit }: Props) {
  const locations = useSelector(selectLocations);
  const payFrequencies = useSelector(selectPayFrequencies);
  const permissions = useSelector(selectPermissions);
  const responsibilities = useSelector(selectResponsibilities);
  const selectedUser = useSelector(selectSelectedUser);

  const [ selections, setSelections ] = useState({
    locations: selectedUser?.locations.length ? selectedUser.locations : [ -1 ],
    payFrequencies: selectedUser?.payFrequencies ? selectedUser.payFrequencies : [ '!' ],
    responsibilities: selectedUser?.responsibilities ? selectedUser.responsibilities : [ -1 ],
    permissions: selectedUser?.permissions ? selectedUser.permissions : [ -1 ],
  });

  function selectAll(path: Path) {
    return () => {
      const lens = lensPath([ path ]);
      const val = view(lens, selections);
      const allSelectedIdentifier = path === 'payFrequencies' ? '!' : -1;
      let newVal = val.includes(allSelectedIdentifier) ? [] : [ allSelectedIdentifier ];

      if (path === 'locations') {
        newVal = [ allSelectedIdentifier ];
      }
      const newSelections = set(lens, newVal, selections);
      setSelections(newSelections);
    };
  }

  function selectOne(path: Path) {
    return (id: string | number) => {
      const lens = lensPath([ path ]);
      const allSelectedIdentifier = path === 'payFrequencies' ? '!' : -1;
      const modified = pipe(
        ifElse(
          includes(id),
          without([ id ]),
          pipe(
            append(id),
            without([ allSelectedIdentifier ]),
          ),
        ),
        when(
          both(
            compose(equals('locations'), always(path)),
            compose(equals(0), length),
          ),
          always([ allSelectedIdentifier ]),
        ),
      )(view(lens, selections));
      const newSelections = set(lens, modified, selections);
      setSelections(newSelections);
    };
  }

  return (
    <>
      <h6>{`${selectedUser?.conid ? 'Edit' : 'Add'} User`}</h6>
      <p>
        Select the locations, pay frequencies, administrative responsibilities,
        and permissions in which the individual should have access.
      </p>
      <div className="mt-10">
        <p className="pt-4 font-bold">Select Locations</p>
        <div className="flex space-x-4 flex-wrap">
          <Chip
            color={selections.locations.includes(-1) ? 'primary' : 'default'}
            variant={selections.locations.includes(-1) ? undefined : 'outlined'}
            className="mt-2 cursor-pointer"
            onClick={selectAll('locations')}
            label="All"
            data-testid="add-user-all-locations"
          />
          {
            locations?.map(l => (
              <Chip
                key={`${l.loccode}`}
                color={selections.locations.includes(l.loccode) ? 'primary' : 'default'}
                variant={selections.locations.includes(l.loccode) ? undefined : 'outlined'}
                className="mt-2 cursor-pointer"
                onClick={compose(selectOne('locations'), always(l.loccode))}
                label={l.location}
              />
            ))}
        </div>
      </div>
      <div>
        <p className="pt-4 font-bold">Select Pay Frequencies (Optional)</p>
        <div className="flex space-x-4 flex-wrap">
          <Chip
            color={selections.payFrequencies.includes('!') ? 'primary' : 'default'}
            variant={selections.payFrequencies.includes('!') ? undefined : 'outlined'}
            className="mt-2 cursor-pointer"
            onClick={selectAll('payFrequencies')}
            label="All"
            data-testid="add-user-all-payFrequencies"
          />
          {payFrequencies?.map(pf => (
            <Chip
              key={pf.pfqid}
              color={selections.payFrequencies.includes(pf.pfqid) ? 'primary' : 'default'}
              variant={selections.payFrequencies.includes(pf.pfqid) ? undefined : 'outlined'}
              className="mt-2 cursor-pointer"
              onClick={compose(selectOne('payFrequencies'), always(pf.pfqid))}
              label={pf.pfqdesc}
            />
          ))}
        </div>
      </div>
      <div>
        <p className="pt-4 font-bold">Select Responsibilities (Optional)</p>
        <div className="flex space-x-4 flex-wrap">
          <Chip
            color={selections.responsibilities.includes(-1) ? 'primary' : 'default'}
            variant={selections.responsibilities.includes(-1) ? undefined : 'outlined'}
            className="mt-2 cursor-pointer"
            onClick={selectAll('responsibilities')}
            label="All"
            data-testid="add-user-all-responsibilities"
          />
          {responsibilities?.map(r => (
            <Chip
              key={`${r.ctpid}`}
              color={selections.responsibilities.includes(r.ctpid) ? 'primary' : 'default'}
              variant={selections.responsibilities.includes(r.ctpid) ? undefined : 'outlined'}
              className="mt-2 cursor-pointer"
              onClick={compose(selectOne('responsibilities'), always(r.ctpid))}
              label={r.description}
            />
          ))}
        </div>
        {
          selections.responsibilities.length === 0
            ? <small className="text-low-em">Please select at least one responsibility.</small>
            : <></>
        }
      </div>
      <div>
        <p className="pt-4 font-bold">Select Dash Employer Portal Permissions (Optional)</p>
        <div className="flex space-x-4 flex-wrap">
          <Chip
            color={selections.permissions.includes(-1) ? 'primary' : 'default'}
            variant={selections.permissions.includes(-1) ? undefined : 'outlined'}
            className="mt-2 cursor-pointer"
            onClick={selectAll('permissions')}
            label="All"
            data-testid="add-user-all-permissions"
          />
          {permissions?.map(p => (
            <Chip
              key={`${p.prmid}`}
              color={selections.permissions.includes(p.prmid) ? 'primary' : 'default'}
              variant={selections.permissions.includes(p.prmid) ? undefined : 'outlined'}
              className="mt-2 cursor-pointer"
              onClick={compose(selectOne('permissions'), always(p.prmid))}
              label={p.prmdesc}
            />
          ))}
        </div>
      </div>
      <div className="pt-10 flex flex-row-reverse">
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={compose(onSubmit, always(selections))}
          data-testid="directBillingNextStep"
          className="ml-4"
        >
          Submit
        </Button>
        <Button
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default AddUserStepTwo;
