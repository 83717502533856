import { useEffect, useState } from 'react';
import LoopIcon from '@mui/icons-material/Loop';

import GridCard from 'components/shared/GridCard';
import fsaChecklist from 'images/reenroll-fsa-checklist.svg';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from 'companies/companies.slice';
import { doesFsaTemplateExist, downloadFsaTemplate } from 'shared/api/enrollmentResourcesApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';

function FsaEnrollmentTemplateCard() {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const [ exists, setExists ] = useState<boolean | null>(null);
  const errorNotifier = useErrorNotifier();

  useEffect(() => {
    doesFsaTemplateExist(compid)
      .then((val) => setExists(!!val))
      .catch(errorNotifier);
  }, [ compid, errorNotifier ]);

  function downloadTemplate() {
    downloadFsaTemplate(compid)
      .catch(errorNotifier);
  }

  switch (exists) {
  case null:
    return <LoopIcon className="animate-spin" data-testid="loading-fsa-template-card" />;
  case true:
    return (
      <GridCard
        src={fsaChecklist}
        type="XLS"
        name="FSA Enrollment Template"
        onClick={downloadTemplate}
      />
    );
  case false:
    return <></>;
  }
}

export default FsaEnrollmentTemplateCard;
