import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { compose, nthArg } from 'ramda';

import { Check } from './view-checks.types';
import CheckTableRow from './CheckTableRow';

export interface InvoiceTableProps {
  checks: Check[] | null;
}

function CheckTable({ checks }: InvoiceTableProps): React.ReactElement {
  const [ page, setPage ] = useState(0);

  return (
    <Table className="checks-table">
      <TableHead>
        <TableRow>
          <TableCell>Check Number</TableCell>
          <TableCell>Check Amount</TableCell>
          <TableCell>Payment Postmarked</TableCell>
          <TableCell>Amount Applied</TableCell>
          <TableCell>Credit Remaining</TableCell>
          <TableCell>Invoices</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          checks && checks.length > 0
            ? checks
              .slice(page * 10, page * 10 + 10)
              .map((check) => <CheckTableRow check={check} key={check.unqid} />)
            : <TableRow><TableCell colSpan={6}><p>No checks to display.</p></TableCell></TableRow>
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPage={10}
            rowsPerPageOptions={[ 10 ]}
            colSpan={7}
            count={checks?.length ?? 0}
            page={page}
            onPageChange={compose(setPage, nthArg(1))}
            SelectProps={ { native: true } }
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

export default CheckTable;
