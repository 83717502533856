import * as yup from 'yup';
import dayjs from 'dayjs';

const requiredText = 'This field is required';

export const validationSchema = yup.object({
  eventDate: yup
    .date()
    .typeError('Please enter a valid date')
    .nullable()
    .required(requiredText),
  coverageBegins: yup
    .date()
    .typeError('Please enter a valid date')
    .when('eventDate', (ed: Date | null, schema: any) => {
      return schema.test({
        test: (cb: Date | null) => !!ed && !!cb && (dayjs(ed).isSame(dayjs(cb)) || dayjs(ed).isBefore(dayjs(cb))),
        message: 'Event date must be before or the same date as the coverage begins date',
      });
    })
    .nullable()
    .required(requiredText),
  paidThrough: yup
    .date()
    .typeError('Please enter a valid date')
    .when('coverageBegins', (cb: Date | null, schema: any) => {
      return schema.test({
        test: (pt: Date | null) => !pt || (!!cb && dayjs(cb).isBefore(dayjs(pt))),
        message: 'Coverage begins must be before the paid through date',
      });
    })
    .nullable(),
  eventType: yup
    .string()
    .trim()
    .test('match',
      'Please select an event type',
      (eventType) => eventType !== '-1',
    )
    .required(requiredText),
});
