import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { always, compose, cond, equals, gt, lte } from 'ramda';

interface HsaContributionsNotForwardedCardProps {
  count: number;
}

function HsaContributionsNotForwardedCard({ count }: HsaContributionsNotForwardedCardProps) {
  const history = useHistory();

  const isBlue = equals<number>(0);
  const isYellow = (c: number) => gt(c, 0) && lte(c, 10);
  const isRed = (c: number) => gt(c, 10);

  // tailwind will not compile these classes if they are not used elsewhere
  // so instead of template stringing text and border this is to ensure
  // that these classes get compiled.
  const borderColor = cond<number, string>([
    [ isBlue, always('border-primary-main') ],
    [ isYellow, always('border-yellow-400') ],
    [ isRed, always('border-red-600') ],
  ])(count);

  const countColor = cond<number, string>([
    [ isBlue, always('text-primary-main') ],
    [ isYellow, always('text-yellow-400') ],
    [ isRed, always('text-red-600') ],
  ])(count);

  return count > 0
    ? (
      <div className={`w-full border border-solid ${ borderColor } rounded p-4`}>
        <div>
          <div className="flex justify-between">
            <div className={`text-2xl ${ countColor } font-bold not-forwarded-count`}>
              { count }
            </div>
            <div className="flex">
              <Button className="p-0">
                <p
                  onClick={compose(
                    history.push,
                    always('action-items/hsa-not-forwarded'),
                  )}
                  className="flex text-sm text-high-em"
                >
                  <SearchIcon className="mr-1" fontSize="small" />
                  View Details
                </p>
              </Button>
            </div>
          </div>
          <div className="font-semibold">
            HSA Contributions Not Forwarded
          </div>
        </div>
      </div>
    )
    : <></>;
}

export default HsaContributionsNotForwardedCard;
