import store from 'store';
import {
  validate,
} from 're-enrollment-checklist/cobra-reenrollment-checklist/cobra-checklist-plan.middleware';

export function validateCobraPlanOnDeck(): any {
  return (next: any) => async (action: any) => {
    return validate(store)(next)(action);
  };
}
