import { ReactElement, useState } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { compose, nthArg } from 'ramda';

import DirectDebitRow from './DirectDebitRow';
import { Debit } from './direct-debit.types';

interface DirectDebitProps {
  debits: Debit[];
}

function DirectDebitTable({ debits }: DirectDebitProps): ReactElement {
  const [ page, setPage ] = useState(0);

  return (
    <form className="mt-4 w-full">
      <div className="w-full">
        <Table className="w-full">
          <TableHead>
            <TableRow>
              <TableCell className="text-sm">Pay Date</TableCell>
              <TableCell className="text-sm">Pay Frequency</TableCell>
              <TableCell className="text-sm">Amount</TableCell>
              <TableCell className="text-sm">Status</TableCell>
              <TableCell className="text-sm">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              debits.length > 0
                ? debits
                  .slice(page * 10, page * 10 + 10)
                  .map((d) => (
                    <DirectDebitRow
                      debit={d}
                      key={d.unqid}
                    />
                  ))
                : <TableRow><TableCell colSpan={4}><p>No direct debits to display.</p></TableCell></TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPage={10}
                rowsPerPageOptions={[ 10 ]}
                colSpan={7}
                count={debits.length}
                page={page}
                onPageChange={compose(setPage, nthArg(1))}
                SelectProps={ { native: true } }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </form>
  );
}

export default DirectDebitTable;
