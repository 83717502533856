import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { always, ifElse, isEmpty } from 'ramda';

import { DateFormat_M_D_YYYY } from 'shared/consts/date-time.consts';
import { selectSelectedCompany } from 'companies/companies.slice';
import { LeaveRequestFormFields } from 'enter-leave-request/enter-leave-request.types';
import classes from './EnterLeaveRequestSummary.module.css';

export function EnterLeaveRequestSummary(): React.ReactElement {
  const form = useFormikContext<LeaveRequestFormFields>();
  const company = useSelector(selectSelectedCompany);
  const hireDate = ifElse(
    isEmpty,
    always(''),
    always(dayjs(form.values.employment.hireDate).format(DateFormat_M_D_YYYY)),
  )(form.values.employment.hireDate);
  const dateStart = ifElse(
    isEmpty,
    always(''),
    always(dayjs(form.values.event.dateStart).format(DateFormat_M_D_YYYY)),
  )(form.values.event.dateStart);
  const dateEnd = ifElse(
    isEmpty,
    always(''),
    always(dayjs(form.values.event.dateEnd).format(DateFormat_M_D_YYYY)),
  )(form.values.event.dateEnd);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form.validateForm();
  }, []);

  return (
    <>
      <div className="flex space-x-8 mb-4" data-testid="enter-leave-request-summary">
        <div className="w-1/2">
          <div className="flex justify-between items-center w-full pb-2 mb-2 border-b border-b-gray-200">
            <p className={classes.sectionHeader}>Personal Details</p>
            <Link to="/enter-leave-request/personal-details" className={`${classes.link} text-sm`}>Edit</Link>
          </div>
          {
            form.errors.employee && (
              <p className="text-sm text-red-600 mb-4" data-testid="personal-details-error">
                One or more validation errors occurred
              </p>
            )
          }
          <dl className="text-sm">
            <dt className="font-bold">{form.values.employee.nameFirst} {form.values.employee.nameLast}</dt>
            <div className="flex">
              <dt className="pr-2">EEID:</dt>
              <dd className="font-bold flex-grow">{form.values.employee.eeId}</dd>
            </div>
            {
              form.values.employee.email &&
              (
                <div className="flex" data-testid="email">
                  <dt className="pr-2">Email:</dt>
                  <dd className="font-bold flex-grow">{form.values.employee.email}</dd>
                </div>
              )
            }
            {
              form.values.employee.phone &&
              (
                <div className="flex" data-testid="phone">
                  <dt className="pr-2">Phone:</dt>
                  <dd className="font-bold flex-grow">{form.values.employee.phone}</dd>
                </div>
              )
            }
            <div className="flex">
              <dt className="pr-2">Address:</dt>
              <dd className="font-bold basis-5 flex-grow">
                {form.values.employee.addr1}
                {
                  form.values.employee.addr2 !== '' &&
                  (
                    <>
                      <br data-testid="addr2" />{form.values.employee.addr2}
                    </>
                  )
                }
                <br />{form.values.employee.city}, {form.values.employee.stateProvCd} {form.values.employee.postalCd}
              </dd>
            </div>
          </dl>
        </div>
        <div className="w-1/2">
          <div className="flex justify-between items-center w-full pb-2 mb-2 border-b border-b-gray-200">
            <p className={classes.sectionHeader}>Employment Details</p>
            <Link to="/enter-leave-request/employment-details" className={`${classes.link} text-sm`}>Edit</Link>
          </div>
          {
            form.errors.employment && (
              <p className="text-sm text-red-600 mb-4" data-testid="employment-details-error">
                One or more validation errors occurred
              </p>
            )
          }
          <dl className="text-sm">
            <div className="flex">
              <dt className="pr-2">Employer's Name:</dt>
              <dd className="font-bold">{company?.compname}</dd>
            </div>
            {
              form.values.employment.location &&
              (
                <div className="flex" data-testid="location">
                  <dt className="pr-2">Location or Division:</dt>
                  <dd className="font-bold">{form.values.employment.location}</dd>
                </div>
              )
            }
            <div className="flex">
              <dt className="pr-2">Employment Type:</dt>
              <dd className="font-bold">{form.values.employment.type}</dd>
            </div>
            {
              form.values.employment.supervisorName &&
              (
                <div className="flex" data-testid="supervisor-name">
                  <dt className="pr-2">Supervisor Name:</dt>
                  <dd className="font-bold">{form.values.employment.supervisorName}</dd>
                </div>
              )
            }
            <div className="flex">
              <dt className="pr-2">Hire Date:</dt>
              <dd className="font-bold">{hireDate}</dd>
            </div>
            <div className="flex">
              <dt className="pr-2">Supervisor Email:</dt>
              <dd className="font-bold">{form.values.employment.supervisorEmail}</dd>
            </div>
            <div className="flex">
              <dt className="pr-2">Total hours worked in the past 12 months:</dt>
              <dd className="font-bold">{form.values.employment.totalHoursWorked}</dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-between items-center w-full pb-2 mb-2 border-b border-b-gray-200">
          <p className={classes.sectionHeader}>Event Info</p>
          <Link to="/enter-leave-request/event-info" className={`${classes.link} text-sm`}>Edit</Link>
        </div>
        {
          form.errors.event && (
            <p className="text-sm text-red-600 mb-4" data-testid="event-info-error">
              One or more validation errors occurred
            </p>
          )
        }
        <dl className="text-sm">
          <div className="flex">
            <dt className="pr-2">Leave of Absence or Accommodation Start Date:</dt>
            <dd className="font-bold">{dateStart}</dd>
          </div>
          <div className="flex">
            <dt className="pr-2">Leave of Absence or Accommodation End Date:</dt>
            <dd className="font-bold">{dateEnd}</dd>
          </div>
          {
            form.values.event.kinRelation &&
            (
              <div className="flex" data-testid="kin-relation">
                <dt className="pr-2">Family Member Relationship:</dt>
                <dd className="font-bold">{form.values.event.kinRelation}</dd>
              </div>
            )
          }
          <div className="flex">
            <dt className="pr-2">Briefly describe the reason for your leave of absence or accommodation request:</dt>
            <dd className="font-bold">{form.values.event.explanation}</dd>
          </div>
          <div className="flex">
            <dt className="pr-2">Duration of the Leave of Absence or Accommodation:</dt>
            <dd className="font-bold">{form.values.event.duration}</dd>
          </div>
          {
            form.values.event.faxHealthProvider &&
            (
              <>
                <dt className="font-bold mt-2" data-testid="summary-fax-information">Fax Information</dt>
                <div className="flex">
                  <dt className="pr-2">Provider Name:</dt>
                  <dd className="font-bold">{form.values.event.providerName}</dd>
                </div>
                <div className="flex">
                  <dt className="pr-2">Fax Number:</dt>
                  <dd className="font-bold">{form.values.event.providerFax}</dd>
                </div>
                {
                  form.values.event.kinName &&
                  (
                    <div className="flex" data-testid="summary-kin-name">
                      <dt className="pr-2">Family Member's Name:</dt>
                      <dd className="font-bold">{form.values.event.kinName}</dd>
                    </div>
                  )
                }
              </>
            )
          }
        </dl>
      </div>
    </>
  );
}
