import { DeductionDate } from 'reports/reports.types';
import request from '../api-request';
import { CompanyLocation } from 'enter-cobra-qe/cobra-qe.types';
import { PayFreqSelectOption } from 'components/shared/PayFreqSelect';
import { Company } from 'companies/company.types';

export function getUserCompanies(userId: number) {
  if (userId <= 0) {
    return Promise.reject(new Error('Cannot get companies for invalid user.'));
  }

  return request<Company[]>(`/api/users/${userId}/companies`);
}

export function getDeductionDates(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get deduction dates for invalid company.'));
  }

  return request<DeductionDate[]>(`/api/companies/${companyId}/deduction-dates`);
}

export function logToServer(companyId: number, body: any) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot log for an invalid company.'));
  }

  return Promise.resolve();
}

export function getLocations(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load locations for an invalid company.'));
  }

  return request<CompanyLocation[]>(`/api/companies/${companyId}/locations`);
}

export function getPayFrequencies(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load pay frequencies for an invalid company.'));
  }

  return request<PayFreqSelectOption[]>(`/api/companies/${companyId}/payfreqs`);
}
