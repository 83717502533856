import { Dayjs } from 'dayjs';

import { Benefit, Dependent } from 'shared/types/non-plan-year.types';
import { CobraQeFormProps } from '../cobra-qe.types';
import Loading from 'components/shared/Loading';
import { useCobraPlans } from './useCobraPlans';
import BenefitsFormContainer from './BenefitsFormContainer';

interface CobraQeBenefitsProps extends CobraQeFormProps {
  dependents: Dependent[];
  selectedBenefits: Benefit[];
  effectiveDate: string | Dayjs;
}

function CobraQeBenefits({
  selectedBenefits,
  dependents,
  onNextStep,
  onCancel,
  effectiveDate,
}: CobraQeBenefitsProps) {
  const availablePlans = useCobraPlans(effectiveDate);

  return availablePlans === null
    ? <Loading text="Loading Benefits..." />
    : (
      <div>
        <div className="flex flex-col mb-4">
          <p>
            Select the benefits the insured and their dependent(s) are eligible to continue under COBRA.
          </p>
        </div>
        <BenefitsFormContainer
          availablePlans={availablePlans}
          dependents={dependents}
          selectedBenefits={selectedBenefits}
          onNextStep={onNextStep}
          onCancel={onCancel}
        />
      </div>
    );
}

export default CobraQeBenefits;

