import { useEffect, useState, useRef } from 'react';
import { useFormik, FormikProvider } from 'formik';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { compose, tap, always, omit } from 'ramda';
import { useSelector } from 'react-redux';

import { conformToForm, conformToSlice, hashCoveredDependents } from 'enter-cobra-qe/benefits/benefit-state-helpers';
import { Benefit, Dependent } from 'shared/types/non-plan-year.types';
import { CobraQe, QeBenefits, CobraQePlusPlans, QeEventInfo } from 'enter-cobra-qe/cobra-qe.types';
import EventInfoForm from 'enter-cobra-qe/event-info/EventInfoForm';
import CobraQeDetails from './CobraQeDetails';
import BenefitsForm from 'enter-cobra-qe/benefits/BenefitsForm';
import { validationSchema } from './update-cobra-qe.validators';
import FormikErrorFocus from 'components/shared/FormikErrorFocus';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { getCobraAvailablePlans, getNoBenefitsMessage } from 'shared/api/cobraQeApi';
import { selectSelectedCompany } from 'companies/companies.slice';

interface UpdateCobraQeFormProps {
  entry: CobraQePlusPlans;
  dependents: Dependent[];
  selectedBenefits: Benefit[];
  onCancel: () => void;
  onSave: (result: CobraQe) => void;
}

type UpdateQeValues = QeEventInfo & QeBenefits;

function UpdateCobraQeForm({
  entry,
  dependents,
  selectedBenefits,
  onCancel,
  onSave,
}: UpdateCobraQeFormProps) {
  const [ availablePlans, setAvailablePlans ] = useState<QeBenefits[] | null>(entry.availablePlans);
  const conformBenefits = conformToSlice(dependents, entry.availablePlans);
  const form = useFormik<UpdateQeValues>({
    initialValues: {
      eventDate: entry.eventDate,
      eventType: entry.eventType,
      coverageBegins: entry.coverageBegins,
      paidThrough: entry.paidThrough,
      benefits: conformToForm(selectedBenefits, entry.availablePlans)
        .map(b => hashCoveredDependents(b, dependents)),
    },
    validationSchema,
    onSubmit: (values) => {
      const conformed = conformBenefits(values);

      if (!conformed.benefits.length) {
        window.alert('Please fill out at least one benefit');

        return;
      }

      const cobraQe = {
        ...omit([ 'availablePlans' ], entry),
        ...values,
        eventType: parseInt(values.eventType?.toString() ?? '-1'),
        benefits: conformed.benefits,
      } as unknown as CobraQe;

      onSave(cobraQe);
    },
  });
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const mountedRef = useRef(false);
  const { current: setDefault } = useRef(always(undefined));
  const errorNotifier = useErrorNotifier();
  const [ handleError ] = useState(() => compose(
    tap(setDefault),
    errorNotifier,
  ));

  useEffect(() => {
    mountedRef.current = true;

    if (form.values.coverageBegins && !dayjs(form.values.coverageBegins).format('MM/DD/YYYY').includes('Invalid')) {
      getCobraAvailablePlans(compid, form.values.coverageBegins)
        .then((result) => {
          if (mountedRef.current) {
            setAvailablePlans(result);
            setBenefits(result);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            handleError(err);
          }
        });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [ form.values.coverageBegins, handleError, compid ]); // eslint-disable-line react-hooks/exhaustive-deps

  function setBenefits(plans?: QeBenefits[]) {
    if (plans && plans.length > 0) {
      form.setFieldValue('benefits', conformToForm(entry.benefits, plans)
        .map(b => hashCoveredDependents(b, entry.participant.dependents ?? []) as unknown as QeBenefits[]));
    } else {
      form.setFieldValue('benefits', []);
    }
  }

  return (
    <form className="update-cobra-qe-form w-full">
      <div className="flex flex-col">
        <FormikProvider value={form}>
          <FormikErrorFocus />
          <div className="flex mb-4 space-x-4">
            <div className="w-1/2">
              <EventInfoForm letterSent={!!entry.details?.letterSent} respPostmark={!!entry.details?.postmark} />
            </div>
            <div className="w-1/2">
              <CobraQeDetails details={entry.details!} />
            </div>
          </div>

          <div className="flex-grow overflow-auto">
            {
              availablePlans?.length
                ? (
                  <BenefitsForm
                    dependents={dependents}
                    availablePlans={availablePlans}
                    letterSent={!!entry.details?.letterSent}
                    respPostmark={!!entry.details?.postmark}
                  />
                )
                : <p>{getNoBenefitsMessage(dayjs(form.values.coverageBegins).format('MM/DD/YYYY'))}</p>
            }
          </div>
        </FormikProvider>
        <div className="flex justify-end my-4 h-8">
          <Button
            className="update-qe-form-cancel"
            color="primary"
            onClick={compose(onCancel, always(undefined))}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="update-qe-form-save"
            className="ml-8 update-qe-form-save"
            type="button"
            onClick={form.submitForm}
            disabled={!!entry.details?.postmark}
          >
            Save QE
          </Button>
        </div>
      </div>
    </form>
  );
}

export default UpdateCobraQeForm;
