import React from 'react';
import { Route as _Route, Switch as _Switch, Redirect as _Redirect } from 'react-router-dom';

import SettingsSidebar from './SettingsSidebar';
import ContactInfo from './contact-info/ContactInfo';
import ChangePassword from './change-password/ChangePassword';
import MultiFactorSettings from './multi-factor-settings/MultiFactorSettings';
import ManageUsers from 'manage-settings/manage-users/ManageUsers';

const Route = _Route as any;
const Switch = _Switch as any;
const Redirect = _Redirect as any;

function ManageSettings() {
  return (
    <div className="m-4 w-full">
      <h4 className="mb-4">Manage Your Settings</h4>
      <div className="flex w-full">
        <div className="w-1/5" style={ { minWidth: '221px' } }>
          <SettingsSidebar />
        </div>
        <div className="flex flex-grow ml-8">
          <Switch>
            <Route exact path="/manage-settings/contact-info">
              <ContactInfo />
            </Route>
            <Route path="/manage-settings/change-password">
              <ChangePassword />
            </Route>
            <Route path="/manage-settings/multi-factor">
              <MultiFactorSettings />
            </Route>
            <Route path="/manage-settings/manage-users">
              <ManageUsers />
            </Route>
            <Route>
              <Redirect to="/manage-settings/contact-info" />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default ManageSettings;
