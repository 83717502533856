import { useHistory } from 'react-router-dom';
import BeachAccessRounded from '@mui/icons-material/BeachAccessRounded';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { ReEnrollmentChecklistPlan } from 're-enrollment-checklist/re-enrollment-checklist.types';
import { ChecklistCard } from 're-enrollment-checklist/ChecklistCard';
import { selectCobraPlan, selectCobraOeProgress } from 're-enrollment-checklist/re-enrollment-checklist.slice';
import { compose, always, equals, all } from 'ramda';

export function CobraOePacketCard ({ plan }: { plan: ReEnrollmentChecklistPlan; }) {
  const history = useHistory();
  const progress = useSelector(selectCobraOeProgress);
  const {
    coverLetterSectionComplete,
    oePacketsSectionComplete,
  } = useSelector(selectCobraPlan);

  const summaryDisabled = !all(
    equals(true),
    [
      coverLetterSectionComplete,
      oePacketsSectionComplete,
    ],
  );

  const icon = <BeachAccessRounded style={ { fontSize: 32 } } />;
  const buttons = (
    <>
      <Button
        variant="outlined"
        className="mr-5"
        onClick={
          compose(
            history.push,
            always(`/reenrollment-checklist/cobra/${plan.planYearId}/cover-letter-info`),
          )
        }
      >Cover Letter
      </Button>
      <Button
        variant="outlined"
        className="mr-5"
        onClick={
          compose(
            history.push,
            always(`/reenrollment-checklist/cobra/${plan.planYearId}/oe-packets`),
          )
        }
        disabled={!coverLetterSectionComplete}
      >OE Packet
      </Button>
      <Button
        variant="outlined"
        disabled={summaryDisabled}
        onClick={
          compose(
            history.push,
            always(`/reenrollment-checklist/cobra/${plan.planYearId}/summary`),
          )
        }
      >Summary
      </Button>
    </>
  );

  return (
    <ChecklistCard
      icon={icon}
      title="COBRA Open Enrollment Packets"
      planYearStart={plan.planYearStart}
      planYearEnd={plan.planYearEnd}
      buttons={buttons}
      progress={progress}
    />
  );
}
