import PageContentPaper from 'components/layout/PageContentPaper';

import DirectDebitTable from './DirectDebitTable';
import { useDirectDebits } from './hooks/useDirectDebits';
import Loading from 'components/shared/Loading';

function DirectDebit(): React.ReactElement {
  const directDebits = useDirectDebits();

  return (
    <div className="w-full flex flex-col p-4">
      <h2 className="page-title">Authorize Direct Debit</h2>
      <PageContentPaper className="flex flex-col">
        <div className="w-full flex">
          {
            directDebits !== null
              ? <DirectDebitTable debits={directDebits} />
              : <Loading text="Loading Direct Debit..." />
          }
        </div>
      </PageContentPaper>
    </div>
  );
}

export default DirectDebit;
