import request from '../api-request';
import { OpenPlan } from 'plan-enrollment/plan-enrollment.types';

export function getBalanceReportPlans(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get balance plans for invalid company.'));
  }

  return request<OpenPlan[]>(`/api/companies/${companyId}/report-balance-plans`);
}
