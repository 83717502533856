import React, { ReactElement } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

function TransactionRow({ cells }: { cells: string[]; }): ReactElement {
  return (
    <TableRow>
      {
        cells.map((v, i) => (
          <TableCell key={i}>
            <p className="text-black text-sm">
              {v}
            </p>
          </TableCell>
        ))
      }
    </TableRow>
  );
}

export default TransactionRow;
