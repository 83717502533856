import { useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import { SelectChangeEvent } from 'shared/types/form.types';
import { QeEventType } from '../cobra-qe.types';
import FormErrorText from 'components/shared/FormErrorText';

interface EventTypeSelectProps {
  eventTypes: QeEventType[];
  value: string;
  error: boolean;
  onSelectChange: (value: string) => void;
  className?: string;
  disabled?: boolean;
}

function EventTypeSelect({
  eventTypes,
  value,
  error,
  onSelectChange,
  className = '',
  disabled = false,
}: EventTypeSelectProps) {
  const labelRef = useRef<HTMLLabelElement>(null);

  function handleChange({ target: { value: val } }: SelectChangeEvent) {
    onSelectChange(val as string);
  }

  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel ref={labelRef} id="event-types-label">Event Type</InputLabel>
      <Select
        label="Event Type"
        labelId="event-types-label"
        name="eventType"
        native
        variant="outlined"
        fullWidth
        data-testid="event-type"
        value={value}
        onChange={handleChange as any}
        error={error}
        className={!value ? 'MuiFormLabel-root' : ''}
        tabIndex={-1}
        inputProps={ { disabled } }
        disabled={disabled}
      >
        <option value="-1" />
        {eventTypes.map((event, idx) => <option key={idx} value={event.c_qualevid}>{event.desc}</option>)}
      </Select>
      <FormErrorText show={error} />
    </FormControl>
  );
}

export default EventTypeSelect;
