import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { compose, T, F } from 'ramda';

import { selectSelectedCompany } from 'companies/companies.slice';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import PlanYearSelect from './PlanYearSelect';
import { PlanServiceSummary, PlanYear } from './reports-tile.types';
import NonPlanYearServices from './non-plan-year/NonPlanYearServices';
import ReimbursementPlanSummary from './plan-year/ReimbursementPlanSummary';
import { getServiceSummaries } from 'shared/api/reportsApi';
import { getPlanYears } from 'shared/api/planYearsApi';

function ReportsTile(): React.ReactElement {
  const [ loading, setLoading ] = useState(false);
  const [ startLoading ] = useState(() => compose(setLoading, T));
  const [ doneLoading ] = useState(() => compose(setLoading, F));
  const [ planYears, setPlanYears ] = useState<PlanYear[]>([]);
  const [ selectedPlanYearId, setSelectedPlanYearId ] = useState(-1);
  const [ serviceSummaries, setServiceSummaries ] = useState<PlanServiceSummary[]>([]);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const handleError = useErrorNotifier();

  // initial plan year load for select
  useEffect(() => {
    if (compid) {
      startLoading();
      getPlanYears(compid)
        .then((py) => {
          setPlanYears(py ?? []);

          if ((py ?? [])[0]?.pyrid) {
            setSelectedPlanYearId(py![0].pyrid);
          }
        })
        .catch(handleError)
        .finally(doneLoading);
    }
  }, [ compid, handleError, startLoading, doneLoading ]);

  // respond to selected plan year change by loading details
  useEffect(() => {
    if (compid && selectedPlanYearId !== -1) {
      startLoading();
      getServiceSummaries(compid, selectedPlanYearId)
        .then((summaries) => setServiceSummaries(summaries ?? []))
        .catch(handleError)
        .finally(doneLoading);
    }
  }, [ compid, handleError, selectedPlanYearId, startLoading, doneLoading ]);

  function serviceSummaryContent(): React.ReactElement {
    return (
      <div className="w-full flex flex-wrap">
        {serviceSummaries.map((summary, index) => {
          return (
            <div className={`w-1/2 mb-4 ${index % 2 ? 'pl-2' : 'pr-2'}`} key={summary.plcid}>
              <ReimbursementPlanSummary
                title={summary.name}
                reimbursements={summary.reimbursements}
                annualTotals={summary.annualTotals}
                participants={summary.participants}
                summary={summary}
              />
            </div>
          );
        })}
      </div>
    );
  }

  // conditional render
  function content(): React.ReactElement {
    if (loading) {
      return (
        <>
          <Skeleton variant="rectangular" height={570} data-testid="reportTileSkeleton" />
        </>
      );
    }

    if (selectedPlanYearId === -1) {
      return (
        <>
          <NonPlanYearServices />
        </>
      );
    }

    return serviceSummaryContent();
  }

  return (
    <Paper className="p-4">
      <div className="flex justify-between mb-4">
        <p className="text-high-em w-1/2 mr-4">Reports</p>
        <div className="w-1/2">
          {
            !loading && (
              <PlanYearSelect
                planYears={planYears}
                onChange={setSelectedPlanYearId}
                value={selectedPlanYearId}
              />
            )
          }
        </div>
      </div>
      {content()}
    </Paper>
  );
}

export default ReportsTile;
