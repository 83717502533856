import { Response } from 'miragejs';

import forms from '../fixtures/document-library-forms.json';
import eduDocs from '../fixtures/education-documents.json';
import legal from '../fixtures/document-library-legal.json';

function routing(server) {
  server.get('/companies/:companyId/document-library/forms', (schema) => {
    const data = forms;
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/document-library/education', (schema) => {
    const data = eduDocs;
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });

  server.get('/companies/:companyId/document-library/legal-docs', (schema) => {
    const data = legal;
    const length = JSON.stringify(data).length;
    return new Response(200, { 'content-length': length }, { data });
  });
}

export default routing;
