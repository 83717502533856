import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/SystemUpdateAlt';

import CenterLabelDonutChart, { DataPoint } from 'components/shared/CenterLabelDonutChart';
import { currencyFormatter, noop } from 'shared/utils';
import { PlanServiceSummary } from 'reports-tile/reports-tile.types';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { downloadReimbursementReport } from 'shared/api/reportsApi';

interface ReimbursementPlanSummaryProps {
  title: string;
  participants: number;
  showExport?: boolean;
  summary: PlanServiceSummary;
  annualTotals?: number;
  reimbursements?: number;
}

function ReimbursementPlanSummary({
  title,
  summary,
  showExport = true,
  participants,
  annualTotals,
  reimbursements,
}: ReimbursementPlanSummaryProps): React.ReactElement {
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const handleError = useErrorNotifier();
  const [ chartData, setChartData ] = useState<DataPoint[]>([]);

  useEffect(() => {
    setChartData([
      {
        label: 'Reimbursements',
        value: reimbursements ?? 0,
      },
      {
        label: '',
        value: (annualTotals ?? 0) - (reimbursements ?? 0),
      },
    ]);
  }, [ reimbursements, annualTotals ]);

  function handleExport() {
    downloadReimbursementReport(
      compid ?? -2,
      summary.pyrid,
      summary.plcid,
      summary.type === 'custom',
      summary.startDate,
      summary.endDate,
    )
      .then(noop)
      .catch(handleError);
  }

  function exportLink(): React.ReactElement {
    return showExport
      ? (
        <Button onClick={handleExport} className="p-0">
          <p className="flex flex-row justify-center text-high-em text-sm">
            <DownloadIcon className="mr-1" fontSize="small" /> Export
          </p>
        </Button>
      )
      : <></>;
  }

  return (
    <div className="border border-solid border-gray-300 rounded-sm p-2">
      <div className="flex justify-between mb-4">
        <p className="text-high-em text-sm">{title}</p>
        <div className="flex-grow" />
        {exportLink()}
      </div>
      <div className="flex">
        { annualTotals ?
          (
            <div className="w-1/2" >
              <CenterLabelDonutChart
                chartData={chartData}
                centerItem={0}
                formatter={currencyFormatter}
                radius={150}
                innerRadius={125}
                fontSize={30}
                width={300}
                height={300}
              />
            </div>
          )
          : (<></>)
        }
        <div className={`${annualTotals ? 'w-1/2' : 'w-full'} flex flex-col justify-center"`}>
          <div className={`${annualTotals ? 'mb-8' : 'mb-4'} text-center`}>
            <p className="text-high-em text-lg">Total Participants</p>
            <p className="text-high-em text-3xl">{participants}</p>
          </div>
          {
            annualTotals
              ? (
                <div className= "text-center">
                  <p className="text-high-em text-lg">Annual Totals</p>
                  <p className="text-high-em text-3xl">{currencyFormatter.format(annualTotals)}</p>
                </div>
              )
              : <></>
          }
        </div>
      </div>
    </div>
  );
}

export default ReimbursementPlanSummary;
