import request, { ContentType } from '../api-request';
import { EnrollmentResourcePlan } from 'open-enrollment-resources/open-enrollment.types';

export function doesFsaTemplateExist(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot check for FSA enrollment template for invalid company.'));
  }

  return request<boolean>(`/api/companies/${companyId}/enrollment-resources/fsa/template-exists`);
}

export function downloadFsaTemplate(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download FSA enrollment template for invalid company.'));
  }

  const uri = `/api/companies/${companyId}/enrollment-resources/fsa/download-template`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function downloadCustomChecklist(companyId: number, planYearId: number, planId: number, subPlanId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download checklist for invalid company.'));
  }

  if (planYearId <= 0) {
    return Promise.reject(new Error('Cannot download checklist for invalid plan year.'));
  }

  if (planId <= 0) {
    return Promise.reject(new Error('Cannot download checklist for invalid plan.'));
  }

  if (subPlanId <= 0) {
    return Promise.reject(new Error('Cannot download checklist for invalid custom plan.'));
  }

  const uri = `/api/companies/${companyId}/enrollment-resources/custom` +
    `/${planYearId}/${planId}/${subPlanId}/download-checklist`;

  return request(uri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}

export function getCompanyOpenEnrollment(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get open enrollment plans for invalid company.'));
  }

  return request<EnrollmentResourcePlan[]>(`/api/companies/${companyId}/enrollment-resources/plans`);
}
