import dayjs from 'dayjs';

export function validateDate(message: string, date?: string) {
  const dateObj = date ? dayjs(date) : undefined;

  if (dateObj && !dateObj.isValid()) {
    throw new Error(message);
  }

  return dateObj;
}
