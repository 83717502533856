import { useState } from 'react';
import { compose, F, T, tap } from 'ramda';

import ReportsTile from '../../shared/ReportsTile';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useSelector } from 'react-redux';
import { downloadOverDueInvoicesReport } from 'shared/api/overDueInvoicesApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { CRLType } from 'shared/types/non-plan-year.types';

interface OverDueInvoicesProps {
  serviceType: CRLType;
  startDate: string | null;
  endDate: string | null;
  count: number | null;
}

function OverDueInvoices({ serviceType, startDate, endDate, count }: OverDueInvoicesProps) {
  const [ downloading, setDownloading ] = useState(false);
  const doneDownloading = compose(setDownloading, F);
  const startDownloading = compose(setDownloading, T);

  const errorNotifier = useErrorNotifier();
  const handleError = compose(tap(doneDownloading), errorNotifier);

  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };

  function handleExport() {
    startDownloading();
    downloadOverDueInvoicesReport(compid, serviceType, startDate ?? undefined, endDate ?? undefined)
      .then(doneDownloading)
      .catch(handleError);
  }

  return (
    <ReportsTile
      count={count}
      title="Overdue Invoices"
      description="List of outstanding invoices that are past due."
      exportDisabled={count === null || !startDate || !endDate}
      onExport={handleExport}
      downloading={downloading}
    />
  );
}

export default OverDueInvoices;
