import request, { ContentType } from '../api-request';
import { OpenPlan } from '../../plan-enrollment/plan-enrollment.types';

export function getCustomEnrollmentWithDepsCount(companyId: number, plan: OpenPlan) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load custom enrollment with dependents for invalid company.'));
  }

  if (plan.plcid <= 0) {
    return Promise.reject(new Error('Cannot load custom enrollment with dependents for invalid plan.'));
  }

  return request<number>(`/api/companies/${companyId}/reports/custom-enrollment/with-deps/${plan.plcid}/count`);
}

export function downloadCustomEnrollmentWithDepsReport(companyId: number, plan: OpenPlan) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot download custom enrollment with dependents report for invalid company.'));
  }

  if (plan.plcid <= 0) {
    return Promise.reject(new Error('Cannot download custom enrollment with dependents report for invalid plan.'));
  }

  if (plan.pyrid <= 0) {
    return Promise.reject(new Error('Cannot download custom enrollment with dependents report for invalid plan year.'));
  }

  const exportUri =
    `/api/companies/${companyId}/reports/custom-enrollment/with-deps/${plan.pyrid}/${plan.plcid}/export`;

  return request(exportUri, { method: 'GET', headers: { accept: ContentType.XLSX } });
}
