import * as yup from 'yup';

import { requiredText } from 'shared/common-validators';

export const validationSchema = yup.object({
  confname: yup
    .string()
    .trim()
    .max(15, 'First name must be 15 characters or less')
    .required('First name is required'),
  conlname: yup
    .string()
    .trim()
    .max(20, 'Last name must be 20 characters or less')
    .required('Last name is required'),
  conaddr1: yup
    .string()
    .trim()
    .max(30, 'Street address line 1 must be 30 characters or less')
    .nullable()
    .required(requiredText),
  conaddr2: yup
    .string()
    .max(30, 'Street address line 2 must be 30 characters or less')
    .nullable(),
  concity: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z\s]+$/, 'City must only include letters and spaces')
    .max(20, 'City must be 20 characters or less')
    .nullable()
    .required(requiredText),
  constate: yup
    .string()
    .trim()
    .nullable()
    .required(requiredText),
  conzip: yup
    .string()
    .trim()
    .matches(/^[\d-]+$/, 'Zip must only include numbers and hyphens')
    .max(10, 'Zip must be 10 characters or less')
    .nullable()
    .required(requiredText),
  conphone: yup
    .string()
    .max(17, 'Phone must be 17 characters or less')
    .test('digits', 'Must be at least 10 digits',
      (phone) => !phone || phone.replace(/[A-Za-z$-.]/g, '').length >= 10)
    .required(requiredText),
  conemail: yup
    .string()
    .email()
    .max(50, 'Email must be 50 characters or less')
    .nullable()
    .required(requiredText),
  confax: yup
    .string()
    .max(17, 'Fax must be 17 characters or less')
    .test(
      'fax-empty-or-10-chars-check',
      'Fax must be at least 10 characters',
      (phone) => !phone || phone.length >= 10,
    )
    .nullable(),
  contitle: yup
    .string()
    .max(30, 'Street address line 2 must be 30 characters or less')
    .nullable(),
  condept: yup
    .string()
    .max(30, 'Street address line 2 must be 30 characters or less')
    .nullable(),
});
