import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose, F, T } from 'ramda';
import store from 'store';
import { selectSelectedCompany } from 'companies/companies.slice';
import LeaveRecordsSearchResultsContainer from 'leave-records/components/LeaveRecordsSearchResultsContainer';
import withDebounce from 'components/shared/HOC/WithDebounce';
import SearchInput from 'components/shared/SearchInput';
import { fetchEmployeeSearchResultsByCriteria } from 'leave-records/leave-records.thunk';

const DebouncedSearch = withDebounce(SearchInput, 500);

export function LeaveRecordsSearchInput() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [ isOpen, setIsOpen ] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const companyId = useSelector(selectSelectedCompany)?.compid ?? 0;
  const searchPromise = useRef() as any;

  function onDebounceChange(criteria: string) {
    if (criteria.length) {
      searchPromise.current?.abort('cancelled');
      searchPromise.current = dispatch(fetchEmployeeSearchResultsByCriteria({ criteria, companyId }));
    }
  }

  return (
    <>
      <h3 className="text-base font-medium uppercase">Search Leave Cases</h3>
      <div ref={anchorRef} data-testid="search-bar">
        <DebouncedSearch
          placeholder="Search by Name/Employee ID"
          size="small"
          label=""
          className="rounded-full text-sm"
          onDebouncedChange={onDebounceChange}
          onFocus={compose(setIsOpen, T)}
          onBlur={compose(setIsOpen, T)}
          aria-controls={isOpen ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        />
      </div>
      <LeaveRecordsSearchResultsContainer
        anchorRef={anchorRef}
        isOpen={isOpen}
        onClose={compose(setIsOpen, F)}
      />
    </>
  );
}
