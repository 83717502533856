import * as yup from 'yup';

import { validationSchema as piSchema } from 'shared/participant-info.validators';
import { payrollid } from 'shared/common-validators';

export const validationSchema = piSchema.concat(
  yup.object({
    ...payrollid,
    sendToEmployee: yup.boolean(),
    sendToSpouse: yup.boolean(),
    birthdate: yup
      .date()
      .nullable(),
    payfreq: yup
      .string()
      .nullable(),
    spouseName: yup
      .string()
      .test(
        'spouse-name-empty-or-max35-check',
        'Spouse name must be no more than 35 characters.',
        (name) => !name || name.length <= 35,
      )
      .nullable(),
    spouseGender: yup
      .string()
      .test(
        'spouse-sex-empty-or-1char-check',
        'Spouse sex must be exactly 1 character.',
        (sex) => !sex || sex.length === 1,
      )
      .nullable(),
  })
    .test(
      'at-least-one-notice-type',
      'Please select at least one notice to send.',
      ({ sendToEmployee, sendToSpouse }) => !!sendToEmployee || !!sendToSpouse,
    ),
);
