/* eslint-disable react/jsx-no-bind */
import { ReactElement, useState, useRef, DragEventHandler } from 'react';

type Props = { onDrop: (files: File[]) => void; disabled?: boolean; }

function DragAndDropUpload({ onDrop, disabled = false }: Props): ReactElement {
  const [ dragActive, setDragActive ] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  function handleDrag(e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }

  function handleDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e?.dataTransfer?.files?.length) {
      onDrop(e.dataTransfer.files);
    }
  }

  function handleChange(e: any) {
    e.preventDefault();

    if (e.target?.files?.length) {
      onDrop(e.target.files);
    }
  }

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <form
        className="h-64 w-full text-center relative"
        onDragEnter={handleDrag as unknown as DragEventHandler<HTMLFormElement>}
        onSubmit={(e: any) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          data-testid="upload-input"
          multiple
          onChange={handleChange}
          disabled={disabled}
        />

        <label
          htmlFor="input-file-upload"
          data-testid="upload-label"
          className={`${
            dragActive ? 'bg-gray-400' : 'bg-gray-100'
          } h-full flex items-center justify-center border-2 rounded-lg border-dashed border-gray-400`}
        >
          <p className="d-inline">Drag and drop or</p>
          <button
            className="cursor-pointer p-1 border-0 bg-transparent d-inline text-blue-500"
            data-testid="upload-button"
            onClick={onButtonClick}
          >browse
          </button>
          <p className="d-inine">your files</p>
        </label>
        {
          dragActive && (
            <div
              className="absolute w-full h-full rounded-lg top-0 right-0 bottom-0 left-0"
              data-testid="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )
        }
      </form>
    </>
  );
}

export default DragAndDropUpload;
