import request from '../api-request';
import { ContactUser, SummaryUser } from 'manage-settings/manage-users/manage-users.types';
import { setJWT } from 'authentication/authentication';

export function getSummaryUsers(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Unable to get users for invalid company.'));
  }

  return request<SummaryUser[]>(`/api/companies/${companyId}/users`)
    .then((result) => result?.map((u) => ({
      ...u,
      responsibilities: (u.responsibilities as string).split('|'),
      locations: (u.locations as string).split('|'),
      payFrequencies: (u.payFrequencies as string).split('|'),
    })) ?? []);
}

export async function getUserPermissions(companyId: number) {
  const response = await request<string>(`/api/companies/${companyId}/users/get-user-permissions`);
  setJWT(response ?? '');
}

export async function saveUser(companyId: number, body: ContactUser) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Unable to save user for invalid company.'));
  }

  if (!!body.conid) {
    return request(`/api/companies/${companyId}/users/${body.conid}`, { method: 'PUT', body });
  }

  return request(`/api/companies/${companyId}/users`, { method: 'POST', body });
}

export async function getContactUser(companyId: number, conid: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Unable to get user for invalid company.'));
  }

  if (conid < 0) {
    return Promise.reject(new Error('Unable to get user for invalid user id.'));
  }

  return request<ContactUser>(`/api/companies/${companyId}/users/${conid}`);
}
