import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { always, compose, F, T, tap, cond, equals, mergeDeepRight } from 'ramda';

import WizardStepper, { WizardStep } from 'components/shared/WizardStepper';
import wizardSteps, { AddUserStepSlug } from './add-user-steps';
import { noop } from 'shared/utils';
import { useHistoryBlock } from 'shared/custom-hooks/useHistoryBlock';
import PageContentPaper from 'components/layout/PageContentPaper';
import CancelModal from 'components/shared/CancelModal';
import AddUserStepOne from './AddUserStepOne';
import AddUserStepTwo from './AddUserStepTwo';
import { saveUser } from 'shared/api/usersApi';
import { useErrorNotifier } from 'shared/custom-hooks/useNotifiers';
import { selectSelectedCompany } from 'companies/companies.slice';
import { ContactUser } from 'manage-settings/manage-users/manage-users.types';
import Loading from 'components/shared/Loading';
import {
  selectSelectedUser,
  setSelectedUser,
  resetSelectedUser,
} from 'manage-settings/manage-users/manage-users.slice';
import store from 'store';

function AddUser(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch<typeof store.dispatch>();
  const {
    stepSlug: activeStepSlug,
  } = useParams<{ stepSlug: AddUserStepSlug; }>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: 0 };
  const selectedUser = useSelector(selectSelectedUser);

  const [ blockRegex ] = useState(/^\/(add|edit)-user/);
  const navUnblock = useHistoryBlock(blockRegex);

  const [ modalOpen, setModalOpen ] = useState(false);
  const showModal = compose(setModalOpen, T);
  const hideModal = compose(setModalOpen, F);

  const [ saving, setSaving ] = useState(false);
  const startSaving = compose(setSaving, T);
  const doneSaving = compose(setSaving, F);
  const errorNotifier = useErrorNotifier();
  const handleApiError = compose(tap(doneSaving), errorNotifier);
  const handleApiSuccess = compose(tap(doneSaving), onExit);

  const goToManageUsers = compose(
    tap(compose(history.push, always('/manage-settings/manage-users'))),
    navUnblock ?? noop,
    hideModal,
  );

  function handleStepClick(clickedStep: WizardStep) {
    return history.push(`/add-user/${clickedStep.slug}`);
  }

  function onWizardCancel() {
    if (activeStepSlug !== wizardSteps.keys().next().value) {
      showModal();
    } else {
      onExit();
    }
  }

  function onExit() {
    dispatch(resetSelectedUser());
    goToManageUsers();
  }

  function onSubmit(values: ContactUser) {
    startSaving();
    const newUser = mergeDeepRight(selectedUser ?? {}, values) as ContactUser;
    dispatch(setSelectedUser(newUser));
    saveUser(compid, newUser)
      .then(handleApiSuccess)
      .catch(handleApiError);
  }

  const renderStep = cond<string, ReactElement>([
    [
      equals('step-1'),
      always(<AddUserStepOne onCancel={onWizardCancel} />),
    ],
    [
      equals('step-2'),
      always(<AddUserStepTwo onCancel={onWizardCancel} onSubmit={onSubmit} />),
    ],
  ]);

  return (
    <>
      <PageContentPaper className="mt-4 mb-8">
        <div className="mb-8">
          <WizardStepper
            steps={wizardSteps}
            allowClick
            onChange={handleStepClick}
          />
        </div>
        <div className="px-8">
          {renderStep(activeStepSlug)}
        </div>
      </PageContentPaper>
      <CancelModal
        isOpen={modalOpen}
        onCancel={onExit}
        onClose={hideModal}
      />
      {saving && <Loading text="Saving user" />}
    </>
  );
}

export default AddUser;
