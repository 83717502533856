import React from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/SystemUpdateAlt';
import LoopIcon from '@mui/icons-material/Loop';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom';

interface ParticipantCountSummaryProps {
  title: string;
  participants: number;
  loading: boolean;
  exportUri?: string;
  detailsPath?: string;
  className?: string;
}

function ParticipantCountSummary({
  title,
  loading,
  participants,
  exportUri = '',
  detailsPath = '',
  className = '',
}: ParticipantCountSummaryProps): React.ReactElement {
  const history = useHistory();

  function handleExport() {
    // todo: there's another ticket for this
  }

  function handleDetails() {
    history.push(detailsPath);
  }

  function titleBarLink(): React.ReactElement {
    if (loading) {
      return <></>;
    }

    if (exportUri.length > 0) {
      return (
        <Button onClick={handleExport} className="p-0">
          <p className="flex flex-row justify-center text-sm text-high-em">
            <DownloadIcon className="mr-1" fontSize="small" /> Export
          </p>
        </Button>
      );
    }

    if (detailsPath.length > 0) {
      return (
        <Button onClick={handleDetails} className="p-0">
          <p className="flex flex-row justify-center text-sm text-high-em">
            <SearchIcon className="mr-1" fontSize="small" /> View Details
          </p>
        </Button>
      );
    }

    return <></>;
  }

  function content(): React.ReactElement {
    if (loading) {
      return (
        <div className="flex justify-center items-center flex-grow">
          <LoopIcon className="animate-spin" data-testid="noticeCountLoadingIcon" />
        </div>
      );
    }

    return (
      <>
        <div className={`flex flex-col justify-center text-center p-3 w-full`}>
          <p className="text-high-em">Pending Total</p>
          <h4 className="text-high-em">{participants}</h4>
        </div>
      </>
    );
  }

  return (
    <div className={`${className} flex flex-col border border-solid border-gray-300 rounded-sm divide-y`}>
      <div className="flex flex-row justify-between align-middle py-2 px-4">
        <p className="text-high-em">{title}</p>
        {titleBarLink()}
      </div>
      <div className="flex flex-wrap xl:divide-x flex-grow" >{content()}</div>
    </div>
  );
}

export default ParticipantCountSummary;
