import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Note, MAX_NOTIFICATION_LIST_SIZE, NotificationType } from './notification.types';
import { RootState } from 'store/rootReducer';

export interface NotificationState {
  notifications: Note[];
}

export const initialState: NotificationState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, { payload }: PayloadAction<Note>) {
      if (!payload.key) {
        payload.key = performance.now().toString();
      }

      state.notifications.unshift(payload);

      if (state.notifications.length > MAX_NOTIFICATION_LIST_SIZE) {
        state.notifications.pop();
      }
    },
    removeNotification(state, { payload }: PayloadAction<string>) {
      const idx = state.notifications.findIndex((n) => n.key === payload);

      if (idx > -1) {
        state.notifications.splice(idx, 1);
      }
    },
  },
});

export const {
  addNotification,
  removeNotification,
} = notificationSlice.actions;

/**
 * A psuedo action to wrap a common pattern I've been using.
 *
 * @param {Error} error
 * @returns notifications/addNotification type action
 */
export function addHttpErrorNotification(error: Error) {
  return addNotification({
    message: error.message,
    type: NotificationType.ERROR,
    area: 'snackbar',
    key: performance.now().toString(),
  });
}

/**
 * A psuedo action to wrap a common pattern I've been using.
 *
 * @param {Message} message
 * @returns notifications/addNotification type action
 */
export function addHttpSuccessNotification(message: string) {
  return addNotification({
    message,
    type: NotificationType.SUCCESS,
    area: 'snackbar',
    key: performance.now().toString(),
  });
}

export default notificationSlice.reducer;

// selectors
export const selectNotifications = (state: RootState): Note[] => state.notifications.notifications;

export const selectSnacks = (state: RootState): Note[] => {
  return state.notifications.notifications.filter((n) => n.area === 'snackbar' || n.area === 'both');
};

export const selectNotes = (state: RootState): Note[] => {
  return state.notifications.notifications.filter((n) => n.area === 'notifications' || n.area === 'both');
};
