import request from 'shared/api-request';
import { FeatureFlags } from 'components/app-settings.types';

export function getFeatureFlags(companyId: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot get feature flag settings for invalid company.'));
  }

  return request<FeatureFlags>(`/api/companies/${companyId}/features`);
}
