export function colorFromLetters(letters: string): string {
  const code = letters.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

  return avatarColorPalette[ code % avatarColorPalette.length ];
}

export const avatarColorPalette = [
  'bg-pink-400',
  'bg-pink-500',
  'bg-pink-600',
  'bg-pink-700',
  'bg-pink-800',
  'bg-pink-900',
  'bg-purple-400',
  'bg-purple-500',
  'bg-purple-600',
  'bg-purple-700',
  'bg-purple-800',
  'bg-purple-900',
  'bg-deepPurple-400',
  'bg-deepPurple-500',
  'bg-deepPurple-600',
  'bg-deepPurple-700',
  'bg-deepPurple-800',
  'bg-deepPurple-900',
  'bg-indigo-400',
  'bg-indigo-500',
  'bg-indigo-600',
  'bg-indigo-700',
  'bg-indigo-800',
  'bg-indigo-900',
  'bg-blue-400',
  'bg-blue-500',
  'bg-blue-600',
  'bg-blue-700',
  'bg-blue-800',
  'bg-blue-900',
  'bg-lightBlue-400',
  'bg-lightBlue-500',
  'bg-lightBlue-600',
  'bg-lightBlue-700',
  'bg-lightBlue-800',
  'bg-lightBlue-900',
  'bg-cyan-400',
  'bg-cyan-500',
  'bg-cyan-600',
  'bg-cyan-700',
  'bg-cyan-800',
  'bg-cyan-900',
  'bg-teal-400',
  'bg-teal-500',
  'bg-teal-600',
  'bg-teal-700',
  'bg-teal-800',
  'bg-teal-900',
  'bg-green-400',
  'bg-green-500',
  'bg-green-600',
  'bg-green-700',
  'bg-green-800',
  'bg-green-900',
  'bg-lightGreen-400',
  'bg-lightGreen-500',
  'bg-lightGreen-600',
  'bg-lightGreen-700',
  'bg-lightGreen-800',
  'bg-lightGreen-900',
  'bg-deepOrange-400',
  'bg-deepOrange-500',
  'bg-deepOrange-600',
  'bg-deepOrange-700',
  'bg-deepOrange-800',
  'bg-deepOrange-900',
  'bg-brown-400',
  'bg-brown-500',
  'bg-brown-600',
  'bg-brown-700',
  'bg-brown-800',
  'bg-brown-900',
  'bg-blueGrey-400',
  'bg-blueGrey-500',
  'bg-blueGrey-600',
  'bg-blueGrey-700',
  'bg-blueGrey-800',
  'bg-blueGrey-900',
];

export const getInitials = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
};

export const avatarColor = (firstName: string, lastName: string) => {
  return colorFromLetters(firstName + lastName);
};
