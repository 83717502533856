import
{
  NonPlanYearInvoice,
  NonPlanYearInvoiceDetail,
} from 'participant-overview/enrollment/view-invoices/view-invoices.types';
import request from 'shared/api-request';

export function getInvoices(companyId: number, isCobra: boolean, employeeNumber: string) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load invoices for invalid company.'));
  }

  if (!employeeNumber) {
    return Promise.reject(new Error('Cannot load invoices with invalid employee id.'));
  }

  return request<NonPlanYearInvoice[]>(`/api/companies/${companyId}/qualifying-events/`
    + `${isCobra ? 'c' : 'r'}/invoices/${employeeNumber}`);
}

export function getInvoiceDetails(companyId: number, isCobra: boolean, employeeNumber: string, invoiceNumber: number) {
  if (companyId <= 0) {
    return Promise.reject(new Error('Cannot load invoice details for invalid company.'));
  }

  if (!employeeNumber) {
    return Promise.reject(new Error('Cannot load invoice details with invalid employee id.'));
  }

  if (invoiceNumber <= 0) {
    return Promise.reject(new Error('Cannot load invoice details with invalid invoice number.'));
  }

  return request<NonPlanYearInvoiceDetail[]>(`/api/companies/${companyId}/qualifying-events/` +
    `${isCobra ? 'c' : 'r'}/invoice-details/${employeeNumber}/${invoiceNumber}`);
}
