import * as React from 'react';
import ReactPDF, { Text, View } from '@react-pdf/renderer';
import { getDefaultBorderIncludes, transformToArray } from './utils';

/**
 * Whether to include borders or not.
 * Depending on the context some toggles will not have any effect.
 */
export interface PdfTableBorder {
    /**
     * Include the top border. Default true.
     */
    includeTopBorder?: boolean;

    /**
     * Include the right border. Default true.
     */
    includeRightBorder?: boolean;

    /**
     * Include the bottom border. Default true.
     */
    includeBottomBorder?: boolean;

    /**
     * Include the left border. Default true.
     */
    includeLeftBorder?: boolean;
}

export interface PdfTableCellProps extends PdfTableBorder {
    /**
     * The weighting of a cell based on the flex layout style.
     * This value is between 0..1, if not specified 1 is assumed, this will take up the remaining available space.
     */
    weighting?: number;

    /**
     * Extra styling to apply. These will override existing style with the same key.
     */
    style?: ReactPDF.Style | ReactPDF.Style[];

    /**
     * How to align the text
     */
    textAlign?: 'left' | 'center' | 'right';

    /**
     * Whether this is a header cell or not. If not defined it will be false.
     */
    isHeader?: boolean;

    /**
     * The font-size to apply to the cell.
     */
    fontSize?: number | string;
}

/**
 * This component displays the associated content of it's children.
 */
// eslint-disable-next-line react/prefer-stateless-function
export class PdfTableCell extends React.PureComponent<PdfTableCellProps> {
  render() {
    let content: any;

    if (typeof (this.props as any).children === 'string') {
      content = (
        <Text>{(this.props as any).children}</Text>
      );
    } else if (typeof (this.props as any).children === 'number') {
      content = (
        <Text>{(this.props as any).children.toString()}</Text>
      );
    } else {
      content = (this.props as any).children;
    }

    const { includeRightBorder } = getDefaultBorderIncludes(this.props);
    const defaultStyle: ReactPDF.Style = {
      flex: this.props.weighting ?? 1,
      // @ts-expect-error
      justifyContent: 'stretch',
      padding: '5px',
      textAlign: this.props.textAlign ?? 'left',
      fontSize: this.props.fontSize ?? (this.props.isHeader === true ? 14 : 12),
      borderRight: includeRightBorder && '1pt solid #E5E5E5' as any,
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    };

    const mergedStyles: ReactPDF.Style[] = [
      defaultStyle,
      ...transformToArray(this.props.style),
    ];

    return (
      <View
        style={mergedStyles}
        wrap
      >
        {content}
      </View>
    );
  }
}
