import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { compose, pathOr } from 'ramda';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export interface SearchInputProps {
  label?: string;
  placeholder?: string;
  onChange: (search: string) => void;
  onFocus?: (search?: string) => void;
  size?: 'small' | 'medium'; // this gets passed along to the TextField. Default is "medium"
  variant?: 'outlined' | 'standard' | 'filled'; // passed along to TextField. Default is "outlined"
  [otherProp: string]: any;
}

function SearchInput({
  label = 'Search',
  placeholder = '',
  onChange,
  onFocus,
  size = 'medium',
  variant = 'outlined',
  className,
  ...rest
}: SearchInputProps): React.ReactElement {
  var inputRef = useRef<HTMLInputElement>(null);

  function handleFocus() {
    if (onFocus) {
      onFocus(inputRef.current?.value);
    }
  }

  const handleChanged = compose(
    onChange,
    pathOr('', [ 'currentTarget', 'value' ]),
  );

  function handleIconClick() {
    inputRef.current?.focus();
  }

  function handleClickAway(event: React.MouseEvent<Document>) {
    inputRef.current?.blur();
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway as any}>
      <TextField
        inputRef={inputRef}
        variant={variant}
        size={size}
        fullWidth
        label={label}
        placeholder={placeholder}
        onChange={handleChanged}
        onFocus={handleFocus}
        {...rest}
        inputProps={ {
          onPaste: handleChanged,
        } }
        InputProps={ {
          className,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon htmlColor="gray" onClick={handleIconClick} data-testid="search-input-icon" />
            </InputAdornment>
          ),
        } }
      />
    </ClickAwayListener>
  );
}

export default SearchInput;
