import * as yup from 'yup';
import dayjs from 'dayjs';

export const directBillChecklistErrors = {
  date: {
    required: 'Please enter a valid date',
  },
  directBillOeBegins: {
    planYearStart: 'Open enrollment starting date must be no more than 90 days after the plan year starts',
  },
  directBillOeEnds: {
    afterStartDate: 'Open enrollment ending date must be after open enrollment starting date',
  },
  directBillResponseDeadline: {
    afterStartDate: 'Deadline must be more than 14 days after starting date',
  },
};

export const directBillChecklistValidationSchema = yup.object({
  planYearStart: yup.string(),
  directBillOeBegins: yup
    .date()
    .typeError(`directBillOeBegins ${directBillChecklistErrors.date.required}`)
    .required()
    .when('planYearStart', (planYearStart: string | null, sch: any) => {
      return sch.test({
        test: (directBillOeBegins: Date | null) => !!directBillOeBegins && !!planYearStart && (
          dayjs(directBillOeBegins).diff(dayjs(planYearStart), 'days') <= 90
        ),
        message: `directBillOeBegins ${directBillChecklistErrors.directBillOeBegins.planYearStart}`,
      });
    }),
  directBillOeEnds: yup
    .date()
    .typeError(`directBillOeEnds ${directBillChecklistErrors.date.required}`)
    .required()
    .when('directBillOeBegins', (directBillOeBegins: Date | null, sch: any) => {
      return sch.test({
        test: (directBillOeEnds: Date | null) => !!directBillOeEnds && !!directBillOeBegins && (
          dayjs(directBillOeEnds).isAfter(directBillOeBegins)
        ),
        message: `directBillOeEnds ${directBillChecklistErrors.directBillOeEnds.afterStartDate}`,
      });
    }),
  directBillResponseDeadline: yup
    .date()
    .typeError(`directBillResponseDeadline ${directBillChecklistErrors.date.required}`)
    .required()
    .when('directBillOeBegins', (directBillOeBegins: Date | null, sch: any) => {
      return sch.test({
        test: (directBillResponseDeadline: Date | null) => !!directBillOeBegins && !!directBillResponseDeadline && (
          dayjs(directBillResponseDeadline).diff(dayjs(directBillOeBegins), 'days') > 14
        ),
        message:
          `directBillResponseDeadline ${directBillChecklistErrors.directBillResponseDeadline.afterStartDate}`,
      });
    }),
});
