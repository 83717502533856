/* eslint-disable no-console */
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { compose, T, F } from 'ramda';

import { CobraEnrollment } from './enrollment.types';
import DisplayCobRetLoaEnrollmentRow from './DisplayCobRetLoaEnrollmentRow';
import request from 'shared/api-request';
import { selectSelectedCompany } from 'companies/companies.slice';
import { useDispatch, useSelector } from 'react-redux';
import { addHttpErrorNotification } from 'notifications/notifications.slice';
import Button from '@mui/material/Button';
import { InvoicesModal } from './view-invoices/ViewInvoices';
import { ChecksModal } from './view-checks/ViewChecks';
import { DocumentsModal } from './view-documents/ViewDocuments';
import store from 'store';

export interface DisplayCobRetLoaEnrollmentProps {
  enrollment: CobraEnrollment[];
  title: string;
  type?: 'r' | 'c' | 'l';
}

const invoiceModalTitles = {
  c: 'COBRA Invoices',
  r: 'Retiree Invoices',
  l: 'LOA Invoices',
};

function DisplayCobRetLoaEnrollment({
  enrollment,
  title,
  type = 'r',
}: DisplayCobRetLoaEnrollmentProps): React.ReactElement {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { compid } = useSelector(selectSelectedCompany) ?? { compid: undefined };
  const [ invoicesOpen, setInvoicesOpen ] = useState(false);
  const [ documentsOpen, setDocumentsOpen ] = useState(false);
  const [ checksOpen, setChecksOpen ] = useState(false);

  function handlePrintNotice(e: CobraEnrollment): Promise<any> {
    return request<string>(`/api/companies/${compid}/qualifying-events/${type !== 'c' ? 'r' : 'c'}/print/${e.unqid}`)
      .catch((err) => {
        compose(dispatch, addHttpErrorNotification)(err);
      });
  }

  function content() {
    if (enrollment.length > 0) {
      return (
        <>
          <h6 className="text-primary-light ml-4 mt-4 participant-overview-crl-enrollments">{title}</h6>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Event Date</TableCell>
                <TableCell>Event Reason</TableCell>
                <TableCell>Event Status</TableCell>
                <TableCell>Period Start</TableCell>
                <TableCell>Period End</TableCell>
                <TableCell>Last Payment</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enrollment.map((e) => (
                <DisplayCobRetLoaEnrollmentRow
                  enrollment={e}
                  key={e.unqid}
                  onPrintNotice={handlePrintNotice}
                />
              ))}
            </TableBody>
          </Table>
          <div className="flex justify-end mt-8 plan-enrollment-crl-enrollments-btn">
            <Button onClick={compose(setInvoicesOpen, T)} variant="outlined" color="primary" className="mr-8">
              View Invoices
            </Button>
            <Button onClick={compose(setDocumentsOpen, T)} variant="outlined" color="primary" className="mr-8">
              View Documents
            </Button>
            <Button onClick={compose(setChecksOpen, T)} variant="outlined" color="primary" className="mr-8">
              View Checks
            </Button>
          </div>
          <InvoicesModal
            title={invoiceModalTitles[type]}
            isCobra={type === 'c'}
            isOpen={invoicesOpen}
            onClose={compose(setInvoicesOpen, F)}
          />
          <DocumentsModal
            title="View Documents"
            isCobra={type === 'c'}
            isOpen={documentsOpen}
            onClose={compose(setDocumentsOpen, F)}
          />
          <ChecksModal
            title="View Checks"
            isCobra={type === 'c'}
            isOpen={checksOpen}
            onClose={compose(setChecksOpen, F)}
          />
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      {content()}
    </>
  );
}

export default DisplayCobRetLoaEnrollment;
