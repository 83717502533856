import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';

import StateSelect from 'components/shared/StateSelect';
import { handleCustomFieldChange, formikFieldErrorText, formikFieldHasError } from 'shared/form-helpers';
import { LeaveRequestFormFields } from 'enter-leave-request/enter-leave-request.types';

export function EnterLeaveRequestPersonalDetails(): React.ReactElement {
  const form = useFormikContext<LeaveRequestFormFields>();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form.validateForm();
  }, []);

  return (
    <>
      <div className="flex space-x-4 mb-4" data-testid="enter-leave-request-personal-details">
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="EEID"
          name="employee.eeId"
          value={form.values.employee.eeId}
          error={formikFieldHasError('employee.eeId', form)}
          helperText={formikFieldErrorText('employee.eeId', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="eeId"
        />
        <div className="basis-1/2" />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="First Name"
          name="employee.nameFirst"
          value={form.values.employee.nameFirst}
          error={formikFieldHasError('employee.nameFirst', form)}
          helperText={formikFieldErrorText('employee.nameFirst', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="nameFirst"
        />
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Last Name"
          name="employee.nameLast"
          value={form.values.employee.nameLast}
          error={formikFieldHasError('employee.nameLast', form)}
          helperText={formikFieldErrorText('employee.nameLast', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="nameLast"
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Employee Email (Optional)"
          name="employee.email"
          value={form.values.employee.email}
          error={formikFieldHasError('employee.email', form)}
          helperText={formikFieldErrorText('employee.email', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="email"
        />
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="Employee Phone (Optional)"
          name="employee.phone"
          value={form.values.employee.phone}
          error={formikFieldHasError('employee.phone', form)}
          helperText={formikFieldErrorText('employee.phone', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="phone"
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          fullWidth
          variant="outlined"
          label="Address Line 1"
          name="employee.addr1"
          value={form.values.employee.addr1}
          error={formikFieldHasError('employee.addr1', form)}
          helperText={formikFieldErrorText('employee.addr1', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="addr1"
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          fullWidth
          variant="outlined"
          label="Address Line 2 (Optional)"
          name="employee.addr2"
          value={form.values.employee.addr2}
          error={formikFieldHasError('employee.addr2', form)}
          helperText={formikFieldErrorText('employee.addr2', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="addr2"
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          className="basis-1/2"
          variant="outlined"
          label="City"
          name="employee.city"
          value={form.values.employee.city}
          error={formikFieldHasError('employee.city', form)}
          helperText={formikFieldErrorText('employee.city', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="city"
        />
        <StateSelect
          className="basis-1/4"
          label="State"
          name="employee.stateProvCd"
          value={form.values.employee.stateProvCd}
          onStateChanged={handleCustomFieldChange('employee.stateProvCd', form)}
          onStateBlur={form.handleBlur}
          error={formikFieldHasError('employee.stateProvCd', form)}
          data-testid="stateProvCd"
        />
        <TextField
          className="basis-1/4"
          variant="outlined"
          label="Zip"
          name="employee.postalCd"
          value={form.values.employee.postalCd}
          error={formikFieldHasError('employee.postalCd', form)}
          helperText={formikFieldErrorText('employee.postalCd', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          data-testid="postalCd"
        />
      </div>
    </>
  );
}
