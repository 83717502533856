import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';

import {
  formikFieldErrorText,
  formikFieldHasError,
} from 'shared/form-helpers';
import { FormikMuiDatePicker } from 'components/shared/form/formik/FormikMuiDatePicker';
import { ReportAbsenceFormFields } from 'report-an-absence/report-an-absence.types';

export function ReportAnAbsenceEventInfo(): React.ReactElement {
  const form = useFormikContext<ReportAbsenceFormFields>();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form.validateForm();
  }, []);

  return (
    <>
      <div className="flex space-x-4 mb-4" data-testid="report-an-absence-event-info">
        <FormikMuiDatePicker
          className="basis-1/2"
          label="Date of Absence"
          path="event.absenceDate"
          form={form}
          hasError={formikFieldHasError('event.absenceDate', form)}
          value={form.values.event.absenceDate}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
        />
        <TextField
          data-testid="hours"
          className="w-1/2"
          type="number"
          variant="outlined"
          label="Hours"
          name="event.hours"
          inputProps={ { min: 0, max: 24 } }
          value={form.values.event.hours}
          error={formikFieldHasError('event.hours', form)}
          helperText={formikFieldErrorText('event.hours', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <TextField
          data-testid="minutes"
          className="w-1/2"
          type="number"
          variant="outlined"
          label="Minutes"
          name="event.minutes"
          inputProps={ { min: 0, max: 59 } }
          value={form.values.event.minutes}
          error={formikFieldHasError('event.minutes', form)}
          helperText={formikFieldErrorText('event.minutes', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
        <TextField
          data-testid="title"
          className="w-1/2"
          variant="outlined"
          label="Case Number"
          name="event.title"
          value={form.values.event.title}
          error={formikFieldHasError('event.title', form)}
          helperText={formikFieldErrorText('event.title', form)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      </div>
    </>
  );
}
