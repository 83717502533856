import * as yup from 'yup';
import dayjs from 'dayjs';
import { both, isNil } from 'ramda';

import { isWithinDateRange } from 'shared/utils';

const requiredText = 'This field is required';

export const validationSchema = yup
  .object({
    partStart: yup
      .date()
      .typeError('Invalid Date Format')
      .nullable()
      .required(requiredText),
    partEnd: yup
      .date()
      .typeError('Invalid Date Format')
      .when('partStart', (start: string, schema: any) => {
        return schema.test({
          test: (end: string) => !!start && (dayjs(start).isSame(dayjs(end)) || dayjs(start).isBefore(dayjs(end))),
          message: 'End date must be after start date',
        });
      })
      .nullable()
      .required(requiredText),
    payPeriodDeduction: yup
      .number()
      .nullable()
      .min(0, 'Must be a positive number')
      .when('isHra', {
        is: (isHra: boolean) => !isHra,
        then: yup
          .number()
          .min(0, 'Must be a positive number')
          .typeError('Please enter a valid number')
          .required(requiredText),
      }),
    partAnn: yup
      .number()
      .positive('Must be a positive number')
      .typeError('Please enter a valid number')
      .required(requiredText)
      .test({
        name: 'max',
        exclusive: false,
        params: { },
        message: 'Cannot exceed maximum.',
        test: function (value) {
          return (value ?? 0) <= parseFloat(this.parent.allocationMax ?? Number.MAX_SAFE_INTEGER);
        },
      }),
    firstPayDateOfChange: yup
      .string()
      .trim()
      .nullable()
      .when('isHra', {
        is: (isHra: boolean) => !isHra,
        then: yup
          .string()
          .trim()
          .nullable()
          .required(requiredText),
      }),
    yearBeg: yup.string(),
    yearEnd: yup.string(),
    payDates: yup.array(),
    limitedPurpose: yup.boolean(),
    level: yup
      .number()
      .when('limitedPurpose', {
        is: (limitedPurpose: boolean) => limitedPurpose,
        then: yup
          .number()
          .required('Level is required when Limited Purpose is checked.'),
      }),
  })
  // Begin full form tests
  .test(
    'plan-year-provided',
    'Error: No plan year can be determined.',
    ({ yearBeg, yearEnd }) => !(isNil(yearBeg) || isNil(yearEnd)),
  )
  // Skip the next validation if either partStart or partEnd
  // is nil because .required() will catch that
  .test(
    'dates-within-plan-year',
    'Start and end dates must be within the open plan year.',
    ({
      yearBeg, yearEnd, partStart, partEnd,
    }) => isNil(partStart) || isNil(partEnd) || both(
      isWithinDateRange(yearBeg as string, yearEnd as string, partStart),
      isWithinDateRange(yearBeg as string, yearEnd as string, partEnd),
    )(),
  );
