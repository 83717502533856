import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

import classes from './ReportAnAbsenceSummary.module.css';
import { ReportAbsenceFormFields } from 'report-an-absence/report-an-absence.types';
import { DateFormat_M_D_YYYY } from 'shared/consts/date-time.consts';
import { always, any, ifElse, isEmpty } from 'ramda';

export function ReportAnAbsenceSummary(): React.ReactElement {
  const form = useFormikContext<ReportAbsenceFormFields>();
  const { values, errors } = form;
  const hasPersonalDetailsErrors = errors.employee;
  const hasEventDetailsErrors = errors.event;
  const absenceDate = ifElse(
    isEmpty,
    always(''),
    always(dayjs(values.event.absenceDate).format(DateFormat_M_D_YYYY)),
  )(values.event.absenceDate);
  const dob = ifElse(
    isEmpty,
    always(''),
    always(dayjs(values.employee.dob).format(DateFormat_M_D_YYYY)),
  )(values.employee.dob);
  const absenceTime = ifElse(
    any(isEmpty),
    always(''),
    always(`${dayjs().hour((parseInt(values.event.hours))).format('hh')}
      : ${dayjs().minute(parseInt(values.event.minutes)).format('mm')}`),
  )([ values.event.hours, values.event.minutes ]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form.validateForm();
  }, []);

  return (
    <div className="flex space-x-8 mb-12" data-testid="report-an-absence-summary">
      <div className="w-1/2">
        <div className="flex justify-between items-center w-full pb-2 mb-2 border-b border-b-gray-200">
          <p className={classes.sectionHeader}>Personal Details</p>
          <Link to="/report-an-absence/personal-details" className={`${classes.link} text-sm`}>Edit</Link>
        </div>
        {
          hasPersonalDetailsErrors && (
            <p className="text-sm text-red-600 mb-4" data-testid="personal-details-error">
              One or more validation errors occurred
            </p>
          )
        }
        <dl className="text-sm">
          <dt className="font-bold">{values.employee.nameFirst} {values.employee.nameLast}</dt>
          {
            values.employee.dob &&
            (
              <div className="flex" data-testid="dob">
                <dt className="pr-2">Date of Birth:</dt>
                <dd className="font-bold flex-grow">{dob}</dd>
              </div>
            )
          }
          {
            values.employee.phone &&
            (
              <div className="flex" data-testid="phone">
                <dt className="pr-2">Phone:</dt>
                <dd className="font-bold flex-grow">{values.employee.phone}</dd>
              </div>
            )
          }
          <div className="flex">
            <dt className="pr-2">Address:</dt>
            <dd className="font-bold basis-5 flex-grow">
              {values.employee.addr1}
              {
                values.employee.addr2 &&
                (
                  <span data-testid="addr2">
                    <br />{values.employee.addr2}
                  </span>
                )
              }
              <br />{values.employee.city}, {values.employee.stateProvCd} {values.employee.postalCd}
            </dd>
          </div>
        </dl>
      </div>
      <div className="w-1/2">
        <div className="flex justify-between items-center w-full pb-2 mb-2 border-b border-b-gray-200">
          <p className={classes.sectionHeader}>Event Info</p>
          <Link to="/report-an-absence/event-info" className={`${classes.link} text-sm`}>Edit</Link>
        </div>
        {
          hasEventDetailsErrors && (
            <p className="text-sm text-red-600 mb-4" data-testid="event-info-error">
              One or more validation errors occurred
            </p>
          )
        }
        <dl className="text-sm">
          <div className="flex">
            <dt className="pr-2">Date of Absence:</dt>
            <dd className="font-bold">{absenceDate}</dd>
          </div>
          <div className="flex">
            <dt className="pr-2">Hours & Minutes:</dt>
            <dd className="font-bold">{absenceTime}</dd>
          </div>
          {
            values.event.title &&
            (
              <div className="flex" data-testid="title">
                <dt className="pr-2">Case Number:</dt>
                <dd className="font-bold">{values.event.title}</dd>
              </div>
            )
          }
        </dl>
      </div>
    </div>
  );
}
