import { useEffect, useState } from 'react';
import { planCodeToPlanType } from 'shared/utils';
import { concat, always, cond, equals, isNil, identity, T } from 'ramda';

import { EnrollmentResourcePlan } from 'open-enrollment-resources/open-enrollment.types';

function mapPlanTypeToAbbreviation(type: string) {
  return cond([
    [ equals('MRA'), always('HCFSA') ],
    [ equals('DCRA'), always('DCFSA') ],
    [ equals('QTE'), always('CBA') ],
    [ equals('HSA'), identity ],
    [ equals('LFSA'), identity ],
    [ equals('HRA'), identity ],
    [ T, always(undefined) ],
  ])(type as never);
}

function mapAbbreviationToDisplayName(type: string) {
  return cond([
    [ equals('HCFSA'), always('Health Care FSA') ],
    [ equals('DCFSA'), always('Dependent Care FSA') ],
    [ equals('CBA'), always('Commuter Benefit Account') ],
    [ equals('HSA'), always('Health Savings Account') ],
    [ equals('LFSA'), always('Limited-Purpose FSA') ],
    [ equals('HRA'), always('Health Reimbursement Account') ],
    [ equals('Custom'), always('Custom Reimbursement Account') ],
    [ equals('COBRA'), identity ],
    [ equals('Direct Bill'), identity ],
  ])(type as never);
}

export function usePlanDisplayNames(
  plans: EnrollmentResourcePlan[] | null,
  hasCobra: boolean | undefined,
  hasDirectBill: boolean | undefined): [string[], string[]] {
  const [ planAbbreviations, setPlanAbbreviations ] = useState<string[]>([]);
  const [ planDisplayNames, setPlanDisplayNames ] = useState<string[]>([]);
  const [ mappedPlans, setMappedPlans ] = useState<EnrollmentResourcePlan[] | null>(null);

  useEffect(() => {
    setMappedPlans(
      plans
        ? concat(
          plans
            .filter(p => p.planCode === 'M' && (p.hasFloresHSA || p.hasOtherHSA))
            .map(p => ({ ...p, planCode: '-' })),
          plans)
        : null,
    );
  }, [ plans ]);

  useEffect(() => {
    let abbreviations: string[] = [];

    if (mappedPlans?.length) {
      abbreviations = mappedPlans
        .map(({ planCode }) => planCodeToPlanType(planCode))
        .filter(code => !isNil(code))
        .filter(code => [ 'MRA', 'DCRA', 'QTE', 'HSA', 'LFSA', 'HRA' ].includes(code!))
        .map(code => mapPlanTypeToAbbreviation(code!)) as string[];

      if (mappedPlans.some((plan) => plan.planId === 35)) {
        abbreviations.push('Custom');
      }
    }

    if (hasCobra) {
      abbreviations.push('COBRA');
    }

    if (hasDirectBill) {
      abbreviations.push('Direct Bill');
    }

    const uniqueAbbreviations = Array.from(new Set(abbreviations))
      .filter(code => !isNil(code));
    setPlanAbbreviations(uniqueAbbreviations);

    const displayNames = uniqueAbbreviations
      .map(a => mapAbbreviationToDisplayName(a));
    setPlanDisplayNames(displayNames);
  }, [ mappedPlans, hasCobra, hasDirectBill ]);

  return [ planAbbreviations, planDisplayNames ];
}
